import "./general-form.css"
import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Container,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
} from "reactstrap"
import { get, getValueByDotNotation } from "../utility/client-utility"
import QrcodeScanner from "./qrcode-scanner"
import GeneralTable from "./general-table"
import { makeApiCall, makeApiDownloadCall } from "../api/generic-api"

import { debounce } from "lodash"
import { matcher, isMatch } from "matcher"
import { findRenderedComponentWithType } from "react-dom/test-utils"

import * as moment from "moment"
import ReactLoading from "react-loading"
import { useAlert } from "react-alert"

function GeneralFormTrayNew(props) {
  const elements = useRef({})
  const sections = get(() => props.formDefinition.sections, [])
  const allFields = sections.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.fields)
  }, [])
  const dateFields = allFields.filter((field) => field.type === "date")
  const datetimeFields = allFields.filter((field) => field.type === "datetime-local")
  const readOnly = !!!props.submit || props.update

  const [isDisableForm, setIsDisableForm] = useState(readOnly)
  const [isMerging, setIsMerging] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [showMergeModal, setShowMergeModal] = useState(false)
  const handleCloseModal = () => setShowMergeModal(false)
  const handleShowModal = () => setShowMergeModal(true)
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([])
  const badEmailAddresses = props.applicationState.badEmailAddresses

  const alert = useAlert()

  useEffect(() => {
    props.setIsFormDisabled(isDisableForm)
  }, [isDisableForm])

  const capitalize = (value) => {
    return value && value.replace ? value.replace(/^\w/, (c) => c.toUpperCase()) : value
  }

  const getCustomValidationFields = (sections) => {
    let customValidationFields = []
    for (let s = 0; s < sections.length; s++) {
      const fields = get(() => sections[s].fields, [])
      const validationFields = fields.filter((field) => field.validation instanceof Object)
      if (validationFields.length) {
        customValidationFields = customValidationFields.concat(validationFields)
      }
    }
    return customValidationFields
  }
  const customValidationFields = getCustomValidationFields(sections)

  const getInitialState = () => {
    const initialFormState = {}
    for (let s = 0; s < sections.length; s++) {
      const fields = get(() => sections[s].fields, [])
      for (let f = 0; f < fields.length; f++) {
        if (fields[f].defaultValue !== undefined && fields[f].defaultValue !== null) {
          //console.log('default value of ', fields[f].name, 'is', fields[f].defaultValue);
          initialFormState[fields[f].name] = fields[f].defaultValue
        } else if (fields[f].type === "checkbox") {
          initialFormState[fields[f].name] = false
        } else {
          initialFormState[fields[f].name] = null
        }
      }
    }
    //console.log(Object.assign(initialFormState, props.formState));
    return Object.assign(initialFormState, props.formState)
  }

  const [formState, setFormState] = useState(getInitialState())
  const [dataState, setDataState] = useState(getInitialState())
  const [alertState, setAlertState] = useState({})

  useEffect(() => {
    setFormState(getInitialState())
    setDataState(getInitialState())
  }, [props.formState])

  const getColumns = (section) => {
    const fields = get(() => section.fields, [])
    const numberOfFields = fields.length
    //console.log('section ' + section.label + ' field count is ' + numberOfFields);
    const numberOfColumns = get(() => section.columns, 1)
    const maxFieldsPerColumn = Math.ceil(numberOfFields / numberOfColumns)
    //console.log('max fields per column: ' + maxFieldsPerColumn);
    let columnWidth = "6"
    switch (numberOfColumns) {
      case 1:
        columnWidth = "12"
        break
      case 2:
        columnWidth = "6"
        break
      case 3:
        columnWidth = "4"
        break
      default:
        throw new Error("Unhandled column format")
    }

    const columns = []
    for (let fieldIndex = 0; fieldIndex < numberOfFields; fieldIndex++) {
      const columnIndex = Math.floor(fieldIndex / maxFieldsPerColumn)
      //console.log(columnIndex, fieldIndex);
      if (columnIndex >= columns.length) {
        const column = {
          width: columnWidth,
          fields: [],
        }
        columns.push(column)
      }
      columns[columnIndex].fields.push(fields[fieldIndex])
    }

    return columns
  }

  const getOptions = (field, noEmpty) => {
    const empty = noEmpty
      ? []
      : [
          {
            key: "",
            value: "",
          },
        ]
    if (!field || !field.source || !field.source.kind || !field.source.data) {
      return empty
    }

    if (field.source.kind === "direct") {
      const directResult = empty.concat(field.source.data)
      return directResult
    }

    if (field.source.kind === "lookup") {
      const lookupResult = empty.concat(props.lookups.filter((s) => s.type === field.source.data))
      return lookupResult
    }

    if (field.source.kind === "prop" || field.source.kind === "props") {
      const lookupResult = empty.concat(props[field.source.data])
      return lookupResult
    }

    return empty
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    console.log("general form submit")
    if (props.submit) {
      props.submit(dataState)
    }

    if (props.update) {
      setIsSubmitting(true)
      const result = await props.update(dataState)
      if (result.ok) {
        setIsDisableForm(true)
        alert.show("Form has been successfully updated", { type: "success" })
      }

      setTimeout(() => {
        setIsSubmitting(false)
      }, 500)
    }
  }

  const dateValidate = (newDataState) => {
    if (!newDataState) {
      return
    }
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (dateFields.find((dateField) => dateField.name === fieldName) && newDataState[fieldName]) {
          console.log("validating date", newDataState[fieldName])
          const element = elements.current[fieldName]
          let valid = false
          if (newDataState[fieldName] && newDataState[fieldName].length === 10) {
            try {
              const dateTimeString = newDataState[fieldName] + "T00:00:00Z"
              const date = new Date(dateTimeString)
              console.log("date", date)
              valid = date && date instanceof Date && isFinite(date) ? true : false
            } catch {}
          }
          console.log("valid", valid)
          element.setCustomValidity(valid ? "" : "Invalid date, please use this format: " + getDateFormatString())
        }
      }
    }
    console.log("new data state", newDataState)
    return newDataState
  }

  const datetimeValidate = (newDataState) => {
    if (!newDataState) {
      return
    }
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (datetimeFields.find((datetimeField) => datetimeField.name === fieldName) && newDataState[fieldName]) {
          console.log("validating date time", newDataState[fieldName])
          const element = elements.current[fieldName]
          let valid = false
          if (newDataState[fieldName]) {
            try {
              const datetimeString = newDataState[fieldName]
              const datetime = new Date(datetimeString)
              console.log("datetime", datetime)
              valid = datetime && datetime instanceof Date && isFinite(datetime) ? true : false
            } catch {}
          }
          console.log("valid", valid)
          element?.setCustomValidity(valid ? "" : "Invalid datetime value")
        }
      }
    }
    console.log("new data state", newDataState)
    return newDataState
  }

  const customValidate = (newDataState) => {
    if (customValidationFields.length) {
      //console.log('custom validate');
      for (let i = 0; i < customValidationFields.length; i++) {
        const field = customValidationFields[i]
        //console.log('checking', field.name);
        const rule = get(() => field.validation.rule)
        const message = get(() => field.validation.message)
        if (rule && message) {
          // TODO more general rule parsing
          const or = get(() => rule.$or, [])
          if (!or) {
            console.error("validation rule not supported", rule)
          } else {
            //console.log('checking rule', or);
            let valid = or.length === 0 ? true : false
            for (let i = 0; i < or.length; i++) {
              const ruleItem = or[i]
              //console.log('rule item', ruleItem);
              for (let j in ruleItem) {
                if (ruleItem.hasOwnProperty(j)) {
                  //console.log('has own property', j);
                  //console.log('comparing', newState[j], ruleItem[j]);
                  if (newDataState[j] === ruleItem[j]) {
                    valid = true
                  }
                }
              }
            }
            //console.log('validity of', field.name, 'is', valid);
            const element = elements.current[field.name]
            element.setCustomValidity(valid ? "" : message)
          }
        } else {
          console.warn("skipping custom validation because of empty rule or message", field.validation)
        }
      }
    }
  }

  const getDateFormatString = () => {
    const formatObj = new Intl.DateTimeFormat(navigator.language).formatToParts(new Date())

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "DD"
          case "month":
            return "MM"
          case "year":
            return "YYYY"
          default:
            return obj.value
        }
      })
      .join("")
  }

  const dateValue = (rawValue) => {
    if (!rawValue) {
      return rawValue
    }
    console.log(rawValue)
    const usaDateFormatRegex = /^([\d]{2})\/([\d]{2})\/([\d]{4})$/
    const results = rawValue.match(usaDateFormatRegex)
    if (results) {
      const dateFormatString = getDateFormatString()
      switch (dateFormatString) {
        case "MM/DD/YYYY":
          return `${results[3]}-${results[1]}-${results[2]}`
        case "DD/MM/YYYY":
          return `${results[3]}-${results[2]}-${results[1]}`
        default:
          return rawValue
      }
    }
    return rawValue
  }

  const datetimeValue = (rawValue) => {
    if (!rawValue) {
      return rawValue
    }
    try {
      const value = new Date(rawValue)
      const result = value.toISOString()
      return result
    } catch (e) {
      console.error("Could not parse datetime", e)
    }

    return rawValue
  }

  const dataStateFromFormState = (newFormState) => {
    let newDataState = JSON.parse(JSON.stringify(newFormState))
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (dateFields.find((dateField) => dateField.name === fieldName)) {
          newDataState[fieldName] = dateValue(newDataState[fieldName])
        }
        if (datetimeFields.find((datetimeField) => datetimeField.name === fieldName)) {
          newDataState[fieldName] = datetimeValue(newDataState[fieldName])
        }
        if (newDataState[fieldName] === "true") {
          newDataState[fieldName] = true
        }
        if (newDataState[fieldName] === "false") {
          newDataState[fieldName] = false
        }
        if (newDataState[fieldName] === "null") {
          newDataState[fieldName] = null
        }
      }
    }
    //console.log('new data state', newDataState);
    return newDataState
  }

  const validateEmailChange = useRef(
    debounce(async (e, field) => {
      const email = e.target.value
      const badEmails = props.applicationState.badEmailAddresses
      const invalid = isInvalidEmailAddress(email)
      if (invalid) {
        e.target.className += " invalid-input"
        return false
      } else {
        e.target.classList.remove("invalid-input")
        return true
      }
    }, 300),
  ).current

  const isInvalidEmailAddress = (email) => {
    if (email && badEmailAddresses && badEmailAddresses.length) {
      const matches = matcher([email], badEmailAddresses)
      return matches.length
    }
  }

  const validateDateChange = (e, field) => {
    if (e.target.value) {
      const input = moment(e.target.value)
      const covidStarted = moment("1/1/2020")

      const rules = [
        {
          field: "dateOfBirth",
          value: input.isBetween(moment().subtract(130, "y"), moment()),
          // not in the future, max 130 years in the past.
        },
        {
          field: "sampleCollectionDateTime",
          value: input.isBetween(moment().subtract(7, "d"), moment()),
          // not in the future, max 7 days in the past.
        },
        {
          field: "dateOfSymptomOnset",
          value: input.isBetween(covidStarted, moment()),
          // not in the future, not before 1/1/2020
        },
        {
          field: "vaccinationDate",
          value: input.isBetween(covidStarted, moment()),
          // not in the future, not before 1/1/2020
        },
      ]

      const rule = rules.find((rule) => rule.field == field.name)
      if (rule) {
        const element = elements.current[field.name]
        if (!rule?.value) {
          element.setCustomValidity(`Invalid ${field.label.toLowerCase()}.`)
          return false
        } else {
          element.setCustomValidity("")
          return true
        }
      }
    }
  }

  const validateChange = (e, field) => {
    let valid = true

    // validate email with list of bad emails
    if (field.type === "email") {
      valid = validateEmailChange(e, field)
    }

    if (_.includes(["date", "datetime-local"], field.type)) {
      valid = validateDateChange(e, field)
    }

    return valid
  }

  const handleChange = (e, field) => {
    const target = e.target
    const name = target.name
    let newFormState = {}
    props.setEnabledBin(false)

    if (field.type === "qrcode") {
      e.preventDefault()
      return
    }

    const value = target.type === "checkbox" ? target.checked : target.type === "number" && isFinite(Number(target.value)) ? Number(target.value) : target.value

    newFormState = {
      ...formState,
      [name]: value,
    }

    setFormState(newFormState)
    const newDataState = dataStateFromFormState(newFormState)
    setDataState(newDataState)

    dateValidate(newDataState)
    datetimeValidate(newDataState)
    customValidate(newDataState)
    validateChange(e, field)
    if (props.change) {
      props.change()
    }
  }

  const evaluate = (rule, nullValue) => {
    if (rule === undefined || rule === null) {
      return nullValue || false
    }
    //console.log(rule);
    if (!(rule instanceof Object)) {
      //console.log('rule direct');
      return rule
    }
    for (const property in rule) {
      if (rule.hasOwnProperty(property)) {
        if (rule[property] instanceof Object) {
          if (rule[property].empty === true && formState[property]) {
            console.log("rule object empty false")
            return false
          }
          if (rule[property].empty === false && !formState[property]) {
            console.log("rule object not empty false")
            return false
          }
        } else if (formState[property] !== rule[property]) {
          console.log("rule value false")
          return false
        }
      }
    }
    console.log("rule true")
    return true
  }

  const qrCodeSuccess = (field, value) => {
    // console.log('qr code success');
    // console.log(field);
    // console.log(value);
    if (formState[field.name] !== value) {
      setFormState({
        ...formState,
        [field.name]: value,
      })
    }
  }

  const qrCodeError = (field, error) => {
    // console.log('qr code error ');
    // console.log(field);
    // console.log(error);
    const message = error && error instanceof Object ? error.constructor.name : error
    console.log(message)
    if (alertState[field.name] !== message) {
      setAlertState({
        ...alertState,
        [field.name]: message,
      })
    }
  }

  const customLabel = (label) => {
    if (!label) {
      return label
    }
    const regex = /\{(.+?)\}/
    const match = label.match(regex)
    if (!match) {
      return label
    }
    console.log(match)
    const value = props.customFunctions[match[1]](label, formState)
    return value
  }

  const getLabel = (field) => {
    const baseStyle = field.type === "checkbox" ? { marginLeft: "2rem" } : { display: "block" }
    const style = Object.assign(baseStyle, field.labelStyle)
    if (field.labelHtml) {
      const html = customLabel(field.labelHtml)
      return <Label for={field.name} className={field.labelClassName} style={style} dangerouslySetInnerHTML={{ __html: html }} />
    } else {
      const label = customLabel(field.label)
      return (
        <Label for={field.name} className={field.labelClassName} style={style}>
          {(label ? label : "") + (evaluate(field.required, false) ? " *" : "")}
        </Label>
      )
    }
  }

  const download = (field) => {
    makeApiDownloadCall("get", field.format, formState, "application/pdf", formState[field.name] + ".pdf")
  }

  const formatValue = (format, value) => {
    if (value && format === "toLocalizedDateString") {
      if (value === null || value === undefined) {
        return ""
      }
      return new Date(value).toLocaleString()
    }
    if (value && format === "capitalized") {
      return capitalize(value)
    }
    if (format === "boolean") {
      return value === true ? "Yes" : value === false ? "No" : "Unset"
    }

    return value
  }

  const selectSubject = (data) => {
    if (selectedSubjectIds.includes(data.id)) {
      setSelectedSubjectIds(selectedSubjectIds.filter((item) => item != data.id))
    } else {
      setSelectedSubjectIds((prevState) => [data.id, ...prevState])
    }
  }

  const handleMergeSubjects = async () => {
    setIsMerging(true)
    const result = await makeApiCall("post", "/api/subjects/merge", {
      mainSubjectId: formState.id,
      selectedSubjectIds: selectedSubjectIds,
    })

    setTimeout(() => {
      setIsMerging(false)
    }, 500)

    if (result.ok) {
      props.close()
      window.scrollTo(0, 0)
      props.setAlertState("good")
    } else {
      props.setAlertState("bad")
      window.scrollTo(0, document.body.scrollHeight)
    }

    if (props.setExistingSubjects()) {
      props.setExistingSubjects([])
    }
    handleCloseModal()
  }

  return (
    <div>
      {props.isLoading && (
        <center className="mt-5">
          <ReactLoading type="spin" color="red" height={667} width={100} />
        </center>
      )}
      {!props.isLoading && (
        <Form className="GeneralForm" onSubmit={onSubmit} style={props.style}>
          {props.formDefinition.formLabel ? (
            <Card className="mb-3 rounded-0">
              <CardBody>
                <Row className="QR-code">
                  <Col>
                    <h2>{props.formDefinition.formLabel}</h2>
                    <h3>{props.id}</h3>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          ) : null}
          {sections.map((section, sectionIndex) => {
            console.log("formstate is", formState)
            if (evaluate(section.visible, true)) {
              return (
                <Card key={"section-" + sectionIndex} className="mb-3 rounded-0">
                  {section.label ? (
                    <CardHeader>
                      <CardTitle>{section.label}</CardTitle>
                    </CardHeader>
                  ) : null}
                  <CardBody>
                    <Row>
                      {section.type === "table" ? (
                        <GeneralTable
                          style={{ marginTop: "30px" }}
                          table={section.table}
                          data={getValueByDotNotation(formState, section.name)}
                          firstRowNumber={1}
                        />
                      ) : (
                        getColumns(section).map((column, columnIndex) => (
                          <Col sm={column.width} key={"column-" + columnIndex}>
                            {column.fields.map((field) => {
                              if (evaluate(field.visible, true)) {
                                return (
                                  <FormGroup key={field.name} style={field.groupStyle}>
                                    {field.type !== "checkbox" && field.type !== "radio" && field.type !== "button" ? (
                                      <Label style={{ display: "block" }} for={field.name}>
                                        {field.label + (field.required ? " *" : "")}{" "}
                                      </Label>
                                    ) : null}
                                    {field.type === "textarea" ? (
                                      <textarea
                                        type={field.type}
                                        name={field.name}
                                        id={field.name}
                                        disabled={isDisableForm}
                                        readOnly={false}
                                        required={field.required}
                                        onChange={(e) => handleChange(e, field)}
                                        style={Object.assign({ display: "block" }, field.style)}
                                        placeholder={field.placeholder}
                                        rows={field.rows}
                                        cols={field.cols}
                                        wrap={field.wrap}
                                        value={formState[field.name] || ""}
                                      ></textarea>
                                    ) : null}

                                    {field.type === "timestamp" || (field.type === "datetime-local" && evaluate(field.readonly, false) === true) ? (
                                      <Input
                                        type={"text"}
                                        name={field.name}
                                        id={field.name}
                                        value={formatValue(field.format, formState[field.name]) || ""}
                                        checked={field.type === "checkbox" ? formState[field.name] || false : null}
                                        disabled={isDisableForm || evaluate(field.disabled, false)}
                                        readOnly={true} //evaluate(field.readonly, false)}
                                        required={evaluate(field.required, false)}
                                        pattern={field.pattern}
                                        title={field.title}
                                        //onChange={(e) => handleChange(e, field)}
                                        style={field.style}
                                        placeholder={field.placeholder}
                                        className={field.className}
                                      ></Input>
                                    ) : null}

                                    {field.type !== "textarea" &&
                                    field.type !== "label" &&
                                    field.type !== "radio" &&
                                    field.type !== "timestamp" &&
                                    field.type !== "button" &&
                                    (field.type !== "datetime-local" || (field.type === "datetime-local" && evaluate(field.readonly, false) === false)) ? (
                                      <Input
                                        className={field.type === "email" && isInvalidEmailAddress(formState[field.name]) ? "invalid-input" : ""}
                                        type={field.type}
                                        name={field.name}
                                        id={field.name}
                                        value={
                                          field.type === "checkbox"
                                            ? ""
                                            : formState[field.name] === null || formState[field.name] === undefined
                                            ? ""
                                            : field.format != "toLocalizedDateString"
                                            ? formatValue(field.format, formState[field.name])
                                            : moment(formState[field.name]).format("YYYY-MM-DDTHH:mm") ?? ""
                                        }
                                        checked={field.type === "checkbox" ? formState[field.name] : false}
                                        disabled={field.readonly || isDisableForm}
                                        required={field.required}
                                        pattern={field.pattern}
                                        title={field.title}
                                        onChange={(e) => {
                                          e.persist()
                                          handleChange(e, field)
                                        }}
                                        innerRef={(element) => (elements.current[field.name] = element)}
                                        style={field.type === "checkbox" ? Object.assign({ marginLeft: 0 }, field.style) : field.style}
                                        placeholder={field.placeholder}
                                      >
                                        {field.type === "select"
                                          ? getOptions(field).map((item) => (
                                              <option key={item.key} value={item.key}>
                                                {item.value || item.key}
                                              </option>
                                            ))
                                          : null}
                                      </Input>
                                    ) : null}
                                    {field.type === "checkbox" ? (
                                      <Label for={field.name} disabled={field.readonly || isDisableForm} style={{ marginLeft: "2rem" }}>
                                        {field.label + (field.required ? " *" : "")}
                                      </Label>
                                    ) : null}
                                    {field.type === "radio"
                                      ? getOptions(field).map((item) =>
                                          item.key !== "" ? (
                                            <span key={item.key} style={field.itemStyle && field.itemStyle.span}>
                                              <Input
                                                type="radio"
                                                name={field.name}
                                                id={field.name + "-" + item.key}
                                                value={item.key}
                                                checked={item.key === formState[field.name]}
                                                disabled={field.readonly || isDisableForm}
                                                readOnly={true}
                                                required={evaluate(field.required, false)}
                                                onChange={(e) => handleChange(e, field)}
                                                style={Object.assign(
                                                  Object.assign(
                                                    {
                                                      marginLeft: "10px",
                                                      marginTop: "5px",
                                                    },
                                                    field.itemStyle || {},
                                                  ),
                                                  field.itemStyle.radio || {},
                                                )}
                                                className={field.className}
                                              />
                                              <label htmlFor={field.name + " " + item.key} style={field.itemStyle && field.itemStyle.label}>
                                                {item.value || item.key}
                                              </label>
                                              <br />
                                            </span>
                                          ) : null,
                                        )
                                      : null}
                                    {field.type === "button" ? (
                                      <Button outline color="secondary" type="button" onClick={() => download(field)}>
                                        {getLabel(field)}
                                      </Button>
                                    ) : null}
                                  </FormGroup>
                                )
                              }
                            })}
                          </Col>
                        ))
                      )}
                    </Row>
                  </CardBody>
                </Card>
              )
            }
          })}

          <Row className={"general-form-buttons mt-3"}>
            <Col sm="12">
              <div className="float-right">
                {props.submit ? (
                  <Button
                    type="submit"
                    outline
                    color="primary"
                    style={Object.assign(
                      { marginRight: "20px" },
                      get(() => props.formDefinition.submit.style, {}),
                    )}
                    disabled={evaluate(props.formDefinition.submit.disabled, false)}
                  >
                    {get(() => props.formDefinition.submit.label, "Submit")}
                  </Button>
                ) : null}

                {props.update && !isDisableForm ? (
                  <Button
                    type="submit"
                    outline
                    color="primary"
                    style={Object.assign(
                      { marginRight: "20px" },
                      get(() => props.formDefinition.update.style, {}),
                    )}
                    disabled={evaluate(props.formDefinition?.update?.disabled, false)}
                  >
                    {isSubmitting ? <ReactLoading type="spin" color="white" height={25} width={20} /> : "Update"}
                  </Button>
                ) : null}
                {props.formDefinition.edit && isDisableForm && !formState.taps && (
                  <Button
                    type="submit"
                    outline
                    color="primary"
                    onClick={() => {
                      setIsDisableForm(false)
                      props.setEnabledBin(false)
                    }}
                    style={Object.assign(
                      { marginRight: "20px" },
                      get(() => props.formDefinition.edit.style, {}),
                    )}
                    disabled={evaluate(props.formDefinition.edit.disabled, false)}
                  >
                    {get(() => props.formDefinition.edit.label, "Edit")}
                  </Button>
                )}
                {props.cancel ? (
                  <Button
                    type="button"
                    outline
                    color="secondary"
                    style={get(() => props.formDefinition.cancel.style)}
                    onClick={() => {
                      if (props.cancel) {
                        props.cancel()
                      }
                    }}
                  >
                    {get(() => props.formDefinition.cancel.label, "Cancel")}
                  </Button>
                ) : null}
                {props.findDuplicate && !formState.taps ? (
                  <Button
                    type="button"
                    outline
                    color="primary"
                    style={get(() => props.formDefinition.findDuplicate.style)}
                    onClick={() => {
                      if (props.findDuplicate) {
                        props.findDuplicate(props.formState)
                      }
                    }}
                  >
                    {get(() => props.formDefinition.findDuplicate.label, "Find Duplicate")}
                  </Button>
                ) : null}
              </div>
            </Col>
          </Row>
          {props.existingSubjects && props.existingSubjects.length >= 1 ? (
            <Row>
              <Col sm="12">
                <Modal isOpen={showMergeModal} toggle={handleCloseModal} size="lg">
                  <ModalHeader>Subject Details</ModalHeader>
                  <ModalBody>Merge selected subject{selectedSubjectIds.length > 1 && "s "} into the current subject?</ModalBody>
                  <ModalFooter>
                    <Button type="button" outline color="danger" onClick={handleMergeSubjects}>
                      {isMerging ? <ReactLoading type="spin" color="white" height={25} width={20} /> : "OK"}
                    </Button>
                    <Button type="button" outline color="primary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
                <h4 className="mb-4">Possible Patient Matches</h4>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Patient ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Date of Birth</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>No. of Test</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.existingSubjects.map((record, index) => (
                      <tr key={index} onClick={() => selectSubject(record)}>
                        <td>
                          <input type="radio" value="medium" checked={selectedSubjectIds.includes(record.id)} onChange={() => selectSubject(record)} />
                        </td>
                        <td>{record.id}</td>
                        <td>{record.firstName}</td>
                        <td>{record.lastName}</td>
                        <td>{record.dateOfBirth ? new Date(record.dateOfBirth).toLocaleString() : ""}</td>
                        <td>{record.email}</td>
                        <td>{record.phoneNumber}</td>
                        <td className="text-center">{(record.tests?.netestRecords?.all.length || 0) + (record.tests?.testRecords?.all.length || 0)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="float-right mt-3 mb-5">
                  <Button
                    type="button"
                    outline
                    color="primary"
                    style={get(() => props.formDefinition.merge.style)}
                    disabled={!selectedSubjectIds.length}
                    onClick={() => {
                      handleShowModal()
                    }}
                  >
                    {get(() => props.formDefinition.label, "Merge")}
                  </Button>
                  <Button
                    type="button"
                    outline
                    color="primary"
                    style={get(() => props.formDefinition.cancel.style)}
                    onClick={() => {
                      props.setExistingSubjects([])
                    }}
                  >
                    {get(() => props.formDefinition.cancel.label, "Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          ) : null}
        </Form>
      )}
    </div>
  )
}

GeneralFormTrayNew.propTypes = {
  formDefinition: PropTypes.object.isRequired,
  lookups: PropTypes.array.isRequired,
  formState: PropTypes.object,
  submit: PropTypes.func,
  cancel: PropTypes.func,
  change: PropTypes.func,
}

export default GeneralFormTrayNew
