const propertiesFormDefinition = {
    formLabel: null,
    submit: {
        label: 'Submit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        },
    },
    cancel: {
        label: 'Cancel',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    edit: {
        label: 'Edit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    close: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    sections: [
        {
            label: 'Isolate Properties',
            columns: 1,
            fields: [
                {
                    label: 'Isolation ID',
                    name: 'cDnaId',
                    type: 'text',
                    readOnly: true
                },
                {
                    label: 'Location',
                    name: 'cDnaSampleLocation',
                    type: 'text',
                    disabled: false
                },
                {
                    label: 'Isolation Status',
                    name: 'isolationStatus',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'isolationStatus'
                    },
                    disabled: false
                },
                {
                    label: 'Isolation Concentration',
                    name: 'isolationConcentration',
                    type: 'number',
                    disabled: false
                },
                {
                    label: 'Isolation 260280 Ratio',
                    name: 'isolation260280ratio',
                    type: 'text',
                    disabled: false
                },
                {
                    label: 'Isolation 260230 Ratio',
                    name: 'isolation260230ratio',
                    type: 'text',
                    disabled: false
                },
                {
                    label: 'cDNA',
                    name: 'cDNASample',
                    type: 'button',
                    labelStyle: { marginBottom: '0px'},
                    hidden: true
                }
            ]
        },
    ]
};

export default propertiesFormDefinition;
