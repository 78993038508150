import React from "react"
import Breadcrumb from "./custom/Breadcrumb"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { usePreviousTableQuery, useQuery } from "hooks/query"
import CovidTestDetails from "./custom/CovidTestDetails"
import { useParams } from "react-router"

function TestViewInfo(props) {
  const permission = props && props.applicationState.authentication.user.permission
  const { id } = useParams()

  const previousTableQuery = usePreviousTableQuery("/test-view")

  const breadcrumbItems = [
    {
      label: "Covid Tests",
      link: `/test-view?${previousTableQuery}`,
    },
    {
      label: id,
      link: `/test-view?id=${id}`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title={id} breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardHeader>
          <CardTitle>Covid Test Details</CardTitle>
        </CardHeader>
        <CardBody>
          <CovidTestDetails testId={id} permission={permission} {...props} />
        </CardBody>
      </Card>
    </div>
  )
}

export default TestViewInfo