import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useTraysQuery({ variables, ...rest } = {}) {
  const { query } = useRequestProcessor()

  const fields = [
    "_id",
    "id",
    "testType",
    "maxFill",
    "ownerId",
    "status",
    "id",
    "trayConfigurationId",
    "trayConfigurationLabel",
    "stage",
    "testType",
    "maxFill",
    "ownerId",
    "lotNumber",
    "status",
    "tests",
  ].map((item) => `"${item}"`)

  const params = encodeURIComponent(`[${fields.toString()}]`)

  const newVariables = {
    ...variables,
    selector: {
      ...(variables?.selector || {}),
    },
  }

  if (newVariables?.selector?._id?.$eq) {
    newVariables.selector._id = {
      $regex: "(?i)" + newVariables.selector._id.$eq,
    }
  }

  if (newVariables?.selector?.ownerId?.$eq) {
    newVariables.selector.ownerId = {
      $regex: "(?i)" + newVariables.selector.ownerId.$eq,
    }
  }

  return query(
    ["trays", newVariables],
    () => axiosClient.get(`/trays?${paramsToQuery(newVariables, { allowEmptyObject: true })}&fields=${params}`).then((res) => res.data),
    { ...rest },
  )
}

export function useTrayUsersQuery({ variables, ...rest } = {}) {
  const { query } = useRequestProcessor()

  const fields = [
    "_id",
    "id",
    "testType",
    "maxFill",
    "ownerId",
    "status",
    "id",
    "trayConfigurationId",
    "trayConfigurationLabel",
    "stage",
    "testType",
    "maxFill",
    "ownerId",
    "lotNumber",
    "status",
    "tests",
  ].map((item) => `"${item}"`)

  const params = encodeURIComponent(`[${fields.toString()}]`)

  const newVariables = {
    ...variables,
    selector: {
      ...(variables?.selector || {}),
    },
  }

  return query(
    ["tray-users", newVariables],
    () => axiosClient.get(`/tray/users?${paramsToQuery(newVariables, { allowEmptyObject: true })}&fields=${params}`).then((res) => res.data),
    { ...rest },
  )
}