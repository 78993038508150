import React, { useEffect, useRef, useState } from "react"
import { Button, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table } from "reactstrap"
import { makeApiCall, makeApiDownloadCall } from "../api/generic-api"
import { useAlert } from "react-alert"

import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js"
import { Line } from "react-chartjs-2"
import ReactLoading from "react-loading"
import html2canvas from "html2canvas"
import moment from "moment"
import { v4 as uuidv4 } from "uuid"

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)

export const plugins = [
  {
    id: "backgrounds",
    beforeDraw: (chart, args, options) => {
      const {
        ctx,
        chartArea,
        scales: { y },
      } = chart

      options.hbars.forEach((hBar) => {
        ctx.save()
        ctx.fillStyle = hBar.color
        ctx.fillRect(
          chartArea.left,
          y.getPixelForValue(hBar.from),
          chartArea.right - chartArea.left,
          y.getPixelForValue(hBar.to) - y.getPixelForValue(hBar.from),
        )
        ctx.restore()
      })
    },
  },
]

const ProcessCancerPrcInnerFormNew = (props) => {
  const scoreCategoryTableRef = useRef()
  const progressionScoreCategoryTableRef = useRef()

  const alert = useAlert()
  const { currentInnerRecord, currentRecord } = props

  const [sending, setSending] = useState(false)
  const [sendType, setSendType] = useState("")
  const [emailOtherModal, setEmailOther] = useState(false)
  const [netScoreChartData, setNetScoreChartData] = useState(null)
  const [riskScoreChartData, setSetRiskScoreChartData] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isCommitting, setIsCommitting] = useState(false)
  const [riskScoreOptionsData, setRiskScoreOptionsData] = useState(null)
  const [netScoreOptionsData, setNetScoreOptionsData] = useState(null)
  const [selectedTest, setSelectedTest] = useState(null)
  const [resultsForm, setResultsForm] = useState({
    result: null,
    diseaseStatus: null,
    currentTreatment: null,
    interpretation: null,
    recommendation: null,
    emailOther: null,
  })
  const [isPPQ, setIsPPQ] = useState(false)
  const [isLoadedPPQ, setIsloadedPPQ] = useState(false)
  const [searchingLegacyTests, setSearchingLegacyTests] = useState(false)
  const [legacyTests, setLegacyTests] = useState([])

  const [showClinicalHistoryModal, setShowClinicalHistoryModal] = useState(false)
  const [selectedPatient, setSelectedPatient] = useState(null)
  const [selectedDetails, setSelectedDetails] = useState(null)
  const [selectedSamples, setSelectedSamples] = useState(null)

  const [openRecommit, setOpenRecommit] = useState(false)

  const [subjectId, setSubjectId] = useState(null)
  const [showPatientSamplesModal, setShowPatientSamplesModal] = useState(false)
  const [isLoadingPatientSamples, setIsLoadingPatientSamples] = useState(false)
  const [patientSamples, setPatientSamples] = useState([])
  const [selectedSample, setSelectedSample] = useState(null)

  const [editForm, setEditForm] = useState(false)
  const [isDoneCommitting, setIsDoneCommitting] = useState(false);
  
  useEffect(() => {
    const handleSetTestRecord = async (_id) => {
      if (currentInnerRecord) {
        const test = await getSubjectByTestId(_id, true)
        setSelectedTest(test)
      }
    }

    const testId = currentInnerRecord?.testId || currentInnerRecord?.subId
    if (testId) {
      handleSetTestRecord(testId)
    }
  }, [currentInnerRecord?.testId, currentInnerRecord?.subId])

  const openPatientModal = (record) => {
    setSelectedPatient(record)
    handleShowModal()
  }

  const handleCloseModal = () => setShowClinicalHistoryModal(false)
  const handleShowModal = () => setShowClinicalHistoryModal(true)

  const handleClosePatientSamplesModal = () => setShowPatientSamplesModal(false)
  const handleShowPatientSamplesModal = () => setShowPatientSamplesModal(true)

  const handleCloseRecommit = () => setOpenRecommit(false)

  const getSubjectByTestId = async (testId, searchLegacyTest = false) => {
    setIsLoading(true)
    let currentTest

    const result = await makeApiCall("get", `/api/subject-test/${testId}`)

    if (result.ok) {
      const data = result.body
      const testResult = await makeApiCall("get", `/api/core/test/${testId}`)
      const testType = testResult.body.testType
      const tests = data.tests[`${testType}`]?.all

      currentTest = tests.filter((test) => test.id == testId)[0]
      const currentTestIndex = tests.findIndex((test) => test.id == testId)

      const testsBeforeCurrentTest = tests.slice(0, currentTestIndex + 1)

      if (currentTest && currentTest.testResult) {
        setResultsForm((prevState) => ({
          ...prevState,
          result: currentTest.testResult.result || null,
          diseaseStatus: currentTest.testResult.diseaseStatus || null,
          currentTreatment: currentTest.testResult.currentTreatment || null,
          interpretation: currentTest.testResult.interpretation || null,
          recommendation: currentTest.testResult.recommendation || null,
        }))

        setNetScoreChart(testsBeforeCurrentTest)
        setRiskScoreChart(testsBeforeCurrentTest)
        setNetScoreOptions(currentTest)
        setRiskScoreOptions(currentTest)
        setSubjectId(data.id)
      } else {
        alert.show("There was a problem with this PCR upload. Please try uploading it again.", {
          type: "error",
        })
      }

      if (searchLegacyTest) {
        await searchLegacyTests(data)
      }
    } else {
      const detail = `Details: code ${result.statusCode}; ${result.error}`
      alert.show(detail, {
        type: "error",
      })
    }

    setIsLoading(false)
    return currentTest
  }

  const viewPatientSamples = async () => {
    if (subjectId) {
      try {
        handleShowPatientSamplesModal()
        setIsLoadingPatientSamples(true)
        const result = await makeApiCall("get", `/api/subject-samples/${subjectId}`)
        setIsLoadingPatientSamples(false)
        setPatientSamples(result?.body || [])
      } catch (e) {
        console.error(e)
        setPatientSamples([])
        setIsLoadingPatientSamples(false)
      }
    } else {
      return
    }
  }

  const getImportRequisitionBySampleId = async (sampleId) => {
    if (sampleId) {
      try {
  
        setSelectedSample(sampleId)
        const result = await makeApiCall("get", `/api/sample-requisition/${sampleId}`)
        let id = result?.body[0]?.id
        id = id.replace(/\./g, "_")
        
        const url = `/requisition-netest/${id}?status=accepted`
        console.log("opening window: " + url)
        window.open(url)
        setSelectedSample(null)
      } catch (e) {
        console.error(e)
      }
    } else {
      return
    }
  }

  const searchLegacyTests = async (data) => {
    if (!data) {
      setSearchingLegacyTests(false)
    }

    setSearchingLegacyTests(true)
    const query = {
      dateOfBirth: data.dateOfBirth,
    }

    if (data.firstName) {
      query.patientFirstName = {
        $regex: "^(?i)" + data.firstName + "$",
      }
    }

    if (data.lastName) {
      query.patientLastName = {
        $regex: "^(?i)" + data.lastName + "$",
      }
    }

    const request = await makeApiCall("get", "/api/legacy/netest", query)
    if (request.ok) {
      setLegacyTests(request.body.searchResult)
    } else {
      console.log("something went wrong searching for legacy tests")
    }

    setSearchingLegacyTests(false)
  }

  const commitResults = async () => {
    setIsCommitting(true)
    const testId = selectedTest.id

    const scoreCategoryTableBase64 = await exportAsImage(scoreCategoryTableRef.current)
    let progressionScoreCategoryTableBase64
    if (progressionScoreCategoryTableRef.current) {
      progressionScoreCategoryTableBase64 = await exportAsImage(progressionScoreCategoryTableRef.current)
    }
    // const progressionScoreCategoryTableBase64 = await exportAsImage(progressionScoreCategoryTableRef.current);
    console.log("base64 score", scoreCategoryTableBase64)
    console.log("base64 progress", progressionScoreCategoryTableBase64)
    // scoreCategoryTableBase64 = await exportAsImage(scoreCategoryTableRef.current);
    // progressionScoreCategoryTableBase64 = await exportAsImage(progressionScoreCategoryTableRef.current);

    resultsForm.scoreCategoryTableBase64 = scoreCategoryTableBase64
    if (progressionScoreCategoryTableBase64) {
      resultsForm.progressionScoreCategoryTableBase64 = progressionScoreCategoryTableBase64
    }

    const transactionHistory = {
      id: await uuidv4(),
      timestamp: new Date().toISOString(),
      user: props.applicationState.authentication.user,
      type: "commit-results",
      transaction: {
        data: {
          id: testId,
          field: "-",
          value: "-",
          state: null,
        },
        action: {
          descShort: "commit-results",
          desc: "Commit Results",
        },
      },
      transactionId: testId,
      transactionField: "-",
      transactionValue: "-",
      subjectId: selectedTest.subjectId,
    }

    if (scoreCategoryTableBase64 || progressionScoreCategoryTableBase64) {
      const result = await makeApiCall("put", `/api/test/${testId}/commit`, { ...resultsForm, transactionHistory: [transactionHistory] })
      if (result.ok) {
        // await getSubjectByTestId(testId)
        await handleSendOutput("pdf-generation", false, scoreCategoryTableBase64, progressionScoreCategoryTableBase64)
        alert.show("Results have successfully committed", {
          type: "success",
        })
        setOpenRecommit(false)
      } else {
        const detail = `Details: code ${result.statusCode}; ${result.error}`
        alert.show(detail, {
          type: "error",
        })
      }
    } else {
      alert.show("Something went wrong generating chart data. Try again", {
        type: "error",
      })
    }

    const updateRequest = await makeApiCall("get", `/api/test-result/${encodeURI(testId)}`, null)
    if (updateRequest.ok) {
      const testResult = updateRequest.body.testResult
      setResultsForm((prevState) => ({
        ...prevState,
        result: testResult.result || null,
        diseaseStatus: testResult.diseaseStatus || null,
        currentTreatment: testResult.currentTreatment || null,
        interpretation: testResult.interpretation || null,
        recommendation: testResult.recommendation || null,
      }))
    } else {
      console.log("something went wrong searching for legacy tests")
    }

    
    setIsDoneCommitting(true)
    setIsCommitting(false)
    setEditForm(false)
  }

  const getSafe = (fn, defaultVal) => {
    try {
      return fn()
    } catch (e) {
      return defaultVal
    }
  }

  const setNetScoreChart = (tests) => {
    const sortedTests = tests
      .filter(
        (test) => getSafe(() => test["sampleCollectionDateTime"], null) !== null, // Ensure data exists
      )
      .sort((a, b) => {
        const dateA = moment(getSafe(() => a["sampleCollectionDateTime"], ""))
        const dateB = moment(getSafe(() => b["sampleCollectionDateTime"], ""))
        return dateA.diff(dateB)
      })

    let dataPoints = sortedTests.map((item) => getSafe(() => item.testResult["scoreDisplayValue"], null)).filter((item) => item !== null && item !== undefined)

    let dataLabels = sortedTests
      .map((item) => {
        const scoreDisplayValue = getSafe(() => item.testResult["scoreDisplayValue"], null)
        if (scoreDisplayValue) {
          return moment(getSafe(() => item["sampleCollectionDateTime"], "")).format("MM/DD/yyyy")
        }
        return null
      })
      .filter((item) => item !== null && item !== undefined)

    const data = {
      labels: ["", ...dataLabels, ""],
      datasets: [
        {
          // label: '# of Votes',
          fill: false,
          backgroundColor: "black",
          borderColor: "black",
          borderWidth: "0.95",
          pointRadius: 6,
          pointHoverRadius: 6,
          data: [NaN, ...dataPoints, NaN],
        },
      ],
    }

    setNetScoreChartData(data)
  }

  const setRiskScoreChart = (tests) => {
    const sortedTests = tests
      .filter(
        (test) => getSafe(() => test["sampleCollectionDateTime"], null) !== null, // Ensure data exists
      )
      .sort((a, b) => {
        const dateA = moment(getSafe(() => a["sampleCollectionDateTime"], ""))
        const dateB = moment(getSafe(() => b["sampleCollectionDateTime"], ""))
        return dateA.diff(dateB)
      })

    let dataPoints = sortedTests
      .map((item) => getSafe(() => item.testResult["progressionScoreDisplayValue"], null))
      .filter((item) => item !== null && item !== undefined)

    let dataLabels = sortedTests
      .map((item) => {
        const progressionScoreDisplayValue = getSafe(() => item.testResult["progressionScoreDisplayValue"], null)
        if (progressionScoreDisplayValue) {
          return moment(getSafe(() => item["sampleCollectionDateTime"], "")).format("MM/DD/yyyy")
        }
        return null
      })
      .filter((item) => item !== null && item !== undefined)

    const data = {
      labels: ["", ...dataLabels, ""],
      datasets: [
        {
          // label: '# of Votes',
          fill: false,
          backgroundColor: "black",
          borderColor: "black",
          borderWidth: "0.95",
          pointRadius: 6,
          pointHoverRadius: 6,
          data: [NaN, ...dataPoints, NaN],
        },
      ],
    }

    setSetRiskScoreChartData(data)
  }

  const setNetScoreOptions = (test) => {
    const testConfig = test.testConfig
    const scoreCategory = testConfig.scoreCategory

    const scoreName = testConfig.scoreName
    // const interpretation = testConfig.interpretation;
    // const label = testConfig.label;
    // const result = testConfig.result;

    const colors = ["#b3c6e9", "#ebb2b2"]
    const stepSize = scoreCategory[0].end - scoreCategory[0].start

    const hbars = scoreCategory.map((x, index) => {
      return {
        from: x.start,
        to: x.end,
        color: x.color || colors[index],
      }
    })

    const data = {
      plugins: {
        backgrounds: {
          hbars: hbars,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 45,
            minRotation: 45,
          },
        },
        y: {
          grid: {
            display: false,
          },
          max: 100,
          min: 0,
          beginAtZero: true,
          ticks: {
            stepSize: stepSize,
          },
          title: {
            display: true,
            text: scoreName || "NET SCORE",
            font: {
              size: 15,
            },
          },
        },
      },
      responsive: true,
    }

    setNetScoreOptionsData(data)
  }

  const setRiskScoreOptions = (test) => {
    const progressionScoreCategory = test.testConfig.progressionScoreCategory
    const colors = ["#b2e8b4", "#b3c6e9", "#ebc9b2", "#ebb2b2"]

    const stepSize = progressionScoreCategory[0].end - progressionScoreCategory[0].start

    const hbars = progressionScoreCategory.map((x, index) => {
      return {
        from: x.start,
        to: x.end,
        color: x.color || colors[index],
      }
    })

    const data = {
      plugins: {
        backgrounds: {
          hbars: hbars,
        },
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false,
          },
          ticks: {
            maxRotation: 45,
            minRotation: 45,
          },
        },
        y: {
          grid: {
            display: false,
          },
          max: 100,
          min: 0,
          beginAtZero: true,
          ticks: {
            stepSize: stepSize,
          },
          title: {
            display: true,
            text: "PROGRESSION RISK SCORE",
            font: {
              size: 15,
            },
          },
        },
      },
      responsive: true,
      // maintainAspectRatio: false
    }

    setRiskScoreOptionsData(data)
  }

  const handleResultsFormChange = (e) => {
    const name = e.target.name
    const value = e.target.value

    setResultsForm((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSendOutput = async (_sendType, showAlert = true, scoreCategoryTableBase64, progressionScoreCategoryTableBase64) => {
    setSending(true)
    setSendType(_sendType)

    const testId = selectedTest.id
    selectedTest.outputType = _sendType.split("-")[0]

    if (scoreCategoryTableBase64) {
      selectedTest.testResult.scoreCategoryTableBase64 = scoreCategoryTableBase64
    }

    if (progressionScoreCategoryTableBase64) {
      selectedTest.testResult.progressionScoreCategoryTableBase64 = progressionScoreCategoryTableBase64
    }

    selectedTest.emailOther = resultsForm.emailOther || ""

    if (emailOtherModal === true && (!resultsForm.emailOther || resultsForm.emailOther === "")) {
      const detail = `Please enter email address`
      showAlert &&
        alert.show(detail, {
          type: "error",
        })
    } else {
      const transactionHistory = {
        id: await uuidv4(),
        timestamp: new Date().toISOString(),
        user: props.applicationState.authentication.user,
        type: "send-test",
        transaction: {
          data: {
            id: testId,
            field: _sendType,
            value: "-",
            state: null,
          },
          action: {
            descShort: "send-test",
            desc: _sendType == "pdf-generation" ? "Pdf Generation" : "Send test",
          },
        },
        transactionId: testId,
        transactionField: _sendType,
        transactionValue: "-",
        subjectId: selectedTest.subjectId,
      }

      const result = await makeApiCall("post", `/api/test/${testId}/send/${_sendType}`, { ...selectedTest, transactionHistory: [transactionHistory] })
      if (result.ok) {
        showAlert &&
          alert.show("Results have successfully sent", {
            type: "success",
          })
        setEmailOther(false)
      } else {
        const detail = `Details: code ${result.statusCode}; ${result.error}`
        showAlert &&
          alert.show(detail, {
            type: "error",
          })
      }
    }
    setSending(false)
  }

  const sendToPrint = async (_sendType, showAlert = true) => {
    const fileName = currentInnerRecord.testId || currentInnerRecord.subId + "-" + _sendType + ".pdf"

    const result = await makeApiCall("post", `/api/netest/${_sendType}`, {
      data: {
        fileName: fileName,
        data: currentInnerRecord,
      },
    })

    if (result.ok) {
      showAlert &&
        alert.show("Successfully generated file and queued for printing", {
          type: "success",
        })
    } else {
      const detail = result.error
      showAlert &&
        alert.show(detail, {
          type: "error",
        })
    }
  }

  const exportAsImage = async (element, imageFileName) => {
    const canvas = await html2canvas(element)

    // returns base64 image
    return canvas.toDataURL("image/png", 1.0)
  }

  const downloadPdf = async () => {
    if (selectedTest.testResult && !selectedTest.testResult.progressionScoreCategoryTableBase64 && !selectedTest.testResult.scoreCategoryTableBase64) {
      setOpenRecommit(true)
    } else {
      if (currentInnerRecord) {
        let fileName = currentInnerRecord.testId || currentInnerRecord.subId
        fileName = fileName + ".pdf"
        if (fileName) {
          try {
            const verb = "get"
            const uri = `/api/document/test/${fileName}`

            const data = await makeApiDownloadCall(verb, uri, "", "application/pdf", fileName)
            if (!data.ok) {
              alert.show(`Error in downloading file: ${data.error}`),
                {
                  type: "error",
                }
            } else {
              alert.show("File successfuly downloaded", {
                type: "success",
              })
            }
          } catch (e) {
            alert.show(`Error in download action: ${e}`, {
              type: "error",
            })
          }
        }
      } else {
        alert.show(`Unable to fetch current record`, {
          type: "error",
        })
      }
    }
  }

  const editPdf = async () => {
    setEditForm(true)
  }

  const getPPQRequest = async () => {
    setIsloadedPPQ(false)
    const testId = currentInnerRecord?.testId ? currentInnerRecord?.testId : currentInnerRecord?.subId
    const ppqData = await makeApiCall("get", `/api/ppqData/${encodeURIComponent(testId)}`)
    if (ppqData.ok) {
      setIsPPQ(ppqData.body.isPPQ || ppqData.body.netestPPQ)
    }
    setIsloadedPPQ(true)
  }

  useEffect(() => {
    getPPQRequest()
  }, [isPPQ])

  return (
    <div>
      <Modal isOpen={showClinicalHistoryModal} toggle={handleCloseModal} size={selectedSamples || selectedDetails ? "xl" : "md"}>
        <ModalHeader>Clinical History</ModalHeader>
        <ModalBody>
          <Row style={{ marginTop: "30px" }}>
            <Col sm={selectedSamples || selectedDetails ? "6" : "12"}>
              {!!selectedPatient && (
                <Table responsive hover>
                  <thead style={{ backgroundColor: "#b3c6e9" }}>
                    <tr>
                      <th>Date</th>
                      <th>View</th>
                    </tr>
                  </thead>

                  <tbody>
                    {selectedPatient.clinicalHistory.map((history, index) => {
                      return (
                        <tr key={index}>
                          <td>{history.dateFormFilledOut}</td>
                          <td>
                            <div className="d-flex gap-2">
                              <button
                                className="btn btn-outline btn-outline-primary"
                                onClick={() => {
                                  setSelectedDetails(history)
                                  setSelectedSamples(null)
                                }}
                              >
                                Details
                              </button>
                              <button
                                className="btn btn-outline btn-outline-warning"
                                style={{ color: "black" }}
                                onClick={() => {
                                  setSelectedDetails(null)
                                  setSelectedSamples(history?.sample)
                                }}
                              >
                                Samples
                              </button>
                            </div>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              )}
            </Col>
            <Col sm="6">
              {selectedSamples &&
                selectedSamples.map((sample, index) => (
                  <Table key={index} responsive hover className={index > 0 ? "mt-5" : ""}>
                    <thead style={{ backgroundColor: "#ebb2b2" }}>
                      <th>Field</th>
                      <th>Value</th>
                    </thead>
                    <tbody>
                      {Object.keys(sample).map((key, ind) => (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>{sample[key]}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                ))}

              {selectedDetails && (
                <Table responsive hover>
                  <thead style={{ backgroundColor: "#ebb2b2" }}>
                    <th>Field</th>
                    <th>Value</th>
                  </thead>
                  <tbody>
                    {selectedDetails &&
                      Object.keys(selectedDetails).map((key) => {
                        if (key !== "sample") {
                          return (
                            <tr key={key}>
                              <td>{key}</td>
                              <td>{selectedDetails[key]}</td>
                            </tr>
                          )
                        }
                      })}
                  </tbody>
                </Table>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button type="button" outline onClick={handleCloseModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={openRecommit} toggle={handleCloseRecommit} size="md">
        <ModalHeader>Warning!</ModalHeader>
        <ModalBody>
          <Label>There was a problem with committing this sample. Press the "Recommit" button to resolve the issue.</Label>
          <Label>Please wait a few minutes after recommitting to download the PDF.</Label>
        </ModalBody>
        <ModalFooter>
          <Button type="button" color="success" onClick={commitResults}>
            Recommit
          </Button>
          <Button type="button" outline onClick={handleCloseRecommit}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={showPatientSamplesModal} toggle={handleClosePatientSamplesModal} size="lg">
        <ModalHeader>Medical History</ModalHeader>
        <ModalBody>
          {isLoadingPatientSamples && (
            <center>
              <Spinner type="grow" />
            </center>
          )}

          {!isLoadingPatientSamples && patientSamples.length && (
            <Row>
              <Col>
                <div>
                  <Table responsive hover>
                    <thead>
                      <tr>
                        <th>Samples</th>
                        <th>Collected</th>
                        <th>Accessioned</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {patientSamples.length ? (
                        patientSamples.map((record, index) => (
                          <tr key={index}>
                            <td>{record.id}</td>
                            <td>
                              {new Date(record?.sampleCollectionDateTime).toLocaleString("en-US", {
                                timeZone: "UTC",
                                month: "2-digit",
                                year: "numeric",
                                day: "2-digit",
                              })}
                            </td>
                            <td>
                              {new Date(record?.laboratoryReceivedDateTime).toLocaleString("en-US", {
                                timeZone: "UTC",
                                month: "2-digit",
                                year: "numeric",
                                day: "2-digit",
                              })}
                            </td>
                            {record.type == "netest" ? (
                              <td>
                                <Button outline color="secondary" onClick={() => getImportRequisitionBySampleId(record.id)}>
                                  {selectedSample == record.id ? (
                                    <center className="px-4 mb-2">
                                      <Spinner size="sm" />
                                    </center>
                                  ) : (
                                    <span>
                                      <i className="fa fa-external-link" /> &nbsp; View
                                    </span>
                                  )}
                                </Button>
                              </td>
                            ) : (
                              <td>&nbsp;</td>
                            )}
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={4} className="my-4 font-italic">
                            No samples found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button type="button" outline onClick={handleClosePatientSamplesModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {isLoading && (
        <center>
          <Spinner type="grow" />
        </center>
      )}

      {!isLoading && selectedTest && netScoreChartData && riskScoreChartData && (
        <div>
          <Row className="mb-5">
            {selectedTest.testConfig.hideProgressionCategory ||
            selectedTest.testConfig.scoreCategory[0].label?.toLowerCase() == selectedTest.testResult.scoreCategory?.toLowerCase() ? (
              <div className="col-6 mx-auto">
                <div ref={scoreCategoryTableRef}>
                  <h3 className="text-center">
                    {selectedTest.testConfig.scoreName || "NETest score"}: <span className="text-danger">{selectedTest.testResult.scoreCategory}</span>
                  </h3>
                  <div className="text-center text-uppercase">
                    {selectedTest.testConfig.hideProgressionCategory ? selectedTest.testResult.scoreInterpretation || "" : ""}
                  </div>
                  <Line options={netScoreOptionsData} data={netScoreChartData} plugins={plugins} />
                </div>
              </div>
            ) : (
              <>
                <div className="col-6 col-auto">
                  <div ref={scoreCategoryTableRef}>
                    <h3 className="text-center">
                      {selectedTest.testConfig.scoreName || "NETest score"}: <span className="text-danger">{selectedTest.testResult.scoreCategory}</span>
                    </h3>
                    <div className="text-center text-uppercase">
                      {selectedTest.testConfig.hideProgressionCategory ? selectedTest.testResult.scoreInterpretation || "" : ""}{" "}
                    </div>
                    <Line options={netScoreOptionsData} data={netScoreChartData} plugins={plugins} />
                  </div>
                </div>
                <Col lg="6">
                  <div ref={progressionScoreCategoryTableRef}>
                    <h3 className="text-center">
                      PROGRESSION SCORE: <span className="text-danger">{selectedTest.testResult.progressionScoreCategory}</span>
                    </h3>
                    <Line options={riskScoreOptionsData} data={riskScoreChartData} plugins={plugins} />
                  </div>
                </Col>
              </>
            )}
          </Row>

          <div>
            <div className="mb-4">
              <h5>Previous Netest Data</h5>
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Date of Birth</th>
                    <th>Submitted</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {!searchingLegacyTests && legacyTests.length ? (
                    legacyTests.map((record, index) => (
                      <tr key={index}>
                        <td>{record.patientFirstName}</td>
                        <td>{record.patientLastName}</td>
                        <td>{record.dateOfBirth}</td>
                        <td>{record.registrationDate ? new Date(record.registrationDate).toLocaleString() : ""}</td>
                        <td>
                          <button
                            className="btn btn-outline btn-outline-primary"
                            onClick={() => {
                              setSelectedDetails(null)
                              setSelectedSamples(null)
                              openPatientModal(record)
                            }}
                          >
                            View
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan={4} className="my-4 font-italic">
                        No legacy results or list possible matches
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>

            {isLoadedPPQ ? (
              <div className="mb-4">
                <h5>
                  Requested PPQ: <em>{isPPQ ? "True" : "False"}</em>
                </h5>
              </div>
            ) : null}

            <div className="mb-4">
              <div>
                <Button onClick={viewPatientSamples} color="secondary">
                  Medical History
                </Button>
              </div>
              {/*<Col sm="3" item>*/}
              {/*	{!editForm ?*/}
              {/*		<>*/}
              {/*			{!selectedTest.testResult.laboratoryCompletedDateTime && !currentRecord?.testing ?*/}
              {/*				<div className="mt-1 mb-3">*/}
              {/*					<Button outline color="primary" onClick={commitResults}>*/}
              {/*						{ isCommitting ?*/}
              {/*							<ReactLoading type='spin' color='blue' height={25} width={15} /> : 'Commit Results' }*/}
              {/*					</Button>*/}
              {/*				</div>*/}
              {/*				:*/}
              {/*				<div className="mt-1 mb-3">*/}
              {/*					<Button onClick={downloadPdf} outline color="primary">Document</Button>{"  "}*/}
              {/*					<Button onClick={editPdf} outline color="secondary">Edit</Button>*/}
              {/*				</div>*/}
              {/*			}*/}
              {/*		</>*/}
              {/*		:*/}
              {/*		<div className="mt-1 mb-3">*/}
              {/*			<Button outline color="primary" onClick={commitResults}>*/}
              {/*				{ isCommitting ?*/}
              {/*					<ReactLoading type='spin' color='blue' height={25} width={15} /> : 'Commit Results' }*/}
              {/*			</Button>*/}
              {/*		</div>*/}
              {/*	}*/}
              {/*</Col>*/}
            </div>

            {/*<div className="mt-2">*/}
            {/*	RESULT*/}
            {/*	/!*selectedTest.testResult.laboratoryCompletedDateTime*!/*/}
            {/*	<textarea maxlength="100" disabled={!editForm ? !selectedTest.testResult.laboratoryCompletedDateTime && !currentRecord?.testing ? false: true : false} value={resultsForm.result || ""} name="result" className="mt-1" placeholder="Result" style={{ width: "100%", minHeight: "50px"}} onChange={handleResultsFormChange}/>*/}
            {/*	<div className="text-right" style={{ marginTop: "-5px", fontSize: "10px"}}>{ resultsForm?.result?.length || 0 }/100</div>*/}
            {/*</div>*/}
            {/*<div className="mt-2">*/}
            {/*	DISEASE STATUS*/}
            {/*	<textarea maxlength="200" disabled={!editForm ? !selectedTest.testResult.laboratoryCompletedDateTime && !currentRecord?.testing ? false: true : false} value={resultsForm.diseaseStatus || ""} name="diseaseStatus" className="mt-1" placeholder="Disease Status" style={{ width: "100%", minHeight: "50px"}} onChange={handleResultsFormChange}/>*/}
            {/*	<div className="text-right" style={{ marginTop: "-5px", fontSize: "10px"}}>{ resultsForm?.diseaseStatus?.length || 0 }/200</div>*/}
            {/*</div>*/}
            {/*<div className="mt-2">*/}
            {/*	CURRENT TREATMENT*/}
            {/*	<textarea maxlength="200" disabled={!editForm ? !selectedTest.testResult.laboratoryCompletedDateTime && !currentRecord?.testing ? false: true : false} value={resultsForm.currentTreatment || ""} name="currentTreatment" className="mt-1" placeholder="Current Treatment" style={{ width: "100%", minHeight: "50px"}} onChange={handleResultsFormChange}/>*/}
            {/*	<div className="text-right" style={{ marginTop: "-5px", fontSize: "10px"}}>{ resultsForm?.currentTreatment?.length || 0 }/200</div>*/}
            {/*</div>*/}
            {/*<div className="mt-2">*/}
            {/*	INTERPRETATION*/}
            {/*	<textarea maxlength="200" disabled={!editForm ? !selectedTest.testResult.laboratoryCompletedDateTime && !currentRecord?.testing ? false: true : false} value={resultsForm.interpretation || ""} name="interpretation" className="mt-1" placeholder="Interpretation" style={{ width: "100%", minHeight: "50px"}} onChange={handleResultsFormChange}/>*/}
            {/*	<div className="text-right" style={{ marginTop: "-5px", fontSize: "10px"}}>{ resultsForm?.interpretation?.length || 0 }/200</div>*/}
            {/*</div>*/}
            {/*<div className="mt-2">*/}
            {/*	RECOMMENDATION*/}
            {/*	<textarea maxlength="200" disabled={!editForm ? !selectedTest.testResult.laboratoryCompletedDateTime && !currentRecord?.testing ? false: true : false} value={resultsForm.recommendation || ""} name="recommendation" className="mt-1" placeholder="Recommendation" style={{ width: "100%", minHeight: "50px"}} onChange={handleResultsFormChange}/>*/}
            {/*	<div className="text-right" style={{ marginTop: "-5px", fontSize: "10px"}}>{ resultsForm?.recommendation?.length || 0 }/200</div>*/}
            {/*</div>*/}

            {selectedTest.testResult.scoreCategory && selectedTest.testResult.scoreDisplayValue && (
              <div className="mb-4">
                {selectedTest.testResult.scoreResult ? (
                  <h5>{selectedTest.testResult.scoreResult}.</h5>
                ) : (
                  <h5>
                    The {selectedTest.testConfig.scoreName || "NETest score"} is <b>{selectedTest.testResult.scoreDisplayValue}</b>, it is{" "}
                    <b>{selectedTest.testResult.scoreCategory}.</b>
                  </h5>
                )}

                {!selectedTest.testConfig.hideProgressionCategory &&
                  selectedTest.testResult.progressionScoreCategory &&
                  selectedTest.testResult.progressionScoreDisplayValue &&
                  selectedTest.testConfig.scoreCategory[0].label?.toLowerCase() != selectedTest.testResult.scoreCategory?.toLowerCase() && (
                    <h5>
                      The Progression score is <b>{selectedTest.testResult.progressionScoreDisplayValue}</b>, it is{" "}
                      <b>{selectedTest.testResult.progressionScoreCategory}.</b>
                    </h5>
                  )}

                {selectedTest.testConfig.hideProgressionCategory && selectedTest.testResult.scoreInterpretation ? (
                  <h5>{`${selectedTest.testResult.scoreInterpretation}.` || ""}</h5>
                ) : (
                  <div></div>
                )}
              </div>
            )}

            {!editForm ? (
              <>
                {!selectedTest.testResult.laboratoryCompletedDateTime && !currentRecord?.testing && !isDoneCommitting ? (
                  <div className="mb-4">
                    <Button color="danger" onClick={commitResults}>
                      {isCommitting && <Spinner size="sm" />} Commit Results
                    </Button>
                  </div>
                ) : (
                  <div className="mb-4 d-flex gap-2">
                    <Button onClick={downloadPdf} color="primary">
                      <icon className="fa fa-download" /> Document
                    </Button>
                    <Button onClick={editPdf} color="danger">
                      <icon className="fa fa-edit" /> Edit
                    </Button>
                  </div>
                )}
              </>
            ) : (
              <div className="mb-4">
                <Button color="danger" onClick={commitResults}>
                  {isCommitting && <Spinner size="sm" />} Commit Results
                </Button>
              </div>
            )}

            {/*{ editForm == true || (!selectedTest.testResult.laboratoryCompletedDateTime && !currentRecord?.testing) &&*/}
            {/*	<div className="mt-3">*/}
            {/*		<Button outline color="primary" onClick={commitResults}>*/}
            {/*			{ isCommitting ?*/}
            {/*				<ReactLoading type='spin' color='blue' height={25} width={15} /> : 'Commit Results' }*/}
            {/*			*/}
            {/*		</Button>*/}
            {/*	</div>*/}
            {/*}*/}
            {/*{ selectedTest.testResult.laboratoryCompletedDateTime &&*/}
            {/*	<div className="mt-3">*/}
            {/*		<Button onClick={downloadPdf} outline color="secondary">Document</Button>{"  "}*/}
            {/*		{!editForm ? <Button onClick={editPdf} outline color="secondary">Edit</Button>: null}*/}
            {/*	</div>*/}
            {/*}*/}
          </div>

          <div className="mb-4">
            {(selectedTest.testResult.laboratoryCompletedDateTime || isDoneCommitting) && (
              <div className="d-flex gap-2">
                <Button
                  outline
                  color="primary"
                  onClick={async () => await handleSendOutput("email-patient")}
                  disabled={sending && sendType === "email-patient"}
                >
                  {sending && sendType === "email-patient" && <Spinner size="sm" />} Email Patient
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={async () => await handleSendOutput("email-physician")}
                  disabled={sending && sendType === "email-physician"}
                >
                  {sending && sendType === "email-physician" && <Spinner size="sm" />} Email Physician
                </Button>
                <Button outline color="primary" onClick={() => setEmailOther(!emailOtherModal)}>
                  Email Other
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={async () => await handleSendOutput("letter-patient")}
                  disabled={sending && sendType === "letter-patient"}
                >
                  {sending && sendType === "letter-patient" && <Spinner size="sm" />} Letter to Patient
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={async () => await handleSendOutput("letter-physician")}
                  disabled={sending && sendType === "letter-physician"}
                >
                  {sending && sendType === "letter-physician" && <Spinner size="sm" />} Letter to Physician
                </Button>
              </div>
            )}
          </div>

          <div>
            {selectedTest.testResult && (
              <Table responsive hover>
                <thead>
                  <tr>
                    <th>Result</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(selectedTest.testResult).map((key) => {
                    if (
                      key !== "testConfig" &&
                      key !== "cancer" &&
                      key !== "result" &&
                      key !== "scoreCategoryTableBase64" &&
                      key !== "progressionScoreCategoryTableBase64" &&
                      key !== "transactionHistory" &&
                      key !== "resultsForm"
                    ) {
                      return (
                        <tr key={key}>
                          <td>{key}</td>
                          <td>
                            {selectedTest.testResult[key] === true ? "True" : selectedTest.testResult[key] === false ? "False" : selectedTest.testResult[key]}
                          </td>
                        </tr>
                      )
                    }
                  })}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      )}

      <Modal isOpen={emailOtherModal} size="md">
        <ModalHeader>Enter other email address</ModalHeader>
        <ModalBody>
          <Input name="emailOther" onChange={(e) => handleResultsFormChange(e)}></Input>
        </ModalBody>

        <ModalFooter>
          <Button outline color="primary" onClick={async () => await handleSendOutput("email-other")} disabled={sending && sendType === "email-other"}>
            {sending && sendType === "email-other" && <Spinner size="sm" />} Send Email
          </Button>
          <Button outline color="secondary" onClick={() => setEmailOther(false)} disabled={sending && sendType === "email-other"}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

ProcessCancerPrcInnerFormNew.propTypes = {}

export default ProcessCancerPrcInnerFormNew
