import React from "react";
import Breadcrumb from "../custom/Breadcrumb";
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap";
import { usePreviousTableQuery } from "hooks/query";
import NotificationDetails from "../custom/NotificationDetails";
import { useParams, useLocation } from "react-router";

const MAX_LENGTH = 20;

const NotificationViewInfo = (props) => {
    const permission = props?.applicationState?.authentication?.user?.permission || null;
    const { id } = useParams();
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const title = queryParams.get("title") || "Notification";

    const truncateText = (text) => {
        return text.length > MAX_LENGTH ? `${text.substring(0, MAX_LENGTH)}...` : text;
    };

    const truncatedTitle = truncateText(title);

    const previousTableQuery = usePreviousTableQuery("/notifications");

    const breadcrumbItems = [
        {
            label: "Announcement",
            link: `/notifications?${previousTableQuery}`,
        },
        {
            label: truncatedTitle,
            link: `/notification?id=${id}`,
        },
    ];

    return (
        <div className="wide-container">
            <Breadcrumb title="Announcement" breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Announcement Details</CardTitle>
                </CardHeader>
                <CardBody>
                    <NotificationDetails id={id} permission={permission} />
                </CardBody>
            </Card>
        </div>
    );
};

export default NotificationViewInfo;
