import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import ProductForm from "../components/custom/ProductForm"
import { usePreviousTableQuery } from "hooks/query"

function ProductFormView(props) {
    const previousQuery = usePreviousTableQuery("/products-view")

    const breadcrumbItems = [
        {
            label: "Products",
            link: `/products-view?${previousQuery}`,
        },
        {
            label: "Product Form",
            link: `/products-view-form`,
        },

    ]

    return (
        <div className="wide-container">
            <Breadcrumb title="Products" breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Product Form</CardTitle>
                </CardHeader>
                <CardBody>
                    <ProductForm />
                </CardBody>
            </Card>
        </div>
    )
}

export default ProductFormView
