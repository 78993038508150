

const getValueByDotNotation = (obj, path) => {
    try {
        return new Function('_', 'return _.' + path)(obj);
    } catch(e) {
        return null;
    }
};
// or get nested property:
//const name = ((user-service || {}).personalInfo || {}).name;

const setValueByDotNotation = (obj, path, value) => {
    var schema = obj;  // a moving reference to internal objects within obj
    var pList = path.split('.');
    var len = pList.length;
    for(var i = 0; i < len-1; i++) {
        var elem = pList[i];

        if( !schema[elem] ) schema[elem] = {}
        schema = schema[elem];
    }

    schema[pList[len-1]] = value;
};

// safe get returns value or default value
const get = (func, defaultValue) => {
    if (!isFunction(func)) {
        return (func === null || func === undefined) ? defaultValue: func;
    }

    try {
        var value = func();
        return (value === null || value === undefined) ? defaultValue : value;
    } catch (e) {
        return defaultValue;
    }
};

// safe call returns true for success false for fail
const call = (func) => {
    try {
        func();
        return true;
    } catch (e) {
        return false;
    }
};

// returns the given function or a new function that returns the given value
const func = (g) => {
    const f = get(g);
    if (isFunction(f)) {
        return f;
    } else {
        return () => f;
    }
};

const uuid = () => {
    var d = new Date().getTime();
    if (typeof performance !== 'undefined' && typeof performance.now === 'function') {
        d += performance.now(); //use high-precision timer if available
    }
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
};

const isFunction = f => {
    return Object.prototype.toString.call(f) === '[object Function]';
};

const pipe = (...functions) => input => functions.reduce((chain, func) => chain.then(func), Promise.resolve(input));

const isEmpty = (str) => {
    return (!str || 0 === str.length);
};

const isBlank = (str) => {
    return (!str || /^\s*$/.test(str));
};

const getServiceStatusRank = serviceStatus => {
    switch(serviceStatus) {
        case 'serviceStatusStopped':
            return 5;
        case 'serviceStatusStoppedByPrimary':
            return 4;
        case 'serviceStatusSuspended':
            return 3;
        case 'serviceStatusWarning':
            return 2;
        case 'serviceStatusOk':
            return 1;
        default:
            return 0;
    }
};

const getServiceStatus = (userOrServices, serviceType, forDisplay) => {

    const userServices = userOrServices && (userOrServices._id || userOrServices.id || userOrServices.userid) ? userOrServices.services : userOrServices;
    //console.log('get service status for ' + serviceType + ' ' + forDisplay);
    //console.log(userServices);
    let result = null;

    if (serviceType) {
        if (!userServices) {
            result = 'serviceStatusAbsent';
        } else {
            const service = userServices[serviceType];
            if(!service) {
                result = 'serviceStatusAbsent';
            } else {
                if(service.stopped) {
                    result = 'serviceStatusStopped';
                } else if(service.stoppedByPrimary) {
                    result = 'serviceStatusStoppedByPrimary';
                } else if((service.directPaymentSuspended || service.invoicePaymentSuspended || service.legalSuspended) && !service.overrideSuspension) {
                    result = 'serviceStatusSuspended';
                } else if(service.directPaymentDeadline || service.legalDeadline) {
                    result = 'serviceStatusWarning';
                } else {
                    result = 'serviceStatusOk';
                }
            }
        }
    }
    //console.log('service status is ' + result);
    if(result && forDisplay) {
        result = result.replace('serviceStatus', '');
    }
    // console.log('off status: ' + ['serviceStatusAbsent', 'serviceStatusStoppedByPrimary'].includes(result));
    // console.log('on status: ' + !['serviceStatusAbsent', 'serviceStatusStoppedByPrimary'].includes(result));
    return result;
};

const getServiceProperty = (userOrServices, serviceType, property) => {
    const userServices = userOrServices && (userOrServices._id || userOrServices.id || userOrServices.userid) ? userOrServices.services : userOrServices;
    let result = null;
    if (serviceType) {
        result = userServices[serviceType][property];
    }
    //console.log('result!');
    //console.log(result);
    if(result === null || result === false || result === undefined || result === '') {
        return 'No';
    }
    if(result === true) {
        return 'Yes';
    }
    return result;
};

const dataToQuery = (data, noQuestion) => {
    if (!data || !(data instanceof Object)) {
        return '';
    }
    let query = '';
    for (var prop in data) {
        if (data.hasOwnProperty(prop)) {
            if(query !== '') {
                query += '&';
            }
            query += encodeURIComponent(prop) + '=' + encodeURIComponent(data[prop]);
        }
    }
    if (query !== '' && !noQuestion) {
        query = '?' + query;
    }
    return query;
};

const replaceAllCaseInsensitive = (content, strReplace, strWith) => {
    var esc = strReplace.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');
    return content.replace(reg, strWith);
};

const objectsIdentical = (o1, o2) => {
    if(o1 === o2) {
        return true;
    }
    if(!o1 || (typeof o1 !== 'object')) {
        return false;
    }
    if (!o2 || (typeof o2 !== 'object')) {
        return false;
    }

    return JSON.stringify(o1) === JSON.stringify(o2);
};

// curry :: ((a, b, ...) -> c) -> a -> b -> ... -> c
function curry(fn) {
    const arity = fn.length;

    return function $curry(...args) {
        if (args.length < arity) {
            return $curry.bind(null, ...args);
        }

        return fn.call(null, ...args);
    };
}

module.exports = {
    uuid: uuid,
    getValueByDotNotation: getValueByDotNotation,
    setValueByDotNotation: setValueByDotNotation,
    isFunction: isFunction,
    pipe: pipe,
    get: get,
    func: func,
    call: call,
    isEmpty: isEmpty,
    isBlank: isBlank,
    getServiceStatus: getServiceStatus,
    getServiceStatusRank: getServiceStatusRank,
    dataToQuery: dataToQuery,
    getServiceProperty: getServiceProperty,
    replaceAllCaseInsensitive: replaceAllCaseInsensitive,
    objectsIdentical,
    curry
};
