import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Form, Row, Col, Label, Spinner, Button, FormFeedback, Alert, Table } from "reactstrap"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { Link } from "react-router-dom"
import { useOrderDetailsQuery, useTestsQuery } from "api/getters"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { useUpdateOrder } from "api/mutators"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

const schema = Yup.object({
  totalPrice: Yup.string().required("Please enter price"),
})

const OrderDetails = ({ id, productId, permission, ...rest }) => {
  const { data: currentOrder, isLoading, remove } = useOrderDetailsQuery({ variables: { _id: id }, enabled: Boolean(id) })

  const [editing, setEditing] = useState(false)
  const [successMessage, setSuccessMessage] = useState("")

  const { data: testsData, isLoading: isLoadingTests } = useTestsQuery({
    enabled: Boolean(currentOrder?.subjectId),
    variables: {
      selector: {
        subjectId: {
          $eq: currentOrder?.subjectId,
        },
      },
    },
  })

  const data = useMemo(() => {
    if (currentOrder?.sampleId && testsData?.length) {
      const filteredTests = testsData?.filter((record) => record.id.includes(currentOrder?.sampleId))
      return filteredTests.map((test) => {
        const products =
          currentOrder.products &&
          currentOrder.products
            .filter((product) => (productId ? product.id === productId : true))
            .map((product) => {
              // if (test.testType?.toLowerCase() == product.testTypes?.toLowerCase()) {
              //   return product
              // }

              // if (test.testType == "covid-thermo-fisher" && product.testTypes == "Covid Thermo Fisher") {
              //   return product
              // }
              return product
            })

        return {
          product: products[0]?.productName,
          productGroup: products[0]?.productGroup,
          item: products[0]?.testTypes,
          completed: test.laboratoryCompletedDateTime && test.reportingDateTime && (test?.testResult?.scoreCategory || test.testResult) ? "Y" : "N",
          sampleId: currentOrder.sampleId,
          testId: test.testId,
          reportedAt: test.reportingDateTime,
          analysedAt: test.laboratoryCompletedDateTime,
          result: test?.testResult?.scoreCategory || test.testResult,
          status: products[0]?.status,
          testType: test.testType,
        }
      })
    }
  }, [testsData, productId, currentOrder])

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      totalPrice: "",
      feeCode: "",
      billed: false,
      paid: false,
    },
  })

  const {
    mutate,
    isLoading: mutating,
    isError,
    error: updateOrderError,
  } = useUpdateOrder({
    onSuccess: () => {
      setSuccessMessage("Successfully Updated")
      setEditing(false)
      reset(getValues())
    },
  })

  const handleReset = useCallback(
    (_data) => {
      reset({
        totalPrice: _data?.totalPrice,
        feeCode: _data?.feeCode,
        billed: _data?.billed,
        paid: _data?.paid,
      })
    },
    [reset],
  )

  useEffect(() => {
    return () => {
      remove()
    }
  }, [])

  useEffect(() => {
    if (currentOrder?.id) {
      handleReset(currentOrder)
    }
  }, [currentOrder])

  const onSubmit = handleSubmit(async (data) => {
    const formValues = {
      billed: data.billed,
      feeCode: data.feeCode || null,
      orderCreatedTimeStamp: currentOrder?.orderCreatedTimeStamp,
      paid: data.paid,
      // payerType: currentOrder.payerType,
      price: data.totalPrice,
      // product: currentOrder?.products?.map((item) => item.productName),
      // subjectData: currentOrder?.subjectData,
      subjectId: currentOrder?.subjectId,
      _id: id,
      // "subjectData.address": null,
      // "subjectData.dateOfBirth": null,
      // "subjectData.email": null,
      // "subjectData.firstName": null,
      // "subjectData.lastName": null,
      // "subjectData.phoneNumber": null,
    }

    try {
      await mutate(formValues)
    } catch (err) {
      console.error({ err })
    }
  })

  const hasRole = (role) => {
    const roles = rest.applicationState.authentication.user?.roles
    return roles && roles.length && roles.includes(role)
  }

  if (isLoading) {
    return <Spinner type="grow" />
  }

  return (
    <Form onSubmit={onSubmit}>
      {successMessage ? <Alert color="success">{successMessage}</Alert> : null}
      {isError && (
        <Alert color="warning" role="alert">
          {updateOrderError?.response?.data?.reason || updateOrderError?.response?.data?.message || updateOrderError?.message}
        </Alert>
      )}
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Patient ID</Label>
            <Link to={`/subject-info/${currentOrder.subjectId}/personal`} className="text-secondary" target="_blank">
              <input readOnly type="text" className="form-control cursor-pointer" value={currentOrder?.subjectId || ""} />
            </Link>
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Email</Label>
            <input readOnly type="text" className="form-control" value={currentOrder?.subjectData?.email || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">First name</Label>
            <input readOnly type="text" className="form-control" value={currentOrder?.subjectData?.firstName || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Last name</Label>
            <input readOnly type="text" className="form-control" value={currentOrder?.subjectData?.lastName || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Date of Birth</Label>
            <input readOnly type="date" className="form-control" value={currentOrder?.subjectData?.dateOfBirth} />
          </div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Order ID</Label>
            <input readOnly type="text" className="form-control" value={currentOrder?.id || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="form-label formrow-text-Input">Price</Label>
            <input
              disabled={!editing}
              type="number"
              className={`form-control ${Boolean(errors?.totalPrice?.message) ? "is-invalid" : ""}`}
              {...register("totalPrice")}
            />
            {Boolean(errors?.totalPrice?.message) && <FormFeedback type="invalid">{errors?.totalPrice.message}</FormFeedback>}
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Date</Label>
            <input
              readOnly
              type="datetime-local"
              className="form-control"
              value={currentOrder?.orderCreatedTimeStamp ? dayjs(currentOrder.orderCreatedTimeStamp).format("YYYY-MM-DDTHH:mm") : ""}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Fee Code</Label>
            <input disabled={!editing} type="text" className="form-control" {...register("feeCode")} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label>Product Name(s)</Label>
            <textarea
              readOnly
              className="form-control"
              rows="3"
              placeholder="Enter Product Name"
              value={currentOrder?.products?.map((item) => item.productName)?.toString() || ""}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label>Payer Type</Label>
            <input type="text" className="form-control" value={currentOrder?.payerType || ""} readOnly />
          </div>
        </Col>
        <Col className="mb-3">
          <div className="form-check mb-3">
            <input type="checkbox" className={`form-check-input`} id="formrow-paid" disabled={!editing} {...register("paid")} />
            <label className="form-check-label" htmlFor="formrow-paid">
              Paid
            </label>
          </div>
          <div className="form-check mb">
            <input type="checkbox" className={`form-check-input`} id="formrow-billed" disabled={!editing} {...register("billed")} />
            <label className="form-check-label" htmlFor="formrow-billed">
              Billed
            </label>
          </div>
        </Col>
        {(permission === "administrator" || hasRole("billing")) && (
          <div className="d-flex gap-3 mt-4">
            {editing && (
              <Button
                key="cancel"
                outline
                type="button"
                disabled={mutating}
                onClick={() => {
                  setEditing(false)
                  handleReset(currentOrder)
                }}
              >
                Cancel
              </Button>
            )}
            {editing ? (
              <Button key="submit" color="danger" type="submit" disabled={mutating || !isDirty}>
                {mutating && <Spinner size="sm" />} Update Order
              </Button>
            ) : (
              <Button
                key="edit"
                color="danger"
                type="button"
                onClick={() => {
                  setEditing(true)
                  setSuccessMessage("")
                }}
              >
                Edit Order
              </Button>
            )}
          </div>
        )}
      </Row>

      <hr />
      <h4>Products</h4>
      <Row>
        <Table responsive hover style={{ fontSize: "14px" }}>
          <thead>
            <tr>
              <th>Product</th>
              <th>Item</th>
              <th>Completed</th>
              <th>Sample ID</th>
              <th>Test ID</th>
              <th>Analysed Date</th>
              <th>Status</th>
              <th>Result</th>
              <th>Reported Date</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((record, index) => (
              <tr key={index}>
                <td>{record.product}</td>
                <td>{record.item}</td>
                <td>{record.completed}</td>
                <td>
                  <Link to={`/sample-view/${record.sampleId}`} className="text-secondary">
                    {record.sampleId}
                  </Link>
                </td>
                <td>
                  <Link
                    to={record.productGroup === "cancer" ? `/cancer-tests-view/${record.testId}` : `/test-view/${record.testId}`}
                    className="text-secondary"
                  >
                    {record.testId}
                  </Link>
                </td>
                <td>{record.analysedAt ? dayjs(record.analysedAt).format("MM/DD/YYYY") : "-"}</td>
                <td>{record.status}</td>
                <td>{record.result}</td>
                <td>{record.reportedAt ? dayjs(record.reportedAt).format("MM/DD/YYYY") : "-"}</td>
              </tr>
            ))}
          </tbody>
        </Table>
        {isLoadingTests && (
          <center>
            <Spinner type="grow" />
          </center>
        )}
      </Row>
    </Form>
  )
}

export default OrderDetails
