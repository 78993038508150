import React from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { usePreviousTableQuery } from "hooks/query"
import Breadcrumb from "./custom/Breadcrumb"
import SampleDetails from "./custom/SampleDetails"
import { useParams } from "react-router"

function SampleViewInfo(props) {
  const { id } = useParams()
  const previousTableQuery = usePreviousTableQuery("/sample-view")

  const breadcrumbItems = [
    {
      label: "Samples",
      link: `/sample-view?${previousTableQuery}`,
    },
    {
      label: id,
      link: `/sample-view/${id}`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title={id} breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardHeader>
          <CardTitle>Sample Details</CardTitle>
        </CardHeader>
        <CardBody>
          <SampleDetails id={id} />
        </CardBody>
      </Card>
    </div>
  )
}

export default SampleViewInfo
