import React, { useState, useEffect } from "react"
import { Button, Label, Row, Col, Dropdown, DropdownToggle, Spinner, DropdownMenu, DropdownItem, Alert } from "reactstrap"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"
import { makeApiCall } from "api/generic-api"
import { usePriceFormSubmit } from "api/getters"
import lookups from "../../configuration/lookups"

const schema = Yup.object().shape({
  productId: Yup.string().required("Product ID is required"),
  payerType: Yup.string().required("Payer Type is required"),
  price: Yup.number().typeError("Price must be a number").required("Price is required").moreThan(0, "Please enter a valid price"),
})

const PriceForm = ({ item }) => {
  const [configurations, setConfigurations] = useState({
    test: [],
    payerType: [],
    lookups: [],
  })
  const [loadingStates, setLoadingStates] = useState({
    isLoading: false,
    isSubmitted: false,
  })
  const [messages, setMessages] = useState({
    error: "",
    success: "",
  })
  const [dropdownStates, setDropdownStates] = useState({
    product: false,
    payer: false,
  })
  const [selected, setSelected] = useState({
    lookup: null,
    payerType: null,
  })

  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      productId: item?.productId || "",
      payerType: item?.payerType || "",
      feeCode: item?.feeCode || "",
      price: item?.price || "",
    },
  })

  const { mutate, isLoading: mutating } = usePriceFormSubmit({
    onSuccess: () => {
      setMessages({
        ...messages,
        success: "Price has been successfully added.",
        error: "",
      })
      setLoadingStates({ ...loadingStates, isSubmitted: true })
    },
    onError: (error) => {
      const apiErrorMessage = error?.response?.data?.error
      setMessages({
        ...messages,
        error: apiErrorMessage || "An unexpected error occurred.",
      })
    },
  })

  const toggleDropdown = (type) => {
    setDropdownStates((prev) => ({ ...prev, [type]: !prev[type] }))
  }

  const handleSelectionChange = (type, value) => {
    setSelected((prev) => ({ ...prev, [type]: value }))
    if (type === "lookup") {
      setValue("productId", value._id)
      clearErrors("productId")
    } else if (type === "payerType") {
      setValue("payerType", value.value)
      clearErrors("payerType")
    }
  }

  const fetchConfigurations = async () => {
    setLoadingStates({ ...loadingStates, isLoading: true })
    try {
      const [products, payerTypes] = await Promise.all([makeApiCall("get", "/api/products"), makeApiCall("get", "/api/prices")])
      setConfigurations({
        test: products.body,
        payerType: payerTypes.body,
        lookups: products.body.map((product) => ({
          _id: product.id,
          value: product.productName,
        })),
      })
    } catch (error) {
      const errorMessage = error?.response?.data?.error || "Failed to fetch configurations"
      setMessages({ ...messages, error: errorMessage })
    } finally {
      setLoadingStates({ ...loadingStates, isLoading: false })
    }
  }

  useEffect(() => {
    fetchConfigurations()
  }, [])

  const onSubmit = (data) => {
    const formValues = {
      productId: data.productId,
      payerType: data.payerType.toLowerCase(),
      feeCode: data.feeCode,
      price: data.price,
    }
    mutate(formValues)
  }

  if (loadingStates.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner size="sm" />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Row>
        {messages.error && (
          <Alert color="danger" className="mt-3">
            {messages.error}
          </Alert>
        )}
        {messages.success && (
          <Alert color="success" className="mt-3">
            {messages.success}
          </Alert>
        )}

        <Col className="mb-3" lg="6">
          <Label className="form-label">Product ID *</Label>
          <Controller
            name="productId"
            control={control}
            render={({ field }) => (
              <Dropdown isOpen={dropdownStates.product} toggle={() => toggleDropdown("product")}>
                <DropdownToggle
                  className={`form-control text-start px-3 ${errors.productId ? "is-invalid" : ""}`}
                  disabled={loadingStates.isSubmitted}
                  style={{
                    backgroundColor: "transparent",
                    border: errors.productId ? "1px solid #dc3545" : "1px solid #ccc",
                    color: "#000",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  caret={!errors.productId}
                >
                  {selected.lookup ? selected.lookup.value : "Select Product"}
                </DropdownToggle>
                <DropdownMenu className="mt-2" style={{ backgroundColor: "white" }}>
                  {configurations.lookups.map((item) => (
                    <DropdownItem key={item._id} onClick={() => handleSelectionChange("lookup", item)}>
                      {item.value}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            )}
          />

          {errors.productId && (
            <p className="text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.productId.message}
            </p>
          )}
        </Col>
        <Col className="mb-3" lg="6">
          <Label className="form-label">Payer Type *</Label>
          <Controller
            name="payerType"
            control={control}
            render={({ field }) => (
              <Dropdown isOpen={dropdownStates.payer} toggle={() => toggleDropdown("payer")}>
                <DropdownToggle
                  className={`form-control text-start px-3 ${errors.payerType ? "is-invalid" : ""}`}
                  disabled={loadingStates.isSubmitted}
                  style={{
                    backgroundColor: "transparent",
                    border: errors.payerType ? "1px solid #dc3545" : "1px solid #ccc",
                    color: "#000",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                  caret={!errors.payerType}
                >
                  {selected.payerType ? selected.payerType.value : "Select Payer Type"}
                </DropdownToggle>
                <DropdownMenu className="mt-2" style={{ backgroundColor: "white" }}>
                  {lookups
                    .filter((item) => item.type === "payerType")
                    .map((item) => (
                      <DropdownItem key={item._id} onClick={() => handleSelectionChange("payerType", item)}>
                        {item.value}
                      </DropdownItem>
                    ))}
                </DropdownMenu>
              </Dropdown>
            )}
          />
          {errors.payerType && (
            <p className="text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.payerType.message}
            </p>
          )}
        </Col>
        <Col className="mb-3" lg="6">
          <Label className="form-label">Fee Code</Label>
          <Controller
            name="feeCode"
            control={control}
            render={({ field }) => (
              <input
                type="text"
                className={`form-control ${errors.feeCode ? "is-invalid" : ""}`}
                {...field}
                disabled={loadingStates.isSubmitted}
                placeholder="Enter Fee Code"
              />
            )}
          />
          {errors.feeCode && <div className="invalid-feedback">{errors.feeCode.message}</div>}
        </Col>

        <Col className="mb-3" lg="6">
          <Label className="form-label">Price *</Label>
          <Controller
            name="price"
            control={control}
            render={({ field }) => (
              <input type="number" className={`form-control ${errors.price ? "is-invalid" : ""}`} {...field} placeholder="Enter Price" min="0" />
            )}
          />
          {errors.price && <div className="invalid-feedback">{errors.price.message}</div>}
        </Col>

        <Col className="text-end">
          <Button color="primary" type="submit" disabled={loadingStates.isSubmitted}>
            {mutating ? <Spinner size="sm" /> : "Submit"}
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export default PriceForm
