import "./general-form.css"
import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
} from "reactstrap"
import { get, getValueByDotNotation } from "../utility/client-utility"
import GeneralTable from "./general-table"
import { makeApiCall, makeApiDownloadCall } from "../api/generic-api"

import _, { debounce } from "lodash"
import { matcher } from "matcher"
import { MultiSelect } from "react-multi-select-component"

import * as moment from "moment"
import ReactLoading from "react-loading"
import { useAlert } from "react-alert"
import { useNavigate } from "react-router"
import { useParams } from "react-router-dom"
import { stringQueryToData } from "../utility/uri-utility";
const { countries } = require("countries-list")

function GeneralForm(props) {
  const navigate = useNavigate()
  const { subjectId } = useParams()
  const alert = useAlert()
  
  const urlParams = new URLSearchParams(window.location.search);
  const urlParamsString = decodeURIComponent(urlParams.toString());
  const dataQuery = stringQueryToData(urlParamsString);;

  const { currentRecord } = props
  const elements = useRef({})
  // const sections = get(() => props.formDefinition.sections, []);
  const [sections, setSections] = useState([])

  const allFields = sections?.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.fields)
  }, [])
  const dateFields = allFields?.filter((field) => field.type === "date")
  const datetimeFields = allFields?.filter((field) => field.type === "datetime-local")
  const permission = props && props.applicationState.authentication.user.permission

  const [isDisableForm, setIsDisableForm] = useState(true)
  const [isEditForm, setIsEditForm] = useState(false)
  const [showMergeModal, setShowMergeModal] = useState(false)
  const [submitType, setSubmitType] = useState(null)
  const [othersDisabled, setOthersDisabled] = useState(true)
  const [isMerging, setIsMerging] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [viewTestResult, setViewTestResult] = useState(false)
  const topCountries = ["US", "GB", "NL"]
  const addressField = ["state", "zipCode", "postalCode", "region", "billingState", "billingZipCode", "billingRegion", "billingPostalCode"]
  const reasonsForTesting = [
    "isPreSurgery",
    "isPostSurgery",
    "postSurgeryMonths",
    "isMonitoringWaiting",
    "isMonitoringAnalog",
    "lastSSADate",
    "isSymptomsNotConfirmed",
    "isMonitoringOther",
    "isChemo",
    "isTargetTherapy",
    "isPRRT",
    "isOther",
  ]
  const [invalidInput, setInvalidInput] = useState(null)

  let product

  const [selected, setSelected] = useState(() => {
    const multipleSelectFields = allFields.filter((field) => field.type == "multiple-select")
    const data = {}

    multipleSelectFields.map((field) => {
      if (props.formState) {
        data[field.name] = props.formState[field.name]
      } else {
        data[field.name] = []
      }
    })

    return data
  })
  const [admin, setAdmin] = useState(false)

  const handleCloseModal = () => setShowMergeModal(false)
  const handleShowModal = () => setShowMergeModal(true)
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([])
  const badEmailAddresses = props.applicationState.badEmailAddresses

  const capitalize = (value) => {
    return value && value.replace ? value.replace(/^\w/, (c) => c.toUpperCase()) : value
  }

  useEffect(() => {
    setFormState((prev) => ({
      ...prev,
      price: props.price,
    }))
  }, [props.price])

  const getCustomValidationFields = (sections) => {
    let customValidationFields = []
    for (let s = 0; s < sections?.length; s++) {
      const fields = get(() => sections[s].fields, [])
      const validationFields = fields.filter((field) => field.validation instanceof Object)
      if (validationFields.length) {
        customValidationFields = customValidationFields.concat(validationFields)
      }
    }
    return customValidationFields
  }
  const customValidationFields = getCustomValidationFields(sections)

  useEffect(() => {
    const formState = getInitialState()

    // fetch questions
    if (props?.formState?.products) {
      // getQuestions('cancer');
      setProducts(props.formState.products);
      setProductGroup(true);
    }
    setFormState(formState)
    setDataState(formState)
    setAnswersData(formState?.answers || [])
    isAdmin()
  }, [props.formState])

  const isAdmin = () => {
    if (permission === "administrator") {
      setAdmin(true)
    } else {
      setAdmin(false)
    }
  }

  const getInitialState = () => {
    const initialFormState = {}
    for (let s = 0; s < sections?.length; s++) {
      const fields = get(() => sections[s].fields, [])
      for (let f = 0; f < fields.length; f++) {
        if (fields[f].defaultValue !== undefined && fields[f].defaultValue !== null) {
          initialFormState[fields[f].name] = fields[f].defaultValue
        } else if (fields[f].type === "checkbox") {
          initialFormState[fields[f].name] = false
        } else if (fields[f].type === "switch") {
          initialFormState[fields[f].name] = false
        } else {
          initialFormState[fields[f].name] = null
        }
      }
    }

    return Object.assign(initialFormState, props.formState)
  }

  const [products, setProducts] = useState([])
  const [formState, setFormState] = useState(getInitialState())
  const [dataState, setDataState] = useState(getInitialState());
  const [cancerProducts, setCancerProducts] = useState([])
  const [questionsData, setQuestionsData] = useState(formState.answers ? formState.answers : [])
  const [productGroup, setProductGroup] = useState(null)
  const [answersData, setAnswersData] = useState([])
  const [selectedProduct, setSelectedProduct] = useState(null)

  useEffect(() => {
    if (formState.billTo == "personal") {
      // todo: update this to 3 when insurance is available
      setSections(props.formDefinition.sections)
    } else if (formState.billTo == "client") {
      // todo: update this to 4 insurance is available
      // 3 == 'self pay (please fill out credit card information below, or indicate your billing address if different from above)',
      setSections(props.formDefinition.sections)
      setSections((prevState) => prevState?.filter((item, index) => index != 3))
    } else if (formState.billTo == "insurance") {
      // todo: update this to 4 when insurance is available
      // 4 == 'self pay (please fill out credit card information below, or indicate your billing address if different from above)',
      setSections(props.formDefinition.sections)
      setSections((prevState) => prevState?.filter((item, index) => index != 4))
    } else {
      // todo: update this to 3, 4 when insurance is available
      // 3 == 'self pay (please fill out credit card information below, or indicate your billing address if different from above)',
      setSections(props.formDefinition.sections)
      setSections((prevState) => prevState?.filter((item, index) => index != 3))
    }
  }, [formState.billTo])

  useEffect(() => {
    if (!sections?.length && props?.formDefinition?.sections) {
      if (formState.billTo == "personal") {
        // todo: update this to 3 when insurance is available
        setSections(props.formDefinition.sections)
      } else if (formState.billTo == "client") {
        // todo: update this to 4 insurance is available
        // 3 == 'self pay (please fill out credit card information below, or indicate your billing address if different from above)',
        setSections(props.formDefinition.sections)
        setSections((prevState) => prevState?.filter((item, index) => index != 3))
      } else if (formState.billTo == "insurance") {
        // todo: update this to 4 when insurance is available
        // 4 == 'self pay (please fill out credit card information below, or indicate your billing address if different from above)',
        setSections(props.formDefinition.sections)
        setSections((prevState) => prevState?.filter((item, index) => index != 4))
      } else {
        // todo: update this to 3, 4 when insurance is available
        // 3 == 'self pay (please fill out credit card information below, or indicate your billing address if different from above)',
        setSections(props.formDefinition.sections)
        setSections((prevState) => prevState?.filter((item, index) => index != 3))
      }
    }
  }, [sections])

  const getRequisitionDefinitions = async (testGroup) => {
    const verb = "get"
    const uri = `/api/requisitions/definitions`

    try {
      const response = await makeApiCall(verb, uri)
      const requisitions = response.body.requisitions
      const data = requisitions.filter((x) => x.testGroup == testGroup)

      if (data) {
        setQuestionsData(data)
        setCancerProducts(data[0]?.relevantData?.products)
      } else {
        alert.show("There are no available questions from requisition configuration.", {
          type: "error",
        })
        setQuestionsData([])
      }
    } catch (e) {
      alert.show(`There was a problem getting questions data: ${e}`, {
        type: "error",
      })
    }
  }

  const getAllCountries = () => {
    const customCountries = [
      { code: "US", name: "United States" },
      { code: "GB", name: "United Kingdom" },
      { code: "NL", name: "Netherlands" },
    ]

    const countryOptions = customCountries.map((country) => ({
      key: country.code,
      value: country.name,
    }))

    for (const code in countries) {
      if (!customCountries.some((c) => c.code === code)) {
        countryOptions.push({
          key: code,
          value: countries[code].name,
        })
      }
    }

    return countryOptions
  }

  const getColumns = (section) => {
    const fields = get(() => section.fields, [])
    const numberOfFields = fields.length
    const numberOfColumns = get(() => section.columns, 1)
    const maxFieldsPerColumn = Math.ceil(numberOfFields / numberOfColumns)
    let columnWidth = "6"
    switch (numberOfColumns) {
      case 1:
        columnWidth = "12"
        break
      case 2:
        columnWidth = "6"
        break
      case 3:
        columnWidth = "4"
        break
      default:
        throw new Error("Unhandled column format")
    }

    const columns = []
    for (let fieldIndex = 0; fieldIndex < numberOfFields; fieldIndex++) {
      const columnIndex = Math.floor(fieldIndex / maxFieldsPerColumn)
      if (columnIndex >= columns.length) {
        const column = {
          width: columnWidth,
          fields: [],
        }
        columns.push(column)
      }
      columns[columnIndex].fields.push(fields[fieldIndex])
    }

    return columns
  }

  const getOptions = (field, noEmpty) => {
    const empty = noEmpty
      ? []
      : [
          {
            key: "",
            value: "",
          },
        ]
    if (!field || !field.source || !field.source.kind || !field.source.data) {
      return empty
    }

    if (field.source.kind === "direct") {
      const directResult = empty.concat(field.source.data)
      return directResult
    }

    if (field.source.kind === "lookup") {
      const lookupResult = empty.concat(props.lookups.filter((s) => s.type === field.source.data))
      return lookupResult
    }

    if (field.source.kind === "prop" || field.source.kind === "props") {
      const lookupResult = empty.concat(props[field.source.data])
      return lookupResult
    }

    if (field.source.kind === "request") {
      const data = props.formSource.find((x) => x.name == field.name).data
      return data.map((config) => ({
        key: config.id,
        value: config.name,
      }))
    }

    if (field.source.kind === "list") {
      let countryResult = getAllCountries()
      countryResult = empty.concat(countryResult)
      return countryResult
    }

    if (field.source.kind === "extrasConfig") {
      const data = props.formSource.find((x) => x.name == field.name).data
      return data.map((config) => ({
        key: config.id,
        value: config.extraName,
      }))
    }

    return empty
  }

  const getStateForNetest = () => {
    const empty = [
      {
        key: "",
        value: "",
      },
    ]
    if (currentRecord && formState && currentRecord.prefix) {
      const stateName = currentRecord.state
      const lookupResult = props.lookups.filter((s) => s.type === "state" && (s.key === stateName || s.value === stateName))
      const newFormState = {
        ...formState,
        state: lookupResult[0]?.key,
      }
      setFormState(newFormState)
      // return lookupResult;
    }
  }

  const containsReasons = () => {
    const trueValues = []
    let hasReasons

    for (const key of reasonsForTesting) {
      if (formState.hasOwnProperty(key) && formState[key]) {
        trueValues.push(key)
      }
    }

    if (trueValues.length > 0) {
      hasReasons = true
    } else {
      hasReasons = false
    }
    return hasReasons
  }

  const validateState = () => {
    const selectedReason = containsReasons()

    if (invalidInput) {
      alert.show("Please check Credit Card Expiry Date", {
        type: "error",
      })
      return null
    }

    if (selectedReason === false) {
      alert.show("Please select at least one reason for testing", {
        type: "error",
      })
      return null
    }

    if (formState.providerEmailDelivery && !formState.providerEmail) {
      alert.show("Provider email is required if Email Delivery is selected", {
        type: "error",
      })
      return null
    }

    if (formState.providerFaxDelivery && !formState.providerFaxNo) {
      alert.show("Provider Fax No. is required if Fax Delivery is selected", {
        type: "error",
      })
      return null
    }

    if (!formState.providerFaxDelivery && !formState.providerEmailDelivery) {
      alert.show("Please select at least one provider delivery option", {
        type: "error",
      })
      return null
    }

    const hasSelectedCancerProduct = formState?.products?.some((product) => {
      return cancerProducts.some((obj) => {
        return obj["id"] == product && obj["productGroup"] == "cancer"
      })
    })

    if (!hasSelectedCancerProduct) {
      alert.show("Please select one or more test(s) to perform to proceed", {
        type: "error",
      })
      return null
    }

    if (formState.providerFaxDelivery && !formState.providerFaxNo === true) {
      alert.show(`Provider fax number is required.`, {
        type: "error",
      })
      return null
    }

    if (!formState.providerFaxDelivery && !formState.providerEmailDelivery) {
      alert.show(`Please select at least one provider delivery option`, {
        type: "error",
      })
      return null
    }

    if ((formState.isPreSurgery || formState.isPreSurgery === true) && (formState.isPostSurgery || formState.isPostSurgery === true)) {
      alert.show(`Selecting both Pre-Surgery and Post-Surgery is not allowed. Please only select one.`, {
        type: "error",
      })
      return null
    }

    if (formState.postSurgeryMonths && (!formState.isPostSurgery || formState.isPostSurgery === false)) {
      alert.show(`Please select 'Post-surgery' if you've entered a value in the 'Months (if Post-surgery)' field."`, {
        type: "error",
      })
      return null
    }

    if ((!formState.postSurgeryMonths || formState.postSurgeryMonths === "") && (formState.isPostSurgery || formState.isPostSurgery === true)) {
      alert.show(`Please enter a value in the 'Months (if Post-surgery)' field if you've selected 'Post-surgery'."`, {
        type: "error",
      })
      return null
    }

    return true
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    const validState = validateState()
    if (validState === true) {
      if (submitType == "submit") {
        if (props.submit) {
          setIsSubmitting(true)
          const result = await props.submit(dataState)
          if (result && result.ok) {
            setIsDisableForm(true)
          }
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        }
      }

      if (submitType == "update") {
        if (props.update) {
          setIsSubmitting(true)

          const result = await props.update(dataState)
          if (result.ok) {
            setIsDisableForm(true)
            setIsEditForm(false)
            
            // setDataState(dataState)
            // setFormState(dataState)
            // setAnswersData(dataState.answers)
            
          }
          setTimeout(() => {
            setIsSubmitting(false)
          }, 500)
        }
      }
      setInvalidInput(null)
    }
  }

  const dateValidate = (newDataState) => {
    if (!newDataState) {
      return
    }
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (dateFields.find((dateField) => dateField.name === fieldName) && newDataState[fieldName]) {
          const element = elements.current[fieldName]
          let valid = false
          if (newDataState[fieldName] && newDataState[fieldName].length === 10) {
            try {
              const dateTimeString = newDataState[fieldName] + "T00:00:00Z"
              const date = new Date(dateTimeString)
              valid = date && date instanceof Date && isFinite(date) ? true : false
            } catch {}
          }
          element.setCustomValidity(valid ? "" : "Invalid date, please use this format: " + getDateFormatString())
        }
      }
    }
    return newDataState
  }

  const datetimeValidate = (newDataState) => {
    if (!newDataState) {
      return
    }
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (datetimeFields.find((datetimeField) => datetimeField.name === fieldName) && newDataState[fieldName]) {
          const element = elements.current[fieldName]
          let valid = false
          if (newDataState[fieldName]) {
            try {
              const datetimeString = newDataState[fieldName]
              const datetime = new Date(datetimeString)
              valid = datetime && datetime instanceof Date && isFinite(datetime) ? true : false
            } catch {}
          }
          element?.setCustomValidity(valid ? "" : "Invalid datetime value")
        }
      }
    }
    return newDataState
  }

  const customValidate = (newState) => {
    if (customValidationFields.length) {
      for (let i = 0; i < customValidationFields.length; i++) {
        const field = customValidationFields[i]
        const rule = get(() => field.validation.rule)
        const message = get(() => field.validation.message)
        if (rule && message) {
          const or = get(() => rule.$or, [])
          if (!or) {
            console.error("validation rule not supported", rule)
          } else {
            let valid = or.length === 0 ? true : false
            for (let i = 0; i < or.length; i++) {
              const ruleItem = or[i]
              for (let j in ruleItem) {
                if (ruleItem.hasOwnProperty(j)) {
                  if (ruleItem[j] instanceof Object) {
                    if (ruleItem[j].required === true && newState[j]) {
                      valid = true
                    }
                    if (ruleItem[j].required === false && !newState[j]) {
                      valid = true
                    }
                  } else if (newState[j] === ruleItem[j]) {
                    valid = true
                  }
                }
              }
            }
            const element = elements.current[field.name]
            element.setCustomValidity(valid ? "" : message)
          }
        } else {
          console.warn("skipping custom validation because of empty rule or message", field.validation)
        }
      }
    }
  }

  const getDateFormatString = () => {
    const formatObj = new Intl.DateTimeFormat(navigator.language).formatToParts(new Date())

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "DD"
          case "month":
            return "MM"
          case "year":
            return "YYYY"
          default:
            return obj.value
        }
      })
      .join("")
  }

  const dateValue = (rawValue) => {
    if (!rawValue) {
      return rawValue
    }
    const usaDateFormatRegex = /^([\d]{2})\/([\d]{2})\/([\d]{4})$/
    const results = rawValue.match(usaDateFormatRegex)
    if (results) {
      const dateFormatString = getDateFormatString()
      switch (dateFormatString) {
        case "MM/DD/YYYY":
          return `${results[3]}-${results[1]}-${results[2]}`
        case "DD/MM/YYYY":
          return `${results[3]}-${results[2]}-${results[1]}`
        default:
          return rawValue
      }
    }
    return rawValue
  }

  const datetimeValue = (rawValue) => {
    if (!rawValue) {
      return rawValue
    }
    try {
      const value = new Date(rawValue)
      const result = value.toISOString()
      return result
    } catch (e) {
      console.error("Could not parse datetime", e)
    }

    return rawValue
  }

  const dataStateFromFormState = (newFormState) => {
    let newDataState = JSON.parse(JSON.stringify(newFormState))
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (dateFields.find((dateField) => dateField.name === fieldName)) {
          newDataState[fieldName] = dateValue(newDataState[fieldName])
        }
        if (datetimeFields.find((datetimeField) => datetimeField.name === fieldName)) {
          newDataState[fieldName] = datetimeValue(newDataState[fieldName])
        }
        if (newDataState[fieldName] === "true") {
          newDataState[fieldName] = true
        }
        if (newDataState[fieldName] === "false") {
          newDataState[fieldName] = false
        }
        if (newDataState[fieldName] === "null") {
          newDataState[fieldName] = null
        }
      }
    }
    return newDataState
  }

  const validateEmailChange = useRef(
    debounce(async (e, field) => {
      const email = e.target.value
      const badEmails = props.applicationState.badEmailAddresses
      const invalid = isInvalidEmailAddress(email)
      if (invalid) {
        e.target.className += " invalid-input"
        return false
      } else {
        e.target.classList.remove("invalid-input")
        return true
      }
    }, 300),
  ).current

  const isInvalidEmailAddress = (email) => {
    if (email && badEmailAddresses && badEmailAddresses.length) {
      const matches = matcher([email], badEmailAddresses)
      return matches.length
    }
  }

  const validateDateChange = (e, field) => {
    if (e.target.value) {
      const input = moment(e.target.value)
      const covidStarted = moment("1/1/2020")
      const sevenDaysAgo = moment().subtract(7, "days")

      const rules = [
        {
          field: "dateOfBirth",
          value: input.isBetween(moment().subtract(130, "y"), moment()),
          // not in the future, max 130 years in the past.
        },
        {
          field: "sampleCollectionDateTime",
          value: input.isBetween(moment().subtract(7, "d"), moment()),
          // not in the future, max 7 days in the past.
        },
        {
          field: "dateOfSymptomOnset",
          value: input.isBetween(covidStarted, moment()),
          // not in the future, not before 1/1/2020
        },
        {
          field: "vaccinationDate",
          value: input.isBetween(covidStarted, moment()),
          // not in the future, not before 1/1/2020
        },
        {
          field: "lastSSADate",
          value: input.isBefore(moment()),
        },
        // {
        //     field: "bloodCollectionDate",
        //     value: input.isBetween(sevenDaysAgo, moment())
        // }
      ]

      const rule = rules.find((rule) => rule.field == field.name)
      if (rule) {
        const element = elements.current[field.name]
        if (!rule?.value) {
          element.setCustomValidity(`Invalid ${field.label.toLowerCase()}.`)
          return false
        } else {
          element.setCustomValidity("")
          return true
        }
      }
    }
  }

  const validateChange = (e, field) => {
    let valid = true

    // validate email with list of bad emails
    if (field.type === "email") {
      valid = validateEmailChange(e, field)
    }

    if (_.includes(["date", "datetime-local"], field.type)) {
      valid = validateDateChange(e, field)
    }

    return valid
  }

  const validateDate = (date) => {
    const dateFormat = "MM/YY"
    const parsedDate = moment(date, dateFormat, true)
    if (!parsedDate.isValid()) {
      return false
    }

    const currentMonth = moment().format("MM")
    const currentYear = moment().format("YY")

    const parsedMonth = parsedDate.format("MM")
    const parsedYear = parsedDate.format("YY")

    if (parsedYear < currentYear || (parsedYear === currentYear && parsedMonth < currentMonth)) {
      return false // Date is before the current month/year
    }

    return true
  }

  const handleChange = (e, field, checked) => {
    const target = e.target
    const name = target.name
    let newFormState = {}
    let maxlength = field.maxlength
    let value = target.type === "checkbox" ? target.checked : target.type === "number" && isFinite(Number(target.value)) ? Number(target.value) : target.value

    if (field.type === "qrcode") {
      e.preventDefault()
      return
    }

    setInvalidInput(null)

    // if (field.name === 'bloodCollectionDate') {
    //     const isAcceptedDate = isInDateRange(value);
    //
    //     if (!isAcceptedDate) {
    //         setWarning(true)
    //     }
    // }

    if (field.name === "country") {
      if (value !== "US") {
        newFormState = {
          ...formState,
          [name]: value,
          state: null,
          zipCode: null,
        }
      } else {
        newFormState = {
          ...formState,
          [name]: value,
          region: null,
          postalCode: null,
        }
      }
    } else if (field.name === "billingCountry") {
      if (value !== "US") {
        newFormState = {
          ...formState,
          [name]: value,
          billingState: null,
          billingZipCode: null,
        }
      } else {
        newFormState = {
          ...formState,
          [name]: value,
          billingRegion: null,
          billingPostalCode: null,
        }
      }
    } else {
      // if (target.type == 'multiple-select') { }
      if (target.type === "radio") {
        if (target.name === "testingPurpose" && value === "purposeOther") {
          setOthersDisabled(false)
        } else {
          setOthersDisabled(true)
        }
      }

      if (field.type === "text" && field.name === "creditCardExpiry") {
        if (value) {
          // let inputValue = value.replace(/[^0-9/]/g, '');
          const isValidDate = validateDate(value)

          if (isValidDate === false) {
            setInvalidInput(`Please enter valid expiry date`)
          } else {
            setInvalidInput(null)
          }
          // value = inputValue;
        } else {
          setInvalidInput(null)
        }
      }

      if (maxlength) {
        if (formState.creditCardType === "amex" && field.name === "creditCardNo") {
          maxlength = 15
        }
        if (value.length > field.maxlength) {
          value = value.slice(0, maxlength)
        }
      }

      if (field.name === "postSurgeryMonths") {
        let inputValue = value.replace(/[^0-9]/g, "").replace(/^0+/, "")

        if (!inputValue || parseInt(inputValue) > 0) {
          value = inputValue
        }
      }

      newFormState = {
        ...formState,
        [name]: value,
      }
    }
    
    setFormState(newFormState);
    props.fieldsOnChange && props.fieldsOnChange(newFormState)
    const newDataState = dataStateFromFormState(newFormState)
    setDataState(newDataState)
    
    
    
    dateValidate(newDataState)
    datetimeValidate(newDataState)
    customValidate(newDataState)
    validateChange(e, field)
    if (props.change) {
      props.change()
    }
  }

  const selectHandleChange = (e, field, product, answer) => {
    const target = e.target
    let name = target.name
    let value = target.type === "checkbox" ? target.checked : target.value
    let newState = {}
    let answers = {}
    let productId;
    let newProduct;

    if (product) {
      productId = product.id
    }

    if (productId) {
      newProduct = handleProductChange(products, productId, target.checked);
      setProducts(newProduct);
    }

    console.log('ANONA new product', newProduct);

    setSelectedProduct(name)
    // setProductGroup(name);
    // getQuestions('cancer');

    if (!answer) {
      if ((target.type === "checkbox" || target.type === "radio") && target.checked) {
        if (name.includes("ppq") || name.includes("PPQ")) {
          newState = {
            ...formState,
            isPPQ: true,
          }
        } else {
          newState = {
            ...formState,
            isPPQ: false,
          }
        }

        value = target.type === "checkbox" ? target.checked : target.type === "number" && isFinite(Number(target.value)) ? Number(target.value) : target.value
      }

      newState = {
          ...formState,
          [name]: value
      }
      // setFormState(newState);
  

      if (newProduct) {
        newState = {
          ...newState,
          products: newProduct
        }
      }
      setFormState(newState)
  
      const newDataState = dataStateFromFormState(newState)
      setDataState(newDataState)
    } else {
      // answers[name] = value;

      console.log('nameX: ', name);
      console.log('valueX: ', value);
      setAnswersData((prevState) => ({
        ...prevState,
        [name]: value,
      }))
  
  
      newState = {
        ...formState,
        [name]: value,
        answers: {
          ...answersData,
          [name]: value
        }
      }

      if (newProduct) {
        newState = {
          ...newState,
          products: newProduct
        }
      }
      
      setFormState(newState)
      const newDataState = dataStateFromFormState(newState)
      setDataState(newDataState)
    }

    // if (fieldIndex != null) {
    //     newAnswers[sectionIndex]['answers'][fieldIndex][name] = value;
    // } else {
    //     newAnswers[sectionIndex][name] = value;
    // }
    // setAnswers(newAnswers);

    // newState = {
    //     ...formState,
    //     [name]: value
    // }
    // setFormState(newState);

  }

  useEffect(() => {
    console.log('anona products', products);
    let newState ={
      ...dataState,
      products: products
    };

    setDataState(newState)
  }, [products])

  const handleProductChange = (products, value, checked) => {
    let newState;
    if (products.includes(value) && checked === false) {
      // Value is already in the array, remove it
      // setProducts(products.filter((item) => item != value))
      return products.filter((item) => item != value);
    }
    if (!products.includes(value) && checked === true) {
      // Value is not in the array, add it
      // setProducts([...products, value])
      return [...products, value];
    }
  }

  const evaluate = (rule, nullValue) => {
    if (rule === undefined || rule === null) {
      return nullValue || false
    }
    if (!(rule instanceof Object)) {
      return rule
    }
    for (const property in rule) {
      if (rule.hasOwnProperty(property)) {
        if (rule[property] instanceof Object) {
          if (rule[property].empty === true && formState[property]) {
            return false
          }
          if (rule[property].empty === false && !formState[property]) {
            return false
          }
        } else if (formState[property] !== rule[property]) {
          return false
        }
      }
    }
    return true
  }

  const validateDisabledAddress = (field) => {
    const includedCountry = topCountries.includes(formState.country) || false
    if (formState.country && formState.country === "US" && (field.name === "state" || field.name === "zipCode")) {
      return false
    }

    if (formState.country && formState.country !== "US" && (field.name === "region" || field.name === "postalCode")) {
      return false
    }

    if (formState.billingCountry && formState.billingCountry === "US" && (field.name === "billingState" || field.name === "billingZipCode")) {
      return false
    }

    if (formState.billingCountry && formState.billingCountry !== "US" && (field.name === "billingRegion" || field.name === "billingPostalCode")) {
      return false
    }

    return true
  }

  const customLabel = (label) => {
    if (!label) {
      return label
    }
    const regex = /\{(.+?)\}/
    const match = label.match(regex)
    if (!match) {
      return label
    }
    const value = props.customFunctions[match[1]](label, formState)
    return value
  }

  const getLabel = (field) => {
    const baseStyle = field.type === "checkbox" ? { marginLeft: "2rem" } : { display: "block" }
    const style = Object.assign(baseStyle, field.labelStyle)
    if (field.labelHtml) {
      const html = customLabel(field.labelHtml)
      return <Label for={field.name} className={field.labelClassName} style={style} dangerouslySetInnerHTML={{ __html: html }} />
    } else {
      const label = customLabel(field.label)
      return (
        <Label for={field.name} className={field.labelClassName} style={style}>
          {(label ? label : "") + (evaluate(field.required, false) ? " *" : "")}
        </Label>
      )
    }
  }

  const download = async (field) => {
    const res = await makeApiDownloadCall("get", field.format, formState, "application/pdf", formState[field.name] + ".pdf")
    if (!res.ok) {
      alert.show("PDF not yet available", {
        type: "error",
      })
    }
  }

  const viewResult = (field) => {
    setViewTestResult(true)
  }

  const closeResult = () => {
    setViewTestResult(false)
  }

  const formatValue = (format, value) => {
    if (value && format === "toLocalizedDateString") {
      if (value === null || value === undefined) {
        return ""
      }
      return new Date(value).toLocaleString()
    }
    if (value && format === "capitalized") {
      return capitalize(value)
    }
    if (format === "boolean") {
      return value === true ? "Yes" : value === false ? "No" : "Unset"
    }

    if (format === "allCaps") {
      return value.toUpperCase()
    }

    return value
  }

  const selectSubject = (data) => {
    if (selectedSubjectIds.includes(data.id)) {
      setSelectedSubjectIds(selectedSubjectIds.filter((item) => item != data.id))
    } else {
      setSelectedSubjectIds((prevState) => [data.id, ...prevState])
    }
  }

  const handleMergeSubjects = async () => {
    setIsMerging(true)

    const result = await makeApiCall("post", "/api/subjects/merge", {
      mainSubjectId: formState.id,
      selectedSubjectIds: selectedSubjectIds,
    })

    setTimeout(() => {
      setIsMerging(false)
    }, 500)

    if (result.ok) {
      // props.close();
      setIsDisableForm(true)
      window.scrollTo(0, 0)
      props.setAlertState("good")
    } else {
      props.setAlertState("bad")
      window.scrollTo(0, document.body.scrollHeight)
    }

    if (props.setExistingSubjects()) {
      props.setExistingSubjects([])
    }
    handleCloseModal()
  }

  // const toggleWarning = () => {
  //     setWarning(!warning);
  // };

  useEffect(() => {
    getStateForNetest()
    getRequisitionDefinitions("cancer")
    console.log('anona formstate', formState);
  }, [currentRecord, formState])

  if (props.isLoading || !questionsData.length) {
    return (
      <center>
        <Spinner type="grow" />
      </center>
    )
  }

  return (
    <div>
      {!props.isLoading && questionsData.length && (
        <Form className="GeneralForm" onSubmit={onSubmit} style={props.style}>
          {props.formDefinition.formLabel ? (
            <Row className="QR-code">
              <Col>
                <h2>{props.formDefinition.formLabel}</h2>
                <h3>{props.id}</h3>
              </Col>
            </Row>
          ) : null}
          {sections?.map((section, sectionIndex) => {
            if (evaluate(section.visible, true)) {
              return (
                <Card key={"section-" + sectionIndex} className="mb-3">
                  {section.label && (
                    <CardHeader>
                      <CardTitle>{section.label}</CardTitle>
                    </CardHeader>
                  )}
                  <CardBody>
                    <Row>
                      {section.type === "table" ? (
                        <GeneralTable
                          style={{ marginTop: "30px" }}
                          table={section.table}
                          data={getValueByDotNotation(formState, section.name)}
                          firstRowNumber={1}
                          rowActions={props.formRowActions}
                          currentRecord={currentRecord}
                        />
                      ) : (
                        getColumns(section).map((column, columnIndex) => (
                          <Col sm={column.width} key={"column-" + columnIndex}>
                            {column.fields.map((field) => {
                              if (evaluate(field.visible, true)) {
                                return (
                                  <FormGroup key={field.name} style={field.groupStyle}>
                                    {field.type !== "checkbox" && field.type !== "radio" && field.type !== "button" ? (
                                      <Label style={{ display: "block" }} for={field.name}>
                                        {field.label + (field.required ? " *" : "")}{" "}
                                      </Label>
                                    ) : null}
                                    {field.type === "textarea" ? (
                                      <Input
                                        type={field.type}
                                        name={field.name}
                                        id={field.name}
                                        disabled={(isEditForm && field.nonEditable) || isDisableForm}
                                        readOnly={field.readOnly}
                                        required={field.required}
                                        onChange={(e) => handleChange(e, field)}
                                        style={Object.assign({ display: "block" }, field.style)}
                                        placeholder={field.placeholder}
                                        rows={field.rows}
                                        cols={field.cols}
                                        wrap={field.wrap}
                                        value={getValueByDotNotation(formState, field.name)}
                                        maxLength={field.maxlength}
                                      />
                                    ) : null}

                                    {field.type === "timestamp" || (field.type === "datetime-local" && evaluate(field.readonly, false) === true) ? (
                                      <Input
                                        type={"text"}
                                        name={field.name}
                                        id={field.name}
                                        value={formatValue(field.format, getValueByDotNotation(formState, field.name)) || ""}
                                        checked={field.type === "checkbox" ? getValueByDotNotation(formState, field.name) || false : null}
                                        disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}
                                        readOnly={true} //evaluate(field.readonly, false)}
                                        required={evaluate(field.required, false)}
                                        pattern={field.pattern}
                                        title={field.title}
                                        //onChange={(e) => handleChange(e, field)}
                                        style={field.style}
                                        placeholder={field.placeholder}
                                        className={field.className}
                                        max={field.max}
                                        min={field.min}
                                        maxLength={field.maxlength}
                                      ></Input>
                                    ) : null}

                                    {field.type == "multiple-select" && (
                                      <div style={field.type === "multiple-select" ? Object.assign({ marginLeft: 0, padding: 0 }, field.style) : field.style}>
                                        <MultiSelect
                                          disableSearch
                                          defaultIsOpen={false}
                                          overrideStrings={{
                                            allItemsAreSelected: selected ? _.join(_.map(selected[field.name], "label"), ", ") : [],
                                          }}
                                          options={() => {
                                            const options = getOptions(field)
                                            const customOptions = []
                                            options.map((option) => {
                                              if (option.key && option.value) {
                                                customOptions.push({
                                                  label: option.value,
                                                  value: option.key,
                                                })
                                              }
                                            })

                                            return customOptions
                                          }}
                                          value={selected[field.name] ?? []}
                                          disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                          onChange={(e) => {
                                            setSelected((prevState) => ({
                                              ...prevState,
                                              [field.name]: e,
                                            }))
                                            e = {
                                              target: {
                                                value: e,
                                                type: "multiple-select",
                                                name: field.name,
                                              },
                                            }
                                            handleChange(e, field)
                                          }}
                                          labelledBy="Select"
                                          hasSelectAll={false}
                                        />
                                      </div>
                                    )}

                                    {field.type === "text" && field.name === "purposeOtherDescription" ? (
                                      <Input
                                        className={"text"}
                                        type={field.type}
                                        name={field.name}
                                        id={field.name}
                                        value={getValueByDotNotation(formState, field.name)}
                                        disabled={othersDisabled}
                                        required={field.required}
                                        pattern={field.pattern}
                                        title={field.title}
                                        onChange={(e) => {
                                          e.persist()
                                          handleChange(e, field)
                                        }}
                                        innerRef={(element) => (elements.current[field.name] = element)}
                                        style={field.style}
                                        placeholder={field.placeholder}
                                        maxLength={field.maxlength}
                                      />
                                    ) : null}

                                    {field.type === "input-dropdown" ? (
                                      <div className="input-group" style={field.style}>
                                        <Input
                                          type={"text"}
                                          name={field.name}
                                          id={field.name}
                                          value={formatValue(field.format, getValueByDotNotation(formState, field.name)) || ""}
                                          checked={field.type === "checkbox" ? getValueByDotNotation(formState, field.name) || false : null}
                                          disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}
                                          readOnly={evaluate(field.readonly, false)}
                                          required={evaluate(field.required, false)}
                                          pattern={field.pattern}
                                          title={field.title}
                                          onChange={(e) => handleChange(e, field)}
                                          style={field.style}
                                          placeholder={field.placeholder}
                                          className={field.className}
                                          max={field.max}
                                          min={field.min}
                                          maxLength={field.maxlength}
                                        />
                                        <div className="input-group-append">
                                          <UncontrolledDropdown inNavbar>
                                            <DropdownToggle
                                              outline={true}
                                              split={true}
                                              caret
                                              disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}
                                            ></DropdownToggle>
                                            <DropdownMenu>
                                              {getOptions(field).map((item) => (
                                                <DropdownItem name={field.name} onClick={(e) => handleChange(e, field)} key={item.key} value={item.value}>
                                                  {item.value || item.key}
                                                </DropdownItem>
                                              ))}
                                            </DropdownMenu>
                                          </UncontrolledDropdown>
                                        </div>
                                      </div>
                                    ) : null}

                                    {field.type !== "input-dropdown" &&
                                    field.type !== "multiple-select" &&
                                    field.type !== "textarea" &&
                                    field.type !== "label" &&
                                    field.type !== "radio" &&
                                    field.type !== "timestamp" &&
                                    field.type !== "button" &&
                                    (field.type !== "datetime-local" || (field.type === "datetime-local" && evaluate(field.readonly, false) === false)) &&
                                    field.name !== "purposeOtherDescription" &&
                                    field.type !== "modal" &&
                                    field.type !== "product" &&
                                    field.type !== "questions" ? (
                                      <span>
                                        <Input
                                          className={
                                            field.type === "email" && isInvalidEmailAddress(getValueByDotNotation(formState, field.name)) ? "invalid-input" : ""
                                          }
                                          type={field.type}
                                          name={field.name}
                                          id={field.name}
                                          value={
                                            field.type === "checkbox"
                                              ? ""
                                              : getValueByDotNotation(formState, field.name) === null ||
                                                getValueByDotNotation(formState, field.name) === undefined
                                              ? ""
                                              : field.format != "toLocalizedDateString"
                                              ? formatValue(field.format, getValueByDotNotation(formState, field.name))
                                              : moment(getValueByDotNotation(formState, field.name)).format("YYYY-MM-DDTHH:mm") ?? ""
                                          }
                                          checked={field.type === "checkbox" ? getValueByDotNotation(formState, field.name) : false}
                                          disabled={
                                            (isEditForm && field.nonEditable) ||
                                            field.readonly ||
                                            isDisableForm ||
                                            (props.formState && field.name === "id") ||
                                            (addressField.includes(field.name) ? validateDisabledAddress(field) : evaluate(field.disabled, false))
                                          }
                                          required={field.required}
                                          pattern={formState.creditCardType === "amex" && field.name === "creditCardNo" ? '[0-9]{15}': field.pattern}
                                          title={field.title}
                                          onChange={(e) => {
                                            e.persist()
                                            handleChange(e, field)
                                          }}
                                          max={field.max}
                                          min={field.min}
                                          innerRef={(element) => (elements.current[field.name] = element)}
                                          style={field.type === "checkbox" ? Object.assign({ marginLeft: 0 }, field.style) : field.style}
                                          placeholder={field.placeholder}
                                          maxLength={field.maxlength}
                                        >
                                          {field.type === "select"
                                            ? getOptions(field).map((item) => (
                                                <option key={item.key} value={item.key}>
                                                  {item.value || item.key}
                                                </option>
                                              ))
                                            : null}
                                        </Input>
                                        {invalidInput &&
                                          field.name === "creditCardExpiry" &&
                                          (formState["creditCardExpiry"] !== null || formState["creditCardExpiry"] !== "") && (
                                            <span className="small">{invalidInput}</span>
                                          )}
                                        {field.notes && <span className="small">{field.notes}</span>}
                                      </span>
                                    ) : null}
                                    {field.type === "checkbox" ? (
                                      <Label
                                        for={field.name}
                                        disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                        style={{ marginLeft: "2rem" }}
                                      >
                                        {field.label + (field.required ? " *" : "")}
                                      </Label>
                                    ) : null}
                                    {field.type === "radio"
                                      ? getOptions(field).map((item) =>
                                          item.key !== "" ? (
                                            <span key={item.key} style={field.itemStyle && field.itemStyle.span}>
                                              <Input
                                                type="radio"
                                                name={field.name}
                                                id={field.name + "-" + item.key}
                                                value={item.key}
                                                checked={item.key === getValueByDotNotation(formState, field.name)}
                                                disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                readOnly={true}
                                                required={evaluate(field.required, false)}
                                                onChange={(e) => handleChange(e, field)}
                                                style={Object.assign(
                                                  Object.assign(
                                                    {
                                                      marginLeft: "10px",
                                                      marginTop: "5px",
                                                    },
                                                    field.itemStyle || {},
                                                  ),
                                                  field.itemStyle.radio || {},
                                                )}
                                                className={field.className}
                                              />
                                              <label htmlFor={field.name + " " + item.key} style={field.itemStyle && field.itemStyle.label}>
                                                {item.value || item.key}
                                              </label>
                                              <br />
                                            </span>
                                          ) : null,
                                        )
                                      : null}
                                    {field.type === "button" ? (
                                      <Button outline color="secondary" type="button" onClick={() => download(field)}>
                                        {getLabel(field)}
                                      </Button>
                                    ) : null}

                                    {field.type === "product" && field.name === "testTypeName" && (
                                      <Row>
                                        {cancerProducts &&
                                          cancerProducts.map((products, idx) => {
                                            product = products.productName
                                            return (
                                              <div key={idx}>
                                                <Col>
                                                  <Input
                                                    type="checkbox"
                                                    name={products.id}
                                                    id={products.id}
                                                    checked={formState[products.id]}
                                                    disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                    required={field.required}
                                                    title={field.title}
                                                    onChange={(e) => {
                                                      e.persist()
                                                      selectHandleChange(e, field, products, false)
                                                    }}
                                                    placeholder={field.placeholder}
                                                    style={{ marginLeft: "0" }}
                                                    maxLength={field.maxlength}
                                                  />
                                                  <Label
                                                    for={products.id}
                                                    disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                    style={{ marginLeft: "2rem" }}
                                                  >{`${product} ${field.required ? " *" : ""}`}</Label>
                                                </Col>
                                              </div>
                                            )
                                          })}
                                      </Row>
                                    )}

                                    {field.type === "questions" && field.name === "questionName" ? (
                                      // <Row>
                                      <>
                                        {questionsData && questionsData.length > 0 ? (
                                          questionsData.map((el, idx) => {
                                            return (
                                              <React.Fragment key={idx}>
                                                {el.sections &&
                                                  el.sections.map((section, idx) => {
                                                    if (!section.productId || (section.productId && formState[section.productId])) {
                                                      return (
                                                        <Card key={section.label || idx} className="mb-3 rounded-0">
                                                          <CardHeader>
                                                            <CardTitle>{section.label}</CardTitle>
                                                          </CardHeader>
                                                          <CardBody>
                                                            {section.fields
                                                              ? section.fields.map((field, idx) => {
                                                                  return (
                                                                    <Row key={idx} className="form-block-2 w-form mt-3 mb-3 px-2">
                                                                      {field.label && field.type === "label" && field.name === "providerLabel" ? (
                                                                        <div className="form-info-text">{field.label}</div>
                                                                      ) : field.label ? (
                                                                        <div className="form-subheading p-0">{field.label}</div>
                                                                      ) : (
                                                                        <div className="form-subheading-2">{field.label}</div>
                                                                      )}
                                                                      {field.type !== "radio" && field.type !== "multiple-select" && field.type !== "label" ? (
                                                                        <>
                                                                          <Input
                                                                            type={field.type}
                                                                            name={field.name}
                                                                            id={field.name}
                                                                            // value={field.type === 'checkbox' ? '' : (formState[field.label] === null || formState[field.label] === undefined ? '' : (field.format != 'toLocalizedDateString' ? formatValue(field.format, formState[field.label]) : moment(formState[field.label]).format("YYYY-MM-DDTHH:mm") ?? ''))}
                                                                            value={answersData[field.name] ? answersData[field.name] : ""}
                                                                            checked={field.type === "checkbox" ? formState[field.name] || false : null}
                                                                            disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                                            readOnly={evaluate(field.readonly, false)}
                                                                            required={evaluate(field.required, false)}
                                                                            pattern={field.pattern}
                                                                            title={field.title}
                                                                            onChange={(e) => {
                                                                              e.persist()
                                                                              selectHandleChange(e, field, null, true)
                                                                            }}
                                                                            max={field.max}
                                                                            min={field.min}
                                                                            style={
                                                                              field.type === "checkbox"
                                                                                ? Object.assign({ marginLeft: 0 }, field.style)
                                                                                : field.style
                                                                            }
                                                                            placeholder={field.placeholder}
                                                                            maxLength={field.maxLength}
                                                                          >
                                                                            {field.type === "select"
                                                                              ? getOptions(field).map((item) => (
                                                                                  <option key={item.key} value={item.key}>
                                                                                    {item.value || item.key}
                                                                                  </option>
                                                                                ))
                                                                              : null}
                                                                          </Input>
                                                                        </>
                                                                      ) : null}

                                                                      {field.type == "multiple-select" && (
                                                                        <div
                                                                          style={
                                                                            field.type === "multiple-select"
                                                                              ? Object.assign({ marginLeft: 0, padding: 0 }, field.style)
                                                                              : field.style
                                                                          }
                                                                        >
                                                                          <MultiSelect
                                                                            disableSearch
                                                                            defaultIsOpen={false}
                                                                            overrideStrings={{
                                                                              allItemsAreSelected: selected
                                                                                ? _.join(_.map(selected[field.name], "label"), ", ")
                                                                                : [],
                                                                            }}
                                                                            options={() => {
                                                                              const options = getOptions(field)
                                                                              const customOptions = []
                                                                              options.map((option) => {
                                                                                if (option.key && option.value) {
                                                                                  customOptions.push({
                                                                                    label: option.value,
                                                                                    value: option.key,
                                                                                  })
                                                                                }
                                                                              })

                                                                              return customOptions
                                                                            }}
                                                                            checked={selected[field.name] ? true : false}
                                                                            value={selected[field.name] ?? []}
                                                                            disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                                            onChange={(e) => {
                                                                              setSelected((prevState) => ({
                                                                                ...prevState,
                                                                                [field.name]: e,
                                                                              }))
                                                                              e = {
                                                                                target: {
                                                                                  value: e,
                                                                                  type: "multiple-select",
                                                                                  name: field.name,
                                                                                },
                                                                              }
                                                                              selectHandleChange(e, field, null, true)
                                                                            }}
                                                                            labelledBy="Select"
                                                                            hasSelectAll={false}
                                                                          />
                                                                        </div>
                                                                      )}

                                                                      {field.type === "radio" ? (
                                                                        <>
                                                                          {getOptions(field).map((item) =>
                                                                            item.key !== "" ? (
                                                                              <span key={item.key} style={{ display: "block" }}>
                                                                                <Input
                                                                                  type="radio"
                                                                                  name={field.name}
                                                                                  id={field.name + "-" + item.key}
                                                                                  value={item.key}
                                                                                  checked={item.key == answersData[field.name] ? true : false}
                                                                                  // checked={item.key === getValueByDotNotation(formState, field.name) ? true : false}
                                                                                  disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                                                  readOnly={evaluate(field.readOnly, false)}
                                                                                  required={evaluate(field.required, false)}
                                                                                  onChange={(e) => selectHandleChange(e, field, null, true)}
                                                                                  style={Object.assign(
                                                                                    Object.assign(
                                                                                      {
                                                                                        marginLeft: "10px",
                                                                                        marginTop: "5px",
                                                                                      },
                                                                                      field.itemStyle || {},
                                                                                    ),
                                                                                    field.itemStyle?.radio || {},
                                                                                  )}
                                                                                  className={field.className}
                                                                                />
                                                                                <label htmlFor={field.name + " " + item.key} style={{ marginLeft: "30px" }}>
                                                                                  {item.value || item.key}
                                                                                </label>
                                                                                <br />
                                                                              </span>
                                                                            ) : null,
                                                                          )}
                                                                        </>
                                                                      ) : null}
                                                                    </Row>
                                                                  )
                                                                })
                                                              : null}
                                                          </CardBody>
                                                        </Card>
                                                      )
                                                    }
                                                  })}
                                              </React.Fragment>
                                            )
                                          })
                                        ) : (
                                          <div></div>
                                        )}
                                      </>
                                    ) : // </Row>
                                    null}

                                    {field.type === "modal" && field.name === "testResult" ? (
                                      <>
                                        <Button outline color="secondary" type="button" onClick={() => viewResult(field)}>
                                          View Test Result
                                        </Button>

                                        {props.isSubjectInfoPage ? (
                                          <Button
                                            className="ml-2"
                                            outline
                                            color="secondary"
                                            type="button"
                                            onClick={() => navigate(`/subject-info/${subjectId}/netest-details?subId=${formState.id}&displayMode=inner-form`)}
                                          >
                                            View Test Result Graphs
                                          </Button>
                                        ) : null}

                                        <Row>
                                          <Col sm={"12"}>
                                            <Modal isOpen={viewTestResult} toggle={closeResult} size="lg">
                                              <ModalHeader>Test Result</ModalHeader>
                                              <ModalBody>
                                                <Row>
                                                  <Col sm="12">
                                                    {formState[field.name] && (
                                                      <table className="table mt-3">
                                                        <thead>
                                                          <tr>
                                                            <th>Result</th>
                                                            <th>Value</th>
                                                          </tr>
                                                        </thead>
                                                        <tbody>
                                                          {Object.keys(formState[field.name]).map((key) => {
                                                            return (
                                                              <tr key={key}>
                                                                <td>{key}</td>
                                                                <td>
                                                                  {formState[field.name][key] === true
                                                                    ? "True"
                                                                    : formState[field.name][key] === false
                                                                    ? "False"
                                                                    : formState[field.name][key]}
                                                                </td>
                                                              </tr>
                                                            )
                                                          })}
                                                        </tbody>
                                                      </table>
                                                    )}
                                                  </Col>
                                                </Row>
                                              </ModalBody>
                                              <ModalFooter>
                                                <Button type="button" outline color="primary" onClick={closeResult}>
                                                  Close
                                                </Button>
                                              </ModalFooter>
                                            </Modal>
                                          </Col>
                                        </Row>
                                      </>
                                    ) : null}
                                  </FormGroup>
                                )
                              }
                            })}
                          </Col>
                        ))
                      )}
                    </Row>
                  </CardBody>
                </Card>
              )
            }
          })}
          <Row className={"general-form-buttons"}>
            <Col sm="12">
              <div className="float-right">
                {props.submit && !props.formState && !isDisableForm ? (
                  <Button
                    type="submit"
                    outline
                    color="primary"
                    onClick={(e) => {
                      if (!isSubmitting) {
                        setSubmitType("submit")
                      }
                    }}
                    style={Object.assign(
                      { marginRight: "10px" },
                      get(() => props.formDefinition.submit.style, {}),
                    )}
                    disabled={evaluate(props.formDefinition.submit.disabled, false)}
                  >
                    {isSubmitting ? <ReactLoading type="spin" color="white" height={25} width={20} /> : "Submit"}
                  </Button>
                ) : null}

                {!!props.update && !isDisableForm ? (
                  <Button
                    type="submit"
                    outline
                    color="primary"
                    onClick={(e) => {
                      if (!isSubmitting) {
                        setSubmitType("update")
                      }
                    }}
                    style={Object.assign(
                      { marginRight: "10px" },
                      get(() => props.formDefinition.update.style, {}),
                    )}
                    disabled={evaluate(props.formDefinition.update.disabled, false)}
                  >
                    {isSubmitting ? <ReactLoading type="spin" color="white" height={25} width={20} /> : "Update"}
                  </Button>
                ) : null}
                {props.formDefinition.edit && props.formState && isDisableForm && !formState.taps && dataQuery?.status !== 'accepted' && (
                  <Button
                    type="submit"
                    outline
                    color="primary"
                    onClick={() => {
                      setIsDisableForm(false)
                      setIsEditForm(true)
                    }}
                    style={Object.assign(
                      { marginRight: "10px" },
                      get(() => props.formDefinition.edit.style, {}),
                    )}
                    disabled={evaluate(props.formDefinition.edit.disabled, false)}
                  >
                    {get(() => props.formDefinition.edit.label, "Edit")}
                  </Button>
                )}
                {props.cancel ? (
                  <Button
                    type="button"
                    outline
                    color="secondary"
                    style={get(() => props.formDefinition.cancel.style)}
                    onClick={() => {
                      if (props.cancel) {
                        props.cancel()
                      }
                    }}
                  >
                    {get(() => props.formDefinition.cancel.label, "Cancel")}
                  </Button>
                ) : null}
                {props.findDuplicate && !formState.taps && admin && !props.sampleId ? (
                  <Button
                    type="button"
                    outline
                    color="primary"
                    style={get(() => props.formDefinition.findDuplicate.style)}
                    onClick={() => {
                      if (props.findDuplicate) {
                        props.findDuplicate(formState)
                      }
                    }}
                  >
                    {get(() => props.formDefinition.findDuplicate.label, "Find Duplicate")}
                  </Button>
                ) : null}
                {!isDisableForm && isEditForm && (
                  <Button
                    type="button"
                    outline
                    color="primary"
                    style={get(() => props.formDefinition.close.style)}
                    onClick={ async () => {
                      // await props.loadCurrentData()
                      
                      setIsDisableForm(true)
                      setIsEditForm(false);
                      setFormState(getInitialState())
                      setDataState(getInitialState());
                      setAnswersData(props.formState?.answers || [])
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {/* {!props.cancel && !props.isSubjectInfoPage && !props.sampleId && (
                    <Button
                      type="button"
                      outline
                      color="primary"
                      style={get(() => props.formDefinition.close.style)}
                      onClick={() => {
                        if (props.close) {
                          props.close()
                        }

                        if (props.setExistingSubjects) {
                          props.setExistingSubjects([])
                        }

                        setIsDisableForm(true)
                        setIsEditForm(false)
                      }}
                    >
                      {get(() => props.formDefinition.close.label, "Cancel")}
                    </Button>
                  )} */}
              </div>
            </Col>
          </Row>
          {props.existingSubjects && props.existingSubjects.length >= 1 ? (
            <Row>
              <Col sm="12">
                <Modal isOpen={showMergeModal} toggle={handleCloseModal} size="lg">
                  <ModalHeader>Subject Details</ModalHeader>
                  <ModalBody>Merge selected subject{selectedSubjectIds.length > 1 && "s "} into the current subject?</ModalBody>
                  <ModalFooter>
                    <Button type="button" outline color="danger" onClick={handleMergeSubjects}>
                      {isMerging ? <ReactLoading type="spin" color="red" height={25} width={20} /> : "OK"}
                    </Button>
                    <Button type="button" outline color="primary" onClick={handleCloseModal}>
                      Close
                    </Button>
                  </ModalFooter>
                </Modal>
                <h4 className="mb-4">Possible Patient Matches</h4>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Select</th>
                      <th>Patient ID</th>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Date of Birth</th>
                      <th>Email</th>
                      <th>Phone</th>
                      <th>No. of Test</th>
                    </tr>
                  </thead>
                  <tbody>
                    {props.existingSubjects.map((record, index) => (
                      <tr key={index} onClick={() => selectSubject(record)}>
                        <td>
                          <input type="radio" value="medium" checked={selectedSubjectIds.includes(record.id)} onChange={() => selectSubject(record)} />
                        </td>
                        <td>{record.id}</td>
                        <td>{record.firstName}</td>
                        <td>{record.lastName}</td>
                        <td>{record.dateOfBirth ? new Date(record.dateOfBirth).toLocaleString() : ""}</td>
                        <td>{record.email}</td>
                        <td>{record.phoneNumber}</td>
                        <td className="text-center">{(record.tests?.netestRecords?.all.length || 0) + (record.tests?.testRecords?.all.length || 0)}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

                <div className="float-right mt-3 mb-5">
                  <Button
                    type="button"
                    outline
                    color="primary"
                    style={get(() => props.formDefinition.merge.style)}
                    disabled={!selectedSubjectIds.length}
                    onClick={() => {
                      handleShowModal()
                    }}
                  >
                    {get(() => props.formDefinition.label, "Merge")}
                  </Button>
                  <Button
                    type="button"
                    outline
                    color="primary"
                    style={get(() => props.formDefinition.cancel.style)}
                    onClick={() => {
                      props.setExistingSubjects([])
                    }}
                  >
                    {get(() => props.formDefinition.cancel.label, "Cancel")}
                  </Button>
                </div>
              </Col>
            </Row>
          ) : null}
        </Form>
      )}
      {/*<Modal isOpen={warning} toggle={warning} backdrop={true}>*/}
      {/*    <ModalHeader>Process Warning</ModalHeader>*/}
      {/*    <ModalBody>*/}
      {/*        You are trying to process a sample that is not in the accepted day(s) of blood collection date*/}
      {/*    </ModalBody>*/}
      {/*    <ModalFooter>*/}
      {/*        <Button type="button" color="success" onClick={toggleWarning}>OK</Button>*/}
      {/*        /!*<Button type="button" outline color="primary" onClick={setWarning(false)}>Cancel</Button>*!/*/}
      {/*    </ModalFooter>*/}
      {/*</Modal>*/}
    </div>
  )
}

GeneralForm.propTypes = {
  formDefinition: PropTypes.object.isRequired,
  lookups: PropTypes.array.isRequired,
  formState: PropTypes.object,
  submit: PropTypes.func,
  cancel: PropTypes.func,
  change: PropTypes.func,
}

export default GeneralForm
