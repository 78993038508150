import React, { Component, useState } from 'react';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import {
    Jumbotron, Button,
    Input, Label
} from 'reactstrap';
import classnames from 'classnames';
import FineUploaderTraditional from 'fine-uploader-wrappers';
import FileInput from 'react-fine-uploader/file-input';
import 'react-fine-uploader/gallery/gallery.css';
import {get} from "../utility/client-utility";


const FileUploader = props => {
    const { showForTesting = true } = props;
    const [ state, setState ] = useState({
        downloadEnabled: props.uri ? true : false,
        uploadEnabled: props.uri ? true : false,
        fileUploaded: ''
    });
    const [isTesting, setIsTesting] = useState(false);
    const [ customUploadErrorMessage, setCustomUploadErrorMessage ] = useState('');
    
    const downloadFile = () => {
        let filename = 'download';
        fetch(props.uri,
          {
              credentials: 'include'
          }
        )
          .then(response => {
              if (response.status === 200) {
                  filename = response.headers.get("content-disposition");
                  filename = filename.match(/(?<=")(?:\\.|[^"\\])*(?=")/)[0];
                  return response.blob();
              } else {
                  throw 'response status code ' + response.status;
              }
          })
          .then(body => {
              require("downloadjs")(body, filename, "application/octet-stream");
          })
          .catch(e => {
              props.setError('Failed to download file');
          });
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;

        if (target.checked) {
            setIsTesting(true);
        } else {
            setIsTesting(false);
        }

    };
    
    const uploader = new FineUploaderTraditional({
        callbacks: {
            onError: function(id, name, errorReason, xhrOrXdr) {
                props.setError('The file upload failed');
            },
            onValidate: function (data, container) {
            
            },
            onUpload: () => {
            
            }
        },
        options: {
            request: {
                endpoint: props.uri,
                params: { testing: isTesting }
            },
        }
    });
    
    uploader.on('upload',  (id, name, response) => {
        if (props.allowedExtensions?.length) {
            const fileType = name?.split('.').pop() || '';
            const acceptedFileTypes = props.allowedExtensions;
    
            if (!acceptedFileTypes.includes(fileType)) {
                setState(prevState => ({...prevState, fileUploaded: 'Invalid file type'}));
                return false;
            }
        }
    })
    uploader.on('complete', (id, name, response) => {
        if (!response || !response.success) {
            // setState(prevState => ({...prevState, fileUploaded: 'Returned empty result'}));
            if (response.message) {
                const detail = response.message;
                props.setError(detail);
                if (response.note) {
                    setState(prevState => ({...prevState, fileUploaded: response.note }));
                } else {
                    setState(prevState => ({...prevState, fileUploaded: 'Returned empty result'}));
                }
            } else {
                props.setError('The file upload failed.');
            }
        } else {
            setState(prevState => ({...prevState, fileUploaded: name + ' uploaded for processing'}));
            props.refresh();
        }
        
        if (props.onUploadCompleted != null) {
            props.onUploadCompleted();
        }
    });
    
    return (
      <div className="FileUploader">
        <div className="rounded">
          <h3 className="mb-3">{props.title}</h3>
          <div className="btn btn-outline-success mb-3" disabled={!state.uploadEnabled}>
            <FileInput disabled={!state.uploadEnabled} accept={props.filteredExtensions || ""} uploader={uploader}>
              <span className="fa fa-upload"></span>Upload
            </FileInput>
          </div>
          {showForTesting && (
            <div>
              <Input type="checkbox" value={state.isTesting} name="testing" onChange={(e) => handleChange(e)} /> <Label check>&nbsp;For Testing</Label>
            </div>
          )}

          <div>{state.fileUploaded}</div>
        </div>
      </div>
    )
};


export default FileUploader;
