import React, { useState, useEffect, useRef } from 'react';
import { Spinner, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import AuthenticationAppSetup from "./authentication-app-setup";
import SuccessMessage from "./success-message";
import AuthenticationAppVerify from "./authentication-app-verify";
import VerificationMessage from "./verification-message";
import { makeApiCall } from "../../api/generic-api";
import { Navigate } from "react-router-dom";
import ReactLoading from "react-loading";

const TwoFactorAuthView = ({ handleLogout, loadUser, setError, applicationState }) => {
	const user = applicationState.authentication.user;
	const showTwoFa = user.twoFactorSetupRequired ? false: true;
	
	const [loading, setLoading] = useState(false);
	const [twoFaEnabled, setTwoFaEnabled] = useState(showTwoFa);
	const [code, setCode] = useState(new Array(6).fill(''));
	const [authCode, setAuthCode] = useState('');
	const [setupSuccess, setSetupSuccess] = useState(false);
	const [verified, setVerified] = useState(null);
	const [qrCode, setQrCode] = useState('');
	const [setupData, setSetupData] = useState(null);
	const inputRefs = useRef([]);
	
	const handleSetup = async () => {
		setLoading(true);
		const response = await makeApiCall('post', '/api/2fa/setup', { setup: setupData, code: parseInt(authCode, 10) });
		setLoading(false);
		if (response.ok) {
			setSetupSuccess(true);
			setTimeout(async () => {
				await loadUser()
			}, 2000)
			
			
		} else {
			console.log('responseX: ', response);
			setError(response.error);
		}
	};
	
	const handleVerify = async () => {
		setLoading(true);
		const response = await makeApiCall('post', '/api/2fa/authenticate', { code: parseInt(code.join(''), 10) });
		setLoading(false);
		if (response.ok) {
			setVerified(true);
			setTimeout(async () => {
				await loadUser()
			}, 2000)
		} else {
			setVerified(false);
			setError(response.error);
		}
	};
	
	const handleChange = (e, idx) => {
		const value = e.target.value;
		if (/^[0-9]$/.test(value) || value === '') {
			const newCode = [...code];
			newCode[idx] = value;
			setCode(newCode);
			if (value !== '' && idx < 5) {
				inputRefs.current[idx + 1].focus();
			}
		}
	};
	
	const handleKeyDown = (e, idx) => {
		if (e.key === 'Backspace' && code[idx] === '' && idx > 0) {
			inputRefs.current[idx - 1].focus();
		}
	};
	
	const handleCopy = () => {
		navigator.clipboard.writeText(setupData.secret);
		alert('Code copied to clipboard');
	};
	
	useEffect(() => {
		if (twoFaEnabled) {
			inputRefs.current[0].focus();
		}
	}, [twoFaEnabled]);
	
	useEffect(() => {
		const newCode = [...code];
		if (newCode.every((digit) => digit !== '')) {
			handleVerify();
		}
	}, [code]);
	
	useEffect(() => {
		const fetchSetupData = async () => {
			setLoading(true);
			const response = await makeApiCall('get', '/api/2fa/setup');
			setLoading(false);
			if (response.ok) {
				setSetupData(response.body);
				setQrCode(response.body.uri);
			} else {
				setError(response.error);
			}
		};
		
		fetchSetupData();
	}, []);
	
	return (
		<div className="d-flex justify-content-center align-items-center min-vh-100" style={{ backgroundColor: '#f8f9fa', overflow: 'hidden' }}>
			<Card className="p-4 shadow-sm" style={{ width: '100%', maxWidth: '500px', borderRadius: '10px' }}>
				{loading && (
					<div className="d-flex justify-content-center py-4">
						<ReactLoading type="spin" color="red" height={50} width={50} />
					</div>
				)}
				{!loading && !twoFaEnabled && !setupSuccess && (
					<AuthenticationAppSetup
						handleSetup={handleSetup}
						handleCopy={handleCopy}
						setError={setError}
						authCode={authCode}
						setAuthCode={setAuthCode}
						qrCode={qrCode}
						handleLogout={handleLogout} // Pass handleLogout here
						setupData={setupData}
					/>
				)}
				{setupSuccess && <SuccessMessage setSetupSuccess={setSetupSuccess} />}
				{!loading && twoFaEnabled && !setupSuccess && !verified && (
					<AuthenticationAppVerify
						code={code}
						handleChange={handleChange}
						handleKeyDown={handleKeyDown}
						inputRefs={inputRefs}
						verified={verified}
						setVerified={setVerified}
						setCode={setCode}
					/>
				)}
				{verified && <VerificationMessage />}
			</Card>
		</div>
	);
};

export default TwoFactorAuthView;
