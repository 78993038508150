import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useNotificationsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const params = {
    ...variables,
    sort: [{ createdAt: "desc" }],
  }

  return query(["notifications", params], () => axiosClient.get(`/notifications?${paramsToQuery(params)}`).then((res) => res.data), { ...rest })
}

export function useNotificationsInfo({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["notificationsInfo", variables], () => axiosClient.get(`/notifications?${paramsToQuery(variables)}`).then((res) => res.data), {
    ...rest,
  })
}
export function useNotificationDetailsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query("notificationDetails", () => axiosClient.get(`/notifications/${variables?.id || ""}?type=${variables?.type}`).then((res) => res.data), {
    ...rest,
  })
}
