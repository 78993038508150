import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useTemplatesQuery({ variables, ...rest } = {}) {
  const { query } = useRequestProcessor()

  const fields = [
    "id",
    "templateType",
    "testGroup",
    "testTypes",
    "dateCreated",
    "isSystemTemplate",
    "testTypeData",
    "templateFor",
    "mapping",
    "emailFrom",
    "emailSubject",
  ].map((item) => `"${item}"`)

  const params = encodeURIComponent(`[${fields.toString()}]`)

  return query(["templates-list", variables], () => axiosClient.get(`/dir/templates?${paramsToQuery(variables)}&fields=${params}`).then((res) => res.data), {
    ...rest,
  })
}
