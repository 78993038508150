import React from "react"
import Breadcrumb from "./custom/Breadcrumb"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { usePreviousTableQuery, useQuery } from "hooks/query"
import ProductDetails from "./custom/ProductDetails"
import { useParams } from "react-router"

function ProductViewInfo(props) {
    const permission = props && props.applicationState.authentication.user.permission
    const { id } = useParams()

    const previousTableQuery = usePreviousTableQuery("/products-view")

    const breadcrumbItems = [
        {
            label: "Products",
            link: `/products-view?${previousTableQuery}`,
        },
        {
            label: id,
            link: `/product-view?id=${id}`,
        },
    ]

    return (
        <div className="wide-container">
            <Breadcrumb title={id} breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Product Details</CardTitle>
                </CardHeader>
                <CardBody>
                    <ProductDetails productId={id} permission={permission} {...props} />
                </CardBody>
            </Card>
        </div>
    )
}

export default ProductViewInfo