import React from 'react';
import error from "../resources/error-img.png"
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

const PageNotFound = props => {
	
	return (
		<div className="account-pages my-5 pt-5">
			<Container>
				<Row>
					<Col lg="12">
						<div className="text-center mb-5">
							<h1 className="display-2 font-weight-medium">
								404
							</h1>
							<h4 className="text-uppercase">Sorry, you do not have access to this page</h4>
							<div className="mt-5 text-center">
								<Link
									className="btn btn-primary "
									to="/accession-sample"
								>
									Back to Dashboard
								</Link>
							</div>
						</div>
					</Col>
				</Row>
				<Row className="justify-content-center">
					<Col md="8" xl="6">
						<div>
							<img src={error} alt="" className="img-fluid" />
						</div>
					</Col>
				</Row>
			</Container>
		</div>
	)
};

export default PageNotFound;
