import React, { Component, useEffect, useState } from 'react';
import fetch from 'isomorphic-fetch';
import {
    Table, Container, Row, Col,
    FormGroup, Label, Input,
    ButtonGroup, Button, TabContent, TabPane,
    Nav, NavItem, NavLink,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import classnames from 'classnames';
import UserForm from './user-form';
// const BootstrapSwitchButton = require('bootstrap-switch-button-react');
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

const AdminAccount = props => {
    const [ state, setState ] = useState({
        standardUsers: [],
        deletingStandardUsers: [],
        administrators: [],
        deletingAdministrators: [],
        selectedStandardUser: null,
        selectedAdministrator: null,
        activeTab: 'standardUserTab',
        confirmingToggleSuspend: false,
        confirmingToggleDelete: false,
        confirmingToggleEmailPassword: false,
        editingStandardUser: false
    });
    
    const newStandardUser = () => {
        setState(prevState => ({
            ...prevState, selectedStandardUser: null, editingStandardUser: true
        }));
    }
    
    const newAdministrator = () => {
        setState(prevState => ({
            ...prevState, selectedAdministrator: null, editingAdministrator: true
        }));
    }
    
    const editStandardUser = ( user ) => {
        setState(prevState => ({
            ...prevState, selectedStandardUser: user, editingStandardUser: true
        }));
    }
    
    const editAdministrator = ( user ) => {
        setState(prevState => ({
            ...prevState, selectedAdministrator: user, editingAdministrator: true
        }));
    }
    
    const userFormDone = ( user, newUser ) => {
        if (user && (user._id || user.id)) {
            let found = false;
            let standardUsers = state.standardUsers;
            for (let i = 0; i < state.standardUsers.length; i++) {
                if (state.standardUsers[i].id === (user._id || user.id)) {
                    standardUsers[i] = user;
                    setState(prevState => ({
                        ...prevState, standardUsers: standardUsers, editingStandardUser: false
                    }));
                    found = true;
                }
            }
            if (!found) {
                standardUsers.push(user);
                setState(prevState => ({
                    ...prevState, standardUsers: standardUsers, editingStandardUser: false
                }));
            }
        } else {
            setState(prevState => ({
                ...prevState, editingStandardUser: false
            }));
        }
        
        refresh();
    }

    const adminFormDone = ( user ) => {
        if (user && (user._id || user.id)) {
            let found = false;
            let administrators = state.administrators;
            for (let i = 0; i < state.administrators.length; i++) {
                if (state.administrators[i].id === (user._id || user.id)) {
                    administrators[i] = user;
                    setState(prevState => ({
                        ...prevState, administrators: administrators, editingAdministrator: false
                    }));
                    found = true;
                }
            }
            if (!found) {
                administrators.push(user);
                setState(prevState => ({
                    ...prevState, administrators: administrators, editingAdministrator: false
                }));
            }
        } else {
            setState(prevState => ({
                ...prevState, editingAdministrator: false
            }));
        }
        
        refresh();
    }
    
    const toggleTab = ( tab ) => {
        if (state.activeTab !== tab) {
            setState(prevState => ({
                ...prevState, activeTab: tab, editingStandardUser: false, editingAdministrator: false
            }));
        }
    }

    const toggleSuspendOk = () => {
        setState(prevState => ({
            ...prevState, confirmingToggleSuspend: false
        }));
        if (state.selectedStandardUser) {
            let user = state.selectedStandardUser;
            let url = '/api/user/' + encodeURIComponent((user._id || user.id)) + '/status';
            let newStatus = null;
            if (user.status === 'live') {
                newStatus = 'suspended';
            } else if (user.status === 'suspended') {
                newStatus = 'live';
            } else {
                console.error('Status ' + user.status + ' is not right for suspend/unsuspend so ignoring');
                return;
            }
            fetch(url, {
                credentials: 'include',
                method: 'put',
                body: JSON.stringify({ status: newStatus }),
                headers: { "Content-Type": "application/json" }
            })
              .then(function ( response ) {
                  if (response.ok) {
                      user.status = newStatus;
                      setState(prevState => ({
                          ...prevState, selectedStandardUser: user
                      }));
                      return response.json();
                  }
                  throw new Error('/api/user call failed, status: ' + response.status);
              })
              .catch(( reason ) => {
                  props.setError('Failed to change user status to ' + newStatus);
              });
        }
    }
    
    const toggleSuspendCancel = () => {
        setState(prevState => ({
            ...prevState, confirmingToggleSuspend: false
        }));
    }
    
    const confirmToggleSuspend = ( user ) => {
        setState(prevState => ({
            ...prevState, confirmingToggleSuspend: true, selectedStandardUser: user
        }));
    }
    
    const toggleDeleteOk = () => {
        setState(prevState => ({
            ...prevState, confirmingToggleDelete: false
        }));
        
        let user = null;
        let deleting = null;
        let adminMode = false;
        
        if (state.activeTab === 'standardUserTab' && state.selectedStandardUser) {
            user = state.selectedStandardUser;
            deleting = state.deletingStandardUsers;
        }
        if (state.activeTab === 'administratorTab' && state.selectedAdministrator) {
            user = state.selectedAdministrator;
            deleting = state.deletingAdministrators;
            adminMode = true;
        }
        
        if (user) {
            let url = '/api/user/' + encodeURIComponent((user._id || user.id));
            let method = 'delete';
            fetch(url, {
                credentials: 'include', method: method
            })
              .then(function ( response ) {
                  if (response.ok) {
                      user.deleted = true;
                      
                      deleting.push((user._id || user.id));
                      
                      if (adminMode) {
                          setState(prevState => ({
                              ...prevState, selectedAdministrator: null, deletingAdministrators: deleting
                          }));
                      } else {
                          setState(prevState => ({
                              ...prevState, selectedStandardUser: null, deletingStandardUsers: deleting
                          }));
                      }
                      
                      return response.json();
                  }
                  
                  throw new Error('/api/user call failed, status: ' + response.status);
              })
              .catch(( reason ) => {
                  props.setError('Failed to delete user-service');
              });
        }
    }
    
    const toggleDeleteCancel = () => {
        setState(prevState => ({
            ...prevState, confirmingToggleDelete: false
        }));
    }
    
    const confirmToggleDelete = ( user ) => {
        if (state.activeTab === 'administratorTab') {
            setState(prevState => ({
                ...prevState, confirmingToggleDelete: true, selectedAdministrator: user
            }));
        } else {
            setState(prevState => ({
                ...prevState, confirmingToggleDelete: true, selectedStandardUser: user
            }));
        }
    }
    
    const refresh = () => {
        fetch('/api/users', { credentials: 'include' })
          .then(function ( response ) {
              if (response.ok) {
                  return response.json();
              }
              throw new Error('/api/users call failed, status: ' + response.status);
          })
          .then(users => {
              const standardUserList = users.filter(user => user.permission !== 'administrator');
              const administratorList = users.filter(user => user.permission === 'administrator');
              setState(prevState => ({
                  ...prevState, standardUsers: standardUserList, administrators: administratorList, selectedStandardUser: null
              }));
          })
          .catch(( reason ) => {
              props.setError('Failed to retrieve users');
          });
    }
    
    const toggleEmailPasswordOk = () => {
        setState(prevState => ({
            ...prevState, confirmingToggleEmailPassword: false
        }));
        
        let user = null;
        let adminMode = false;
        
        if (state.activeTab === 'standardUserTab' && state.selectedStandardUser) {
            user = state.selectedStandardUser;
        }
        
        if (state.activeTab === 'administratorTab' && state.selectedAdministrator) {
            user = state.selectedAdministrator;
            adminMode = true;
        }
        
        if (user) {
            let url = '/api/user/' + encodeURIComponent((user._id || user.id)) + '/password-email';
            let method = 'post';
            fetch(url, {
                credentials: 'include', method: method
            })
              .then(function ( response ) {
                  if (response.ok) {
                      if (adminMode) {
                          setState(prevState => ({
                              ...prevState,
                              selectedAdministrator: null
                          }));
                      } else {
                          setState(prevState => ({
                              ...prevState,
                              selectedStandardUser: null
                          }));
                      }
                      
                      return response.json();
                  }
                  throw new Error('/api/user/:username/password-email call failed, status: ' + response.status);
              })
              .catch(( reason ) => {
                  props.setError('Failed to email password to user-service');
              });
        }
    }
    
    const toggleEmailPasswordCancel = () => {
        setState(prevState => ({
            ...prevState,
            confirmingToggleEmailPassword: false
        }));
    }
    
    const confirmToggleEmailPassword = (user) => {
        if (state.activeTab === 'administratorTab') {
            setState(prevState => ({
                ...prevState,
                confirmingToggleEmailPassword: true,
                selectedAdministrator: user
            }));
        } else {
            setState(prevState => ({
                ...prevState,
                confirmingToggleEmailPassword: true,
                selectedStandardUser: user
            }));
        }
    }
    
    const newPassword = (user) => {
        setState(prevState => ({
            ...prevState,
            toggleNewPassword: true,
            newPassword: null,
            selectedStandardUser: user
        }));
    }
    
    const toggleNewPasswordOk = () => {
        let password = state.newPassword;
        if (password !== null && state.selectedStandardUser) {
            const id = state.selectedStandardUser._id || state.selectedStandardUser.id;
            let url = '/api/user/' + encodeURIComponent(id) + '/password';
            fetch(url, {
                credentials: 'include',
                method: 'put',
                body: JSON.stringify({ password: password }),
                headers: { "Content-Type": "application/json" }
            })
              .then(function ( response ) {
                  if (response.ok) {
                      return true;
                  } else {
                      throw new Error('password set call failed, status: ' + response.status);
                  }
              })
              .catch(( reason ) => {
                  props.setError('Failed to change user password');
              });
        }
        
        setState(prevState => ({
            ...prevState,
            toggleNewPassword: false
        }));
    }
    
    const toggleNewPasswordCancel = () => {
        setState(prevState => ({
            ...prevState,
            toggleNewPassword: false
        }));
    }

    const toggleChangeStatus = (user, checked) => {
        const userid = user.id || user._id;
        let newStatus = null
        if (checked) {
            newStatus = 'live'
        } else {
            newStatus = 'suspended'
        }
        let url = '/api/user/' + encodeURIComponent(userid) + '/status';
        fetch(url, {
            credentials: 'include',
            method: 'put',
            body: JSON.stringify({ status: newStatus }),
            headers: { "Content-Type": "application/json" }
        })
            .then(function ( response ) {
                if (response.ok) {
                    return true;
                } else {
                    throw new Error('status set call failed, status: ' + response.status);
                }
            })
            .catch(( reason ) => {
                props.setError('Failed to change user status');
            });
    };
    
    const handleNewPasswordChange = (e) => {
        e.persist();
        setState(prevState => ({
            ...prevState,
            newPassword: e.target.value
        }));
    }
    
    useEffect(() => {
        refresh();
    }, []);
    
    
    return (
      <div className="AdminAccount">
          <div className="wide-container">
              <Nav tabs style={{marginTop:'40px'}}>
                  <NavItem>
                      <NavLink
                        className={classnames({ active: state.activeTab === 'standardUserTab' })}
                        onClick={() => { toggleTab('standardUserTab'); }}
                      >
                          Standard Users
                      </NavLink>
                  </NavItem>
                  <NavItem>
                      <NavLink
                        className={classnames({ active: state.activeTab === 'administratorTab' })}
                        onClick={() => { toggleTab('administratorTab'); }}
                      >
                          Administrative Users
                      </NavLink>
                  </NavItem>
              </Nav>
              <TabContent activeTab={state.activeTab} style={{marginTop:'20px'}}>
                  <TabPane tabId="standardUserTab">
                      <Row>
                          <Col sm="12">
                              { state.editingStandardUser ?
                                <UserForm done={userFormDone}
                                          user={Object.assign({}, state.selectedStandardUser)}
                                          setError={props.setError} {...props}/>
                                : null
                              }
                              <Table responsive hover hidden={state.editingStandardUser}>
                                  <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Username (email)</th>
                                      <th>First Name</th>
                                      <th>Last name</th>
                                      {/*<th>Deleted</th>*/}
                                      <th>
                                          <Button
                                            outline color="success"
                                            onClick={() => {newStandardUser(); }}>
                                              <span className="fa fa-plus"></span> New</Button></th>
                                      <th></th>
                                      <th></th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {state.standardUsers.map((user, i) =>
                                    <tr key={(user._id || user.id)}
                                        hidden={state.deletingStandardUsers.includes((user._id || user.id))}
                                    >
                                        <td>{i + 1}</td>
                                        <td>{user.username}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>
                                            <Button
                                              outline color="primary"
                                              onClick={() => {editStandardUser(user); }}>
                                                <span className="fa fa-pencil"></span> Edit
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                              outline color="danger"
                                              onClick={() => {confirmToggleDelete(user); }}>
                                                <span className="fa fa-remove"></span> Delete
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                              outline color="success"
                                              onClick={() => {newPassword(user); }}>
                                                <span className="fa fa-key"></span> Set Password
                                            </Button>
                                        </td>
                                    </tr>
                                  )}
                                  </tbody>
                              </Table>
                          </Col>
                      </Row>
                  </TabPane>
                  <TabPane tabId="administratorTab">
                      <Row>
                          <Col sm="12">
                              { state.editingAdministrator ?
                                <UserForm done={adminFormDone}
                                          user={Object.assign({}, state.selectedAdministrator)}
                                          administrator={true}
                                          setError={props.setError} {...props} />
                                : null
                              }
                              
                              <Table responsive hover hidden={state.editingAdministrator}>
                                  <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Username</th>
                                      <th>First Name</th>
                                      <th>Last Name</th>
                                      <th>
                                          <Button
                                            outline color="success"
                                            onClick={() => {newAdministrator(); }}>
                                              <span className="fa fa-plus"></span> New</Button>
                                      </th>
                                      <th>Status</th>
                                      <th></th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  {state.administrators.map((user, i) =>
                                    <tr key={(user._id || user.id)} hidden={state.deletingAdministrators.includes((user._id || user.id))}>
                                        <td>{i + 1}</td>
                                        <td>{user.username}</td>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>
                                            <Button
                                              outline color="primary"
                                              onClick={() => {editAdministrator(user); }}>
                                                <span className="fa fa-pencil"></span> Edit</Button>
                                        </td>
                                        <td>
                                            {user.id || user._id ?
                                                <BootstrapSwitchButton
                                                    // checked={state.isChecked}
                                                    checked={user.status === 'live' ? true : false}
                                                    // checked={state.currentStatus === 'live' && user.username === state.selectedAdministrator.username ? true : false}
                                                    onlabel="Live"
                                                    offlabel="Suspended"
                                                    width="150"
                                                    onstyle="success"
                                                    offstyle="danger"
                                                    onChange={(checked) => {
                                                        toggleChangeStatus(user, checked);
                                                    }}
                                                />
                                                : null
                                            }
                                        
                                        </td>

                                    </tr>
                                  )}
                                  </tbody>
                              </Table>
                          </Col>
                      </Row>
                  </TabPane>
              </TabContent>
              <Modal isOpen={state.confirmingToggleSuspend} toggle={toggleSuspendCancel} backdrop={true}>
                  <ModalHeader toggle={toggleSuspendCancel}>Confirmation</ModalHeader>
                  <ModalBody>
                      {'Are you sure you want to ' + (state.selectedStandardUser && state.selectedStandardUser.status === 'live' ? 'suspend' : 'unsuspend') + ' this standardUser?'}
                  </ModalBody>
                  <ModalFooter>
                      <Button
                        outline color="warning"
                        onClick={toggleSuspendOk}>Ok</Button>{' '}
                      <Button
                        outline color="secondary"
                        onClick={toggleSuspendCancel}>Cancel</Button>
                  </ModalFooter>
              </Modal>
              <Modal isOpen={state.confirmingToggleDelete} toggle={toggleDeleteCancel} backdrop={true}>
                  <ModalHeader toggle={toggleDeleteCancel}>Confirmation</ModalHeader>
                  <ModalBody>
                      {
                          state.activeTab === 'standardUserTab'
                            ? 'Are you sure you want to delete this standard user account?'
                            : 'Are you sure you want to delete this administrator account?'
                      }
                  </ModalBody>
                  <ModalFooter>
                      <Button
                        outline color="danger"
                        onClick={toggleDeleteOk}>Ok</Button>{' '}
                      <Button
                        outline color="secondary"
                        onClick={toggleDeleteCancel}>Cancel</Button>
                  </ModalFooter>
              </Modal>
              <Modal isOpen={state.confirmingToggleEmailPassword} toggle={toggleEmailPasswordCancel} backdrop={true}>
                  <ModalHeader toggle={toggleEmailPasswordCancel}>Confirmation</ModalHeader>
                  <ModalBody>
                      {
                          state.activeTab === 'standardUserTab'
                            ? 'Email new password to this user (' + (state.selectedStandardUser && state.selectedStandardUser.name) + ')?'
                            : 'Email new password to this administrator (' + (state.selectedAdministrator && state.selectedAdministrator.name) + ')?'
                      }
                  </ModalBody>
                  <ModalFooter>
                      <Button
                        outline color="danger"
                        onClick={toggleEmailPasswordOk}>Ok</Button>{' '}
                      <Button
                        outline color="secondary"
                        onClick={toggleEmailPasswordCancel}>Cancel</Button>
                  </ModalFooter>
              </Modal>
              <Modal isOpen={state.toggleNewPassword} toggle={toggleNewPasswordCancel} backdrop={true}>
                  <ModalHeader toggle={toggleNewPasswordCancel}>Set Password</ModalHeader>
                  <ModalBody>
                      <FormGroup>
                          <Label for="newPassword">Enter new password for {state.selectedStandardUser ? state.selectedStandardUser.firstName + ' ' + state.selectedStandardUser.lastName : 'user'}</Label>
                          <Input type="text" name="newPassword" id="newPassword" placeholder="new password"
                                 onChange={handleNewPasswordChange}/>
                      </FormGroup>
                  </ModalBody>
                  <ModalFooter>
                      <Button
                        type="button"
                        outline color="primary"
                        onClick={toggleNewPasswordOk}>Ok</Button>{' '}
                      <Button
                        type="button"
                        outline color="secondary"
                        onClick={toggleNewPasswordCancel}>Cancel</Button>
                  </ModalFooter>
              </Modal>
          </div>
      </div>
    );
};

export default AdminAccount;
