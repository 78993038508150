import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import InsuranceCompanyForm from "../components/custom/InsuranceCompanyForm"
import { usePreviousTableQuery } from "hooks/query"

function insuranceCompanyViewForm(props) {
    const permission = props && props.applicationState.authentication.user.permission;
    const previousQuery = usePreviousTableQuery("/insurance-company-view")

    const breadcrumbItems = [
        {
            label: "Insurance Companies",
            link: `/insurance-company-view?${previousQuery}`,
        },
        {
            label: "Insurance Companies  Form",
            link: `/prices-iewForm`,
        },

    ]

    return (
        <div className="wide-container">
            <Breadcrumb title="Insurance Companies " breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Insurance Companies Form</CardTitle>
                </CardHeader>
                <CardBody>
                    <InsuranceCompanyForm permission={permission} />
                </CardBody>
            </Card>
        </div>
    )
}

export default insuranceCompanyViewForm
