import React, { useState, useEffect } from "react";
import { Row, Col, Label, Table, Button, Spinner, Alert, CardHeader, CardTitle } from "reactstrap";
import { usePriceList, usePriceDetailSubmit } from "api/getters";
import { makeApiCall } from "api/generic-api";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const PriceDetails = ({ id }) => {
    const [testTypeData, setTestTypeData] = useState(null);
    const [testConfigurations, setTestConfigurations] = useState([]);
    const [productName, setProductName] = useState("");
    const [editing, setEditing] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [price, setPrice] = useState(0);

    const { data, isLoading, refetch } = usePriceList({
        variables: {
            page: 1,
            selector: {
                _id: id,
            },
        },
    });

    const productData = data?.[0] || {};
    const priceHistory = productData?.history || [];

    const fetchConfigurations = async () => {
        try {
            const ProductResult = await makeApiCall("get", "/api/cancer-products");
            const configurations = ProductResult.body || [];
            setTestConfigurations(configurations);

            const matchingProduct = configurations.find(
                (product) => String(product.id) === String(productData.productId)
            );

            if (matchingProduct) {
                setTestTypeData(matchingProduct.testTypeData?.[0]);
                setProductName(matchingProduct.productName);
            }
        } catch (error) {
            console.error("Failed to fetch configurations", error);
        }
    };

    useEffect(() => {
        if (productData.productId) {
            fetchConfigurations();
        }
    }, [productData.productId]);

    useEffect(() => {
        if (productData?.price) {
            setPrice(productData.price);
            setIsSubmitted(false);
        }
    }, [productData]);

    const handleUpdate = () => {
        if (productData) {
            const numericPrice = parseFloat(price);
            if (isNaN(numericPrice)) {
                setErrorMessage("Please enter a valid price.");
                return;
            }

            const updatedProduct = {
                ...productData,
                price: numericPrice,
            };

            mutate(updatedProduct);
        } else {
            setErrorMessage("Product data is not available.");
        }
    };

    const { mutate, mutating, isError, error } = usePriceDetailSubmit({
        onSuccess: () => {
            setSuccessMessage("Price has been successfully updated.");
            setErrorMessage("");
            setIsSubmitted(true);
            setEditing(false);
            refetch();
        },
        onError: (error) => {
            const apiErrorMessage = error?.response?.data?.error;
            console.error("API Error:", apiErrorMessage);

            if (error?.response?.status === 409) {
                setErrorMessage(apiErrorMessage || "There was a problem submitting your data.");
            } else {
                setErrorMessage(apiErrorMessage || "An error occurred");
            }
        },
    });

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center ">
                <Spinner size="sm" />
            </div>
        )
    }

    return (
        <div>
            {!editing && (
                <>
                    {errorMessage && (
                        <Alert color="danger" className="mb-3">
                            {errorMessage}
                        </Alert>
                    )}
                    {successMessage && (
                        <Alert color="success" className="mb-3">
                            {successMessage}
                        </Alert>
                    )}
                </>
            )}

            <Row>
                <Col>
                    <div className="mb-3">
                        <Label htmlFor="product-name-input">Product Name</Label>
                        <input
                            disabled
                            type="text"
                            className="form-control"
                            value={productName || (productData ? productData?.productId : "")}
                        />
                    </div>
                </Col>


                <Col>
                    <div className="mb-3">
                        <Label htmlFor="payer-type-input">Payer Type *</Label>
                        <input
                            disabled
                            type="text"
                            className="form-control"
                            value={productData?.payerType
                                ? productData.payerType.charAt(0).toUpperCase() + productData.payerType.slice(1)
                                : ""}
                        />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className="mb-3">
                        <Label htmlFor="fee-code-input">Fee Code</Label>
                        <input
                            disabled
                            type="text"
                            className="form-control"
                            value={productData?.feeCode || ""}
                        />
                    </div>
                </Col>
                <Col>
                    <div className="mb-3">
                        <Label htmlFor="price-input">Price *</Label>
                        <input
                            disabled={!editing}
                            type="number"
                            className="form-control"
                            value={price}
                            onChange={(e) => setPrice(e.target.value)}
                        />
                    </div>
                </Col>
            </Row>

            <Row className="mt-5">
                <Col>
                    <CardHeader>
                        <CardTitle>Price History</CardTitle>
                    </CardHeader>

                    <Table striped responsive>
                        <thead>
                            <tr>
                                <th>Author</th>
                                <th>Price</th>
                                <th>Action</th>
                                <th>Updated At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {priceHistory.length > 0 ? (
                                priceHistory.map((entry, index) => (
                                    <tr key={index}>
                                        <td>{entry.author.author}</td>
                                        <td>{entry.price}</td>
                                        <td>{entry.action}</td>
                                        <td>{dayjs(entry.updatedTimestamp).format("MM-DD-YYYY")}</td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">
                                        No price history available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>

            <div className="d-flex justify-content-end gap-3 mt-4">
                {editing ? (
                    <>
                        <Button
                            color="danger"
                            type="button"
                            onClick={handleUpdate}
                            disabled={isSubmitted || mutating}
                        >
                            Update
                            {mutating && <Spinner size="sm" className="ms-2" />}
                        </Button>
                        <Button
                            key="cancel"
                            color="secondary"
                            type="button"
                            onClick={() => {
                                setEditing(false);
                                setSuccessMessage("");
                                setErrorMessage("");
                                setPrice(productData?.price || 0);
                            }}
                        >
                            Cancel
                        </Button>
                    </>
                ) : (
                    <Button
                        color="primary"
                        type="button"
                        onClick={() => setEditing(true)}
                    >
                        Edit
                    </Button>
                )}
            </div>
        </div>
    );
};

export default PriceDetails;
