import React from 'react';

import lookups from "../configuration/lookups";
import DataWow from "./data-wow";
import { makeApiCall } from "../api/generic-api";
import prescriptionFullDefinition from "../configuration/prescription-full-definition";
import {Col, Row} from "reactstrap";

function PrescriptionView(props) {

    const structure = prescriptionFullDefinition;

    // covid tests
    // const fixedSelector = {
    //     'data.testType': 'covid',
    //     "$or": [
    //         {
    //             "status": {
    //                 "$exists": false
    //             }
    //         },
    //         {
    //             status: {
    //                 "$ne": "done"
    //             }
    //         }
    //     ]
    // };

    const userSelector = {
        heading: {
            label: 'Filter',
            style: {display: 'inline-block', fontWeight: 800}
        },
        lookups: lookups,
        controlBlockStyle: {display: 'inline-block'},
        controls: [
            {
                label: 'First Name',
                name: 'data.firstName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Last Name',
                name: 'data.lastName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Date of Birth',
                name: 'data.dateOfBirth',
                type: 'date',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'},
                max: "9999-12-31",
            },
        ]
    };

    return (
        <div>
            <Row style={{marginTop: '30px'}}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>Prescription Requests</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>
            <DataWow
                className={'wide-container'}
                structure={structure}
                userSelector={userSelector}
                formDataField={'data'}
                loadData={makeApiCall}
                {...props} />
        </div>
    );
}

export default PrescriptionView;
