import React, { useState, useEffect } from "react";
import {
    Button,
    Label,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    Spinner,
    DropdownMenu,
    DropdownItem,
    Alert,
} from "reactstrap";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { makeApiCall } from "api/generic-api";
import {
    useProductDetailSubmit,
    useProductListInfo,
} from "api/getters";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import lookups from "../../configuration/lookups"

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const validationSchema = Yup.object().shape({
    productCode: Yup.string().required("Product Code is required"),
    productName: Yup.string().required("Product Name is required"),
    status: Yup.string().required("Status is required"),
    testType: Yup.array().min(0, "Please select at least one Test Type"),
});


const ProductsDetails = ({ productId }) => {
    const [dropdownOpen, setDropdownOpen] = useState({
        status: false,
        extrasConfiguration: false,
        testType: false,
        productGroup: false,
    });

    const [selected, setSelected] = useState({
        status: "",
        testTypes: [],
        extrasConfiguration: [],
        productGroup: "",
    });

    const [messages, setMessages] = useState({ success: "", error: "" });
    const [configurations, setConfigurations] = useState({
        test: [],
        extras: [],
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [editing, setEditing] = useState(false);
    const [recentProductData, setRecentProductData] = useState(null);


    const { data } = useProductListInfo({
        variables: {
            page: 1,
            selector: productId ? { _id: { $regex: productId } } : {},
        },
    });

    const {
        control,
        handleSubmit,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });

    useEffect(() => {
        const fetchConfigurations = async () => {
            setIsLoading(true);
            try {
                const [testResult, extrasResult] = await Promise.all([
                    makeApiCall("get", "/api/test-configuration"),
                    makeApiCall("get", "/api/extras-products"),
                ]);
                setConfigurations({
                    test: testResult.body,
                    extras: extrasResult.body,
                });
            } catch (error) {
                const errorMessage =
                    error.response?.data?.error || "Failed to fetch configurations";
                setMessages((prev) => ({ ...prev, error: errorMessage }));
            } finally {
                setIsLoading(false);
            }
        };
        fetchConfigurations();
    }, []);

    useEffect(() => {
        if (data?.[0]) {
            const product = data[0];
            let extrasConfig = product.extrasConfiguration || [];
            setSelected({
                ...selected,
                extrasConfiguration: extrasConfig.map(
                    (config) => config.label || config
                ),
                status:
                    product.status,
                testTypes: product.testTypeData.map((type) => type.label) || [],
                productGroup: product.productGroup?.toLowerCase() || "",
            });
            setRecentProductData({
                extrasConfiguration: extrasConfig
                    .filter((config) => config !== null)
                    .map((config) => config.label || config),
                status: product.status,
                testTypes: product.testTypeData.map((type) => type.label) || [],
                productGroup: product?.productGroup?.toLowerCase() || "",
            });
        }
    }, [data]);

    const productData = data?.[0] || {};

    const { mutate, isLoading: mutating } = useProductDetailSubmit({
        onSuccess: () => {
            setMessages((prev) => ({
                ...prev,
                success: "Product has been successfully updated.",
            }));
            setIsSubmitted(true);
        },
        onError: (error) => {
            const apiErrorMessage =
                error?.response?.data?.error || "An error occurred";
            setMessages((prev) => ({ ...prev, error: apiErrorMessage }));
        },
    });


    const toggleDropdown = (type) => {
        setDropdownOpen((prev) => ({ ...prev, [type]: !prev[type] }));
    };


    const handleSelectionChange = (type, value) => {
        setSelected((prev) => {
            const newSelection = Array.isArray(prev[type]) ? (prev[type].includes(value) ? prev[type].filter((v) => v !== value) : [...prev[type], value]) : value

            if (type === "status" && errors.status) {
                clearErrors("status")
            }
            if (type === "productGroup" && errors.productGroup) {
                clearErrors("productGroup")
            }

            setValue(type === "testTypes" ? "testType" : type, newSelection)
            return { ...prev, [type]: newSelection }
        })
    }
    const onSubmit = async (data) => {
        const formValues = {
            id: data.productCode,
            productName: data.productName,
            testTypeData: selected.testTypes.map((type) => ({
                label: type,
                value: type.charAt(0).toUpperCase(),
            })),
            extrasConfiguration: selected.extrasConfiguration.map((type) => ({
                label: type,
                value: type.charAt(0).toUpperCase(),
            })),
            status: selected.status.toLowerCase(),
            productGroup: selected.productGroup.toLowerCase() || null,
        }

        await mutate(formValues)
    }

    const getSelectedTestTypesText = () => {
        return selected.testTypes.length > 0
            ? selected.testTypes.join(", ")
            : "Select Test Type";
    };
    const getSelectedExtrasText = () => {
        return selected.extrasConfiguration.length > 0
            ? selected.extrasConfiguration.join(", ")
            : "Select Extras Configuration";
    };


    const getDropdownToggleText = (type, placeholder) =>
        selected[type]?.length > 0 ? selected[type].join(", ") : placeholder;

    const testTypesArray = Array.from(
        new Set(configurations.test.map((item) => item.name))
    );
    const extrasArray = Array.from(
        new Set(configurations.extras.flatMap((item) => item.extraName || []))
    );

    const handleCancel = () => {
        if (recentProductData) {
            setSelected((prev) => ({
                ...prev,
                extrasConfiguration: recentProductData.extrasConfiguration,
                status: recentProductData.status,
                testTypes: recentProductData.testTypes,
                productGroup: recentProductData.productGroup,
            }));
        }
        clearErrors();
        setEditing(false);
    };



    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner size="sm" />
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {messages.success && <Alert color="success">{messages.success}</Alert>}
            {messages.error && <Alert color="danger">{messages.error}</Alert>}
            <Row>
                <Col md={6}>
                    <Label htmlFor="formrow-text-Input">Product Code *</Label>
                    <Controller
                        name="productCode"
                        control={control}
                        defaultValue={data?.[0]?.id || ""}
                        render={({ field }) => (
                            <input
                                {...field}
                                type="text"
                                placeholder="Enter Product Code"
                                className={`form-control ${errors.productCode ? 'is-invalid' : ''}`}
                                disabled
                                readOnly
                            />
                        )}
                    />
                    {errors.productCode && (
                        <div className="invalid-feedback">
                            {errors.productCode.message}
                        </div>
                    )}
                </Col>

                <Col className="mb-3" lg="6">
                    <Label className="form-label">Extras Configuration</Label>
                    {editing ? (
                        <Dropdown
                            isOpen={dropdownOpen.extrasConfiguration}
                            toggle={() => toggleDropdown("extrasConfiguration")}
                        >
                            <DropdownToggle
                                className="form-control text-start px-3"
                                disabled={isSubmitted}
                                style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #ccc",
                                    color: "#000",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                                caret
                            >
                                {getDropdownToggleText(
                                    "extrasConfiguration",
                                    "Select Extras Configuration"
                                )}
                            </DropdownToggle>
                            <DropdownMenu className="mt-2">
                                {extrasArray.map((extra) => (
                                    <DropdownItem
                                        key={extra}
                                        onClick={() => handleSelectionChange("extrasConfiguration", extra)}
                                    >
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                checked={selected.extrasConfiguration.includes(extra)}
                                                readOnly
                                            />
                                            <label className="form-check-label" style={{ color: "black" }}>
                                                {extra}
                                            </label>
                                        </div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    ) : (
                        <input
                            type="text"
                            className="form-control"
                            value={getSelectedExtrasText() || ""}
                            readOnly
                            disabled={!editing || isSubmitted}
                        />
                    )}
                </Col>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="formrow-text-Input">Product Name *</Label>

                        {editing ? (
                            <Controller
                                name="productName"
                                control={control}
                                defaultValue={productData?.productName || ""}
                                render={({ field }) => (
                                    <input
                                        placeholder="Enter Product Name"
                                        {...field}
                                        className={`form-control ${errors.productName ? 'is-invalid' : ''}`}
                                        disabled={isSubmitted}
                                    />
                                )}
                            />
                        ) : (
                            <input
                                type="text"
                                className={`form-control ${errors.productName ? 'is-invalid' : ''}`}
                                value={productData?.productName || ""}
                                readOnly
                                disabled={!editing || isSubmitted}
                            />
                        )}
                        {errors.productName && (
                            <div className="invalid-feedback">
                                {errors.productName.message}
                            </div>
                        )}

                    </div>


                </Col>
                <Col md={6}>
                    <Label className="form-label">Status *</Label>

                    {editing ? (
                        <Controller
                            defaultValue={productData?.status || ""}
                            name="status"
                            control={control}
                            render={({ field }) => (
                                <Dropdown className={errors.status ? 'is-invalid' : ''} isOpen={dropdownOpen.status} toggle={() => toggleDropdown("status")}>
                                    <DropdownToggle
                                        className="form-control "
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "1px solid #ccc",
                                            color: "#000",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        caret
                                        disabled={isSubmitted}
                                    >
                                        {field.value
                                            ? `${field.value.charAt(0).toUpperCase()}${field.value.slice(1)}`
                                            : "Select Status"}
                                    </DropdownToggle>
                                    <DropdownMenu className="mt-2" style={{ backgroundColor: "white" }}>
                                        {lookups
                                            .filter((item) => item.type === "productStatus")
                                            .map((item) => (
                                                <DropdownItem
                                                    key={item._id}
                                                    onClick={() => {
                                                        field.onChange(item.value);
                                                        handleSelectionChange("status", item.value);
                                                    }}
                                                >
                                                    {item.value}
                                                </DropdownItem>
                                            ))}
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                        />
                    ) : (
                        <input
                            type="text"
                            className="form-control"
                            value={
                                productData?.status
                                    ? `${productData.status.charAt(0).toUpperCase()}${productData.status.slice(1)}`
                                    : "Select Status"
                            }
                            readOnly
                            disabled
                        />
                    )}
                    {errors.status && (
                        <div className="invalid-feedback d-block">
                            {errors.status.message}
                        </div>
                    )}
                </Col>
                <Col className="mb-3" lg="6">
                    <Label className="form-label">Test Type *</Label>
                    {editing ? (
                        <Dropdown
                            isOpen={dropdownOpen.testType} toggle={() => toggleDropdown("testType")}>
                            <DropdownToggle
                                className={`form-control text-start px-3 ${errors.testType ? "is-invalid" : ""}`}
                                disabled={!editing || isSubmitted}
                                style={{
                                    backgroundColor: "white",
                                    borderColor: errors.testType ? "red" : "#ced4da",
                                    color: "black",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                                caret={!errors.testType}
                            >

                                <span>{getSelectedTestTypesText("testTypes", "Select Test Type")}</span>
                                <span className="dropdown-caret" style={{ marginLeft: "auto" }}>
                                    <i className="caret" />
                                </span>
                            </DropdownToggle>
                            <DropdownMenu className="mt-2" style={{ backgroundColor: "white" }}>
                                {testTypesArray.map((type) => (
                                    <DropdownItem key={type} onClick={() => handleSelectionChange("testTypes", type)}>
                                        <div className="form-check">
                                            <input type="checkbox" className="form-check-input" checked={selected.testTypes.includes(type)} readOnly />
                                            <label className="form-check-label" style={{ color: "black" }}>
                                                {type}
                                            </label>
                                        </div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    ) : (
                        <input
                            type="text"
                            className="form-control"
                            value={getSelectedTestTypesText() || ""}
                            readOnly
                            disabled={!editing || isSubmitted}
                        />
                    )}
                    {errors.testType && (
                        <div className="invalid-feedback d-block">
                            {errors.testType.message}
                        </div>
                    )}
                </Col>
                <Col className="mb-3" lg="6">
                    <Label className="form-label">Product Group</Label>

                    {editing ? (
                        <Controller
                            name="productGroup"
                            control={control}
                            defaultValue={productData?.productGroup || ""}
                            render={({ field }) => (
                                <Dropdown
                                    isOpen={dropdownOpen.productGroup}
                                    toggle={() => toggleDropdown("productGroup")}
                                >
                                    <DropdownToggle
                                        className={`form-control `}
                                        disabled={!editing || isSubmitted}
                                        style={{
                                            backgroundColor: "transparent",
                                            border: "1px solid #ccc",
                                            color: "#000",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            alignItems: "center",
                                        }}
                                        caret
                                    >
                                        {field.value || "Select Product Group"}
                                    </DropdownToggle>
                                    <DropdownMenu className="mt-2" style={{ backgroundColor: "white" }}>
                                        {lookups
                                            .filter((item) => item.type === "productGroup")
                                            .map((item) => (
                                                <DropdownItem
                                                    key={item._id}
                                                    onClick={() => handleSelectionChange("productGroup", item.value)}
                                                >
                                                    {item.value}
                                                </DropdownItem>
                                            ))}
                                    </DropdownMenu>
                                </Dropdown>
                            )}
                        />
                    ) : (
                        <input
                            type="text"
                            className="form-control"
                            value={productData?.productGroup || "Select Product Group"}
                            readOnly
                            disabled
                        />
                    )}

                </Col>

            </Row>
            <div className="d-flex justify-content-end gap-3 mt-4">
                {editing ? (
                    <>
                        <Button
                            key="update"
                            color="danger"
                            type="submit"
                            disabled={isSubmitted || mutating}
                        >
                            Update
                            {mutating && <Spinner size="sm" className="ms-2" />}
                        </Button>
                        {!isSubmitted && (
                            <Button
                                key="cancel"
                                color="secondary"
                                type="button"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        )}
                    </>
                ) : (
                    <Button
                        key="edit"
                        color="primary"
                        type="button"
                        onClick={() => setEditing(true)}
                    >
                        Edit
                    </Button>
                )}
            </div>
        </form>
    );
};
export default ProductsDetails;
