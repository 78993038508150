import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { Row, Col, Breadcrumb as BootstrapBreadcrumb, BreadcrumbItem } from "reactstrap"

const Breadcrumb = ({ title, breadcrumbItems = [] }) => {
  return (
    <Row className="mb-4">
      <Col className="col-12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-sm-0 font-size-18">{title}</h4>
          <div className="page-title-right">
            <BootstrapBreadcrumb className="m-0">
              {Boolean(breadcrumbItems.length > 1) &&
                breadcrumbItems.map((breadcrumb, index) => (
                  <BreadcrumbItem className="text-truncate text-capitalize" key={index} active={index === breadcrumbItems.length - 1}>
                    {index === breadcrumbItems.length - 1 ? (
                      // <span>{breadcrumb.label?.replace(/\-/g, " ")}</span>
                      <span>{breadcrumb.label}</span>
                    ) : (
                      <Link to={breadcrumb.link}>{breadcrumb.label.replace(/\-/g, " ")}</Link>
                    )}
                  </BreadcrumbItem>
                ))}
            </BootstrapBreadcrumb>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  title: PropTypes.string.isRequired,
  breadcrumbItems: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

Breadcrumb.defaultProps = {
  breadcrumbItems: [],
}

export default Breadcrumb
