import React, { useState, useEffect, forwardRef, useImperativeHandle } from "react"
import { Button, Card, CardBody, CardHeader, CardTitle } from "reactstrap"
//import classnames from 'classnames';
import { get, objectsIdentical, getValueByDotNotation, curry } from "../utility/client-utility"
import GeneralTable from "./general-table"
import GeneralForm from "./general-form"
import DataControls from "./data-controls"
import csvDownloader from "./csv-downloader"
import { makeApiCall } from "../api/generic-api"
import DataSelector from "./data-selector"
import { useQueryParam, NumberParam, StringParam, useQueryParams } from "use-query-params"
import { decrypt, encrypt } from "../utility/cryptr-service"
import ReactLoading from "react-loading"
import { useAlert } from "react-alert"
import ProcessSampleForm from "./process-sample/process-sample-form"
import lookups from "../configuration/lookups"
import { dataToQuery, stringQueryToData } from "../utility/uri-utility"
import { isEqual } from "lodash"
import processSampleFullDefinition from "configuration/process-sample-full-definition"
import { usePreviousTableQuery } from "hooks/query"
import { useNavigate, useParams } from "react-router"
import Breadcrumb from "./custom/Breadcrumb"

function ProcessSampleInfoView(props) {
  const { id } = useParams()
  const navigate = useNavigate()
  const previousTableQuery = usePreviousTableQuery("/process-sample")

  const structure = processSampleFullDefinition

  const [cDnaData, setCDnaData] = useState([])
  const [searchStatus, setSearchStatus] = useState("")
  const [displayMode, setDisplayMode] = useState("")
  const [editForm, setEditForm] = useState(false)

  const refresh = () => {}
  const closeForm = () => {
    navigate(`/process-sample?${previousTableQuery}`)
  }

  const breadcrumbItems = [
    {
      label: "Process Samples",
      link: `/process-sample?${previousTableQuery}`,
    },
    {
      label: id,
      link: `/process-sample/${id}`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title={id} breadcrumbItems={breadcrumbItems} />
      <ProcessSampleForm
        formDefinition={structure.form}
        formState={{ id }}
        loadData={makeApiCall}
        lookups={lookups}
        close={closeForm}
        editMode={editForm}
        setEditMode={setEditForm}
        searchStatus={searchStatus}
        data={cDnaData}
        setDisplay={setDisplayMode}
        refresh={refresh}
        {...props}
      />
    </div>
  )
}

export default ProcessSampleInfoView
