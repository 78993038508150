import React from "react";
import Breadcrumb from "./custom/Breadcrumb";
import { Card, CardBody } from "reactstrap";
import { InsuranceCompanyTable } from "./tables/InsuranceCompanyTable";

function InsuredView(props) {
    const permission = props && props.applicationState.authentication.user.permission;

    const breadcrumbItems = [
        {
            label: "Insurance Companies",
            link: `/insurance-company-view`,
        },
    ];

    return (
        <div className="wide-container">
            <Breadcrumb title="Insurance Companies " breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardBody>
                    <InsuranceCompanyTable
                        permission={permission}
                        allowPageSize
                        allowPaginate
                        isGlobalFilter
                    />
                </CardBody>
            </Card>
        </div>
    );
}

export default InsuredView;
