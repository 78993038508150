import React, { useCallback, useMemo } from "react"
import { Link } from "react-router-dom"
import { usePatientsQuery } from "api/getters"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
dayjs.extend(utc)

export function PatientsTable({ pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector,
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: patientsData,
    isLoading,
    isFetching,
    refetch,
    error,
  } = usePatientsQuery({
    variables: queryVariables,
  })

  const columns = useMemo(
    () => [
      {
        Header: "Patient ID",
        accessor: "id",
        hasSort: true,
        csvAccessor: "csvId",
      },
      // {
      //   Header: "Client Unique ID",
      //   accessor: "",
      //   hasSort: true,
      // },
      {
        Header: "Email",
        accessor: "email",
        hasSort: true,
      },
      {
        Header: "First Name",
        accessor: "firstName",
        hasSort: true,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        hasSort: true,
      },
      {
        Header: "Date Of Birth",
        accessor: "dateOfBirth",
        hasSort: true,
      },
      {
        Header: "Group ID",
        accessor: "prefix",
        hasSort: true,
      },
      {
        Header: "Insured",
        accessor: "insured",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const getProcessedTableData = useCallback(
    (_patientsData) =>
      (_patientsData &&
        _patientsData.map((item) => ({
          // id: (
          //   <Link to={`/subject-info/${item.id}/personal`} className="text-secondary">
          //     {item.id}
          //   </Link>
          // ),
          id: item.id,
          csvId: item.id,
          prefix: item.prefix,
          clientId: item.clientId,
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName,
          dateOfBirth: dayjs(item?.dateOfBirth).format("YYYY/MM/DD"),
          insured: item.insured ? "Yes" : "No",
          action: (
            <Link to={`/subject-info/${item.id}/personal`} className="btn btn-outline-primary">
              View
            </Link>
          ),
        }))) ||
      [],
    [],
  )

  const data = useMemo(() => getProcessedTableData(patientsData), [patientsData, getProcessedTableData])

  const filters = useMemo(() => {
    return [
      {
        field: "_id",
        operator: "$eq",
        label: "Search Patient ID",
        placeholder: "Type in Patient ID",
        removeSpaces: true,
      },
      {
        field: "prefix",
        operator: "$eq",
        label: "Search Group ID",
        placeholder: "Type in Group ID",
        removeSpaces: true,
      },
      {
        field: "firstName",
        operator: "$eq",
        label: "Search First Name",
        placeholder: "Type in First Name",
      },
      {
        field: "lastName",
        operator: "$eq",
        label: "Search Last Name",
        placeholder: "Type in Last Name",
      },
      {
        field: "email",
        operator: "$eq",
        label: "Search Email",
        placeholder: "Type in Email",
        removeSpaces: true,
      },
      {
        label: "Date of Birth",
        field: "dateOfBirth",
        operator: "$eq",
        type: "date",
      },
    ]
  }, [])

  return (
    <TableContainer
      refetch={refetch}
      columns={columns}
      data={data}
      isLoading={isLoading || isFetching}
      filters={filters}
      selector={selector}
      sort={sort}
      downloadFileName="Patients"
      defaultSort="id"
      defaultSortDir="asc"
      csvQuery={usePatientsQuery}
      queryVariables={queryVariables}
      getProcessedTableData={getProcessedTableData}
      {...rest}
    />
  )
}
