import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import {
    Row,
    Col,
    Button,
    Input,
    Alert,
    Label,
    Container,
    Table,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Modal,
    FormGroup,
    ButtonDropdown,
    DropdownToggle, DropdownMenu, DropdownItem, Dropdown
} from 'reactstrap';
import classnames from 'classnames';
import { func, get, getValueByDotNotation } from '../utility/client-utility';
import fetch from 'isomorphic-fetch';
import ReactLoading from "react-loading";
import { makeApiCall, makeApiDownloadCall } from "../api/generic-api";
import { useAlert } from "react-alert";
import netestRequisitionFormDefinition from "../configuration/netest-requisition-form-definition";
import moment from 'moment';
import testFullDefinition from '../configuration/test-full-definition';
import TestView from './test-view';

import { saveAs } from 'file-saver';
import { Link } from 'react-router-dom';


const { Parser } = require('json2csv');

const capitalize = (value) => {
    return value && value.replace ? value.replace(/^\w/, c => c.toUpperCase()) : value;
};

function GeneralTable(props) {
    const { currentRecord } = props;

    const formDefinition = netestRequisitionFormDefinition.generateId;

    const loadData = props && props.loadData;
    const table = get(() => props.table);
    const columns = get(() => props.table.columns);
    const rowActions = get(() => props.rowActions, []);
    const key = get(() => props.table.key);
    const firstRowNumber = get(() => props.firstRowNumber, 1);
    const data = get(() => props.data, []);
    const isAdmin = get(() => props.isAdmin);
    const currentUserRole = get(() => props.currentRole)
    const usersData = get(() => props.users, []);
    const refresh = get(() => props.refresh, []);

    const [toggleModal, setToggleModal] = useState(false);
    const [toggleAuditModal, setToggleAuditModal] = useState(false);

    const [toggleDeleteModal, setToggleDeleteModal] = useState(false);
    const [selectedEntry, setSelectedEntry] = useState(null);
    const [selectedRecord, setSelectedRecord] = useState({});
    const [toggleSampleIdModal, setSampleIdModal] = useState(false);
    const [showRejectModal, setShowRejectModal] = useState(false);
    const [openPcrDetailModal, setOpenPcrDetailModal] = useState(false);
    // const [warning, setWarning] = useState(false);

    const alert = useAlert();

    const [newOwner, setNewOwner] = useState("");
    const [selectedTray, setTray] = useState("");
    const [selectedSampleId, setSelectedSampleId] = useState(null);
    const [currentOwner, setOwner] = useState("");
    const [dropdownOpen, setOpen] = useState(false);
    const [alertState, setAlertState] = useState();
    const [alertBadMessage, setAlertBadMessage] = useState();
    const [disabled, setDisabled] = useState(false);
    const [sampleId, setSampleId] = useState("");
    const [generateDisabled, setGenerateDisabled] = useState(false);


    const [transactionHistory, setTransactionHistory] = useState([]);
    const [loadingTransactionHistory, setLoadingTransactionHistory] = useState(false);

    if (!table) {
        console.warn('No table definition');
        return null;
    }
    if (!columns) {
        console.warn('No table columns definitions');
        return null;
    }

    const getColumnValue = (record, column, index, firstRowNumber) => {
        const value = column.type === 'rowNumber' ? index + firstRowNumber : getValueByDotNotation(record, column.field);

        if (value && column.type === 'array' && column.field === 'product') {
            const newValue = value.join(', ');
            return newValue;
        }

        if (value && column.type === 'array') {
            const newValue = String(value);
            return newValue;
        }
        if (column.type === 'text' && column.field === 'physicianFullName') {
            return value ? value : 'N/A';
        }

        if (column.type === 'text' && column.field === 'subjectName') {
            return value ? value : 'N/A';
        }
        if (column.type === 'text' && column.field === 'sampleLocation') {
            return value ? value : 'Unset';
        }

        if (column.type === 'text' && column.field === 'status') {
            return value ? value : 'unset';
        }

        if (column.type === 'text' && column.field === 'testResult.individualResult') {
            return value ? value.toUpperCase() : 'Unset';
        }

        if (value && column.type === 'timestamp') {
            if (column.format === 'toLocalizedDateString') {
                // return new Date(value).toLocaleString();
                const date = new Date(value).toLocaleDateString('en-US', { timeZone: 'America/New_York' });
                return date;
            } else if (column.format == 'toDateString') {
                const date = new Date(value);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '');
                const day = String(date.getDate()).padStart(2, '');
    
                return `${month}/${day}/${year}`;
            }
        }
    
        
        
        if (value && column.format === 'capitalized') {
            return capitalize(value);
        }
        if (column.type === 'boolean') {
            if (column.name === 'processResult') {
                return value === true ? 'Success' : (value === false ? 'Failed' : 'Unset');
            } else {
                return value === true ? 'Yes' : (value === false ? 'No' : 'Unset');
            }
        }
        if (column.type === 'checkbox') {
            return <input type="checkbox" checked={props.checkedInput.includes(record?.id)} value={record?.id} onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                    const updatedCheckedInput = [...props.checkedInput, record.id];
                    props.setCheckedInput(updatedCheckedInput);
                } else {
                    const updatedCheckedInput = props.checkedInput.filter(item => {
                        return item !== record?.id
                    })
                    props.setCheckedInput(updatedCheckedInput);
                }
            }} />
        }

        if (column.maxlength) {
            return value?.length > column.maxlength ? value.substring(0, column.maxlength - 3) + "..." : value;
        }

        return value;
    };

    // props.setSort([
    //     {'data.gatewayTimestamp' : 'desc'}
    // ]);

    const visible = (visibilityRule, record, action) => {
        if (action === 'reassign') {
            //console.log('reassign', record);

            if (((isAdmin || (!isAdmin && currentUserRole.includes("laboratory"))) && record.status === 'Filling' && record.stage === 1) ||
                ((isAdmin || (!isAdmin && currentUserRole.includes("laboratory"))) && record.status === 'Filling' && record.stage === 2)) {
                return true;
            } else {
                return false;
            }
        } else if (action === 'generateId') {
            if (!record.data.status && record.status !== "rejected" && !record.data.sampleId) {
                return true;
            } else {
                return false;
            }
        } else if (action === 'view') {
            if (!currentRecord?.testing) {
                if (record.individualResult) {
                    if (record.individualResult == 'pass') {
                        return true;
                    } else {
                        return false;
                    }
                }
                return true
            }

            return false;
        } else {
            if (!visibilityRule) {
                return true;
            }
            if (!record) {
                return false;
            }
            for (let property in visibilityRule) {
                if (visibilityRule.hasOwnProperty(property)) {

                    if (visibilityRule[property] === null) {
                        if (!(record[property] === undefined || record[property] === null || record[property] === '')) {
                            return false;
                        }
                    } else if (record[property] !== visibilityRule[property]) {
                        return false;
                    }
                }
            }
            return true;
        }
    };

    const viewModal = (trayId, currentOwner) => {
        setToggleModal(!toggleModal);
        setTray(trayId);
        setOwner(currentOwner);
        console.log('tray view modal', trayId)
    };

    const viewAuditModal = async ({ sampleId, testId }) => {
        const id = sampleId || testId
        console.log('sampleIdX: ', sampleId);
        setToggleAuditModal(!toggleAuditModal);
        setSelectedSampleId(id)

        const sampleData = data.filter(item => item?.id == id || item?.testId == id)
        console.log('sampleDataX: ', sampleData);
        console.log('sampleData[0].transactionHistoryX: ', sampleData[0]?.transactionHistory);

        if (testId) {
            setTransactionHistory([]);
            setLoadingTransactionHistory(true)
            const result = await makeApiCall('get', `/api/accession/test/${testId}`);
            if (result.ok) {
                const data = result.body.searchResult[0];
                const history = data?.transactionHistory;

                const currentSampleId = testId?.split('-')[0];
                const currentTestDetails = testId?.split('-')[1];

                let currentIsolation;
                if (currentTestDetails?.indexOf('D') !== -1) {
                    currentIsolation = currentSampleId + '-' + currentTestDetails?.substring(0, currentTestDetails.indexOf('D'));
                } else {
                    currentIsolation = currentSampleId + '-' + currentTestDetails;
                }

                let currentDilution;
                if (currentTestDetails?.indexOf('N') !== -1) {
                    currentDilution = currentSampleId + '-' + currentTestDetails?.substring(0, currentTestDetails.indexOf('N'));
                } else {
                    currentDilution = currentSampleId + '-' + currentTestDetails;
                }

                const filteredHistory = history?.filter((item) => {
                    return item.transactionId == testId || item.transactionId == currentSampleId || item.transactionId == currentIsolation || item.transactionId == currentDilution;
                });
                setTransactionHistory(filteredHistory || [])
                setLoadingTransactionHistory(false)
            }

            setLoadingTransactionHistory(false)
        } else {
            setTransactionHistory([]);
            setLoadingTransactionHistory(true)
            const result = await makeApiCall('get', `/api/samples/${sampleId}`);
            if (result.ok) {
                setTransactionHistory(result.body.transactionHistory || [])
            }

            setLoadingTransactionHistory(false)
        }


    }

    const isInDateRange = (bloodCollectionDate) => {
        const dateNow = moment();
        const dateToday = dateNow.locale('en-US').utc();
        const currentDate = moment(dateToday).endOf('day').format("YYYY-MM-DD");

        const forSevenDays = moment(new Date().toLocaleDateString('en-US', { timeZone: 'UTC' }));
        const sevenDaysAgo = forSevenDays.subtract(7, 'days');
        const previousDay = moment(sevenDaysAgo).format("YYYY-MM-DD");

        return (moment(bloodCollectionDate).isSameOrAfter(previousDay, 'day') && moment(bloodCollectionDate).isSameOrBefore(currentDate, 'day'));
    }

    const viewGenerateId = (entry) => {
        setSelectedEntry(entry);
        // const bloodCollectionDate = entry?.record.data?.bloodCollectionDate;
        // console.log('bloodCollectionDate', bloodCollectionDate);
        // const isAcceptedDate = isInDateRange(bloodCollectionDate);
        // console.log('bloodCollectionDate', isAcceptedDate)

        // if (!isAcceptedDate) {
        //     setWarning(true);
        // } else {
        setSampleIdModal(true);
        // }
    };

    const viewPcrDetail = (entry) => {
        setOpenPcrDetailModal(true);
        setSelectedRecord(entry?.record?.result);
    };

    const generateBarcode = async (trayId) => {
        console.log('tray id to generate barcode', trayId);
        if (trayId) {
            const url = `/api/tray/${trayId}/barcode`;
            const result = await makeApiDownloadCall('get', url, { id: trayId }, 'application/pdf', `tray-barcode-${trayId}`);
            console.log('res generate tray barcode', result)
            if (result.ok) {
                alert.show('Successfully generated barcode', {
                    type: 'success'
                });
            } else {
                alert.show(result.error, {
                    type: 'error'
                })
            }
        }
    }

    const handleClick = async (action, record) => {
        console.log('recordX: ', record);
        const id = record._id || record.id;
        const currentOwner = record.ownerId;
        const entry = { action, record };

        setAlertState('');
        if (action.action) {
            if (action.action === "viewModal") {
                const trayId = id;
                viewModal(trayId, currentOwner);
            } else if (action.action == "viewAuditModal") {
                await viewAuditModal({ sampleId: id, testId: record.testId })
            } else if (action.name == 'delete') {
                setToggleDeleteModal(true)
                setSelectedEntry(entry);
                // action.action(record);
            } else if (action.action == 'generateId' && (!record.data.sampleId || record.data.sampleId === null)) {
                viewGenerateId(entry);
            } else if (action.action == 'showPcrDetail') {
                viewPcrDetail(entry)
            } else if (action.action == 'generateBarcode') {
                const trayId = id;
                generateBarcode(trayId);
            } else if (action.action == 'rejectItem') {
                setShowRejectModal(true)
                setSelectedEntry(entry);
            }
            else {
                await action.action(record);
            }

        }
    };

    const toggleDropdown = () => setOpen(!dropdownOpen);

    const toggleNewOwnerOk = () => {
        let owner = newOwner;
        let trayId = selectedTray;

        if (owner !== null && selectedTray) {
            let url = `/api/tray/${trayId}/reassign`;

            fetch(
                url,
                {
                    credentials: 'include',
                    method: 'put',
                    body: JSON.stringify({ ownerId: owner }),
                    headers: { "Content-Type": "application/json" }
                })
                .then((response) => {
                    console.log("Tray owner change response status code: " + response.status);

                    if (response.ok) {
                        console.log('tray owner set');
                        if (currentOwner !== owner) {
                            setAlertState('good');
                            setDisabled(true);
                            reload();
                            return true;
                        } else {
                            setAlertState('existing');
                            setAlertBadMessage('Selected user is already assigned as owner of tray.');
                            setDisabled(false);
                            reload();
                        }
                    } else {
                        setAlertState('bad');
                        const details = `Details: code ${response.statusCode}; ${response.error}`;
                        setAlertBadMessage(details);
                        reload();
                    }
                })
                .catch((reason) => {
                    console.log('got an error with general table updating tray owner');
                    console.log(reason);
                    setAlertState('bad');
                    setAlertBadMessage(`Failed to change tray owner for ${trayId}`);
                })
        }
    };

    const toggleNewOwnerCancel = () => {
        setToggleModal(false);
        setAlertState('');
        setAlertBadMessage('');
        setDisabled(false);
        reload();
    };

    const handleToggleAuditModal = () => {
        setToggleAuditModal(false)
        setAlertState('');
        setAlertBadMessage('');
        setDisabled(false);
    }

    const handleExportCSV = () => {
        if (!transactionHistory) return;

        const pickedData = [];
        transactionHistory.forEach(item => {

            // labels for transaction history
            const modifiedLabels = [
                {
                    value: "isolationId",
                    modified: "Isolation ID"
                },
                {
                    value: "isolation260280ratio",
                    modified: "Isolation 260/280 ratio"
                },
                {
                    value: "isolationStatus",
                    modified: "Isolation Status"
                },
                {
                    value: "isolationConcentration",
                    modified: "Isolation Concentration",
                },
                {
                    value: "isolation260230ratio",
                    modified: "Isolation 260/230 ratio"
                },
                {
                    value: "cDnaStatus",
                    modified: "cDNA Status"
                },
                {
                    value: "cDna260280ratio",
                    modified: "cDNA 260/280 ratio"
                },
                {
                    value: "cDnaConcentration",
                    modified: "cDNA Concentration"
                },
                {
                    value: "cDna260230ratio",
                    modified: "cDNA 260/230 ratio"
                },
                {
                    value: "location",
                    modified: "Location"
                },
                {
                    value: "status",
                    modified: "Status"
                }
            ];
            const fieldLabel = modifiedLabels.filter(label => label.value == item.transactionField);

            pickedData.push({
                date: new Date(item?.date || item?.timestamp).toLocaleString('en-US', { timeZone: 'UTC' }),
                action: item?.transaction?.action?.desc,
                details: item?.transactionId,
                field: fieldLabel.length ? fieldLabel[0].modified : item?.transactionField,
                value: DataDisplay({ data:item?.transactionValue, isText:true}),
                user: item?.user?.userShort
            })
        })
        
        const fields = ['date', 'action', 'details', 'field', 'value', 'user'];

        try {
            const parser = new Parser({ fields });
            const csv = parser.parse(pickedData);

            const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
            saveAs(blob, `${selectedSampleId}-transaction-history.csv`);
        } catch (err) {
            console.error(err);
        }
    }


    const toggleSampleIdClose = () => {
        setSampleIdModal(false);
        setAlertState('');
        setAlertBadMessage('');
        setSampleId('');
        setGenerateDisabled(false);
        reload();
    };

    const toggleRejectClose = () => {
        setShowRejectModal(false);
        setAlertState('');
        setAlertBadMessage('');
        setSampleId('');
        setGenerateDisabled(false);
        reload();
    };

    const handleChange = (e) => {
        setNewOwner(e.target.value);
    };

    const reload = () => {
        setNewOwner("");
        setTray("");
        setSampleId("");
        refresh();
    };

    const addLeadingZeros = (value) => {
        return String(value).padStart(8, 0);
    };

    const resolveId = (lastId) => {
        let possibleNewId;
        if (lastId) {
            const intValues = lastId.slice(4);
            const strippedId = intValues.slice(0, -1);
            const maxId = 99999999;

            if (strippedId > maxId) {
                alert.show(`You've reached the maximum number of generated ID, please contact the administrator`, {
                    type: "error"
                });
                return;
            }

            possibleNewId = Number(strippedId) + 1;
        } else {
            possibleNewId = 1;
        }
        const newId = addLeadingZeros(possibleNewId);
        return newId;
    };

    const generateId = async () => {
        let id;
        let prefix;
        const netestData = await makeApiCall('get', `/api/netests`);
        console.log('netestData', netestData);
        if (netestData.ok) {
            setAlertBadMessage('');
            setAlertState('');
            const lastId = netestData.body.sampleId;
            const resolvedId = await resolveId(lastId);
            if (resolvedId) {
                const prefix = netestData.body.prefix;
                id = prefix + resolvedId + 'B';
                setSampleId(id);
            }
        } else {
            const detail = netestData.error;
            setGenerateDisabled(true);
            alert.show(detail, {
                type: "error"
            });
            // setAlertBadMessage(detail);
            setSampleIdModal(true);
        }
    };

    const handleIdChange = (e) => {
        setSampleId(e.target.value);
        setGenerateDisabled(true);
    };

    const validateId = () => {
        const expectedFormat = /^[A-Za-z]{4}\d{8}B$/;
        let isValidFormat;
        if (sampleId) {
            isValidFormat = expectedFormat.test(sampleId);
        }
        return isValidFormat;
    };

    const confirmSampleId = async (e) => {
        const isValidId = validateId();
        let detail;
        if (isValidId && isValidId === true) {
            const requisitionId = selectedEntry?.record.id;
            const result = await makeApiCall('put', `/api/requisition/${encodeURIComponent(requisitionId)}`, {
                sampleId: sampleId
            });

            if (result.ok) {
                detail = "Successfully created sample ID. Click refresh button"
                alert.show(detail, {
                    type: 'success'
                });
                toggleSampleIdClose();
            } else {
                detail = `Something went wrong while creating sample ID. ${result.error}`;
                alert.show(detail, {
                    type: 'error'
                });
            }
        } else {
            detail = `Invalid sampleId. Should be 4 letters and 8 numbers along with the suffix of B`
            alert.show(detail, {
                type: 'error'
            })
        }
    };

    // const toggleWarning = () => {
    //     setWarning(!warning);
    //     setSampleIdModal(true);
    // };
    
    const DataDisplay = ({ data, isText }) => {
        console.log('dataX: ', data);
        let parsedData;
        
        try {
            // Attempt to parse the data as JSON
            parsedData = JSON.parse(data);
        } catch (e) {
            // If parsing fails, it means data is not a JSON string
            parsedData = null;
        }
        
        if (parsedData && typeof parsedData === 'object' && !Array.isArray(parsedData)) {
            // Custom labels for specific keys
            const labels = {
                sampleCollectionDateTime: 'collected',
                laboratoryReceivedDateTime: 'accessioned',
                laboratoryCompletedDateTime: 'reported',
            };
            
            const formatDateTime = (key, value) => {
                if (key === 'sampleCollectionDateTime' || key === 'laboratoryReceivedDateTime') {
                    return moment(value).format('MM/DD/YYYY');
                } else if (key === 'laboratoryCompletedDateTime') {
                    return convertToTimezone(value);
                }
                return value;
            };
            
            // Generate the formatted output for specific keys
            const formattedOutput = Object.entries(parsedData)
              .filter(([key]) => labels[key])
              .map(([key, value]) => `${labels[key]}: ${formatDateTime(key, value)}`)
              .join(', ');
            
            // Return formatted text or original data based on isText flag
            if (isText) {
                return  formattedOutput; // Return formatted output or original data if no formatting is needed
            } else {
                return (
                  <div>
                      {formattedOutput ? (
                        formattedOutput.split(', ').map((line, index) => (
                          <p key={index}>{line}</p>
                        ))
                      ) : (
                        <p>{data}</p> // Display original data if no formatting is needed
                      )}
                  </div>
                );
            }
        } else {
            // Case where data is not an object or is a simple string
            if (isText) {
                return data
            }
            return <div>{data}</div>;
        }
    };
    const convertToTimezone = (dateTimeValue) => {
        const date = new Date(dateTimeValue);
        const timezone = 'America/New_York'; // Change to the desired timezone
        const options = {
            timeZone: timezone,
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: false,
        };
        
        return new Intl.DateTimeFormat('en-US', options).format(date);
    };
    
    
    return (
        <>
            <Table style={props.style} hover>
                <thead>
                    <tr>
                        {columns.map((column, index) => {
                            // const fieldSort = null;
                            // console.log('props.sort', props.sort);
                            const fieldSort = props.sort && props.sort instanceof Array && props.sort.length > 0 ? props.sort[0][column.field] : null
                            return (
                                <th
                                    key={"col-" + index}
                                    className={column.field || column.type}
                                    onClick={() => {
                                        if (column.sortType != "none") {
                                            if (props.sort && props.sort instanceof Array && props.sort.length > 0) {
                                                props.setSort([{ [column.field]: fieldSort === "asc" ? "desc" : "asc" }])
                                            }
                                        }
                                    }}
                                    style={{ cursor: "pointer" }}
                                >
                                    {column.type == "checkbox" ? (
                                        <input
                                            type="checkbox"
                                            checked={props.checkedInput.length == data.length}
                                            onChange={(e) => {
                                                const checked = e.target.checked
                                                if (checked) {
                                                    const updatedCheckedInput = data.map((i) => i.id)
                                                    props.setCheckedInput(updatedCheckedInput)
                                                } else {
                                                    props.setCheckedInput([])
                                                }
                                            }}
                                        />
                                    ) : (
                                        column.label
                                    )}
                                    {fieldSort ? <span style={{ marginLeft: "5px" }} className={"fa fa-arrow-" + (fieldSort === "asc" ? "up" : "down")}></span> : null}
                                </th>
                            )
                        })}
                        {rowActions &&
                            rowActions.map((action, index) => {
                                return <th key={"act-" + index} className={action.name}></th>
                            })}
                    </tr>
                </thead>

                <tbody>
                    {props.isLoading && (
                        <tr>
                            <td colSpan={columns?.length + 1}>
                                <center className="mt-5">
                                    <ReactLoading type="spin" color="red" height={667} width={100} />
                                </center>
                            </td>
                        </tr>
                    )}

                    {!props.isLoading && data && !data.length && (
                        <tr>
                            <td colSpan={columns.length + 1}>
                                <center className="mt-3">
                                    <h5>No records found</h5>
                                </center>
                            </td>
                        </tr>
                    )}

                    {!props.isLoading &&
                        data &&
                        data.map((record, index) => (
                            <tr className={props.checkedInput && props.checkedInput.includes(record.id) ? "active" : ""} key={"pcr-" + record[key] + "-" + index || index}>
                                {columns.map((column, columnIndex) => (
                                    <td key={`pcrCol-${columnIndex}`} className={column.field || column.type}>
                                        {column.field === 'subjectId' ? (
                                            <a className='text-secondary' target="_blank"
                                                rel="noopener noreferrer" href={`/subject-info/${record.subjectId}/personal?id=${record.subjectId}&displayMode=form&selector_id=${record.subjectId}`}>
                                                {record.subjectId}
                                            </a>
                                        ) : column.field === 'data.data.subjectId' ? (
                                            <a className='text-secondary' target="_blank"
                                                rel="noopener noreferrer" href={`/subject-info/${record.data.data.subjectId}/personal?id=${record.data.data.subjectId}&displayMode=form&selector_id=${record.data.data.subjectId}`}>
                                                {record.data.data.subjectId}
                                            </a>
                                        ) : (
                                            getColumnValue(record, column, index, firstRowNumber)
                                        )}
                                    </td>
                                ))}

                                <td>
                                    {rowActions.map((action, actionIndex) => {
                                        return (
                                            <span key={"act-" + actionIndex} className="action-btn">
                                                {visible(action.details && action.details.visible, record, action && action.name) ? (
                                                    <>
                                                        <Button
                                                            className="mr-2"
                                                            type="button"
                                                            outline
                                                            color={action.color || "secondary"}
                                                            style={action.style || get(() => props.formDefinition.close.style)}
                                                            onClick={async () => await handleClick(action, record)}
                                                        >
                                                            {action.label}
                                                        </Button>
                                                    </>
                                                ) : (
                                                    <Button className="mr-2" type="button" outline color={action.color || "secondary"} style={{ visibility: "hidden" }}>
                                                        {action.label}
                                                    </Button>
                                                )}
                                            </span>
                                        )
                                    })}
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
            <Modal isOpen={toggleDeleteModal} toggle={setToggleDeleteModal} backdrop={true}>
                <ModalHeader>Confirmation</ModalHeader>
                <ModalBody>Are you sure you want to remove {selectedEntry?.record.name || selectedEntry?.record.productName || "this entry"}?</ModalBody>
                <ModalFooter>
                    <Button
                        outline
                        color="danger"
                        onClick={() => {
                            selectedEntry?.action.action(selectedEntry?.record)
                            setToggleDeleteModal(false)
                            props.setAlertState && props.setAlertState("deleted")
                        }}
                    >
                        OK
                    </Button>{" "}
                    <Button outline color="secondary" onClick={() => setToggleDeleteModal(false)}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
            <Modal isOpen={toggleModal} toggle={toggleNewOwnerCancel} backdrop={true}>
                <ModalHeader toggle={toggleNewOwnerCancel}>Re-assign Tray Owner</ModalHeader>
                <ModalBody>
                    {alertState === "good" ? (
                        <FormGroup>
                            <Alert style={{ marginTop: "10px", textAlign: "center" }} color="success">
                                {"Successfully changed tray owner. Click the refresh button."}
                            </Alert>
                        </FormGroup>
                    ) : alertState === "bad" || alertState === "existing" ? (
                        <FormGroup>
                            <Alert style={{ marginTop: "10px", textAlign: "center" }} color="danger">
                                {alertBadMessage}
                            </Alert>
                        </FormGroup>
                    ) : null}
                    {alertState === "" ? (
                        <>
                            <FormGroup>
                                <Label for="userId">Select new owner for tray no. {selectedTray ? selectedTray : "[tray]"}</Label>
                            </FormGroup>
                            <FormGroup>
                                <Input type="select" name="newOwner" id="newOwner" onChange={handleChange}>
                                    <option key={" "} value={" "}>
                                        {"  "}
                                    </option>
                                    {usersData &&
                                        usersData.map((userid, index) => (
                                            <option key={"owner-" + index} value={userid}>
                                                {userid}
                                            </option>
                                        ))}
                                </Input>
                            </FormGroup>
                        </>
                    ) : null}
                </ModalBody>
                <ModalFooter>
                    {alertState === "" ? (
                        <>
                            <Button type="button" outline color="primary" onClick={toggleNewOwnerOk} disabled={disabled}>
                                Ok
                            </Button>{" "}
                            <Button type="button" outline color="secondary" onClick={toggleNewOwnerCancel}>
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <Button type="button" outline color="primary" onClick={toggleNewOwnerCancel}>
                            Close
                        </Button>
                    )}
                </ModalFooter>
            </Modal>

            {/*<Modal isOpen={warning} toggle={warning} backdrop={true}>*/}
            {/*    <ModalHeader>Process Warning</ModalHeader>*/}
            {/*    <ModalBody>*/}
            {/*        You are trying to process a sample that is not in the accepted day(s) of blood collection date*/}
            {/*    </ModalBody>*/}
            {/*    <ModalFooter>*/}
            {/*        <Button type="button" color="success" onClick={toggleWarning}>OK</Button>*/}
            {/*        /!*<Button type="button" outline color="primary" onClick={setWarning(false)}>Cancel</Button>*!/*/}
            {/*    </ModalFooter>*/}
            {/*</Modal>*/}

            <Modal isOpen={toggleAuditModal} toggle={handleToggleAuditModal} backdrop={true} size="xl">
                <ModalHeader>Transaction History</ModalHeader>
                <ModalBody>
                    {loadingTransactionHistory && (
                        <center className="mt-5">
                            <ReactLoading type="spin" color="red" height={667} width={100} />
                        </center>
                    )}

                    {!loadingTransactionHistory && (
                        <Table responsive hover size="lg">
                            <thead>
                                <tr>
                                    <th>Date/time</th>
                                    <th>Action</th>
                                    <th>Details</th>
                                    <th>Field</th>
                                    <th>Value</th>
                                    <th>User</th>
                                </tr>
                            </thead>
                            <tbody>
                                {transactionHistory.length ? (
                                    transactionHistory.map((item, idx) => {
                                        // labels for transaction history
                                        const modifiedLabels = [
                                            { value: 'isolationId', modified: 'Isolation ID' },
                                            { value: 'isolation260280ratio', modified: 'Isolation 260/280 ratio' },
                                            { value: 'isolationStatus', modified: 'Isolation Status' },
                                            { value: 'isolationConcentration', modified: 'Isolation Concentration' },
                                            { value: 'isolation260230ratio', modified: 'Isolation 260/230 ratio' },
                                            { value: 'cDnaStatus', modified: 'cDNA Status' },
                                            { value: 'cDna260280ratio', modified: 'cDNA 260/280 ratio' },
                                            { value: 'cDnaConcentration', modified: 'cDNA Concentration' },
                                            { value: 'cDna260230ratio', modified: 'cDNA 260/230 ratio' },
                                            { value: 'location', modified: 'Location' },
                                            { value: 'status', modified: 'Status' },
                                        ];
                                        const fieldLabel = modifiedLabels.filter((label) => label.value == item.transactionField)
                                        
                                        return (
                                            <tr key={idx}>
                                                <td>{new Date(item?.date || item?.timestamp).toLocaleString("en-US", { timeZone: "UTC" })}</td>
                                                <td>{item?.transaction?.action?.desc}</td>
                                                <td>{item?.transactionId}</td>
                                                <td>{fieldLabel.length ? fieldLabel[0].modified : item?.transactionField}</td>
                                                <td><DataDisplay data={item?.transactionValue} /></td>
                                                <td>{item?.user?.userShort}</td>
                                            </tr>
                                        )
                                    })
                                ) : (
                                    <tr>
                                        <td>No records found</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    )}
                </ModalBody>
                <ModalFooter>
                    {!loadingTransactionHistory && (
                        <Button type="button" outline color="info" onClick={handleExportCSV}>
                            Export
                        </Button>
                    )}

                    <Button type="button" outline color="primary" onClick={handleToggleAuditModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={toggleSampleIdModal} toggle={toggleSampleIdClose} backdrop={true}>
                <ModalHeader>Click button to generate Sample ID</ModalHeader>
                <ModalBody>
                    <Button
                        type="button"
                        name="generateBtn"
                        id="generateBtn"
                        color="warning"
                        onClick={generateId}
                        readOnly={generateDisabled}
                        style={{ marginBottom: "0.5rem" }}
                    >
                        Generate
                    </Button>
                    <Input
                        className={sampleId ? "" : "invalid-input"}
                        type={"text"}
                        name={formDefinition.name}
                        id={formDefinition.name}
                        value={sampleId}
                        required={formDefinition.required}
                        pattern={formDefinition.pattern}
                        onChange={handleIdChange}
                    />
                    <span className="small">{formDefinition.notes}</span>
                    {alertState === "netestError" && (
                        <>
                            <Alert style={{ marginTop: "10px", textAlign: "center" }} color="danger">
                                {alertBadMessage}
                            </Alert>
                        </>
                    )}
                </ModalBody>
                <ModalFooter>
                    {alertState === "" ? (
                        <>
                            <Button
                                type="button"
                                name="confirmGenerate"
                                id="confirmGenerate"
                                outline
                                color="success"
                                onClick={confirmSampleId}
                            // disabled={generateDisabled}
                            >
                                Confirm
                            </Button>{" "}
                            <Button type="button" outline color="secondary" onClick={toggleSampleIdClose}>
                                Cancel
                            </Button>
                        </>
                    ) : (
                        <Button type="button" name="generateClose" id="generateClose" outline color="primary" onClick={toggleSampleIdClose}>
                            Close
                        </Button>
                    )}
                </ModalFooter>
            </Modal>

            <Modal isOpen={showRejectModal} toggle={setShowRejectModal} backdrop={true}>
                <ModalHeader>Reject Backlog</ModalHeader>
                <ModalBody>Are you sure you want to reject this entry?</ModalBody>
                <ModalFooter>
                    <Button
                        type="button"
                        outline
                        color="danger"
                        onClick={async () => {
                            await selectedEntry?.action.customAction(selectedEntry?.record)
                            toggleRejectClose()
                        }}
                    >
                        Yes
                    </Button>
                    <Button type="button" outline color="secondary" onClick={toggleRejectClose}>
                        No
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal isOpen={openPcrDetailModal} toggle={() => setOpenPcrDetailModal(!openPcrDetailModal)} backdrop={true}>
                <ModalHeader>PCR Detail</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col sm="12">
                            {selectedRecord && (
                                <table className="table mt-3">
                                    <thead>
                                        <tr>
                                            <th>Result</th>
                                            <th>Value</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {Object.keys(selectedRecord).map((key) => {
                                            if (key !== "testConfig" && key !== "cancer" && key !== "result") {
                                                return (
                                                    <tr key={"test-config" + key}>
                                                        <td>{key}</td>
                                                        <td>{selectedRecord[key] === true ? "True" : selectedRecord[key] === false ? "False" : selectedRecord[key]}</td>
                                                    </tr>
                                                )
                                            }
                                        })}
                                    </tbody>
                                </table>
                            )}
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" outline color="primary" onClick={() => setOpenPcrDetailModal(!openPcrDetailModal)}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default GeneralTable;
