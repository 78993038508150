import { axiosClient, useRequestProcessor } from "api/provider"

export function useAddTray({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("create-tray", (update) => axiosClient.post(`/tray`, update).then((res) => res.data), { ...rest })
}

export function useReassignTray({ variables = {}, ...rest }) {
  const { mutate } = useRequestProcessor()

  return mutate("reassign-tray", (update) => axiosClient.put(`/tray/${variables?.id}/reassign`, update).then((res) => res.data), { ...rest })
}
