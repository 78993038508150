const covidStreamlineFormDefinition = {
    formLabel: null,
    submit: {
        label: 'Submit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        },
        disabled: {testId: {empty: true}}
    },
    cancel: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    
    update: {
        label: 'Update',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center',
        },
        disabled: false
    },
    edit: {
        label: 'Edit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    close: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    sections: [
        {
            label: 'Submission',
            columns: 2,
            fields: [
                {
                    label: 'Submission Timestamp',
                    name: 'submittedTimestamp',
                    type: 'timestamp',
                    format: 'toLocalizedDateString',
                    readonly: true,
                    style: {maxWidth:'250px'}
                }
            ]
        },
        {
            label: 'Your COVID test',
            columns: 2,
            fields: [
                {
                    label: 'COVID Test Number',
                    name: 'testId',
                    type: 'text',
                    readonly: false,
                    style: {maxWidth:'250px'},
                    className: 'covid-test',
                    pattern: '(^[A-Z]{2}[0-9]{10}[A-Z]$)|(^[A-Z]{4}[0-9]{8}[A-Z]$)',
                },
                {
                    label: 'Format is 2 capital letters, 10 digits, 1 capital letter or 4 capital letters, 8 digits, 1 capital letter no commas or spaces.',
                    name: 'manualTestPrompt',
                    type: 'label',
                    visible: {manualTestId: true},
                    labelStyle: {color: 'red'},
                    labelClassName: 'warning-label-class'
                },
                {
                    label: 'Paid',
                    name: 'paid',
                    type: 'checkbox',
                    visible: true,
                    // disabled: true
                },
                // {
                //     label: 'You have arrived at this page without a test number.  Please scan your sample tube with a QR code reader.',
                //     name: 'noIdWarning',
                //     type: 'label',
                //     visible: {testId: {empty: true}},
                //     labelStyle: {color: 'red'},
                //     labelClassName: 'warning-label-class'
                // },
                {
                    label: 'Manual Test Id',
                    name: 'manualTestId',
                    type: 'checkbox',
                    visible: true,
                    disabled: false
                },
                {
                    label: 'Sample Collection',
                    name: 'sampleCollectionDateTime',
                    type: 'datetime-local',
                    required: false,
                    format: 'toLocalizedDateString',
                    style: {maxWidth:'240px'},
                    max: "9999-12-31T23:59"
                }
            ]
        },
        {
            label: 'About you',
            columns: 2,
            fields: [
                {
                    label: 'First Name',
                    name: 'firstName',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'200px'},
                    pattern: '[^0-9]+',
                    title: 'At least one character and not numerical',
                    maxlength: 50
                },
                {
                    label: 'Last Name',
                    name: 'lastName',
                    type: 'text',
                    pattern: '[^0-9]+',
                    title: 'At least one character and not numerical',
                    required: true,
                    style: {maxWidth:'200px'},
                    maxlength: 50
                },
                {
                    label: 'Date of Birth',
                    name: 'dateOfBirth',
                    type: 'date',
                    required: true,
                    style: {maxWidth:'170px'},
                    max: "9999-12-31"
                },
                {
                    label: 'Gender',
                    name: 'gender',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'gender'
                    },
                    required: true,
                    style: {maxWidth:'200px'}
                },
                {
                    label: 'Address',
                    name: 'address',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'250px'},
                    maxlength: 50
                },
                {
                    label: 'City',
                    name: 'city',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'250px'},
                    pattern: '[^0-9]+',
                    title: 'At least one character and not numerical',
                    maxlength: 30
                },
                {
                    label: 'State',
                    name: 'state',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'state'
                    },
                    required: true,
                    style: {maxWidth:'200px'}
                },
                {
                    label: 'Zip Code',
                    name: 'zipCode',
                    type: 'text',
                    required: true,
                    pattern: '[0-9]{5}',
                    style: {maxWidth:'150px'}
                },
                {
                    label: 'Phone Number',
                    name: 'phoneNumber',
                    type: 'text',
                    required: true,
                    pattern: '[0-9]{6,15}',
                    style: {maxWidth:'200px'}
                },
                {
                    label: 'Email Address',
                    name: 'email',
                    type: 'email',
                    required: true,
                    pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
                    style: {maxWidth:'400px'}
                },

                {
                    label: 'County',
                    name: 'county',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'200px'},
                    pattern: '[^0-9]+',
                    title: 'At least one character and not numerical',
                },
                {
                    label: 'Race',
                    name: 'race',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'race'
                    },
                    required: true,
                    style: {maxWidth:'400px'}
                },
                {
                    label: 'Ethnicity',
                    name: 'ethnicity',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'ethnicity'
                    },
                    required: true,
                    style: {maxWidth:'300px'}
                },
                {
                    label: 'Deleted',
                    name: 'deletedTimestamp',
                    type: 'text',
                    required: false,
                    style: {maxWidth: '200px'},
                    readonly: true,
                },
                {
                    label: 'Taps',
                    name: 'taps',
                    type: 'checkbox',
                    style: {maxWidth: '200px'},
                    readonly: true,
                },
            ]
        },
        {
            label: 'Please indicate your testing purpose',
            columns: 1,
            fields: [
                {
                    label: '',
                    key: 'testingPurpose',
                    name: 'testingPurpose',
                    type: 'radio',
                    source: {
                        kind: 'direct',
                        data: [
                            {
                                value: 'Symptomatic',
                                key: 'purposeSymptomatic',
                            },
                            {
                                value: 'Exposed',
                                key: 'purposeExposed',
                            },
                            {
                                value: 'Travel',
                                key: 'purposeTravel',
                            },
                            {
                                value: 'Weekly Testing Due to state mandate (Long-term care) Education / childcare',
                                key: 'purposeStateLongTermCare',
                            },
                            {
                                value: 'Weekly Testing Due to state mandate (Education/Childcare)',
                                key: 'purposeStateEducationOrChildcare',
                            },
                            {
                                value: 'Weekly Testing Due to state mandate (State Employee)',
                                key: 'purposeStateEmployee',
                            },
                            {
                                value: 'Testing due to other employer mandate, not associated with state mandate',
                                key: 'purposeEmployee',
                            },
                            {
                                value: 'Other (please state)',
                                key: 'purposeOther',
                            },
                        ]
                    },
                    itemStyle: {
                        marginLeft: '0px',
                        label: { marginLeft: '30px'}
                    }
                },
                {
                    label: '',
                    placeholder: 'Please state reason...',
                    name: 'purposeOtherDescription',
                    type: 'text',
                    maxlength: 30
                },
            ]
        },
        {
            label: 'Please indicate reason(s) for the COVID19 Test',
            columns: 2,
            fields: [
                {
                    label: 'Patient has signs and symptoms (e.g. fever, cough, difficulty breathing)',
                    name: 'patientSymptoms',
                    type: 'checkbox',
                },
                {
                    label: 'Date of onset of symptoms (if applicable)',
                    name: 'dateOfSymptomOnset',
                    type: 'date',
                    style: {maxWidth: '170px'},
                    groupStyle: {marginLeft: '40px'},
                    max: "9999-12-31"
                    
                },
                {
                    label: 'Patient lives in or has recently travelled to a place where transmission of COVID-19 is known to occur',
                    name: 'patientLocation',
                    type: 'checkbox',
                    labelStyle: { marginTop: '-2rem', display: 'block', marginLeft: '3rem'}
                },
                {
                    label: 'Patient has been in close contact with an individual suspected of, or confirmed to have COVID-19',
                    name: 'patientContact',
                    type: 'checkbox',
                    labelStyle: { marginTop: '-2rem', display: 'block', marginLeft: '3rem'}
                },
            ]
        },
        {
            label: 'Please check if applicable',
            columns: 2,
            fields: [
                {
                    label: 'Patient works in a healthcare setting',
                    name: 'healthcareSetting',
                    type: 'checkbox',
                },
                {
                    label: 'Patient is pregnant (if female)',
                    name: 'patientPregnant',
                    type: 'checkbox',
                },
                {
                    label: 'This is the patient\'s first test for COVID-19',
                    name: 'patientFirstTest',
                    type: 'checkbox',
                },
                {
                    label: 'Patient resides in a congregate setting',
                    name: 'patientCongregateResidence',
                    type: 'checkbox',
                }
            ]
        },
        {
            label: 'Please check if patient is symptomatic',
            columns: 2,
            fields: [
                {
                    label: 'Patient hospitalized',
                    name: 'patientHospitalized',
                    type: 'checkbox',
                },
                {
                    label: 'Patient admitted to ICU',
                    name: 'patientAdmittedToICU',
                    type: 'checkbox',
                }
            ]
        },
        {
            label: 'COVID Vaccination Status',
            columns: 2,
            fields: [
                {
                    label: 'Vaccinated Against COVID-19?',
                    name: 'vaccinated',
                    type: 'checkbox'
                },
                {
                    label: 'First Vaccination Date',
                    name: 'vaccinationDate',
                    type: 'date',
                    style: {maxWidth:'170px'},
                    max: "9999-12-31"
                },
                {
                    label: 'Vaccine Type',
                    name: 'vaccineType',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'vaccine'
                    },
                    style: {maxWidth:'200px'}
                },
                {
                    label: 'Please specify',
                    name: 'vaccineTypeOther',
                    type: 'text',
                    style: {maxWidth:'250px'},
                    visible: {vaccineType: 'Other'}
                }
            ]
        },
        {
            label: 'Physician\'s or Requisitioner\'s Information (healthcare provider or employer etc)',
            columns: 2,
            fields: [
                {
                    label: 'Requisitioner\'s Full Name',
                    name: 'requisitionerFullName',
                    type: 'text',
                    required: false,
                    style: {maxWidth: '400px'},
                    pattern: '[^0-9]+',
                    title: 'At least one character and not numerical',
                    maxlength: 100
                },
                {
                    label: 'Requisitioner\'s Email Address',
                    name: 'requisitionerEmail',
                    type: 'email',
                    required: false,
                    pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
                    style: {maxWidth: '400px'}
                }
            ]
        },
        {
            label: 'Insurance Cover',
            columns: 2,
            fields: [
                {
                    label: 'Test is covered by insurance',
                    name: 'patientInsured',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'insured'
                    },
                    required: true
                },
                {
                    label: 'Insurance Group ID',
                    name: 'insuranceGroupId',
                    type: 'text',
                    maxlength: 20,
                    validation: {
                        rule: {
                            $or: [
                                {patientInsured: false},
                                {insuranceGroupId: {required: true}}
                            ]
                        },
                        message: 'Insurance group ID is required when test is covered by insurance'
                    },
                },
                {
                    label: 'Insurance Company Name',
                    name: 'insuranceCompanyName',
                    type: 'text',
                    required: false,
                    maxlength: 60,
                    validation: {
                        rule: {
                            $or: [
                                {patientInsured: false},
                                {insuranceCompanyName: {required: true}}
                            ]
                        },
                        message: 'Insurance company name is required when test is covered by insurance'
                    },
                },
                {
                    label: 'Insurance Subscriber Number',
                    name: 'insuranceSubscriberNo',
                    type: 'text',
                    required: false,
                    maxlength: 20,
                    validation: {
                        rule: {
                            $or: [
                                {patientInsured: false},
                                {insuranceSubscriberNo: {required: true}}
                            ]
                        },
                        message: 'Insurance subscriber name is required when test is covered by insurance'
                    },
                }
            ]
        },
        {
            label: 'Fee Information',
            columns: 2,
            fields: [
                {
                    label: 'Payer type',
                    name: 'payerTypeLabel',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'payerTypeSubForm'
                    },
                    required: true,
                    defaultValue: 'personal'
                },
                {
                    label: 'Price',
                    name: 'price',
                    type: 'text',
                    readonly: true,
                },
                {
                    label: 'Fee Code',
                    name: 'feeCode',
                    type: 'text',
                    required: false,
                    maxlength: 10,
                    pattern: '^[a-zA-Z0-9]+$',
                    title: 'It only accepts alphanumeric code',
                },
               
            ]
        },
        {
            label: 'Confirmation',
            columns: 1,
            fields: [
                {
                    label: 'Please send me additional information when new products become available',
                    name: 'marketingPermission',
                    type: 'checkbox',
                    required: false,
                    defaultValue: true
                },
                {
                    label: 'Terms and conditions agreed',
                    name: 'termsAndConditions',
                    type: 'checkbox',
                    required: true,
                    defaultValue: false
                },
                {
                    label: 'I affirm and attest the above information is truthful. If this statement is false, I understand that I may subject to the penalties of false statement pursuant to conn, Gen, Stat § 53a-157. I also consent to the State and its operator to conduct further research to obtain any additional information necessary to submit an insurance claim on behalf of the patient.',
                    name: 'attestation',
                    type: 'checkbox',
                    required: true,
                    defaultValue: false,
                    labelStyle: { marginTop: '-2rem', display: 'block', marginLeft: '3rem'}
                }
            ]
        }

    ]
};

export default covidStreamlineFormDefinition;
