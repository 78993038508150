import React from "react"
import { PatientsTable } from "./tables/PatientsTable"
import Breadcrumb from "./custom/Breadcrumb"
import { Card, CardBody } from "reactstrap"

function SubjectView() {
  const breadcrumbItems = [
    {
      label: "Patients",
      link: `/subject-view`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title={`Patients`} breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <PatientsTable allowPageSize allowPaginate isGlobalFilter />
        </CardBody>
      </Card>
    </div>
  )
}

export default SubjectView
