import React, { useEffect, useRef, useState } from 'react';

import lookups from "../configuration/lookups";
import DataWow from "./data-wow";
import { makeApiCall } from "../api/generic-api";
import covidStreamlineFullDefinition from "../configuration/covid-streamline-full-definition";
import { Alert, Col, Row } from "reactstrap";
import { debounce, omit } from "lodash";
import {stringQueryToData} from "../utility/uri-utility";


function AccessionView(props) {
    const [alertState, setAlertState] = useState(null);
    const [alertBadMessage, setAlertBadMessage] = useState(null);
    const [ price, setPrice ] = useState(0);
    
    const structure = covidStreamlineFullDefinition;
    
    
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamsString = decodeURIComponent(urlParams.toString());
    const dataQuery = stringQueryToData(urlParamsString);
    const [ id, setId ] = useState(() => {
        return dataQuery?.id || null
    });


    useEffect(() => {
        setTimeout(() => {
            setAlertState(null)
        }, 6000)
    }, [ alertState ]);

    const fixedSelector = {
        "$or": [
            {
                "status": {
                    "$exists": false
                }
            },
            {
                status: {
                    "$ne": "done"
                }
            }
        ]
    };

    const userSelector = {
        heading: {
            label: 'Filter',
            style: {display: 'inline-block', fontWeight: 800}
        },
        lookups: lookups,
        controlBlockStyle: {display: 'inline-block'},
        controls: [
            {
                label: 'Test Id',
                name: 'data.testId',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Requisitioner',
                name: 'data.requisitionerFullName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'First Name',
                name: 'data.firstName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Last Name',
                name: 'data.lastName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Date of Birth',
                name: 'data.dateOfBirth',
                type: 'date',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'},
                max: "9999-12-31"
            },
        ],
        selectorFunction: (selector, controlState) => {
            console.log('controlState', controlState);
            let resultSelector = JSON.parse(JSON.stringify(selector));
        
            // temp disable for now
            if (controlState['data.firstName']) {
                resultSelector = Object.assign({}, {
                    ...resultSelector,
                    'data.firstName': {
                        $regex: "(?i)" +controlState['data.firstName']
                    }
                })
            };
        
            if (controlState['data.lastName']) {
                resultSelector = Object.assign({}, {
                    ...resultSelector,
                    'data.lastName': {
                        $regex: "(?i)" +controlState['data.lastName']
                    }
                })
            };
        
        
            return resultSelector;
        }
    };

    // const customRowActions = [
    //     {
    //         type: 'button',
    //         name: 'done',
    //         label: 'Done',
    //         color: 'success',
    //         details: {
    //             endpoint: 'patch',
    //             data: {
    //                 status: 'done'
    //             },
    //             idToken: ':id',
    //             visible: {
    //                 status: null
    //             }
    //         }
    //     }
    // ];

    const update = async (formState) => {
        formState = omit(formState, ['price'])
        formState.id = id;
        const result = await makeApiCall('put', '/api/submission/covid', formState);
        if (result.ok) {
            setAlertState('good');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `Details: code ${result.statusCode}; ${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }

        return result;
    };

    const handleDelete = async (items) => {
        const result = await makeApiCall('put', '/api/accession/streamline/bulk-delete', items);
        if (result.ok) {
            setAlertState('good');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `Details: code ${result.statusCode}; ${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }

        return result;
    }

    const fieldsOnChange = useRef(
      debounce(async (data) => {
          await fetchPrices(data);
      }, 200)
    ).current

    const fetchPrices = async (data) => {
        if (data.testId && data.payerTypeLabel) {
            const patientInsured = data.patientInsured;
            if ((patientInsured === 'true' || patientInsured === true) && data.payerTypeLabel === 'personal') {
                data.payerType = 'insurance'
            } else {
                data.payerType = data.payerTypeLabel;
            }
        
            data.payerTypeLabel = data.payerTypeLabel;
        
            const payerType = data.payerType;
            const feeCode = data.feeCode === '' ? null : data.feeCode;
            const testTypeId = data.testId.substring(data.testId.length - 1);
        
            const productResult = await makeApiCall('get', '/api/products', {
                selector: {
                    testTypeData: {
                        $elemMatch: {
                            value: testTypeId
                        }
                    }
                }
            });
        
            if (productResult && productResult.statusCode === 200 && productResult.body && productResult.body.length > 0) {
                const productId = productResult.body[0].id;
                const priceResult = await makeApiCall('get', '/api/prices', {
                    selector: {
                        'productId': productId,
                        'payerType': payerType,
                        'feeCode': feeCode
                    }
                });
            
                if (priceResult && priceResult.statusCode === 200 && priceResult.body && priceResult.body.length > 0) {
                    data.price = priceResult.body[0].price;
                    setPrice(priceResult.body[0].price);
                    return priceResult.body[0].price;
                } else {
                    data.price = null;
                    setPrice(null);
                    return null;
                }
            } else {
                data.price = null;
                setPrice(null);
                return null;
            }
        } else {
            data.price = null;
            setPrice(null)
            return null;
        }
    }

    return (
        <div>
            <Row style={{marginTop: '30px'}}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>Accession Backlog</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>
            <DataWow
                className={'wide-container'}
                structure={structure}
                lookups={lookups}
                fixedSelector={fixedSelector}
                userSelector={userSelector}
                formDataField={'data'}
                update={update}
                //            customRowActions={customRowActions}
                loadData={makeApiCall}
                showDeleteButton={true}
                handleDelete={handleDelete}
                fieldsOnChange={fieldsOnChange}
                fetchPrices={fetchPrices}
                price={price}
                isCovid={true}
                {...props} />

            { alertState === 'good' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Record has been updated.'}
              </Alert>
              : null
            }
            { alertState === 'bad' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                  {'There was a problem submitting your data.'}
                  <br/>
                  { alertBadMessage || 'Please try again or contact us.'}
              </Alert>
              : null
            }
        </div>
    );
}

export default AccessionView;
