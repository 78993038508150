import trayFormDefinition from "./tray-form-definition";

const trayFullDefinition = {
  key: "_id",
  //userId: 'primaryLogin',
  table: {
    key: "_id",
    columns: [
      {
        label: "#",
        type: "rowNumber",
        filterType: "none",
        sortType: "none",
      },
      {
        label: "Id",
        field: "id",
        type: "text",
        filterType: "equals",
        sortType: "both",
      },
      {
        label: "Tray Type",
        field: "trayConfigurationLabel",
        type: "text",
        filterType: "equals",
        sortType: "both",
      },
      {
        label: "Test Type",
        field: "testType",
        type: "text",
        filterType: "equals",
        sortType: "both",
      },
      {
        label: "Capacity",
        field: "maxFill",
        type: "number",
        filterType: "equals",
        sortType: "both",
      },
      {
        label: "Owner",
        field: "ownerId",
        type: "text",
        sortType: "both",
      },
      {
        label: "Status",
        field: "status",
        type: "text",
        filterType: "equals",
        sortType: "both",
      },
    ],
    defaultSort: [{ _id: "desc" }],
  },

  form: trayFormDefinition,
  //     newRecordTemplate: {
  //         status: 'live'
  //     },
  //     validation: (record) => {
  //         return record.primaryLogin.length > 12 && record.name.startsWith('s');
  //     }
  // },

  endpoints: {
    patch: "/api/tray/:id",
    barcode: {
      uri: "/api/tray/:id/barcode",
      verb: "get",
      type: "application/pdf",
      filename: "tray-barcode-:id.pdf",
    },
    export: {
      uri: "/api/tray/:id/export",
      verb: "get",
      type: "text/plain",
      filename: "tray-export-:id.txt",
    },
    reassign: {
      uri: "/api/tray/:id/reassign",
      verb: "put",
    },
    search: {
      uri: "/api/trays?",
      verb: "get",
    },
    trayusers: {
      uri: "/api/tray/users",
      verb: "get",
    },
  },
  // labels: {
  //     table: 'Laboratories',
  //     form: {
  //         new: 'New Laboratory',
  //         edit: 'Edit Laboratory'
  //     }
  // },
  pageSize: 10,
}

export default trayFullDefinition;
