import React, { useEffect, useMemo, useState, useRef } from "react"
import { Alert } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import { useNavigate, useParams } from "react-router"
import { usePreviousTableQuery } from "hooks/query"
import lookups from "../configuration/lookups"
import { makeApiCall } from "../api/generic-api"
import covidStreamlineFullDefinition from "../configuration/covid-streamline-full-definition"
import { debounce, omit } from "lodash"
import { useAccessionsQuery } from "api/getters"
import GeneralFormNew from "./general-form-new"

function AccessionViewInfo(props) {
  const navigate = useNavigate()
  const { id } = useParams()

  const {
    data: requisitionsData,
    isLoading,
    refetch,
  } = useAccessionsQuery({
    variables: {
      page: 1,
      selector: {
        _id: id,
      },
    },
  })

  const previousQuery = usePreviousTableQuery()
  const breadcrumbItems = [
    {
      label: "Accession Backlog",
      link: `/accession-view?${previousQuery}`,
    },
    {
      label: id,
      link: `/accession-view/${id}`,
    },
  ]

  const [alertState, setAlertState] = useState(null)
  const [alertBadMessage, setAlertBadMessage] = useState(null)
  const [price, setPrice] = useState(0)

  const structure = covidStreamlineFullDefinition

  useEffect(() => {
    setTimeout(() => {
      setAlertState(null)
    }, 6000)
  }, [alertState])

  const update = async (formState) => {
    formState = omit(formState, ["price"])
    formState.id = id
    const result = await makeApiCall("put", "/api/submission/covid", formState)
    if (result.ok) {
      setAlertState("good")
      setAlertBadMessage("")
      window.scrollTo(0, document.body.scrollHeight)
    } else {
      const detail = `Details: code ${result.statusCode}; ${result.error}`
      setAlertState("bad")
      setAlertBadMessage(detail)
      window.scrollTo(0, document.body.scrollHeight)
    }

    return result
  }

  const handleDelete = async (items) => {
    const result = await makeApiCall("put", "/api/accession/streamline/bulk-delete", items)
    if (result.ok) {
      setAlertState("good")
      setAlertBadMessage("")
      window.scrollTo(0, document.body.scrollHeight)
    } else {
      const detail = `Details: code ${result.statusCode}; ${result.error}`
      setAlertState("bad")
      setAlertBadMessage(detail)
      window.scrollTo(0, document.body.scrollHeight)
    }

    return result
  }

  const fetchPrices = async (data) => {
    if (data.testId && data.payerTypeLabel) {
      const patientInsured = data.patientInsured
      if ((patientInsured === "true" || patientInsured === true) && data.payerTypeLabel === "personal") {
        data.payerType = "insurance"
      } else {
        data.payerType = data.payerTypeLabel
      }

      data.payerTypeLabel = data.payerTypeLabel

      const payerType = data.payerType
      const feeCode = data.feeCode === "" ? null : data.feeCode
      const testTypeId = data.testId.substring(data.testId.length - 1)

      const productResult = await makeApiCall("get", "/api/products", {
        selector: {
          testTypeData: {
            $elemMatch: {
              value: testTypeId,
            },
          },
        },
      })

      if (productResult && productResult.statusCode === 200 && productResult.body && productResult.body.length > 0) {
        const productId = productResult.body[0].id
        const priceResult = await makeApiCall("get", "/api/prices", {
          selector: {
            productId: productId,
            payerType: payerType,
            feeCode: feeCode,
          },
        })

        if (priceResult && priceResult.statusCode === 200 && priceResult.body && priceResult.body.length > 0) {
          data.price = priceResult.body[0].price
          setPrice(priceResult.body[0].price)
          return priceResult.body[0].price
        } else {
          data.price = null
          setPrice(null)
          return null
        }
      } else {
        data.price = null
        setPrice(null)
        return null
      }
    } else {
      data.price = null
      setPrice(null)
      return null
    }
  }

  const currentRecord = useMemo(
    () => ({
      ...(requisitionsData?.[0]?.data || {})
    }),
    [requisitionsData?.[0]],
  )
  
  useEffect(() => {
    fetchPrices(currentRecord)
  }, [currentRecord])

  return (
    <div className="wide-container">
      <Breadcrumb title={""} breadcrumbItems={breadcrumbItems} />
      <div>
        <GeneralFormNew
          formDefinition={structure.form}
          lookups={lookups}
          formState={currentRecord}
          close={() => navigate(-1)}
          update={update}
          setAlertState={props.setAlertState}
          fetchPrices={fetchPrices}
          isLoading={isLoading}
          price={price}
          {...props}
        />
        {alertState === "good" ? (
          <Alert style={{ marginTop: "10px", textAlign: "center" }} color="success">
            {"Record has been updated."}
          </Alert>
        ) : null}
        {alertState === "bad" ? (
          <Alert style={{ marginTop: "10px", textAlign: "center" }} color="danger">
            {"There was a problem submitting your data."}
            <br />
            {alertBadMessage || "Please try again or contact us."}
          </Alert>
        ) : null}
      </div>
    </div>
  )
}

export default AccessionViewInfo
