import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { ProductTable } from "./tables/ProductTable"
import Breadcrumb from "./custom/Breadcrumb"

function ProductsView(props) {
    const permission = props && props.applicationState.authentication.user.permission

    const breadcrumbItems = [
        {
            label: "products",
            link: `/products-view`,
        },

    ]

    return (
        <div className="wide-container">
            <Breadcrumb title="Products" breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardBody>
                    <ProductTable
                        allowPageSize
                        allowPaginate
                        isGlobalFilter
                        permission={permission}
                    />
                </CardBody>
            </Card>
        </div>
    )
}

export default ProductsView
