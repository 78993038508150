import React, { useState, useEffect } from "react"
import { Button, Container, Row, Col, Input, ModalHeader, ModalBody, Modal, Form, Spinner } from "reactstrap"
import { get } from "utility/client-utility"
import { makeApiCall } from "api/generic-api"
import { useAlert } from "react-alert"
import ReactLoading from "react-loading"
import { v4 as uuidv4 } from "uuid"
import { FaXmark } from "react-icons/fa6"
import TrayLayout from "components/tray-layout"
import { useNavigate } from "react-router"
import GeneralFormTrayNew from "components/general-form-tray-new"

const TrayDetails = (props) => {
  const navigate = useNavigate()
  const alert = useAlert()

  let unDisplayedResultCount = 0
  const structure = props && props.structure

  const [isLoading, setIsLoading] = useState(false)
  const [trayConfiguration, setTrayConfiguration] = useState(() => {
    return null
  })
  const [currentRecord, setCurrentRecord] = useState(() => {
    return null
  })
  const [formState, setFormState] = useState(null)
  const [isFormDisabled, setIsFormDisabled] = useState(false)
  const [updatedFormStructure, setUpdatedFormStructure] = useState(null)
  const [cancerGroup, setCancerGroup] = useState([])

  useEffect(() => {
    getTrayConfiguration()
  }, [])

  const getTrayConfiguration = async () => {
    const result = await makeApiCall("get", `/api/test-configuration`)
    const cancerGroupData = result.body.filter((item) => item.testGroup === "cancer" && item?.product?.status == "active")
    setCancerGroup(cancerGroupData)
  }

  const [toggleAddSampleModal, setToggleAddSampleModal] = useState(false)
  const [searchingSample, setSearchingSample] = useState(false)
  const [addingSelectedSamples, setAddingSelectedSamples] = useState(false)
  const [searchSampleResults, setSearchSampleResults] = useState([])
  const [selectedSamples, setSelectedSamples] = useState([])
  const [searchSampleForm, setSearchSampleForm] = useState({
    sampleId: "",
    firstName: "",
    lastName: "",
  })
  const [enableBinButtons, setEnableBinButtons] = useState(false)

  useEffect(() => {
    const fetchDetails = async () => {
      setIsLoading(true)
      const trayConfig = await getCurrentTrayConfigFromTray(props.id)
      const trayDetails = await getTrayDetails(props.id)
      setTrayConfiguration(trayConfig)
      setCurrentRecord(trayDetails)
      setFormState(trayDetails)
      setIsLoading(false)
    }

    fetchDetails()
  }, [props.id])

  useEffect(() => {
    if (currentRecord && structure) {
      setUpdatedFormStructure(getFormStructure(structure, currentRecord))
    }
  }, [currentRecord])

  const closeForm = () => {
    navigate(-1)
  }

  const submitForm = async (data, changeDisplay = true, addSample = false) => {
    if (!data) {
      console.error("Null form state in submission")
      return
    }
    const uri = get(() => structure.endpoints.patch)
    if (!uri) {
      console.error("No patch uri set for tray update")
    } else {
      const status = data.newStatus ? await getNextStatus(data) : null
      // TODO iterate properties of tray config
      const parameters = {
        id: data._id || data.id,
        lotNumber: data.lotNumber,
      }
      if (status) {
        parameters.status = status
      }

      if (addSample && data.tests) {
        parameters.tests = data.tests
      }

      try {
        if (parameters?.tests?.length) {
          const transactionHistory = {
            id: await uuidv4(),
            timestamp: new Date().toISOString(),
            user: props.applicationState.authentication.user,
            type: "add-tray",
            transaction: {
              data: {
                id: parameters?.tests[parameters?.tests?.length - 1],
                field: "-",
                value: data._id,
                state: null,
              },
              action: {
                descShort: "add-tray",
                desc: "Add tray",
              },
            },
            transactionId: parameters?.tests[parameters?.tests?.length - 1],
            transactionField: "-",
            transactionValue: data._id,
          }

          parameters.transactionHistory = [transactionHistory]
        }

        if (parameters?.status) {
          const transactionHistory = {
            id: await uuidv4(),
            timestamp: new Date().toISOString(),
            user: props.applicationState.authentication.user,
            type: "change-tray-status",
            transaction: {
              data: {
                id: null,
                field: "status",
                value: parameters.status,
                state: null,
              },
              action: {
                descShort: "change-tray-status",
                desc: "Change tray status (" + (data._id || data.id) + ")",
              },
            },
            transactionId: data._id || data.id,
            transactionField: "status",
            transactionValue: parameters.status,
          }

          parameters.transactionHistory = [transactionHistory]
        }

        const result = await makeApiCall("patch", uri, parameters)

        setFormState((prev) => ({
          ...prev,
          ...parameters,
        }))

        setCurrentRecord((prev) => ({
          ...prev,
          ...parameters,
        }))
        // changeDisplay && setDisplayMode('table');

        return result
      } catch (err) {
        console.error("Error in action", err)
      }
    }
  }

  const getCurrentTrayConfigFromTray = async (_id) => {
    if (_id) {
      const trayId = _id

      const trayResult = await makeApiCall("get", `/api/tray-config/${encodeURIComponent(trayId)}`)
      return trayResult.body
    }
  }

  const getTrayDetails = async (_id) => {
    if (_id) {
      const selector = {
        _id: {
          $eq: _id,
        },
      }

      const trayResult = await makeApiCall("get", "/api/trays", {
        selector: selector,
        fields: [
          "_id",
          "id",
          "trayConfigurationLabel",
          "testType",
          "maxFill",
          "ownerId",
          "status",
          "id",
          "trayConfigurationId",
          "trayConfigurationLabel",
          "stage",
          "testType",
          "maxFill",
          "ownerId",
          "lotNumber",
          "status",
          "tests",
        ],
      })

      return trayResult.body?.[0]
    }
  }

  const currentTrayConfiguration = (currentRecord) => {
    const trayConfig = props.applicationState.trayConfiguration?.find((t) => t.id === currentRecord.trayConfigurationId)
    if (!trayConfig) {
      console.error("No tray configuration found for id " + currentRecord.trayConfigurationId)
    }
    return trayConfig
  }

  const getNextStatus = async (currentRecord) => {
    const status = currentRecord.status
    if (!status) {
      console.error("No tray status found")
    }
    const trayConfiguration = currentTrayConfiguration(currentRecord)

    const statusPosition = trayConfiguration?.statuses?.findIndex((s) => s === status)
    if (statusPosition === null || statusPosition === undefined) {
      console.error("Could not find existing tray status " + status)
      return null
    }
    const statusNextPosition = statusPosition + 1
    const nextStatus = trayConfiguration.statuses.length > statusNextPosition ? trayConfiguration.statuses[statusNextPosition] : null
    return nextStatus
  }

  const getFormStructure = async (structure, currentRecord) => {
    // TODO refactor: everything using formStructure should use this for dynamic structure by type
    const newFormStructure = JSON.parse(JSON.stringify(structure.form))
    const newStatus = await getNextStatus(currentRecord)
    if (newStatus) {
      const newStatusField = {
        label: "Set to " + newStatus,
        name: "newStatus",
        type: "checkbox",
        required: false,
        style: { maxWidth: "200px" },
      }
      // third section is processing.  TODO find by section name?
      newFormStructure.sections[2].fields.push(newStatusField)
    }

    setUpdatedFormStructure(newFormStructure)
    return newFormStructure
  }

  const getTestConfigurations = async () => {
    const url = "/api/core/test-configurations"
    const result = await makeApiCall("get", url)
    return result
  }

  const handleAddSelectedSamples = async () => {
    setAddingSelectedSamples(true)
    setEnableBinButtons(false)

    if (!selectedSamples.length) {
      alert.show("No selected sample", { type: "success" })
      return
    }

    const allTestConfigurations = await getTestConfigurations()
    const testType = allTestConfigurations.body.find((item) => item.testType === formState.testType)
    const modifiedSelectedSamples = selectedSamples.map((sample) => sample + testType.id)

    for (const modifiedSelectedSample of modifiedSelectedSamples) {
      const data = formState
      const oldTests = data.tests
      let newTests = []
      if (data.tests) {
        newTests = data.tests.concat(modifiedSelectedSample)
      } else {
        newTests = [modifiedSelectedSample]
      }

      data.tests = [...new Set(newTests)]

      const result = await submitForm(data, false, true)
      if (result.ok) {
        setFormState((prev) => ({
          ...prev,
          data,
        }))

        setCurrentRecord(data)
        alert.show(`${modifiedSelectedSample} has been successfully added`, { type: "success" })
      } else {
        data.tests = oldTests
        setFormState((prev) => ({
          ...prev,
          data,
        }))

        setCurrentRecord(data)
        alert.show(result.error, { type: "error" })
      }
    }

    setAddingSelectedSamples(false)
    setToggleAddSampleModal(false)
  }

  const handleOnChangeSearchSampleForm = (e) => {
    const value = e.target.value
    const name = e.target.name
    if (name == "sampleId") {
      setSearchSampleForm((previousQuery) => ({
        ...previousQuery,
        firstName: "",
        lastName: "",
        [name]: value,
      }))
    } else {
      setSearchSampleForm((previousQuery) => ({
        ...previousQuery,
        [name]: value,
        sampleId: "",
      }))
    }
  }

  const resetSearchSampleForm = () => {
    setSearchSampleForm({
      sampleId: "",
      firstName: "",
      lastName: "",
    })

    setSearchSampleResults([])
    setSelectedSamples([])
  }

  const removeSample = () => {
    setEnableBinButtons(true)
  }

  const handleSearchSample = async () => {
    if ((searchSampleForm.firstName && !searchSampleForm.lastName) || (!searchSampleForm.firstName && searchSampleForm.lastName)) {
      alert.show("Invalid search params", { type: "error" })
      return
    }

    setSearchingSample(true)
    setSearchSampleResults([])

    let selector = {}
    if (searchSampleForm.sampleId) {
      selector._id = searchSampleForm.sampleId
    }

    if (searchSampleForm.firstName && searchSampleForm.lastName) {
      selector = {
        firstName: {
          $regex: "^(?i)" + searchSampleForm.firstName + "$",
        },
        lastName: {
          $regex: "^(?i)" + searchSampleForm.lastName + "$",
        },
      }
    }

    const result = await makeApiCall("get", "/api/samples", {
      selector: selector,
      fields: ["firstName", "lastName", "id", "cDna", "subjectId"],
    })

    if (result.ok) {
      if (result.body.length) {
        setSearchSampleResults(result.body)
      } else {
        alert.show("No results found", { type: "error" })
      }
    }

    setSearchingSample(false)
  }

  const checkTrayByTests = async (testId) => {
    let notExisting
    const url = "/api/netest/tray-tests"
    const result = await makeApiCall("get", url, { testId: testId })
    return result.body
  }

  const handleSelectSamples = async (e) => {
    const value = e.target.value
    const checked = e.target.checked

    const data = formState

    if (checked) {
      if (data.tests?.length + selectedSamples.length >= Number(data?.maxFill)) {
        alert.show(`You cannot select more than ${data?.maxFill} samples`, { type: "error" })
        return
      }

      const checkTests = await checkTrayByTests(value)

      if (checkTests.exists === true) {
        alert.show(`Sample already exists in tray ${checkTests.trayId}`, { type: "error" })
        return
      } else if (data.tests?.includes(value + "N")) {
        alert.show("Sample is already in the tray", { type: "error" })
        return
      } else {
        setSelectedSamples((prevState) => {
          prevState.push(value)
          return [...prevState]
        })
      }
    } else {
      const index = selectedSamples?.findIndex((x) => x == value)
      setSelectedSamples((prevState) => {
        return [...prevState.slice(0, index), ...prevState.slice(index + 1)]
      })
    }
  }

  const handleCancelRemove = () => {
    setEnableBinButtons(false)
  }

  if (isLoading) {
    return (
      <center>
        <Spinner type="grow" />
      </center>
    )
  }

  return (
    <div className={props.className} style={props.style}>
      {currentRecord && currentRecord?.testConfiguration?.testGroup == "cancer" && (
        <div className="text-right">
          <Row sm="12" className="mb-3">
            <Col sm="3" className="offset-3">
              {enableBinButtons == true && (
                <Button type="button" color="success" outline onClick={() => handleCancelRemove()} style={{ float: "right" }}>
                  <FaXmark style={{ paddingBottom: "5px" }} />
                </Button>
              )}
            </Col>
            <Col sm="3">
              <Button
                type="button"
                color="danger"
                disabled={currentRecord.status != "Filling"}
                onClick={() => removeSample()}
                style={{ display: "block", width: "100%" }}
              >
                Remove Sample(s)
              </Button>
            </Col>
            <Col sm="3">
              <Button
                type="button"
                color="primary"
                disabled={!isFormDisabled || !(currentRecord.tests?.length < currentRecord.maxFill) || currentRecord.status != "Filling"}
                onClick={() => {
                  resetSearchSampleForm()
                  setToggleAddSampleModal(true)
                }}
                style={{ display: "block", width: "100%" }}
              >
                Add Samples
              </Button>
            </Col>
          </Row>
        </div>
      )}

      <Modal isOpen={toggleAddSampleModal} toggle={setToggleAddSampleModal} backdrop={true} size={searchSampleResults.length ? "xl" : "md"}>
        <ModalHeader>Search Sample</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Container>
                <Form onSubmit={handleSearchSample}>
                  <Input
                    type="text"
                    name="sampleId"
                    placeholder="Sample ID"
                    value={searchSampleForm.sampleId}
                    onChange={handleOnChangeSearchSampleForm}
                    disabled={searchingSample}
                  />
                  <Row>
                    <Col>
                      <hr className="mt-4" />
                    </Col>
                    <div className="mt-3">OR</div>
                    <Col>
                      <hr className="my-4" />
                    </Col>
                  </Row>

                  <Input
                    type="text"
                    name="firstName"
                    placeholder="First name"
                    value={searchSampleForm.firstName}
                    onChange={handleOnChangeSearchSampleForm}
                    disabled={searchingSample}
                  />
                  <Input
                    className="mt-2"
                    type="text"
                    name="lastName"
                    placeholder="Last name"
                    value={searchSampleForm.lastName}
                    onChange={handleOnChangeSearchSampleForm}
                    disabled={searchingSample}
                  />

                  <Button
                    type="submit"
                    color="danger"
                    className="mt-3 w-100"
                    disabled={
                      searchingSample || addingSelectedSamples || (!searchSampleForm.sampleId && (!searchSampleForm.firstName || !searchSampleForm.lastName))
                    }
                    onClick={handleSearchSample}
                  >
                    {searchingSample ? (
                      <center>
                        <ReactLoading type="spin" color="white" height={25} width={25} />
                      </center>
                    ) : (
                      "Search"
                    )}
                  </Button>
                  <Button
                    color="secondary"
                    className="my-2 w-100"
                    disabled={searchingSample || addingSelectedSamples}
                    onClick={() => {
                      setToggleAddSampleModal(false)
                      resetSearchSampleForm()
                    }}
                  >
                    Cancel
                  </Button>
                </Form>
              </Container>
            </Col>
            {searchSampleResults.length ? (
              <Col>
                <Container>
                  <h5 className="mb-3">Search Results</h5>
                  {searchSampleResults.length &&
                    searchSampleResults.map((user, idx) => {
                      if (!user?.cDna?.length) {
                        unDisplayedResultCount++
                        return
                      }

                      let hasUnprocessedDilutions = user.cDna.map((cDna) => {
                        return cDna?.dilutions?.map((dilution) => !dilution?.processed)
                      })[0]

                      hasUnprocessedDilutions = hasUnprocessedDilutions?.includes(true)

                      if (hasUnprocessedDilutions) {
                        return (
                          <div className="mt-2" key={idx}>
                            <div className={"tray-layout"} style={{ textAlign: "left" }}>
                              <table>
                                <tr style={{ color: "red" }}>
                                  <th colSpan={3}>
                                    {user.firstName + " " + user.lastName} ({user.subjectId})
                                  </th>
                                </tr>
                                <tr>
                                  <th>Select</th>
                                  <th>Sample ID</th>
                                  <th>Location</th>
                                </tr>

                                {user?.cDna?.length &&
                                  user.cDna.map((cDna) => {
                                    return (
                                      cDna?.dilutions?.length &&
                                      cDna.dilutions.map((dilution, idx) => {
                                        if (!dilution?.processed) {
                                          return (
                                            <tr key={idx}>
                                              <td className="pointer-event">
                                                <input
                                                  checked={selectedSamples.includes(dilution.id)}
                                                  className="mr-2"
                                                  type="checkbox"
                                                  value={dilution.id}
                                                  id={dilution.id}
                                                  onChange={handleSelectSamples}
                                                />
                                              </td>
                                              <td className="pointer-event">
                                                <label htmlFor={dilution.id}>{dilution.id}</label>
                                              </td>
                                              <td className="text-left pointer-event">
                                                <label htmlFor={dilution.id}>{dilution?.location}</label>
                                              </td>
                                            </tr>
                                          )
                                        } else {
                                        }
                                      })
                                    )
                                  })}
                              </table>
                            </div>
                            <hr />
                          </div>
                        )
                      } else {
                        unDisplayedResultCount++
                        return
                      }
                    })}

                  {unDisplayedResultCount != searchSampleResults.length && (
                    <Button
                      color="danger"
                      className="mb-3 w-100"
                      disabled={!selectedSamples.length || addingSelectedSamples}
                      onClick={handleAddSelectedSamples}
                    >
                      {addingSelectedSamples ? (
                        <center>
                          <ReactLoading type="spin" color="white" height={25} width={25} />
                        </center>
                      ) : (
                        "Add Selected Samples"
                      )}
                    </Button>
                  )}

                  {unDisplayedResultCount == searchSampleResults.length && <div>No results found ...</div>}
                </Container>
              </Col>
            ) : null}
          </Row>
        </ModalBody>
      </Modal>

      <Row>
        <Col>
          {currentRecord && (
            <TrayLayout
              tray={currentRecord}
              trayConfiguration={trayConfiguration}
              enabledBin={enableBinButtons}
              setEnableBin={setEnableBinButtons}
              setCurrentRecord={setCurrentRecord}
              setFormState={setFormState}
            />
          )}
        </Col>
      </Row>
      {updatedFormStructure && (
        <GeneralFormTrayNew
          style={{ marginTop: "30px" }}
          formDefinition={updatedFormStructure}
          lookups={props.lookups}
          formState={formState}
          cancel={closeForm}
          update={submitForm}
          setIsFormDisabled={setIsFormDisabled}
          isLoading={isLoading}
          setEnabledBin={setEnableBinButtons}
          {...props}
        />
      )}
    </div>
  )
}

export default TrayDetails
