import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useTestsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["testsList", variables], () => axiosClient.get(`/test-results?${paramsToQuery(variables)}`).then((res) => res.data), { ...rest })
}

export function useTestDetailsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["testsResultDetails", variables?.id], () => axiosClient.get(`/test-result/${variables?.id || ""}`).then((res) => res.data), { ...rest })
}

export function usePcrListQuery({ variables, ...rest } = {}) {
  const { query } = useRequestProcessor()

  const fields = [
    "fileName",
    "submittedTimestamp",
    "authorShort",
    "fileName",
    "results.fileResult",
    "testing",
    "fileName",
    "fileId",
    "submittedTimestamp",
    "author",
    "results.testResults",
  ].map((item) => `"${item}"`)

  const params = encodeURIComponent(`[${fields.toString()}]`)

  return query(["pcr-list", variables], () => axiosClient.get(`/pcrs?${paramsToQuery(variables)}&fields=${params}`).then((res) => res.data), { ...rest })
}

export function usePcrDetailsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["pcr-details", variables?.id], () => axiosClient.get(`/pcr/${variables?.id || ""}`).then((res) => res.data), { ...rest })
}

export function useTestHistoryQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["test-history", variables?.id], () => axiosClient.get(`/accession/test/${variables?.id || ""}`).then((res) => res.data), { ...rest })
}

export function useCovidTestsQuery({ variables, ...rest } = {}) {
  const { query } = useRequestProcessor()

  const fields = [
    "id",
    "subjectId",
    "subjectId",
    "sampleCollectionDateTime",
    "laboratoryReceivedDateTime",
    "laboratoryCompletedDateTime",
    "reportingDateTime",
    "patientInsured",
    "testResult",
  ].map((item) => `"${item}"`)

  const params = encodeURIComponent(`[${fields.toString()}]`)

  return query(["covid-test-list", variables], () => axiosClient.get(`/tests?${paramsToQuery(variables)}&fields=${params}`).then((res) => res.data), {
    ...rest,
  })
}
