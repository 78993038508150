import React from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import ReactLoading from 'react-loading';

const VerificationMessage = () => (
	<div className="text-center">
		<FaCheckCircle size={50} color="green" className="mb-3" />
		<h5 className="mb-2">User is verified</h5>
		<div className="d-flex justify-content-center align-items-center mb-3">
			<ReactLoading type="spin" color="red" height={20} width={20} className="mr-3" />
			<p style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '-5px', marginLeft: '5px' }}>
				Redirecting to the dashboard shortly...
			</p>
		</div>
	</div>
);

export default VerificationMessage;
