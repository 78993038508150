import './display-only-form.css';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
    Form, FormGroup, Label, Input,
    Button, Row, Col, Container, Alert
} from 'reactstrap';
import { get } from '../utility/client-utility';
import * as moment from 'moment';
import { debounce } from "lodash";
import { matcher } from "matcher";

function DisplayOnlyForm(props) {
    const elements = useRef({});
    const initialData = props.data;
    const [formState, setFormState] = useState({ id: props.id, ...props.data });
    const [ oldFormState, setOldFormState ] = useState(props.data);
    const badEmailAddresses = props.applicationState.badEmailAddresses;
    
    useEffect(() => {
        const newFormState = {
            ...formState,
            price: props.price
        };
        
        setFormState(newFormState);
    }, [ props.price ]);
    
    useEffect(() => {
        setFormState({ id: props.id, ...props.data })
    }, [ props.data ]);
    
    
    const sections = get(() => props.formDefinition.sections, []);
    const { isDisableForm, setIsDisableForm} = props;
    
    const validateChange = (e, field) => {
        // validate email with list of bad emails
        if (field.type === 'email') {
            validateEmailChange(e, field);
        }
        
        if (_.includes(['date', 'datetime-local'], field.type)) {
            validateDateChange(e, field)
        }
    }
    
    const validateEmailChange = useRef(
      debounce(async (e, field) => {
          const email = e.target.value;
          const invalid = isInvalidEmailAddress(email);
          if (invalid) {
              e.target.className += " invalid-input"
              return false;
          } else {
              e.target.classList.remove("invalid-input")
              return true;
          }
      }, 300)
    ).current;
    
    const isInvalidEmailAddress = (email) => {
        if (email && badEmailAddresses && badEmailAddresses.length) {
            const matches = matcher([email], badEmailAddresses);
            return matches.length;
        }
    }
    
    const validateDateChange = (e, field) => {
        if (e.target.value) {
            const input = moment(e.target.value);
            const covidStarted = moment("1/1/2020");
    
            const rules = [
                {
                    field: "dateOfBirth",
                    value: input.isBetween(moment().subtract(130, 'y'), moment()),
                    // not in the future, max 130 years in the past.
                },
                {
                    field: "sampleCollectionDateTime",
                    value: input.isBetween(moment().subtract(7, 'd'), moment()),
                    // not in the future, max 7 days in the past.
                },
                {
                    field: "dateOfSymptomOnset",
                    value: input.isBetween(covidStarted, moment()),
                    // not in the future, not before 1/1/2020
                },
                {
                    field: "vaccinationDate",
                    value: input.isBetween(covidStarted, moment()),
                    // not in the future, not before 1/1/2020
                },
            ];
    
            const rule = rules.find(rule => rule.field == field.name);
            if (rule) {
                const element = elements.current[field.name];
                if (!rule?.value) {
                    element.setCustomValidity(`Invalid ${field.label.toLowerCase()}.`);
                    return false;
                } else {
                    element.setCustomValidity('');
                    return true;
                }
            }
        }
    }
    
    const getColumns = (section) => {
        const fields = get(() => section.fields, []);
        const numberOfFields = fields.length;
        
        const numberOfColumns = get(() => section.columns, 1);
        const maxFieldsPerColumn = Math.ceil(numberOfFields / numberOfColumns);
        
        let columnWidth = '6'
        switch (numberOfColumns) {
            case 1:
                columnWidth = '6';
                break;
            case 2:
                columnWidth = '6';
                break;
            case 3:
                columnWidth = '4';
                break;
            default:
                throw new Error('Unhandled column format');
        }

        const columns = [];
        for (let fieldIndex = 0; fieldIndex < numberOfFields; fieldIndex++) {
            const columnIndex = Math.floor(fieldIndex / maxFieldsPerColumn);
            
            if (columnIndex >= columns.length) {
                const column = {
                    width: columnWidth,
                    fields: []
                }
                columns.push(column);
            }
            columns[columnIndex].fields.push(fields[fieldIndex])
        }

        return columns;
    };

    const getOptions = (field) => {
        const empty = [
            {
                key: '',
                value: ''
            }
        ];
        if (!field || !field.source || !field.source.kind || !field.source.data) {
            return empty;
        }

        if (field.source.kind === 'direct') {
            const directResult = empty.concat(field.source.data);
            return directResult;
        }

        if (field.source.kind === 'lookup') {
            const lookupResult = empty.concat(props.lookups.filter(s => s.type === field.source.data));
            return lookupResult;
        }

        if (field.source.kind === 'prop' || field.source.kind === 'props') {
            const lookupResult = empty.concat(props[field.source.data]);
            return lookupResult;
        }

        return empty;
    }


    const onSubmit = e => {
        e.preventDefault();
        console.log('general form submit');
        console.log('formState', formState);
        if(props.submit) {
            formState.id = props.id;
            props.submit(formState);
        }
    };

    const handleChange = (e, field) => {
        validateChange(e, field);
        const target = e.target;
        const name = target.name;
    
        if (field.type === 'qrcode') {
            e.preventDefault();
            return;
        }
    
        const value = target.type === 'checkbox' ? target.checked
          : (target.type === 'number' && isFinite(Number(target.value) )? Number(target.value) : target.value);
    
        let newFormState = {};
        newFormState = {
            ...formState,
            [name]: value
        };
        
        setFormState(newFormState)
        props.fieldsOnChange && props.fieldsOnChange(newFormState)
        if (props.change) {
            props.change();
        }
    };

    const evaluate = (rule, nullValue) => {
        if (rule === undefined || rule === null) {
            return nullValue || false;
        }
        if (!rule instanceof Object) {
            return rule;
        }
        for (const property in rule) {
            if (rule.hasOwnProperty(property)) {
                if (formState[property] !== rule[property]) {
                    return false;
                }
            }
        }
        return true;
    };
    
    const formatValue  = (format, value) => {
        if(value && format === 'toLocalizedDateString') {
            if(value === null || value === undefined) {
                return '';
            }
            
            return new Date(value).toLocaleString();
        }
        if (value && format === 'capitalized') {
            return capitalize(value);
        }
        
        if(format === 'boolean') {
            return value === true ? 'Yes' : (value === false ? 'No' : 'Unset');
        }
        
        return value;
    };

    return (
        <Form className="GeneralForm" onSubmit={onSubmit}>
            {props.formDefinition.formLabel ?
                <Container>
                    <Row>
                        <Col>
                            <h2>{props.formDefinition.formLabel}</h2>
                        </Col>
                    </Row>
                </Container>
                : null
            }
            {sections.map((section, sectionIndex) => {
                    if (evaluate(section.visible, true)) {
                        return <Container key={'section-' + sectionIndex}>
                            {section.label ?
                                <Row className="section-header">
                                    <Col>{section.label}</Col>
                                </Row>
                                : null
                            }
                            <Row>
                                {getColumns(section).map((column, columnIndex) =>
                                    <Col sm={column.width} key={'column-' + columnIndex}>
                                        {column.fields.map(field => {
                                                if (evaluate(field.visible, true)) {
                                                    return (
                                                      <FormGroup key={field.name} style={field.groupStyle}>
                                                        {field.type !== "checkbox" && field.type !== "radio" ? (
                                                          <Label style={{ display: "block" }} for={field.name}>
                                                            {field.label + (field.required ? " *" : "")}{" "}
                                                          </Label>
                                                        ) : null}
                                                        {field.type === "textarea" ? (
                                                          <textarea
                                                            type={field.type}
                                                            name={field.name}
                                                            id={field.name}
                                                            disabled={isDisableForm}
                                                            readOnly={false}
                                                            required={field.required}
                                                            onChange={(e) => handleChange(e, field)}
                                                            style={Object.assign({ display: "block" }, field.style)}
                                                            placeholder={field.placeholder}
                                                            rows={field.rows}
                                                            cols={field.cols}
                                                            wrap={field.wrap}
                                                            value={formState[field.name] || ""}
                                                          ></textarea>
                                                        ) : null}

                                                        {field.type === "timestamp" ||
                                                        (field.type === "datetime-local" && evaluate(field.readonly, false) === true) ? (
                                                          <Input
                                                            type={"text"}
                                                            name={field.name}
                                                            id={field.name}
                                                            value={formatValue(field.format, formState[field.name])}
                                                            checked={field.type === "checkbox" ? formState[field.name] || false : null}
                                                            disabled={isDisableForm || evaluate(field.disabled, false)}
                                                            readOnly={true} //evaluate(field.readonly, false)}
                                                            required={evaluate(field.required, false)}
                                                            pattern={field.pattern}
                                                            title={field.title}
                                                            //onChange={(e) => handleChange(e, field)}
                                                            style={field.style}
                                                            placeholder={field.placeholder}
                                                            className={field.className}
                                                          ></Input>
                                                        ) : null}

                                                        {field.type !== "textarea" &&
                                                        field.type !== "label" &&
                                                        field.type !== "radio" &&
                                                        field.type !== "timestamp" &&
                                                        field.type !== "button" &&
                                                        (field.type !== "datetime-local" ||
                                                          (field.type === "datetime-local" && evaluate(field.readonly, false) === false)) ? (
                                                          <Input
                                                            className={field.type == "email" && isInvalidEmailAddress(formState[field.name]) && "invalid-input"}
                                                            type={field.type}
                                                            name={field.name}
                                                            id={field.name}
                                                            value={
                                                              field.type === "checkbox"
                                                                ? ""
                                                                : formState[field.name] === null || formState[field.name] === undefined
                                                                ? ""
                                                                : field.format != "toLocalizedDateString"
                                                                ? formatValue(field.format, formState[field.name])
                                                                : moment(formState[field.name]).format("YYYY-MM-DDTHH:mm") ?? ""
                                                            }
                                                            checked={field.type === "checkbox" ? formState[field.name] : false}
                                                            disabled={field.readonly || isDisableForm}
                                                            required={field.required}
                                                            pattern={field.pattern}
                                                            title={field.title}
                                                            onChange={(e) => {
                                                              e.persist()
                                                              handleChange(e, field)
                                                            }}
                                                            innerRef={(element) => (elements.current[field.name] = element)}
                                                            style={field.type === "checkbox" ? Object.assign({ marginLeft: 0 }, field.style) : field.style}
                                                            placeholder={field.placeholder}
                                                          >
                                                            {field.type === "select"
                                                              ? getOptions(field).map((item) => (
                                                                  <option key={item.key} value={item.key}>
                                                                    {item.value || item.key}
                                                                  </option>
                                                                ))
                                                              : null}
                                                          </Input>
                                                        ) : null}
                                                        {field.type === "checkbox" ? (
                                                          <Label for={field.name} disabled={field.readonly || isDisableForm} style={{ marginLeft: "2rem" }}>
                                                            {field.label + (field.required ? " *" : "")}
                                                          </Label>
                                                        ) : null}
                                                        {field.type === "radio"
                                                          ? getOptions(field).map((item) =>
                                                              item.key !== "" ? (
                                                                <span key={item.key} style={field.itemStyle && field.itemStyle.span}>
                                                                  <Input
                                                                    type="radio"
                                                                    name={field.name}
                                                                    id={field.name + "-" + item.key}
                                                                    value={item.key}
                                                                    checked={item.key === formState[field.name]}
                                                                    disabled={field.readonly || isDisableForm}
                                                                    readOnly={true}
                                                                    required={evaluate(field.required, false)}
                                                                    onChange={(e) => handleChange(e, field)}
                                                                    style={Object.assign(
                                                                      Object.assign(
                                                                        {
                                                                          marginLeft: "10px",
                                                                          marginTop: "5px",
                                                                        },
                                                                        field.itemStyle || {},
                                                                      ),
                                                                      field.itemStyle.radio || {},
                                                                    )}
                                                                    className={field.className}
                                                                  />
                                                                  <label htmlFor={field.name + " " + item.key} style={field.itemStyle && field.itemStyle.label}>
                                                                    {item.value || item.key}
                                                                  </label>
                                                                  <br />
                                                                </span>
                                                              ) : null,
                                                            )
                                                          : null}
                                                      </FormGroup>
                                                    )
                                                }
                                            }
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    }
                }
            )}
    
            <Container className={"general-form-buttons"}>
                <Row>
                    <Col sm="12">
                        <div className="float-right">
                            {isDisableForm && !formState.taps && props.formDefinition.edit &&
                              <Button
                                  type="submit"
                                  outline color="primary"
                                  onClick={() => {
                                      setIsDisableForm(false)
                                      props.selectSubject && props.selectSubject(null)
                                  }}
                                  style={Object.assign({marginRight: '20px'}, get(() => props.formDefinition.edit?.style, {}))}
                                  disabled={props.selectedSubjectId || evaluate(props.formDefinition.edit?.disabled, false)}>
                                  {get(() => props.formDefinition.edit?.label, 'Edit')}
                              </Button>}
    
                            {!isDisableForm && !formState.taps &&
                                <div>
                                    <Button
                                      type="button"
                                      outline color="secondary"
                                      style={get(() => props.formDefinition.cancel.style)}
                                      onClick={() => {
                                          setIsDisableForm(true);
                                          setFormState({
                                              ...oldFormState
                                          });
                                      }}>
                                        {get(() => props.formDefinition.cancel.label, 'Cancel')}
                                    </Button>
                                    <Button
                                      className="ml-2"
                                      type="submit"
                                      outline color="primary"
                                      onClick={() => {}}
                                      style={Object.assign({marginRight: '20px'}, get(() => props.formDefinition.submit.style, {}))}
                                      disabled={evaluate(props.formDefinition.submit.disabled, false)}>
                                        {get(() => props.formDefinition.submit.label, 'Submit')}
                                    </Button>
                                </div>
                            }
                        </div>
                    </Col>
                </Row>
            </Container>
        </Form>
    );
}

DisplayOnlyForm.propTypes = {
    formDefinition: PropTypes.object.isRequired,
    lookups: PropTypes.array.isRequired
};

export default DisplayOnlyForm;
