import React, { useState } from "react"
import { Alert, Button, Label, Row, Col, Spinner, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import localizedFormat from "dayjs/plugin/localizedFormat"
import utc from "dayjs/plugin/utc"
import { makeApiDownloadCall } from "api/generic-api"
import { useUnfilledOrdersQuery } from "api/getters"

dayjs.extend(utc)
dayjs.extend(localizedFormat)

const UnfilledOrderDetails = ({ orderId }) => {
  const { data, isLoading } = useUnfilledOrdersQuery({
    variables: {
      page: 1,
      selector: {
        _id: orderId,
      },
    },
  })

  const orderData = data?.[0] || {}

  const [modal, setModal] = useState(false)
  const [downloadError, setDownloadError] = useState("")
  const [successMessage, setSuccessMessage] = useState("")
  const [downloading, setDownloading] = useState(false)

  const toggleModal = () => {
    setModal(!modal)
  }

  const downloadReport = async () => {
    try {
      setDownloadError("")
      setSuccessMessage("")
      setDownloading(true)
      const response = await makeApiDownloadCall("get", `/api/document/test/${orderId}.pdf`, {}, "application/pdf", `${orderId}.pdf`)
      if (!response?.ok) {
        setDownloadError("PDF not yet available")
      } else {
        setSuccessMessage("PDF downloaded successfully")
      }
    } catch (error) {
      console.error(error)
      setDownloadError("An error occurred while downloading the PDF")
    } finally {
      setDownloading(false)
    }
  }

  return (
    <form>
      {downloadError && (
        <Col md={12}>
          <Alert color="warning">{downloadError}</Alert>
        </Col>
      )}
      {successMessage && (
        <Col md={12}>
          <Alert color="success">{successMessage}</Alert>
        </Col>
      )}
      {/* {downloadError && (
        <Alert color="warning" role="alert">
          {downloadError}
        </Alert>
      )}
      {successMessage && <Alert color="success">{successMessage}</Alert>}
      {isError && (
        <Alert color="warning" role="alert">
          {error?.response?.data?.message || error?.response?.data?.reason}
        </Alert>
      )} */}
      {isLoading ? (
        <Spinner type="grow" />
      ) : (
        <Row>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Test ID</Label>
              <input disabled readOnly type="text" className="form-control" value={orderData?.id || ""} />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Patient ID</Label>
              <Link to={`/subject-info/${orderData?.subjectId}/personal`} className="text-secondary" target="_blank">
                <input readOnly type="text" className="form-control cursor-pointer" value={orderData?.subjectId || ""} />
              </Link>
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Insured</Label>
              <input disabled readOnly type="text" className="form-control" value={orderData?.patientInsured || ""} />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Collected</Label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control"
                value={orderData?.sampleCollectionDateTime ? dayjs(orderData.sampleCollectionDateTime).format("MM-DD-YYYY") : ""}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Insurance Group ID</Label>
              <input disabled readOnly type="text" className="form-control" value={orderData?.insuranceGroupId || ""} />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Accessioned</Label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control"
                value={orderData?.laboratoryReceivedDateTime ? dayjs(orderData.laboratoryReceivedDateTime).format("MM-DD-YYYY") : ""}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Insurance Company</Label>
              <input disabled readOnly type="text" className="form-control" value={orderData?.insuranceCompany || ""} />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Reported</Label>
              <input
                disabled
                readOnly
                type="text"
                className="form-control"
                value={orderData?.laboratoryCompletedDateTime ? dayjs(orderData.laboratoryCompletedDateTime).format("MM-DD-YYYY") : ""}
              />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Insurance Subscriber No</Label>
              <input disabled readOnly type="text" className="form-control" value={orderData?.insuranceSubscriberNo || ""} />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">PCR Updated</Label>
              <input disabled readOnly type="text" className="form-control" value={orderData?.pcrUpdatedDateTime ? dayjs(orderData.pcrUpdatedDateTime).format("MM-DD-YYYY") : ""} />
            </div>
          </Col>
          <Col md={6}>
            <div className="mb-3">
              <Label htmlFor="formrow-text-Input">Attestation</Label>
              <input disabled readOnly type="text" className="form-control" value={orderData?.attestation || ""} />
            </div>
          </Col>
          <div className="d-flex gap-2 mt-2">
            <Button color="primary" disabled={downloading} onClick={downloadReport}>
              {downloading && <Spinner size="sm" />} Download Report
            </Button>
            <Button onClick={toggleModal} color="secondary">
              View Test Result
            </Button>
          </div>
          <Modal isOpen={modal} toggle={toggleModal} size="lg">
            <ModalHeader toggle={toggleModal}>Test Result</ModalHeader>
            <ModalBody style={{ overflowY: "auto" }}>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Result</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {orderData?.testResult &&
                    Object.entries(orderData.testResult).map(([key, value]) => (
                      <tr key={key}>
                        <td>{key}</td>
                        <td>{value != null ? value.toString() : ""}</td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            </ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={toggleModal}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </Row>
      )}
    </form>
  )
}

export default UnfilledOrderDetails
