import React from "react"
import Breadcrumb from "./custom/Breadcrumb"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { usePreviousTableQuery, useQuery } from "hooks/query"
import InsuranceCompanyDetails from "./custom/InsuranceCompanyDetails"
import { useParams } from "react-router"

function InsuranceViewInfo(props) {
    const permission = props && props.applicationState.authentication.user.permission
    const { id } = useParams()

    const previousTableQuery = usePreviousTableQuery("/insurance-company-view")

    const breadcrumbItems = [
        {
            label: "Insurance Companies",
            link: `/insurance-company-view?${previousTableQuery}`,
        },
        {
            label: id,
            link: `/insurance-company-view?id=${id}`,
        },
    ]

    return (
        <div className="wide-container">
            <Breadcrumb title={id} breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Insurance Companies Details</CardTitle>
                </CardHeader>
                <CardBody>
                    <InsuranceCompanyDetails id={id} permission={permission} />
                </CardBody>
            </Card>
        </div>
    )
}

export default InsuranceViewInfo
