import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import Breadcrumb from "../custom/Breadcrumb"
import NotificationForm from "./notificationForm"
import { usePreviousTableQuery } from "hooks/query"

function notificationViewForm(props) {
    const permission = props && props.applicationState.authentication.user.permission;
    const previousQuery = usePreviousTableQuery("/notifications")

    const breadcrumbItems = [
        {
            label: "Announcement",
            link: `/notifications?${previousQuery}`,
        },
        {
            label: "Announcement Form",
            link: `/notifications-form`,
        },

    ]

    return (
        <div className="wide-container">
            <Breadcrumb title="Announcement " breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Announcement Form</CardTitle>
                </CardHeader>
                <CardBody>
                    <NotificationForm permission={permission} />
                </CardBody>
            </Card>
        </div>
    )
}

export default notificationViewForm
