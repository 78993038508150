import React, { useEffect } from "react"
import { useShallow } from "zustand/react/shallow"
import qs from "qs"
import { useQuery } from "hooks/query"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { useLocation } from "react-router"

const TableQuery = () => {
  const location = useLocation()
  const query = useQuery()
  const queryString = query?.toString()
  const queryObject = qs.parse(queryString)
  const { page, pageSize, selector, sort } = queryObject

  const pathname = location.pathname

  const setTableQuery = useTableQueryStore(useShallow((state) => state.setTableQuery))

  useEffect(() => {
    setTableQuery({
      page: page || "1",
      pageSize: pageSize || "10",
      selector: selector || undefined,
      sort: sort || undefined,
    })

    return () => {
      setTableQuery({
        previousQuery: query?.toString(),
        previousPath: pathname,
      })
    }
  }, [page, pageSize, selector, sort, pathname, setTableQuery])

  return <></>
}

export default TableQuery
