import React from "react"
import { Card, CardBody } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import { CovidTestTable } from "./tables/CovidTestTable"

function TestViewNew(props) {
  const breadcrumbItems = [
    {
      label: "Covid Tests",
      link: `/test-view`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Covid Tests" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <CovidTestTable
            allowPageSize
            allowPaginate
            isGlobalFilter
            customSelect={{
              testType: {
                $in: ["covid", "covid-thermo-fisher"],
              },
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default TestViewNew
