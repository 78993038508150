import React, { useState } from "react"
import { Form, Row, Col, Label, Button, Table, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { useForm } from "react-hook-form"
import localizedFormat from "dayjs/plugin/localizedFormat"
import { ModalTitle } from "react-bootstrap"
import useDisclosure from "hooks/disclosure"
import { Link } from "react-router-dom"
import { getEncodedQuery } from "utils/query"
import { useQuery } from "hooks/query"
import { TransactionHistoryTable } from "components/tables/TransactionHistoryTable"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

const ResultUploadDetails = ({ type = "cancer", id, data, permission }) => {
  const query = useQuery()
  const [testId, setTestId] = useState(null)
  const [auditId, setAuditId] = useState(null)

  const { isOpen, toggleModal } = useDisclosure()
  const { isOpen: auditIsOpen, toggleModal: auditToggleModal } = useDisclosure()

  const { handleSubmit } = useForm({
    defaultValues: {
      id: "",
      fileName: "",
      submitted: "",
      author: "",
    },
  })

  const onSubmit = handleSubmit(async (data) => {})

  const selectedRecord = data?.results?.testResults?.find((item) => item.testId === testId)?.result
  const sortedRecords = [...(data?.results?.testResults || [])].sort((a, b) => a.testId.localeCompare(b.testId))

  return (
    <Form onSubmit={onSubmit}>
      <div className="mb-4">
        <Link to={`/process-${type}-pcr?${getEncodedQuery({ id: "" }, "", query.toString())}`} className="btn btn-primary">
          <i className="fa fa-caret-left" /> Go Back
        </Link>
      </div>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">ID</Label>
            <input readOnly type="text" className="form-control" value={data?.id || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">File Name</Label>
            <input readOnly type="text" className="form-control" value={data?.fileName || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Submitted</Label>
            <input readOnly type="date" className="form-control" value={data?.submittedTimestamp ? dayjs(data?.submittedTimestamp).format("YYYY-MM-DD") : ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Author</Label>
            <input readOnly className="form-control" value={data?.author} />
          </div>
        </Col>
      </Row>
      <div>
        <Table responsive hover>
          <thead>
            <tr>
              <th>Test ID</th>
              <th className={type === "cancer" ? "text-end" : "text-start"}>{type === "cancer" ? "Net Probability" : "Result"}</th>
              {type === "cancer" ? (
                <>
                  <th className="text-end">Progressive Probability</th>
                  <th>Process Result</th>
                  <th></th>
                </>
              ) : (
                <>
                  <th className="text-end">n1</th>
                  <th className="text-end">n3</th>
                  <th className="text-end">rnasep</th>
                </>
              )}
            </tr>
          </thead>
          <tbody>
            {sortedRecords.map((item, i) => (
              <tr key={item.testId}>
                <td>{item.testId}</td>
                {type === "cancer" ? (
                  <>
                    <td className="text-end">{item.result?.NETest_NET_proba}</td>
                    <td className="text-end">{item.result?.NETest_progressive_proba}</td>
                    <td>{item.result?.individualResult}</td>
                    <td className="d-flex gap-2">
                      {item.result?.individualResult &&
                        <Link className="btn btn-outline-primary" to={`/process-${type}-pcr?${getEncodedQuery({ testId: item.testId }, "", query.toString())}`}>
                          View
                        </Link>
                      }
                      
                      <Button
                        outline
                        onClick={() => {
                          setTestId(item.testId)
                          toggleModal()
                        }}
                      >
                        PCR Detail
                      </Button>
  
                      {item.result?.individualResult &&
                        <Button
                          color="warning"
                          outline
                          onClick={() => {
                            setAuditId(item.testId)
                            auditToggleModal()
                          }}
                        >
                          Audit
                        </Button>
                      }
                    </td>
                  </>
                ) : (
                  <>
                    <td className="text-start">{item.result}</td>
                    <td className="text-end">{item?.signal?.n1}</td>
                    <td className="text-end">{item?.signal?.n3}</td>
                    <td className="text-end">{item?.signal?.rnasep}</td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      <Modal size="lg" toggle={toggleModal} isOpen={isOpen}>
        <ModalHeader>
          <ModalTitle>PCR Details</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {selectedRecord && (
            <table className="table mt-3">
              <thead>
                <tr>
                  <th>Result</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                {Object.keys(selectedRecord).map((key) => {
                  if (key !== "testConfig" && key !== "cancer" && key !== "result") {
                    return (
                      <tr key={"test-config" + key}>
                        <td>{key}</td>
                        <td>{selectedRecord[key] === true ? "True" : selectedRecord[key] === false ? "False" : selectedRecord[key]}</td>
                      </tr>
                    )
                  }
                })}
              </tbody>
            </table>
          )}
        </ModalBody>
      </Modal>
      <Modal toggle={auditToggleModal} isOpen={auditIsOpen} backdrop={true} size="xl">
        <ModalHeader>
          <ModalTitle>Transaction History</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <TransactionHistoryTable type="test" id={auditId} customPageSize={1000} />
        </ModalBody>
        <ModalFooter>
          <Button type="button" outline color="secondary" onClick={auditToggleModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </Form>
  )
}

export default ResultUploadDetails
