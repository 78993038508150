import React from "react"
import { Card, CardBody } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import { TestsTable } from "./tables/TestsTable"

function CancerTestsView(props) {
  const breadcrumbItems = [
    {
      label: "Cancer Tests",
      link: `/cancer-tests-view`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Cancer Tests" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <TestsTable
            allowPageSize
            allowPaginate
            isGlobalFilter
            customSelect={{
              testType: {
                $nin: ["covid", "covid-thermo-fisher"],
              },
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default CancerTestsView
