import React, { useState, useEffect } from "react";
import {
    Button,
    Label,
    Row,
    Col,
    Spinner,
    Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNotificationFormSubmit } from "api/mutators";

const schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    message: Yup.string().required("Message is required"),
});

const notificationForm = ({ item }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        handleSubmit,
        control,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            title: item?.title || "",
            message: item?.message || "",

        },
    });

    const { mutate, isLoading: mutating } = useNotificationFormSubmit({
        onSuccess: () => {
            setSuccessMessage("Announcement has been successfully created");
            setErrorMessage("");
            setIsSubmitted(true);
        },
        onError: (error) => {
            const apiErrorMessage = error?.response?.data?.error;
            console.error("API Error:", apiErrorMessage);
            setErrorMessage(apiErrorMessage || "An unexpected error occurred.");
        },
    });



    const onSubmit = (data) => {
        const formValues = {
            title: data.title,
            message: data.message,
        };
        mutate(formValues);
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner size="sm" />
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                {errorMessage && (
                    <Alert color="danger" className="mt-3">
                        {errorMessage}
                    </Alert>
                )}
                {successMessage && (
                    <Alert color="success" className="mt-3">
                        {successMessage}
                    </Alert>
                )}
                <Col className="mb-3" lg="6">
                    <Label className="form-label">Title * </Label>
                    <Controller
                        name="title"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${errors.title ? "is-invalid" : ""}`}
                                {...field}
                                disabled={isSubmitted}
                                placeholder="Enter Title"
                            />
                        )}
                    />
                    {errors.title && <div className="text-danger">{errors.title.message}</div>}
                    <Col className="mt-3" >
                        <Label className="form-label">Message *</Label>
                        <Controller
                            name="message"
                            control={control}
                            render={({ field }) => (
                                <textarea
                                    className={`form-control ${errors.message ? "is-invalid" : ""}`}
                                    {...field}
                                    disabled={isSubmitted}
                                    placeholder="Enter Message "
                                    rows="4"
                                />
                            )}
                        />
                        {errors.message && <div className="text-danger">{errors.message.message}</div>}
                    </Col>
                </Col>



                <div className="d-flex justify-content-end gap-3 mt-4">
                    <Button color="primary" type="submit" disabled={isSubmitted}>
                        {mutating ? <Spinner size="sm" /> : "Submit"}
                    </Button>
                </div>
            </Row>
        </form>
    );
};

export default notificationForm
