import "../general-form.css"
import React, { useState, useRef, useEffect } from "react"
import PropTypes from "prop-types"
import { Form, FormGroup, Label, Input, Button, Row, Col, Container } from "reactstrap"
import { get, getValueByDotNotation } from "../../utility/client-utility"
import GeneralTable from "../general-table"
import { makeApiCall, makeApiDownloadCall } from "../../api/generic-api"
import { useAlert } from "react-alert"

import { debounce } from "lodash"
import { matcher, isMatch } from "matcher"

import * as moment from "moment"
import ReactLoading from "react-loading"
import lookups from "../../configuration/lookups"
import RequisitionsConfigurationQuestionsForm from "./requisitions-configuration-questions-form"
import netestRequisitionFormDefinition from "../../configuration/netest-requisition-new-form-definition"

function GeneralFormRequisitionsConfigurationNew(props) {
  const { currentRecord } = props
  // console.log('props.formState', props.formState);
  const elements = useRef({})
  const sections = get(() => props.formDefinition.sections, [])
  const allFields = sections.reduce((accumulator, currentValue) => {
    return accumulator.concat(currentValue.fields)
  }, [])
  const dateFields = allFields.filter((field) => field.type === "date")
  const datetimeFields = allFields.filter((field) => field.type === "datetime-local")
  const readOnly = props.formState && (!props.submit || !!props.update)
  const permission = props && props.applicationState.authentication.user.permission

  const [isDisableForm, setIsDisableForm] = useState(readOnly)
  const [showMergeModal, setShowMergeModal] = useState(false)
  const [submitType, setSubmitType] = useState(null)
  const [isEditForm, setIsEditForm] = useState(false)
  const [othersDisabled, setOthersDisabled] = useState(true)
  const [isMerging, setIsMerging] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const [selectedTestGroup, setSelectedTestGroup] = useState(null)
  const [testTypeOptions, setTestTypeOptions] = useState([])

  const alert = useAlert()

  useEffect(() => {
    console.log("isEditFormX: ", isEditForm)
  }, [isEditForm])

  const [selected, setSelected] = useState(() => {
    const multipleSelectFields = allFields.filter((field) => field.type == "multiple-select")
    const data = {}

    multipleSelectFields.map((field) => {
      if (props.formState) {
        data[field.name] = props.formState[field.name]
      } else {
        data[field.name] = []
      }
    })

    return data
  })
  const [admin, setAdmin] = useState(false)

  const handleCloseModal = () => setShowMergeModal(false)
  const handleShowModal = () => setShowMergeModal(true)
  const [selectedSubjectIds, setSelectedSubjectIds] = useState([])
  const badEmailAddresses = props.applicationState.badEmailAddresses

  const capitalize = (value) => {
    return value && value.replace ? value.replace(/^\w/, (c) => c.toUpperCase()) : value
  }

  const getCustomValidationFields = (sections) => {
    let customValidationFields = []
    for (let s = 0; s < sections.length; s++) {
      const fields = get(() => sections[s].fields, [])
      const validationFields = fields.filter((field) => field.validation instanceof Object)
      if (validationFields.length) {
        customValidationFields = customValidationFields.concat(validationFields)
      }
    }
    return customValidationFields
  }
  const customValidationFields = getCustomValidationFields(sections)

  useEffect(() => {
    const formState = getInitialState()
    setFormState(formState)
    setDataState(formState)
    isAdmin()
  }, [props.formState])

  const isAdmin = () => {
    if (permission === "administrator") {
      setAdmin(true)
    } else {
      setAdmin(false)
    }
  }

  const getInitialState = () => {
    const initialFormState = {}
    for (let s = 0; s < sections.length; s++) {
      const fields = get(() => sections[s].fields, [])
      for (let f = 0; f < fields.length; f++) {
        if (fields[f].defaultValue !== undefined && fields[f].defaultValue !== null) {
          //console.log('default value of ', fields[f].name, 'is', fields[f].defaultValue);
          initialFormState[fields[f].name] = fields[f].defaultValue
        } else if (fields[f].type === "checkbox") {
          initialFormState[fields[f].name] = false
        } else {
          initialFormState[fields[f].name] = null
        }
      }
    }

    //console.log(Object.assign(initialFormState, props.formState));
    return Object.assign(initialFormState, props.formState)
  }

  const [formState, setFormState] = useState(getInitialState())
  const [dataState, setDataState] = useState(getInitialState())
  const [alertState, setAlertState] = useState({})
  const [questionForms, setQuestionForms] = useState([])

  const getColumns = (section) => {
    const fields = get(() => section.fields, [])
    const numberOfFields = fields.length
    //console.log('section ' + section.label + ' field count is ' + numberOfFields);
    const numberOfColumns = get(() => section.columns, 1)
    const maxFieldsPerColumn = Math.ceil(numberOfFields / numberOfColumns)
    //console.log('max fields per column: ' + maxFieldsPerColumn);
    let columnWidth = "6"
    switch (numberOfColumns) {
      case 1:
        columnWidth = "12"
        break
      case 2:
        columnWidth = "6"
        break
      case 3:
        columnWidth = "4"
        break
      default:
        throw new Error("Unhandled column format")
    }

    const columns = []
    for (let fieldIndex = 0; fieldIndex < numberOfFields; fieldIndex++) {
      const columnIndex = Math.floor(fieldIndex / maxFieldsPerColumn)
      //console.log(columnIndex, fieldIndex);
      if (columnIndex >= columns.length) {
        const column = {
          width: columnWidth,
          fields: [],
        }
        columns.push(column)
      }
      columns[columnIndex].fields.push(fields[fieldIndex])
    }

    console.log("columnsX: ", columns)
    return columns
  }

  const getOptions = (field, noEmpty) => {
    const empty = noEmpty
      ? []
      : [
          {
            key: "",
            value: "",
          },
        ]
    if (!field || !field.source || !field.source.kind || !field.source.data) {
      return empty
    }

    if (field.source.kind === "direct") {
      const directResult = empty.concat(field.source.data)
      return directResult
    }

    if (field.source.kind === "lookup") {
      const lookupResult = empty.concat(props.lookups.filter((s) => s.type === field.source.data))
      return lookupResult
    }

    if (field.source.kind === "prop" || field.source.kind === "props") {
      const lookupResult = empty.concat(props[field.source.data])
      return lookupResult
    }

    if (field.source.kind === "request") {
      const data = props.formSource.find((x) => x.name == field.name)?.data
      return empty.concat(
        data
          ?.filter((x) => x.testGroup && x.testGroup == formState.testGroup)
          ?.map((config) => ({
            key: config?.product?.id,
            value: config?.product?.testTypes,
          })),
      )
    }

    return empty
  }

  const getStateForNetest = () => {
    const empty = [
      {
        key: "",
        value: "",
      },
    ]
    if (currentRecord && formState && currentRecord.prefix) {
      const stateName = currentRecord.state
      const lookupResult = props.lookups.filter((s) => s.type === "state" && s.value === stateName)
      const newFormState = {
        ...formState,
        state: lookupResult[0]?.key,
      }
      setFormState(newFormState)
      // return lookupResult;
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    let configName
    const testTypeOptionData = props.lookups.filter((s) => s.type === "testGroup")
    const testGroupData = testTypeOptionData.find((item) => item.key == dataState?.testGroup)
    configName = testGroupData.value

    const errors = {
      sections: [],
      fields: [],
    }

    questionForms.forEach((section, sectionIndex) => {
      const productId = section.productId || configName
      // check for falsy values more concisely
      if (!section.label) {
        errors.sections.push(`${productId}`)
      }

      section.fields.forEach((field, fieldIndex) => {
        if (!field.label) {
          errors.fields.push(`${productId}`)
        }
      })
    })

    if (errors.sections.length || errors.fields.length) {
      displayErrors("sections", errors.sections)
      displayErrors("fields", errors.fields)
      return
    }

    setIsSubmitting(true)
    const verb = "get"
    const uri = "/api/cancer-products"
    const products = await makeApiCall(verb, uri)

    const data = {
      _id: dataState?.testGroup,
      testGroup: dataState?.testGroup,
      configId: dataState?.testGroup || dataState?.testType,
      configName: configName,
      sections: questionForms,
      defaultDefinition: netestRequisitionFormDefinition.sections,
      relevantData: {
        products: products.body || [],
      },
    }

    if (submitType == "submit") {
      if (props.submit) {
        const result = await props.submit(data)
        if (result && result.ok) {
          setIsDisableForm(true)
          // await props.loadCurrentData()
        }

        setTimeout(() => {
          setIsSubmitting(false)
        }, 500)
      }
    }

    if (submitType == "update") {
      if (props.update) {
        const result = await props.submit(data)
        if (result.ok) {
          setIsDisableForm(true)
          setDataState(dataState)
          setFormState(dataState)
          // await props.loadCurrentData()
        }

        setTimeout(() => {
          setIsSubmitting(false)
        }, 500)
      }
    }
  }

  function displayErrors(errorType, errors) {
    if (errors.length) {
      // join errors with a semicolon for better readability
      const errorMessage = `Invalid values in ${errorType}: ${errors.join("; ")}`
      alert.show(errorMessage, { type: "error" })
    }
  }

  const dateValidate = (newDataState) => {
    if (!newDataState) {
      return
    }
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (dateFields.find((dateField) => dateField.name === fieldName) && newDataState[fieldName]) {
          console.log("validating date", newDataState[fieldName])
          const element = elements.current[fieldName]
          let valid = false
          if (newDataState[fieldName] && newDataState[fieldName].length === 10) {
            try {
              const dateTimeString = newDataState[fieldName] + "T00:00:00Z"
              const date = new Date(dateTimeString)
              console.log("date", date)
              valid = date && date instanceof Date && isFinite(date) ? true : false
            } catch {}
          }
          console.log("valid", valid)
          element.setCustomValidity(valid ? "" : "Invalid date, please use this format: " + getDateFormatString())
        }
      }
    }
    console.log("new data state", newDataState)
    return newDataState
  }

  const datetimeValidate = (newDataState) => {
    if (!newDataState) {
      return
    }
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (datetimeFields.find((datetimeField) => datetimeField.name === fieldName) && newDataState[fieldName]) {
          console.log("validating date time", newDataState[fieldName])
          const element = elements.current[fieldName]
          let valid = false
          if (newDataState[fieldName]) {
            try {
              const datetimeString = newDataState[fieldName]
              const datetime = new Date(datetimeString)
              console.log("datetime", datetime)
              valid = datetime && datetime instanceof Date && isFinite(datetime) ? true : false
            } catch {}
          }
          console.log("valid", valid)
          element?.setCustomValidity(valid ? "" : "Invalid datetime value")
        }
      }
    }
    console.log("new data state", newDataState)
    return newDataState
  }

  const customValidate = (newState) => {
    if (customValidationFields.length) {
      //console.log('custom validate');
      for (let i = 0; i < customValidationFields.length; i++) {
        const field = customValidationFields[i]
        //console.log('checking', field.name);
        const rule = get(() => field.validation.rule)
        const message = get(() => field.validation.message)
        if (rule && message) {
          // TODO more general rule parsing
          const or = get(() => rule.$or, [])
          if (!or) {
            console.error("validation rule not supported", rule)
          } else {
            console.log("checking rule", or)
            let valid = or.length === 0 ? true : false
            for (let i = 0; i < or.length; i++) {
              const ruleItem = or[i]
              //console.log('rule item', ruleItem);
              for (let j in ruleItem) {
                if (ruleItem.hasOwnProperty(j)) {
                  //console.log('has own property', j);
                  //console.log('comparing', newState[j], ruleItem[j]);
                  if (ruleItem[j] instanceof Object) {
                    //console.log('is an object expression');
                    //console.log('required', ruleItem[j]);
                    if (ruleItem[j].required === true && newState[j]) {
                      //console.log('truthy', newState[j]);
                      valid = true
                    }
                    if (ruleItem[j].required === false && !newState[j]) {
                      //console.log('falsey', newState[j]);
                      valid = true
                    }
                  } else if (newState[j] === ruleItem[j]) {
                    valid = true
                  }
                }
              }
            }
            //console.log('validity of', field.name, 'is', valid);
            const element = elements.current[field.name]
            element.setCustomValidity(valid ? "" : message)
          }
        } else {
          console.warn("skipping custom validation because of empty rule or message", field.validation)
        }
      }
    }
  }

  const getDateFormatString = () => {
    const formatObj = new Intl.DateTimeFormat(navigator.language).formatToParts(new Date())

    return formatObj
      .map((obj) => {
        switch (obj.type) {
          case "day":
            return "DD"
          case "month":
            return "MM"
          case "year":
            return "YYYY"
          default:
            return obj.value
        }
      })
      .join("")
  }

  const dateValue = (rawValue) => {
    if (!rawValue) {
      return rawValue
    }
    console.log(rawValue)
    const usaDateFormatRegex = /^([\d]{2})\/([\d]{2})\/([\d]{4})$/
    const results = rawValue.match(usaDateFormatRegex)
    if (results) {
      const dateFormatString = getDateFormatString()
      switch (dateFormatString) {
        case "MM/DD/YYYY":
          return `${results[3]}-${results[1]}-${results[2]}`
        case "DD/MM/YYYY":
          return `${results[3]}-${results[2]}-${results[1]}`
        default:
          return rawValue
      }
    }
    return rawValue
  }

  const datetimeValue = (rawValue) => {
    if (!rawValue) {
      return rawValue
    }
    try {
      const value = new Date(rawValue)
      const result = value.toISOString()
      return result
    } catch (e) {
      console.error("Could not parse datetime", e)
    }

    return rawValue
  }

  const dataStateFromFormState = (newFormState) => {
    let newDataState = JSON.parse(JSON.stringify(newFormState))
    for (let fieldName in newDataState) {
      if (newDataState.hasOwnProperty(fieldName)) {
        if (dateFields.find((dateField) => dateField.name === fieldName)) {
          newDataState[fieldName] = dateValue(newDataState[fieldName])
        }
        if (datetimeFields.find((datetimeField) => datetimeField.name === fieldName)) {
          newDataState[fieldName] = datetimeValue(newDataState[fieldName])
        }
        if (newDataState[fieldName] === "true") {
          newDataState[fieldName] = true
        }
        if (newDataState[fieldName] === "false") {
          newDataState[fieldName] = false
        }
        if (newDataState[fieldName] === "null") {
          newDataState[fieldName] = null
        }
      }
    }
    //console.log('new data state', newDataState);
    return newDataState
  }

  const validateEmailChange = useRef(
    debounce(async (e, field) => {
      const email = e.target.value
      const badEmails = props.applicationState.badEmailAddresses
      const invalid = isInvalidEmailAddress(email)
      if (invalid) {
        e.target.className += " invalid-input"
        return false
      } else {
        e.target.classList.remove("invalid-input")
        return true
      }
    }, 300),
  ).current

  const isInvalidEmailAddress = (email) => {
    if (email && badEmailAddresses && badEmailAddresses.length) {
      const matches = matcher([email], badEmailAddresses)
      return matches.length
    }
  }

  const validateDateChange = (e, field) => {
    if (e.target.value) {
      const input = moment(e.target.value)
      const covidStarted = moment("1/1/2020")

      const rules = [
        {
          field: "dateOfBirth",
          value: input.isBetween(moment().subtract(130, "y"), moment()),
          // not in the future, max 130 years in the past.
        },
        {
          field: "sampleCollectionDateTime",
          value: input.isBetween(moment().subtract(7, "d"), moment()),
          // not in the future, max 7 days in the past.
        },
        {
          field: "dateOfSymptomOnset",
          value: input.isBetween(covidStarted, moment()),
          // not in the future, not before 1/1/2020
        },
        {
          field: "vaccinationDate",
          value: input.isBetween(covidStarted, moment()),
          // not in the future, not before 1/1/2020
        },
      ]

      const rule = rules.find((rule) => rule.field == field.name)
      if (rule) {
        const element = elements.current[field.name]
        if (!rule?.value) {
          element.setCustomValidity(`Invalid ${field.label.toLowerCase()}.`)
          return false
        } else {
          element.setCustomValidity("")
          return true
        }
      }
    }
  }

  const validateChange = (e, field) => {
    let valid = true

    // validate email with list of bad emails
    if (field.type === "email") {
      valid = validateEmailChange(e, field)
    }

    if (_.includes(["date", "datetime-local"], field.type)) {
      valid = validateDateChange(e, field)
    }

    return valid
  }

  const handleChange = (e, field) => {
    console.log("e", e)
    console.log("field", field)
    const target = e.target
    const name = target.name
    let newFormState = {}

    console.log("target", target)
    console.log("name", name)
    console.log("field", field)

    // if (field.name == 'testGroup') {
    //     setSelectedTestGroup(target.value)
    // }

    if (field.type === "qrcode") {
      e.preventDefault()
      return
    }

    const value = target.type === "checkbox" ? target.checked : target.type === "number" && isFinite(Number(target.value)) ? Number(target.value) : target.value

    // if (target.type == 'multiple-select') { }
    if (target.type === "radio") {
      if (target.name === "testingPurpose" && value === "purposeOther") {
        console.log("field other")
        setOthersDisabled(false)
      } else {
        console.log("field another")
        setOthersDisabled(true)
      }
    }

    setSelected((prevState) => ({
      ...prevState,
      // 'testType': null
    }))

    newFormState = {
      ...formState,
      [name]: value,
    }

    // if (field.name == 'testGroup') {
    //     newFormState = {
    //         ...formState,
    //         testType: null,
    //         [name]: value
    //     };
    //
    //     setSelected(prevState => ({
    //         ...prevState,
    //         'testType': null
    //     }));
    //
    // } else if (field.name == 'testType') {
    //     newFormState = {
    //         ...formState,
    //         testGroup: null,
    //         [name]: value
    //     };
    //
    //     setSelected(prevState => ({
    //         ...prevState,
    //         'testGroup': null
    //     }));
    // } else {
    //     newFormState = {
    //         ...formState,
    //         [name]: value
    //     };
    // }

    console.log("newFormStateX: ", newFormState)
    setFormState(newFormState)
    props.fieldsOnChange && props.fieldsOnChange(newFormState)
    const newDataState = dataStateFromFormState(newFormState)
    setDataState(newDataState)
    dateValidate(newDataState)
    datetimeValidate(newDataState)
    customValidate(newDataState)
    validateChange(e, field)
    if (props.change) {
      props.change()
    }
  }

  const evaluate = (rule, nullValue) => {
    if (rule === undefined || rule === null) {
      return nullValue || false
    }
    //console.log(rule);
    if (!(rule instanceof Object)) {
      //console.log('rule direct');
      return rule
    }
    for (const property in rule) {
      if (rule.hasOwnProperty(property)) {
        if (rule[property] instanceof Object) {
          if (rule[property].empty === true && formState[property]) {
            console.log("rule object empty false")
            return false
          }
          if (rule[property].empty === false && !formState[property]) {
            console.log("rule object not empty false")
            return false
          }
        } else if (formState[property] !== rule[property]) {
          console.log("rule value false")
          return false
        }
      }
    }
    console.log("rule true")
    return true
  }

  const customLabel = (label) => {
    if (!label) {
      return label
    }
    const regex = /\{(.+?)\}/
    const match = label.match(regex)
    if (!match) {
      return label
    }
    console.log(match)
    const value = props.customFunctions[match[1]](label, formState)
    return value
  }

  const getLabel = (field) => {
    const baseStyle = field.type === "checkbox" ? { marginLeft: "2rem" } : { display: "block" }
    const style = Object.assign(baseStyle, field.labelStyle)
    if (field.labelHtml) {
      const html = customLabel(field.labelHtml)
      return <Label for={field.name} className={field.labelClassName} style={style} dangerouslySetInnerHTML={{ __html: html }} />
    } else {
      const label = customLabel(field.label)
      return (
        <Label for={field.name} className={field.labelClassName} style={style}>
          {(label ? label : "") + (evaluate(field.required, false) ? " *" : "")}
        </Label>
      )
    }
  }

  const download = (field) => {
    makeApiDownloadCall("get", field.format, formState, "application/pdf", formState[field.name] + ".pdf")
  }

  const formatValue = (format, value) => {
    if (value && format === "toLocalizedDateString") {
      if (value === null || value === undefined) {
        return ""
      }
      return new Date(value).toLocaleString()
    }
    if (value && format === "capitalized") {
      return capitalize(value)
    }
    if (format === "boolean") {
      return value === true ? "Yes" : value === false ? "No" : "Unset"
    }

    if (format === "allCaps") {
      return value.toUpperCase()
    }

    return value
  }

  useEffect(() => {
    getStateForNetest()
  }, [currentRecord])

  useEffect(() => {
    console.log("questionFormsX: ", questionForms)
  }, [questionForms])

  return (
    <div>
      {props.isLoading && (
        <center className="mt-5">
          <ReactLoading type="spin" color="red" height={667} width={100} />
        </center>
      )}

      {!props.isLoading && (
        <Form className="GeneralForm" onSubmit={onSubmit} style={props.style}>
          {props.formDefinition.formLabel ? (
            <div>
              <Row className="QR-code">
                <Col>
                  <h2>{props.formDefinition.formLabel}</h2>
                  <h3>{props.id}</h3>
                </Col>
              </Row>
            </div>
          ) : null}
          {sections.map((section, sectionIndex) => {
            console.log("formstate is", formState)
            if (evaluate(section.visible, true)) {
              return (
                <div key={"section-" + sectionIndex}>
                  {section.label ? (
                    <Row className="section-header">
                      <Col>{section.label}</Col>
                    </Row>
                  ) : null}
                  <Row>
                    {section.type === "table" ? (
                      <GeneralTable
                        style={{ marginTop: "30px" }}
                        table={section.table}
                        data={getValueByDotNotation(formState, section.name)}
                        firstRowNumber={1}
                        rowActions={props.formRowActions}
                        currentRecord={currentRecord}
                      />
                    ) : (
                      getColumns(section).map((column, columnIndex) => (
                        <Col sm={column.width} key={"column-" + columnIndex}>
                          {column.fields.map((field) => {
                            if (evaluate(field.visible, true)) {
                              return (
                                <FormGroup key={field.name} style={field.groupStyle}>
                                  {field.type !== "checkbox" && field.type !== "radio" && field.type !== "button" ? (
                                    <Label style={{ display: "block" }} for={field.name}>
                                      {field.label + (field.required ? " *" : "")}{" "}
                                    </Label>
                                  ) : null}
                                  {field.type === "textarea" ? (
                                    <Input
                                      type={field.type}
                                      name={field.name}
                                      id={field.name}
                                      disabled={(isEditForm && field.nonEditable) || isDisableForm}
                                      readOnly={false}
                                      required={field.required}
                                      onChange={(e) => handleChange(e, field)}
                                      style={Object.assign({ display: "block" }, field.style)}
                                      placeholder={field.placeholder}
                                      rows={field.rows}
                                      cols={field.cols}
                                      wrap={field.wrap}
                                      value={formState[field.name] || ""}
                                      maxLength={field.maxlength}
                                    />
                                  ) : null}

                                  {field.type === "timestamp" || (field.type === "datetime-local" && evaluate(field.readonly, false) === true) ? (
                                    <Input
                                      type={"text"}
                                      name={field.name}
                                      id={field.name}
                                      value={formatValue(field.format, formState[field.name]) || ""}
                                      checked={field.type === "checkbox" ? formState[field.name] || false : null}
                                      disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}
                                      readOnly={true} //evaluate(field.readonly, false)}
                                      required={evaluate(field.required, false)}
                                      pattern={field.pattern}
                                      title={field.title}
                                      //onChange={(e) => handleChange(e, field)}
                                      style={field.style}
                                      placeholder={field.placeholder}
                                      className={field.className}
                                      max={field.max}
                                      min={field.min}
                                      maxLength={field.maxlength}
                                    ></Input>
                                  ) : null}

                                  {/*{field.type == 'multiple-select' && <div*/}
                                  {/*  style={field.type === 'multiple-select' ? Object.assign({ marginLeft: 0 }, field.style) : field.style}>*/}
                                  {/*    <MultiSelect*/}
                                  {/*      disableSearch*/}
                                  {/*      defaultIsOpen={true}*/}
                                  {/*      overrideStrings={{*/}
                                  {/*          allItemsAreSelected: selected ? _.join(_.map(selected[field.name], 'label'), ', '): []*/}
                                  {/*      }}*/}
                                  {/*      options={() => {*/}
                                  {/*          console.log('helloooooox');*/}
                                  {/*          console.log('field.nameX: ', field.name);*/}
                                  {/*          let testTypeOption;*/}
                                  {/*          if (field.name == 'testType') {*/}
                                  {/*              const testTypeOptionData = props.formSource.find(x => x.name == 'testType').data;*/}
                                  {/*              if (formState['testGroup']) {*/}
                                  {/*                  testTypeOption = testTypeOptionData.filter(item => item.testGroup ==  formState['testGroup'])*/}
                                  {/*              } else {*/}
                                  {/*                  testTypeOption = testTypeOptionData;*/}
                                  {/*              }*/}

                                  {/*              testTypeOption = testTypeOption.map(config => ({*/}
                                  {/*                  key: config.id,*/}
                                  {/*                  value: config.testType*/}
                                  {/*              }))*/}
                                  {/*          }*/}
                                  {/*          */}
                                  {/*          */}
                                  {/*          const options = field.name == 'testType' ? testTypeOption: getOptions(field);*/}
                                  {/*          console.log('optionsX: ', options);*/}
                                  {/*          const customOptions = []*/}
                                  {/*          options.map(option => {*/}
                                  {/*              if (option.key && option.value) {*/}
                                  {/*                  customOptions.push({*/}
                                  {/*                      label: option.value, value: option.key,*/}
                                  {/*                  });*/}
                                  {/*              }*/}
                                  {/*          });*/}
                                  {/*          */}
                                  {/*          console.log('customOptionsX: ', customOptions);*/}

                                  {/*          return customOptions;*/}
                                  {/*      }}*/}
                                  {/*      value={selected[field.name] ?? []}*/}
                                  {/*      disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}*/}
                                  {/*      onChange={( e ) => {*/}
                                  {/*          setSelected(prevState => ({*/}
                                  {/*              ...prevState, [field.name]: e*/}
                                  {/*          }))*/}
                                  {/*          e = {*/}
                                  {/*              target: {*/}
                                  {/*                  value: e, type: 'multiple-select', name: field.name,*/}
                                  {/*              }*/}
                                  {/*          }*/}
                                  {/*          handleChange(e, field)*/}
                                  {/*      }}*/}
                                  {/*      labelledBy="Select"*/}
                                  {/*      hasSelectAll={false}*/}
                                  {/*    />*/}
                                  {/*</div>}*/}

                                  {field.type === "text" && field.name === "purposeOtherDescription" ? (
                                    <Input
                                      className={"text"}
                                      type={field.type}
                                      name={field.name}
                                      id={field.name}
                                      value={formState[field.name]}
                                      disabled={othersDisabled}
                                      required={field.required}
                                      pattern={field.pattern}
                                      title={field.title}
                                      onChange={(e) => {
                                        e.persist()
                                        handleChange(e, field)
                                      }}
                                      innerRef={(element) => (elements.current[field.name] = element)}
                                      style={field.style}
                                      placeholder={field.placeholder}
                                      maxLength={field.maxlength}
                                    />
                                  ) : null}

                                  {/*{field.type === 'input-dropdown' ?*/}
                                  {/*  <div className="input-group" style={field.style}>*/}
                                  {/*      <Input*/}
                                  {/*        type={'text'}*/}
                                  {/*        name={field.name}*/}
                                  {/*        id={field.name}*/}
                                  {/*        value={formatValue(field.format, formState[field.name]) || ''}*/}
                                  {/*        checked={field.type === 'checkbox' ? formState[field.name] || false : null}*/}
                                  {/*        disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}*/}
                                  {/*        readOnly={evaluate(field.readonly, false)}*/}
                                  {/*        required={evaluate(field.required, false)}*/}
                                  {/*        pattern={field.pattern}*/}
                                  {/*        title={field.title}*/}
                                  {/*        onChange={( e ) => handleChange(e, field)}*/}
                                  {/*        style={field.style}*/}
                                  {/*        placeholder={field.placeholder}*/}
                                  {/*        className={field.className}*/}
                                  {/*        max={field.max}*/}
                                  {/*        min={field.min}*/}
                                  {/*        maxLength={field.maxlength}*/}
                                  {/*      />*/}
                                  {/*      <div className="input-group-append">*/}
                                  {/*          <UncontrolledDropdown inNavbar>*/}
                                  {/*              <DropdownToggle outline={true} split={true} caret*/}
                                  {/*                              disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}>*/}
                                  {/*              </DropdownToggle>*/}
                                  {/*              <DropdownMenu>*/}
                                  {/*                  {getOptions(field).map(item => <DropdownItem*/}
                                  {/*                    name={field.name}*/}
                                  {/*                    onClick={( e ) => handleChange(e, field)}*/}
                                  {/*                    key={item.key}*/}
                                  {/*                    value={item.value}>{item.value || item.key}</DropdownItem>)}*/}
                                  {/*              </DropdownMenu>*/}
                                  {/*          </UncontrolledDropdown>*/}
                                  {/*      </div>*/}
                                  {/*  </div>*/}

                                  {/*  : null}*/}

                                  {field.type !== "input-dropdown" &&
                                  field.type !== "multiple-select" &&
                                  field.type !== "textarea" &&
                                  field.type !== "label" &&
                                  field.type !== "radio" &&
                                  field.type !== "timestamp" &&
                                  field.type !== "button" &&
                                  (field.type !== "datetime-local" || (field.type === "datetime-local" && evaluate(field.readonly, false) === false)) &&
                                  field.name !== "purposeOtherDescription" ? (
                                    <span>
                                      <Input
                                        className={field.type === "email" && isInvalidEmailAddress(formState[field.name]) ? "invalid-input" : ""}
                                        type={field.type}
                                        name={field.name}
                                        id={field.name}
                                        value={
                                          field.type === "checkbox"
                                            ? ""
                                            : formState[field.name] === null || formState[field.name] === undefined
                                            ? ""
                                            : field.format != "toLocalizedDateString"
                                            ? formatValue(field.format, formState[field.name])
                                            : moment(formState[field.name]).format("YYYY-MM-DDTHH:mm") ?? ""
                                        }
                                        checked={field.type === "checkbox" ? formState[field.name] : false}
                                        disabled={
                                          (isEditForm && field.nonEditable) || field.readonly || isDisableForm || (props.formState && field.name === "id")
                                        }
                                        required={field.required}
                                        pattern={field.pattern}
                                        title={field.title}
                                        onChange={(e) => {
                                          e.persist()
                                          handleChange(e, field)
                                        }}
                                        max={field.max}
                                        min={field.min}
                                        innerRef={(element) => (elements.current[field.name] = element)}
                                        style={field.type === "checkbox" ? Object.assign({ marginLeft: 0 }, field.style) : field.style}
                                        placeholder={field.placeholder}
                                        maxLength={field.maxlength}
                                      >
                                        {field.type === "select"
                                          ? getOptions(field).map((item) => (
                                              <option key={item?.key} value={item?.key}>
                                                {item?.value || item?.key}
                                              </option>
                                            ))
                                          : null}
                                      </Input>
                                      {field.notes && <span className="small">{field.notes}</span>}
                                    </span>
                                  ) : null}
                                  {/*{field.type === 'checkbox' ? <Label for={field.name}*/}
                                  {/*                                    disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}*/}
                                  {/*                                    style={{ marginLeft: '2rem' }}>{field.label + (field.required ? ' *' : '')}</Label> : null}*/}
                                  {/*{field.type === 'radio' ? getOptions(field).map(item => item.key !== '' ?*/}
                                  {/*  <span key={item.key}*/}
                                  {/*        style={field.itemStyle && field.itemStyle.span}>*/}
                                  {/*                          <Input type="radio"*/}
                                  {/*                                 name={field.name}*/}
                                  {/*                                 id={field.name + '-' + item.key}*/}
                                  {/*                                 value={item.key}*/}
                                  {/*                                 checked={item.key === formState[field.name]}*/}
                                  {/*                                 disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}*/}
                                  {/*                                 readOnly={true}*/}
                                  {/*                                 required={evaluate(field.required, false)}*/}
                                  {/*                                 onChange={( e ) => handleChange(e, field)}*/}
                                  {/*                                 style={Object.assign(Object.assign({*/}
                                  {/*                                     marginLeft: '10px', marginTop: '5px'*/}
                                  {/*                                 }, field.itemStyle || {}), field.itemStyle.radio || {})}*/}
                                  {/*                                 className={field.className}*/}
                                  {/*                          />*/}
                                  {/*                          <label htmlFor={field.name + ' ' + item.key}*/}
                                  {/*                                 style={field.itemStyle && field.itemStyle.label}>{item.value || item.key}</label>*/}
                                  {/*                                  <br/>*/}
                                  {/*                      </span> : null) : null}*/}
                                  {field.type === "button" ? (
                                    <Button outline color="secondary" type="button" onClick={() => download(field)}>
                                      {getLabel(field)}
                                    </Button>
                                  ) : null}
                                </FormGroup>
                              )
                            }
                          })}
                        </Col>
                      ))
                    )}
                  </Row>
                </div>
              )
            }
          })}

          <div>
            <Row>
              <RequisitionsConfigurationQuestionsForm
                currentData={currentRecord}
                formState={formState}
                isEditForm={props.formState ? isEditForm : true}
                isDisableForm={isDisableForm}
                setQuestionForms={setQuestionForms}
                questionForms={questionForms}
              />
            </Row>
          </div>

          <div className={"general-form-buttons"}>
            <Row>
              <Col sm="12">
                <div className="float-right">
                  {props.submit && !props.formState && !isDisableForm ? (
                    <Button
                      type="submit"
                      outline
                      color="primary"
                      onClick={(e) => {
                        if (!isSubmitting) {
                          setSubmitType("submit")
                        }
                      }}
                      style={Object.assign(
                        { marginRight: "10px" },
                        get(() => props.formDefinition.submit.style, {}),
                      )}
                      disabled={evaluate(props.formDefinition.submit.disabled, false)}
                    >
                      {isSubmitting ? <ReactLoading type="spin" color="white" height={25} width={20} /> : "Submit"}
                    </Button>
                  ) : null}

                  {!!props.update && props.formState && !isDisableForm ? (
                    <Button
                      type="submit"
                      outline
                      color="primary"
                      onClick={(e) => {
                        if (!isSubmitting) {
                          setSubmitType("update")
                        }
                      }}
                      style={Object.assign(
                        { marginRight: "10px" },
                        get(() => props.formDefinition.update.style, {}),
                      )}
                      disabled={evaluate(props.formDefinition.update.disabled, false)}
                    >
                      {isSubmitting ? <ReactLoading type="spin" color="white" height={25} width={20} /> : "Update"}
                    </Button>
                  ) : null}
                  {props.formDefinition.edit && props.formState && isDisableForm && !formState.taps && (
                    <Button
                      type="submit"
                      outline
                      color="primary"
                      onClick={() => {
                        setIsDisableForm(false)
                        setIsEditForm(true)
                      }}
                      style={Object.assign(
                        { marginRight: "10px" },
                        get(() => props.formDefinition.edit.style, {}),
                      )}
                      disabled={evaluate(props.formDefinition.edit.disabled, false)}
                    >
                      {get(() => props.formDefinition.edit.label, "Edit")}
                    </Button>
                  )}
                  {props.cancel ? (
                    <Button
                      type="button"
                      outline
                      color="secondary"
                      style={get(() => props.formDefinition.cancel.style)}
                      onClick={() => {
                        if (props.cancel) {
                          props.cancel()
                        }
                      }}
                    >
                      {get(() => props.formDefinition.cancel.label, "Cancel")}
                    </Button>
                  ) : null}
                  {props.findDuplicate && !formState.taps && admin ? (
                    <Button
                      type="button"
                      outline
                      color="primary"
                      style={get(() => props.formDefinition.findDuplicate.style)}
                      onClick={() => {
                        if (props.findDuplicate) {
                          props.findDuplicate(formState)
                        }
                      }}
                    >
                      {get(() => props.formDefinition.findDuplicate.label, "Find Duplicate")}
                    </Button>
                  ) : null}
                  {!props.cancel && (
                    <Button
                      type="button"
                      outline
                      color="primary"
                      style={get(() => props.formDefinition.close.style)}
                      onClick={() => {
                        if (props.close) {
                          props.close()
                        }

                        if (props.setExistingSubjects) {
                          props.setExistingSubjects([])
                        }
                      }}
                    >
                      {get(() => props.formDefinition.close.label, "Close")}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </div>
  )
}

GeneralFormRequisitionsConfigurationNew.propTypes = {
  formDefinition: PropTypes.object.isRequired,
  lookups: PropTypes.array.isRequired,
  formState: PropTypes.object,
  submit: PropTypes.func,
  cancel: PropTypes.func,
  change: PropTypes.func,
}

export default GeneralFormRequisitionsConfigurationNew
