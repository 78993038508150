import React, { useMemo, useState, useEffect, useCallback } from "react"
import { useInsuredQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"

dayjs.extend(utc)

export function InsuredTable({ pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector,
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: insuredData,
    isLoading,
    isFetching,
    refetch,
    error,
  } = useInsuredQuery({
    variables: queryVariables,
  })

  const columns = useMemo(
    () => [
      {
        Header: "Patient Id",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Patient Last Name",
        accessor: "lastName",
        hasSort: true,
      },
      {
        Header: "Patient First Name",
        accessor: "firstName",
        hasSort: true,
      },
      {
        Header: "Patient Middle Initial",
        accessor: "middleInitial",
        hasSort: true,
      },
      {
        Header: "Sample Id",
        accessor: "testId",
        hasSort: true,
      },
      {
        Header: "Physician Name",
        accessor: "physicianFullName",
        hasSort: true,
      },
      {
        Header: "Tube Type",
        accessor: "tubeType",
        hasSort: true,
      },
      {
        Header: "Test Type",
        accessor: "testType",
        hasSort: true,
      },
      {
        Header: "Collection Method",
        accessor: "collectionMethod",
        hasSort: true,
      },
      {
        Header: "Received Sample Date",
        accessor: "laboratoryReceivedDateTime",
        hasSort: true,
      },
      {
        Header: "Insurance Company",
        accessor: "insuranceCompanyName",
        hasSort: true,
      },
      {
        Header: "Insurance Group",
        accessor: "insuranceGroupId",
        hasSort: true,
      },
      {
        Header: "Insurance Membership #",
        accessor: "insuranceSubscriberNo",
        hasSort: true,
      },
      {
        Header: "Insurance Reported Date",
        accessor: "insuranceReportedDate",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const getProcessedTableData = useCallback(
    (_insuredData) =>
      (_insuredData &&
        _insuredData.map((item) => ({
          subjectId: (
            <Link to={`/subject-info/${item.subjectId || ""}/personal`} className="text-secondary" target="_blank">
              {item.subjectId}
            </Link>
          ),
          csvSubjectId: item.subjectId,
          lastName: item?.lastName,
          firstName: item?.firstName,
          middleInitial: item?.middleInitial,
          testId: item?.testId,
          physicianFullName: item?.physicianFullName,
          tubeType: item?.tubeType,
          testType: item?.testType,
          collectionMethod: item?.collectionMethod,
          laboratoryReceivedDateTime: item?.laboratoryReceivedDateTime && dayjs(item?.laboratoryReceivedDateTime).local().format("MM/DD/YYYY"),
          insuranceCompanyName: item?.insuranceCompanyName,
          insuranceGroupId: item?.insuranceGroupId,
          insuranceSubscriberNo: item?.insuranceSubscriberNo,
          insuranceReportedDate: item?.insuranceReportedDate && dayjs(item?.insuranceReportedDate).local().format("MM/DD/YYYY"),
          action: (
            <Link to={`/insured-view/${item?.testId || ""}`} className="btn btn-outline-primary">
              View
            </Link>
          ),
        }))) ||
      [],
    [],
  )

  const data = useMemo(() => getProcessedTableData(insuredData), [insuredData, getProcessedTableData])

  const filters = useMemo(
    () => [
      {
        sectionTitle: "Received Sample Date",
        field: "laboratoryReceivedDateTime",
        type: "date",
        operator: "$gte",
        label: "Start Date",
        placeholder: "Select Date Range",
      },
      {
        sectionTitle: " ",
        field: "laboratoryReceivedDateTime",
        type: "date",
        operator: "$lte",
        label: "End Date",
        placeholder: "Select Date Range",
      },
    ],
    [],
  )

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        defaultSort="laboratoryReceivedDateTime"
        defaultSortDir="desc"
        downloadFileName="Insured"
        csvQuery={useInsuredQuery}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
    </div>
  )
}
