import { axiosClient, useRequestProcessor } from "api/provider"

export function useLogin(options = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("login", (login) => axiosClient.post("/auth/login", login).then((res) => res.data), { ...options })
}

export function useLogout(options = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("logout", (logout) => axiosClient.post("/auth/logout", logout).then((res) => res.data), { ...options })
}

export function useRegister(options = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("register", (register) => axiosClient.post("/auth/register", register).then((res) => res.data), { ...options })
}

export function useUpdateSubject(options = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("updateSubject", (update) => axiosClient.put("/subject", update).then((res) => res.data), { ...options })
}

export function useForgot(options = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("forgot", (forgot) => axiosClient.post("/auth/forgot", forgot).then((res) => res.data), { ...options })
}

export function useReset(options = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("Reset", (reset) => axiosClient.post("/auth/reset", reset).then((res) => res.data), { ...options })
}
