import templateFormDefinition from "./template-form-definition";

const templateFullDefinition = {
    key: 'id',
    table: {
        key: 'id',
        columns: [
            {
                label: '#',
                type: 'rowNumber',
                filterType: 'none',
                sortType: 'none'
            },
            {
                label: 'File Name',
                field: 'id',
                type: 'text',
                filterType: 'equals',
                sortType: 'none',
            },
            {
                label: 'Type',
                field: 'templateType',
                type: 'text',
                filterType: 'equals',
                sortType: 'none',
            },
            {
                label: 'Test Group',
                field: 'testGroup',
                type: 'text',
                filterType: 'equals',
                sortType: 'none',
            },
            {
                label: 'Test Types',
                field: 'testTypes',
                type: 'text',
                filterType: 'equals',
                sortType: 'none',
            },
            {
                label: 'Created At',
                field: 'dateCreated',
                type: 'timestamp',
                format: 'toLocalizedDateString',
                // sortType: 'none',
            },
          
           
        ],
        defaultSort : [
            { dateCreated : 'asc' }
        ]
    },

    form: templateFormDefinition,
    // innerForm: pcrInnerFormDefinition,

    endpoints : {
        search: {
            uri: '/api/dir/templates?',
            verb: 'get'
        },
        download: {
            uri: '/api/document/template/:id',
            verb: 'get',
            type: 'application/pdf',
            filename: ':id'
        }
    },
    pageSize: 10
};

export default templateFullDefinition;
