import React, { useEffect, useState } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const AuthenticationAppVerify = ({ code, setCode, handleChange, handleKeyDown, inputRefs, verified, setVerified }) => {
	const [showAlert, setShowAlert] = useState(false);
	const navigate = useNavigate();
	
	useEffect(() => {
		if (verified === false) {
			setShowAlert(true);
		}
	}, [verified]);
	
	const handleAlertClose = () => {
		setShowAlert(false);
		setVerified(null);
		setCode(new Array(6).fill(''));
	};
	
	const handleInputChange = (e, idx) => {
		handleChange(e, idx);
		if (verified === false) {
			setShowAlert(false);
			setVerified(null);
		}
	};
	
	const handlePaste = (e) => {
		e.preventDefault();
		const paste = e.clipboardData.getData('text');
		if (/^\d{6}$/.test(paste)) {
			const newCode = paste.split('').map(Number);
			setCode(newCode);
			inputRefs.current.forEach((input, index) => {
				input.value = newCode[index];
			});
			setVerified(null); // Trigger verification process after setting the code
		}
	};
	
	return (
		<div>
			<h5 className="mb-3">Authentication App</h5>
			<p style={{ fontSize: '0.9rem', color: '#6c757d' }}>
				Enter the 6 digit code generated by your authentication app.
			</p>
			<Form>
				<div className="d-flex justify-content-center mb-3" onPaste={handlePaste}>
					{code.map((digit, idx) => (
						<div key={idx} style={{ padding: '0 5px' }}>
							<Form.Control
								type="text"
								value={digit}
								onChange={(e) => handleInputChange(e, idx)}
								onKeyDown={(e) => handleKeyDown(e, idx)}
								ref={(el) => (inputRefs.current[idx] = el)}
								maxLength="1"
								className="text-center form-control"
								style={{ fontSize: '1.5rem', width: '3rem', height: '3rem', borderColor: '#ced4da', backgroundColor: '#f8f9fa' }}
							/>
						</div>
					))}
				</div>
			</Form>
			<div className="d-flex justify-content-center">
				<button
					type="button"
					className="btn btn-link"
					style={{ padding: '0', color: 'red', textDecoration: 'none', cursor: 'pointer' }}
					onClick={() => navigate('/logout')}
				>
					Cancel
				</button>
			</div>
		</div>
	);
};

export default AuthenticationAppVerify;
