const lookups = [
    {
        _id: 'race-1002-5',
        type: 'race',
        key: '1002-5',
        value: 'American Indian or Alaska Native'
    },
    {
        _id: 'race-2028-9',
        type: 'race',
        key: '2028-9',
        value: 'Asian'
    },
    {
        _id: 'race-2054-5',
        type: 'race',
        key: '2054-5',
        value: 'Black or African American'
    },
    {
        _id: 'race-2076-8',
        type: 'race',
        key: '2076-8',
        value: 'Native Hawaiian or Other Pacific Islander'
    },
    {
        _id: 'race-2106-3',
        type: 'race',
        key: '2106-3',
        value: 'White'
    },
    {
        _id: 'race-2131-1',
        type: 'race',
        key: '2131-1',
        value: 'Other Race'
    },
    {
        _id: 'race-UNK',
        type: 'race',
        key: 'UNK',
        value: 'Unknown'
    },
    /*{
        _id: 'race-PHC1175',
        type: 'race',
        key: 'PHC1175',
        value: 'Refused'
    },*/
    {
        _id: 'ethnicity-CTEDSS-Yes',
        type: 'ethnicity',
        key: 'Yes',
        value: 'Hispanic or Latino'
    },
    {
        _id: 'ethnicity-CTEDSS-No',
        type: 'ethnicity',
        key: 'No',
        value: 'Not Hispanic or not Latino'
    },
    {
        _id: 'ethnicity-CTEDSS-Unkn',
        type: 'ethnicity',
        key: 'Unkn',
        value: 'Unknown'
    },
/*    {
        _id: 'ethnicity-CTEDSS-PHC1367',
        type: 'ethnicity',
        key: 'PHC1367',
        value: 'Refused'
    },*/
    {
        _id: 'gender-male',
        type: 'gender',
        key: 'male',
        value: 'Male'
    },
    {
        _id: 'gender-female',
        type: 'gender',
        key: 'female',
        value: 'Female'
    },
    {
        _id: 'gender-non-binary',
        type: 'gender',
        key: 'non-binary',
        value: 'Non-Binary'
    },
    {_id: 'state-AL', type: 'state', key: 'AL', value: 'Alabama'},
    {_id: 'state-AK', type: 'state', key: 'AK', value: 'Alaska'},
    {_id: 'state-AZ', type: 'state', key: 'AZ', value: 'Arizona'},
    {_id: 'state-AR', type: 'state', key: 'AR', value: 'Arkansas'},
    {_id: 'state-CA', type: 'state', key: 'CA', value: 'California'},
    {_id: 'state-CO', type: 'state', key: 'CO', value: 'Colorado'},
    {_id: 'state-CT', type: 'state', key: 'CT', value: 'Connecticut'},
    {_id: 'state-DE', type: 'state', key: 'DE', value: 'Delaware'},
    {_id: 'state-DC', type: 'state', key: 'DC', value: 'District of Columbia'},
    {_id: 'state-FL', type: 'state', key: 'FL', value: 'Florida'},
    {_id: 'state-GA', type: 'state', key: 'GA', value: 'Georgia'},
    {_id: 'state-HI', type: 'state', key: 'HI', value: 'Hawaii'},
    {_id: 'state-ID', type: 'state', key: 'ID', value: 'Idaho'},
    {_id: 'state-IL', type: 'state', key: 'IL', value: 'Illinois'},
    {_id: 'state-IN', type: 'state', key: 'IN', value: 'Indiana'},
    {_id: 'state-IA', type: 'state', key: 'IA', value: 'Iowa'},
    {_id: 'state-KS', type: 'state', key: 'KS', value: 'Kansas'},
    {_id: 'state-KY', type: 'state', key: 'KY', value: 'Kentucky'},
    {_id: 'state-LA', type: 'state', key: 'LA', value: 'Louisiana'},
    {_id: 'state-ME', type: 'state', key: 'ME', value: 'Maine'},
    {_id: 'state-MD', type: 'state', key: 'MD', value: 'Maryland'},
    {_id: 'state-MA', type: 'state', key: 'MA', value: 'Massachusetts'},
    {_id: 'state-MI', type: 'state', key: 'MI', value: 'Michigan'},
    {_id: 'state-MN', type: 'state', key: 'MN', value: 'Minnesota'},
    {_id: 'state-MS', type: 'state', key: 'MS', value: 'Mississippi'},
    {_id: 'state-MO', type: 'state', key: 'MO', value: 'Missouri'},
    {_id: 'state-MT', type: 'state', key: 'MT', value: 'Montana'},
    {_id: 'state-NE', type: 'state', key: 'NE', value: 'Nebraska'},
    {_id: 'state-NV', type: 'state', key: 'NV', value: 'Nevada'},
    {_id: 'state-NH', type: 'state', key: 'NH', value: 'New Hampshire'},
    {_id: 'state-NJ', type: 'state', key: 'NJ', value: 'New Jersey'},
    {_id: 'state-NM', type: 'state', key: 'NM', value: 'New Mexico'},
    {_id: 'state-NY', type: 'state', key: 'NY', value: 'New York'},
    {_id: 'state-NC', type: 'state', key: 'NC', value: 'North Carolina'},
    {_id: 'state-ND', type: 'state', key: 'ND', value: 'North Dakota'},
    {_id: 'state-OH', type: 'state', key: 'OH', value: 'Ohio'},
    {_id: 'state-OK', type: 'state', key: 'OK', value: 'Oklahoma'},
    {_id: 'state-OR', type: 'state', key: 'OR', value: 'Oregon'},
    {_id: 'state-PA', type: 'state', key: 'PA', value: 'Pennsylvania'},
    {_id: 'state-RI', type: 'state', key: 'RI', value: 'Rhode Island'},
    {_id: 'state-SC', type: 'state', key: 'SC', value: 'South Carolina'},
    {_id: 'state-SD', type: 'state', key: 'SD', value: 'South Dakota'},
    {_id: 'state-TN', type: 'state', key: 'TN', value: 'Tennessee'},
    {_id: 'state-TX', type: 'state', key: 'TX', value: 'Texas'},
    {_id: 'state-UT', type: 'state', key: 'UT', value: 'Utah'},
    {_id: 'state-VT', type: 'state', key: 'VT', value: 'Vermont'},
    {_id: 'state-VA', type: 'state', key: 'VA', value: 'Virginia'},
    {_id: 'state-WA', type: 'state', key: 'WA', value: 'Washington'},
    {_id: 'state-WV', type: 'state', key: 'WV', value: 'West Virginia'},
    {_id: 'state-WI', type: 'state', key: 'WI', value: 'Wisconsin'},
    {_id: 'state-WY', type: 'state', key: 'WY', value: 'Wyoming'},
    {
        _id: 'vaccine-pfizer',
        type: 'vaccine',
        key: 'Pfizer',
        value: 'Pfizer'
    },
    {
        _id: 'vaccine-moderna',
        type: 'vaccine',
        key: 'Moderna',
        value: 'Moderna'
    },
    {
        _id: 'vaccine-other',
        type: 'vaccine',
        key: 'Other',
        value: 'Other'
    },
    {_id: 'relationship-self', type: 'relationship', key: 'self', value: 'Self'},
    {_id: 'relationship-child', type: 'relationship', key: 'child', value: 'Child'},
    {_id: 'relationship-spouse', type: 'relationship', key: 'spouse', value: 'Spouse'},
    {_id: 'relationship-other', type: 'relationship', key: 'other', value: 'Other'},
    // {_id: 'billto-insurance', type: 'billTo', key: 'insurance', value: 'Insurance'},
    {_id: 'billto-patient', type: 'billTo', key: 'personal', value: 'Patient'},
    {_id: 'billto-client', type: 'billTo', key: 'client', value: 'Client\s Office'},
    {_id: 'netest-gender-male', type: 'netest-gender', key: 'male', value: 'Male'},
    {_id: 'netest-gender-female', type: 'netest-gender', key: 'female', value: 'Female'},
    {_id: 'netest-gender-other', type: 'netest-gender', key: 'other', value: 'Other'},
    
    {_id: 'netest-status-accepted', type: 'netest-status', key: 'accepted', value: 'Accepted'},
    {_id: 'netest-status-rejected', type: 'netest-status', key: 'rejected', value: 'Rejected'},

    {
        _id: 'card-master',
        type: 'creditCardType',
        key: 'master',
        value: 'Master Card'
    },
    {
        _id: 'card-visa',
        type: 'creditCardType',
        key: 'visa',
        value: 'Visa'
    },
    {
        _id: 'card-discover',
        type: 'creditCardType',
        key: 'discover',
        value: 'Discover'
    },
    {
        _id: 'card-amex',
        type: 'creditCardType',
        key: 'amex',
        value: 'AMEX'
    },
  
    {
        _id: 'insured-yes',
        type: 'insured',
        key: true,
        value: 'Yes'
    },
    {
        _id: 'insured-no',
        type: 'insured',
        key: false,
        value: 'No'
    },
    {
        _id: 'personal',
        type: 'payerTypeSubForm',
        key: 'personal',
        value: 'Personal'
    },
    {
        _id: 'client',
        type: 'payerTypeSubForm',
        key: 'client',
        value: 'Client'
    },
    
    {
        _id: 'patient',
        type: 'payerType',
        key: 'personal',
        value: 'Personal'
    },
    {
        _id: 'insurance',
        type: 'payerType',
        key: 'insurance',
        value: 'Insurance'
    },
    {
        _id: 'client',
        type: 'payerType',
        key: 'client',
        value: 'Client'
    },
    {
        _id: 'isolationStart',
        type: 'isolationStatus',
        key: 'isolationStart',
        value: 'Isolation Start'
    },
    {
        _id: 'isolationStop',
        type: 'isolationStatus',
        key: 'isolationStop',
        value: 'Isolation Stop'
    },
    {
        _id: 'accepted',
        type: 'isolationStatus',
        key: 'accepted',
        value: 'Accepted'
    },
    {
        _id: 'rejected',
        type: 'isolationStatus',
        key: 'rejected',
        value: 'Rejected'
    },
    {
        _id: 'storage',
        type: 'isolationStatus',
        key: 'storage',
        value: 'Storage'
    },
    {
        _id: 'cDnaStart',
        type: 'cDnaStatus',
        key: 'cDnaStart',
        value: 'cDNA Start'
    },
    {
        _id: 'cDnaStop',
        type: 'cDnaStatus',
        key: 'cDnaStop',
        value: 'cDNA Stop'
    },
    {
        _id: 'accepted',
        type: 'cDnaStatus',
        key: 'accepted',
        value: 'Accepted'
    },
    {
        _id: 'rejected',
        type: 'cDnaStatus',
        key: 'rejected',
        value: 'Rejected'
    },
    {
        _id: 'storage',
        type: 'cDnaStatus',
        key: 'storage',
        value: 'Storage'
    },

    {_id: 'print-status-queued', type: 'print-status', key: 'queued', value: 'Queued'},
    {_id: 'print-status-done', type: 'print-status', key: 'done', value: 'Done'},

    {
        _id: 'cancer',
        type: 'tubeType',
        key: 'cancer',
        value: 'cancer'
    },
    {
        _id: 'covid',
        type: 'tubeType',
        key: 'covid',
        value: 'covid'
    },
    {
        _id: 'raw',
        type: 'tubeType',
        key: 'raw',
        value: 'raw'
    },

    {
        _id: 'test-covid',
        type: 'testGroup',
        key: 'covid',
        value: 'Wren COVID'
    },
    {
        _id: 'test-tfcovid',
        type: 'testGroup',
        key: 'tfcovid',
        value: 'ThermoFisher COVID'
    },
    {
        _id: 'test-cancer',
        type: 'testGroup',
        key: 'cancer',
        value: 'Wren Cancer'
    },
    
    {
        _id: 'product-covid',
        type: 'productGroup',
        key: 'covid',
        value: 'COVID'
    },
    {
        _id: 'test-cancer',
        type: 'productGroup',
        key: 'cancer',
        value: 'Cancer'
    },
    
    {
        _id: 'document',
        type: 'documentFor',
        key: 'document',
        value: 'Document'
    },
    
    {
        _id: 'patientEmail',
        type: 'documentFor',
        key: 'patientEmail',
        value: 'Patient - Email'
    },

    {
        _id: 'otherEmail',
        type: 'documentFor',
        key: 'otherEmail',
        value: 'Other - Email'
    },

    {
        _id: 'patientLetter',
        type: 'documentFor',
        key: 'patientLetter',
        value: 'Patient - Letter'
    },
    
    {
        _id: 'physicianEmail',
        type: 'documentFor',
        key: 'physicianEmail',
        value: 'Physician - Email'
    },
    
    {
        _id: 'physicianLetter',
        type: 'documentFor',
        key: 'physicianLetter',
        value: 'Physician - Letter'
    },
    {
        _id: 'active',
        type: 'productStatus',
        key: 'active',
        value: 'Active'
    },
    {
        _id: 'inactive',
        type: 'productStatus',
        key: 'inactive',
        value: 'Inactive'
    },

    {
        _id: 'equalTo',
        type: 'ruleOperator',
        key: '===',
        value: 'Equal to ='
    },
    {
        _id: 'notEqualTo',
        type: 'ruleOperator',
        key: '!==',
        value: 'Not equal to ≠'
    },
    {
        _id: 'greaterThan',
        type: 'ruleOperator',
        key: '>',
        value: 'Greater than >'
    },
    {
        _id: 'greaterThanOrEqualTo',
        type: 'ruleOperator',
        key: '>=',
        value: 'Greater than or equal to ≥'
    },
    {
        _id: 'lessThan',
        type: 'ruleOperator',
        key: '<',
        value: 'Less Than <'
    },
    {
        _id: 'lessThanOrEqualTo',
        type: 'ruleOperator',
        key: '<=',
        value: 'Less than or equal to ≤'
    }
];

export default lookups;
