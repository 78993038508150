import React from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { usePreviousTableQuery } from "hooks/query"
import Breadcrumb from "./custom/Breadcrumb"
import InsuredDetails from "./custom/InsuredDetails"
import { useParams } from "react-router"

function InsuredInfo(props) {
    const { id: testId } = useParams()
    const previousTableQuery = usePreviousTableQuery("/insured-view")

    const permission = props?.applicationState?.authentication?.user?.permission
    const breadcrumbItems = [
        {
            label: "Insured",
            link: `/insured-view?${previousTableQuery}`,
        },
        {
            label: testId,
            link: `/insured/${testId}`,
        },
    ]

    return (
        <div className="wide-container">
            <Breadcrumb breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Insured Details</CardTitle>
                </CardHeader>
                <CardBody>
                    <InsuredDetails testId={testId} permission={permission} />
                </CardBody>
            </Card>
        </div>
    )
}

export default InsuredInfo
