import React, { useEffect, useRef, useState } from 'react';
import './covid-streamline-form.css';
import GeneralForm from "./general-form";
import lookups from "../configuration/lookups";
import covidStreamlineFormDefinition from "../configuration/covid-streamline-form-definition";
import {Container, Row, Col, Alert, Button} from "reactstrap";
import {makeApiCall} from "../api/generic-api";
import {get} from "../utility/client-utility";
import { debounce } from "lodash";
//import { submitStreamline } from "../api/submit-streamline";
//import ReactPlayer from "react-player";

function CovidStreamlineForm(props) {
    const [alertState, setAlertState] = useState();
    const [alertBadMessage, setAlertBadMessage] = useState();
    const [ price, setPrice ] = useState(0);

    const submit = async (formState) => {
        console.log('covid streamline form submit');
        const result = await makeApiCall('post', '/api/submission/covid', formState);
        if (result.ok) {
            setAlertState('good');
            setAlertBadMessage('');
            window.scrollTo(0, 0);
        } else {
            setAlertState('bad');
            const detail = `Details: code ${result.statusCode}; ${result.error}`;
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }
    };

    const cancel = () => {
        console.log('covid test streamline form cancel');
        window.location.href = '/';
    };

    const reset = () => {
        console.log('covid test streamline form reset');
        setAlertState('reset');
        setTimeout(() => setAlertState(null), 200);
    };

    const change = () => {
        setAlertState(null);
    };

    // const getFormState = () => {
    //     const params = new URLSearchParams(window.location.search);
    //     const testid = params.get("testid");
    //     const manualTestId = !testid;
    //     return {testId: testid, manualTestId: manualTestId};
    // };

    const resetStyle = {
        cursor: 'pointer',
        padding: '14px 24px',
        borderRadius: '200px',
        backgroundColor: '#29335c',
        boxShadow: 'inset 0 10px 3px 100px transparent',
        webkitTransition: 'box-shadow 200ms ease',
        transition: 'box-shadow 200ms ease',
        color: '#ffffff',
        fontWeight: 600,
        textAlign: 'center'
    };

    const fieldsOnChange = useRef(
      debounce(async (data) => {
          await fetchPrices(data);
      }, 200)
    ).current
    
    const fetchPrices = async (data) => {
        if (data.testId && data.payerTypeLabel) {
            const patientInsured = data.patientInsured;
            if ((patientInsured === 'true' || patientInsured === true) && data.payerTypeLabel === 'personal') {
                data.payerType = 'insurance'
            } else {
                data.payerType = data.payerTypeLabel;
            }
            
            data.payerTypeLabel = data.payerTypeLabel;
            
            const payerType = data.payerType;
            const feeCode = data.feeCode === '' ? null : data.feeCode;
            const testTypeId = data.testId.substring(data.testId.length - 1);
            
            const productResult = await makeApiCall('get', '/api/products', {
                selector: {
                    testTypeData: {
                        $elemMatch: {
                            value: testTypeId
                        }
                    }
                }
            });
            
            if (productResult && productResult.statusCode === 200 && productResult.body && productResult.body.length > 0) {
                const productId = productResult.body[0].id;
                const priceResult = await makeApiCall('get', '/api/prices', {
                    selector: {
                        'productId': productId,
                        'payerType': payerType,
                        'feeCode': feeCode
                    }
                });
                
                if (priceResult && priceResult.statusCode === 200 && priceResult.body && priceResult.body.length > 0) {
                    data.price = priceResult.body[0].price;
                    setPrice(priceResult.body[0].price);
                    return priceResult.body[0].price;
                } else {
                    data.price = null;
                    setPrice(null);
                    return null;
                }
            } else {
                data.price = null;
                setPrice(null);
                return null;
            }
        } else {
            data.price = null;
            setPrice(null)
            return null;
        }
    }

    return (
        <div className="CovidStreamlineForm" style={{marginTop:'30px'}}  id={"streamline-form-top"}>
            <Container>
                <Row>
                    <Col>
                        <h2>COVID-19 TEST SUBMISSION</h2>
                    </Col>
                </Row>
                {alertState !== 'good' && alertState !== 'reset' ?
                    <Row>
                        <Col>Welcome to the Wren COVID-19 test submission process.
                            Please fill in the form below and submit it with all required fields completed, together with any optional fields that apply.
                        </Col>
                    </Row>
                    : null
                }
                <Row>
                    <Col>
                        <div className="float-right">
                            <Button
                                type="button"
                                outline color="secondary"
                                style={resetStyle}
                                onClick={() => reset()}
                            >Reset</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
            {alertState !== 'good' && alertState !== 'reset' ?
                <GeneralForm
                    formDefinition={covidStreamlineFormDefinition}
                    // formState={getFormState()}
                    lookups={lookups}
                    submit={submit}
                    cancel={cancel}
                    change={change}
                    fieldsOnChange={fieldsOnChange}
                    price={price}
                    isCovid={true}
                    {...props}
                />
                : null
            }
            { alertState === 'good' ?
                <div>
                    <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                        {'Data submitted.  Press reset if you want to enter another now.'}
                    </Alert>
                    {/*
                    <div style={{margin: '20px'}}>
                        <div style={{margin: '5px', textAlign: 'center', color: '#40496b', fontWeight: 500}} >Guide to Wren Laboratories Saliva COVID-19 test</div>
                        <ReactPlayer
                            url={"https://vimeo.com/476623484"}
                            controls={true}
                            style={{margin: 'auto', textAlign: 'center'}} />
                    </div>

                    <div style={{margin: '20px'}}>
                        <div style={{margin: '5px', textAlign: 'center', color: '#40496b', fontWeight: 500}}>Instructions for using the Wren Laboratories Saliva COVID-19 test</div>
                        <ReactPlayer
                            url={"https://vimeo.com/506176148"}
                            controls={true}
                            style={{margin: 'auto', textAlign: 'center'}} />
                    </div>
                    */}
                </div>
                : null
            }
            { alertState === 'bad' ?
                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                    {alertBadMessage}
                </Alert>
                : null
            }
        </div>
    );
}

export default CovidStreamlineForm;
