import React, { useState } from "react"
import { Card, CardBody } from "reactstrap"
import { useQuery } from "hooks/query"
import { useNavigate } from "react-router"
import Breadcrumb from "components/custom/Breadcrumb"
import { RequisitionConfigurationTable } from "components/tables/RequisitionConfigurationTable"

function RequisitionsConfigurationView(props) {
  const query = useQuery()

  const permission = props && props.applicationState.authentication.user.permission
  const id = query.get("id")

  return (
    <div>
      <div className="wide-container">
        <Breadcrumb title={Boolean(id) ? `${id === "new" ? "Add" : "Edit"} Configuration` : `Requisitions Configuration`} breadcrumbItems={[]} />
        <Card>
          <CardBody>
            <RequisitionConfigurationTable
              showAdd
              allowPageSize
              allowPaginate
              downloadFileName="Requisitions Configuration"
              permission={permission}
              defaultSort="createdTimestamp"
              defaultSortDir="asc"
              {...props}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default RequisitionsConfigurationView
