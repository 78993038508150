import React, { useState } from 'react';
import { Button, Form, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';  // Import useNavigate
import QRCode from 'qrcode.react';
import { FaCopy, FaInfoCircle } from 'react-icons/fa';

const AuthenticationAppSetup = ({ setupData, handleSetup, handleCopy, setError, authCode, setAuthCode, qrCode }) => {
	const [codeError, setCodeError] = useState(null);
	const navigate = useNavigate();  // Initialize useNavigate
	
	const handleSubmit = () => {
		if (authCode === '') {
			setCodeError('Code cannot be empty');
			return;
		}
		
		if (!/^\d+$/.test(authCode)) {
			setCodeError('Code must contain only numbers');
			return;
		}
		
		setCodeError(null);
		handleSetup();
	};
	
	return (
		<div>
			<h4 className="mb-4">Authentication App</h4>
			<p style={{ fontSize: '0.9rem', color: '#6c757d' }}>
				Using any authenticator tools (i.e. Google Authenticator), scan this QR code. It will generate a 6 digit code for you to enter below.
			</p>
			<div className="d-flex justify-content-center mb-3">
				<QRCode value={qrCode} size={200} />
			</div>
			<Form.Group>
				<Form.Label style={{ fontSize: '0.9rem', color: '#6c757d' }}>Enter Authentication Code</Form.Label>
				<Form.Control
					type="text"
					placeholder="Enter Code"
					value={authCode}
					onChange={(e) => {
						const value = e.target.value;
						if (!/^\d*$/.test(value)) {
							e.preventDefault();
							return;
						}
						setAuthCode(value);
					}}
				/>
				{codeError && <Alert variant="danger" className="mt-2">{codeError}</Alert>}
			</Form.Group>
			<small className="text-muted mb-3">
				<FaInfoCircle className="me-2" /> You can use <code>{setupData?.issuer}</code> as an account name.
			</small>
			<div className="my-4">
				<p className="bg-light p-4 rounded">
					<div className="text-muted text-center">
						<small>Scan not working? Copy this code key and enter it manually in your authentication app.</small>
					</div>
					<div className="mt-3 text-center">
						{setupData?.secret}
						<FaCopy className="ms-2" style={{ cursor: 'pointer' }} onClick={handleCopy} />
					</div>
				</p>
			</div>
			<div className="d-flex justify-content-end mb-3 mt-4">
				<Button variant="light" onClick={() => navigate('/logout')} className="me-2">  {/* Use navigate here */}
					Cancel
				</Button>
				<Button
					variant="primary"
					onClick={handleSubmit}
					style={{ backgroundColor: '#f64d52', borderColor: '#f64d52', color: 'white' }}
				>
					Set up
				</Button>
			</div>
		</div>
	);
};

export default AuthenticationAppSetup;
