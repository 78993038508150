import React from "react"
import { Card, CardBody } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import { RequisitionsTable } from "./tables/RequisitionsTable"

function RequisitionNetest(props) {
  const permission = props && props.applicationState.authentication.user.permission

  const breadcrumbItems = [
    {
      label: "Cancer Sample Requisition Backlog",
      link: `/requisition-netest`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Cancer Sample Requisition Backlog" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <RequisitionsTable allowPageSize allowPaginate isGlobalFilter permission={permission} {...props} />
        </CardBody>
      </Card>
    </div>
  )
}

export default RequisitionNetest
