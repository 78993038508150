import fetch from 'isomorphic-fetch';
import { dataToQuery } from "../utility/uri-utility";
import FileSaver from "file-saver";

const asyncFetch = async(url, options) => fetch(url, options);

const makeApiCall = async (verb, url, parameters) => {
    const useQueryString = verb?.toLowerCase() === "get"
    const id = parameters ? parameters._id || parameters.id : ""
    const fullUrl = url?.replace(":id", id) + (useQueryString ? dataToQuery(parameters, url.endsWith("?")) : "")
    const body = useQueryString || !parameters ? null : JSON.stringify(parameters);

    try {
        const response = await asyncFetch(fullUrl, {
          method: verb?.toUpperCase(),
          headers: {
            "Content-Type": "application/json",
          },
          body: body,
        })

        if(response.ok) {
            let json = '';
            try {
                json = await (async() => response.json())();
            } catch(e) {}
            return {ok: true, statusCode: response.status, body: json};
        }
        
        // else if (response.status == 401) {
        //     window.location.href = '/login?unauthorized=true';
        // }
        
        else {
            const text = await (async() => response.text())();
            let error = '';
            try {
                const json = JSON.parse(text);
                error = json.error || json.errorMessage || json.message;
            } catch(e) {
                error = text;
            }
            return {ok: false, statusCode: response.status, body: null, error: error};
        }
    } catch(reason) {
        console.error('caught an error in ', verb, url, parameters, reason);
        return { ok: false, statusCode: null, error: reason };
    }
};

const makeApiDownloadCall = async (verb, url, parameters, type, filename, isPcr = false) => {
    const useQueryString = verb.toLowerCase() === 'get' && url.endsWith('?');
    
    let fullUrl;
    let id;
    let pcrFilename;
    if (isPcr) {
        const key = filename.slice(0, 0) + filename.slice(0 + 1);
        pcrFilename = parameters[key];
        id = (parameters._id || parameters.id) + '-' + parameters[key];
        fullUrl = url.replace(filename, id) + (useQueryString ? dataToQuery(parameters, url.endsWith('?')) : '' );
    } else {
        const useQueryString = verb.toLowerCase() === 'get' && url.endsWith('?');
        id = parameters._id || parameters.id;
        fullUrl = url.replace(':id', id) + (useQueryString ? dataToQuery(parameters, url.endsWith('?')) : '' );
    }
    
    try {
        const response = await asyncFetch(fullUrl, {
          method: verb?.toUpperCase(),
        })
        
        if(response.ok) {
            const data = await (async() => response.blob())();
            let blob = new Blob([data], {type: type && type.replace(':id', id)});
            if (isPcr) {
                FileSaver.saveAs(blob, filename && filename.replace(filename, pcrFilename));
            } else {
                FileSaver.saveAs(blob, filename && filename.replace(':id', id));
            }
            return {ok: true, statusCode: response.status, body: response.body, error: null};
        } else {
            const text = await (async() => response.text())();
            return {ok: false, statusCode: response.status, body: null, error: text};
        }
    } catch(reason) {
        console.error('caught an error in ', verb, url, parameters, reason);
        return { ok: false, statusCode: null, error: reason };
    }
};

export {
    makeApiCall,
    makeApiDownloadCall
};
