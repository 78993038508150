import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { PriceTable } from "./tables/PriceTable"
import Breadcrumb from "./custom/Breadcrumb"

function PriceView(props) {
  const permission = props && props.applicationState.authentication.user.permission

  const breadcrumbItems = [
    {
      label: "prices",
      link: `/prices-view`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Prices" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <PriceTable allowPageSize allowPaginate isGlobalFilter permission={permission} />
        </CardBody>
      </Card>
    </div>
  )
}

export default PriceView
