import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react';
import { Button } from 'reactstrap';
import {get, objectsIdentical, getValueByDotNotation, curry} from '../../utility/client-utility';
import GeneralTable from "../general-table";
import GeneralForm from "../general-form";
import DataControls from "../data-controls";
import csvDownloader from "../csv-downloader";
import { makeApiCall, makeApiDownloadCall } from "../../api/generic-api";
import DataSelector from "../data-selector";
import { useAlert } from "react-alert";
import InnerFormContainer from "../inner-form-container";
import { useNavigate } from "react-router";
import { dataToStringQuery, flatten, stringQueryToData } from "../../utility/uri-utility";
import ReactLoading from "react-loading";
import { isEqual, omit, pick } from "lodash";
import GeneralFormNotifications from "./general-form-notifications";

const DataWowNotifications = forwardRef(( props, ref) => {
    const { showViewFormAction = true } = props;
    const navigate = useNavigate();
    
    useImperativeHandle(ref, () => ({
        callRefresh() {
            console.log('calling refresh from data wow');
            refresh()
        }
    }));
    const alert = useAlert();

    console.log('data wow');
    const update = props && props.update;
    const loadData = props && props.loadData;
    const structure = props && props.structure;
    const defaultPageSize = 20;
    const pageSize = get(() => structure.pageSize, defaultPageSize);
    const tableStructure = get(() => structure.table, {});
    const formStructure = get(() => structure.form, {});
    const innerFormStructure = get(() => structure.innerForm, {});
    const tableKey = get(() => tableStructure.key);
    const tableKeyArray = tableKey ? [tableKey] : [];
    const tableColumns = tableStructure.columns ? tableStructure.columns : [];
    const tableColumnFields = tableColumns.filter(column => column.field);
    const tableColumnFieldNames = tableColumnFields.map(column => column.field);
    const tableFields = tableKeyArray.concat(tableColumnFieldNames);
    const tableFieldsWithExtras = tableStructure.extraFields ? tableFields.concat(tableStructure.extraFields) : tableFields;
    const getFormFieldNames = form => {
        const fields = form.sections.reduce((acc, section) => {
            return acc.concat(section.fields);
        }, []);
        return fields.map(field => props.formDataField ? props.formDataField + '.' + field.name : field.name);
    };
    const formFields = getFormFieldNames(formStructure);
    const fields = tableFieldsWithExtras.concat(formFields);
    
    const urlParams = new URLSearchParams(window.location.search);
    const urlParamsString = decodeURIComponent(urlParams.toString());
    const dataQuery = stringQueryToData(urlParamsString);
    console.log('dataQuery', dataQuery);
    
    const [ data, setData ] = useState(null);
    const [ id, setId ] = useState(() => {
        return dataQuery?.id || null
    });
    const [ subId, setSubId ] = useState(() => {
        return dataQuery?.subId || null
    });
    const [selector, setSelector] = useState(() => {
        if (dataQuery?.selector?.data) {
            const selectorWithData = pick(dataQuery?.selector, 'data')
            const selectorWithoutData = omit(dataQuery?.selector, 'data')
            if (selectorWithData) {
                dataQuery.selector = { ...selectorWithoutData, ...flatten(selectorWithData, 1) }
            }
        }
        
        console.log('dataQuery?.selector', dataQuery?.selector);
        return dataQuery?.selector || null
    });
    const [sort, setSort] = useState(() => {
        return dataQuery?.sort && [flatten(dataQuery.sort, 1)] || tableStructure.defaultSort
    });
    const [pageRequested, setPageRequested] = useState(() => {
        return dataQuery?.page || 1
    });
    const [pageActual, setPageActual] = useState(() => {
        return dataQuery?.page || 1
    });
    const [displayMode, setDisplayMode] = useState(() => {
        return dataQuery?.displayMode || 'table'
    });
    const [currentRecord, setCurrentRecord] = useState(() => {
        return null;
    });
    const [currentInnerRecord, setCurrentInnerRecord] = useState(() => {
        return dataQuery?.subId && { subId: dataQuery?.subId } || null
    });
    const [lastPage, setLastPage] = useState(1);
    const [previousQuery, setPreviousQuery] = useState();
    
    
    const [ checkedInput, setCheckedInput ] = useState([]);
    const [ isLoading, setIsLoading ] = useState(false);
    const [ isDownloading, setIsDownloading ] = useState(false);
    
    useEffect(() => {
        console.log('currentInnerRecord', currentInnerRecord);
        const query = {
            displayMode: displayMode,
            previousQuery: previousQuery,
            currentRecord: displayMode == 'form' && currentRecord,
        }
        
        const urlQueryData = {
            id: query?.currentRecord?._id || query?.currentRecord?.id || id,
            subId: currentInnerRecord?.id || currentInnerRecord?.testId || subId,
            displayMode: query?.displayMode || "table",
            page: query?.previousQuery?.page || pageRequested,
            pageSize: query?.previousQuery?.pageSize || pageSize,
            selector: query?.previousQuery?.selector || selector,
            sort: !!query?.previousQuery?.sort && query?.previousQuery?.sort[0] || !!sort && sort[0] || null,
        }
        
        const stringQuery = dataToStringQuery(urlQueryData)
        navigate(`?${stringQuery}`)
    }, [ previousQuery, displayMode, currentRecord, currentInnerRecord, id]);
    
    const getFieldValue = (record, column) => {
        const value = getValueByDotNotation(record, column.field);
        if(value && column.type === 'timestamp' && column.format === 'toLocalizedDateString') {
            return new Date(value).toLocaleString();
        }
        return value;
    };
    
    const download = () => {
        console.log('download');
        if (!data?.body) return;
        (async () => {
            setIsDownloading(true);
            const fields = get(() => structure.download.fields, tableColumnFields);
            setTimeout(() => {
                csvDownloader(data.body, fields, getFieldValue);
                setIsDownloading(false);
            }, 800)
            // refresh();
        })();
    };

    const genericAction = (details, record) => {
        console.log('action');
        console.log('record', record);
        console.log('details', details);
        if(!record) {
            console.warn('No record selected for action');
            return;
        }
        if(!details) {
            console.error('No details selected for action');
            return;
        }
        if(!structure.endpoints) {
            console.error('No endpoints defined for this structure');
            return;
        }
        if(!details.endpoint) {
            console.error('No endpoint defined for this action');
            return;
        }
        const endpoint = structure.endpoints[details.endpoint];
        if(!endpoint) {
            console.error('No endpoint found for this action endpoint', details.endpoint);
        }
        const verb = endpoint.verb || details.endpoint;
        const baseUri = endpoint.uri || endpoint;
        if(!verb || !baseUri) {
            console.error('Missing verb/uri for action', details);
            return;
        }
        const uri = details.idToken ? baseUri.replace(details.idToken, encodeURIComponent(record[tableKey] || record.id)) : baseUri;
        const parameters = details.data || record;
        if (details.download) {
            (async () => {
                try {
                    const data = await makeApiDownloadCall(verb, uri, parameters, endpoint.type, endpoint.filename);
                } catch (err) {
                    console.error('Error in download action', err, details);
                }
            })();
        } else {
            (async () => {
                try {
                    const result = await makeApiCall(verb, uri, parameters);
                    console.log(result);
                    if (result.ok) {
                        alert.show('Action has been successfully executed', {
                            type: 'success'
                        })
                        window.scrollTo(0, document.body.scrollHeight);
                    } else {
                        let error;
                        if (result.statusCode !== null) {
                            error = result.error
                            const detail = `Details: code ${result.statusCode}; ${error.message || error}`;
                            alert.show(detail, {
                                type: 'error'
                            })
                            window.scrollTo(0, document.body.scrollHeight);
                        }
                    }
                } catch(err) {
                    console.error('Error in action', err, details);
                }
                refresh();
            })();
        }
    };

    const loadCurrentData = async () => {
        setIsLoading(true);
        console.log('loadCurrentData');
        // immediately invoked function expression IIFE
        const fullSelector = {...selector, ...props.fixedSelector};
        const paging = (objectsIdentical(fullSelector, previousQuery && previousQuery.selector) && objectsIdentical(sort, previousQuery && previousQuery.sort));
        const page = paging ? pageRequested : 1;
        const genericQuery = {
            fields: fields,
            selector: fullSelector,
            sort: sort,
            page: page,
            pageSize: pageSize
        };
    
        let finalQuery = genericQuery;
        const action = structure.endpoints.search.action;
        if (action) {
            finalQuery = { action, ...finalQuery }
        }
    
        setPreviousQuery(finalQuery);
        const data = await loadData(get(() => structure.endpoints.search.verb), get(() => structure.endpoints.search.uri), finalQuery);
    
        if (dataQuery?.id && data) {
            const record = data?.body?.filter(x => x.id == dataQuery.id)[0];
            if (record) {
                setCurrentRecord(record)
            }
        }
        
        setPageActual( !data.ok ? 0 : page * 1);
        setLastPage((data.ok && data.body instanceof Array && data.body.length < pageSize) || !data.ok ? pageActual : 0);
        setData(data);
        
        setIsLoading(false);
    };

    const refresh = () => {
        if(pageActual === 1) {
            loadCurrentData();
        } else {
            setPageRequested(1);
        }
        setCheckedInput([])
    };
    
    
    useEffect(() => {
        if (!isEqual(selector, dataQuery?.selector)) {
            setSelector(selector || null)
        }
    }, [ dataQuery?.selector ]);
    
    useEffect(() => {
        loadCurrentData();
    }, [selector, sort, pageRequested]);
    
    const viewForm = async record => {
        if (record?.data) {
            record.data.id = record?.id;
        }
        
        setCurrentRecord(record);
        setDisplayMode('form');
        if (props.change) {
            props.change();
        }
        setCheckedInput([])
        
    };
    
    const viewInnerForm = record => {
        if (record?.data) {
            record.data.id = record?.id;
        }
        setCurrentInnerRecord(record);
        setDisplayMode('inner-form');
        if (props.change) {
            props.change();
        }
        setCheckedInput([])
    };
    
    const closeForm = () => {
        setDisplayMode('table');
        setId(null);
        if (props.change) {
            props.change();
        }
    };
    
    // const closeInnerForm = (record) => {
    //     setDisplayMode('form');
    //     // if (props.change) {
    //     //     props.change();
    //     // }
    // };

    const standardTableRowActions = showViewFormAction ? [
        {
            type: 'button',
            name: 'view',
            label: 'View',
            color: 'primary',
            action: viewForm
        }
    ]: [];

    const tableRowActions = props && props.customRowActions && props.customRowActions instanceof Array && props.customRowActions.length > 0 ?
        standardTableRowActions.concat(props && props.customRowActions && props.customRowActions.map(customRowAction => {
            console.log('customRowAction', customRowAction);
            if (customRowAction.name !== 'generateId') {
                const curriedAction = curry((details, record) => genericAction(details, record));
                customRowAction.action = curriedAction(customRowAction.details);
                return customRowAction;
            } else {
                return customRowAction;
            }

        }))
       : standardTableRowActions;
    
    
    const formRowActions = props && props.customFormRowActions && props.customFormRowActions instanceof Array
    && props.customFormRowActions.length > 0 ?
      props && props.customFormRowActions && props.customFormRowActions.map(customRowAction => {
          if (customRowAction.name == 'view') {
              customRowAction.action = viewInnerForm;
          } else if (customRowAction.name !== 'showPcrDetail'){
              const curriedAction = curry((details, record) => genericAction(details, record));
              customRowAction.action = curriedAction(customRowAction.details);
          }
          
          return customRowAction;
      })
      : null;

    console.log('formRowActions', formRowActions);

    const handleDelete = async () => {
        await props.handleDelete(checkedInput);
        refresh();
    }

    return (
        <div className={props.className} style={props.style}>
            {displayMode === 'table' ?
                <div>
                    <DataControls
                        className="float-right mb-4"
                        style={{marginTop: '20px'}}
                        currentPage={pageActual}
                        lastPage={lastPage}
                        setPageRequested={setPageRequested}
                        download={download}
                        isDownloading={isDownloading}
                        refresh={refresh}
                        add={viewForm}
                        showAddButton={props.showAddButton}
                        showPaginationButtons={props.showPaginationButtons}
                        showDeleteButton={props.showDeleteButton}
                        handleDelete={handleDelete}
                        checkedInput={checkedInput}
                    />
                    <DataSelector
                        className="float-right"
                        style={{ marginRight: '100px'}}
                        configuration={props.userSelector}
                        setSelector={setSelector}
                        selector={selector}
                        displayMode={displayMode}
                    />
                </div>
                : <Button
                    type="button"
                    outline color="primary"
                    onClick={() => displayMode == 'inner-form' ? viewForm(currentRecord): closeForm()}>{`Return to ${displayMode == 'inner-form' ? 'Form' : 'Table'}`}</Button>
            }
            { (displayMode === 'form' && dataQuery.id && currentRecord) ||
              (displayMode === 'form' && !dataQuery.id && !currentRecord)?
                <GeneralFormNotifications
                    style={{marginTop: '30px'}}
                    formDefinition={formStructure}
                    lookups={props.lookups}
                    formState={props.formDataField ? currentRecord && currentRecord[props.formDataField] : currentRecord}
                    close={closeForm}
                    update={props.update}
                    change={props.change}
                    findDuplicate={props.findDuplicate}
                    existingSubjects={props.existingSubjects}
                    setExistingSubjects={props.setExistingSubjects}
                    loadCurrentData={loadCurrentData}
                    setAlertState={props.setAlertState}
                    formRowActions={formRowActions}
                    currentRecord={currentRecord}
                    isLoading={isLoading}
                    {...props}
                />
                 :
              displayMode === 'inner-form' ?
                // <GeneralForm
                //   style={{marginTop: '30px'}}
                //   formDefinition={innerFormStructure}
                //   lookups={props.lookups}
                //   formState={props.formDataField ? currentRecord && currentRecord[props.formDataField] : currentRecord}
                //   close={closeForm}
                //   update={props.update}
                //   change={props.change}
                //   findDuplicate={props.findDuplicate}
                //   existingSubjects={props.existingSubjects}
                //   setExistingSubjects={props.setExistingSubjects}
                //   loadCurrentData={loadCurrentData}
                //   setAlertState={props.setAlertState}
                //   formRowActions={formRowActions}
                //   {...props}
                // />:
                <InnerFormContainer
                  component={props.innerFormComponent}
                  currentInnerRecord={currentInnerRecord}
                  currentRecord={currentRecord}
                />:
                displayMode === 'table' ?
                <div>
                  <GeneralTable
                    style={{marginTop: '30px'}}
                    table={tableStructure}
                    data={data && data.ok && data.body}
                    firstRowNumber={(pageActual - 1) * pageSize + 1}
                    setSort={setSort}
                    sort={sort}
                    rowActions={tableRowActions}
                    setAlertState={props.setAlertState}
                    setCheckedInput={setCheckedInput}
                    checkedInput={checkedInput}
                    isLoading={isLoading}
                    loadCurrentData={loadCurrentData}
                    refresh={refresh}
                  />
              </div>:
                  <center className="mt-5">
                      <ReactLoading type='spin' color='red' height={667} width={100} />
                  </center>

            }
        </div>
    );
});

DataWowNotifications.displayName = "DataWowNotifications"

export default DataWowNotifications;
