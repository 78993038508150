import React from 'react';
import ReactDOM from 'react-dom';

// bootstrap
import 'bootstrap/dist/css/bootstrap.css';

import './index.css';

// app entry point
import App from './components/App';

import registerServiceWorker from './registerServiceWorker';

import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import { ApiProvider } from "./api/provider"

// optional configuration
const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: "30px",
  // you can also just use 'scale'
  transition: transitions.SCALE,
}

const Root = () => (
  <AlertProvider template={AlertTemplate} {...options}>
    <ApiProvider>
      <App />
    </ApiProvider>
  </AlertProvider>
)

ReactDOM.render(
	<Root/>
    , document.getElementById('root')
);

registerServiceWorker();
