import prescriptionFormDefinition from "./prescription-form-definition";

const prescriptionFullDefinition = {
    key: '_id',
    //userId: 'primaryLogin',
    table: {
        key: '_id',
        columns: [
            {
                label: '#',
                type: 'rowNumber',
                filterType: 'none',
                sortType: 'none'
            },
            {
                label: 'Submitted',
                field: 'data.submittedTimestamp',
                type: 'timestamp',
                format: 'toLocalizedDateString',
                sortType: 'both'
            },
            {
                label: 'First Name',
                field: 'data.firstName',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Last Name',
                field: 'data.lastName',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Date of Birth',
                field: 'data.dateOfBirth',
                type: 'text',
                filterType: 'equals'
            },
            {
                label: 'Allowed',
                field: 'data.allowed',
                type: 'boolean',
                filterType: 'equals'
            }
        ],
        defaultSort : [
            {'data.submittedTimestamp' : 'asc'}
        ]
    },

    form: prescriptionFormDefinition,
    //     newRecordTemplate: {
    //         status: 'live'
    //     },
    //     validation: (record) => {
    //         return record.primaryLogin.length > 12 && record.name.startsWith('s');
    //     }
    // },

    endpoints : {
        //post: '/api/laboratory',
        //put: '/api/laboratory/:laboratoryid',
        //patch: '/api/requisition/:id',
        //delete: '/api/laboratory/:laboratoryid',
        //get: '/api/laboratory/:laboratoryid',
        //getAll: '/api/laboratories',
        //search: '/api/laboratories?search=:search',
        search: {
            uri: '/api/prescriptions?',
            verb: 'get'
        }
    },
    // labels: {
    //     table: 'Laboratories',
    //     form: {
    //         new: 'New Laboratory',
    //         edit: 'Edit Laboratory'
    //     }
    // },
    pageSize: 10
};

export default prescriptionFullDefinition;
