import React, { Component } from 'react';
import UploadView from './upload-view';

const Upload = props => {
    const structure = {
        key: 'uploadid',
        table: {
            header : [
                '#', 'Date', 'User', 'Filename', 'OK', 'WARN', 'ERROR', 'Total', 'Status', 'View Details'
            ],
            body : [
                '|#|', 'timestamp', 'username', 'filename', 'totalOK', 'totalWARN', 'totalERR', 'totalRow', 'filestatus', '|View|'
            ]
        },
        tableDetails: {
            header : [
                '#', '|%|', 'Item', 'Status', 'Error Message'
            ],
            body : [
                '|##|', '|%|', 'item', '|status.code|', '|status.details|'
            ]
        },
        uri : {
            get: '/api/upload/:uploadid',
            getAll: '/api/uploads',
            getConfig: '/api/upload-config'
        },
        labels: {
            table: 'Upload'
        },
        pageSize: 10,
        detailedPageSize: 10
    };
    return <UploadView structure={structure}  {...props}></UploadView>
};

export default Upload;
