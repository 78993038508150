import React, { useEffect, useState } from "react"
import { Button, Input, Alert, Spinner } from "reactstrap"
import { get } from "utility/client-utility"
import { makeApiCall } from "api/generic-api"
import { useAlert } from "react-alert"
import { Controller, useForm } from "react-hook-form"
import netestRequisitionFormDefinition from "configuration/netest-requisition-form-definition"
import { useGetSampleIdQuery } from "api/getters"
import { useAddSampleId } from "api/mutators"

const GenerateRecordId = (props) => {
  const alert = useAlert()

  const formDefinition = netestRequisitionFormDefinition.generateId

  const { handleSubmit, control, reset } = useForm({
    defaultValues: {
      sampleId: "",
    },
  })

  const addLeadingZeros = (value) => {
    return String(value).padStart(8, 0)
  }

  const resolveId = async (lastId) => {
    let possibleNewId
    if (lastId) {
      const intValues = lastId.slice(4)
      const strippedId = intValues.slice(0, -1)
      const maxId = 99999999

      if (strippedId > maxId) {
        const detail = `You've reached the maximum number of generated ID, please contact the administrator`
        alert.show(detail, {
          type: "error",
        })
        return
      }

      possibleNewId = Number(strippedId) + 1
    } else {
      possibleNewId = 1
    }
    const newId = addLeadingZeros(possibleNewId)
    return newId
  }

  const generateId = async () => {
    let id;
    let prefix;
    const netestData = await makeApiCall('get', `/api/netests`);
    console.log('netestData', netestData);
    if (netestData.ok) {
      const lastId = netestData.body.sampleId;
      const resolvedId = await resolveId(lastId);
      if (resolvedId) {
        const prefix = netestData.body.prefix;
        id = prefix + resolvedId + 'B';
        reset({
          sampleId: id,
        })
      }
    } else {
      const detail = netestData.error;
      alert.show(detail, {
        type: "error"
      });
      // setAlertBadMessage(detail);
    }
  };

  const { refetch, isFetching } = useGetSampleIdQuery({
    onSuccess: async (_data) => {
      // const lastId = _data.sampleId
      // const resolvedId = await resolveId(lastId)
      // if (resolvedId) {
      //   const prefix = _data.prefix
      //   const id = prefix + resolvedId + "B"
      //   reset({
      //     sampleId: id,
      //   })
      // }
    },
    onError: (_error) => {
      alert.show(_error?.response?.data?.reason || _error?.response?.data?.message || _error?.message, {
        type: "error",
      })
    },
  })

  const { mutateAsync, isLoading: mutating } = useAddSampleId({
    variables: {
      id: props.requisitionId,
    },
    onSuccess: () => {
      const detail = "Successfully created sample ID. Click refresh button"
      alert.show(detail, {
        type: "success",
      })
      if (props.onSuccess) {
        props.onSuccess()
      }
    },
    onError: (_error) => {
      alert.show(_error?.response?.data?.reason || _error?.response?.data?.message || _error?.message, {
        type: "error",
      })
    },
  })

  const validateId = (_id) => {
    const expectedFormat = /^[A-Za-z]{4}\d{8}B$/
    if (_id) {
      return expectedFormat.test(_id)
    }

    return false
  }

  const onSubmit = handleSubmit(async (data) => {
    const isValidId = validateId(data.sampleId)
    if (isValidId && isValidId === true) {
      try {
        await mutateAsync(data)
      } catch (err) {
        console.error({ err })
      }
    } else {
      const detail = `Invalid sampleId. Should be 4 letters and 8 numbers along with the suffix of B`
      alert.show(detail, {
        type: "error",
      })
    }
  })


  return (
    <form onSubmit={onSubmit}>
      <div className="d-flex align-items-center gap-2 ">
        <Button className="text-nowrap" type="button" color="warning" onClick={generateId}>
          {isFetching && <Spinner size="sm" />} Generate
        </Button>
        <Controller
          name="sampleId"
          control={control}
          render={({ field }) => (
            <Input
              type="text"
              pattern={formDefinition.pattern}
              required={formDefinition.required}
              className={field.value ? "" : "invalid-input"}
              disabled={isFetching}
              {...field}
            />
          )}
        />
      </div>
      <span className="small">{formDefinition.notes}</span>
      <div className="d-flex gap-2 mt-3 justify-content-end">
        <Button color="danger" type="submit" disabled={mutating}>
          {mutating && <Spinner size="sm" />} Confirm
        </Button>
        <Button
          outline
          type="button"
          onClick={() => {
            if (props.onCancel) {
              props.onCancel()
            }
          }}
          disabled={mutating}
        >
          Cancel
        </Button>
      </div>
    </form>
  )
}

export default GenerateRecordId
