import './general-form.css';
import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Row,
    Col,
    Container,
    Alert,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu, DropdownItem
} from 'reactstrap';
import { get, getValueByDotNotation } from '../utility/client-utility';
import QrcodeScanner from "./qrcode-scanner";
import GeneralTable from "./general-table";
import { makeApiCall, makeApiDownloadCall } from "../api/generic-api";

import { debounce } from "lodash"
import { matcher, isMatch } from 'matcher';
import { findRenderedComponentWithType } from "react-dom/test-utils";
import { MultiSelect } from "react-multi-select-component";
import BootstrapSwitchButton from 'bootstrap-switch-button-react';

import * as moment from 'moment';
import ReactLoading from "react-loading";
import lookups from "../configuration/lookups";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router";
import { useParams } from "react-router-dom";
import { countries } from "countries-list";
import { Link } from 'react-router-dom';


function GeneralForm(props) {
    const navigate = useNavigate();
    let { subjectId } = useParams();

    const { currentRecord } = props;
    // console.log('props.formState', props.formState);
    const elements = useRef({});
    const sections = get(() => props.formDefinition.sections, []);
    const allFields = sections.reduce((accumulator, currentValue) => { return accumulator.concat(currentValue.fields) }, []);
    const dateFields = allFields.filter(field => field.type === 'date');
    const datetimeFields = allFields.filter(field => field.type === 'datetime-local');
    const readOnly = props.formState && (!props.submit || !!props.update);
    const permission = props && props.applicationState.authentication.user.permission;

    const [isDisableForm, setIsDisableForm] = useState(readOnly);
    const [showMergeModal, setShowMergeModal] = useState(false);
    const [submitType, setSubmitType] = useState(null);
    const [isEditForm, setIsEditForm] = useState(false);
    const [othersDisabled, setOthersDisabled] = useState(true);
    const [isMerging, setIsMerging] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [viewTestResult, setViewTestResult] = useState(false);

    const [selectedTestGroup, setSelectedTestGroup] = useState(null);
    const topCountries = ['US', 'GB', 'NL'];
    const addressField = [
        'state',
        'zipCode',
        'postalCode',
        'region',
        'billingState',
        'billingZipCode',
        'billingRegion',
        'billingPostalCode'
    ];

    const alert = useAlert();

    let product;

    const [selected, setSelected] = useState(() => {
        const multipleSelectFields = allFields.filter(field => field.type == 'multiple-select');
        const data = {};

        multipleSelectFields.map(field => {
            if (props.formState) {
                data[field.name] = props.formState[field.name]
            } else {
                data[field.name] = []
            }
        })

        return data;
    });
    const [admin, setAdmin] = useState(false);


    const handleCloseModal = () => setShowMergeModal(false);
    const handleShowModal = () => setShowMergeModal(true);
    const [selectedSubjectIds, setSelectedSubjectIds] = useState([]);
    const badEmailAddresses = props.applicationState.badEmailAddresses;

    const capitalize = (value) => {
        return value && value.replace ? value.replace(/^\w/, c => c.toUpperCase()) : value;
    };

    useEffect(() => {
        const newFormState = {
            ...formState,
            price: props.price
        };

        setFormState(newFormState);
    }, [props.price]);



    const getCustomValidationFields = sections => {
        let customValidationFields = [];
        for (let s = 0; s < sections.length; s++) {
            const fields = get(() => sections[s].fields, []);
            const validationFields = fields.filter(field => field.validation instanceof Object);
            if (validationFields.length) {
                customValidationFields = customValidationFields.concat(validationFields);
            }
        }
        return customValidationFields;
    };
    const customValidationFields = getCustomValidationFields(sections)

    useEffect(() => {
      const formState = getInitialState()
      setFormState(formState)
      setDataState(formState)
      isAdmin()
    }, [props.formState])

    const isAdmin = () => {
      if (permission === "administrator") {
        setAdmin(true)
      } else {
        setAdmin(false)
      }
    }

    const getInitialState = () => {
      const initialFormState = {}
      for (let s = 0; s < sections.length; s++) {
        const fields = get(() => sections[s].fields, [])
        for (let f = 0; f < fields.length; f++) {
          if (fields[f].defaultValue !== undefined && fields[f].defaultValue !== null) {
            //console.log('default value of ', fields[f].name, 'is', fields[f].defaultValue);
            initialFormState[fields[f].name] = fields[f].defaultValue
          } else if (fields[f].type === "checkbox") {
            initialFormState[fields[f].name] = false
          } else if (fields[f].type === "switch") {
            initialFormState[fields[f].name] = false
          } else {
            initialFormState[fields[f].name] = null
          }
        }
      }

      //console.log(Object.assign(initialFormState, props.formState));
      return Object.assign(initialFormState, props.formState)
    }

    const [formState, setFormState] = useState(getInitialState())
    const [dataState, setDataState] = useState(getInitialState())
    const [alertState, setAlertState] = useState({})

    const [cancerProducts, setCancerProducts] = useState([])
    const [questionsData, setQuestionsData] = useState(formState.answers ? formState.answers : [])
    const [productGroup, setProductGroup] = useState(null)
    const [answersData, setAnswersData] = useState(formState.answers ? formState.answers : [])

    const [selectedProduct, setSelectedProduct] = useState(null)

    const getCancerProducts = async () => {
      const verb = "get"
      const uri = "/api/cancer-products"
      const data = await makeApiCall(verb, uri)

      if (data.ok) {
        setCancerProducts(data.body)
      } else {
        alert.show("There are no available products in cancer product group", {
          type: "error",
        })
      }
    }

    const getQuestions = async (testGroup) => {
      const verb = "get"
      const uri = `/api/requisitions-questions/${testGroup}`
      const data = await makeApiCall(verb, uri)

      try {
        if (data.ok) {
          console.log("getquestions", data)
          setQuestionsData(data.body)
        } else {
          alert.show("There are no available questions from requisition configuration.", {
            type: "error",
          })
          setQuestionsData([])
        }
      } catch (e) {
        alert.show(`There was a problem getting questions data: ${e}`, {
          type: "error",
        })
      }
    }

    const getColumns = (section) => {
      const fields = get(() => section.fields, [])
      const numberOfFields = fields.length
      //console.log('section ' + section.label + ' field count is ' + numberOfFields);
      const numberOfColumns = get(() => section.columns, 1)
      const maxFieldsPerColumn = Math.ceil(numberOfFields / numberOfColumns)
      //console.log('max fields per column: ' + maxFieldsPerColumn);
      let columnWidth = "6"
      switch (numberOfColumns) {
        case 1:
          columnWidth = "12"
          break
        case 2:
          columnWidth = "6"
          break
        case 3:
          columnWidth = "4"
          break
        default:
          throw new Error("Unhandled column format")
      }

      const columns = []
      for (let fieldIndex = 0; fieldIndex < numberOfFields; fieldIndex++) {
        const columnIndex = Math.floor(fieldIndex / maxFieldsPerColumn)
        //console.log(columnIndex, fieldIndex);
        if (columnIndex >= columns.length) {
          const column = {
            width: columnWidth,
            fields: [],
          }
          columns.push(column)
        }
        columns[columnIndex].fields.push(fields[fieldIndex])
      }

      return columns
    }

    const getOptions = (field, noEmpty) => {
      const empty = noEmpty
        ? []
        : [
            {
              key: "",
              value: "",
            },
          ]
      if (!field || !field.source || !field.source.kind || !field.source.data) {
        return empty
      }

      if (field.source.kind === "direct") {
        const directResult = empty.concat(field.source.data)
        return directResult
      }

      if (field.source.kind === "lookup") {
        const lookupResult = empty.concat(props.lookups.filter((s) => s.type === field.source.data))
        return lookupResult
      }

      if (field.source.kind === "prop" || field.source.kind === "props") {
        const lookupResult = empty.concat(props[field.source.data])
        return lookupResult
      }

      if (field.source.kind === "request") {
        const data = props.formSource.find((x) => x.name == field.name).data
        return data.map((config) => ({
          key: config.id,
          value: config.name,
        }))
      }

      if (field.source.kind === "extrasConfig") {
        const data = props.formSource.find((x) => x.name == field.name).data
        return data.map((config) => ({
          key: config.id,
          value: config.extraName,
        }))
      }

      if (field.source.kind === "list") {
        let countryResult = getAllCountries()
        countryResult = empty.concat(countryResult)
        return countryResult
      }

      return empty
    }


    const getAllCountries = () => {
        const customCountries = [
            { code: 'US', name: 'United States' },
            { code: 'GB', name: 'United Kingdom' },
            { code: 'NL', name: 'Netherlands' },
        ];

        const countryOptions = customCountries.map((country) => ({
            key: country.code,
            value: country.name,
        }));

        for (const code in countries) {
            if (!customCountries.some((c) => c.code === code)) {
                countryOptions.push({
                    key: code,
                    value: countries[code].name,
                });
            }
        }

        return countryOptions
    };

    const getStateForNetest = () => {
        const empty = [
            {
                key: '',
                value: ''
            }
        ];
        if (currentRecord && formState && currentRecord.prefix) {
            const stateName = currentRecord.state;
            const lookupResult = props.lookups.filter(s => s.type === 'state' && (s.key === stateName || s.value === stateName));
            const newFormState = {
                ...formState,
                state: lookupResult[0]?.key
            };
            setFormState(newFormState);
            // return lookupResult;
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        console.log('general form submit');

        if (submitType == 'submit') {
            if (props.submit) {
                setIsSubmitting(true)
                const result = await props.submit(dataState);
                if (result && result.ok) {
                    setIsDisableForm(true);
                }


                setTimeout(() => {
                    setIsSubmitting(false)
                }, 500)
            }
        }


        if (submitType == 'update') {
            if (props.update) {
                setIsSubmitting(true)
                const result = await props.update(dataState);
                if (result.ok) {
                    setIsDisableForm(true);
                    setDataState(dataState)
                    setFormState(dataState)
                    await props.loadCurrentData()
                }

                setTimeout(() => {
                    setIsSubmitting(false)
                }, 500)
            }
        }
    };

    const dateValidate = (newDataState) => {
        if (!newDataState) {
            return;
        }
        for (let fieldName in newDataState) {
            if (newDataState.hasOwnProperty(fieldName)) {
                if (dateFields.find(dateField => dateField.name === fieldName) && newDataState[fieldName]) {
                    console.log('validating date', newDataState[fieldName]);
                    const element = elements.current[fieldName];
                    let valid = false;
                    if (newDataState[fieldName] && newDataState[fieldName].length === 10) {
                        try {
                            const dateTimeString = newDataState[fieldName] + 'T00:00:00Z';
                            const date = new Date(dateTimeString);
                            console.log('date', date);
                            valid = date && date instanceof Date && isFinite(date) ? true : false;
                        } catch { }
                    }
                    console.log('valid', valid);
                    element.setCustomValidity(valid ? '' : 'Invalid date, please use this format: ' + getDateFormatString());
                }
            }
        }
        console.log('new data state', newDataState);
        return newDataState;
    };

    const datetimeValidate = (newDataState) => {
        if (!newDataState) {
            return;
        }
        for (let fieldName in newDataState) {
            if (newDataState.hasOwnProperty(fieldName)) {
                if (datetimeFields.find(datetimeField => datetimeField.name === fieldName) && newDataState[fieldName]) {
                    console.log('validating date time', newDataState[fieldName]);
                    const element = elements.current[fieldName];
                    let valid = false;
                    if (newDataState[fieldName]) {
                        try {
                            const datetimeString = newDataState[fieldName];
                            const datetime = new Date(datetimeString);
                            console.log('datetime', datetime);
                            valid = datetime && datetime instanceof Date && isFinite(datetime) ? true : false;
                        } catch { }
                    }
                    console.log('valid', valid);
                    element?.setCustomValidity(valid ? '' : 'Invalid datetime value');
                }
            }
        }
        console.log('new data state', newDataState);
        return newDataState;
    };

    const customValidate = (newState) => {
        if (customValidationFields.length) {
            //console.log('custom validate');
            for (let i = 0; i < customValidationFields.length; i++) {
                const field = customValidationFields[i];
                //console.log('checking', field.name);
                const rule = get(() => field.validation.rule);
                const message = get(() => field.validation.message);
                if (rule && message) {
                    // TODO more general rule parsing
                    const or = get(() => rule.$or, []);
                    if (!or) {
                        console.error('validation rule not supported', rule);
                    } else {
                        console.log('checking rule', or);
                        let valid = or.length === 0 ? true : false;
                        for (let i = 0; i < or.length; i++) {
                            const ruleItem = or[i];
                            //console.log('rule item', ruleItem);
                            for (let j in ruleItem) {
                                if (ruleItem.hasOwnProperty(j)) {
                                    //console.log('has own property', j);
                                    //console.log('comparing', newState[j], ruleItem[j]);
                                    if (ruleItem[j] instanceof Object) {
                                        //console.log('is an object expression');
                                        //console.log('required', ruleItem[j]);
                                        if (ruleItem[j].required === true && newState[j]) {
                                            //console.log('truthy', newState[j]);
                                            valid = true;
                                        }
                                        if (ruleItem[j].required === false && !newState[j]) {
                                            //console.log('falsey', newState[j]);
                                            valid = true;
                                        }
                                    } else if (newState[j] === ruleItem[j]) {
                                        valid = true;
                                    }
                                }
                            }
                        }
                        //console.log('validity of', field.name, 'is', valid);
                        const element = elements.current[field.name];
                        element.setCustomValidity(valid ? '' : message);
                    }
                } else {
                    console.warn('skipping custom validation because of empty rule or message', field.validation);
                }

            }
        }
    };

    const getDateFormatString = () => {
        const formatObj = new Intl.DateTimeFormat(navigator.language).formatToParts(new Date());

        return formatObj
            .map(obj => {
                switch (obj.type) {
                    case "day":
                        return "DD";
                    case "month":
                        return "MM";
                    case "year":
                        return "YYYY";
                    default:
                        return obj.value;
                }
            })
            .join("");
    };

    const dateValue = (rawValue) => {
        if (!rawValue) {
            return rawValue;
        }
        console.log(rawValue);
        const usaDateFormatRegex = /^([\d]{2})\/([\d]{2})\/([\d]{4})$/;
        const results = rawValue.match(usaDateFormatRegex);
        if (results) {
            const dateFormatString = getDateFormatString();
            switch (dateFormatString) {
                case 'MM/DD/YYYY':
                    return `${results[3]}-${results[1]}-${results[2]}`;
                case 'DD/MM/YYYY':
                    return `${results[3]}-${results[2]}-${results[1]}`;
                default:
                    return rawValue;
            }
        }
        return rawValue;
    };

    const datetimeValue = (rawValue) => {
        if (!rawValue) {
            return rawValue;
        }
        try {
            const value = new Date(rawValue);
            const result = value.toISOString();
            return result;
        } catch (e) {
            console.error('Could not parse datetime', e);
        }

        return rawValue;
    };

    const dataStateFromFormState = (newFormState) => {
        let newDataState = JSON.parse(JSON.stringify(newFormState));
        for (let fieldName in newDataState) {
            if (newDataState.hasOwnProperty(fieldName)) {
                if (dateFields.find(dateField => dateField.name === fieldName)) {
                    newDataState[fieldName] = dateValue(newDataState[fieldName]);
                }
                if (datetimeFields.find(datetimeField => datetimeField.name === fieldName)) {
                    newDataState[fieldName] = datetimeValue(newDataState[fieldName]);
                }
                if (newDataState[fieldName] === 'true') {
                    newDataState[fieldName] = true;
                }
                if (newDataState[fieldName] === 'false') {
                    newDataState[fieldName] = false;
                }
                if (newDataState[fieldName] === 'null') {
                    newDataState[fieldName] = null;
                }
            }
        }
        //console.log('new data state', newDataState);
        return newDataState;
    };

    const validateEmailChange = useRef(
        debounce(async (e, field) => {
            const email = e.target.value;
            const badEmails = props.applicationState.badEmailAddresses;
            const invalid = isInvalidEmailAddress(email);
            if (invalid) {
                e.target.className += " invalid-input"
                return false;
            } else {
                e.target.classList.remove("invalid-input")
                return true;
            }
        }, 300)
    ).current;

    const isInvalidEmailAddress = (email) => {
        if (email && badEmailAddresses && badEmailAddresses.length) {
            const matches = matcher([email], badEmailAddresses);
            return matches.length;
        }
    }

    const validateDateChange = (e, field) => {
        if (e.target.value) {
            const input = moment(e.target.value);
            const covidStarted = moment("1/1/2020");

            const rules = [
                {
                    field: "dateOfBirth",
                    value: input.isBetween(moment().subtract(130, 'y'), moment()),
                    // not in the future, max 130 years in the past.
                },
                {
                    field: "sampleCollectionDateTime",
                    value: input.isBetween(moment().subtract(7, 'd'), moment()),
                    // not in the future, max 7 days in the past.
                },
                {
                    field: "dateOfSymptomOnset",
                    value: input.isBetween(covidStarted, moment()),
                    // not in the future, not before 1/1/2020
                },
                {
                    field: "vaccinationDate",
                    value: input.isBetween(covidStarted, moment()),
                    // not in the future, not before 1/1/2020
                },
                {
                    field: "creditCardExpiry",
                    value: !moment(input).isBefore(moment(), "day"),
                    // must be today or in the future
                },
            ];

            const rule = rules.find(rule => rule.field == field.name);
            if (rule) {
                const element = elements.current[field.name];
                if (!rule?.value) {
                    element.setCustomValidity(`Invalid ${field.label.toLowerCase()}.`);
                    return false;
                } else {
                    element.setCustomValidity('');
                    return true;
                }
            }
        }
    }

    const validateChange = (e, field) => {
        let valid = true;

        // validate email with list of bad emails
        if (field.type === 'email') {
            valid = validateEmailChange(e, field);
        }

        if (_.includes(['date', 'datetime-local'], field.type)) {
            valid = validateDateChange(e, field)
        }

        return valid;
    }


    const handleChange = (e, field, checked) => {
        // console.log('e', e);
        console.log('field', field);
        const target = e.target;
        const name = target.name;
        let newFormState = {};
        let value;

        if (field.type === 'qrcode') {
            e.preventDefault();
            return;
        }

        value = target.type === 'checkbox' ? target.checked
            : (target.type === 'number' && isFinite(Number(target.value)) ? Number(target.value) : target.value);

        if (target.type === 'radio') {
            if (target.name === 'testingPurpose' && value === 'purposeOther') {
                console.log('field other')
                setOthersDisabled(false);
            } else {
                console.log('field another')
                setOthersDisabled(true);
            }
        }

        if (target.type === 'switch') {
            value = target.value === true ? 'active' : 'inactive';
        };

        if (field.name === 'country') {
            if (value !== 'US') {
                newFormState = {
                    ...formState,
                    [name]: value,
                    state: null,
                    zipCode: null,
                };
            } else {
                newFormState = {
                    ...formState,
                    [name]: value,
                    region: null,
                    postalCode: null,
                };
            }
        } else {
            newFormState = {
                ...formState,
                [name]: value
            };
        }


        setFormState(newFormState);
        props.fieldsOnChange && props.fieldsOnChange(newFormState)
        const newDataState = dataStateFromFormState(newFormState);
        setDataState(newDataState);
        dateValidate(newDataState);
        datetimeValidate(newDataState);
        customValidate(newDataState);
        validateChange(e, field);
        if (props.change) {
            props.change();
        }
    };

    const validateDisabledAddress = (field) => {
        if (props?.isCovid) {
            return false;
        }

        const includedCountry = topCountries.includes(formState.country) || false;
        if (formState.country && formState.country === "US" && (field.name === 'state' || field.name === 'zipCode')) {
            return false;
        }

        if (formState.country && formState.country !== "US" && (field.name === 'region' || field.name === 'postalCode')) {
            return false;
        }

        if (formState.billingCountry && formState.billingCountry === "US" && (field.name === 'billingState' || field.name === 'billingZipCode')) {
            return false;
        }

        if (formState.billingCountry && formState.billingCountry !== "US" && (field.name === 'billingRegion' || field.name === 'billingPostalCode')) {
            return false;
        }

        return true;
    };

    const selectHandleChange = (e, field, answer) => {
        const target = e.target;
        let name = target.name;
        let value = target.value;
        let newState = {};
        let answers = {};
        console.log('product name', name);
        console.log('product value', value);
        console.log('product target', field);

        setSelectedProduct(name);
        setProductGroup(name);
        getQuestions('cancer');

        if (!answer) {
            if ((target.type === 'checkbox' || target.type === 'radio') && target.checked) {
                if ((name.includes('ppq') || name.includes('PPQ'))) {
                    newState = {
                        ...formState,
                        isPPQ: true
                    };
                } else {
                    newState = {
                        ...formState,
                        isPPQ: false
                    };
                }

                value = target.type === 'checkbox' ? target.checked
                    : (target.type === 'number' && isFinite(Number(target.value)) ? Number(target.value) : target.value);
            }

            newState = {
                ...formState,
                [name]: value
            }
            setFormState(newState);
        } else {
            answers[name] = value;

            setAnswersData(prevState => ({
                ...prevState,
                [name]: value
            }))

            newState = {
                ...formState,
                answers: answersData
            }
            setFormState(newState);
        }

        // if (fieldIndex != null) {
        //     newAnswers[sectionIndex]['answers'][fieldIndex][name] = value;
        // } else {
        //     newAnswers[sectionIndex][name] = value;
        // }
        // setAnswers(newAnswers);

        // newState = {
        //     ...formState,
        //     [name]: value
        // }
        // setFormState(newState);

        const newDataState = dataStateFromFormState(newState);
        setDataState(newDataState);
    };



    const evaluate = (rule, nullValue) => {
        if (rule === undefined || rule === null) {
            return nullValue || false;
        }
        //console.log(rule);
        if (!(rule instanceof Object)) {
            //console.log('rule direct');
            return rule;
        }
        for (const property in rule) {
            if (rule.hasOwnProperty(property)) {
                if (rule[property] instanceof Object) {
                    if (rule[property].empty === true && formState[property]) {
                        console.log('rule object empty false');
                        return false;
                    }
                    if (rule[property].empty === false && !formState[property]) {
                        console.log('rule object not empty false');
                        return false;
                    }
                } else if (formState[property] !== rule[property]) {
                    console.log('rule value false');
                    return false;
                }
            }
        }
        console.log('rule true');
        return true;
    };

    const qrCodeSuccess = (field, value) => {
        // console.log('qr code success');
        // console.log(field);
        // console.log(value);
        if (formState[field.name] !== value) {
            setFormState({
                ...formState,
                [field.name]: value
            });
        }
    };

    const qrCodeError = (field, error) => {
        // console.log('qr code error ');
        // console.log(field);
        // console.log(error);
        const message = error && error instanceof Object ? error.constructor.name : error;
        console.log(message);
        if (alertState[field.name] !== message) {
            setAlertState({
                ...alertState,
                [field.name]: message
            });
        }
    };

    const customLabel = label => {
        if (!label) {
            return label;
        }
        const regex = /\{(.+?)\}/;
        const match = label.match(regex);
        if (!match) {
            return label;
        }
        console.log(match);
        const value = props.customFunctions[match[1]](label, formState);
        return value;
    };

    const getLabel = (field) => {
        const baseStyle = field.type === 'checkbox' ? { marginLeft: '2rem' } : { display: 'block' };
        const style = Object.assign(baseStyle, field.labelStyle);
        if (field.labelHtml) {
            const html = customLabel(field.labelHtml);
            return <Label for={field.name}
                className={field.labelClassName}
                style={style}
                dangerouslySetInnerHTML={{ __html: html }}
            />
        } else {
            const label = customLabel(field.label);
            return <Label for={field.name}
                className={field.labelClassName}
                style={style}>{(label ? label : '') + (evaluate(field.required, false) ? ' *' : '')}</Label>;
        }
    };

    const download = async (field) => {
        const res = await makeApiDownloadCall('get', field.format, formState, 'application/pdf', formState[field.name] + '.pdf');
        if (!res.ok) {
            alert.show("PDF not yet available", {
                type: 'error'
            });
        }
    };

    const viewResult = (field) => {
        setViewTestResult(true);
    };

    const closeResult = () => {
        setViewTestResult(false);
    };

    const formatValue = (format, value, name) => {
      if (name == 'patientInsured') {
        // convert boolean to string
        return value.toString();
      }
      
        if (value) {
            if (value === null || value === undefined) {
                return '';
            }

            if (format === 'toLocalizedDateString') {
                // Convert the timestamp to the format "yyyy-MM-dd"
                const date = new Date(value).toLocaleDateString('en-US', { timeZone: 'America/New_York' });
                const [month, day, year] = date.split('/').map(Number);
                const formattedDate = `${String(month).padStart(2, '0')}/${String(day).padStart(2, '0')}/${year}`;

                return formattedDate
            } else if (format === 'toDateString') {
                const date = new Date(value);
                const year = date.getFullYear();
                const month = String(date.getMonth() + 1).padStart(2, '');
                const day = String(date.getDate()).padStart(2, '');

                return `${month}/${day}/${year}`;
            }

            if (format === 'capitalized') {
                return capitalize(value);
            }
            if (format === 'boolean') {
                return value === true ? 'Yes' : (value === false ? 'No' : 'Unset');
            }

            if (format === 'allCaps') {
                return value.toUpperCase()
            }

            return value;
        }
    };

    const selectSubject = (data) => {
        if (selectedSubjectIds.includes(data.id)) {
            setSelectedSubjectIds(selectedSubjectIds.filter(item => item != data.id))
        } else {
            setSelectedSubjectIds(prevState => [data.id, ...prevState]);
        }
    }

    const handleMergeSubjects = async () => {
        setIsMerging(true)

        const result = await makeApiCall('post', '/api/subjects/merge', {
            mainSubjectId: formState.id,
            selectedSubjectIds: selectedSubjectIds,
        });

        setTimeout(() => {
            setIsMerging(false)
        }, 500)

        if (result.ok) {
            // props.close();
            setIsDisableForm(true);
            window.scrollTo(0, 0);
            props.setAlertState('good');
        } else {
            props.setAlertState('bad');
            window.scrollTo(0, document.body.scrollHeight);
        }


        if (props.setExistingSubjects()) {
            props.setExistingSubjects([]);
        }
        handleCloseModal();
    }



    useEffect(() => {
        getStateForNetest();
        getCancerProducts();
        console.log('questionsData', questionsData);
        console.log('answersData', answersData);
    }, [currentRecord, questionsData]);

    return (
        <div>
            {props.isLoading &&
                <center className="mt-5">
                    <ReactLoading type='spin' color='red' height={667} width={100} />
                </center>
            }

            {!props.isLoading &&
                <Form className="GeneralForm" onSubmit={onSubmit} style={props.style}>
                    {props.formDefinition?.formLabel ? <Container>
                        <Row className="QR-code">
                            <Col>
                                <h2>{props.formDefinition.formLabel}</h2>
                                <h3>{props.id}</h3>
                            </Col>
                        </Row>
                    </Container> : null}
                    {sections.map((section, sectionIndex) => {
                        console.log('formstate is', formState);
                        if (evaluate(section.visible, true)) {
                            return <Container key={'section-' + sectionIndex}>
                                {section.label && !section.label.includes('questions') ?
                                    <Row className="section-header">
                                        <Col>{section.label}</Col>
                                    </Row>
                                    : null
                                }
                                <Row>
                                    {section.type === 'table' ? <GeneralTable
                                        style={{ marginTop: '30px' }}
                                        table={section.table}
                                        data={getValueByDotNotation(formState, section.name)}
                                        firstRowNumber={1}
                                        rowActions={props.formRowActions}
                                        currentRecord={currentRecord}
                                    /> : getColumns(section).map((column, columnIndex) =>
                                        <Col sm={column.width} key={'column-' + columnIndex}>
                                            {column.fields.map(field => {
                                                if (evaluate(field.visible, true) && field.name !== "subjectId") {
                                                    return <FormGroup key={field.name} style={field.groupStyle}>
                                                        {field.type !== 'checkbox' && field.type !== 'radio' && field.type !== 'button' ?
                                                            <Label style={{ display: 'inline' }}
                                                                for={field.name}>{field.label + (field.required ? ' *' : '')} </Label> : null}
                                                        {field.type === 'textarea' ? <Input
                                                            type={field.type}
                                                            name={field.name}
                                                            id={field.name}
                                                            disabled={(isEditForm && field.nonEditable) || isDisableForm}
                                                            readOnly={field.readOnly}
                                                            required={field.required}
                                                            onChange={(e) => handleChange(e, field)}
                                                            style={Object.assign({ display: 'block' }, field.style)}
                                                            placeholder={field.placeholder}
                                                            rows={field.rows}
                                                            cols={field.cols}
                                                            wrap={field.wrap}
                                                            value={getValueByDotNotation(formState, field.name)}
                                                            maxLength={field.maxlength}
                                                        /> : null}

                                                        {field.type === 'timestamp' || (field.type === 'datetime-local' && (evaluate(field.readonly, false) === true)) ?
                                                            <Input
                                                                type={'text'}
                                                                name={field.name}
                                                                id={field.name}
                                                                value={formatValue(field.format, getValueByDotNotation(formState, field.name)) || ''}
                                                                checked={field.type === 'checkbox' ? getValueByDotNotation(formState, field.name) || false : null}
                                                                disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}
                                                                readOnly={true} //evaluate(field.readonly, false)}
                                                                required={evaluate(field.required, false)}
                                                                pattern={field.pattern}
                                                                title={field.title}
                                                                //onChange={(e) => handleChange(e, field)}
                                                                style={field.style}
                                                                placeholder={field.placeholder}
                                                                className={field.className}
                                                                max={field.max}
                                                                min={field.min}
                                                                maxLength={field.maxlength}
                                                            >
                                                            </Input> : null}

                                                        {field.type == 'multiple-select' && <div
                                                            style={field.type === 'multiple-select' ? Object.assign({ marginLeft: 0 }, field.style) : field.style}>
                                                            <MultiSelect
                                                                disableSearch
                                                                defaultIsOpen={false}
                                                                overrideStrings={{
                                                                    allItemsAreSelected: selected ? _.join(_.map(selected[field.name], 'label'), ', ') : []
                                                                }}
                                                                options={() => {
                                                                    const options = getOptions(field);
                                                                    const customOptions = []
                                                                    options.map(option => {
                                                                        if (option.key && option.value) {
                                                                            customOptions.push({
                                                                                label: option.value, value: option.key,
                                                                            });
                                                                        }
                                                                    });

                                                                    return customOptions;
                                                                }}
                                                                value={selected[field.name] ?? []}
                                                                disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                                onChange={(e) => {
                                                                    setSelected(prevState => ({
                                                                        ...prevState, [field.name]: e
                                                                    }))
                                                                    e = {
                                                                        target: {
                                                                            value: e, type: 'multiple-select', name: field.name,
                                                                        }
                                                                    }
                                                                    handleChange(e, field)
                                                                }}
                                                                labelledBy="Select"
                                                                hasSelectAll={false}
                                                            />
                                                        </div>}

                                                        {field.type === 'text' && field.name === 'purposeOtherDescription' ?
                                                            <Input
                                                                className={'text'}
                                                                type={field.type}
                                                                name={field.name}
                                                                id={field.name}
                                                                value={getValueByDotNotation(formState, field.name)}
                                                                disabled={othersDisabled}
                                                                required={field.required}
                                                                pattern={field.pattern}
                                                                title={field.title}
                                                                onChange={(e) => {
                                                                    e.persist();
                                                                    handleChange(e, field)
                                                                }}
                                                                innerRef={(element) => elements.current[field.name] = element}
                                                                style={field.style}
                                                                placeholder={field.placeholder}
                                                                maxLength={field.maxlength}
                                                            /> : null}

                                                        {field.type === 'input-dropdown' ?
                                                            <div className="input-group" style={field.style}>
                                                                <Input
                                                                    type={'text'}
                                                                    name={field.name}
                                                                    id={field.name}
                                                                    value={formatValue(field.format, getValueByDotNotation(formState, field.name)) || ''}
                                                                    checked={field.type === 'checkbox' ? getValueByDotNotation(formState, field.name) || false : null}
                                                                    disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}
                                                                    readOnly={evaluate(field.readonly, false)}
                                                                    required={evaluate(field.required, false)}
                                                                    pattern={field.pattern}
                                                                    title={field.title}
                                                                    onChange={(e) => handleChange(e, field)}
                                                                    style={field.style}
                                                                    placeholder={field.placeholder}
                                                                    className={field.className}
                                                                    max={field.max}
                                                                    min={field.min}
                                                                    maxLength={field.maxlength}
                                                                />
                                                                <div className="input-group-append">
                                                                    <UncontrolledDropdown inNavbar>
                                                                        <DropdownToggle outline={true} split={true} caret
                                                                            disabled={(isEditForm && field.nonEditable) || isDisableForm || evaluate(field.disabled, false)}>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            {getOptions(field).map(item => <DropdownItem
                                                                                name={field.name}
                                                                                onClick={(e) => handleChange(e, field)}
                                                                                key={item.key}
                                                                                value={item.value}>{item.value || item.key}</DropdownItem>)}
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                </div>
                                                            </div>

                                                            : null}

                                                        {field.type !== 'input-dropdown' && field.type !== 'multiple-select' && field.type !== 'textarea' && field.type !== 'label' && field.type !== 'radio'
                                                            && field.type !== 'timestamp' && field.type !== 'button'
                                                            && (field.type !== 'datetime-local' || (field.type === 'datetime-local'
                                                                && evaluate(field.readonly, false) === false)) && (field.name !== 'purposeOtherDescription')
                                                            && field.type !== 'modal' && field.type !== 'product' && field.type !== 'questions' ?
                                                            <span>
                                                                <Input
                                                                    className={field.type === 'email' && isInvalidEmailAddress(getValueByDotNotation(formState, field.name)) ? 'invalid-input' : ''}
                                                                    type={field.type}
                                                                    name={field.name}
                                                                    id={field.name}
                                                                    value={field.type === 'checkbox' ? '' : (getValueByDotNotation(formState, field.name) === null || getValueByDotNotation(formState, field.name) === undefined ? '' : (field.format != 'toLocalizedDateString' ? formatValue(field.format, getValueByDotNotation(formState, field.name), field.name) : moment(getValueByDotNotation(formState, field.name)).format("YYYY-MM-DDTHH:mm") ?? ''))}
                                                                    checked={field.type === 'checkbox' ? getValueByDotNotation(formState, field.name) : false}
                                                                    disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm || props.formState && field.name === 'id' || (addressField.includes(field.name) ? validateDisabledAddress(field) : evaluate(field.disabled, false))}
                                                                    required={field.required}
                                                                    pattern={field.pattern}
                                                                    title={field.title}
                                                                    onChange={(e) => {
                                                                        e.persist();
                                                                        handleChange(e, field)
                                                                    }}
                                                                    max={field.max}
                                                                    min={field.min}
                                                                    innerRef={(element) => elements.current[field.name] = element}
                                                                    style={field.type === 'checkbox' ? Object.assign({ marginLeft: 0 }, field.style) : field.style}
                                                                    placeholder={field.placeholder}
                                                                    maxLength={field.maxlength}
                                                                >
                                                                    {field.type === 'select' ? getOptions(field).map(item =>
                                                                        <option key={item.key}
                                                                            value={item.key}>{item.value || item.key}</option>) : null}
                                                                </Input>
                                                                {field.notes && <span className="small">{field.notes}</span>}
                                                            </span>

                                                            : null}
                                                        {field.type === 'checkbox' ? <Label for={field.name}
                                                            disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                            style={field.labelStyle ? field.labelStyle : { marginLeft: '2rem', display: "inline", marginBottom: '2rem' }}>{field.label + (field.required ? ' *' : '')}</Label> : null}
                                                        {field.type === 'radio' ? getOptions(field).map(item => item.key !== '' ?
                                                            <span key={item.key}
                                                                style={field.itemStyle && field.itemStyle.span}>
                                                                <Input type="radio"
                                                                    name={field.name}
                                                                    id={field.name + '-' + item.key}
                                                                    value={item.key}
                                                                    checked={item.key === getValueByDotNotation(formState, field.name)}
                                                                    disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                                    readOnly={true}
                                                                    required={evaluate(field.required, false)}
                                                                    onChange={(e) => handleChange(e, field)}
                                                                    style={Object.assign(Object.assign({
                                                                        marginLeft: '10px', marginTop: '5px'
                                                                    }, field.itemStyle || {}), field.itemStyle.radio || {})}
                                                                    className={field.className}
                                                                />
                                                                <label htmlFor={field.name + ' ' + item.key}
                                                                    style={field.itemStyle && field.itemStyle.label}>{item.value || item.key}</label>
                                                                <br />
                                                            </span> : null) : null}
                                                        {field.type === 'button' ? <Button
                                                            outline color="secondary"
                                                            type="button"
                                                            onClick={() => download(field)}
                                                        >{getLabel(field)}</Button> : null}

                                                        {field.type === 'product' && field.name === 'testTypeName' &&
                                                            <Row>
                                                                {cancerProducts &&
                                                                    cancerProducts.map(products => {
                                                                        product = products.productName;
                                                                        return <>
                                                                            <Col>
                                                                                <Input
                                                                                    type='checkbox'
                                                                                    name={products.id}
                                                                                    id={products.id}
                                                                                    checked={getValueByDotNotation(formState, field.name) ? getValueByDotNotation(formState, field.name) : null}
                                                                                    disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                                                    required={field.required}
                                                                                    title={field.title}
                                                                                    onChange={(e) => {
                                                                                        e.persist();
                                                                                        selectHandleChange(e, field, false)
                                                                                    }}
                                                                                    placeholder={field.placeholder}
                                                                                    style={{ marginLeft: '0' }}
                                                                                    maxLength={field.maxlength}
                                                                                />
                                                                                <Label for={products.id}
                                                                                    disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm}
                                                                                    style={{ marginLeft: '2rem' }}>{`${product} ${(field.required ? ' *' : '')}`}</Label>

                                                                            </Col>
                                                                        </>
                                                                    })}
                                                            </Row>
                                                        }

                                                        {(productGroup || productGroup !== null) && field.type === 'questions' && field.name === 'questionName' ?
                                                            // <Row>
                                                            <>
                                                                {questionsData && questionsData.length > 0 && productGroup !== null ?
                                                                    questionsData.map(el => {
                                                                        return (
                                                                            <>
                                                                                {el.sections && el.sections.map(section => {
                                                                                    return (
                                                                                        <>
                                                                                            <Row className="section-header">
                                                                                                <Col>{section.label}</Col>
                                                                                            </Row>
                                                                                            <Row>
                                                                                                {section.fields && productGroup !== null ?
                                                                                                    section.fields.map(field => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                {field.type !== 'radio' ?
                                                                                                                    <>
                                                                                                                        <Label>{field.label}</Label>
                                                                                                                        <Input
                                                                                                                            type={field.type}
                                                                                                                            name={field.label}
                                                                                                                            id={field.label}
                                                                                                                            // value={field.type === 'checkbox' ? '' : (formState[field.label] === null || formState[field.label] === undefined ? '' : (field.format != 'toLocalizedDateString' ? formatValue(field.format, formState[field.label]) : moment(formState[field.label]).format("YYYY-MM-DDTHH:mm") ?? ''))}
                                                                                                                            value={answersData[field.label] ? answersData[field.label] : ''}
                                                                                                                            checked={field.type === 'checkbox' ? answersData[field.label] : false}
                                                                                                                            disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm || props.formState && field.name === 'id'}
                                                                                                                            required={field.required ? true : false}
                                                                                                                            pattern={field.pattern}
                                                                                                                            title={field.title}
                                                                                                                            onChange={(e) => {
                                                                                                                                e.persist();
                                                                                                                                selectHandleChange(e, field, true)
                                                                                                                            }}
                                                                                                                            max={field.max}
                                                                                                                            min={field.min}
                                                                                                                            style={field.type === 'checkbox' ? Object.assign({ marginLeft: 0 }, field.style) : field.style}
                                                                                                                            placeholder={field.placeholder}
                                                                                                                            maxLength={field.maxLength}
                                                                                                                        />
                                                                                                                    </>
                                                                                                                    : null
                                                                                                                }

                                                                                                                {field.type === 'radio' ?
                                                                                                                    <>
                                                                                                                        <Input
                                                                                                                            type={field.type}
                                                                                                                            name={field.label}
                                                                                                                            id={field.name}
                                                                                                                            value={answersData[field.label] ? answersData[field.label] : ''}
                                                                                                                            checked={field.type === 'checkbox' ? answersData[field.label] : false}
                                                                                                                            disabled={(isEditForm && field.nonEditable) || field.readonly || isDisableForm || props.formState && field.name === 'id'}
                                                                                                                            required={field.required}
                                                                                                                            pattern={field.pattern}
                                                                                                                            title={field.title}
                                                                                                                            onChange={(e) => {
                                                                                                                                e.persist();
                                                                                                                                selectHandleChange(e, field, true)
                                                                                                                            }}
                                                                                                                            max={field.max}
                                                                                                                            min={field.min}
                                                                                                                            style={field.type === 'radio' ? Object.assign({ marginLeft: 0 }, field.style) : field.style}
                                                                                                                            placeholder={field.placeholder}
                                                                                                                            maxLength={field.maxLength}
                                                                                                                        />
                                                                                                                        <Label style={field.type === 'radio' ? Object.assign({ marginLeft: 20 }, field.style) : field.style}>{field.label}</Label>
                                                                                                                    </>
                                                                                                                    : null
                                                                                                                }
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                    : null
                                                                                                }
                                                                                            </Row>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </>
                                                                        )
                                                                    })
                                                                    : <Row><Label>No questions available</Label></Row>
                                                                }
                                                            </>
                                                            // </Row>
                                                            : null}

                                                        {field.type === 'modal' && field.name === 'testResult' ?
                                                            <>
                                                                <Button
                                                                    outline color="secondary"
                                                                    type="button"
                                                                    onClick={() => viewResult(field)}
                                                                >View Test Result</Button>

                                                                {props.isSubjectInfoPage ?
                                                                    <Button className="ml-2"
                                                                        outline color="secondary"
                                                                        type="button"
                                                                        onClick={() => navigate(`/subject-info/${subjectId}/netest-details?subId=${formState.id}&displayMode=inner-form`)}>
                                                                        View Test Result Graphs
                                                                    </Button> : null
                                                                }


                                                                <Row>
                                                                    <Col sm={"12"}>
                                                                        <Modal isOpen={viewTestResult} toggle={closeResult} size="lg">
                                                                            <ModalHeader>Test Result</ModalHeader>
                                                                            <ModalBody>
                                                                                <Row>
                                                                                    <Col sm="12">
                                                                                        {formState[field.name] &&
                                                                                            <table className="table mt-3">
                                                                                                <thead>
                                                                                                    <tr>
                                                                                                        <th>Result</th>
                                                                                                        <th>Value</th>
                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>
                                                                                                    {Object.keys(formState[field.name]).map(key => {
                                                                                                        return (
                                                                                                            <tr key={key}>
                                                                                                                <td>{key}</td>
                                                                                                                <td style={{
                                                                                                                    maxWidth: '400px', // Adjust the width as needed
                                                                                                                    overflow: 'hidden',
                                                                                                                    textOverflow: 'ellipsis',
                                                                                                                    whiteSpace: 'nowrap'
                                                                                                                }}>{formState[field.name][key] === true ? 'True' : formState[field.name][key] === false ? 'False' : formState[field.name][key]}</td>
                                                                                                            </tr>
                                                                                                        )
                                                                                                    })}
                                                                                                </tbody>
                                                                                            </table>
                                                                                        }
                                                                                    </Col>
                                                                                </Row>
                                                                            </ModalBody>
                                                                            <ModalFooter>
                                                                                <Button
                                                                                    type="button"
                                                                                    outline color="primary"
                                                                                    onClick={closeResult}
                                                                                >Close</Button>
                                                                            </ModalFooter>
                                                                        </Modal>
                                                                    </Col>
                                                                </Row>
                                                            </>
                                                            : null
                                                        }
                                                    </FormGroup>
                                                }
                                                else if (field.name === "subjectId") {
                                                    return (
                                                        <FormGroup key={field.name} style={field.groupStyle}>
                                                            <Label style={{ display: 'inline' }} for={field.name}>
                                                                {field.label + (field.required ? ' *' : '')}
                                                            </Label>
                                                            <a
                                                                href={`/subject-info/${formState.subjectId}/personal?id=${formState.subjectId}&displayMode=form&selector+_id=${formState.subjectId}`}
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                style={{
                                                                    textDecoration: 'underline',
                                                                    display: 'block',
                                                                    ...field.style
                                                                }}


                                                            >
                                                                <Input
                                                                    type="text"
                                                                    className='text-secondary'
                                                                    id={field.name}
                                                                    readOnly
                                                                    style={{
                                                                        cursor: 'pointer',
                                                                        color: '#007bff',
                                                                        textDecoration: 'underline',
                                                                        backgroundColor: '#E9ECEF',
                                                                        border: 'none',
                                                                        ...field.style
                                                                    }}
                                                                    placeholder={field.placeholder}
                                                                    value={getValueByDotNotation(formState, "subjectId")}
                                                                    maxLength={field.maxlength}
                                                                    onMouseEnter={e => (e.target.style.cursor = 'pointer')}
                                                                    onChange={e => handleChange(e, field)}
                                                                />
                                                            </a>
                                                        </FormGroup>
                                                    );
                                                }
                                                return null;
                                            }
                                            )
                                            }

                                        </Col>)
                                    }
                                </Row>
                            </Container>
                        }
                    })
                    }

                    <Container className={"general-form-buttons"}>
                        <Row>
                            <Col sm="12">
                                <div className="float-right">
                                    {props.submit && !props.formState && !isDisableForm ? <Button
                                        type="submit"
                                        outline color="primary"
                                        onClick={(e) => {
                                            if (!isSubmitting) {
                                                setSubmitType('submit')
                                            }
                                        }}
                                        style={Object.assign({ marginRight: '10px' }, get(() => props.formDefinition.submit.style, {}))}
                                        disabled={evaluate(props.formDefinition.submit.disabled, false)}
                                    >{isSubmitting ? <ReactLoading type='spin' color='white' height={25}
                                        width={20} /> : 'Submit'}</Button> : null}

                                    {!!props.update && props.formState && !isDisableForm ? <Button
                                        type="submit"
                                        outline color="primary"
                                        onClick={(e) => {
                                            if (!isSubmitting) {
                                                setSubmitType('update')
                                            }

                                        }}
                                        style={Object.assign({ marginRight: '10px' }, get(() => props.formDefinition.update.style, {}))}
                                        disabled={evaluate(props.formDefinition.update.disabled, false)}
                                    >{isSubmitting ? <ReactLoading type='spin' color='white' height={25}
                                        width={20} /> : 'Update'}</Button> : null}
                                    {props.formDefinition?.edit && props.formState && isDisableForm && !formState.taps &&
                                        <Button
                                            type="submit"
                                            outline color="primary"
                                            onClick={() => {
                                                setIsDisableForm(false)
                                                setIsEditForm(true);
                                            }}
                                            style={Object.assign({ marginRight: '10px' }, get(() => props.formDefinition.edit.style, {}))}
                                            disabled={evaluate(props.formDefinition.edit.disabled, false)}>
                                            {get(() => props.formDefinition.edit.label, 'Edit')}
                                        </Button>}
                                    {props.cancel ? <Button
                                        type="button"
                                        outline color="secondary"
                                        style={get(() => props.formDefinition.cancel.style)}
                                        onClick={() => {
                                            if (props.cancel) {
                                                props.cancel();
                                            }
                                        }}>{get(() => props.formDefinition.cancel.label, 'Cancel')}</Button> : null}
                                    {props.findDuplicate && !formState.taps && admin ? <Button
                                        type="button"
                                        outline color="primary"
                                        style={get(() => props.formDefinition.findDuplicate.style)}
                                        onClick={() => {
                                            if (props.findDuplicate) {
                                                props.findDuplicate(formState);
                                            }
                                        }}>{get(() => props.formDefinition.findDuplicate.label, 'Find Duplicate')}</Button> : null}
                                    {!props.cancel && !props.isSubjectInfoPage && <Button
                                        type="button"
                                        outline color="primary"
                                        style={get(() => props.formDefinition.close.style)}
                                        onClick={() => {
                                            if (props.close) {
                                                props.close();
                                            }

                                            if (props.setExistingSubjects) {
                                                props.setExistingSubjects([]);
                                            }
                                            setIsEditForm(false);
                                        }}>
                                        {get(() => props.formDefinition.close.label, 'Close')}
                                    </Button>}

                                </div>
                            </Col>
                        </Row>
                    </Container>
                    {props.existingSubjects && props.existingSubjects.length >= 1 ? <Row>
                        <Col sm="12">
                            <Container className="mt-5 mb-5 pb-5">
                                <Modal isOpen={showMergeModal} toggle={handleCloseModal} size="lg">
                                    <ModalHeader>Subject Details</ModalHeader>
                                    <ModalBody>
                                        Merge selected subject{selectedSubjectIds.length > 1 && 's '} into the current
                                        subject?
                                    </ModalBody>
                                    <ModalFooter>
                                        <Button
                                            type="button"
                                            outline color="danger"
                                            onClick={handleMergeSubjects}>
                                            {isMerging ?
                                                <ReactLoading type='spin' color='red' height={25} width={20} /> : 'OK'}
                                        </Button>
                                        <Button
                                            type="button"
                                            outline color="primary"
                                            onClick={handleCloseModal}
                                        >Close</Button>
                                    </ModalFooter>
                                </Modal>
                                <h4 className="mb-4">Possible Patient Matches</h4>
                                <Table responsive>
                                    <thead>
                                        <tr>
                                            <th>Select</th>
                                            <th>Patient ID</th>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Date of Birth</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>No. of Test</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.existingSubjects.map((record, index) => <tr key={index}
                                            onClick={() => selectSubject(record)}>
                                            <td>
                                                <input
                                                    type="radio"
                                                    value="medium"
                                                    checked={selectedSubjectIds.includes(record.id)}
                                                    onChange={() => selectSubject(record)}
                                                />
                                            </td>
                                            <td>{record.id}</td>
                                            <td>{record.firstName}</td>
                                            <td>{record.lastName}</td>
                                            <td>{record.dateOfBirth ? new Date(record.dateOfBirth).toLocaleString() : ''}</td>
                                            <td>{record.email}</td>
                                            <td>{record.phoneNumber}</td>
                                            <td
                                                className="text-center">{(record.tests?.netestRecords?.all.length || 0) + (record.tests?.testRecords?.all.length || 0)}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>

                                <div className="float-right mt-3 mb-5">
                                    <Button
                                        type="button"
                                        outline color="primary"
                                        style={get(() => props.formDefinition.merge.style)}
                                        disabled={!selectedSubjectIds.length}
                                        onClick={() => {
                                            handleShowModal();
                                        }}>{get(() => props.formDefinition.label, 'Merge')}</Button>
                                    <Button
                                        type="button"
                                        outline color="primary"
                                        style={get(() => props.formDefinition.cancel.style)}
                                        onClick={() => {
                                            props.setExistingSubjects([]);
                                        }}>{get(() => props.formDefinition.cancel.label, 'Cancel')}</Button>
                                </div>
                            </Container>
                        </Col>
                    </Row> : null}
                </Form>
            }
        </div >

    );
}

GeneralForm.propTypes = {
    formDefinition: PropTypes.object.isRequired,
    lookups: PropTypes.array.isRequired,
    formState: PropTypes.object,
    submit: PropTypes.func,
    cancel: PropTypes.func,
    change: PropTypes.func
};

export default GeneralForm;
