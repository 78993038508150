import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useTraysQuery, useTrayUsersQuery } from "api/getters"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
import useDisclosure from "hooks/disclosure"
import { ModalTitle } from "react-bootstrap"
import { makeApiCall, makeApiDownloadCall } from "api/generic-api"
import { useAlert } from "react-alert"
import ReactSelect from "react-select"
import { useAddTray, useReassignTray } from "api/mutators"
dayjs.extend(utc)

export function TraysTable({ customSelect = {}, authentication, pageSize: pageSizeProp, ...rest }) {
  const permission = authentication?.permission
  const alert = useAlert()
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector: {
      ...selector,
      ...customSelect,
    },
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: traysData,
    isLoading,
    isFetching,
    refetch,
  } = useTraysQuery({
    variables: queryVariables,
  })

  const [trayId, setTrayId] = useState(null)
  const { openModal, closeModal, toggleModal, isOpen } = useDisclosure()
  const { openModal: openAddTray, closeModal: closeAddTray, toggleModal: toggleAddTray, isOpen: addTrayOpen } = useDisclosure()
  const [isGenerating, setIsGenerating] = useState(false)
  const [isExporting, setIsExporting] = useState(false)
  const [types, setTypes] = useState([])
  const [owners, setOwners] = useState([])
  const [cancerGroup, setCancerGroup] = useState([])
  const [statuses, setStatuses] = useState([])
  const [newOwner, setNewOwner] = useState("")
  const [testType, setTestType] = useState("")

  const hasRole = (role) => {
    const roles = authentication?.roles
    return roles && roles.length && roles.includes(role)
  }

  const trayUsersData = useTrayUsersQuery({
    variables: {
      sort,
      page: 1,
      selector: {},
      pageSize: 10,
    },
  })

  const addTray = useAddTray({
    onSuccess: () => {
      refetch()
      alert.show("Successfully added", {
        type: "success",
      })
      closeAddTray()
    },
    onError: (err) => {
      alert.show(err?.response?.data?.message || err?.response?.data?.error || err.error, {
        type: "error",
      })
    },
  })

  const reassignTray = useReassignTray({
    variables: {
      id: trayId,
    },
    onSuccess: () => {
      refetch()
      alert.show("Successfully re-assigned", {
        type: "success",
      })
      closeModal()
    },
    onError: (err) => {
      alert.show(err?.response?.data?.message || err?.response?.data?.error || err.error, { type: "error" })
    },
  })

  const generateBarcode = async (_trayId) => {
    if (_trayId) {
      setIsGenerating(true)
      const url = `/api/tray/${_trayId}/barcode`
      const result = await makeApiDownloadCall("get", url, { id: _trayId }, "application/pdf", `tray-barcode-${_trayId}`)
      if (result.ok) {
        alert.show("Successfully generated barcode", {
          type: "success",
        })
      } else {
        alert.show(result.error, {
          type: "error",
        })
      }
      setIsGenerating(false)
    }
  }

  const exportTestsIds = async (_trayId) => {
    if (_trayId) {
      setIsExporting(true)
      const url = `/api/tray/${_trayId}/export`
      const result = await makeApiDownloadCall("get", url, { id: _trayId }, "text/plain", `tray-barcode-${_trayId}`)
      if (result.ok) {
        alert.show("Successfully exported", {
          type: "success",
        })
      } else {
        alert.show(result.error, {
          type: "error",
        })
      }
      setIsExporting(false)
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        accessor: "_id",
        hasSort: true,
      },
      {
        Header: "Tray Type",
        accessor: "trayConfigurationLabel",
        hasSort: true,
      },
      {
        Header: "Test Type",
        accessor: "testType",
        hasSort: true,
      },
      {
        Header: "Capacity",
        accessor: "maxFill",
        hasSort: true,
        isNumeric: true,
      },
      {
        Header: "Owner",
        accessor: "ownerId",
        hasSort: true,
      },
      {
        Header: "Status",
        accessor: "status",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const getProcessedTableData = useCallback(
    (_traysData) =>
      (_traysData &&
        _traysData.map((item) => ({
          ...item,
          _id: item.id,
          action: (permission === "administrator" || hasRole("laboratory") || hasRole("laboratoryDirector")) && (
            <div className="d-flex gap-2 text-nowrap">
              <Link to={`/process-tray/${item.id}`} className="btn btn-outline-primary">
                View
              </Link>
              <Button
                outline
                color="secondary"
                disabled={trayId === item.id && isGenerating}
                onClick={() => {
                  setTrayId(item.id)
                  generateBarcode(item.id)
                }}
              >
                {trayId === item.id && isGenerating && <Spinner size="sm" />} Barcode
              </Button>
              <Button
                outline
                color="secondary"
                disabled={trayId === item.id && isExporting}
                onClick={() => {
                  setTrayId(item.id)
                  exportTestsIds(item.id)
                }}
              >
                {trayId === item.id && isExporting && <Spinner size="sm" />} Export
              </Button>
              {item.status === "Filling" && (
                <Button
                  outline
                  color="danger"
                  disabled={trayId === item.id && reassignTray.isLoading}
                  onClick={() => {
                    setTrayId(item.id)
                    openModal()
                  }}
                >
                  {trayId === item.id && reassignTray.isLoading && <Spinner size="sm" />} Re-assign
                </Button>
              )}
            </div>
          ),
        }))) ||
      [],
    [trayId, isGenerating, isExporting, reassignTray.reassignTray],
  )

  const data = useMemo(() => getProcessedTableData(traysData), [traysData, getProcessedTableData])

  const filters = useMemo(() => {
    return [
      // {
      //   field: "_id",
      //   operator: "$eq",
      //   label: "Search Sample ID",
      //   placeholder: "Type in Sample ID",
      //   removeSpaces: true,
      // },
      // {
      //   field: "subjectId",
      //   operator: "$eq",
      //   label: "Search Patient ID",
      //   placeholder: "Type in Patient ID",
      //   removeSpaces: true,
      // },
      // {
      //   field: "laboratoryReceivedDateTime",
      //   operator: "$gte",
      //   label: "Accession Date",
      //   type: "date",
      // },
      {
        field: "_id",
        operator: "$eq",
        label: "ID",
        placeholder: "Search ID",
      },
      {
        field: "ownerId",
        operator: "$eq",
        label: "Email",
        placeholder: "Search Email",
      },
      {
        field: "trayConfigurationLabel",
        operator: "$eq",
        label: "Tray Type",
        placeholder: "Select Tray Type",
        renderCustom: (filter, value, onChange) => {
          return (
            <div className="input-group">
              <ReactSelect
                isClearable
                classNamePrefix="select2-selection"
                placeholder={filter.placeholder}
                value={types?.find((item) => item.value === value) || null}
                options={types}
                onChange={(_value) => onChange(_value?.value)}
              />
            </div>
          )
        },
      },
      {
        field: "status",
        operator: "$eq",
        label: "Status",
        placeholder: "Select Status",
        renderCustom: (filter, value, onChange) => {
          return (
            <div className="input-group">
              <ReactSelect
                isClearable
                classNamePrefix="select2-selection"
                placeholder={filter.placeholder}
                value={statuses?.find((item) => item.value === value) || null}
                options={statuses}
                onChange={(_value) => onChange(_value?.value)}
              />
            </div>
          )
        },
      },
    ]
  }, [types, statuses])

  const fetchTrayTypes = async () => {
    const trayTypes = await makeApiCall("get", "/api/trayTypes/")
    setTypes(
      trayTypes?.body?.map((trayType) => {
        return { _id: trayType.label, type: "trayType", key: trayType.label, value: trayType.label, label: trayType.label }
      }),
    )
  }

  // const fetchTrayOwners = async () => {
  //   const trayEmails = await makeApiCall("get", "/api/trayEmails/")
  //   setOwners(
  //     trayEmails?.body?.map((trayEmail) => {
  //       return { _id: trayEmail.ownerId, type: "trayEmail", key: trayEmail.ownerId, value: trayEmail.ownerId, label: trayEmail.ownerId }
  //     }),
  //   )
  // }

  const fetchTrayStatuses = async () => {
    const trayStatuses = await makeApiCall("get", "/api/trayStatuses/")
    setStatuses(
      trayStatuses?.body
        ?.map((trayStatus) => {
          return { _id: trayStatus.id, type: "trayStatus", key: trayStatus.id, value: trayStatus.status, label: trayStatus.status }
        })
        ?.filter((item, index, self) => index === self.findIndex((obj) => obj.value === item.value)),
    )
  }

  const getTrayConfiguration = async () => {
    const result = await makeApiCall("get", `/api/test-configuration`)
    setCancerGroup(result.body.filter((item) => item.testGroup === "cancer" && item?.product?.status?.toLowerCase() === "active"))
  }

  const handleAddTray = () => {
    addTray.mutate({
      testType: testType,
    })
  }

  useEffect(() => {
    getTrayConfiguration()
    fetchTrayTypes()
    // fetchTrayOwners()
    fetchTrayStatuses()
  }, [])

  return (
    <div>
      <div className="d-flex justify-content-start mb-3">
        <Button onClick={openAddTray} color="primary">
          Add Cancer Tray
        </Button>
      </div>
      <TableContainer
        downloadFileName="Trays"
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="_id"
        defaultSortDir="desc"
        csvQuery={useTraysQuery}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
      <Modal isOpen={isOpen} toggle={toggleModal} backdrop={true}>
        <ModalHeader toggle={closeModal}>
          <ModalTitle>Re-assign tray owner</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="userId">Select new owner for tray no. {trayId}</Label>
          </FormGroup>
          <FormGroup>
            <Input type="select" name="newOwner" value={newOwner} onChange={(e) => setNewOwner(e.target?.value)}>
              <option value="" disabled>
                Select new owner
              </option>
              {trayUsersData?.data &&
                trayUsersData?.data?.map((userid) => (
                  <option key={userid} value={userid}>
                    {userid}
                  </option>
                ))}
            </Input>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button disabled={reassignTray.isLoading} type="button" color="danger" onClick={() => reassignTray.mutate({ ownerId: newOwner })}>
            {reassignTray.isLoading && <Spinner size="sm" />} Confirm
          </Button>
          <Button disabled={reassignTray.isLoading} type="button" outline color="secondary" onClick={toggleModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={addTrayOpen} toggle={toggleAddTray} backdrop={true}>
        <ModalHeader>
          <ModalTitle>Add New Cancer Tray</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="form-group">
            <select className="form-control" value={testType} onChange={(e) => setTestType(e.target.value)} required>
              <option value="">Select a test type</option>
              {cancerGroup?.map((type) => (
                <option key={type.id} value={type.testType}>
                  {type.name}
                </option>
              ))}
            </select>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleAddTray} disabled={addTray.isLoading}>
            {addTray.isLoading && <Spinner size="sm" />} Confirm
          </Button>
          <Button outline color="secondary" onClick={closeAddTray} disabled={addTray.isLoading}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
