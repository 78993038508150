const prescriptionFormDefinition = {
    formLabel: 'COVID-19 Test Kit Prescription',

    sections: [
        {
            label: 'About you',
            columns: 1,
            fields: [
                {
                    label: 'First Name',
                    name: 'firstName',
                    type: 'text',
                    //pattern: '[a-z]{10}',
                    //title: 'Must be 10',
                    required: true,
                    style: {maxWidth: '400px'},
                    maxlength: 20
                },
                {
                    label: 'Last Name',
                    name: 'lastName',
                    type: 'text',
                    required: true,
                    style: {maxWidth: '400px'},
                    maxlength: 20
                },
                {
                    label: 'Date of Birth',
                    name: 'dateOfBirth',
                    type: 'date',
                    required: true,
                    style: {maxWidth: '250px'},
                    max: "9999-12-31"
                }
            ]
        },
        {
            label: 'Symptoms',
            columns: 1,
            fields: [
                {
                    label: 'Within the last 14 days, have you experienced any of the following symptoms?',
                    name: 'symptomsLabel',
                    type: 'label'
                },
                {
                    label: 'Fever',
                    name: 'symptomFever',
                    type: 'checkbox',
                    style: {maxWidth: '200px'}
                },
                {
                    label: 'Cough',
                    name: 'symptomCough',
                    type: 'checkbox',
                    style: {maxWidth: '200px'}
                },
                {
                    label: 'Difficulty breathing',
                    name: 'symptomDifficultyBreathing',
                    type: 'checkbox',
                    style: {maxWidth: '200px'}
                },
                {
                    label: 'Nasal congestion',
                    name: 'symptomNasalCongestion',
                    type: 'checkbox',
                    style: {maxWidth: '200px'}
                },
                {
                    label: 'I have not experienced any of these symptoms',
                    name: 'symptomNone',
                    type: 'checkbox',
                    style: {maxWidth: '200px'},
                    validation: {
                        rule: {
                            $or: [
                                {
                                    $and: [
                                        {
                                            $or: [
                                                {symptomFever: true},
                                                {symptomCough: true},
                                                {symptomDifficultyBreathing: true},
                                                {symptomNasalCongestion: true}
                                            ]
                                        },
                                        {symptomNone: 'falsy'}
                                    ]
                                },
                                {
                                    $and: [
                                        {symptomFever: 'falsy'},
                                        {symptomCough: 'falsy'},
                                        {symptomDifficultyBreathing: 'falsy'},
                                        {symptomNasalCongestion: 'falsy'},
                                        {symptomNone: true}
                                    ]
                                }
                            ]
                        },
                        message: 'Please indicate one or more symptoms or check no symptoms have been experienced'
                    }
                }
            ]
        },
        {
            label: 'Location',
            columns: 1,
            fields: [
                {
                    label: 'Have you lived or recently travelled to a place where transmission of COVID-19 is known to occur?',
                    type: 'radio',
                    name: 'location',
                    source: {
                        kind: 'direct',
                        data: [
                            {
                                key: 'yes',
                                value: 'Yes'
                            },
                            {
                                key: 'no',
                                value: 'No'
                            },
                            {
                                key: 'unsure',
                                value: 'Not sure'
                            },
                        ]
                    },
                    required: true,
                    style: {maxWidth: '200px'},
                    itemStyle: {
                        span: {display: 'block'},
                        radio: {marginTop: '1px'},
                        label: {marginLeft: '30px'}
                    }
                }
            ]
        },
        {
            label: 'Contact',
            columns: 1,
            fields: [
                {
                    label: 'Have you been in close contact (within the last 14 days) with an individual suspected of or confirmed to have COVID-19?',
                    type: 'radio',
                    name: 'contact',
                    source: {
                        kind: 'direct',
                        data: [
                            {
                                key: 'yes',
                                value: 'Yes'
                            },
                            {
                                key: 'no',
                                value: 'No'
                            }
                        ]
                    },
                    required: true,
                    style: {maxWidth: '200px'},
                    itemStyle: {
                        span: {display: 'block'},
                        radio: {marginTop: '1px'},
                        label: {marginLeft: '30px'}
                    }
                }
            ]
        }
    ]
};

export default prescriptionFormDefinition;
