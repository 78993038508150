import React from "react";
import Breadcrumb from "./custom/Breadcrumb";
import { Card, CardBody } from "reactstrap";
import { InvoiceTable } from "./tables/InvoiceTable";

function InvoiceView(props) {
    const permission = props && props.applicationState.authentication.user.permission;

    const breadcrumbItems = [
        {
            label: "Invoice",
            link: `/invoice-view`,
        },
    ];

    return (
        <div className="wide-container">
            <Breadcrumb title="Invoice" breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardBody>
                    <InvoiceTable
                        permission={permission}
                        allowPageSize
                        allowPaginate
                        isGlobalFilter
                    />
                </CardBody>
            </Card>
        </div>
    );
}

export default InvoiceView;
