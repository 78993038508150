import React from "react";
import axios from 'axios';
import { useQuery, useMutation, useQueryClient, QueryClientProvider, QueryClient } from "react-query"

export const axiosClient = axios.create({
  baseURL: "/api",
})

axiosClient.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 401) {
    //   // handle 401 error here
    // }

    return Promise.reject(error)
  },
)

export function useRequestProcessor() {
  const queryClient = useQueryClient()

  const query = (key, queryFunction, options = {}) => {
    // eslint-disable-next-line
    return useQuery({
      queryKey: key,
      queryFn: queryFunction,
      refetchOnWindowFocus: false,
      ...options,
    })
  }

  const mutate = (key, mutationFunction, options = {}) => {
    // eslint-disable-next-line
    return useMutation({
      mutationKey: key,
      mutationFn: mutationFunction,
      onSettled: () => queryClient.invalidateQueries(key),
      ...options,
    })
  }

  return { query, mutate }
}

const queryClient = new QueryClient({
  queries: {
    onError: (error) => {
      if (error.response?.status === 401) {
        window.location.href = "/login"
      }
    },
  },
})

export const ApiProvider = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {children}
    </QueryClientProvider>
  );
};