import React, { useState, useEffect } from "react";
import {
    Button,
    Label,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    Spinner,
    DropdownMenu,
    DropdownItem,
    Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { makeApiCall } from "api/generic-api";
import { useInsuranceCompanyFormSubmit } from "api/getters";
import { FaTimes } from "react-icons/fa";

const schema = Yup.object().shape({
    companyCode: Yup.string().required("Company Code is required"),
    companyName: Yup.string().required("Company Name is required"),
    productType: Yup.array().min(1, "At least one Supported Product must be selected"),
});

const PriceForm = ({ item }) => {
    const [productConfiguration, setProductConfiguration] = useState([]);
    const [productTypeDropdownOpen, setProductTypeDropdownOpen] = useState(false);
    const [selectedProductTypes, setSelectedProductTypes] = useState([]);
    const [lookups, setLookups] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false);

    const {
        handleSubmit,
        control,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: {
            companyCode: item?.companyCode || "",
            companyName: item?.companyName || "",
            notes: item?.notes || "",
            productType: [],
        },
    });

    const { mutate, isLoading: mutating } = useInsuranceCompanyFormSubmit({
        onSuccess: () => {
            setSuccessMessage("Insurance company has been successfully added");
            setErrorMessage("");
            setIsSubmitted(true);
        },
        onError: (error) => {
            const apiErrorMessage = error?.response?.data?.error;
            console.error("API Error:", apiErrorMessage);
            setErrorMessage(apiErrorMessage || "An unexpected error occurred.");
        },
    });

    const fetchConfigurations = async () => {
        setIsLoading(true);
        try {
            const ProductResult = await makeApiCall("get", "/api/products/");
            setProductConfiguration(ProductResult.body);
            setLookups(
                ProductResult.body.map((product) => ({
                    _id: product.id,
                    type: "product",
                    key: product.id,
                    value: product.productName,
                }))
            );
        } catch (error) {
            const errorMessage = error?.response?.data?.error || "Failed to fetch configurations";
            setErrorMessage(errorMessage);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchConfigurations();
    }, []);

    const toggleDropdown = () => {
        setProductTypeDropdownOpen((prev) => !prev);
    };

    const clearSelectedProductTypes = () => {
        setSelectedProductTypes([]);
        setValue("productType", []);
    };

    const productArray = Array.from(new Set(productConfiguration.map(item => item.productName)));

    const handleProductTypeChange = (value) => {
        setSelectedProductTypes((prev) => {
            const updatedProductTypes = prev.includes(value)
                ? prev.filter((type) => type !== value)
                : [...prev, value];

            setValue("productType", updatedProductTypes);
            clearErrors("productType");
            return updatedProductTypes;
        });
    };

    const onSubmit = (data) => {
        const formValues = {
            id: data.companyCode,
            name: data.companyName,
            notes: data.notes || null,
            productsData: selectedProductTypes.map((type) => ({
                label: type,
                value: type.toLowerCase(),
            })),
        };
        mutate(formValues);
    };

    if (isLoading) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <Spinner size="sm" />
            </div>
        );
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
                {errorMessage && (
                    <Alert color="danger" className="mt-3">
                        {errorMessage}
                    </Alert>
                )}
                {successMessage && (
                    <Alert color="success" className="mt-3">
                        {successMessage}
                    </Alert>
                )}
                <Col className="mb-3" lg="6">
                    <Label className="form-label">Company Code *</Label>
                    <Controller
                        name="companyCode"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${errors.companyCode ? "is-invalid" : ""}`}
                                {...field}
                                disabled={isSubmitted}
                                placeholder="Enter Company Code"
                                onInput={(e) => {
                                    e.target.value = e.target.value.toUpperCase();
                                }}
                            />
                        )}
                    />
                    {errors.companyCode && <div className="text-danger">{errors.companyCode.message}</div>}
                </Col>

                <Col className="mb-3" lg="6">
                    <Label className="form-label">Company Name *</Label>
                    <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                            <input
                                className={`form-control ${errors.companyName ? "is-invalid" : ""}`}
                                {...field}
                                disabled={isSubmitted}
                                placeholder="Enter Company Name"
                            />
                        )}
                    />
                    {errors.companyName && <div className="text-danger">{errors.companyName.message}</div>}
                </Col>
                <Col className="mb-3" lg="15">
                    <Label className="form-label">Notes</Label>
                    <Controller
                        name="notes"
                        control={control}
                        render={({ field }) => (
                            <textarea
                                className="form-control"
                                {...field}
                                disabled={isSubmitted}
                                placeholder="Enter Notes"
                                rows="4"
                            />
                        )}
                    />
                </Col>


                <Col className="mb-3" lg="6">
                    <Label className="form-label">Supported Products *</Label>
                    <Dropdown isOpen={productTypeDropdownOpen} toggle={toggleDropdown}>
                        <DropdownToggle
                            className={`form-control text-start px-3 ${selectedProductTypes.length === 0 && errors.productType ? "is-invalid" : ""}`}
                            style={{
                                backgroundColor: "transparent",
                                border: `1px solid ${selectedProductTypes.length === 0 && errors.productType ? "#dc3545" : "#ccc"}`,
                                color: "#000",
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                borderRadius: "0.25rem",
                                padding: "0.5rem 1rem",
                            }}
                            caret={!errors.productType}
                            disabled={isSubmitted}
                        >
                            <span style={{ flex: "1 1 auto", overflow: "auto", whiteSpace: "nowrap" }}>
                                {selectedProductTypes.length > 0 ? selectedProductTypes.join(", ") : "Select Products Type"}
                            </span>
                            {selectedProductTypes.length > 0 && (
                                <FaTimes
                                    onClick={clearSelectedProductTypes}
                                    style={{
                                        cursor: "pointer",
                                        fontSize: "1rem",
                                        marginLeft: "1rem",
                                        color: "#888",
                                    }}
                                />
                            )}
                        </DropdownToggle>
                        <DropdownMenu className="mt-2">
                            {productArray.map((productType) => (
                                <DropdownItem key={productType} toggle={false} onClick={(e) => e.stopPropagation()}>
                                    <div className="form-check">
                                        <input
                                            type="checkbox"
                                            className="form-check-input"
                                            id={productType}
                                            name="productType"
                                            value={productType}
                                            checked={selectedProductTypes.includes(productType)}
                                            onChange={() => {
                                                handleProductTypeChange(productType);
                                                clearErrors("productType");
                                            }}
                                        />
                                        <label className="form-check-label cursor-pointer" htmlFor={productType}>
                                            {productType}
                                        </label>
                                    </div>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                    {selectedProductTypes.length === 0 && errors.productType && (
                        <div className="mt-1 text-danger" style={{ fontSize: "0.875rem" }}>
                            {errors.productType.message}
                        </div>
                    )}

                </Col>
                <Col className="text-end mt-5">
                    <Button color="primary" type="submit" disabled={isSubmitted}>
                        {mutating ? <Spinner size="sm" /> : "Submit"}
                    </Button>
                </Col>


            </Row>
        </form>
    );
};

export default PriceForm;
