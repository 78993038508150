import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useExtrasConfigurationQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const newVariables = {
    ...variables,
    selector: {
      ...(variables?.selector || {}),
    },
  }

  if (newVariables?.selector?._id?.$eq) {
    newVariables.selector._id = {
      $regex: "(?i)" + newVariables.selector._id.$eq,
    }
  }

  return query(["extrasConfiguration", newVariables], () => axiosClient.get(`/extras?${paramsToQuery(newVariables)}`).then((res) => res.data), { ...rest })
}

export function useExtrasConfigurationInfo({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["ExtrasConfigurationInfoQueries", variables], () => axiosClient.get(`/extras??${paramsToQuery(variables)}`).then((res) => res.data), {
    ...rest,
  })
}
