import React, { useState } from "react"
import { Navigate } from "react-router-dom"
import fetch from "isomorphic-fetch"

import { Form, FormGroup, Label, Input, Container, Row, Col, Button, Alert } from "reactstrap"
import { useAlert } from "react-alert"
import ReactLoading from "react-loading"

const SendPassword = (props) => {
  const alert = useAlert()

  const [state, setState] = useState({
    username: "",
    redirectToReferrer: false,
    passwordResetSentLoginmessage: false,
    login: false,
    confirming: false,
  })

  const postData = (url, data) => {
    return fetch(url, {
      body: data,
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "user-agent": "Mozilla/4.0 MDN Example",
        "content-type": "application/x-www-form-urlencoded",
      },
      method: "POST",
      mode: "cors",
      redirect: "follow",
      referrer: "no-referrer",
    }).then((response) => {
      if (response.status === 200) {
        return response.json()
      } else if (response.status === 201) {
        return true
      }
      return false
    })
  }

  const handleSendPassword = (event) => {
    event.preventDefault()

    setState((prevState) => ({
      ...prevState,
      confirming: true,
    }))

    const userInfoUrlEncoded = encodeURIComponent(state.username)

    postData("/api/user/" + userInfoUrlEncoded + "/password-email").then((result) => {
      console.log("password reset requested")
      console.log(result)
      if (result === false) {
        props.setError("There was a problem sending the email")
        alert.show("There was a problem sending the email", {
          type: "error",
        })
      } else {
        setState((prevState) => ({ ...prevState, passwordResetSentLoginmessage: true }))
        //   alert.show("An email has been sent to you to help you reset your password", {
        //     type: "success",
        //   })
      }

      setState((prevState) => ({
        ...prevState,
        confirming: false,
      }))
    })
  }

  const handleUsernameChange = (e) => {
    const email = e.target?.value
    setState((prevState) => ({
      ...prevState,
      username: email,
      invalidLoginmessage: false,
    }))
  }

  const login = () => {
    setState((prevState) => ({
      ...prevState,
      login: true,
    }))
  }

  if (state.login) {
    return <Navigate to="/" />
  }

  if (state.passwordResetSentLoginmessage) {
    return (
      <div>
        <Container style={{ width: "500px", marginTop: "20px" }}>
          <Row>
            <Col>
              <h1>Reset Password</h1>
              <Alert color="success">An email has been sent to you to help you reset your password</Alert>
              <Button type="button" outline color="secondary" className="float-left" onClick={() => login()}>
                Go to login
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    )
  }

  return (
    <div>
      <Container style={{ width: "500px", marginTop: "20px" }}>
        <Row>
          <Col>
            <h1>Reset Password</h1>
            <Form onSubmit={handleSendPassword}>
              <FormGroup>
                <Label for="loginUsername">Email address</Label>
                <Input
                  type="text"
                  name="username"
                  id="loginUsername"
                  placeholder="your login email address"
                  value={state.username}
                  onChange={handleUsernameChange}
                />
              </FormGroup>
              <Button type="button" outline color="secondary" className="float-left" onClick={() => login()}>
                Login
              </Button>
              <Button type="submit" outline color="primary" className="float-right">
                {state.confirming ? <ReactLoading type="spin" color="blue" height={25} width={15} /> : "Confirm"}
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default SendPassword
