import React, { useEffect, useRef, useState } from 'react';
import lookups from "../configuration/lookups";
import DataWow from "./data-wow";
import { makeApiCall } from "../api/generic-api";
import { Alert, Col, Row } from "reactstrap";
import productsFullDefinition from "../configuration/products-full-definition";
import fetch from "isomorphic-fetch";
import ReactLoading from "react-loading";

function ProductsView(props) {
    const [ isSubjectInfoPage, setIsSubjectInfoPage ] = useState(window.location.pathname.includes('subject-info') || window.location.pathname.includes('order-info'));
    
    const [alertState, setAlertState] = useState(null);
    const [alertBadMessage, setAlertBadMessage] = useState(null);
    const structure = productsFullDefinition;
    
    const [ testConfiguration, setTestConfiguration ] = useState([]);
    const [ extrasConfiguration, setExtrasConfiguration ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    
    useEffect(() => {
        setTimeout(() => {
            setAlertState(null)
            setAlertBadMessage(null)
        }, 6000)
    }, [ alertState ]);
    
    useEffect(() => {
        getConfigurations();
    }, []);

    const userSelector = {
        heading: {
            label: 'Filter',
            style: {display: 'inline-block', fontWeight: 800}
        },
        lookups: lookups,
        controlBlockStyle: {display: 'inline-block'},
        controls: [
            {
                label: 'Product Code',
                name: '_id',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Product Name',
                name: 'productName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            // {
            //     label: 'Test Type',
            //     name: 'data.testTypes',
            //     type: 'select',
            //     style: {maxWidth:'170px'},
            //     groupStyle: {display: 'inline-block', marginLeft: '20px'},
            //     source: {
            //         kind: 'lookup',
            //         data: 'testType'
            //     },
            // },
        ],
        selectorFunction: (selector, controlState) => {
            let resultSelector = JSON.parse(JSON.stringify(selector));
        
            if (controlState['productName']) {
                resultSelector = Object.assign({}, {
                    ...resultSelector,
                    productName: {
                        $regex: "(?i)" +controlState['productName']
                    }
                })
            };
        
            if (controlState['_id']) {
                resultSelector = Object.assign({},   {
                    ...resultSelector,
                    _id: {
                        $regex: "(?i)" +controlState['_id']
                    }
                })
            };
            
            console.log('resultSelector', resultSelector);
            return resultSelector;
        }
    };

    const updateProduct = async (formState) => {
        const testTypeData = formState.testTypeData;
        if (!testTypeData?.length) {
            setAlertState('bad');
            setAlertBadMessage('Test type is required')
            return;
        }

        const result = await makeApiCall('put', `/api/product`, formState);
        if (result.ok) {
            setAlertState('updated');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }

        return result;
    };
    
    const getConfigurations = async () => {
        setLoading(true)
        const result = await makeApiCall('get', `/api/test-configuration`);
        setTestConfiguration(result.body)

        const extrasResult = await makeApiCall('get', `/api/extras-products`);
        setExtrasConfiguration(extrasResult.body)
        setLoading(false);
    };

    const addProduct = async (formState) => {
        const testTypeData = formState.testTypeData;
        if (!testTypeData?.length) {
            setAlertState('bad');
            setAlertBadMessage('Test type is required')
            return;
        }

        const result = await makeApiCall('post', `/api/product`, formState);
        if (result.ok) {
            setAlertState('added');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }

        return result;
    };

    const customRowActions = [
        {
            type: 'button',
            name: 'delete',
            label: 'Delete',
            color: 'secondary',
            action: 'delete',
            details: {
                endpoint: 'delete',
                idToken: ':id'
            }
        },
      ]

    return (
        <div>
            <Row style={{marginTop: '30px'}}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>Products</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>
    
            { loading &&
              <center className="mt-5">
                  <ReactLoading type='spin' color='red' height={667} width={100} />
              </center>
            }
            { !loading && testConfiguration.length &&
              <DataWow
                className={'wide-container'}
                structure={structure}
                userSelector={userSelector}
                loadData={makeApiCall}
                lookups={lookups}
                update={updateProduct}
                customRowActions={customRowActions}
                setAlertState={setAlertState}
                submit={addProduct}
                showAddButton={true}
                formSource = {
                  [
                      {
                          name: 'testTypeData',
                          data: testConfiguration
                      },
                      {
                          name: 'extrasConfiguration',
                          data: extrasConfiguration
                      }
                  ]
                }
                isSubjectInfoPage={isSubjectInfoPage}
                {...props} />
            }
            

            { alertState === 'deleted' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Product has been successfully deleted'}
              </Alert>
              : null
            }

            { alertState === 'added' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Product has been successfully added.'}
              </Alert>
              : null
            }

            { alertState === 'updated' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                  {'Product has been successfully updated.'}
              </Alert>
              : null
            }

            { alertState === 'bad' ?
              <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                  {'There was a problem submitting your data.'}
                  <br/>
                  { alertBadMessage || 'Please try again or contact us.'}
              </Alert>
              : null
            }
        </div>
    );
}

export default ProductsView;
