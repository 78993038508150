import React, { useMemo, useState, useEffect, useCallback } from "react"
import axios from "axios"
import { useProductList } from "api/getters"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"
import { Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"

dayjs.extend(utc)

export function ProductTable({ pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector,
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: productData,
    isLoading,
    isFetching,
    refetch,
  } = useProductList({
    variables: queryVariables,
  })

  const [alertState, setAlertState] = useState(null)
  const [alertMessage, setAlertMessage] = useState("")
  const [modalOpen, setModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)

  useEffect(() => {
    let timer
    if (alertState) {
      timer = setTimeout(() => {
        setAlertState(null)
        setAlertMessage("")
      }, 2000)
    }
    return () => clearTimeout(timer)
  }, [alertState])

  const columns = useMemo(
    () => [
      {
        Header: "Product Code",
        accessor: "id",
      },
      {
        Header: "Product Name",
        accessor: "productName",
      },
      {
        Header: "Test Types",
        accessor: "testTypes",
      },
      {
        Header: "Product Group",
        accessor: "productGroup",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const handleDelete = async () => {
    try {
      const encodedItemId = encodeURIComponent(itemToDelete)
      await axios.delete(`/api/product/${encodedItemId}`)
      setAlertState("success")
      setAlertMessage("Product has been successfully deleted")
      refetch()
      setModalOpen(false)
      setItemToDelete(null)
    } catch (error) {
      setAlertState("error")
      setAlertMessage("There was a problem deleting the product. Please try again or contact us.")
    }
  }

  const handleDeleteClick = (itemId) => {
    setItemToDelete(itemId)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setItemToDelete(null)
  }

  const getProcessedTableData = useCallback(
    (_productData) =>
      (_productData &&
        _productData.map((item) => ({
          id: item?.id,
          productName: item?.productName,
          testTypes: item?.testTypes,
          productGroup: item?.productGroup,
          status: item?.status,
          action: (
            <div className="d-flex gap-2">
              <Link to={`/products-view/${item.id}`} className="btn btn-outline-primary">
                View
              </Link>
              <Button outline color="danger" onClick={() => handleDeleteClick(item.id)}>
                Delete
              </Button>
            </div>
          ),
        }))) ||
      [],
    [],
  )

  const data = useMemo(() => getProcessedTableData(productData), [productData, getProcessedTableData])

  const filters = useMemo(
    () => [
      {
        field: "_id",
        label: "Search Product Code",
        placeholder: "Type in Product Code",
      },
      {
        field: "productName",
        label: "Search Product Name",
        placeholder: "Type in Product Name",
      },
    ],
    [],
  )

  return (
    <div>
      {alertState === "success" && (
        <Alert style={{ marginTop: "10px", textAlign: "center" }} color="success">
          {alertMessage}
        </Alert>
      )}
      {alertState === "error" && (
        <Alert style={{ marginTop: "10px", textAlign: "center" }} color="danger">
          {alertMessage}
        </Alert>
      )}
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        filters={filters}
        isLoading={isLoading || isFetching}
        selector={selector}
        showAdd="/products-view-form"
        sort={sort}
        downloadFileName="Products"
        csvQuery={useProductList}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
      <Modal isOpen={modalOpen} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to remove {itemToDelete}?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            OK
          </Button>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
