import React, { useCallback, useEffect, useState } from "react"
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom"
import { Helmet } from "react-helmet"
import NavRouting from "./nav-routing"
import fetch from "isomorphic-fetch"
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Spinner } from "reactstrap"
import lookups from "../configuration/lookups"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"
import TwoFactorAuthView from "./two-factor-auth/two-factor-auth-view"
import Logout from "./logout"
import ReactLoading from "react-loading"
import TableQuery from "./tables/TableQuery"

const App = (props) => {
  const [intervalId, setIntervalId] = useState(null)
  const [state, setState] = useState({
    authentication: {
      isAuthenticated: false,
      user: {},
    },
    title: "TAPS",
    error: null,
    features: [],
    userMode: "",
    serviceName: "",
    hasLookups: false,
    lookups: [],
    timezone: "",
    loggedInCheckSeconds: 60,
    twoFactorAuthentication: false,
    configurationLoaded: false,
    badEmailAddresses: [],
  })
  
  const loadAllConfiguration = useCallback(() => {
    return loadConfiguration()
      .then(() => loadLookups())
      .finally(() => {
        setState((prevState) => ({
          ...prevState,
          configurationLoaded: true,
        }))
      })
  }, [state.authentication.isAuthenticated])
  
  const setUser = (user) => {
    if (user) {
      user._id = user.userid
      user.id = user.userid
    }
    setState((prevState) => ({
      ...prevState,
      authentication: {
        user: user,
        isAuthenticated: !!(user && (user._id || user.id)),
      },
      userMode: user ? user.type : "login",
      title: "Wren | " + (user ? user.type : "login"),
    }))
  }
  
  const setError = (error) => {
    if (state.error !== error) {
      setState((prevState) => ({
        ...prevState,
        error: error,
      }))
    }
  }
  
  const clearError = () => {
    setState((prevState) => ({
      ...prevState,
      error: null,
    }))
  }
  
  const loadLookups = () => {
    if (!state.hasLookups) {
      return
    }
    const url = "/api/lookups"
    return fetch(url, {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => (response.ok ? response.json() : Promise.reject("No lookups available")))
      .then((lookups) => {
        setState((prevState) => ({
          ...prevState,
          lookups: lookups,
        }))
      })
      .catch(() => {}) //setError("Failed to load lookups"))
  }
  
  const loadConfiguration = () => {
    const url = "/api/configuration"
    return fetch(url, {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => (response.ok ? response.json() : Promise.reject("No configuration available")))
      .then((configuration) => {
        lookups.push(
          ...configuration.testConfiguration.map((config) => ({
            _id: config.id,
            type: "testType",
            key: config.id,
            value: config.name,
          })),
        )
        setState((prevState) => ({
          ...prevState,
          ...configuration,
          hasLookups: configuration.hasLookups,
          loggedInCheckSeconds: configuration.loggedInCheckSeconds || 60,
          twoFactorAuthentication: configuration.twoFactorAuthentication,
          badEmailAddresses: configuration?.badEmailAddresses
        }))
      })
      .catch(() => setError("Failed to load configuration"))
  }
  
  const loadUser = useCallback(() => {
    const tokenParam = window.location.search.substring(1)
    let url = "/api/session"
    if (tokenParam) {
      url += "?" + tokenParam
    }
    return fetch(url, {
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
    })
      .then((response) => (response.ok ? response.json() : null))
      .then((user) => setUser(user))
      .catch(() => setUser(null))
  }, [])
  
  const handleLogin = async (user) => {
    setUser(user)
    return loadAllConfiguration()
  }
  
  const handleLogout = useCallback(() => {
    setUser(null)
    clearInterval(intervalId)
  }, [intervalId])
  
  const checkLoggedIn = useCallback(() => {
    const url = "/api/authenticated"
    return fetch(url, {
      headers: {
        Accept: "application/json",
      },
    }).then((response) => {
      if (!response.ok) {
        handleLogout()
      } else {
        return response
      }
    })
  }, [handleLogout])
  
  useEffect(() => {
    loadUser()
  }, [loadUser])
  
  useEffect(() => {
    loadAllConfiguration()
  }, [loadAllConfiguration])
  
  useEffect(() => {
    if (state.authentication.isAuthenticated) {
      const id = setInterval(() => {
        checkLoggedIn()
      }, state.loggedInCheckSeconds * 1000)
      
      setIntervalId(id)
      
      return () => clearInterval(id)
    }
  }, [state.authentication.isAuthenticated, state.loggedInCheckSeconds])
  
  if (!state.configurationLoaded) {
    return (
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <div className="d-flex align-items-center">
          <ReactLoading type="spin" color="red" height={50} width={50} className="mr-2" />
          <p style={{ fontSize: "1.2rem", color: "#6c757d", marginBottom: "0", marginLeft: "10px" }}>Loading important configuration. Please wait a moment.</p>
        </div>
      </div>
    )
  }
  
  return (
    <div className="application">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{state.title}</title>
      </Helmet>
      <BrowserRouter>
        <QueryParamProvider adapter={ReactRouter6Adapter}>
          <Routes>
            {state.configurationLoaded &&
            state.authentication.user &&
            (state.authentication.user.twoFactorSetupRequired || state.authentication.user.secondFactorRequired) && (
              <Route
                path="/two-factor-auth"
                element={<TwoFactorAuthView setError={setError} handleLogout={handleLogout} loadUser={loadUser} applicationState={state} />}
              />
            )}
            <Route
              path="/logout"
              element={
                <Logout applicationState={state} onLogin={handleLogin} onLogout={handleLogout} onLoadConfiguration={loadConfiguration} setError={setError} />
              }
            />
            <Route
              path="/*"
              element={
                <>
                  <TableQuery />
                  <NavRouting
                    applicationState={state}
                    onLogin={handleLogin}
                    onLogout={handleLogout}
                    onLoadConfiguration={loadConfiguration}
                    setError={setError}
                  />
                </>
              }
            />
          </Routes>
        </QueryParamProvider>
      </BrowserRouter>
      
      <Modal isOpen={!!state.error} toggle={clearError} backdrop={true}>
        <ModalHeader toggle={clearError}>Error</ModalHeader>
        <ModalBody>
          <div>{state.error}</div>
        </ModalBody>
        <ModalFooter>
          <Button type="button" outline color="primary" onClick={clearError}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}

export default App
