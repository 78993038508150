import React, {useState, useEffect} from 'react';

import {Container, Label, FormGroup, Row, Col} from "reactstrap";
import dilutionFormDefinition from "../../configuration/dilution-form-definition";
import {curry, get} from '../../utility/client-utility';
import DataControlsDilution from "./data-control-dilution";
import GeneralTable from "../general-table";
import {makeApiCall} from "../../api/generic-api";

function ProcessDilutionPropertiesForm (props) {
    const formDefinition = dilutionFormDefinition;
    const sections = formDefinition.sections;
    const data = get(() => props.currentData);
    const sampleId = get(() => props.sampleId);
    const isDisableForm = get(() => props.isDisableForm);

    const [editForm, setEditForm] = useState(false);

    const [currentDataState, setCurrentDataState] = useState(data);

    const [viewModal, setViewModal] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({});
    const [dilutions, setDilutions] = useState(get(() => data.dilutions || []));

    const evaluate = (rule, nullValue) => {
        if (rule === undefined || rule === null) {
            return nullValue || false;
        }

        if (!(rule instanceof Object)) {
            return rule;
        }
        for (const property in rule) {
            if (rule.hasOwnProperty(property)) {
                if(rule[property] instanceof Object) {
                    if(rule[property].empty === true && currentDataState[property]) {
                        console.log('rule object empty false');
                        return false;
                    }
                    if(rule[property].empty === false && !currentDataState[property]) {
                        console.log('rule object not empty false');
                        return false;
                    }
                } else if (currentDataState[property] !== rule[property]) {
                    console.log('rule value false');
                    return false;
                }
            }
        }
        console.log('rule true');
        return true;
    };

    const getColumns = (section) => {
        const fields = get(() => section.fields, []);
        const numberOfFields = fields.length;
        const numberOfColumns = get(() => section.columns, 1);
        const maxFieldsPerColumn = Math.ceil(numberOfFields / numberOfColumns);
        let columnWidth = '6'
        switch (numberOfColumns) {
            case 1:
                columnWidth = '12';
                break;
            case 2:
                columnWidth = '6';
                break;
            case 3:
                columnWidth = '4';
                break;
            default:
                throw new Error('Unhandled column format');
        }

        const columns = [];
        for (let fieldIndex = 0; fieldIndex < numberOfFields; fieldIndex++) {
            const columnIndex = Math.floor(fieldIndex / maxFieldsPerColumn);
            if (columnIndex >= columns.length) {
                const column = {
                    width: columnWidth,
                    fields: []
                }
                columns.push(column);
            }
            columns[columnIndex].fields.push(fields[fieldIndex])
        }

        return columns;
    };

    const viewForm = record => {
        console.log('record iid', record)
        setViewModal(true);

        if (record?.id) {
            record.id = record?.id;
        }
        setCurrentRecord(record);
        console.log('current record', currentRecord);
        setEditForm(true);
    };

    const standardTableRowActions = [
        {
            type: 'button',
            name: 'view',
            label: 'View',
            color: 'primary',
            action: viewForm
        }
    ];

    const tableRowActions = props && props.customRowActions && props.customRowActions instanceof Array
    && props.customRowActions.length > 0 ?
        standardTableRowActions.concat(props && props.customRowActions && props.customRowActions.map(customRowAction => {
            const curriedAction = curry((details, record) => genericAction(details, record));
            customRowAction.action = curriedAction(customRowAction.details);
            return customRowAction;
        }))
        : standardTableRowActions;

    // useEffect(() => {
    //     props.setState(currentDataState)
    // }, [currentDataState])

    return(
        sections.map((section, sectionIndex) => {
            if(evaluate(section.visible, true)) {
                return(
                       getColumns(section).map((column, columnIndex) =>
                            <Col sm={12} key={'column-' + columnIndex}>
                                {column.fields.map(field => {
                                    if (evaluate(field.visible, true)) {
                                        return (
                                          <>
                                            <DataControlsDilution
                                              className="float-left mb-4"
                                              style={{ marginTop: "20px" }}
                                              add={viewForm}
                                              showAddButton={true}
                                              isolateId={currentDataState.id}
                                              setCurrentDataState={setCurrentDataState}
                                              dataState={currentRecord}
                                              setCurrentRecord={setCurrentRecord}
                                              sampleId={sampleId}
                                              currentDataState={currentDataState}
                                              close={props.close}
                                              viewModal={viewModal}
                                              setViewModal={setViewModal}
                                              submit={props.submit}
                                              editForm={editForm}
                                              setDisplay={props.setDisplay}
                                              isDisableForm={isDisableForm}
                                              setIsDisableForm={props.setIsDisableForm}
                                              {...props}
                                            />

                                            <GeneralTable
                                              style={{ marginTop: "10px" }}
                                              table={section.table}
                                              data={currentDataState.dilutions}
                                              firstRowNumber={1}
                                              rowActions={tableRowActions}
                                            />
                                          </>
                                        )
                                    }
                                })}
                            </Col>
                            )
                )
            }
        })
    )
};

export default  ProcessDilutionPropertiesForm;