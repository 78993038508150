// import React, { useEffect, useRef, useState } from 'react';
// import lookups from "../configuration/lookups";
// import DataWow from "./data-wow-print";
// import { makeApiCall } from "../api/generic-api";
// import { Alert, Col, Row } from "reactstrap";
// import printFullDefinition from "../configuration/print-full-definition";

// function PrintView(props) {
//     const [alertState, setAlertState] = useState(null);
//     const [alertBadMessage, setAlertBadMessage] = useState(null);
//     const structure = printFullDefinition;

//     const change = () => {
//         setAlertState(null);
//     };

//     useEffect(() => {
//         setTimeout(() => {
//             setAlertState(null)
//             setAlertBadMessage(null)
//         }, 6000)
//     }, [alertState]);

//     const userSelector = {
//         heading: {
//             label: 'Filter',
//             style: { display: 'inline-block', fontWeight: 800 }
//         },
//         lookups: lookups,
//         controlBlockStyle: { display: 'inline-block' },
//         controls: [
//             {
//                 label: 'Print Id',
//                 name: '_id',
//                 type: 'text',
//                 style: { maxWidth: '170px' },
//                 groupStyle: { display: 'inline-block', marginLeft: '20px' }
//             },
//             {
//                 label: 'Status',
//                 name: 'status',
//                 type: 'select',
//                 source: {
//                     kind: 'lookup',
//                     data: 'print-status'
//                 },
//                 style: { maxWidth: '170px' },
//                 groupStyle: { display: 'inline-block', marginLeft: '20px' },
//             }
//         ],
//         selectorFunction: (selector, controlState) => {
//             const resultSelector = JSON.parse(JSON.stringify(selector));
//             return resultSelector;
//         }
//     };

//     const customRowActions = [
//         {
//             type: 'button',
//             name: 'printDone',
//             label: 'Done',
//             color: 'success',
//             details: {
//                 endpoint: 'patch',
//                 data: {
//                     status: 'done'
//                 },
//                 idToken: ':id',
//                 visible: {
//                     status: 'queued'
//                 }
//             }
//         },
//     ]

//     return (
//         <div>
//             <Row style={{ marginTop: '30px' }}>
//                 <Col sm="3">
//                 </Col>
//                 <Col sm="6" className="text-center">
//                     <h3>Print Queues</h3>
//                 </Col>
//                 <Col sm="3">
//                 </Col>
//             </Row>
//             <DataWow
//                 className={'wide-container'}
//                 structure={structure}
//                 userSelector={userSelector}
//                 loadData={makeApiCall}
//                 lookups={lookups}
//                 // update={updateOrder}
//                 change={change}
//                 formDataField={null}
//                 setAlertState={setAlertState}
//                 customRowActions={customRowActions}
//                 showAddButton={false}
//                 {...props} />

//             {alertState === 'good' ?
//                 <Alert style={{ marginTop: '10px', textAlign: 'center' }} color="success">
//                     {'Document has been printed.'}
//                 </Alert>
//                 : null
//             }
//             {alertState === 'bad' ?
//                 <Alert style={{ marginTop: '10px', textAlign: 'center' }} color="danger">
//                     {'There was a problem printing your document.'}
//                     <br />
//                     {alertBadMessage || 'Please try again or contact us.'}
//                 </Alert>
//                 : null
//             }
//         </div>
//     );
// }

// export default PrintView;
import React from "react"
import Breadcrumb from "./custom/Breadcrumb"
import { Card, CardBody } from "reactstrap"
import { PrintQueuesTable } from "./tables/PrintQueuesTable"

function PrintView() {
  const breadcrumbItems = [
    {
      label: "Print",
      link: `/print-view`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Print Queues" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <PrintQueuesTable allowPageSize allowPaginate isGlobalFilter />
        </CardBody>
      </Card>
    </div>
  )
}

export default PrintView
