import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Table } from 'reactstrap';
import { getAllRequest } from '../utility/uri-utility';
import { get } from '../utility/client-utility';
import FileUploader from './file-uploader';
import ReactLoading from "react-loading";

const UploadView = props => {
    const [ state, setState ] = useState({
        showDetails: false,
        uploadSummary: [],
        records: [],
        recordDetails: [],
        selectedRecord: [],
        page: 1,
        lastPage: 0,
        displayPage: 1,
        subPage: 1,
        subLastPage: 0,
        subDisplayPage: 1
    });
    
    const [ isLoading, setIsLoading ] = useState(false);
    
    const getHeaderValue = (fieldHeader) => {
        if (fieldHeader === null || fieldHeader === undefined) {
            return '';
        }
        return fieldHeader;
    }
    
    const buildRequest = (paged) => {
        const page = paged ? state.page : null;
        const request = isFilterRequest() ? filterRequest(props.structure, state.selectedClient.id, state.startDate, state.endDate, page)
          : (isSearchRequest() ? searchRequest(props.structure, state.search, page)
            : getAllRequest(props.structure, page));
        
        return request;
    }
    
    const isFilterRequest = () => {
        return props.structure.uri['filter'];
    }
    
    const isSearchRequest = () => {
        return state.search && state.search.trim();
    }
    
    const refresh = () => {
        setIsLoading(true);
        const request = buildRequest(true);
        fetch(request.uri,
          {
              credentials: 'include',
              method: request.method
          })
          .then(function(response) {
              if (response.ok) {
                  return response.json();
              }
              throw new Error(request.uri + ' ' + request.verb + ' call failed, status: ' + response.status);
          })
          .then(records => {
              const lastPage = records.length < props.structure.pageSize ? state.page : 0;
              
              setState(prevState => ({
                  ...prevState,
                  showDetails: false,
                  uploadSummary: getSummary(records),
                  records: records,
                  lastPage: lastPage,
                  displayPage: state.page
              }));
              
              getUploadHeaderConfig()
              setIsLoading(false);
          })
          .catch((reason) => {
              props.setError('Failed to retrieve records');
              setIsLoading(false);
          });
    
        
    }
    
    const getUploadHeaderConfig = () => {
        const header = props.structure.header;
        const request = { uri: props.structure.uri['getConfig'], method: 'get' };
        
        // get upload config content
        fetch(request.uri,
          {
              credentials: 'include',
              method: request.method
          })
          .then(function(response) {
              if (response.ok) {
                  return response.json();
              }
              throw new Error(request.uri + ' ' + request.verb + ' call failed, status: ' + response.status);
          })
          .then(config => {
              // TODO not sure about this
              const customHeader = config && Array.isArray(config.fields) ? config.fields.filter(field => field.display).map(filtered => filtered.label) : [];
              const idxHeader = props.structure.tableDetails.header.findIndex(header => header === '|%|');
              
              if(idxHeader >= 0) {
                  props.structure.tableDetails.header.splice(idxHeader, 1, ...customHeader);
              }
              
              //construct values
              // TODO not sure about this
              const customBody = config && Array.isArray(config.fields) ? config.fields.filter(field => field.display).map(filtered => filtered.field) : [];
              const idxBody = props.structure.tableDetails.body.findIndex(body => body === '|%|');
              
              if(idxBody >= 0) {
                  props.structure.tableDetails.body.splice(idxBody, 1, ...customBody);
              }
          })
          .catch((reason) => {
              props.setError('Failed to retrieve records');
          });
    }
    
    const getSummary = (records) => {
        return records.map(record => {
            return {
                id: record._id,
                filename: record.file.name,
                timestamp: record.file.timestamp,
                username: record.file.username,
                totalOK: record.row == null ? 0 : record.row.summary.totalOK,
                totalWARN: record.row == null ? 0 : record.row.summary.totalWARN,
                totalERR: record.row == null ? 0 : record.row.summary.totalERR,
                totalRow: record.row == null ? 0 : record.row.summary.totalRow,
                filestatus: record.file.status
            }
        });
    }
    
    const getBodyClass = (record, index, field) => {
        return '';
    }
    
    const getBodyValue = (record, index, field) => {
        if (!record || !field) {
            return '';
        }
        if(field.toLowerCase().includes('timestamp')) {
            return new Date(record[field]).toDateString();
        }
        if(!field.startsWith('|')) {
            return record[field];
        }
        if(field === '|View|') {
            return  <Button
              outline color="primary"
              onClick={() => {viewDetailedRecord(record.id); }}>
                <span className="fa fa-info"></span> View Details</Button>
        }
        if(field === '|status.code|') {
            const okStatus = get(() => record.batchStatus, '').replace('201|', '') +
              ' ' + get(() => record.uniqueId, '');
            return get(() => record.status.code, okStatus);
        }
        if(field === '|status.details|') {
            if(get(() => record.status.details) && record.status.details.includes("|")) {
                return record.status.details.replace("|", "; ");
            }
            if(record.status && record.status.details === "") {
                return "No Error";
            }
            return get(() => record.status.details);
        }
        
        switch (field) {
            case '|#|':
                const offset = state.displayPage > 1 && props.structure.pageSize ?
                  (state.displayPage - 1) * props.structure.pageSize : 0;
                return index + 1 + offset;
            case '|##|':
                const subOffset = state.subDisplayPage > 1 && props.structure.detailedPageSize ?
                  (state.subDisplayPage - 1) * props.structure.detailedPageSize : 0;
                return index + 1 + subOffset;
            default:
                return field;
        }
    }
    
    const getPagedDetailsData = (page) => {
        const size = props.structure.pageSize;
        const tempRecord = state.recordDetails.map(record => record);
        const startIdx = (page - 1) * size;
        
        const newRecords = tempRecord.splice(startIdx, size);
        const subLastPage = newRecords.length < props.structure.detailedPageSize ? state.subPage : 0;
        
        setState(prevState => ({
            ...prevState,
            subLastPage: subLastPage,
            showDetails: true,
            subPage: page,
            selectedRecord: newRecords,
            subDisplayPage: page
        }));
    }
    
    const viewDetailedRecord = (recordId) => {
        const r = state.records.find(record => (record._id || record.id) === recordId);
        const errorDetails = get(() => r.file.error.details) ? get(() => r.file.error.details).split('|') : null;
        const errorData = errorDetails ?
          errorDetails.map(err => {
              return {
                  item: 'File issue',
                  status: {
                      code: 'ERR', //err && err.indexOf('mandatory') > - 1 ? 'ERR' : 'WARN',
                      details: err
                  }
              };
          })
          : null;
        const data = get(() => r.row.data) ? get(() => r.row.data) : errorData;
        
        if (data) {
            setState(prevState => ({
                ...prevState,
                recordDetails: data
            }));
            
            getPagedDetailsData(1)
        }
    }
    
    const back = () => {
        setState(prevState => ({
            ...prevState,
            recordDetails: [],
            showDetails: false,
            selectedRecord: [],
            subPage: 1,
            subLastPage: 0
        }));
    }
    
    const onUploadCompleted = () => {
        refresh();
    }
    
    const reload = () => {
        setState(prevState => ({
            ...prevState,
            page: 1,
            lastPage: 0
        }));
        
        refresh();
    }
    
    const pageTurn = (change) => {
        let newPage;
        
        if (state.showDetails) {
            newPage = state.subPage + change
            getPagedDetailsData(newPage);
        } else {
            newPage = state.page + change
            setState(prevState => ({...prevState, page: newPage}));
            refresh();
        }
    }
    
    useEffect(() => {
        refresh();
    }, []);
    
    
    return (
      <div className="UploadView">
        <div className="wide-container">
          {!state.showDetails ? (
            <Row style={{ marginTop: "40px" }}>
              <Col sm="3"></Col>
              <Col sm="6">
                <div className="rounded bg-body-secondary px-3 px-sm-4 py-3 py-sm-4">
                  <FileUploader title="SOLV Upload" uri="/api/upload-file" onUploadCompleted={onUploadCompleted} {...props}></FileUploader>
                </div>
              </Col>
              <Col sm="3"></Col>
            </Row>
          ) : null}
          {!state.showDetails ? (
            <div className="float-right" style={{ marginTop: "20px", padding: "20px" }}>
              <Button outline color="primary" onClick={() => pageTurn(-1)} disabled={state.page === 1} style={{ marginRight: "20px" }}>
                <span className="fa fa-angle-left"></span>
              </Button>
              <Button
                outline
                color="primary"
                onClick={() => pageTurn(1)}
                disabled={state.page >= state.lastPage && state.lastPage !== 0}
                style={{ marginRight: "20px" }}
              >
                <span className="fa fa-angle-right"></span>
              </Button>
              <Button outline color="primary" onClick={() => reload()} style={{ marginRight: "20px" }}>
                <span className="fa fa-refresh"></span>
              </Button>
            </div>
          ) : (
            <div>
              <div className="float-left" style={{ marginTop: "20px", padding: "20px" }}>
                <Button outline color="primary" onClick={() => back()} style={{ marginRight: "20px" }}>
                  <span className="fa fa-arrow-left"></span>
                </Button>
              </div>
              <div className="float-right" style={{ marginTop: "20px", padding: "20px" }}>
                <Button outline color="primary" onClick={() => pageTurn(-1)} disabled={state.subPage === 1} style={{ marginRight: "20px" }}>
                  <span className="fa fa-angle-left"></span>
                </Button>
                <Button
                  outline
                  color="primary"
                  onClick={() => pageTurn(1)}
                  disabled={state.subPage >= state.subLastPage && state.subLastPage !== 0}
                  style={{ marginRight: "20px" }}
                >
                  <span className="fa fa-angle-right"></span>
                </Button>
              </div>
            </div>
          )}
          {!state.showDetails ? (
            <Table responsive hover>
              <thead>
                <tr>
                  {props.structure.table.header.map((headerField, i) => (
                    <th key={"header-" + i}>{getHeaderValue(headerField)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={props.structure.table.header.length + 1}>
                      <center className="mt-5">
                        <ReactLoading type="spin" color="red" height={667} width={100} />
                      </center>
                    </td>
                  </tr>
                )}

                {!isLoading && !state.uploadSummary.length && (
                  <tr>
                    <td colSpan={props.structure.table.header.length + 1}>
                      <center className="mt-3">
                        <h5>No records found</h5>
                      </center>
                    </td>
                  </tr>
                )}

                {!isLoading &&
                  state.uploadSummary.map((record, i) => (
                    <tr key={"record-" + i}>
                      {props.structure.table.body.map((bodyField, j) => (
                        <td key={"body-" + i + ":" + j}>
                          <span className={getBodyClass(record, i, bodyField)}>{getBodyValue(record, i, bodyField)}</span>
                        </td>
                      ))}
                    </tr>
                  ))}
              </tbody>
            </Table>
          ) : (
            <Table responsive hover>
              <thead>
                <tr>
                  {props.structure.tableDetails.header.map((headerField, i) => (
                    <th key={"header-" + i}>{getHeaderValue(headerField)}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {isLoading && (
                  <tr>
                    <td colSpan={props.structure.table.header.length + 1}>
                      <center className="mt-5">
                        <ReactLoading type="spin" color="red" height={667} width={100} />
                      </center>
                    </td>
                  </tr>
                )}

                {!isLoading && !state.uploadSummary.length && (
                  <tr>
                    <td colSpan={props.structure.table.header.length + 1}>
                      <center className="mt-3">
                        <h5>No records found</h5>
                      </center>
                    </td>
                  </tr>
                )}

                {state.selectedRecord.map((record, i) => (
                  <tr key={"record-" + i}>
                    {props.structure.tableDetails.body.map((bodyField, j) => (
                      <td key={"body-" + i + ":" + j}>
                        <span className={getBodyClass(record, i, bodyField)}>{getBodyValue(record, i, bodyField)}</span>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </div>
      </div>
    )
};



export default UploadView;
