import React, { useState, useEffect, useRef } from 'react';
import { get } from '../utility/client-utility'
import {
    Alert,
    Button, Col, FormGroup, Input, Label
} from 'reactstrap';
import classnames from 'classnames';
import QrcodeScanner from "./qrcode-scanner";
import { isEmpty } from "lodash";

function DataSelector(props) {
    
    
    const lookups = get(() => props.configuration.lookups, []);
    const controls = get(() => props.configuration.controls, null);

    const readOnly = false;
    if(!controls) {
        console.warn('No controls for data selector');
        return null;
    }
    if(!props.setSelector) {
        console.warn('No set selector callback for data selector');
        return null;
    }

    const [controlState, setControlState] = useState(() => {
        console.log('props.selector', props.selector);
        if (props.selector) {
            return props.selector;
        }
        
        return [];
    });
    const [timer, setTimer] = useState();
    const defaultSelectorFunction = (controlState) => {
        console.log('default selector function', controlState);
        let selector = {};
        for(let controlName in controlState) {
            if (controlState.hasOwnProperty(controlName) && controlState[controlName] !== ''
                && controlState[controlName] !== null && controlState[controlName] != undefined) {
                selector[controlName] = controlState[controlName];
            }
        }
        return selector;
    };
    
    const executeSelect = () => {
        console.log('Will execute select now', controlState);
        let selector = defaultSelectorFunction(controlState);
        if (props.configuration.selectorFunction) {
            selector = props.configuration.selectorFunction(selector, controlState)
        }

        props.setSelector(selector);
    };

    const handleChange = (e) => {
        e.preventDefault();
        console.log('handle change');
        console.log(e);
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        console.log('in handle change value is now', value);
        const name = target.name;

        setControlState({
            ...controlState,
            [name]: value
        });
    };
    
    const prevState = useRef({ controlState }).current;
    useEffect(() => {
        console.log('in use effect control state is now', controlState);
        if (timer) {
            clearTimeout(timer);
        }
    
        if (prevState.controlState != controlState) {
            setTimer( setTimeout(executeSelect, 500));
        }
        
    }, [controlState]);

    const evaluate = (rule, nullValue) => {
        if (rule === undefined || rule === null) {
            return nullValue || false;
        }
        //console.log(rule);
        if (!(rule instanceof Object)) {
            //console.log('rule direct');
            return rule;
        }
        for (const property in rule) {
            if (rule.hasOwnProperty(property)) {
                if(rule[property] instanceof Object) {
                    if(rule[property].empty === true && controlState[property]) {
                        console.log('rule object empty false');
                        return false;
                    }
                    if(rule[property].empty === false && !controlState[property]) {
                        console.log('rule object not empty false');
                        return false;
                    }
                } else if (controlState[property] !== rule[property]) {
                    console.log('rule value false');
                    return false;
                }
            }
        }
        console.log('rule true');
        return true;
    };

    const getLabel = (field) => {
        const baseStyle = field.type === 'checkbox' ? {marginLeft: '2rem'} : {display: 'block'};
        const style = Object.assign(baseStyle, field.labelStyle);
        if (field.labelHtml) {
            const html = field.labelHtml;

            return <Label for={field.name}
                          className={field.labelClassName}
                          style={style}
                          dangerouslySetInnerHTML={{__html: html}}
            />
        } else {
            return <Label for={field.name}
                          className={field.labelClassName}
                          style={style}>{(field.label ? field.label : '') + (evaluate(field.required, false) ? ' *' : '')}</Label>;
        }
    };

    const getOptions = (field) => {
        const empty = [
            {
                key: '',
                value: ''
            }
        ];
        if (!field || !field.source || !field.source.kind || !field.source.data) {
            return empty;
        }

        if (field.source.kind === 'direct') {
            const directResult = empty.concat(field.source.data);
            return directResult;
        }

        if (field.source.kind === 'lookup') {
            const lookupResult = empty.concat(lookups.filter(s => s.type === field.source.data));
            return lookupResult;
        }

        // TODO does this work?
        if (field.source.kind === 'prop' || field.source.kind === 'props') {
            const lookupResult = empty.concat(props[field.source.data]);
            return lookupResult;
        }

        return empty;
    }

    
    const getValue = (name, value) => {
        if (!value) return '';
        if (value['$regex']) {
            setControlState({
                ...controlState,
                [name]: value['$regex'].replace('(?i)', '')
            });
            return value['$regex'].replace('(?i)', '')
        }
        
        return value;
    }
    
    return (

        <div className={props.className} style={props.style}>
            <div style={props.configuration.heading.style}>{props.configuration.heading.label}</div>
            <div style={props.configuration.controlBlockStyle}>
                {controls.map(
                    control => {
                        return <FormGroup key={control.name} style={control.groupStyle}>

                            {control.type !== 'checkbox' ?
                                getLabel(control)
                                : null
                            }
                            {control.type !== 'textarea' && control.type !== 'label' ?
                                <Input
                                    type={control.type}
                                    name={control.name}
                                    id={control.name}
                                    value={getValue(control.name, controlState[control.name])}
                                    checked={control.type === 'checkbox' ? controlState[control.name] || false : null}
                                    disabled={readOnly || evaluate(control.disabled, false)}
                                    readOnly={evaluate(control.readonly, false)}
                                    required={evaluate(control.required, false)}
                                    pattern={control.pattern}
                                    title={control.title}
                                    onChange={(e) => handleChange(e, control)}
                                    style={control.type === 'checkbox' ? Object.assign({marginLeft: 0}, control.style) : control.style}
                                    placeholder={control.placeholder}
                                    className={control.className}
                                    max={control.max}
                                >
                                    {control.type === 'select' ?
                                        getOptions(control).map(item =>
                                            <option key={item.key}
                                                    value={item.key}>{item.value || item.key}</option>
                                        )
                                        : null
                                    }
                                </Input>
                                : null
                            }
                            {control.type === 'checkbox' ?
                                getLabel(control)
                                : null}
                        </FormGroup>
                    }
                )}

            </div>
        </div>
    );
}

export default DataSelector;
