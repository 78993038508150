import React from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { usePreviousTableQuery } from "hooks/query"
import { useParams } from "react-router"
import Breadcrumb from "components/custom/Breadcrumb"
import OrderDetails from "components/custom/OrderDetails"

function OrderInfoView(props) {
  const { orderId } = useParams()
  const previousTableQuery = usePreviousTableQuery("/orders-view")

  const permission = props && props.applicationState.authentication.user.permission

  const breadcrumbItems = [
    {
      label: "Orders",
      link: `/orders-view?${previousTableQuery}`,
    },
    {
      label: orderId,
      link: `/order-info/${orderId}`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title={`Order - ${orderId}`} breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardHeader>
          <CardTitle>Order Details</CardTitle>
        </CardHeader>
        <CardBody>
          <OrderDetails id={orderId} permission={permission} {...props} />
        </CardBody>
      </Card>
    </div>
  )
}

export default OrderInfoView
