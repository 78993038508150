import { create } from "zustand";

export const useTableQueryStore = create((set) => ({
  previousQuery: "",
  previousPath: "",
  page: "1",
  setPage: (page) => set((state) => ({ ...state, page })),
  pageSize: "10",
  setPageSize: (pageSize) => set((state) => ({ ...state, pageSize })),
  selector: null,
  setSelector: (selector) => set((state) => ({ ...state, selector })),
  sort: null,
  setSort: (sort) => set((state) => ({ ...state, sort })),
  setTableQuery: (query) => set((state) => ({ ...state, ...query })),
}))
