const dilutionFormDefinition = {
  formLabel: null,
  submit: {
    label: "Submit",
    style: {
      cursor: "pointer",
      padding: "14px 24px",
      borderRadius: "200px",
      backgroundColor: "#f64d52",
      boxShadow: "inset 0 10px 3px 100px transparent",
      webkitTransition: "box-shadow 200ms ease",
      transition: "box-shadow 200ms ease",
      color: "#ffffff",
      fontWeight: 600,
      textAlign: "center",
    },
  },
  cancel: {
    label: "Cancel",
    style: {
      cursor: "pointer",
      padding: "14px 24px",
      borderRadius: "200px",
      backgroundColor: "#29335c",
      boxShadow: "inset 0 10px 3px 100px transparent",
      webkitTransition: "box-shadow 200ms ease",
      transition: "box-shadow 200ms ease",
      color: "#ffffff",
      fontWeight: 600,
      textAlign: "center",
    },
  },
  edit: {
    label: "Edit",
    style: {
      cursor: "pointer",
      padding: "14px 24px",
      borderRadius: "200px",
      backgroundColor: "#29335c",
      boxShadow: "inset 0 10px 3px 100px transparent",
      webkitTransition: "box-shadow 200ms ease",
      transition: "box-shadow 200ms ease",
      color: "#ffffff",
      fontWeight: 600,
      textAlign: "center",
    },
  },
  close: {
    label: "Close",
    style: {
      cursor: "pointer",
      padding: "14px 24px",
      borderRadius: "200px",
      backgroundColor: "#29335c",
      boxShadow: "inset 0 10px 3px 100px transparent",
      webkitTransition: "box-shadow 200ms ease",
      transition: "box-shadow 200ms ease",
      color: "#ffffff",
      fontWeight: 600,
      textAlign: "center",
    },
  },
  sections: [
    {
      label: "Dilution Properties",
      columns: 1,
      name: "dilutions",
      type: "table",
      fields: [{ name: "dilutions" }],
      table: {
        columns: [
          {
            label: "ID",
            field: "id",
            filterType: "none",
            sortType: "none",
          },
          {
            label: "Location",
            field: "location",
            filterType: "none",
            sortType: "none",
          },
        ],
      },
    },
  ],
  endpoints: {
    search: {
      uri: "/api/netest/sample/dilutions/:id",
      verb: "get",
    },
  },
}

export default dilutionFormDefinition;
