import { axiosClient, useRequestProcessor } from "api/provider"
import { dataToQuery } from "utility/uri-utility"
import { paramsToQuery } from "utils/query"

export function usePatientsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const fields = [`"id"`, `"firstName"`, `"lastName"`, `"dateOfBirth"`, `"email"`, `"insured"`, `"prefix"`]
  const params = encodeURIComponent(`[${fields.toString()}]`)

  const newVariables = {
    ...variables,
    selector: {
      ...(variables?.selector || {}),
    },
  }

  if (newVariables?.selector?.firstName?.$eq) {
    newVariables.selector.firstName = {
      $regex: "(?i)" + newVariables.selector.firstName.$eq,
    }
  }

  if (newVariables?.selector?.lastName?.$eq) {
    newVariables.selector.lastName = {
      $regex: "(?i)" + newVariables.selector.lastName.$eq,
    }
  }

  if (newVariables?.selector?.email?.$eq) {
    newVariables.selector.email = {
      $regex: "(?i)" + newVariables.selector.email.$eq,
    }
  }

  return query(["patients", newVariables], () => axiosClient.get(`/subjects?${paramsToQuery(newVariables)}&fields=${params}`).then((res) => res.data), {
    ...rest,
  })
}

export function usePatientDetailsTestsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const fields = [`"tests"`]
  const params = encodeURIComponent(`[${fields.toString()}]`)

  return query(["patient-details-tests", variables?.id], () => axiosClient.get(`/subject/${variables?.id || ""}?fields=${params}`).then((res) => res.data), {
    ...rest,
  })
}

export function usePatientDetailsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const fields = [
    `"region"`,
    `"firstName"`,
    `"lastName"`,
    `"postalCode"`,
    `"phoneNumber"`,
    `"dateOfBirth"`,
    `"email"`,
    `"gender"`,
    `"race"`,
    `"country"`,
    `"ethnicity"`,
    `"county"`,
    `"insured"`,
    `"address"`,
    `"prefix"`,
    `"city"`,
    `"uniqueId"`,
    `"state"`,
    `"deleted"`,
    `"zipCode"`,
    `"taps"`,
  ]

  const params = encodeURIComponent(`[${fields.toString()}]`)

  return query("patient-details", () => axiosClient.get(`/subject/${variables?.id || ""}?fields=${params}`).then((res) => res.data), {
    ...rest,
  })
}

export function usePatientTestsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["patient-tests", variables?.patientId], () => axiosClient.get(`/test-results/subjects/${variables?.patientId || ""}`).then((res) => res.data), {
    ...rest,
  })
}

export function usePatientOrdersQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["patient-orders", variables?.patientId], () => axiosClient.get(`/orders/subjects/${variables?.patientId || ""}`).then((res) => res.data), {
    ...rest,
  })
}

export function useSubjectDuplicatesQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const queryParams = _.pick(variables, ["firstName", "lastName"])

  if (queryParams.firstName) {
    queryParams.firstName = {
      $regex: "(?i)" + queryParams.firstName + "$",
    }
  }

  if (queryParams.lastName) {
    queryParams.lastName = {
      $regex: "(?i)" + queryParams.lastName + "$",
    }
  }

  return query(
    ["duplicate-subjects", queryParams?.firstName, queryParams?.lastName],
    () => axiosClient.get(`/subjects/existing${dataToQuery(queryParams) || ""}`).then((res) => res.data),
    {
      ...rest,
    },
  )
}
