import { axiosClient, useRequestProcessor } from "api/provider"

export function useNotificationFormSubmit({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("create-notification", (update) => axiosClient.post(`notifications`, update).then((res) => res.data), { ...rest })
}

export function useNotificationInfoSubmit(notificationId, { ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("submit-info-notifications", (update) => axiosClient.put(`notifications/${notificationId}`, update).then((res) => res.data), { ...rest })
}
