import { cleanObject } from "./object";
import qs from "qs";

export const paramsToQuery = (params, options = { allowEmptyObject: false }) => {
  const data = cleanObject(params, options)

  if (!data || !(data instanceof Object)) {
    return ""
  }

  let query = ""

  for (var prop in data) {
    if (data.hasOwnProperty(prop)) {
      if (query != "") {
        query += "&"
      }
      const value = data[prop] instanceof Object ? JSON.stringify(data[prop]) : data[prop]
      query += encodeURIComponent(prop) + "=" + encodeURIComponent(value)
    }
  }

  return query
}

export const getEncodedQuery = (params, field = "", queryString = "", options = {}) => {
  const queryObject = queryString ? qs.parse(queryString) : {}

  if (field) {
    return qs
      .stringify(
        cleanObject({
          ...queryObject,
          page: options?.resetPage ? 1 : queryObject?.page,
          [field]: {
            ...(queryObject?.[field] || {}),
            ...(params || {}),
          },
        }),
        { encode: false },
      )
      .replace(/\./g, "%2E")
  }

  return qs
    .stringify(
      cleanObject({
        ...queryObject,
        ...(params || {}),
      }),
      { encode: false },
    )
    .replace(/\./g, "%2E")
}
