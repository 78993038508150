import React, { useMemo, useRef } from "react"
import { Link, useNavigate } from "react-router-dom"
import { Badge, Card, CardBody, Collapse, Spinner } from "reactstrap"
import Select from "react-select"
import { FcFolder, FcOpenedFolder } from "react-icons/fc"
import { FaChevronDown, FaChevronUp } from "react-icons/fa6"

export const SidebarPatientInfo = ({ activeProductId, activeId, loading, patientId, tests = [], orders = [], file, sample, selectSample = () => {} }) => {
  const navigate = useNavigate()
  const dropdownRef = useRef(null)

  const allTests = useMemo(() => {
    const list = []

    const components = tests?.map((test) => {
      if (test.key === "covid") {
        return (
          <li key={test.key} className="mb-2">
            <div onClick={() => navigate(`/subject-info/${patientId}/covid`, { replace: true })} className="d-flex align-items-center cursor-pointer gap-2">
              {file === "covid" ? <FaChevronUp /> : <FaChevronDown />}
              {file === "covid" ? <FcOpenedFolder className="fs-5" /> : <FcFolder className="fs-5" />}
              <span className="fw-medium me-auto">Covid Tests</span>
              {loading.tests && <Spinner size="sm" />}
              <Badge color="secondary" pill>
                {test?.tests?.length || 0}
              </Badge>
            </div>
            <Collapse isOpen={file === "covid"}>
              <ul className="ps-3 list-unstyled">
                {test?.tests.length === 0 && <li className="px-4 py-2">No records</li>}
                {test?.tests?.map((covid) => {
                  list.push({ value: covid.testId, label: covid.testId })
                  return (
                    <li key={covid.testId} className="ps-2">
                      <Link
                        to={`/subject-info/${patientId}/covid?id=${covid.testId}`}
                        className={`fs-6 text-decoration-none py-1 ${(sample || activeId) === covid.testId ? "text-primary" : "text-secondary"}`}
                      >
                        {covid.testId}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </Collapse>
          </li>
        )
      }
      if (test.key === "cancer") {
        return (
          <li key={test.key} className="mb-2">
            <div onClick={() => navigate(`/subject-info/${patientId}/cancer`, { replace: true })} className="d-flex align-items-center cursor-pointer gap-2">
              {file === "cancer" ? <FaChevronUp /> : <FaChevronDown />}
              {file === "cancer" ? <FcOpenedFolder className="fs-5" /> : <FcFolder className="fs-5" />}
              <span className="fw-medium me-auto">Cancer Tests</span>
              {loading.tests && <Spinner size="sm" />}
              <Badge color="secondary" pill>
                {test?.tests?.length || 0}
              </Badge>
            </div>
            <Collapse isOpen={file === "cancer"}>
              <ul className="ps-3 list-unstyled">
                {test?.tests.length === 0 && <li className="px-4 py-2">No records</li>}
                {test?.tests?.map((cancer) => {
                  list.push({ value: cancer.testId, label: cancer.testId })
                  return (
                    <li key={cancer.testId} className="ps-2">
                      <Link
                        to={`/subject-info/${patientId}/cancer?id=${cancer.testId}`}
                        className={`fs-6 text-decoration-none py-1 ${(sample || activeId) === cancer.testId ? "text-primary" : "text-secondary"}`}
                      >
                        {cancer.testId}
                      </Link>
                    </li>
                  )
                })}
              </ul>
            </Collapse>
          </li>
        )
      }
      return <li key={test.key}></li>
    })

    return {
      list,
      components,
    }
  }, [tests, patientId, file, activeId, sample, loading.tests])

  const getProductNodes = (_order = {}) => {
    const { products, sampleData, sampleId, id: orderId } = _order

    return products?.map((product, index) => {
      const extrasNodes =
        product?.extrasConfiguration &&
        product?.extrasConfiguration.map((extra, extraIndex) => {
          return {
            key: `product${index}-extra${extraIndex}`,
            label: extra.label,
            redirect: `/order-info/${orderId}/extras?id=${extra.value}&displayMode=form&selector+_id=${extra.value}`,
          }
        })

      const getDilutionNodes = (cDna, cDnaIndex, index) => {
        const dilutions = cDna.dilutions
        return (
          dilutions &&
          dilutions.map((dilution, dilutionIndex) => {
            const dilutionId = sampleData.testType == "netest" ? dilution.id + "N" : dilution.id
            return {
              key: `product${index}-test1-cdNa${cDnaIndex}-dilution${dilutionIndex}`,
              label: `${dilutionId} ${dilution.processed ? "(processed)" : ""}`,
              redirect: `/order-info/${orderId}/${
                sampleData.testType == "netest" ? "netest" : "test"
              }?id=${dilutionId}&displayMode=form&selector+_id=${dilutionId}`,
            }
          })
        )
      }

      const cDnaNodes =
        sampleData?.cDna &&
        sampleData?.cDna.map((cDna, cDnaIndex) => ({
          key: `product${index}-test1-cdNa${cDnaIndex}`,
          label: cDna.id,
          nodes: getDilutionNodes(cDna, cDnaIndex, index),
        }))

      const testNodes = [
        {
          key: `product${index}-test1`,
          label: sampleId,
          redirect: `/order-info/${orderId}/sample?id=${sampleId}&displayMode=form&selector+_id=${sampleId}`,
          nodes: cDnaNodes,
        },
      ]

      const getProductItems = (product, index, productItemUrl) => {
        const testTypes = product.testTypeData
        return (
          testTypes &&
          testTypes.map((type, typeIndex) => {
            return {
              key: `product${index}-item${typeIndex}`,
              label: type.label,
              redirect: `${productItemUrl}&item=${type.label}`,
            }
          })
        )
      }

      const productTypeUrl = `${product.id}?product=${product.productName}`
      return {
        id: product.id,
        key: `product${index}`,
        label: product.productName,
        redirect: productTypeUrl,
        nodes: getProductItems(product, index, productTypeUrl),
        // nodes: [
        //   { key: `product${index}-tests`, label: 'Tests', nodes: testNodes || [{ key: 'tests-unknown', label: 'No tests found'}]},
        //   { key: `product${index}-extras`, label: 'Extras', nodes: extrasNodes || [{ key: 'extras-unknown', label: 'No extras found'}] },
        // ]
      }
    })
  }

  return (
    <React.Fragment>
      <Card className="filemanager-sidebar w-100 me-3 mb-3" style={{ maxWidth: "360px" }}>
        <CardBody>
          <div className="d-flex flex-column  h-100">
            <div className="search-box mb-2 me-2" ref={dropdownRef}>
              <div className="position-relative">
                <Select
                  isClearable
                  options={allTests.list || []}
                  placeholder="Search by Sample ID..."
                  classNamePrefix="select2-selection"
                  onChange={(s) => selectSample(s?.value || "")}
                  value={sample ? { label: sample, value: sample } : ""}
                />
              </div>
            </div>
            <ul className="list-unstyled categories-list">
              <div className="custom-accordion">
                <div id="sidebar-menu" className="card border-0 shadow-none mb-0">
                  <ul className="metismenu list-unstyled mb-0">
                    <li className="mb-2">
                      <div
                        onClick={() => navigate(`/subject-info/${patientId}/personal`, { replace: true })}
                        className="text-body fw-medium d-flex align-items-center cursor-pointer gap-2"
                      >
                        <FcOpenedFolder className="fs-5" />
                        Patient Details
                      </div>
                    </li>
                    {allTests.components}
                    <li className="mb-2">
                      <div
                        onClick={() => navigate(`/subject-info/${patientId}/orders`, { replace: true })}
                        className="d-flex align-items-center cursor-pointer gap-2"
                      >
                        {file === "orders" ? <FaChevronUp /> : <FaChevronDown />}
                        {file === "orders" ? <FcOpenedFolder className="fs-5" /> : <FcFolder className="fs-5" />}
                        <span className="fw-medium me-auto">Orders</span>
                        {loading.orders && <Spinner size="sm" />}
                        <Badge color="secondary" pill>
                          {!loading.orders && orders.length}
                        </Badge>
                      </div>
                      <Collapse isOpen={file === "orders"}>
                        <ul className="ps-3 list-unstyled">
                          {orders.length === 0 && <li className="px-4 py-2">No records</li>}
                          {orders.map((order) => {
                            return (
                              <li key={order.id} className="ps-2 mb-2">
                                <div
                                  onClick={() => navigate(`/subject-info/${patientId}/orders?id=${order.id}`, { replace: true })}
                                  className="d-flex align-items-center cursor-pointer gap-2"
                                >
                                  {activeId === order.id ? <FaChevronUp /> : <FaChevronDown />}
                                  {activeId === order.id ? <FcOpenedFolder className="fs-5" /> : <FcFolder className="fs-5" />}
                                  <span className="fw-medium me-auto">{order.id}</span>
                                </div>
                                <Collapse isOpen={activeId === order.id}>
                                  <ul className="ps-3 list-unstyled">
                                    {order?.products.length === 0 && <li className="px-4 py-2">No records</li>}
                                    {getProductNodes(order)?.map((item, key) => {
                                      return (
                                        <li key={key} className="ps-2">
                                          <Link
                                            to={`/subject-info/${patientId}/orders?id=${order.id}&product=${item.id}`}
                                            className={`fs-6 text-decoration-none py-1 ${activeProductId === item.id ? "text-primary" : "text-secondary"}`}
                                          >
                                            {item.label}
                                          </Link>
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </Collapse>
                              </li>
                            )
                          })}
                        </ul>
                      </Collapse>
                    </li>
                  </ul>
                </div>
              </div>
            </ul>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
