import { axiosClient, useRequestProcessor } from "api/provider";
import { paramsToQuery } from "utils/query";

export function useOrdersQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor();

  return query(["orders", variables], () => axiosClient.get(`/orders?${paramsToQuery(variables)}`).then((res) => res.data), { ...rest });
}

export function useOrderDetailsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor();

  return query("OrderInfoDetails", () => axiosClient.get(`/order/${variables?._id || ""}`).then((res) => res.data), { ...rest })
}
