import React, { useState, useEffect } from "react";
import {
    Button,
    Label,
    Row,
    Col,
    Spinner,
    Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useNotificationsInfo } from "api/getters";
import { useNotificationInfoSubmit } from "api/mutators";

const schema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    message: Yup.string().required("Message is required"),
});

const NotificationDetails = ({ id }) => {
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [editing, setEditing] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [recentData, setRecentData] = useState({
        title: "",
        message: "",
    });

    const { control, handleSubmit, setValue, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const { data, isLoading } = useNotificationsInfo({
        variables: {
            page: 1,
            selector: {
                _id: id,
            },
        },
    });

    const notificationData = data?.[0] || {};
    const notificationId = notificationData?.id;

    const { mutate, isLoading: mutating } = useNotificationInfoSubmit(notificationId, {
        onSuccess: () => {
            setSuccessMessage("Announcement has been successfully updated.");
            setErrorMessage("");
            setIsSubmitted(true);
        },
        onError: (error) => {
            const apiErrorMessage = error?.response?.data?.error;
            setErrorMessage(apiErrorMessage || "An error occurred");
        },
    });

    useEffect(() => {
        if (notificationData) {
            const { title, message } = notificationData;
            setRecentData({ title: title || "", message: message || "" });
            setValue("title", title || "");
            setValue("message", message || "");
        }
    }, [notificationData, setValue]);

    const onSubmit = (formData) => {
        mutate(formData);
    };

    const handleCancel = () => {
        setValue("title", recentData.title || "");
        setValue("message", recentData.message || "");
        setEditing(false);
    };

    if (isLoading) {
        return <Spinner type="grow" />;
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {successMessage && <Alert color="success">{successMessage}</Alert>}
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Row>
                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="formrow-text-Input">Title *</Label>
                        {editing ? (
                            <Controller
                                name="title"
                                control={control}
                                defaultValue={recentData.title || ""}
                                render={({ field }) => (
                                    <input
                                        placeholder="Enter Title"
                                        {...field}
                                        className={`form-control ${errors.title ? "is-invalid" : ""}`}
                                        disabled={isSubmitted}
                                    />
                                )}
                            />
                        ) : (
                            <input
                                type="text"
                                className="form-control"
                                value={recentData.title || ""}
                                readOnly
                                disabled={!editing || isSubmitted}
                            />
                        )}
                        {errors.title && <div className="invalid-feedback">{errors.title.message}</div>}
                    </div>
                    <Col className="mt-3">
                        <Label className="form-label">Message *</Label>
                        <Controller
                            name="message"
                            control={control}
                            defaultValue={recentData.message || ""}
                            render={({ field }) => (
                                <textarea
                                    className={`form-control ${errors.message ? "is-invalid" : ""}`}
                                    placeholder="Enter Message"
                                    {...field}
                                    readOnly={!editing || isSubmitted}
                                    disabled={!editing || isSubmitted}
                                    rows="10"
                                />
                            )}
                        />
                        {errors.message && <div className="invalid-feedback">{errors.message.message}</div>}
                    </Col>
                </Col>




                <div className="d-flex justify-content-end gap-3 mt-4">
                    {editing ? (
                        <>
                            <Button
                                key="update"
                                color="danger"
                                type="submit"
                                disabled={isSubmitted || mutating}
                            >
                                Update
                                {mutating && <Spinner size="sm" className="ms-2" />}
                            </Button>
                            {!isSubmitted && (
                                <Button
                                    key="cancel"
                                    color="secondary"
                                    type="button"
                                    onClick={() => {
                                        setSuccessMessage("");
                                        setErrorMessage("");
                                        handleCancel();
                                    }}
                                    disabled={mutating}
                                >
                                    Cancel
                                </Button>
                            )}
                        </>
                    ) : (
                        <Button color="primary" onClick={() => setEditing(true)}>
                            Edit
                        </Button>
                    )}
                </div>
            </Row>
        </form>
    );
};

export default NotificationDetails;
