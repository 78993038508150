const netestRequisitionFormDefinition = {
  formLabel: "Cancer Test Requisition",
  submit: {
    label: "Download",
  },
  cancel: {
    label: "Close",
  },
  sections: [
    {
      label: "Destination",
      columns: 1,
      fields: [
        {
          label: "Where should the test kit be sent? *",
          labelClassName: "form-subheading",
          columns: 1,
          fields: [
            {
              id: "destination",
              name: "destination",
              type: "radio",
              required: true,
              source: {
                kind: "direct",
                data: [
                  {
                    key: "patient",
                    value: "To the patient",
                  },
                  {
                    key: "provider",
                    value: "To the medical care provider",
                  },
                  {
                    key: "noKit",
                    value: "No kit needed",
                  },
                ],
              },
            },
          ],
        },
      ],
    },
    {
      label: "Patient's Information",
      columns: 1,
      fields: [
        {
          label: "Name",
          columns: 3,
          labelClassName: "form-subheading",
          fields: [
            {
              label: "Name",
              placeholder: "First Name *",
              id: "firstName",
              name: "firstName",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              maxlength: 50,
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Last Name *",
              id: "lastName",
              name: "lastName",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              maxlength: 50,
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Middle Initial",
              id: "middleInitial",
              name: "middleInitial",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 50,
              userType: "patient",
              ownInfo: true,
            },
          ],
        },
        {
          label: "",
          columns: 3,
          fields: [
            {
              label: "Date of Birth *",
              id: "dateOfBirth",
              name: "dateOfBirth",
              type: "date",
              required: true,
              max: "9999-12-31",
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "Gender",
              id: "gender",
              name: "gender",
              placeholder: "Gender *",
              type: "select",
              source: {
                kind: "lookup",
                data: "netest-gender",
              },
              required: true,
              userType: "patient",
              ownInfo: true,
            },
          ],
        },
        {
          label: "Address",
          columns: 3,
          labelClassName: "form-subheading",
          fields: [
            {
              label: "Address (Country first to allow for variations)",
              placeholder: "Country *",
              id: "country",
              name: "country",
              type: "select",
              source: {
                kind: "list",
                data: "country",
              },
              required: true,
              style: { maxWidth: "400px" },
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "State *",
              id: "state",
              name: "state",
              type: "select",
              source: {
                kind: "lookup",
                data: "state",
              },
              required: false,
              style: { maxWidth: "400px" },
              validation: {
                rule: {
                  $or: [
                    { country: { $in: ["US", "GB", "NL"] } },
                    { state: { required: true } },
                  ],
                },
                message: "State and Zip Code is required for selected country",
              },
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Zip Code *",
              id: "zipCode",
              name: "zipCode",
              type: "text",
              pattern: "[0-9]*",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 5,
              validation: {
                rule: {
                  $or: [
                    { country: { $in: ["US", "GB", "NL"] } },
                    { zipCode: { required: true } },
                  ],
                },
                message: "State and Zip Code is required for selected country",
              },
              userType: "patient",
              ownInfo: true,
            },
          ],
        },
        {
          label: "",
          columns: 4,
          labelClassName: "form-subheading",
          fields: [
            {
              label: "",
              placeholder: "Street *",
              id: "address",
              name: "address",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "City *",
              id: "city",
              name: "city",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Region *",
              id: "region",
              name: "region",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              validation: {
                rule: {
                  $or: [
                    { country: { $nin: ["US", "GB", "NL"] } },
                    { region: { required: true } },
                  ],
                },
                message:
                  "Region and Postal Code is required for selected country",
              },
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Postal Code *",
              id: "postalCode",
              name: "postalCode",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 15,
              validation: {
                rule: {
                  $or: [
                    { country: { $nin: ["US", "GB", "NL"] } },
                    { postalCode: { required: true } },
                  ],
                },
                message:
                  "Region and Postal Code is required for selected country",
              },
              userType: "patient",
              ownInfo: true,
            },
          ],
        },
        {
          label: "Contact",
          columns: 3,
          labelClassName: "form-subheading",
          fields: [
            {
              label: "Contact",
              placeholder: "Phone Number *",
              id: "phoneNumber",
              name: "phoneNumber",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              maxlength: 15,
              userType: "patient",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Email *",
              id: "email",
              name: "email",
              type: "email",
              pattern: "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$",
              required: true,
              style: { maxWidth: "400px" },
              userType: "patient",
              ownInfo: true,
            },
          ],
        },
        {
          label: "Billing",
          columns: 3,
          labelClassName: "form-subheading",
          fields: [
            {
              label: "Billing",
              placeholder: "Responsible Party *",
              id: "responsibleParty",
              name: "responsibleParty",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "Relationship *",
              id: "relationshipToPatient",
              name: "relationshipToPatient",
              type: "select",
              source: {
                kind: "lookup",
                data: "relationship",
              },
              required: true,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "Bill To *",
              id: "billTo",
              name: "billTo",
              type: "select",
              source: {
                kind: "lookup",
                data: "billTo",
              },
              required: true,
              style: { maxWidth: "400px" },
            },
          ],
        },
      ],
    },
    // {
    //     label: 'Insurance Information (Complete below)',
    //     columns: 1,
    //     fields: [
    //         {
    //             label: 'Primary Insurance Info',
    //             labelClassName: 'form-subheading',
    //             columns: 2,
    //             fields: [
    //                 {
    //                     label: "Primary Insurance Info",
    //                     placeholder: "Subscriber Name",
    //                     id: 'subscriberAName',
    //                     name: 'subscriberAName',
    //                     type: 'text',
    //                     required: false,
    //                     style: {maxWidth:'400px'},
    //                     validation: {
    //                         rule: {
    //                             $or: [
    //                                 {billTo: 'personal'},
    //                                 {billTo: 'client'},
    //                                 {subscriberAName: {required: true}}
    //                             ]
    //                         },
    //                         message: 'Subscriber name is required when insurance is selected for billing'
    //                     },
    //                 },
    //                 {
    //                     label: "",
    //                     placeholder: "Provider Name",
    //                     id: 'providerAName',
    //                     name: 'providerAName',
    //                     type: 'text',
    //                     required: false,
    //                     validation: {
    //                         rule: {
    //                             $or: [
    //                                 {billTo: 'personal'},
    //                                 {billTo: 'client'},
    //                                 {providerAName: {required: true}}
    //                             ]
    //                         },
    //                         message: 'Provider name is required when insurance is selected for billing'
    //                     },
    //                     style: {maxWidth:'400px'}
    //                 },
    //                 {
    //                     label: "",
    //                     placeholder: "Provider Address",
    //                     id: 'providerAAddress',
    //                     name: 'providerAAddress',
    //                     type: 'text',
    //                     required: false,
    //                     validation: {
    //                         rule: {
    //                             $or: [
    //                                 {billTo: 'personal'},
    //                                 {billTo: 'client'},
    //                                 {providerAAddress: {required: true}}
    //                             ]
    //                         },
    //                         message: 'Provider address is required when insurance is selected for billing'
    //                     },
    //                     style: {maxWidth:'400px'}
    //                 },
    //                 {
    //                     label: "",
    //                     placeholder: "ID#/Group#",
    //                     id: 'providerAId',
    //                     name: 'providerAId',
    //                     type: 'text',
    //                     required: false,
    //                     validation: {
    //                         rule: {
    //                             $or: [
    //                                 {billTo: 'personal'},
    //                                 {billTo: 'client'},
    //                                 {providerAId: {required: true}}
    //                             ]
    //                         },
    //                         message: 'ID#/Group# is required when insurance is selected for billing'
    //                     },
    //                     style: {maxWidth:'400px'}
    //                 },
    //                 {
    //                     label: "Secondary Insurance Info",
    //                     placeholder: "Subscriber Name",
    //                     id: 'subscriberBName',
    //                     name: 'subscriberBName',
    //                     type: 'text',
    //                     required: false,
    //                     style: {maxWidth:'400px'}
    //                 },
    //                 {
    //                     label: "",
    //                     placeholder: "Provider Name",
    //                     id: 'providerBName',
    //                     name: 'providerBName',
    //                     type: 'text',
    //                     required: false,
    //                     style: {maxWidth:'400px'}
    //                 },
    //                 {
    //                     label: "",
    //                     placeholder: "Provider Address",
    //                     id: 'providerBAddress',
    //                     name: 'providerBAddress',
    //                     type: 'text',
    //                     required: false,
    //                     style: {maxWidth:'400px'}
    //                 },
    //                 {
    //                     label: "",
    //                     placeholder: "ID#/Group#",
    //                     id: 'providerBId',
    //                     name: 'providerBId',
    //                     type: 'text',
    //                     required: false,
    //                     style: {maxWidth:'400px'}
    //                 },
    //             ]
    //         }
    //     ]
    // },
    {
      label:
        "Self Pay (Please fill out credit card information below, or indicate your billing address if different from above)",
      columns: 1,
      sectionType: "billing",
      fields: [
        {
          label: "Payment Information",
          labelClassName: "form-subheading",
          columns: 3,
          fields: [
            {
              label: "Name (as it appears on your card) *",
              id: "creditCardName",
              name: "creditCardName",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              validation: {
                rule: {
                  $or: [
                    { billTo: "insurance" },
                    { billTo: "client" },
                    { creditCardName: { required: true } },
                  ],
                },
                message:
                  "Card name is required when patient is selected for billing",
              },
            },
            {
              label: "Expiry Date *",
              id: "creditCardExpiry",
              name: "creditCardExpiry",
              type: "text",
              placeholder: "MM/YY",
              required: false,
              validation: {
                rule: {
                  $or: [
                    { billTo: "insurance" },
                    { billTo: "client" },
                    { creditCardExpiry: { required: true } },
                  ],
                },
                message:
                  "Card expiry date is required when patient is selected for billing",
              },
              style: { maxWidth: "400px" },
            },
            {
              label: "Credit Card Type *",
              id: "creditCardType",
              name: "creditCardType",
              type: "select",
              source: {
                kind: "lookup",
                data: "creditCardType",
              },
              required: false,
              validation: {
                rule: {
                  $or: [
                    { billTo: "insurance" },
                    { billTo: "client" },
                    { creditCardType: { required: true } },
                  ],
                },
                message:
                  "Credit card type is required when patient is selected for billing",
              },
              style: { maxWidth: "400px" },
            },
            {
              label: "CVV *",
              id: "creditCardCVV",
              name: "creditCardCVV",
              type: "text",
              pattern: "[0-9]{3}",
              required: false,
              // maxlength: 3,
              validation: {
                rule: {
                  $or: [
                    { billTo: "insurance" },
                    { billTo: "client" },
                    { creditCardCVV: { required: true } },
                  ],
                },
                message:
                  "Card CVV is required when patient is selected for billing",
              },
              style: { maxWidth: "100px" },
            },
            {
              label: "Card Number *",
              id: "creditCardNo",
              name: "creditCardNo",
              pattern: "[0-9]{16}",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 16,
              validation: {
                rule: {
                  $or: [
                    { billTo: "insurance" },
                    { billTo: "client" },
                    { creditCardNo: { required: true } },
                  ],
                },
                message:
                  "Card number is required when patient is selected for billing",
              },
            },
          ],
        },
        {
          label: "Billing Address",
          labelClassName: "form-subheading",
          columns: 3,
          fields: [
            {
              label: "Billing Address (if different from above)",
              placeholder: "Country",
              id: "billingCountry",
              name: "billingCountry",
              type: "select",
              source: {
                kind: "list",
                data: "country",
              },
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "State",
              id: "billingState",
              name: "billingState",
              type: "select",
              source: {
                kind: "lookup",
                data: "state",
              },
              required: false,
              style: { maxWidth: "400px" },
              // validation: {
              //     rule: {
              //         $or: [
              //             {billingCountry: { $in: ['US', 'GB', 'NL']}},
              //             {billingState: {required: true}}
              //         ]
              //     },
              //     message: 'Billing State and Billing Zip Code is required for selected country'
              // },
            },
            {
              label: "",
              placeholder: "Zip Code",
              id: "billingZipCode",
              name: "billingZipCode",
              type: "text",
              pattern: "[0-9]*",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 5,
              // validation: {
              //     rule: {
              //         $or: [
              //             {billingCountry: { $in: ['US', 'GB', 'NL']}},
              //             {billingZipCode: {required: true}}
              //         ]
              //     },
              //     message: 'Billing State and Billing Zip Code is required for selected country'
              // },
            },
          ],
        },
        {
          label: "",
          columns: 4,
          labelClassName: "form-subheading",
          fields: [
            {
              label: "",
              placeholder: "Street Address",
              id: "billingAddress",
              name: "billingAddress",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "City",
              id: "billingCity",
              name: "billingCity",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "Region *",
              id: "billingRegion",
              name: "billingRegion",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              // validation: {
              //     rule: {
              //         $or: [
              //             {billingCountry: { $nin: ['US', 'GB', 'NL']}},
              //             {billingRegion: {required: true}}
              //         ]
              //     },
              //     message: 'Billing Region and Postal Code is required for selected country'
              // },
            },
            {
              label: "",
              placeholder: "Postal Code *",
              id: "billingPostalCode",
              name: "billingPostalCode",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 15,
              // validation: {
              //     rule: {
              //         $or: [
              //             {billingCountry: { $nin: ['US', 'GB', 'NL']}},
              //             {billingPostalCode: {required: true}}
              //         ]
              //     },
              //     message: 'Billing Region and Postal Code is required for selected country'
              // },
            },
          ],
        },
      ],
    },
    {
      label:
        "Provider & Test Information (You must include Provider’s Name and Facility)",
      columns: 1,
      fields: [
        {
          label: "Name",
          labelClassName: "form-subheading",
          columns: 3,
          fields: [
            {
              label: "Name",
              placeholder: "Ordering Provider's Full Name*",
              id: "providerFullName",
              name: "providerFullName",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              maxlength: 100,
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "NPI Registration Number *",
              id: "providerNPINo",
              name: "providerNPINo",
              // pattern: '[0-9]{1,12}',
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              maxlength: 12,
              userType: "physician",
              ownInfo: true,
            },
          ],
        },
        {
          label: "Address",
          labelClassName: "form-subheading",
          columns: 3,
          fields: [
            {
              label: "Address",
              placeholder: "Provider's Facility *",
              id: "providerFacility",
              name: "providerFacility",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              maxlength: 100,
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Street Address",
              id: "providerAddressLine1",
              name: "providerAddressLine1",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 30,
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "City",
              id: "providerCity",
              name: "providerCity",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 30,
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "State/Region",
              id: "providerState",
              name: "providerState",
              type: "select",
              source: {
                kind: "lookup",
                data: "state",
              },
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 30,
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Zip/Postal Code",
              id: "providerZipCode",
              name: "providerZipCode",
              type: "number",
              required: false,
              style: { maxWidth: "400px" },
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Country",
              id: "providerCountry",
              name: "providerCountry",
              type: "text",
              required: false,
              style: { maxWidth: "400px" },
              userType: "physician",
              ownInfo: true,
            },
          ],
        },
        {
          label: "Contact",
          labelClassName: "form-subheading",
          columns: 3,
          fields: [
            {
              label: "Contact",
              placeholder: "Phone Number *",
              id: "providerPhoneNo",
              name: "providerPhoneNo",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              maxlength: 15,
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Email *",
              id: "providerEmail",
              name: "providerEmail",
              pattern: "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$",
              type: "email",
              required: false,
              style: { maxWidth: "400px" },
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Provider Fax Number",
              id: "providerFaxNo",
              name: "providerFaxNo",
              type: "text",
              pattern: "^[\\d\\[\\]\\(\\)\\-+]+$",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 15,
              userType: "physician",
              ownInfo: true,
            },
          ],
        },
        {
          label: "Diagnosis",
          labelClassName: "form-subheading",
          columns: 1,
          fields: [
            {
              label: "Diagnosis",
              placeholder: "Diagnosis/Symptoms (Provide ICD10 Code) *",
              id: "providerDiagnostic",
              name: "providerDiagnostic",
              type: "text",
              required: true,
              style: { maxWidth: "400px" },
              maxlength: 150,
            },
          ],
        },
        {
          label: "How do you want the report to be delivered?*",
          labelClassName: "form-subheading",
          columns: "1",
          atleast: 1,
          fields: [
            {
              label: "How do you want the report to be delivered?",
              placeholder: "Email",
              type: "checkbox",
              id: "providerEmailDelivery",
              name: "providerEmailDelivery",
              required: false,
              style: {
                maxWidth: "200px",
              },
              userType: "physician",
              ownInfo: true,
            },
            {
              label: "",
              placeholder: "Fax",
              type: "checkbox",
              id: "providerFaxDelivery",
              name: "providerFaxDelivery",
              required: false,
              style: {
                maxWidth: "200px",
              },
              userType: "physician",
              ownInfo: true,
            },
          ],
        },
        {
          label: "",
          columns: 1,
          fields: [
            {
              label:
                "Note: When ordering tests which Medicare reimbursement will be sought, providers should only order tests that are medically necessary for the diagnosis or treatment of the patient. Medicare generally does not cover routine screening tests or those for research purposes only.",
              name: "providerLabel",
              type: "label",
              required: false,
              style: { maxWidth: "400px" },
            },
          ],
        },
      ],
    },
    {
      label: "Please indicate below the type of test(s) to be performed",
      columns: 1,
      fields: [
        {
          label: "Blood Collection Date",
          labelClassName: "form-subheading",
          columns: 3,
          fields: [
            {
              label: "Blood Collection Date",
              id: "bloodCollectionDate",
              name: "bloodCollectionDate",
              type: "date",
              required: false,
              style: { maxWidth: "400px" },
              maxlength: 15,
            },
          ],
        },
        {
          label: "",
          labelClassName: "form-subheading",
          columns: 2,
          fields: [
            {
              label: "",
              id: "testTypeName",
              name: "testTypeName",
              type: "product",
              required: false,
            },
          ],
        },
        {
          label: "",
          columns: 1,
          fields: [
            {
              label: "",
              id: "questionName",
              name: "questionName",
              type: "questions",
              required: false,
            },
          ],
        },
      ],
    },
    {
      label: "Please indicate below reason(s) for testing",
      columns: 1,
      sectionType: "testing-reason",
      fields: [
        {
          label: "",
          columns: 4,
          fields: [
            {
              label: "",
              placeholder: "Pre-Surgery",
              type: "checkbox",
              id: "isPreSurgery",
              name: "isPreSurgery",
              required: false,
              style: { maxWidth: "400px" },
            },
          ],
        },
        {
          label: "",
          columns: 4,
          fields: [
            {
              label: "",
              placeholder: "Post-Surgery",
              type: "checkbox",
              id: "isPostSurgery",
              name: "isPostSurgery",
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "Months (if Post-Surgery)",
              placeholder: "No. of Months",
              type: "number",
              id: "postSurgeryMonths",
              name: "postSurgeryMonths",
              required: false,
              maxlength: 3,
              style: { maxWidth: "150px" },
            },
          ],
        },
        {
          label: "",
          columns: 3,
          fields: [
            {
              label: "",
              placeholder: "Monitoring (Watchful waiting)",
              type: "checkbox",
              id: "isMonitoringWaiting",
              name: "isMonitoringWaiting",
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "Monitoring (Somatostatin analog)",
              type: "checkbox",
              id: "isMonitoringAnalog",
              name: "isMonitoringAnalog",
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "Date of Last SSA Dose",
              placeholder: "Date of Last SSA Dose",
              type: "date",
              id: "lastSSADate",
              name: "lastSSADate",
              required: false,
              style: { maxWidth: "300px" },
            },
          ],
        },
        {
          label: "",
          columns: 3,
          fields: [
            {
              label: "",
              placeholder: "Symptoms (Disease Not Confirmed)",
              type: "checkbox",
              id: "isSymptomsNotConfirmed",
              name: "isSymptomsNotConfirmed",
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "Monitoring (Other Therapy)",
              type: "checkbox",
              id: "isMonitoringOther",
              name: "isMonitoringOther",
              required: false,
              style: { maxWidth: "400px" },
            },
          ],
        },
        {
          label: "",
          columns: 3,
          fields: [
            {
              label: "",
              placeholder: "Chemotherapy",
              type: "checkbox",
              id: "isChemo",
              name: "isChemo",
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "Target Therapy",
              type: "checkbox",
              id: "isTargetTherapy",
              name: "isTargetTherapy",
              required: false,
              style: { maxWidth: "400px" },
            },
            {
              label: "",
              placeholder: "PRRT",
              type: "checkbox",
              id: "isPRRT",
              name: "isPRRT",
              required: false,
              style: { maxWidth: "400px" },
            },
          ],
        },
        {
          label: "",
          columns: "3",
          fields: [
            {
              label: "Other",
              placeholder: "Other",
              type: "text",
              id: "isOther",
              name: "isOther",
              required: false,
              style: {
                maxWidth: "400px",
              },
            },
          ],
        },
      ],
    },
  ],
};

export default netestRequisitionFormDefinition;