import React, { useMemo } from "react"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"
import { useSampleDetailsQuery, useTestHistoryQuery } from "api/getters"
import { Button } from "reactstrap"
import moment from "moment"
dayjs.extend(utc)

const { Parser } = require("json2csv")

export function TransactionHistoryTable({ type = "sample", id, pageSize: pageSizeProp, ...rest }) {
  const { selector, sort } = useTableQueryStore()

  const sample = useSampleDetailsQuery({
    variables: { id },
    enabled: type === "sample" && Boolean(id),
  })

  const test = useTestHistoryQuery({
    variables: { id },
    enabled: type === "test" && Boolean(id),
  })

  const response = type === "sample" ? sample : test

  const sampleData = useMemo(() => (type === "sample" ? response?.data : { ...response?.data?.searchResult?.[0] }), [type, response?.data])

  const columns = useMemo(
    () => [
      {
        Header: "Date/Time",
        accessor: "dateTime",
      },
      {
        Header: "Action",
        accessor: "action",
      },
      {
        Header: "Details",
        accessor: "details",
      },
      {
        Header: "Field",
        accessor: "field",
      },
      {
        Header: "User",
        accessor: "user",
      },
      {
        Header: "Value",
        accessor: "value",
      },
    ],
    [],
  )

  const DataDisplay = ({ data, isText }) => {
    let parsedData

    try {
      // Attempt to parse the data as JSON
      parsedData = JSON.parse(data)
    } catch (e) {
      // If parsing fails, it means data is not a JSON string
      parsedData = null
    }

    if (parsedData && typeof parsedData === "object" && !Array.isArray(parsedData)) {
      // Custom labels for specific keys
      const labels = {
        sampleCollectionDateTime: "collected",
        laboratoryReceivedDateTime: "accessioned",
        laboratoryCompletedDateTime: "reported",
      }

      const formatDateTime = (key, value) => {
        if (key === "sampleCollectionDateTime" || key === "laboratoryReceivedDateTime") {
          return moment(value).format("MM/DD/YYYY")
        } else if (key === "laboratoryCompletedDateTime") {
          return convertToTimezone(value)
        }
        return value
      }

      // Generate the formatted output for specific keys
      const formattedOutput = Object.entries(parsedData)
        .filter(([key]) => labels[key])
        .map(([key, value]) => `${labels[key]}: ${formatDateTime(key, value)}`)
        .join(", ")

      // Return formatted text or original data based on isText flag
      if (isText) {
        return formattedOutput // Return formatted output or original data if no formatting is needed
      } else {
        return (
          <div>
            {formattedOutput ? (
              formattedOutput.split(", ").map((line, index) => <p key={index}>{line}</p>)
            ) : (
              <p>{data}</p> // Display original data if no formatting is needed
            )}
          </div>
        )
      }
    } else {
      // Case where data is not an object or is a simple string
      if (isText) {
        return data
      }
      return <div>{data}</div>
    }
  }

  const convertToTimezone = (dateTimeValue) => {
    const date = new Date(dateTimeValue)
    const timezone = "America/New_York" // Change to the desired timezone
    const options = {
      timeZone: timezone,
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    }

    return new Intl.DateTimeFormat("en-US", options).format(date)
  }

  const data = useMemo(
    () =>
      (sampleData &&
        sampleData?.transactionHistory?.map((item) => {
          const modifiedLabels = [
            { value: "isolationId", modified: "Isolation ID" },
            { value: "isolation260280ratio", modified: "Isolation 260/280 ratio" },
            { value: "isolationStatus", modified: "Isolation Status" },
            { value: "isolationConcentration", modified: "Isolation Concentration" },
            { value: "isolation260230ratio", modified: "Isolation 260/230 ratio" },
            { value: "cDnaStatus", modified: "cDNA Status" },
            { value: "cDna260280ratio", modified: "cDNA 260/280 ratio" },
            { value: "cDnaConcentration", modified: "cDNA Concentration" },
            { value: "cDna260230ratio", modified: "cDNA 260/230 ratio" },
            { value: "location", modified: "Location" },
            { value: "status", modified: "Status" },
          ]

          const fieldLabel = modifiedLabels.find((label) => label.value === item?.transactionField)

          return {
            dateTime: <span className="text-nowrap">{new Date(item?.date || item?.timestamp).toLocaleString("en-US", { timeZone: "UTC" })}</span>,
            action: <span>{item?.transaction?.action?.desc}</span>,
            details: <span className="text-nowrap">{item?.transactionId}</span>,
            field: <span>{fieldLabel ? fieldLabel.modified : item?.transactionField}</span>,
            value: <DataDisplay data={item?.transactionValue} />,
            user: <span>{item?.user?.userid}</span>,
          }
        })) ||
      [],
    [sampleData],
  )

  const filters = useMemo(() => {
    return []
  }, [])

  const handleExportCSV = () => {
    if (!sampleData?.transactionHistory) return

    const pickedData = []
    sampleData?.transactionHistory.forEach((item) => {
      // labels for transaction history
      const modifiedLabels = [
        {
          value: "isolationId",
          modified: "Isolation ID",
        },
        {
          value: "isolation260280ratio",
          modified: "Isolation 260/280 ratio",
        },
        {
          value: "isolationStatus",
          modified: "Isolation Status",
        },
        {
          value: "isolationConcentration",
          modified: "Isolation Concentration",
        },
        {
          value: "isolation260230ratio",
          modified: "Isolation 260/230 ratio",
        },
        {
          value: "cDnaStatus",
          modified: "cDNA Status",
        },
        {
          value: "cDna260280ratio",
          modified: "cDNA 260/280 ratio",
        },
        {
          value: "cDnaConcentration",
          modified: "cDNA Concentration",
        },
        {
          value: "cDna260230ratio",
          modified: "cDNA 260/230 ratio",
        },
        {
          value: "location",
          modified: "Location",
        },
        {
          value: "status",
          modified: "Status",
        },
      ]
      const fieldLabel = modifiedLabels.filter((label) => label.value == item.transactionField)

      pickedData.push({
        date: new Date(item?.date || item?.timestamp).toLocaleString("en-US", { timeZone: "UTC" }),
        action: item?.transaction?.action?.desc,
        details: item?.transactionId,
        field: fieldLabel.length ? fieldLabel[0].modified : item?.transactionField,
        user: item?.user?.userid,
        value: DataDisplay({ data: item?.transactionValue, isText: true }),
      })
    })

    const fields = ["date", "action", "details", "field", "user", "value"]

    try {
      const parser = new Parser({ fields })
      const csv = parser.parse(pickedData)

      const blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
      saveAs(blob, `${id}-transaction-history.csv`)
    } catch (err) {
      console.error(err)
    }
  }

  return (
    <div>
      <div className="d-flex justify-content-between mb-3 align-items-center">
        <h4>{id}</h4>
        <Button type="button" color="primary" onClick={handleExportCSV}>
          Export
        </Button>
      </div>
      <TableContainer
        refetch={response.refetch}
        columns={columns}
        data={data}
        isLoading={response?.isLoading || response?.isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        {...rest}
      />
    </div>
  )
}
