import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import { ProcessSamplesTable } from "./tables/ProcessSamplesTable"
import Breadcrumb from "./custom/Breadcrumb"

function ProcessSampleView(props) {
  const authentication = props && props.applicationState.authentication.user;

  const breadcrumbItems = [
    {
      label: "Process Samples",
      link: `/process-sample`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Process Samples" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <ProcessSamplesTable
            allowPageSize
            allowPaginate
            isGlobalFilter
            authentication={authentication}
            customSelect={{
              status: "usable",
              testType: "netest",
            }}
          />
        </CardBody>
      </Card>
    </div>
  )
}

export default ProcessSampleView
