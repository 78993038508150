import React from 'react';
import { Button } from 'react-bootstrap';
import { FaCheckCircle } from 'react-icons/fa';
import ReactLoading from "react-loading";

const SuccessMessage = ({ setSetupSuccess }) => (
	<div className="text-center">
		<FaCheckCircle size={50} color="green" className="mb-3" />
		<h5 className="mb-3">Two-factor authentication is on</h5>
		<p className="mb-4" style={{ fontSize: '0.9rem', color: '#6c757d' }}>
			Now whenever you sign in, we will ask you for a code after you enter your email address and password.
		</p>
		<div className="d-flex justify-content-center align-items-center mb-3">
			<ReactLoading type="spin" color="red" height={20} width={20} className="mr-3" />
			<p style={{ fontSize: '0.9rem', color: '#6c757d', marginBottom: '-5px', marginLeft: '5px' }}>
				Redirecting to the dashboard shortly...
			</p>
		</div>
	</div>
);

export default SuccessMessage;
