export function cleanObject(obj = {}, options = { allowEmptyObject: false }) {
  // Helper function to determine if an object is empty
  const isEmptyObject = (value) => (options.allowEmptyObject ? false : Object.keys(value).length === 0 && value.constructor === Object)

  // Helper function to determine if the value should be removed
  const shouldRemove = (value) => value === null || value === undefined || value === "" || (typeof value === "object" && isEmptyObject(value))

  // Recursively remove null, undefined values, empty strings, and empty objects
  Object.keys(obj).forEach((key) => {
    if (obj[key] && typeof obj[key] === "object") {
      cleanObject(obj[key]) // Recurse into nested objects or arrays
      // After cleaning, if the object or array is empty or an empty string, delete it
      if (shouldRemove(obj[key])) {
        delete obj[key]
      }
    } else if (shouldRemove(obj[key])) {
      delete obj[key] // Remove null, undefined values, and empty strings
    }
  })

  // For arrays, filter out undesired values and clean objects within them
  if (Array.isArray(obj)) {
    return obj
      .filter((value) => !shouldRemove(value))
      .map((item) => {
        if (typeof item === "object") {
          return cleanObject(item) // Recurse into array items if they are objects
        }
        return item
      })
      .filter((item) => !shouldRemove(item)) // Filter out undesired values after cleaning
  }

  return obj
}

// Function to sort an object by its keys
function sortObject(obj) {
  return Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      result[key] = obj[key]
      return result
    }, {})
}

// Convert an object to a sorted string
export function objectToStringSorted(obj) {
  const sortedObj = sortObject(obj)
  return JSON.stringify(sortedObj)
}

// Convert a string back to an object
export function stringToObject(str) {
  return JSON.parse(str)
}
