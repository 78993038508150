import React, { useEffect, useRef, useState } from "react"

import { Row, Col, FormGroup, Label, Input, Button, Card, CardHeader, CardBody, Collapse, Form, Alert, CardTitle, Spinner } from "reactstrap"

import lookups from "../../configuration/lookups"
import { makeApiCall } from "../../api/generic-api"
import { get, uuid } from "../../utility/client-utility"
import ProcessCdnaPropertiesForm from "./process-cdna-properties-form"
import { useAlert } from "react-alert"
import ReactLoading from "react-loading"
import { v4 as uuidv4 } from "uuid"

function ProcessSampleForm(props) {
  const alert = useAlert()
  const [currentFormState, setCurrentFormState] = useState()
  const readOnly = props.formState && (!props.submit || !!props.update)
  const editForm = props && props.editMode

  const [viewIsolateProperties, setViewIsolateProperties] = useState(false)
  const [isolateCount, setIsolateCount] = useState(1)
  const [cardCollapse, setCardCollapse] = useState(0)

  const [isolations, setIsolations] = useState([])

  const [isolationId, setIsolationId] = useState("")
  const [hideCdnaProperties, setHideCdnaProperties] = useState(true)
  const [viewCdnaProperties, setViewCdnaProperties] = useState(false)
  const [isActive, setIsActive] = useState(0)

  const [invalid, setInvalid] = useState(false)
  const [submitType, setSubmitType] = useState(null)
  const [isDisableForm, setIsDisableForm] = useState(readOnly)

  const [disableIsolate, setIsDisabled] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [transactionHistory, setTransactionHistory] = useState([])
  const [transaction, setTransaction] = useState(null)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    setIsLoading(true)
    await loadCurrentData()
    setIsLoading(false)
  }

  const handleAddTransaction = async (transaction) => {
    const data = {
      id: await uuidv4(),
      timestamp: new Date().toISOString(),
      user: props.applicationState.authentication.user,
      transaction: transaction || null,
      transactionId: transaction.data.id,
      transactionField: transaction.data.field,
      transactionValue: transaction.data.value,
      subjectId: currentFormState.subjectId,
      type: transaction && transaction?.action?.descShort,
    }

    const filteredEvents = transactionHistory.filter((item) => item.transactionId != data.transactionId || item.transactionField != data.transactionField)
    setTransactionHistory([...filteredEvents, data])
  }

  useEffect(() => {
    ;(async () => {
      if (transaction) {
        await handleAddTransaction(transaction)
      }
    })()
  }, [transaction])

  const loadCurrentData = async () => {
    const id = props.formState.id
    setCurrentFormState(null)
    setIsolations([])

    if (id) {
      const sampleResult = await makeApiCall("get", `/api/samples/${id}`)
      const sampleData = sampleResult.body
      setCurrentFormState(sampleData)
      setIsolations(sampleData.cDna || [])
    }
  }

  const handleChange = async (e, index, data) => {
    console.warn("handle change")
    const target = e.target
    const name = target.name
    let newFormState = {}

    const value = target.type === "checkbox" ? target.checked : target.type === "number" && isFinite(Number(target.value)) ? Number(target.value) : target.value

    let newState
    if (isolations && isolations.length > 0) {
      newState = isolations.map((obj) => {
        if (obj.id === data.id) {
          if (name === "isolationStatus" && value === "accepted") {
            setHideCdnaProperties(false)
            setIsActive(obj.id)
          }
          return { ...obj, [name]: value }
        }
        return obj
      })
      setIsolations(newState)
    }

    newFormState = {
      ...currentFormState,
      id: currentFormState.id,
      cDna: newState,
    }

    setCurrentFormState(newFormState)
    setTransaction({
      data: {
        id: data.id,
        field: name,
        value: value,
        state: null,
      },
      action: {
        descShort: "update-isolation",
        desc: "Update Isolation",
      },
    })

    if (props.change) {
      props.change()
    }
  }

  const handleIsolateBtn = async () => {
    setViewIsolateProperties(true)
    let newData
    setIsolateCount((prevState) => Number(prevState) + 1)
    if (isolateCount < 6) {
      const id = await generateIsolateId()
      console.log(id)
      newData = { id: id }
      setIsolations([...isolations, Object.assign({}, newData)])

      setTransaction({
        data: {
          id: id,
          field: "isolationId",
          value: id,
          state: null,
        },
        action: {
          descShort: "add-isolation",
          desc: "Add Isolation",
        },
      })
    } else {
      setIsDisabled(true)
    }
  }

  const generateIsolateId = () => {
    const id = currentFormState.id
    const cDnaData = currentFormState.cDna
    let generatedId

    if (isolations.length > 0) {
      const inc = isolateCount + 1
      generatedId = id.trim() + "-C" + inc
    } else {
      generatedId = id.trim() + "-C1"
    }
    // setIsolationId(generatedId);
    return generatedId
  }

  const toggleIso = (e) => {
    const eventIndex = Number(e.currentTarget.dataset.event);
    setCardCollapse(prevIndex => (prevIndex === eventIndex ? null : eventIndex));
  };

  const evaluate = (rule, nullValue) => {
    if (rule === undefined || rule === null) {
      return nullValue || false
    }
    if (!(rule instanceof Object)) {
      return rule
    }
    for (const property in rule) {
      if (rule.hasOwnProperty(property)) {
        if (rule[property] instanceof Object) {
          if (rule[property].empty === true && currentFormState[property]) {
            console.log("rule object empty false")
            return false
          }
          if (rule[property].empty === false && !currentFormState[property]) {
            console.log("rule object not empty false")
            return false
          }
        } else if (currentFormState[property] !== rule[property]) {
          console.log("rule value false")
          return false
        }
      }
    }
    console.log("rule true")
    return true
  }

  const submit = async () => {
    setIsLoading(true)
    const result = await makeApiCall("patch", "/api/netest/sample", { data: { ...currentFormState, transactionHistory } })
    if (result.ok) {
      if (result.body?.searchResult) {
        alert.show("Record has been successfully processed", {
          type: "success",
        })
      } else {
        alert.show("Record has no cDNA to process", {
          type: "warning",
        })
      }
      setIsolateCount(1);
      await loadCurrentData()
      setIsLoading(false)
      setIsDisableForm(true)
    } else {
      alert.show(result.error, {
        type: "error",
      })
      setIsLoading(false)
    }
  }

  const onSubmit = async (e) => {
    e.preventDefault()
    if (currentFormState.isolationStatus === "storage" && (currentFormState.location === null || currentFormState.location === undefined)) {
      alert.show("Location is required", {
        type: "error",
      })
    } else {
      if (submitType == "submit") {
        await submit(currentFormState)
      }
    }
  }
  useEffect(() => {
    setIsolateCount(isolations.length)
  }, [isolations, isolateCount])

  return (
    <>
      {isLoading && (
        <center className="mt-5">
          <Spinner type="grow" />
        </center>
      )}
      {!isLoading && currentFormState && isolations && (
        <Form className="GeneralForm" onSubmit={onSubmit}>
          <div className="d-flex gap-3 align-items-end">
            <FormGroup>
              <Label style={{ display: "block" }} for="sampleId">
                Sample ID
              </Label>
              <Input className="text" type="text" name="id" value={currentFormState.id} disabled={true} />
            </FormGroup>
            <div className="mb-3">
              <Button type="button" color="primary" onClick={handleIsolateBtn} disabled={isDisableForm || disableIsolate}>
                Create Isolation
              </Button>
            </div>
          </div>
          {((isolations && isolations.length > 0) || viewIsolateProperties === true) &&
            isolations.map((data, index) => (
              <Card className="max-w-auto mb-2" key={index}>
                <CardHeader className="cursor-pointer card-header-custom" onClick={(e) => toggleIso(e)} data-event={index}>
                  Isolate Properties
                  {cardCollapse === index ? (
                    <span style={{ float: "right", margin: "5px" }} className="fa fa-minus" />
                  ) : (
                    <span style={{ float: "right", margin: "5px" }} className="fa fa-plus" />
                  )}
                </CardHeader>
                <Collapse isOpen={cardCollapse === index}>
                  <CardBody>
                    <ProcessCdnaPropertiesForm
                      loadCurrentData={loadCurrentData}
                      currentData={data}
                      currentFormState={currentFormState}
                      setCurrentFormState={setCurrentFormState}
                      editMode={editForm}
                      sampleId={currentFormState.id}
                      index={index}
                      handleChange={handleChange}
                      submit={submit}
                      setDisplay={props.setDisplay}
                      isDisableForm={isDisableForm}
                      setIsDisableForm={setIsDisableForm}
                      close={props.close}
                      setTransaction={setTransaction}
                      {...props}
                    />
                  </CardBody>
                </Collapse>
              </Card>
            ))}
          <div className={"general-form-buttons"}>
            <Row>
              <Col sm="12">
                <div className="float-right d-flex gap-2">
                  {props.formState && !isDisableForm ? (
                    <Button
                      type="submit"
                      color="danger"
                      onClick={(e) => {
                        console.log("hello")
                        setSubmitType("submit")
                      }}
                      //   style={Object.assign(
                      //     { marginRight: "10px" },
                      //     get(() => props.formDefinition.submit.style, {}),
                      //   )}
                      disabled={evaluate(props.formDefinition.submit.disabled, false)}
                    >
                      {get(() => props.formDefinition.submit.label, "Submit")}
                    </Button>
                  ) : null}
                  {props.formDefinition.edit && props.formState && isDisableForm && !currentFormState.taps && (
                    <Button
                      type="submit"
                      color="danger"
                      onClick={() => {
                        setIsDisableForm(false)
                        // setIsEditForm(true);
                      }}
                      //   style={Object.assign(
                      //     { marginRight: "10px" },
                      //     get(() => props.formDefinition.edit.style, {}),
                      //   )}
                      disabled={evaluate(props.formDefinition.edit.disabled, false)}
                    >
                      {get(() => props.formDefinition.edit.label, "Edit")}
                    </Button>
                  )}
                  {props.cancel ? (
                    <Button
                      type="button"
                      outline
                      color="secondary"
                      style={get(() => props.formDefinition.cancel.style)}
                      onClick={() => {
                        if (props.cancel) {
                          props.cancel()
                        }
                      }}
                    >
                      {get(() => props.formDefinition.cancel.label, "Cancel")}
                    </Button>
                  ) : null}
                  {!props.cancel && (
                    <Button
                      type="button"
                      outline
                      color="secondary"
                      // style={get(() => props.formDefinition.close.style)}
                      onClick={() => {
                        setIsolateCount(1)
                        if (props.close) {
                          props.close()
                        }

                        if (props.setExistingSubjects) {
                          props.setExistingSubjects([])
                        }
                      }}
                    >
                      {get(() => props.formDefinition.close.label, "Close")}
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </Form>
      )}
    </>
  )
}

export default ProcessSampleForm
