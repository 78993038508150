import React from "react"
import { Card, CardBody } from "reactstrap"
import Breadcrumb from "../custom/Breadcrumb"
import { NotificationsTable } from "../tables/NotifcationTable"

function NotificationsView(props) {
  const permission = props && props.applicationState.authentication.user.permission
  const breadcrumbItems = [
    {
      label: "Announcement",
      link: `/notification-view`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Announcement" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <NotificationsTable allowPageSize allowPaginate isGlobalFilter permission={permission} />
        </CardBody>
      </Card>
    </div>
  )
}

export default NotificationsView
