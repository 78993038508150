const trayFormDefinition = {
    formLabel: null,
    submit: {
        label: 'Submit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        },
        //disabled: {testId: {empty: true}}
    },
    update: {
        label: 'Update',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center',
            marginRight: '10px'
        },
        disabled: false
    },
    cancel: {
        label: 'Cancel',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    edit: {
        label: 'Edit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    sections: [
        {
            label: 'Details',
            columns: 2,
            fields: [
                {
                    label: 'Tray Id',
                    name: 'id',
                    type: 'text',
                    nonEditable: true,
                    readonly: true,
                },
                {
                    label: 'Tray Configuration Id',
                    name: 'trayConfigurationId',
                    type: 'text',
                    disabled: true,
                    readonly: true,
                },
                {
                    label: 'Tray Type',
                    name: 'trayConfigurationLabel',
                    type: 'text',
                    disabled: true,
                    readonly: true,
                },
                {
                    label: 'Stage',
                    name: 'stage',
                    type: 'number',
                    disabled: true,
                    readonly: true,
                },
                {
                    label: 'Test Type',
                    name: 'testType',
                    type: 'text',
                    disabled: true,
                    readonly: true,
                },
                {
                    label: 'Capacity',
                    name: 'maxFill',
                    type: 'number',
                    disabled: true,
                    readonly: true,
                },
                {
                    label: 'Owner',
                    name: 'ownerId',
                    type: 'text',
                    disabled: true,
                    readonly: true,
                }
            ]
        },
        {
            label: 'Additional data',
            columns: 1,
            fields: [
                {
                    label: 'Lot Number',
                    name: 'lotNumber',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'200px'}
                }
            ]
        },
        {
            label: 'Processing',
            columns: 1,
            fields: [
                {
                    label: 'Status',
                    name: 'status',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'500px'},
                    disabled: true,
                    readonly: true
                },
                {
                    label: 'tests',
                    name: 'tests',
                    type: 'hidden',
                    disabled: true,
                    visible: false
                }
            ]
        }
    ]
};

export default trayFormDefinition;
