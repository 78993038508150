import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useProductList({ variables, ...rest } = {}) {
  const { query } = useRequestProcessor()

  const fields = ["id", "productName", "testTypes", "productGroup", "status"].map((item) => `"${item}"`)

  const params = encodeURIComponent(`[${fields.toString()}]`)

  const newVariables = {
    ...variables,
    selector: {
      ...(variables?.selector || {}),
    },
  }

  if (newVariables?.selector?._id) {
    newVariables.selector._id = {
      $regex: "(?i)" + newVariables.selector._id,
    }
  }
  if (newVariables?.selector?.productName) {
    newVariables.selector.productName = {
      $regex: "(?i)" + newVariables.selector.productName,
    }
  }
  return query(
    ["productListQueries", newVariables],
    () => axiosClient.get(`/products?${paramsToQuery(newVariables, { allowEmptyObject: true })}&fields=${params}`).then((res) => res.data),
    { ...rest },
  )
}
export function useProductListInfo({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["productInfoQueries", variables], () => axiosClient.get(`/products?${paramsToQuery(variables)}`).then((res) => res.data), { ...rest })
}

export function useProductFormSubmit({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("submit-product", (update) => axiosClient.post(`product`, update).then((res) => res.data), { ...rest })
}
export function useProductDetailSubmit({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("update-product", (update) => axiosClient.put(`product`, update).then((res) => res.data), { ...rest })
}
