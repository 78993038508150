import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useInsuredQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  console.log("Variables in useInvoiceQuery:", variables)

  const fields = [
    "_id",
    "subjectId",
    "lastName",
    "firstName",
    "middleInitial",
    "testId",
    "physicianFullName",
    "tubeType",
    "testType",
    "collectionMethod",
    "laboratoryReceivedDateTime",
    "insuranceCompanyName",
    "insuranceGroupId",
    "insuranceSubscriberNo",
    "insuranceReportedDate",
  ]
  const fieldsParam = encodeURIComponent(JSON.stringify(fields))

  const fixedSelector = {
    insured: true,
    testId: {
      $or: [{ $regex: "JLBG" }, { $regex: "CT" }],
    },
  }

  const newVariables = {
    ...variables,
    selector: { ...fixedSelector, ...variables.selector },
    action: {
      updateInsuranceReportedDate: true,
    },
  }

  return query(["invoices", newVariables], () => axiosClient.get(`/insured?fields=${fieldsParam}&${paramsToQuery(newVariables)}`).then((res) => res.data), {
    ...rest,
  })
}
