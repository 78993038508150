import React, { useEffect, useRef, useState } from 'react';
import ReactLoading from "react-loading";
import { useAlert } from "react-alert";
import { makeApiCall } from "../../api/generic-api";
import lookups from "../../configuration/lookups";
import DataWowNotifications from "./data-wow-notifications";
import _ from 'lodash';
import notificationFullDefinition from "../../configuration/notification-full-definition"; // Assuming lodash is being used

function NotificationsView(props) {
	const requisitionsStructure = notificationFullDefinition;
	const childRef = useRef(null);
	const alert = useAlert();
	
	async function createNotification(formState) {
		const sanitizedFormState = _.omitBy(formState, _.isEmpty);
		const result = await makeApiCall('post', '/api/notifications', sanitizedFormState);
		
		if (result.ok) {
			alert.show("Successfully created", { type: 'success' });
		} else {
			const details = `Details: code ${result.statusCode}; ${result.error}`;
			alert.show(details, { type: 'error' });
		}
		
		return result;
	}
	
	async function updateNotification(formState) {
		const sanitizedFormState = _.omitBy(formState, _.isEmpty);
		
		const result = await makeApiCall('put', `/api/notifications/${sanitizedFormState.id}`, sanitizedFormState);

		if (result.ok) {
			alert.show("Successfully updated", { type: 'success' });
		} else {
			const details = `Details: code ${result.statusCode}; ${result.error}`;
			alert.show(details, { type: 'error' });
		}

		return result;
	}
	
	return (
		<div>
			<h3 className="text-center mt-5">Announcements</h3>
			<DataWowNotifications
				className="wide-container"
				structure={requisitionsStructure}
				loadData={makeApiCall}
				lookups={lookups}
				ref={childRef}
				customRowActions={[
					{
						type: 'button',
						label: 'Delete',
						color: 'danger',
						style: { color: 'black' },
						details: {
							download: false,
							endpoint: 'delete',
							idToken: ':id',
						}
					}
				]}
				showPaginationButtons={true}
				update={updateNotification}
				submit={createNotification}
				showAddButton={true}
				{...props}
			/>
		</div>
	);
}

export default NotificationsView;
