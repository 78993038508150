import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Container, Row, Col, Form, Alert } from 'react-bootstrap';
import { makeApiCall } from '../../api/generic-api';
import ReactLoading from 'react-loading';

const SystemSettingsView = () => {
	const [clientPortalSettings, setClientPortalSettings] = useState({
		clientPortalRegistrationEmails: false,
	});
	const [cancerTestSettings, setCancerTestSettings] = useState({
		progressionCategory: false,
	});
	const [message, setMessage] = useState(null);
	const [loading, setLoading] = useState(true);
	const [toggleLoading, setToggleLoading] = useState({
		clientPortalRegistrationEmails: false,
		progressionCategory: false,
	});
	
	const settingTypes = ['client-portal-settings'];
	// const settingTypes = ['system-settings', 'cancer-test-settings'];
	
	useEffect(() => {
		const fetchSettings = async (type) => {
			try {
				const result = await makeApiCall('get', `/api/settings/${type}`);
				if (result.ok) {
					if (type === 'client-portal-settings') {
						setClientPortalSettings(result.body);
					} else if (type === 'cancer-test-settings') {
						setCancerTestSettings(result.body);
					}
				} else {
					setMessage(`Failed to fetch ${type}.`);
				}
			} catch (error) {
				setMessage(`Error fetching ${type}.`);
			} finally {
				setLoading(false);
			}
		};
		
		settingTypes.forEach(fetchSettings);
	}, []);
	
	const handleToggle = async (setting, type) => {
		setMessage(null);
		setToggleLoading((prevState) => ({ ...prevState, [setting]: true }));
		
		let updatedSettings;
		if (type === 'client-portal-settings') {
			updatedSettings = {
				...clientPortalSettings,
				[setting]: !clientPortalSettings[setting],
			};
			setClientPortalSettings(updatedSettings);
		} else if (type === 'cancer-test-settings') {
			updatedSettings = {
				...cancerTestSettings,
				[setting]: !cancerTestSettings[setting],
			};
			setCancerTestSettings(updatedSettings);
		}
		
		await updateSettingsOnServer(updatedSettings, type);
		
		setToggleLoading((prevState) => ({ ...prevState, [setting]: false }));
	};
	
	const updateSettingsOnServer = async (updatedSettings, type) => {
		try {
			const result = await makeApiCall('post', `/api/settings/${type}`, updatedSettings);
			if (result.ok) {
				setMessage('System Settings have been successfully updated.');
			} else {
				setMessage('Failed to update settings.');
			}
		} catch (error) {
			setMessage('Error updating settings.');
		}
	};
	
	return (
    <Container className="d-flex justify-content-center">
      <Row className="w-75">
        <Col>
          <h3 className="text-center mt-5">System Settings</h3>
          {message && <Alert variant="info">{message}</Alert>}
          {loading ? (
            <div className="d-flex justify-content-center my-3">
              <ReactLoading type="spin" color="red" height={30} width={30} />
            </div>
          ) : (
            <>
              <div className="container border p-3 mb-4">
                <h4 className="my-4">Client Portal Settings</h4>
                <Suspense
                  fallback={
                    <div className="d-flex justify-content-center align-items-center min-vh-100">
                      <ReactLoading type="spin" color="red" height={30} width={30} />
                    </div>
                  }
                >
                  <Form>
                    <Form.Group as={Row} className="mb-3" controlId="formPatientRegistrationEmails">
                      <Col xs="auto" className="d-flex align-items-center">
                        {toggleLoading.clientPortalRegistrationEmails ? (
                          <div className="d-flex justify-content-center align-items-center" style={{ width: "3rem", height: "1.5rem" }}>
                            <ReactLoading type="spin" color="red" height={20} width={20} />
                          </div>
                        ) : (
                          <Form.Check
                            type="switch"
                            id="patientRegistrationEmails"
                            checked={clientPortalSettings.clientPortalRegistrationEmails}
                            onChange={() => handleToggle("clientPortalRegistrationEmails", "client-portal-settings")}
                            className="me-2"
                          />
                        )}
                        <Form.Label className="mb-0">Patient/Physician Registration and Events Email.</Form.Label>
                      </Col>
                    </Form.Group>
                  </Form>
                </Suspense>
              </div>
            </>
          )}
        </Col>
      </Row>
    </Container>
  )
};

export default SystemSettingsView;
