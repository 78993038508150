import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useProcessSamplesQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const fields = [
    `"_id"`,
    `"id"`,
    `"subjectId"`,
    `"sampleCollectionDateTime"`,
    `"laboratoryReceivedDateTime"`,
    `"collectionMethod"`,
    `"status"`,
    `"id"`,
    `"isolateSample"`,
    `"testType"`,
  ]
  const params = encodeURIComponent(`[${fields.toString()}]`)

  return query(["samples", variables], () => axiosClient.get(`/netest/samples?${paramsToQuery(variables)}&fields=${params}`).then((res) => res.data), {
    ...rest,
  })
}

export function useSamplesQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const fields = [
    `"_id"`,
    `"id"`,
    `"subjectId"`,
    `"sampleCollectionDateTime"`,
    `"laboratoryReceivedDateTime"`,
    `"collectionMethod"`,
    `"status"`,
    `"id"`,
    `"isolateSample"`,
    `"testType"`,
    `"sampleLocation"`,
    `"tubeType"`,
  ]
  const params = encodeURIComponent(`[${fields.toString()}]`)

  return query(["samples", variables], () => axiosClient.get(`/samples?${paramsToQuery(variables)}&fields=${params}`).then((res) => res.data), {
    ...rest,
  })
}

export function useSampleDetailsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["sampleDetails", variables?.id], () => axiosClient.get(`/samples/${variables?.id || ""}`).then((res) => res.data), { ...rest })
}
