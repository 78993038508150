import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useAnnouncementQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["orders", variables], () => axiosClient.get(`/notifications?${paramsToQuery(variables)}`).then((res) => res.data), { ...rest })
}
export function useAnnouncementsInfo({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["AnnouncementInfo", variables], () => axiosClient.get(`/notifications?${paramsToQuery(variables)}`).then((res) => res.data), {
    ...rest,
  })
}
export function useAnnouncementsDetailsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query("AnnouncementDetails", () => axiosClient.get(`/notifications/${variables?.id || ""}?type=${variables?.type}`).then((res) => res.data), {
    ...rest,
  })
}
