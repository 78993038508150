import React, { useCallback, useMemo } from "react"
import { useOrdersQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"
dayjs.extend(utc)

export function OrdersTable({ pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector,
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: ordersData,
    isLoading,
    isFetching,
    refetch,
    error,
  } = useOrdersQuery({
    variables: queryVariables,
  })

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "_id",
        hasSort: true,
        csvAccessor: "csvId",
      },
      {
        Header: "Date",
        accessor: "orderCreatedTimeStamp",
        hasSort: true,
      },
      {
        Header: "Patient ID",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Product Name",
        accessor: "product",
      },
      {
        Header: "Price",
        accessor: "totalPrice",
        hasSort: true,
        isNumeric: true,
      },
      {
        Header: "Fee Code",
        accessor: "feeCode",
        hasSort: true,
      },
      {
        Header: "Paid",
        accessor: "paid",
      },
      {
        Header: "Billed",
        accessor: "billed",
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const getProcessedTableData = useCallback(
    (_ordersData) =>
      (_ordersData &&
        _ordersData?.map((item) => ({
          _id: item._id,
          subjectId: (
            <Link to={`/subject-info/${item.subjectId || ""}/personal`} className="text-secondary" target="_blank">
              {item.subjectId}
            </Link>
          ),
          csvId: item._id,
          csvSubjectId: item.subjectId,
          orderCreatedTimeStamp: item?.orderCreatedTimeStamp && dayjs(item?.orderCreatedTimeStamp).local().format("MM/DD/YYYY h:mm A"),
          product: item?.product?.join(", "),
          totalPrice: item?.price,
          feeCode: item?.feeCode,
          paid: item?.paid ? "Yes" : "No",
          billed: item?.billed ? "Yes" : "No",
          action: (
            <Link to={`/order-info/${item?._id || ""}`} className="btn btn-outline-primary">
              View
            </Link>
          ),
        }))) ||
      [],
    [],
  )

  const data = useMemo(() => getProcessedTableData(ordersData), [ordersData, getProcessedTableData])

  const filters = useMemo(() => {
    return [
      {
        field: "_id",
        operator: "$eq",
        label: "Search ID",
        placeholder: "Type in Order ID",
        removeSpaces: true,
      },
      {
        field: "subjectId",
        operator: "$eq",
        label: "Search Patient ID",
        placeholder: "Type in Patient ID",
        removeSpaces: true,
      },
    ]
  }, [])

  //meta title
  // document.title = "Data Tables | Skote - React Admin & Dashboard Template";

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="orderCreatedTimeStamp"
        defaultSortDir="desc"
        downloadFileName="Orders"
        csvQuery={useOrdersQuery}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
    </div>
  )
}
