import React, { useState } from 'react';
import CovidStreamlineDisplayForm from "./covid-streamline-display-form";

function AccessionSearchResults(props) {
    return (
      <div>
          <div style={{marginTop:'30px'}}>
              <CovidStreamlineDisplayForm  {...props}/>
          </div>
      </div>
      
    );
}

export default AccessionSearchResults;
