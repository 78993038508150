import React, { useRef, useState } from "react"
import { Card, CardBody } from "reactstrap"
import FileUploader from "./file-uploader"
import { ResultsUploadTable } from "./tables/ResultsUploadTable"
import Breadcrumb from "./custom/Breadcrumb"

function ProcessCancerPcrView(props) {
  const [tableKey, setTableKey] = useState(0)

  const fixedSelector = {
    $or: [
      {
        cancerPcr: true,
      },
      {
        cancerPcr: {
          $exists: true,
        },
      },
    ],
  }

  const callRefresh = () => {
    setTableKey((prev) => prev + 1)
  }

  const permission = props && props.applicationState.authentication.user.permission

  return (
    <div>
      <div className="wide-container">
        <Breadcrumb title="" breadcrumbItems={[]} />
        <Card className="mb-3">
          <CardBody>
            <FileUploader title="Cancer PCR Results Upload" uri="/api/pcr-file/cancer" refresh={callRefresh} {...props}></FileUploader>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <ResultsUploadTable
              key={tableKey}
              allowPageSize
              allowPaginate
              downloadFileName="Cancer PCR Results Upload"
              permission={permission}
              customSelect={fixedSelector}
              defaultSort="submittedTimestamp"
              defaultSortDir="desc"
              {...props}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ProcessCancerPcrView
