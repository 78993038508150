import React, { useEffect, useMemo, useState } from "react"
import { Link } from "react-router-dom"
import { useSubjectDuplicatesQuery } from "api/getters"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { useMergeSubjects } from "api/mutators"
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap"
dayjs.extend(utc)

export function DuplicatesTable({ patientId, pageSize: pageSizeProp, firstName, lastName, onMergeSuccess = () => {}, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()
  const {
    data: patientsData,
    isLoading,
    isFetching,
    refetch,
    remove,
  } = useSubjectDuplicatesQuery({
    variables: {
      sort,
      page,
      selector,
      pageSize: rest.customPageSize || pageSizeProp || pageSize,
      firstName,
      lastName,
    },
    enabled: Boolean(firstName && lastName),
  })

  useEffect(() => {
    return () => {
      remove()
    }
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: "",
        accessor: "selected",
      },
      {
        Header: "Patient ID",
        accessor: "id",
        hasSort: true,
      },
      {
        Header: "First Name",
        accessor: "firstName",
        hasSort: true,
      },
      {
        Header: "Last Name",
        accessor: "lastName",
        hasSort: true,
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Date Of Birth",
        accessor: "dateOfBirth",
        hasSort: true,
      },
      {
        Header: "Phone",
        accessor: "phoneNumber",
      },
      {
        Header: "No. of Test",
        accessor: "noOfTest",
        isNumeric: true,
        hasSort: true,
      },
    ],
    [],
  )

  const [checkboxes, setCheckboxes] = useState([])
  const [mergedIds, setMergedIds] = useState([])
  const [mergeSuccess, setMergeSuccess] = useState(false)
  const [showModal, setShowModal] = useState(false)

  const {
    mutate: merge,
    error: mergeError,
    isLoading: merging,
  } = useMergeSubjects({
    onSuccess: (mergeData) => {
      onMergeSuccess()
      setMergedIds((prev) => [...prev, ...checkboxes.filter((item) => item.checked).map((item) => item.id)])
      setMergeSuccess(true)
      setShowModal(false)
    },
  })

  const data = useMemo(
    () =>
      (patientsData?.searchResult &&
        patientsData?.searchResult
          ?.filter((item) => item.id !== patientId && !mergedIds.includes(item.id))
          .map((item, idx) => ({
            selected: (
              <input
                key={item.id}
                type="checkbox"
                className={`form-check-input`}
                onChange={(e) => {
                  e.persist()
                  setCheckboxes((prev) => {
                    const newPrev = [...prev]
                    newPrev[idx].checked = e.target?.checked || false
                    return newPrev
                  })
                }}
              />
            ),
            id: (
              <Link target="_blank" to={`/subject-info/${item.id}/personal`} className="text-secondary text-nowrap">
                {item.id}
              </Link>
            ),
            idString: item.id,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName,
            dateOfBirth: dayjs(item?.dateOfBirth).format("YYYY/MM/DD"),
            phoneNumber: item.phoneNumber,
            noOfTest: item.noOfTest //item.testResults?.reduce((sum, result) => sum + result.tests.length, 0),
          }))) ||
      [],
    [patientsData?.searchResult, mergedIds],
  )

  useEffect(() => {
    setCheckboxes(data.map((item) => ({ id: item.idString, checked: false })))
  }, [data])

  const handleMergeSubjects = async () => {
    setMergeSuccess(false)
    merge({
      mainSubjectId: patientId,
      selectedSubjectIds: checkboxes.filter((item) => item.checked).map((item) => item.id),
    })
  }

  const hasSelected = useMemo(() => checkboxes.filter((item) => item.checked).length > 0, [checkboxes])

  return (
    <div>
      <Alert hidden={!mergeError} color="danger">
        {mergeError?.response?.data?.message}
      </Alert>
      <Alert hidden={!mergeSuccess} color="success">
        Patient records successfully merged
      </Alert>
      <TableContainer refetch={refetch} columns={columns} data={data} isLoading={isLoading || isFetching} selector={selector} sort={sort} {...rest} />
      {Boolean(data?.length) && (
        <Button color="danger" onClick={() => setShowModal(true)} disabled={!hasSelected}>
          Merge Selected Subjects
        </Button>
      )}
      <Modal centered isOpen={showModal} toggle={setShowModal}>
        <ModalHeader>Confirm Merge</ModalHeader>
        <ModalBody>Merge selected subject(s) into the current subject?</ModalBody>
        <ModalFooter>
          <Button type="button" color="danger" onClick={handleMergeSubjects} disabled={merging}>
            {merging && <Spinner size="sm" />} Merge
          </Button>
          <Button type="button" outline onClick={() => setShowModal(false)} disabled={merging}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
