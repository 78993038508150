import React, {useEffect, useState } from 'react';
import './tray-layout.css';
import { get } from "../utility/client-utility";
import { makeApiCall } from "../api/generic-api";
import { IoTrashBinSharp } from "react-icons/io5";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {useAlert} from "react-alert";

const TrayLayoutSimple = (props) => {
    const alert = useAlert();
    const [tray, setTray] = useState(props.tray);
    console.log('tray', tray);
    const tests = props.tests || props.tray?.tests
    // const trayConfiguration = get(() => props.trayConfiguration);
    const trayConfiguration = props.trayConfiguration;
    // console.log('ems tray config bez simple', trayConfiguration)
    const enabledBin = props.enabledBin;
    console.log('bin is enabled', enabledBin)

    let columnIndexes = [];
    let rowIndexes = [];
    let columnHeadings
    let rowLabels;
    const [defaultTrayConfig, setDefaultTrayConfig] = useState(null);
    const [showRemoveModal, setShowRemoveModal] = useState(false);
    const [sampleToRemove, setSampleToRemove] = useState(null);

    if (trayConfiguration) {
        columnIndexes = Array.from(Array(trayConfiguration?.columnCount + 1).keys());
        rowIndexes = Array.from(Array(trayConfiguration?.rowCount).keys());
        columnHeadings = [props.title || ''].concat(trayConfiguration?.columnLabels);
        rowLabels = trayConfiguration?.rowLabels;
    }


    const getDefaultConfig = async () => {
        const testType = trayConfiguration?.testType
        const result = await makeApiCall('get', '/api/test-configuration');
        const trayConfig = result.body?.filter((item) => item.testType === testType && item?.product?.status == "active")
        setDefaultTrayConfig(trayConfig);
    };

    const handleRemoveSample = (sampleId) => {
        console.log('sample to remove', sampleId);
        setSampleToRemove(sampleId);
        setShowRemoveModal(true);
    };

    useEffect(() => {
        getDefaultConfig();
    }, [])

    const getCellIndex = (columnIndex, rowIndex) => {
        return columnIndex - 1 + rowIndex * trayConfiguration.columnCount
    };

    const getCellValue = (columnIndex, rowIndex) => {
        const cellIndex = getCellIndex(columnIndex, rowIndex);
        // console.log('cellindex ', cellIndex);
        if(columnIndex !== 0 && cellIndex >= trayConfiguration.maxFill) {
            return '- X -';
        }
        let testCellIndex;

        if (enabledBin === true) {
            testCellIndex = <Button outline color="danger" onClick={() => handleRemoveSample(tests[cellIndex])}>{tests[cellIndex]} <IoTrashBinSharp style={{ color: 'red', marginBottom: "3px", marginLeft: "10px"}}/></Button>;
        } else {
            testCellIndex = tests[cellIndex];
        }
        const testId = tests && cellIndex >= 0 && cellIndex < tests.length ? testCellIndex  : '-';
        return columnIndex === 0 ? rowLabels[rowIndex] : testId;
    };

    const handleCloseModal = () => {
        props.setEnableBin(false);
        setShowRemoveModal(false)
    };

    const removeSample = async () => {
        if (sampleToRemove) {
            const url = '/api/tray/remove-sample';
            const result = await makeApiCall('put', url, {sampleId: sampleToRemove, trayId: tray._id || tray.id});
            console.log('result', result);
            if (result.ok) {
                alert.show('Sample has been removed', { type: 'success'});
                let trayData = result.body;
                trayData = {
                    ...trayData,
                    testConfiguration: tray.testConfiguration
                }
                props.setFormState(trayData);
                props.setCurrentRecord(trayData);
                handleCloseModal()
            } else {
                alert.show(result.message, {type: 'error'})
            }
        }
    };

    return (
        <div className={'tray-layout'}>
            <table>
                <thead>
                <tr>
                    {columnHeadings?.map((heading, i) => <th key={i}>{heading}</th>)}
                </tr>
                </thead>
                <tbody>
                    {rowIndexes?.map(rowIndex =>
                        <tr key={rowIndex}>
                            {columnIndexes?.map(columnIndex =>
                                <td key={getCellIndex(columnIndex, rowIndex)}>{ getCellValue(columnIndex, rowIndex)}</td>
                            )}
                        </tr>
                    )}
                </tbody>
            </table>

            <Modal isOpen={showRemoveModal} toggle={handleCloseModal} backdrop={true}>
                <ModalHeader toggle={handleCloseModal}>Warning!</ModalHeader>
                <ModalBody>
                    <div>Are you sure you want to remove sample {sampleToRemove} from this tray?</div>
                </ModalBody>
                <ModalFooter>
                    <Button type="button" outline color="secondary" onClick={handleCloseModal}>Cancel</Button>
                    {" "}
                    <Button type="button" color="success" onClick={removeSample}>Confirm</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default TrayLayoutSimple;
