import processSampleFormDefinition from "./process-sample-form-definition";

const processSampleFullDefinition = {
  key: "_id",
  table: {
    key: "_id",
    columns: [
      {
        label: "#",
        type: "rowNumber",
        filterType: "none",
        sortType: "none",
      },
      {
        label: "Sample Id",
        field: "id",
        type: "text",
        filterType: "equals",
        sortType: "both",
      },
      {
        label: "Patient Id",
        field: "subjectId",
        type: "text",
        filterType: "equals",
        sortType: "both",
      },
      {
        label: "Collected",
        field: "sampleCollectionDateTime",
        type: "timestamp",
        format: "toDateString",
        sortType: "both",
      },
      {
        label: "Accessioned",
        field: "laboratoryReceivedDateTime",
        type: "timestamp",
        format: "toDateString",
        sortType: "both",
      },
      {
        label: "Collection Method",
        field: "collectionMethod",
        type: "text",
        filterType: "equals",
        sortType: "both",
      },
      {
        label: "Status",
        field: "status",
        type: "text",
        filterType: "equals",
        sortType: "both",
      },
    ],
    defaultSort: [{ _id: "asc" }],
  },

  form: processSampleFormDefinition,

  endpoints: {
    patch: "/api/netest/sample/:id",
    export: {
      uri: "/api/netest/samples/:id/export",
      verb: "get",
      type: "text/plain",
      filename: "netest-samples-export-:id.txt",
    },
    search: {
      uri: "/api/netest/samples?",
      verb: "get",
    },
  },
  pageSize: 10,
}

export default processSampleFullDefinition;
