import React, { useCallback, useMemo, useState } from "react"
import { useRequisitionsQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import Select from "react-select"
import { useQuery } from "hooks/query"
import { Badge, Button, Modal, ModalBody, ModalFooter, ModalHeader, PopoverBody, PopoverHeader, Spinner, UncontrolledPopover } from "reactstrap"
import GenerateRecordId from "components/custom/GenerateRecordId"
import useDisclosure from "hooks/disclosure"
import { ModalTitle } from "react-bootstrap"
import { useAcceptRequisition } from "api/mutators"
import { useAlert } from "react-alert"
dayjs.extend(utc)

export function RequisitionsTable({ pageSize: pageSizeProp, customSelect = {}, ...rest }) {
  const query = useQuery()
  const alert = useAlert()
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const [requisitionId, setRequisitionId] = useState(null)
  const [acceptId, setAcceptId] = useState(null)
  const [rejectId, setRejectId] = useState(null)
  const { isOpen, toggleModal, closeModal } = useDisclosure()
  const { isOpen: acceptIsOpen, toggleModal: acceptToggleModal, closeModal: acceptCloseModal, openModal: acceptOpenModal } = useDisclosure()

  const queryVariables = {
    sort,
    page,
    selector: {
      ...selector,
      ...customSelect,
    },
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: requisitionsData,
    isLoading,
    refetch,
  } = useRequisitionsQuery({
    refetchInterval: 3000,
    variables: queryVariables,
  })

  const { mutate, isLoading: mutating } = useAcceptRequisition({
    variables: { id: acceptId || rejectId },
    onSuccess: () => {
      refetch()
      alert.show("Action has been successfully executed", {
        type: "success",
      })
      window.scrollTo(0, document.body.scrollHeight)
      acceptCloseModal()
      setAcceptId(null)
      setRejectId(null)
    },
    onError: (registerError) => {
      const detail = registerError?.response?.data?.message || registerError?.response?.data?.reason
      alert.show(detail, {
        type: "error",
      })
      window.scrollTo(0, document.body.scrollHeight)
    },
  })

  const columns = useMemo(
    () => [
      {
        Header: "Submitted",
        accessor: "data.submittedTimestamp",
        csvAccessor: "submittedTimestamp",
        hasSort: true,
      },
      {
        Header: "First Name",
        accessor: "data.firstName",
        csvAccessor: "firstName",
        hasSort: true,
      },
      {
        Header: "Last Name",
        accessor: "data.lastName",
        csvAccessor: "lastName",
        hasSort: true,
      },
      {
        Header: "Date of Birth",
        accessor: "data.dateOfBirth",
        csvAccessor: "dateOfBirth",
        hasSort: true,
      },
      {
        Header: "Status",
        accessor: "status",
        csvAccessor: "csvStatus",
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const hasRole = (role) => {
    const roles = rest.applicationState.authentication.user?.roles
    return roles && roles.length && roles.includes(role)
  }

  const getProcessedTableData = useCallback(
    (_requisitionsData) =>
      (_requisitionsData &&
        _requisitionsData?.map((item) => ({
          submittedTimestamp: item?.data.submittedTimestamp ? dayjs(item?.data.submittedTimestamp).format("MM/DD/YYYY") : "",
          firstName: item?.data.firstName,
          lastName: item?.data.lastName,
          dateOfBirth: item?.data.dateOfBirth && dayjs(item?.data.dateOfBirth).format("MM/DD/YYYY"),
          data: {
            submittedTimestamp: item?.data.submittedTimestamp ? dayjs(item?.data.submittedTimestamp).format("MM/DD/YYYY") : "",
            firstName: item?.data.firstName,
            lastName: item?.data.lastName,
            dateOfBirth: item?.data.dateOfBirth && dayjs(item?.data.dateOfBirth).format("MM/DD/YYYY"),
          },
          csvStatus: item?.status || "pending",
          status: (
            <Badge
              className="text-uppercase"
              color={item?.status === "accepted" ? "success" : item?.status === "rejected" ? "danger" : item?.status === "done" ? "primary" : "secondary"}
            >
              {item?.status || "pending"}
            </Badge>
          ),
          action: (
            <div className="d-flex gap-2 text-nowrap">
              <Link
                to={`/requisition-netest/${item?.id?.replace(/\./g, "_")}${item?.status ? `?status=${item.status}` : ""}`}
                className="btn btn-outline-primary"
              >
                View
              </Link>
              {(rest.permission === "administrator" || hasRole("accessioning") || hasRole("laboratoryDirector")) && (
                <>
                  {Boolean(item?.data?.sampleId) && Boolean(!item?.status) && (
                    <Button
                      outline
                      color="success"
                      onClick={() => {
                        setAcceptId(item?.id)
                        setRejectId(null)
                        acceptOpenModal()
                      }}
                    >
                      Accept
                    </Button>
                  )}
                  {Boolean(!item?.status) && (
                    <Button
                      outline
                      color="danger"
                      disabled={mutating && rejectId === item?.id}
                      onClick={() => {
                        setRejectId(item?.id)
                        setAcceptId(null)
                        acceptOpenModal()
                      }}
                    >
                      Reject
                    </Button>
                  )}
                  {Boolean(!item?.data?.sampleId) && item.status !== "rejected" && (
                    <Button
                      outline
                      color="secondary"
                      onClick={() => {
                        setRequisitionId(item.id)
                        toggleModal()
                      }}
                    >
                      Assign ID
                    </Button>
                  )}
                </>
              )}
            </div>
          ),
        }))) ||
      [],
    [mutating],
  )

  const data = useMemo(() => getProcessedTableData(requisitionsData), [requisitionsData, getProcessedTableData])

  //meta title
  // document.title = "Data Tables | Skote - React Admin & Dashboard Template";

  const filters = useMemo(() => {
    return [
      {
        field: "data.firstName",
        operator: "$eq",
        label: "First Name",
        placeholder: "Type in first name",
      },
      {
        field: "data.lastName",
        operator: "$eq",
        label: "Last Name",
        placeholder: "Type in last name",
      },
      {
        label: "Status",
        placeholder: "Select Status",
        field: "status",
        renderCustom: (filter, value, onChange) => {
          const options = [
            { label: "Pending", value: "pending" },
            { label: "Accepted", value: "accepted" },
            { label: "Rejected", value: "rejected" },
          ]
          return (
            <div className="input-group">
              <Select
                classNamePrefix="select2-selection"
                placeholder={filter.placeholder}
                options={options}
                value={options.find((item) => (value ? item.value === value : item.value === "pending"))}
                onChange={(_value) => onChange(_value?.value)}
              />
            </div>
          )
        },
      },
      {
        label: "Date of Birth",
        field: "data.dateOfBirth",
        operator: "$eq",
        type: "date",
      },
    ]
  }, [selector, query])

  const activeRequisitionData = requisitionsData?.find((item) => item?.id === (acceptId || rejectId))

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="data.submittedTimestamp"
        defaultSortDir="desc"
        downloadFileName="Cancer Sample Requisition Backlog"
        csvQuery={useRequisitionsQuery}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
      <Modal toggle={toggleModal} isOpen={isOpen}>
        <ModalHeader>
          <ModalTitle>Assign Sample ID</ModalTitle>
          <span style={{ fontSize: "small", fontStyle: "italic", marginBottom: "10px" }}>Sample ID can be generated OR typed manually</span>
        </ModalHeader>
        <ModalBody>
          <GenerateRecordId
            requisitionId={requisitionId}
            onSuccess={() => {
              closeModal()
              refetch()
            }}
            onCancel={closeModal}
          />
        </ModalBody>
      </Modal>
      <Modal toggle={acceptToggleModal} isOpen={acceptIsOpen}>
        <ModalHeader>
          <ModalTitle>Confirm</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {acceptId ? "Accept requisition" : "Reject requisition"} of {activeRequisitionData?.data?.firstName} {activeRequisitionData?.data?.lastName}?
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={mutating}
            onClick={() => {
              mutate({ status: acceptId ? "accepted" : "rejected" })
            }}
          >
            {mutating && <Spinner size="sm" />} Yes
          </Button>
          <Button outline disabled={mutating} onClick={acceptCloseModal}>
            No
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
