const templateFormDefinition = {
    formLabel: null,
    sections: [
        {
            label: '',
            columns: 1,
            fields: [
                {
                    label: 'File',
                    name: 'id',
                    type: 'text',
                    style: {maxWidth: '400px'},
                    nonEditable: true,
                },
                {
                    label: 'Type',
                    name: 'templateType',
                    type: 'text',
                    style: {maxWidth: '400px'},
                    nonEditable: true,
                },
                {
                    label: 'Created At',
                    name: 'dateCreated',
                    type: 'timestamp',
                    format: 'toLocalizedDateString',
                    style: {maxWidth: '400px'},
                    sortType: 'none',
                    filterType: 'equals'
                },
                {
                    label: "System Template?",
                    name: "isSystemTemplate",
                    type: 'checkbox',
                    required: false,
                    style: { maxWidth: '400px'}
                },
                {
                    label: 'Test Group',
                    name: 'testGroup',
                    type: 'select',
                    required: false,
                    style: { maxWidth:'400px'},
                    source: {
                        kind: 'lookup',
                        data: 'testGroup'
                    },
                },
                {
                    label: 'Test Name',
                    name: 'testTypeData',
                    type: 'select',
                    required: false,
                    style: { maxWidth:'400px'},
                    source: {
                        kind: 'request',
                        data: []
                    },
                },
                {
                    label: 'Template For',
                    name: 'templateFor',
                    type: 'select',
                    required: true,
                    style: { maxWidth:'400px'},
                    source: {
                        kind: 'lookup',
                        data: 'documentFor'
                    },
                },
                {
                    label: 'Data Mapping',
                    name: 'mapping',
                    type: 'uploadFileBtn',
                    required: false,
                    style: { maxWidth: '400px' },
                },
                {
                    label: 'Email From',
                    name: 'emailFrom',
                    type: 'text',
                    required: false,
                    style: { maxWidth: '400px'}
                },
                {
                    label: 'Email Subject',
                    name: 'emailSubject',
                    type: 'text',
                    required: false,
                    style: { maxWidth: '400px'}
                }
            ]
        },
    ],
    update: {
        label: 'Update',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center',
            marginRight: '10px'
        },
        disabled: false
    },
    cancel: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    edit: {
        label: 'Edit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    close: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    }
};

export default templateFormDefinition;
