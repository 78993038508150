import React from 'react';

const InnerFormContainer = (props) => {
	console.log('propsy', props);
	const { component: Component, currentInnerRecord } = props;
	return (
		<div>
			<Component {...props}/>
		</div>
	);
};

InnerFormContainer.propTypes = {

};

export default InnerFormContainer;
