import React, { useEffect, useRef, useState } from "react"
import { Card, CardBody, Button } from "reactstrap"
import FileUploader from "./file-uploader"
import { makeApiCall } from "../api/generic-api"
import Breadcrumb from "./custom/Breadcrumb"
import { TemplatesUploadTable } from "./tables/TemplatesUploadTable"
import { useQuery } from "hooks/query"
import { useNavigate } from "react-router"

function TemplatesViewNew(props) {
  const navigate = useNavigate()
  const query = useQuery()
  const [tableKey, setTableKey] = useState(0)

  const fixedSelector = {
    $or: [
      {
        cancerPcr: true,
      },
      {
        cancerPcr: {
          $exists: true,
        },
      },
    ],
  }

  const permission = props && props.applicationState.authentication.user.permission

  return (
    <div>
      <div className="wide-container">
        <Breadcrumb title={Boolean(query.get("id")) ? `Edit ${query.get("id")}` : `Template Configuration`} breadcrumbItems={[]} />
        {!Boolean(query.get("id")) ? (
          <Card className="mb-3">
            <CardBody>
              {/* <FileUploader title="Cancer PCR Results Upload" uri="/api/pcr-file/cancer" refresh={callRefresh} {...props}></FileUploader> */}
              <FileUploader
                title="Upload Templates"
                uri="/api/template-file"
                refresh={() => setTableKey((prev) => prev + 1)}
                showForTesting={false}
                allowedExtensions={["pdf", "txt", "html", "json"]}
                filteredExtensions={"application/pdf, text/txt, text/html, json"}
                {...props}
              />
            </CardBody>
          </Card>
        ) : (
          <Button
            color="primary"
            className="mb-3"
            onClick={() => {
              navigate(-1)
            }}
          >
            Go Back
          </Button>
        )}
        <Card>
          <CardBody>
            <TemplatesUploadTable
              key={tableKey}
              customPageSize={1000}
              downloadFileName="Template Configurations"
              permission={permission}
              customSelect={fixedSelector}
              defaultSort="dateCreated"
              defaultSortDir="asc"
              {...props}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default TemplatesViewNew
