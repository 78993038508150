import React, { useEffect, useRef, useState } from 'react';
import lookups from "../configuration/lookups";
import DataWow from "./data-wow";
import { makeApiCall } from "../api/generic-api";
import { Alert, Col, Row } from "reactstrap";
import fetch from "isomorphic-fetch";
import ReactLoading from "react-loading";
import extrasFullDefinition from "../configuration/extras-full-definition";

function ExtrasConfigurationView(props) {
    const [alertState, setAlertState] = useState(null);
    const [alertBadMessage, setAlertBadMessage] = useState(null);
    const structure = extrasFullDefinition;
    const childRef = useRef(null);

    const [ testConfiguration, setTestConfiguration ] = useState([]);
    const [ loading, setLoading ] = useState(false);
    
    const [ isSubjectInfoPage, setIsSubjectInfoPage ] = useState(window.location.pathname.includes('subject-info') || window.location.pathname.includes('order-info'));
    
    useEffect(() => {
        setTimeout(() => {
            setAlertState(null)
            setAlertBadMessage(null)
        }, 6000)
    }, [ alertState ]);

    const userSelector = {
        heading: {
            label: 'Filter',
            style: {display: 'inline-block', fontWeight: 800}
        },
        lookups: lookups,
        controlBlockStyle: {display: 'inline-block'},
        controls: [
            {
                label: 'Key',
                name: '_id',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            }
        ],
        selectorFunction: (selector, controlState) => {
            let resultSelector = JSON.parse(JSON.stringify(selector));

            if (controlState['id']) {
                resultSelector = Object.assign({},   {
                    ...resultSelector,
                    _id: {
                        $regex: "(?i)" +controlState['id']
                    }
                })
            };

            console.log('resultSelector', resultSelector);
            return resultSelector;
        }
    };

    const updateExtra = async (formState) => {
        const result = await makeApiCall('put', `/api/extra/${formState.id}`, formState);
        if (result.ok) {
            setAlertState('updated');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }
        callRefresh();

        return result;
    };
    const addExtra = async (formState) => {
        const result = await makeApiCall('post', `/api/extra`, formState);
        if (result.ok) {
            setAlertState('added');
            setAlertBadMessage('');
            window.scrollTo(0, document.body.scrollHeight);
        } else {
            const detail = `${result.error}`;
            setAlertState('bad');
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }
        callRefresh();
        return result;
    };

    const customRowActions = [
        {
            type: 'button',
            name: 'delete',
            label: 'Delete',
            color: 'secondary',
            action: 'delete',
            details: {
                endpoint: 'delete',
                idToken: ':id'
            }
        },
    ]

    const callRefresh = () => {
        childRef.current.callRefresh();
    };

    return (
        <div>
            <Row style={{marginTop: '30px'}}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>Extras</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>

            { loading &&
                <center className="mt-5">
                    <ReactLoading type='spin' color='red' height={667} width={100} />
                </center>
            }
            { !loading &&
                <DataWow
                    className={'wide-container'}
                    structure={structure}
                    userSelector={userSelector}
                    // fixedSelector={fixedSelector}
                    loadData={makeApiCall}
                    lookups={lookups}
                    update={updateExtra}
                    customRowActions={customRowActions}
                    setAlertState={setAlertState}
                    submit={addExtra}
                    showAddButton={true}
                    ref={childRef}
                    isSubjectInfoPage={isSubjectInfoPage}
                    {...props} />
            }


            {/*{ alertState === 'deleted' ?*/}
            {/*    <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">*/}
            {/*        {'Extra configuration has been successfully deleted'}*/}
            {/*    </Alert>*/}
            {/*    : null*/}
            {/*}*/}

            { alertState === 'added' ?
                <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                    {'Extra configuration has been successfully added.'}
                </Alert>
                : null
            }

            { alertState === 'updated' ?
                <Alert style={{marginTop: '10px', textAlign: 'center'}} color="success">
                    {'Extra configuration has been successfully updated.'}
                </Alert>
                : null
            }

            { alertState === 'bad' ?
                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                    { alertBadMessage || 'There was a problem submitting your data. Please try again or contact us.'}
                </Alert>
                : null
            }
        </div>
    );
}

export default ExtrasConfigurationView;
