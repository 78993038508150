const {  createCodec } = require('json-crypto');
const codec = createCodec('secured-password');

export const encrypt = (data) => {
	return codec.encrypt(data)
	// return encodeURIComponent(JSON.stringify(data))
};

export const decrypt = (data) => {
	return codec.decrypt(data);
	// return JSON.parse(decodeURIComponent(data));
}
