import React from "react"
import PatientInfoView from "components/custom/PatientInfoView"

const SubjectInfoView = (props) => {
  return (
    <div className="wide-container">
      <PatientInfoView {...props} />
    </div>
  )
}

SubjectInfoView.propTypes = {}

export default SubjectInfoView
