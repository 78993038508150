import React, {useEffect, useState} from 'react';
import './tray-layout.css';
import TrayLayoutCompound from "./tray-layout-compound";
import TrayLayoutSimple from "./tray-layout-simple";
import { get } from  "../utility/client-utility";
import {makeApiCall} from "../api/generic-api";

const TrayLayout = (props) => {
    const tray = props.tray;
    const trayConfiguration = (get(() => props.trayConfiguration));
    const enabledBin = props.enabledBin;
    // console.log('ems tray', tray);
    // console.log('ems tray config', trayConfiguration);

    return (
        <>
            {trayConfiguration?.compound ?
                <TrayLayoutCompound tray={tray} trayConfiguration={trayConfiguration} enabledBin={enabledBin}></TrayLayoutCompound>
                : <TrayLayoutSimple tray={tray} trayConfiguration={trayConfiguration} enabledBin={enabledBin} setEnableBin={props.setEnableBin} setCurrentRecord={props.setCurrentRecord} setFormState={props.setFormState}></TrayLayoutSimple>
            }
        </>
    )
}

export default TrayLayout;
