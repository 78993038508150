import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useAccessionsQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()
  const fields = [
    "_id",
    "data.submittedTimestamp",
    "data.testId",
    "data.sampleCollectionDateTime",
    "data.requisitionerFullName",
    "data.firstName",
    "data.lastName",
    "data.dateOfBirth",
    "data.submittedTimestamp",
    "data.testId",
    "data.manualTestPrompt",
    "data.paid",
    "data.manualTestId",
    "data.sampleCollectionDateTime",
    "data.firstName",
    "data.lastName",
    "data.dateOfBirth",
    "data.gender",
    "data.address",
    "data.city",
    "data.state",
    "data.zipCode",
    "data.phoneNumber",
    "data.email",
    "data.county",
    "data.race",
    "data.ethnicity",
    "data.deletedTimestamp",
    "data.taps",
    "data.testingPurpose",
    "data.purposeOtherDescription",
    "data.patientSymptoms",
    "data.dateOfSymptomOnset",
    "data.patientLocation",
    "data.patientContact",
    "data.healthcareSetting",
    "data.patientPregnant",
    "data.patientFirstTest",
    "data.patientCongregateResidence",
    "data.patientHospitalized",
    "data.patientAdmittedToICU",
    "data.vaccinated",
    "data.vaccinationDate",
    "data.vaccineType",
    "data.vaccineTypeOther",
    "data.requisitionerFullName",
    "data.requisitionerEmail",
    "data.patientInsured",
    "data.insuranceGroupId",
    "data.insuranceCompanyName",
    "data.insuranceSubscriberNo",
    "data.payerTypeLabel",
    "data.price",
    "data.feeCode",
    "data.marketingPermission",
    "data.termsAndConditions",
    "data.attestation",
  ].map((item) => `"${item}"`)

  const params = encodeURIComponent(`[${fields.toString()}]`)

  const newVariables = {
    ...variables,
  }

  if (newVariables?.selector?.["data.firstName"]?.$eq) {
    newVariables.selector["data.firstName"] = {
      $regex: "(?i)" + newVariables.selector["data.firstName"].$eq,
    }
  }

  if (newVariables?.selector?.["data.lastName"]?.$eq) {
    newVariables.selector["data.lastName"] = {
      $regex: "(?i)" + newVariables.selector["data.lastName"].$eq,
    }
  }

  if (newVariables?.selector?.["data.testId"]?.$eq) {
    newVariables.selector["data.testId"] = {
      $regex: "(?i)" + newVariables.selector["data.testId"].$eq,
    }
  }

  if (newVariables?.selector?.["data.requisitionerFullName"]?.$eq) {
    newVariables.selector["data.requisitionerFullName"] = {
      $regex: "(?i)" + newVariables.selector["data.requisitionerFullName"].$eq,
    }
  }

  return query(
    ["accessions", newVariables],
    () => axiosClient.get(`/accession/streamlines?${paramsToQuery(newVariables)}&fields=${params}`).then((res) => res.data),
    { ...rest },
  )
}
