import propertiesFormDefinition from "./properties-form-definition";

const processSampleFormDefinition = {
    formLabel: null,
    submit: {
        label: 'Submit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        },
    },
    cancel: {
        label: 'Cancel',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    edit: {
        label: 'Edit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    close: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    update: {
        label: 'Update',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center',
        },
        disabled: false
    },
    form: propertiesFormDefinition,
    sections: [
        {
            label: 'Details',
            columns: 2,
            fields: [
                {
                    label: 'Sample Id',
                    name: 'id',
                    type: 'text',
                    disabled: false,
                },
                {
                    label: 'Isolate',
                    name: 'isolateSample',
                    type: 'button',
                    labelStyle: { marginBottom: '0px'},
                    disabled: true
                },
                {
                    label: 'Test Type',
                    name: 'testType',
                    type: 'text',
                    readOnly: true
                },
            ]
        }
    ]
};

export default processSampleFormDefinition;
