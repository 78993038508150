import React, { Component, useEffect, useRef, useState } from 'react';
import {
    Row, Col, Button, Input, Alert, Label, Container, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { userType, userTypes, userPermission, userPermissions, userStatus, userStatuses, testStatus, testStatuses } from '../utility/constants';
import {
    searchTest,
    processAccessionStreamline,
    searchStreamline,
    processRegisteredTest, searchExistingSubjects
} from '../api/accession';
import AccessionSearchResults from "./accession-search-results";
import { debounce } from "lodash";
import {makeApiCall, makeApiDownloadCall} from "../api/generic-api";
import moment from 'moment';
import ReactLoading from "react-loading";
import { v4 as uuidv4 } from "uuid";
import {useAlert} from "react-alert";

const Accession = props => {
    const alert = useAlert();
    const myInputRef = useRef();
    const [ price, setPrice ] = useState(0);
    const [ isDisableForm, setIsDisableForm ] = useState(true);
    
    const [ state, setState ] = useState({
        testIdInput: '',
        searchDateOfBirth: '',
        assessment: '',
        found: false,
        timer: null,
        searchStatus: '',
        searchResult: {},
        existingSubjects: [],
        hasPricingError: false,
        showNeTestModal : false,
        validationMessage: '',
        invalidInput: false,
        isDisabled: false,
        isAccessioning: false,
        searchingExistingSubject: false,
    });

    useEffect(() => {
        if (state.hasPricingError) {
            setTimeout(() => {
                setState(prevState => ({
                    ...prevState,
                    hasPricingError: false
                }))
            }, 2000)

        }
    }, [state.hasPricingError])

    const handleTestIdChange = async (e) => {
        e.persist();

        setState(prevState => ({
            ...prevState,
            testIdInput: e.target.value,
            searchDateOfBirth: '',
            found: false,
            isAccessioning: false
        }));
    }

    useEffect(() => {
        if (state.testIdInput) {
            const fetchData = async () => {
                await loadTest();
            }

            fetchData().catch(console.error);
        }
    }, [ state.testIdInput ]);


    const handleSearchChange = (e) => {
        e.persist();
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setState(prevState => ({
            ...prevState,
            found: false,
            searchStatus: 'clear',
            testIdInput: '',
            [name]: value
        }));
    }


    const scan = () => {
        setState(prevState => ({
            ...prevState,
            testIdInput: '',
            searchDateOfBirth: '',
            found: false,
            searchStatus: 'clear',
            isAccessioning: false
        }))
    };

    const generateLabel = async () => {
        if (state.testIdInput) {
            const url = `/api/generate/label/${state.testIdInput}`;
            const result = await makeApiDownloadCall('get', url, { id: state.testIdInput}, 'application/pdf', `blood-tube-${state.testIdInput}`)
            console.log('res generate label', result)
            if (result.ok) {
                setState(prevState => ({
                    ...prevState,
                    testIdInput: '',
                }))
                alert.show('Successfully generated blood tube label', {
                    type: 'success'
                });
            } else {
                alert.show(result.error, {
                    type: 'error'
                })
            }
        } else {
            alert.show('Please input sample id', {
                type: 'error'
            })
        }
    }

    const cleanInput = testIdInput => {
        if(!testIdInput) {
            return testIdInput;
        }

        const parts = testIdInput.split('/');
        const result = parts[parts.length - 1];
        return result;
    };

    const loadTest = async() => {
        const testId = cleanInput(state.testIdInput);
        if(!testId || testId.length !== 13) {
            return;
        }

        setState(prevState => ({
            ...prevState,
            searchStatus: 'loading',
            searchResult: {},
            sampleCollectionDate: null,
            sampleLocation: null
        }));


        if(testId !== state.testIdInput) {
            setState(prevState => ({
                ...prevState,
                testIdInput: testId
            }));
        } else {
            const test = await searchTest(testId);

            if (test && Array.isArray(test.searchResult)) {
                await Promise.all(test.searchResult.map(async searchResult => {
                    if (searchResult.data) {
                        const price = await fetchPrices(searchResult.data);
                        searchResult.data.price = price;
                    }
                }));
            }

            setState(prevState => ({
                ...prevState,
                searchStatus: test.searchStatus || 'error',
                searchResult: test.searchResult,
                found: test.searchStatus === 'streamline' || test.searchStatus === 'existsRegistered',
                selectedStreamlineId: (test.searchStatus === 'streamline' || test.searchStatus === 'existsOther') ? test.searchResult[0].id : test.searchStatus === 'existsOther' ? state.testIdInput : null,
                showNeTestModal: test?.searchResult?.netTest && test.searchStatus !== 'existsOther' ? true: false
            }));

            if (test.searchResult[0]) {
                await findDuplicates(test.searchResult[0].data);
            }
        }
    }
    
    const fetchPrices = async (data) => {
        if (data.testId && data.payerTypeLabel) {
            const patientInsured = data.patientInsured;
            if ((patientInsured === 'true' || patientInsured === true) && data.payerTypeLabel === 'personal') {
                data.payerType = 'insurance'
            } else {
                data.payerType = data.payerTypeLabel;
            }
            
            data.payerTypeLabel = data.payerTypeLabel;
            
            const payerType = data.payerType;
            const feeCode = data.feeCode === '' ? null : data.feeCode;
            const testTypeId = data.testId.substring(data.testId.length - 1);
            
            const productResult = await makeApiCall('get', '/api/products', {
                selector: {
                    testTypeData: {
                        $elemMatch: {
                            value: testTypeId
                        }
                    }
                }
            });
            
            if (productResult && productResult.statusCode === 200 && productResult.body && productResult.body.length > 0) {
                const productId = productResult.body[0].id;
                const priceResult = await makeApiCall('get', '/api/prices', {
                    selector: {
                        'productId': productId,
                        'payerType': payerType,
                        'feeCode': feeCode
                    }
                });
                
                if (priceResult && priceResult.statusCode === 200 && priceResult.body && priceResult.body.length > 0) {
                    data.price = priceResult.body[0].price;
                    setPrice(priceResult.body[0].price);
                    return priceResult.body[0].price;
                } else {
                    data.price = null;
                    setPrice(null);
                    return null;
                }
            } else {
                data.price = null;
                setPrice(null);
                return null;
            }
        } else {
            data.price = null;
            setPrice(null)
            return null;
        }
    }

    const findDuplicates = async (data) => {
        setState(prevState => ({
            ...prevState,
            searchingExistingSubject: true,
            existingSubjects: []
        }));
        
        
        const query = _.pick(data, [
            'firstName',
            'lastName',
        ]);

        if (query.firstName) {
            query.firstName = {
                $regex: "^(?i)" + query.firstName + "$"
            }
        }

        if (query.lastName) {
            query.lastName = {
                $regex: "^(?i)" + query.lastName + "$"
            }
        }


        const existingSubjects = await searchExistingSubjects(query);
        setState(prevState => ({
            ...prevState,
            searchingExistingSubject: false,
            existingSubjects: existingSubjects.searchResult,
        }))
    }

    const accessionTest = async(status) => {
        setState(prevState => ({
            ...prevState,
            isAccessioning: true
        }))
        
        if(state.searchStatus === 'streamline' || state.searchStatus === 'existsOther') {
            const searchResult = state.searchResult;
            const selectedSubmission = searchResult.filter(res => res.id == state.selectedStreamlineId);
            if (!selectedSubmission[0]?.data.isNETest && !selectedSubmission[0]?.data.price) {
                setState(prevState => ({
                    ...prevState,
                    hasPricingError: true,
                }));
            } else {
                if (selectedSubmission[0]?.data.isNETest) {
                    setState(prevState => ({
                        ...prevState,
                        // showNeTestModal: state.searchStatus == 'existOther' ? false: true,
                        // showNeTestModal: true,
                        invalidInput: false,
                        validationMessage: '',
                        testIdInput: selectedSubmission[0]?.data.sampleId
                    }));
                } else {
                    const result = await processAccessionStreamline(state.selectedStreamlineId, status, state.selectedSubjectId);
                    setState(prevState => ({
                        ...prevState,
                        searchStatus: result.searchStatus || 'error',
                        searchResult: result.searchResult || {},
                        found: false
                    }));
                }
            }
        } else if(state.searchStatus === 'existsRegistered') {
            const isNetTest = state.searchResult.netTest;
            if (isNetTest && isNetTest === true){
                setState(prevState => ({
                    ...prevState,
                    showNeTestModal: true,
                    invalidInput: false,
                    validationMessage: ''
                }));
            } else {
                const result = await processRegisteredTest(state.testIdInput, status);
                setState(prevState => ({
                    ...prevState,
                    searchStatus: result.searchStatus || 'error',
                    searchResult: {},
                    found: false
                }));
            }
        } else {
            setState(prevState => ({
                ...prevState,
                searchStatus: 'error',
                searchResult: {},
                found: false,
                isAccessioning: false
            }));
        }
    
        setState(prevState => ({
            ...prevState,
            isAccessioning: false
        }))
        // me.props.setError('Failed to process accession record');
    }

    const searchStreamlinesBySubject = async() => {
        const result = await searchStreamline({dateOfBirth: state.searchDateOfBirth});
        await Promise.all(result.searchResult.map(async searchResult => {
            const price = await fetchPrices(searchResult.data);
            searchResult.data.price = price;
        }));

        setState(prevState => ({
            ...prevState,
            testIdInput: '',
            searchStatus: result.searchStatus || 'error',
            searchResult: result.searchResult,
            found: result.searchStatus === 'streamline',
            selectedStreamlineId: result.searchStatus === 'streamline' || result.searchStatus === 'existsOther' ? result.searchResult[0].id : null
        }));

        if (result.searchResult[0]) {
            await findDuplicates(result.searchResult[0].data);
        }
    }

    const confirmed = () => {
        return accessionTest(testStatus.usable);
    }

    const invalidSample = () => {
        return accessionTest(testStatus.unusable);
    }

    const dateMismatch = () => {
        return accessionTest(testStatus.unidentifiable);
    }

    const duplicate = () => {
        return accessionTest(testStatus.duplicate);
    }

    const setSelectedResult = async (streamline) => {
        setState(prevState => ({
            ...prevState,
            selectedStreamlineId: streamline.id || streamline._id
        }));

        await findDuplicates(streamline.data);
    };

    const setSelectedSubject = (subject) => {
        setState(prevState => ({
            ...prevState,
            selectedSubjectId: subject ? subject?.id : null
        }));
    }

    const fieldsOnChange = useRef(
      debounce(async (data) => {
          const price = await fetchPrices(data);
          setPrice(price);
      }, 200)
    ).current

    const getDateFormatString = () => {
        const formatObj = new Intl.DateTimeFormat(navigator.language).formatToParts(new Date());

        return formatObj
            .map(obj => {
                switch (obj.type) {
                    case "day":
                        return "DD";
                    case "month":
                        return "MM";
                    case "year":
                        return "YYYY";
                    default:
                        return obj.value;
                }
            })
            .join("");
    };

    const dateValidate = (value) => {
        if (!value) {
            return;
        }
        console.log('validating sample collection date', value);
        let valid = false;
        try {
            const dateTimeString = value + 'T00:00:00Z';
            const date = new Date(dateTimeString);
            console.log('date', date);
            valid = date && date instanceof Date && isFinite(date) ? true : false;
        } catch (e) {
            setState(prevState => ({
                ...prevState,
                validationMessage: 'Error in validating date: ' + e
            }));
            return false;
        }
        console.log('valid', valid);
        if (!valid) {
            setState(prevState => ({
                ...prevState,
                validationMessage: 'Invalid date, please use this format: ' + getDateFormatString()
            }));
            return false;
        }
    };
    const validateDateChange = (value) => {
        if (value) {
            const input = moment(value);
            console.log('target validatedatechange', input)
            const covidStarted = moment("1/1/2020");
            const currentDate = moment();

            const rule = {
                field: "sampleCollectionDate",
                value: input.isSameOrBefore(currentDate, 'day'),
            };

            console.log('target', rule.value);

            if (!rule?.value) {
                setState(prevState => ({
                    ...prevState,
                    invalidInput: true,
                    validationMessage: 'Invalid sample collection date.'
                }));
                return false;
            } else {
                setState(prevState => ({
                    ...prevState,
                    invalidInput: false,
                    validationMessage: ''
                }));
                return true;
            }
        }
    };

    const handleCloseModal = () => {
        setState(prevState => ({
            ...prevState,
            showNeTestModal: false
        }));
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        let newFormState = {};

        const value = target.value;
        console.log('target name', name);
        console.log('target value', value);
        console.log('target type', target.type);
        if (target.type === 'date') {
            const isDateValid = dateValidate(value);
            const isValidated = validateDateChange(value);
            console.log('target isvalid date', isDateValid);
            console.log('target isvalid', isValidated);

            if ((!isDateValid || isDateValid === false) && (!isValidated || isValidated === false)) {
                setState(prevState => ({
                    ...prevState,
                    invalidInput: true,
                    validationMessage: 'Date is invalid',
                    isDisabled: true
                }));
            } else {
                setState(prevState => ({
                    ...prevState,
                    invalidInput: false,
                    validationMessage: '',
                    isDisabled: false,
                    [name]: value
                }));
            }
        } else {
            setState(prevState => ({
                ...prevState,
                invalidInput: false,
                validationMessage: '',
                isDisabled: false,
                [name]: value
            }));
        }


    };

    const handleConfirmNeTest = async () => {
        console.log('accession state', state);

        if (!state.sampleLocation || state.sampleLocation === '') {
            setState(prevState => ({
                ...prevState,
                showNeTestModal: true,
                invalidInput: true,
                validationMessage: `Error in accessioning this test/sample. Location can't be empty`,
                isDisabled: true
            }));
        } else if (!state.sampleCollectionDate || state.sampleCollectionDate === '') {
            setState(prevState => ({
                ...prevState,
                showNeTestModal: true,
                invalidInput: true,
                validationMessage: `Sample Collection date can't be empty`,
                isDisabled: true
            }));
        }
        else {
    
            
            const transactionHistory = {
                id: await uuidv4(),
                timestamp: new Date().toISOString(),
                user: props.applicationState.authentication.user,
                type: 'accession',
                transaction: {
                    data : {
                        id: state.testIdInput,
                        field: '-',
                        value: '-',
                        state: {
                            sampleId: state.testIdInput,
                            status: testStatus.usable,
                            sampleLocation: state.sampleLocation,
                            sampleCollectionDate: state.sampleCollectionDate
                        }
                    },
                    action: {
                        descShort: 'accession', desc: 'Accession'
                    }
                },
                transactionId: state.testIdInput,
                transactionField: '-',
                transactionValue: '-',
                subjectId: state.searchResult.subjectId,
            }
            
            
            const result = await makeApiCall('put', '/api/netest/receive', {
                sampleId: state.testIdInput,
                status: testStatus.usable,
                sampleLocation: state.sampleLocation,
                sampleCollectionDate: state.sampleCollectionDate,
                transactionHistory: [transactionHistory]
            });

            if (result.ok) {
                setState({
                    showNeTestModal: false,
                    searchStatus: 'success',
                    testIdInput: ''
                });
            } else {
                setState(prevState => ({
                    ...prevState,
                    validationMessage: `Error in accessioning NETest`
                }));
            }
        }
    }

    useEffect(() => {
        scan();
    }, []);

    // console.log('isdisabled', isDisableForm);

    return (
        <>
            <div className="accession">
                <Row style={{marginTop: '30px'}}>
                    <Col sm="3">
                    </Col>
                    <Col sm="6" className="text-center">
                        <h3>Accession Sample</h3>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Row>
                <Row style={{marginTop:'20px', marginBottom:'30px'}}>
                    <Col sm="3">
                    </Col>
                    <Col sm="3" >
                        <div>
                            <Input
                                ref={myInputRef}
                                type="text"
                                name="testIdInput"
                                id="testIdInput"
                                value={state.testIdInput}
                                title = "Input"
                                onChange={handleTestIdChange}
                                autoComplete={'off'}
                                autoFocus
                            />
                        </div>
                        <div>
                            <Button
                                style={{display: 'block', width: '100%', marginTop:'40px'}}
                                type="button"
                                outline color="primary"
                                onClick={() => scan()}> Scan</Button>
                        </div>
                        <div>
                            <Button
                                style={{display: 'block', width: '100%', marginTop:'40px'}}
                                type="button"
                                outline color="primary"
                                onClick={() => generateLabel()}> Generate Blood Tube Label</Button>
                        </div>
                        <div>
                            <Input
                                style={{width: '165px', marginTop: '40px'}}
                                id={"searchDateOfBirth"}
                                name={"searchDateOfBirth"}
                                type={"date"}
                                onChange={(e) => handleSearchChange(e)}
                                value={state.searchDateOfBirth || ''}
                            ></Input>
                        </div>
                        <div>
                            <Button
                                style={{display: 'block', width: '100%', marginTop:'40px'}}
                                type="button"
                                outline color="primary"
                                disabled={!state.searchDateOfBirth}
                                onClick={() => searchStreamlinesBySubject()}>Search by Birth Date</Button>
                        </div>
                    </Col>
                    <Col sm="3" className="text-center">
                        <div>
                            <Button
                                type="button"
                                outline color="success"
                                style={{display: 'block', width: '100%'}}
                                disabled={!state.found || !isDisableForm || state.isDisabled || state.isAccessioning || state.searchingExistingSubject}
                                onClick={() => confirmed()}>Confirmed</Button>
                        </div>
                        <div>
                            <Button
                                type="button"
                                outline color="warning"
                                style={{display: 'block', width: '100%', marginTop:'40px'}}
                                disabled={!state.found || !isDisableForm || state.isAccessioning || state.searchingExistingSubject}
                                onClick={() => invalidSample()}>Invalid sample</Button>
                        </div>
                        <div>
                            <Button
                                type="button"
                                outline color="danger"
                                style={{display: 'block', width: '100%', marginTop:'40px'}}
                                disabled={!state.found || !isDisableForm || state.isAccessioning || state.searchingExistingSubject}
                                onClick={() => dateMismatch()}>Date mismatch</Button>
                        </div>
                        <div>
                            <Button
                                type="button"
                                outline color="danger"
                                style={{display: 'block', width: '100%', marginTop:'40px'}}
                                disabled={state.searchStatus === "clear" || !isDisableForm || state.isAccessioning || state.searchingExistingSubject}
                                onClick={() => duplicate()}>Duplicate</Button>
                        </div>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Row>
                {(() => {
                    switch (state.searchStatus) {
                        case 'clear':
                            return (
                                <div className={'text-center'}>Scan a sample tube or search by date of birth.</div>
                            );
                        case 'loading':
                            return (
                                // <div className={'text-center'}>Loading...</div>
                              <center className="mt-5">
                                  <ReactLoading type='spin' color='red' height={667} width={100} />
                              </center>
                            );
                        case 'existsRegistered':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="warning">
                                    {`This sample (${state.testIdInput}) is registered in the LIS ${state.searchResult.taps ? 'via TAPS' : 'directly' } and can be accessioned.  Please check details: ${state.searchResult.firstName} ${state.searchResult.lastName} ${moment(state.searchResult.dateOfBirth).format("MM/DD/YYYY")}. ${state.searchResult.ppq === true ? 'Patient has requested PPQ.' : ''}`}
                                </Alert>
                            );
                        case 'existsOther':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="warning">
                                    {'This sample has previously been accessioned.'}
                                </Alert>
                            );
                        case 'testTypeNotFound' :
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {'Test type does not exist in test configuration.'}
                                </Alert>
                            )
                        case 'streamline':
                            return (
                                <div>
                                    {state.hasPricingError &&
                                        <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                            Pricing cannot be empty
                                        </Alert>
                                    }
                                    
                                    { state.isAccessioning &&
                                      <center className="mt-5">
                                          <ReactLoading type='spin' color='red' height={667} width={100} />
                                      </center>
                                    }
    
                                    { !state.isAccessioning &&
                                      <AccessionSearchResults
                                        loadTest={loadTest}
                                        searchResult={state.searchResult}
                                        setSelectedResult={setSelectedResult}
                                        selectedSubjectIdselectedSubjectId={state.selectedSubjectId}
                                        setSelectedSubject={setSelectedSubject}
                                        fieldsOnChange={fieldsOnChange}
                                        price={price}
                                        isDisableForm={isDisableForm}
                                        setIsDisableForm={setIsDisableForm}
                                        {...state}
                                        {...props}
                                      />
                                    }
                                </div>

                            );
                        case 'success':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="success">
                                    {'Data import has been processed according to status.'}
                                </Alert>
                            );
                        case 'notFound':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {'No matching sample found.'}
                                </Alert>
                            );
                        case 'invalidSampleType':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {'Invalid sample type: ' + state.searchResult}
                                </Alert>
                            );
                        case 'testIdExistsInTray':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {state.searchResult}
                                </Alert>
                            );
                        case 'productNotFound':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {'Product error: ' + state.searchResult}
                                </Alert>
                            );
                        case 'productValidationError':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {'Product validation error: ' + state.searchResult}
                                </Alert>
                            );
                        case 'unresolvedSampleId':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {'Unresolved Sample ID: ' + state.searchResult}
                                </Alert>
                            );
                        case 'priceError':
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {'Price error: ' + state.searchResult ? state.searchResult : 'There are no prices available'}
                                </Alert>
                            );
                        default:
                            return (
                                <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                    {'Error - unexpected state.'}
                                </Alert>
                            );
                    }
                })()}
            </div>
            <Row>
                <Col sm="12">
                    <Container className="mt-5 mb-5 pb-5">
                        <Modal isOpen={state.showNeTestModal} toggle={handleCloseModal} size="md">
                            <ModalHeader>Input location and date sample collected</ModalHeader>
                            <ModalBody>
                                <Label>Location</Label>
                                <Input type="text" name="sampleLocation" onChange={(e) => { e.persist(); handleChange(e)}}></Input>
                                <br />
                                <Label>Sample Collection Date</Label>
                                <Input type="date" name="sampleCollectionDate" onChange={(e) => { e.persist(); handleChange(e)}} max="9999-12-31"></Input>
                                {state.invalidInput && state.validationMessage !== '' ?
                                    <Alert style={{marginTop: '10px', textAlign: 'center'}}  color="danger">
                                        {state.validationMessage}
                                    </Alert>
                                    : null
                                }
                            </ModalBody>

                            <ModalFooter>
                                <Button
                                    type="button"
                                    outline color="danger"
                                    onClick={handleConfirmNeTest}
                                    disabled={state.isDisabled}
                                >
                                    Confirm
                                </Button>
                                <Button
                                    type="button"
                                    outline color="primary"
                                    onClick={handleCloseModal}
                                >Close</Button>
                            </ModalFooter>
                        </Modal>
                    </Container>
                </Col>
            </Row>
        </>
    )
};

export default Accession;
