import React from 'react';

import covidRequisitionFullDefinition from "../configuration/covid-requisition-full-definition";
import lookups from "../configuration/lookups";
import DataWow from "./data-wow";
import { makeApiCall } from "../api/generic-api";
import {Col, Row} from "reactstrap";

function RequisitionCovid(props) {

    const structure = covidRequisitionFullDefinition;

    // covid tests requisition v2 which aren't done
    const fixedSelector = {
        'data.testType': 'covid',
        'data.requisitionVersion': 2,
        "$or": [
            {
                "status": {
                    "$exists": false
                }
            },
            {
                status: {
                    "$ne": "done"
                }
            }
        ]
    };

    const userSelector = {
        heading: {
            label: 'Filter',
            style: {display: 'inline-block', fontWeight: 800}
        },
        lookups: lookups,
        controlBlockStyle: {display: 'inline-block'},
        controls: [
            {
                label: 'First Name',
                name: 'data.firstName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'Last Name',
                name: 'data.lastName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            }
        ],
        //selectorFunction: null /*(controlState) => {

        //}*/
    };

    const customRowActions = [
        {
            type: 'button',
            name: 'done',
            label: 'Done',
            color: 'success',
            details: {
                endpoint: 'patch',
                data: {
                    status: 'done'
                },
                idToken: ':id',
                visible: {
                    status: null
                }
            }
        }
    ];

    return (
        <div className="accession">
            <Row style={{marginTop: '30px'}}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>COVID Requisition Backlog</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>
            <DataWow
                className={'wide-container'}
                structure={structure}
                lookups={lookups}
                fixedSelector={fixedSelector}
                userSelector={userSelector}
                formDataField={'data'}
                customRowActions={customRowActions}
                loadData={makeApiCall}
                {...props} />
        </div>
    );
}

export default RequisitionCovid;
