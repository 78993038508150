import React, { Component, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import fetch from 'isomorphic-fetch';
import ReactLoading from "react-loading";

const Logout = props => {
  const [ loading, setLoading ] = useState(null);
  
  useEffect(() => {
    setLoading(true);
    const authentication = props.applicationState.authentication;
    if (authentication && authentication.user && authentication.user.impersonator) {
      fetch('/api/impersonate',
        {
          method: 'delete',
          credentials: 'include',
          headers: {
            'Accept': 'application/json'
          },
          mode: 'cors', // no-cors, cors, *same-origin
          redirect: 'follow', // manual, *follow, error
          referrer: 'no-referrer', // *client, no-referrer
        }
      )
        .then((response) => {
          setTimeout(() => { setLoading(false)}, 800)
          if (response.ok) {
            window.location = '/';
          } else {
            throw new Error('Server error');
          }
        })
        .catch((reason) => {
          setTimeout(() => { setLoading(false)}, 800)
          console.log('reason', reason);
          props.setError('Failed to cancel impersonation');
        });
    } else {
      fetch('/api/logout', {
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'include', // include, same-origin, *omit
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, cors, *same-origin
        redirect: 'follow', // manual, *follow, error
        referrer: 'no-referrer', // *client, no-referrer
      })
        .then((response) => {
          setTimeout(() => { setLoading(false)}, 800)
          props.onLogout();
        })
        .catch((reason) => {
          setTimeout(() => { setLoading(false)}, 800)
          console.log('caught an error in Logout component');
          //this.props.setError('Error in logout');
        });
    }
  }, []);
  
  return (
    <div>
      {!!loading &&
      <div className="d-flex flex-column justify-content-center align-items-center min-vh-100">
        <div className="d-flex align-items-center">
          <ReactLoading type="spin" color="red" height={50} width={50} className="mr-2" />
          <p style={{ fontSize: '1.2rem', color: '#6c757d', marginBottom: '0', marginLeft: '10px' }}>
            Logging out...
          </p>
        </div>
      </div>
      }
      {loading == false && <Navigate to={'/'} />}
    </div>
    
  );
};


export default Logout;
