import { axiosClient, useRequestProcessor } from "api/provider"

export function useSubmitRequisition(options = {}) {
  const { mutate } = useRequestProcessor()

  return mutate(
    "requisition",
    (requisition) =>
      axiosClient.post("/requisitions", requisition, { responseType: "blob" }).then(async (res) => {
        const pdfBlob = new Blob([res.data], { type: "application/pdf" })
        const pdfUrl = window.URL.createObjectURL(pdfBlob)
        const downloadLink = document.createElement("a")
        downloadLink.href = pdfUrl
        downloadLink.setAttribute("download", "Wren-netest-requisition.pdf") // Name the file
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)

        return res.data
      }),
    options,
  )
}

export function useAddSampleId({ variables, ...rest }) {
  const { mutate } = useRequestProcessor()

  return mutate("update-sample-id", (update) => axiosClient.put(`/requisition/${encodeURIComponent(variables?.id)}`, update).then((res) => res.data), {
    ...rest,
  })
}

export function useAcceptRequisition({ variables, ...rest }) {
  const { mutate } = useRequestProcessor()

  return mutate(["accept-requisition", variables?.id], (update) => axiosClient.patch(`/requisition/${variables?.id}`, update).then((res) => res.data), {
    ...rest,
  })
}

