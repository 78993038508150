import React, { useState } from 'react';
import {
    Button, Input, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import classnames from 'classnames';
import ReactLoading from "react-loading";

function DataControls(props) {
  const { showPaginationButtons = true } = props;
        // currentPage={pageActual}
        // setPageRequested={setPageRequested}
        // setSelector={setSelector}
        // download={download}
        // refresh={refresh}
    const [ showDeleteModal, setShowDeleteModal ] = useState(false);
    const handleCloseModal = () => setShowDeleteModal(false);
    const handleShowModal = () => setShowDeleteModal(true);
    const DeleteModal = () => {
        return (
          <Modal isOpen={showDeleteModal} toggle={handleCloseModal} size="lg">
              <ModalHeader>Delete Accession</ModalHeader>
              <ModalBody>
                  Are you sure do you want to remove the selected entries?
              </ModalBody>
              <ModalFooter>
                  <Button
                    type="button"
                    outline color="danger"
                    onClick={() => {
                        props.handleDelete()
                        handleCloseModal()
                    }}>
                      OK
                  </Button>
                  <Button
                    type="button"
                    outline color="primary"
                    onClick={handleCloseModal}
                  >Close</Button>
              </ModalFooter>
          </Modal>
        )
    }
    
    
    return (
        <div className={props.className} style={props.style}>
            <DeleteModal/>
            <div >
              { showPaginationButtons &&
                <>
                  <Button outline color="primary"
                          onClick={() => props.setPageRequested(props.currentPage - 1)}
                          disabled={props.currentPage === 1}
                          style={{ marginRight: '20px' }}>
                    <span className="fa fa-angle-left"></span></Button>
                  <Button outline color="primary"
                          onClick={() => {
                            console.log('props.currentPage + 1', props.currentPage + 1);
                            const sample = props.currentPage + 1;
                            console.log('sample', sample);
                            props.setPageRequested(sample);
                            if (props.setPageActual) {
                              props.setPageActual(sample);
                            }
                          }}
                          disabled={(props.currentPage >= props.lastPage) && props.lastPage ? true : false}
                          style={{ marginRight: '20px' }}>
                    <span className="fa fa-angle-right"></span></Button>
                </>
              }
              
                <Button outline color="primary"
                        onClick={() => props.refresh()}
                        style={{marginRight:'20px'}}>
                    <span className="fa fa-refresh"></span></Button>
                <Button outline color="primary"
                        style={{marginRight:'20px'}}
                        onClick={() => props.download()}>
                  {props.isDownloading ?  <ReactLoading type='spin' color='blue' height={25} width={15} /> : <span className="fa fa-download"></span>}
                </Button>
                {props.showAddButton && <Button outline color="primary"
                        style={{marginRight:'20px'}} onClick={() => props.add(null)}>
                    <span className="fa fa-plus"></span></Button>}
                { props.showDeleteButton &&
                    <Button disabled={props.checkedInput.length ? false : true} outline color="primary" style={{marginRight:'20px'}} onClick={() => handleShowModal()}>
                    <span className="fa fa-trash"></span>
                  </Button>
                }
            </div>
        </div>
    );
}

export default DataControls;
