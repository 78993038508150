import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import fetch from 'isomorphic-fetch';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Container,
    Row,
    Col,
    Button,
    Alert,
    Spinner
} from 'reactstrap';
import { makeApiCall } from "../api/generic-api";

const Login = ({ applicationState, onLogin }) => {
    const [state, setState] = useState({
        username: '',
        password: '',
        redirectToReferrer: false,
        invalidLoginMessage: false,
        errorMessage: '',
        resetPassword: false,
        loading: false,
    });
    
    const postData = (url, data) => {
        return fetch(url, {
            body: data,
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'user-agent': 'Mozilla/4.0 MDN Example',
                'content-type': 'application/x-www-form-urlencoded'
            },
            method: 'POST',
            mode: 'cors',
            redirect: 'follow',
            referrer: 'no-referrer',
        })
          .then(response => response.json());
    };
    
    const handleLogin = (event) => {
        event.preventDefault();
        
        if (!state.username || !state.password) {
            setState(prevState => ({
                ...prevState,
                invalidLoginMessage: true,
                errorMessage: 'Invalid credentials'
            }));
            return;
        }
        
        setState(prevState => ({
            ...prevState,
            loading: true,
            invalidLoginMessage: false,
            errorMessage: ''
        }));
        
        const userInfoUrlEncoded = `username=${encodeURIComponent(state.username)}&password=${encodeURIComponent(state.password)}`;
        
        postData('/api/login', userInfoUrlEncoded)
          .then(data => {
              if (data?.errorMessage) {
                  setState(prevState => ({
                      ...prevState,
                      invalidLoginMessage: true,
                      errorMessage: data.errorMessage,
                      loading: false
                  }));
              } else {
                  onLogin(data);
                  setState(prevState => ({
                      ...prevState,
                      invalidLoginMessage: false,
                      redirectToReferrer: true,
                      loading: false
                  }));
              }
          })
          .catch((err) => {
              console.log('errX: ', err);
              setState(prevState => ({
                  ...prevState,
                  invalidLoginMessage: true,
                  errorMessage: 'Login failed',
                  loading: false
              }));
          });
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [name]: value,
            invalidLoginMessage: false
        }));
    };
    
    const resetPassword = () => {
        setState(prevState => ({
            ...prevState,
            resetPassword: true
        }));
    };
    
    const { from } = { from: { pathname: '/accession-sample' } };
    
    if (applicationState?.authentication?.isAuthenticated) {
        return <Navigate to={from} />;
    }
    
    if (state.resetPassword) {
        return <Navigate to='/sendpassword' />;
    }
    
    return (
      <Container style={{ width: '500px', marginTop: '20px' }}>
          <Row>
              <Col>
                  <h1>Login</h1>
                  <Form onSubmit={handleLogin}>
                      <FormGroup>
                          <Label for="loginUsername">Email</Label>
                          <Input
                            type="text"
                            name="username"
                            id="loginUsername"
                            placeholder="Email Address"
                            value={state.username}
                            onChange={handleInputChange}
                            disabled={state.loading}
                          />
                      </FormGroup>
                      <FormGroup>
                          <Label for="loginPassword">Password</Label>
                          <Input
                            type="password"
                            name="password"
                            id="loginPassword"
                            placeholder="Password"
                            value={state.password}
                            onChange={handleInputChange}
                            disabled={state.loading}
                          />
                      </FormGroup>
                      {state.invalidLoginMessage && (
                        <Alert color="danger">{state.errorMessage || 'Invalid credentials'}</Alert>
                      )}
                      <Button
                        type="button"
                        outline
                        color="secondary"
                        className="float-left"
                        onClick={resetPassword}
                        disabled={state.loading}
                      >
                          Reset Password
                      </Button>
                      <Button
                        id="loginSubmit"
                        type="submit"
                        outline
                        color="danger"
                        className="float-right"
                        disabled={state.loading}
                      >
                          {state.loading ? <Spinner size="sm" color="danger"/> : 'Login'}
                      </Button>
                  </Form>
              </Col>
          </Row>
      </Container>
    );
};

Login.propTypes = {
    applicationState: PropTypes.object.isRequired,
    onLogin: PropTypes.func.isRequired
};

export default Login;
