import React, { useState } from "react"
import { Alert, Button, Label, Row, Col, Spinner, FormGroup, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import localizedFormat from "dayjs/plugin/localizedFormat"
import utc from "dayjs/plugin/utc"
import { useInsuredQuery } from "api/getters"

dayjs.extend(utc)
dayjs.extend(localizedFormat)

const UnfilledOrderDetails = ({ testId }) => {
    const { data, isLoading } = useInsuredQuery({
        variables: {
            page: 1,
            selector: {
                _id: testId,
                insured: true,
                testId: {
                    $or: [
                        { $regex: "JLBG" },
                        { $regex: "CT" }
                    ]
                }
            },

        },
    })

    const insuredData = data?.[0] || {}

    return (
        <form>
            {isLoading ? (
                <Spinner type="grow" />
            ) : (
                <Row>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Patient Id</Label>
                            <Link to={`/subject-info/${insuredData?.subjectId}/personal`} className="text-secondary" target="_blank">
                                <input readOnly type="text" className="form-control cursor-pointer" value={insuredData?.subjectId || ""} />
                            </Link>
                        </div>

                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Test Type</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.testType || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Patient First Name</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.firstName || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Collection Method</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.collectionMethod || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Patient Last Name</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.lastName || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Received Sample Date</Label>
                            <input
                                disabled
                                readOnly
                                type="text"
                                className="form-control"
                                value={insuredData?.laboratoryReceivedDateTime ? dayjs(insuredData?.laboratoryReceivedDateTime).format("MM-DD-YYYY") : ""}
                            />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Patient Middle Name</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.middleName || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Insurance Company</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.insuranceCompanyName || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Sample ID</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.testId || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Insurance Group</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.insuranceGroupId || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Physician Name</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.physicianFullName || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Insurance Membership #</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.insuranceSubscriberNo || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Tube Type</Label>
                            <input disabled readOnly type="text" className="form-control" value={insuredData?.tubeType || ""} />
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="mb-3">
                            <Label htmlFor="formrow-text-Input">Insurance Reported Date</Label>
                            <input
                                disabled
                                readOnly
                                type="text"
                                className="form-control"
                                value={insuredData?.insuranceReportedDate ? dayjs(insuredData?.insuranceReportedDate).format("MM-DD-YYYY") : ""}
                            />
                        </div>
                    </Col>
                </Row>
            )}
        </form>
    )
}

export default UnfilledOrderDetails
