import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Input, Label } from "reactstrap";
import { useQuery } from "hooks/query";
import { getEncodedQuery } from "utils/query";
import qs from "qs";

const DateFilter = ({ value, onChange }) => {
  return (
    <Input
      type="date"
      className="form-control"
      placeholder="Select Date"
      value={value}
      onChange={(e) => onChange(e.target.value)}
    />
  );
};

const FilterItem = ({ filterItem, query = "", selector, setPageIndex, ...rest }) => {
  const navigate = useNavigate()
  const queryObject = query ? qs.parse(query) : {}

  const { type, field, operator, placeholder, renderCustom, removeSpaces = false } = filterItem
  const [value, setValue] = useState(queryObject?.selector?.[field]?.[operator])
  const [hasFilterChange, setHasFilterChange] = useState(false)

  const delayedFunction = useCallback(
    (_value) => {
      const _queryObject = query ? qs.parse(query) : {}

      const params = {
        [field]: operator
          ? {
            ...(_queryObject?.selector?.[field] || {}),
            [operator]: _value,
          }
          : _value,
      };


      const queryParams = getEncodedQuery(params, "selector", query, { resetPage: true });

      navigate(
        {
          search: `?${queryParams}`,
        },
        { replace: true }
      );
    },
    [field, operator, query, navigate]
  );

  useEffect(() => {
    setValue(selector?.[field]?.[operator] || selector?.[field] || null);
  }, [selector, field, operator]);

  useEffect(() => {
    if (hasFilterChange) {
      const timer = setTimeout(() => {
        delayedFunction(value);
      }, operator ? 1000 : 0);

      return () => clearTimeout(timer);
    }
  }, [value, operator]);

  const handleChange = useCallback((_value) => {
    setValue(_value || null);
    setHasFilterChange(true);
  }, []);

  const renderInput = useCallback(() => {
    if (type === "date") {
      return <DateFilter value={value || ""} onChange={(newValue) => handleChange(newValue)} {...rest} />
    } else {
      return (
        <Input
          value={value || ""}
          className="form-control"
          placeholder={placeholder || "Search"}
          onChange={(e) => {
            if (removeSpaces) {
              e.target.value = e.target.value.replace(/\s/g, "");
            }
            e.target.value = e.target.value.replace(/^\s+/, "");
            handleChange(e.target.value);
          }}
          {...rest}
        />
      );
    }
  }, [field, value, rest, placeholder, handleChange])

  return renderCustom ? renderCustom(filterItem, value, handleChange) : <div className="input-group">{renderInput()}</div>;
};

const GlobalSearchFilter = ({ filters = [], setPageIndex, selector }) => {
  const query = useQuery();

  return (
    <React.Fragment>
      {filters.map((item, idx) => (
        <div key={item.field || idx}>
          {item.sectionTitle && (
            <div style={{ fontWeight: 'bold' }} className=" mb-2">
              <label>{item.sectionTitle}</label>
            </div>
          )}
          <Label className="form-label">{item.label}</Label>
          <div className="input-group">
            <FilterItem query={query.toString()} filterItem={item} selector={selector} setPageIndex={setPageIndex} />
          </div>
        </div>
      ))}
    </React.Fragment>
  );
};

export default GlobalSearchFilter;
