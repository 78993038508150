import React, { Component, useEffect, useRef, useState } from 'react';
import fetch from 'isomorphic-fetch';
import {
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    Col,
    Row,
    Table,
    Card,
    CardImg,
    CardBody,
    CardHeader,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import { matcher } from 'matcher';
import {useAlert} from "react-alert";
import lookups from "../configuration/lookups";
import LogoUploader from "./logo-uploader";
import {makeApiCall} from "../api/generic-api";
import { debounce } from "lodash";


const TestConfigForm = props => {
    const elements = useRef({});
    const inputRef = useRef(null);
    
    
    const alert = useAlert();
    const refresh = props.refresh;
    const newTestType = !props.editingTestType;
    let config = JSON.parse(JSON.stringify(props.config));
    
    if (newTestType) {
        config = {
            id: '', // or _id in lisTestConfiguration
            prefix: '',
            name: '',
            testType: '',
            tubeType: '',
            testGroup: '',
            testTypeLogo: '',
            logoTrademarked: false,
            collectionMethod: '',
            cancerRowCount: 0,
            cancerColumnCount: 0,
            cancerMaxFill: 0,
            analysisUrl: '',
            // for tray
            labelInit: '',
            idSuffix: '',
            statusesInit: ['Filling'],
            propertiesInit: [],
            categoryInit: '',
            rowCountInit: '',
            columnCountInit: '',
            maxFillInit: '',
            trayCount: '',
            labelPcr: '',
            compoundMapping: [],
            rowCountPcrInit: '',
            columnCountPcrInit: '',
            maxFillPcrInit: '',
            statusesPcr: ['Filling'],
            propertiesPcr: [],
            idSuffixPcr: '',
            statusInit: [],
            propertyInit: [],
            statusPcr: [],
            propertyPcr: [],
            compoundMap: [],
            // for netest
            negativeScoreStart: 0,
            negativeScoreEnd: 0,
            negativeProgression: false,
            positiveScoreStart: 0,
            positiveScoreEnd: 0,
            positiveProgression: false,
            
            
            normalProgressionStart: 0,
            normalProgressionEnd: 0,
            lowProgressionStart: 0,
            lowProgressionEnd: 0,
            intProgressionStart: 0,
            intProgressionEnd: 0,
            highProgressionStart: 0,
            highProgressionEnd: 0,
            rules: [],
            cancerStatuses: ['Filling', 'PCR Start', 'PCR Stop'],
            cancerProperties: [],
            
            hideProgressionCategory: false,
            scoreName: '', // New field
        };
    }
    else {
        let trayConfig = JSON.parse(JSON.stringify(props.config.tray || []));
        console.log('tray config', trayConfig);
        config.id = config.id;
        config.prefix = config.prefix;
        config.name = config.name;
        config.testType = config.testType;
        config.tubeType = config.tubeType;
        config.testGroup = config.testGroup;
        config.testTypeLogo = config.testTypeLogo;
        config.logoTrademarked = config.logoTrademarked;
        config.collectionMethod = config.collectionMethod;
        config.analysisUrl = config.analysisUrl;
        
        config.negativeScoreStart = config.negativeScoreStart || 0;
        config.negativeScoreEnd = config.negativeScoreEnd || 0;
        config.negativeProgression = config.negativeProgression || false;
        config.positiveScoreStart = config.positiveScoreStart || 0;
        config.positiveScoreEnd = config.positiveScoreEnd || 0;
        config.positiveProgression = config.positiveProgression || false;
        
        config.normalProgressionStart = config.normalProgressionStart || 0;
        config.normalProgressionEnd = config.normalProgressionEnd || 0;
        config.lowProgressionStart = config.lowProgressionStart || 0;
        config.lowProgressionEnd = config.lowProgressionEnd || 0;
        config.intProgressionStart = config.intProgressionStart || 0;
        config.intProgressionEnd = config.intProgressionEnd || 0;
        
        config.highProgressionStart = config.highProgressionStart || 0;
        config.highProgressionEnd = config.highProgressionEnd || 0;
        
        
        config.hideProgressionCategory = config.hideProgressionCategory || false;
        config.scoreName = config.scoreName || ''; // New field
        
        for (let i = 0; i < trayConfig.length; i++) {
            const tray = trayConfig[i];
            config.cancerRowCount = tray.rowCount;
            config.cancerRowCount = tray.rowCount;
            config.cancerColumnCount = tray.columnCount;
            config.cancerMaxFill = tray.maxFill;
            config.cancerStatuses = tray.statuses;
            config.cancerProperties = tray.properties;
            if (i < 1) {
                config.labelInit = tray.label;
                config.idSuffix = tray.idSuffix || '';
                config.statusesInit = tray.statuses;
                config.propertiesInit = tray.properties;
                if (!tray.compound) {
                    config.rowCountInit = tray.rowCount || 0;
                    config.columnCountInit = tray.columnCount || 0;
                    config.maxFillInit = tray.maxFill || 0;
                } else {
                    const compoundTray = tray.compound;
                    for (let c = 0; c < compoundTray.length; c++) {
                        const compTray = compoundTray[c];
                        config.trayCount = compoundTray.length;
                        config.rowCountInit = compTray.rowCount;
                        config.columnCountInit = compTray.columnCount;
                        config.maxFillInit = compTray.maxFill;
                    }
                }
            } else {
                // pcr
                config.labelPcr = tray.label;
                config.compoundMapping = tray.compoundMapping || [];
                config.rowCountPcrInit = tray.rowCount || 0;
                config.columnCountPcrInit = tray.columnCount || 0;
                config.maxFillPcrInit = tray.maxFill || 0;
                config.statusesPcr = tray.statuses;
                config.propertiesPcr = tray.properties;
                config.idSuffixPcr = tray.idSuffix || '';
            }
        }
    }
    
    const [ state, setState ] = useState({
        config: config,
        newTestType: newTestType,
        previousConfig: JSON.parse(JSON.stringify(props.config)),
        existingIds: JSON.parse(JSON.stringify(props.existingIds)),
        checkedSingleInit: false,
        checkedCompoundInit: false,
        selectedImage: config.testTypeLogo || null,
        createNewRule: false,
        editingRule: false
    });
    
    const [ rule, setRule ] = useState({});
    
    const [ isValidPrefix, setIsValidPrefix ] = useState(null);
    const [ isValidSuffix, setIsValidSuffix ] = useState(null);
    const [ isValidTestType, setIsValidTestType ] = useState(null);
    const [ isValidScoreName, setIsValidScoreName ] = useState(null);
    const [ isValidUrl, setIsValidUrl ] = useState(null);
    const [ isValidTestGroup, setIsValidTestGroup ] = useState(null);
    const [ isValidCancerInputs, setIsValidCancerInputs ] = useState(null);
    
    const [ invalidCategories, setInvalidCategories ] = useState([]);
    
    const newRule = () => {
        setState(prevState => ({
            ...prevState,
            createNewRule: true
        }));
    };
    
    const deleteRule = (field) => {
        const rules = state.config.rules;
        let newRuleState;
        // let config = JSON.parse(JSON.stringify(state.config));
        
        if (rules) {
            const index = rules.findIndex(obj => obj.fieldName === field);
            rules.splice(index, 1);
            
            setState(prevState => ({
                ...prevState,
                createNewRule: false,
                editingRule: false
            }));
        }
        
    };
    
    const validateRuleFields = () => {
        if (rule && Object.keys(rule).length < 3) {
            return false;
        }
        return true;
    };
    
    const handleConfirmRule = async () => {
        const validRuleFields = validateRuleFields();
        
        if (validRuleFields === true) {
            state.config.rules.push(rule);
            alert.show('Added new rule', {
                type: 'success'
            });
            setState(prevState => ({
                ...prevState,
                createNewRule: false,
                editingRule: false
            }));
            setRule({});
        } else {
            alert.show('Please fill in all required fields for rules',
              {
                  type: 'error'
              });
        }
    };
    
    const handleCloseRule = async () => {
        setState(prevState => ({
            ...prevState,
            createNewRule: false,
            editingRule: false
        }));
        setRule({});
    };
    
    const handleChange = (e) => {
        e.persist();
        
        const target = e.target;
        const name = target.name;
        const value = target.value;
        
        rule[name] = value;
        setRule(rule);
    };
    
    const handleScoreChange = (e) => {
        e.persist();
        
        const target = e.target;
        const name = target.name;
        const value = target.value;
        
        scoreCategory[name] = value;
        setScoreCategory(scoreCategory);
        
    }
    
    function isInRange(value, min, max) {
        return typeof value === 'number' && value >= min && value < max;
    }
    
    function isValidSequence(start, end) {
        return isInRange(start, 0, 100) && isInRange(end, 0, 101) && start < end;
    }
    
    const saveForm = async (e) =>  {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        
        if(config) {
            const validInputs = await validateRequiredInputs(config);
            // Validate progression sequence without gaps or overlaps
            const progressionValidation = [
                {start: config.normalProgressionStart, end: config.normalProgressionEnd, label: "Normal Progression"},
                {start: config.lowProgressionStart, end: config.lowProgressionEnd, label: "Low Progression"},
                {start: config.intProgressionStart, end: config.intProgressionEnd, label: "Intermediate Progression"},
                {start: config.highProgressionStart, end: config.highProgressionEnd, label: "High Progression"}
            ];
            
            // Validate score sequence without gaps or overlaps
            const scoreValidation = [
                {start: config.negativeScoreStart, end: config.negativeScoreEnd, label: "Negative Score"},
                {start: config.positiveScoreStart, end: config.positiveScoreEnd, label: "Positive Score"}
            ];
            
            const validateSequence = (sequence) => {
                let messages = [];
                for (let i = 0; i < sequence.length - 1; i++) {
                    if (sequence[i].end + 1 !== sequence[i + 1].start) {
                        messages.push(`Gap or overlap between ${sequence[i].label} and ${sequence[i + 1].label}.`);
                    }
                }
                // Validate start of first and end of last item for completeness
                if (sequence[0].start !== 0) {
                    messages.push(`${sequence[0].label} does not start at 0.`);
                }
                if (sequence[sequence.length - 1].end !== 100) {
                    messages.push(`${sequence[sequence.length - 1].label} does not end at 100.`);
                }
                return messages;
            };
            
            const progressionMessages = state.config.hideProgressionCategory ? []: validateSequence(progressionValidation);
            const scoreMessages = validateSequence(scoreValidation);
            const invalidCategoriesData =  [...progressionMessages, ...scoreMessages];
            
            
            setInvalidCategories(invalidCategoriesData)
            if (!validInputs || invalidCategoriesData.length) {
                alert.show('Unable to save test configuration. Please check invalid inputs', {
                    type: 'error'
                });
            } else {
                if (newTestType === true) {
                    let url = '/api/test-type';
                    let method = 'post';
                    
                    const saveResult = await makeApiCall(method, url, config);
                    console.log('result', saveResult);
                    
                    try {
                        if (saveResult.ok) {
                            setState(prevState => ({
                                ...prevState,
                                config: config,
                                previousConfig: JSON.parse(JSON.stringify(config))
                            }));
                            
                            props.done(config, state.newTestType);
                            const successMessage = "Successfully saved test configuration";
                            alert.show(successMessage, {
                                type: 'success'
                            });
                            props.setIsOpenForm(false);
                        } else {
                            console.log('body', saveResult.error);
                            const errorMessage = `Error in saving test configuration. ${saveResult.error}`;
                            alert.show(errorMessage, {
                                type: 'error'
                            });
                        }
                    } catch (e) {
                        props.setError(`Could not save the changes. ${e}` );
                    }
                } else {
                    let url = '/api/test-type';
                    let method = 'put';
                    
                    const updateResult = await makeApiCall(method, url, config);
                    
                    try {
                        if (updateResult.ok) {
                            setState(prevState => ({
                                ...prevState,
                                config: config,
                                previousConfig: JSON.parse(JSON.stringify(config))
                            }));
                            
                            props.done(config, state.newTestType);
                            // return response.status;
                            const successMessage = updateResult.body.message;
                            alert.show(successMessage, {
                                type: 'success'
                            });
                            props.setIsOpenForm(false)
                        } else {
                            console.log('body', updateResult.error);
                            const errorMessage = `Error in updating test configuration. ${updateResult.error}`;
                            alert.show(errorMessage, {
                                type: 'error'
                            });
                        }
                    } catch (e) {
                        props.setError(`Could not update the changes. ${e}` );
                    }
                }
            }
        } else {
            alert.show('Unable to save. No Inputs available', {
                type: 'error'
            });
        }
    };
    
    const cancel = () => {
        let config = JSON.parse(JSON.stringify(state.previousConfig));
        setState(prevState => ({
            ...prevState,
            config: config
        }));
        
        props.done(null);
        props.setIsOpenForm(false);
    };
    
    const validateTestType = async (id, testType) => {
        console.log('validating test type');
        const existingSuffixes = state.existingIds;
        let validation;
        for (let x = 0; x < existingSuffixes.length; x++) {
            const suffixId = existingSuffixes[x].id;
            console.log('suffixId', id, suffixId);
            const testTypeId = existingSuffixes[x].testType;
            if (id && id === suffixId && testType === testTypeId) {
                validation = { valid: false, error: { message: 'Suffix with the same test configuration id already exists'} };
                setIsValidSuffix(false);
                alert.show(validation.error.message, {
                    type: 'error'
                });
            }
            
            if (id && id === suffixId) {
                validation = { valid: false, error: { message: 'Suffix already exists'} };
                setIsValidSuffix(false);
                alert.show(validation.error.message, {
                    type: 'error'
                });
            }
            
            if (testType === testTypeId) {
                validation = { valid: false, error: { message: 'Test type already exists'} };
                setIsValidTestType(false);
                alert.show(validation.error.message, {
                    type: 'error'
                });
            }
            
            validation = { valid: true, error: { message: '' }}
        }
        console.log('end validating test type');
        return validation;
    };
    
    const validatePrefix = (prefix) => {
        let validation;
        console.log('validating prefix', prefix);
        const re = /^[A-Za-z]+$/;
        const reTest = re.test(prefix);
        if (!prefix || prefix.length > 4 || prefix.length < 4 || reTest === false) {
            validation = false;
            setIsValidPrefix(false);
        } else {
            validation = true;
            setIsValidPrefix(true);
        }
        console.log('validated prefix', validation);
        return validation
    };
    
    const validateSuffix = (suffix) => {
        let validation;
        console.log('validating suffix', suffix);
        const re = /^[A-Za-z]+$/;
        const reTest = re.test(suffix);
        if (suffix.length > 1 || suffix.length < 1 || reTest === false) {
            validation = false;
            setIsValidSuffix(false);
        } else {
            validation = true;
            setIsValidSuffix(true);
        }
        console.log('validated suffix', validation);
        return validation
    };
    
    const validateTtype = (testType) => {
        let validation;
        console.log('validating testType', testType);
        if (testType === null || testType === '') {
            validation = false;
            setIsValidTestType(false);
        } else {
            validation = true;
            setIsValidTestType(true);
        }
        console.log('validated testType', validation);
        return validation
    };
    
    const validateUrl = (urlString) => {
        console.log('validating url', urlString);
        let validation;
        const urlPattern = new RegExp('^(https?:\\/\\/)?'+ // validate protocol
          '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // validate domain name
          '((\\d{1,3}\\.){3}\\d{1,3}))'+ // validate OR ip (v4) address
          '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // validate port and path
          '(\\?[;&a-z\\d%_.~+=-]*)?'+ // validate query string
          '(\\#[-a-z\\d_]*)?$','i'); // validate fragment locator
        const isValidUrl = urlPattern.test(urlString);
        if (!isValidUrl || (urlString === null || urlString === '')) {
            validation = false;
            setIsValidUrl(false)
        } else {
            validation = true;
            setIsValidUrl(true)
        }
        return validation;
    };
    
    const validateTestGroup = (testGroup) => {
        let validation;
        console.log('validating testGroup', testGroup);
        if (!testGroup || testGroup === "") {
            validation = false;
            setIsValidTestGroup(false);
        } else {
            validation = true;
            setIsValidTestGroup(true);
        }
        console.log('validated testGroup', validation);
        return validation;
    };
    
    const validateRequiredInputs = (config) => {
        const validPrefix = validatePrefix(config.prefix);
        const validTestType = validateTtype(config.testType);
        const validSuffix = validateSuffix(config.id);
        const validUrl = validateUrl(config.analysisUrl);
        const validTestGroup = validateTestGroup(config.testGroup);
        const validScoreName = !!config.scoreName;
        
        console.log('validScoreNameX: ', validScoreName);
        
        if (newTestType) {
            console.log('newTestType')
            if (validPrefix && validTestType && validSuffix && validUrl && validTestGroup && validScoreName) {
                return true;
            } else {
                if (!validScoreName) {
                    setIsValidScoreName(false)
                }
                
                if (!validPrefix) {
                    setIsValidPrefix(false);
                }
                
                if (!validSuffix) {
                    setIsValidSuffix(false);
                }
                
                if (!validTestType) {
                    setIsValidTestType(false);
                }
                
                if (!validUrl) {
                    setIsValidUrl(false);
                }
                
                if (!validTestGroup) {
                    setIsValidTestGroup(false);
                }
                return false;
            }
        } else {
            console.log('not newtesttype')
            if (validPrefix && validTestType && validUrl && validScoreName) {
                return true;
            } else {
                if (!validScoreName) {
                    setIsValidScoreName(false)
                }
                
                if (!validPrefix) {
                    setIsValidPrefix(false);
                }
                
                if (!validTestType) {
                    setIsValidTestType(false);
                }
                
                if (!validUrl) {
                    setIsValidUrl(false);
                }
                return false;
            }
        }
    };
    // test config
    
    const handleConfigChange = (e) => {
        e.persist();
        const target = e.target;
        console.log('targetX: ', target);
        
        let name = target.name;
        let config = JSON.parse(JSON.stringify(state.config));
        let value;
        
        if (target.type === 'checkbox') {
            value = target.checked;
        } else if (target.type === 'number' && (name === 'cancerRowCount' || name === 'cancerColumnCount' )) {
            // value = Math.max(target.min, Math.min(target.max, Number(target.value)));
            value = Math.max(target.min, Number(target.value));
        } else if (target.type === 'number' && name === 'cancerMaxFill') {
            const maxAllowedValue = Number(Number(config.cancerRowCount) * Number(config.cancerColumnCount));
            console.log('max allowed value', maxAllowedValue);
            value = Math.max(target.min, Math.min(maxAllowedValue, Number(target.value)));
        } else if (target.type === 'text' && name === 'prefix') {
            value = target.value.toUpperCase();
        } else if (target.type === 'text' && name === 'id') {
            value = target.value.toUpperCase();
        } else if (target.type === 'number') {
            value = Number(target.value);
        } else {
            value = target.value
        }
        
        config[name] = value;
        setState(prevState => ({
            ...prevState,
            config: config
        }));
    }
    
    const handleNegativeScoreChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        
        const target = e.target;
        const name = target.name;
        const value = target.value;
        
        negativeScore[name] = value;
        setNegativeScore(negativeScore);
    };
    
    const handlePositiveScoreChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        
        const target = e.target;
        const name = target.name;
        const value = target.value;
        
        positiveScore[name] = value;
        setPositiveScore(positiveScore);
    }
    // end of test config
    
    // for tray config
    // initial tray
    
    const handleStatusesChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        const value = e.target.value;
        const splitValues = value.split('\n');
        
        config.statusesInit = value;
        config.statusInit = splitValues;
        setState(prevState => ({
            ...prevState,
            config: config
        }));
    };
    
    const handleCancerStatusesChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        const value = e.target.value;
        const splitValues = value.split('\n');
        
        config.cancerStatuses = value;
        config.cancerStat = splitValues;
        setState(prevState => ({
            ...prevState,
            config: config
        }));
    }
    
    const handlePropertiesChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        const value = e.target.value;
        const splitValues = value.split("\n");
        
        config.propertiesInit = value;
        config.propertyInit = splitValues;
        setState(prevState => ({
            ...prevState,
            config: config
        }));
    };
    
    const handleCancerPropertiesChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        const value = e.target.value;
        const splitValues = value.split("\n");
        
        config.cancerProperties = value;
        config.cancerProp = splitValues;
        setState(prevState => ({
            ...prevState,
            config: config
        }));
    };
    
    const handleInitialCategory = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        const target = e.target;
        const name = target.name;
        const value = target.type === 'checkbox' ? target.checked: target.value;
        
        state[name] = value;
        setState(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    // end of initial tray
    
    //pcr
    const handlePcrStatusesChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        const value = e.target.value;
        const newValue = value.split("\n");
        
        config.statusesPcr = value;
        config.statusPcr = newValue;
        setState(prevState => ({
            ...prevState,
            config: config
        }));
    };
    
    const handlePcrPropertiesChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        const value = e.target.value;
        const newValue = value.split("\n");
        
        config.propertiesPcr = value;
        config.propertyPcr = newValue;
        setState(prevState => ({
            ...prevState,
            config: config
        }));
    };
    
    const handleCompMappingChange = (e) => {
        e.persist();
        let config = JSON.parse(JSON.stringify(state.config));
        const value = e.target.value;
        const newValue = value.split("\n");
        
        config.compoundMapping = value;
        config.compoundMap = newValue;
        setState(prevState => ({
            ...prevState,
            config: config
        }));
    };
    // end of pcr
    
    const getOptions = (source, noEmpty) => {
        const empty = noEmpty ? [] : [
            {
                key: '',
                value: ''
            }
        ];
        
        const lookupResult = empty.concat(lookups.filter(s => s.type === source));
        return lookupResult;
    }
    
    useEffect(() => {
        console.log('state is', state);
    }, [state, rule]);
    
    useEffect(() => {
        if (state.config.hideProgressionCategory) {
            let config = JSON.parse(JSON.stringify(state.config));
            config.normalProgressionStart = 0
            config.normalProgressionEnd = 0
            config.lowProgressionStart = 0
            config.lowProgressionEnd = 0
            config.intProgressionStart = 0
            config.intProgressionEnd = 0
            
            // config.positiveScoreInterpretation = ''
            // config.negativeScoreInterpretation = ''
            
            setState(prevState => ({
                ...prevState,
                config: config
            }));
        }
        
    }, [state.config.hideProgressionCategory])
    
    return (
      <div hidden={props.hidden}>
        <Form onSubmit={saveForm}>
          <FormGroup>
            <h4 style={{ marginTop: "30px" }}>Test Type Details</h4>
            <Row style={{ marginLeft: 20 }}>
              <Col className="my-3">
                <Label for="formSuffix">Prefix *</Label>{" "}
                <Input
                  type="text"
                  name="prefix"
                  id="formPrefix"
                  value={state.config.prefix}
                  onChange={handleConfigChange}
                  // disabled={!state.newTestType}
                />
                {isValidPrefix === false && <span className="validation-error">Should contain 4 letters only</span>}
              </Col>
              <Col className="my-3">
                <Label for="formSuffix">Suffix *</Label>
                <Input type="text" name="id" id="formSuffix" value={state.config.id} onChange={handleConfigChange} disabled={!state.newTestType} />
                {isValidSuffix === false && <span className="validation-error">Should contain 1 letter only</span>}
              </Col>
            </Row>
            <Row style={{ marginLeft: 20 }}>
              <Col className="my-3">
                <Label for="formTestType">Test Type *</Label>
                <Input type="text" name="testType" id="formTestType" value={state.config.testType} onChange={handleConfigChange} maxLength={10} />
                {isValidTestType === false && <span className="validation-error">Test Type can't be empty</span>}
              </Col>
              <Col className="my-3">
                <Label for="formName">Name</Label>
                <Input type="text" name="name" id="formName" value={state.config.name} onChange={handleConfigChange} maxLength={15} />
              </Col>
            </Row>
            <Row style={{ marginLeft: 20 }}>
              <Col className="my-3">
                <Label for="formScoreName">Score Name *</Label>
                <Input type="text" name="scoreName" id="formScoreName" value={state.config.scoreName} onChange={handleConfigChange} required={true} />
                {isValidScoreName === false && <span className="validation-error">Score name can't be empty</span>}
              </Col>
              <Col></Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <h4 style={{ marginTop: "20px" }}>Test Type Configuration</h4>
            <Row style={{ marginLeft: 20 }} className="my-3">
              <Col className="my-3">
                <Label for="formTubeType">Tube Type</Label>
                <Input type="select" name="tubeType" id="formTubeType" value={state.config.tubeType} onChange={handleConfigChange}>
                  {getOptions("tubeType").map((item) => (
                    <option key={item.key} id={item.key} value={item.key}>
                      {item.value || item.key}
                    </option>
                  ))}
                </Input>
              </Col>
              <Col className="my-3">
                <Label for="formTestGroup">Test Group</Label>
                <Input type="select" name="testGroup" id="formTestGroup" value={state.config.testGroup} onChange={handleConfigChange}>
                  {getOptions("testGroup").map((item) => (
                    <option key={item.key} id={item.key} value={item.key}>
                      {item.value || item.key}
                    </option>
                  ))}
                </Input>
                {isValidTestGroup === false && <span className="validation-error">Test Group is required</span>}
              </Col>
            </Row>
          </FormGroup>

          <FormGroup>
            <Row style={{ marginLeft: 20 }}>
              <Col className="my-3">
                <Label for="formCollection">Collection Method</Label>
                <Input type="text" name="collectionMethod" id="formCollection" value={state.config.collectionMethod} onChange={handleConfigChange} />
              </Col>
              <Col className="my-3">
                <Label for="formAnalysis">Analysis Url</Label>
                <Input type="text" name="analysisUrl" id="formAnalysis" value={state.config.analysisUrl} onChange={handleConfigChange} />
                {isValidUrl === false && <span className="validation-error">Url should be in this format: http://xxx.xxx.xx or https://xxx.xxx.xx</span>}
              </Col>
            </Row>
          </FormGroup>

          {state.config?.testGroup?.includes("cancer") ? (
            <>
              <FormGroup>
                <h4 style={{ marginTop: "20px" }}>Tray Configuration</h4>
                <Row style={{ marginLeft: 20, marginBottom: 20 }}>
                  <Col className="my-3">
                    <Label for="formCancerRowCount">Row Count</Label>
                    <Input
                      type="number"
                      name="cancerRowCount"
                      id="formCancerRowCount"
                      value={state.config.cancerRowCount ? state.config.cancerRowCount : 0}
                      onChange={handleConfigChange}
                      min="1"
                    />
                  </Col>
                  <Col className="my-3">
                    <Label for="formCancerColumnCount">Column Count</Label>
                    <Input
                      type="number"
                      name="cancerColumnCount"
                      id="formCancerColumnCount"
                      value={state.config.cancerColumnCount ? state.config.cancerColumnCount : 0}
                      onChange={handleConfigChange}
                      min="1"
                    />
                  </Col>
                  <Col className="my-3">
                    <Label for="formCancerMaxFill">Max Fill</Label>
                    <Input
                      type="number"
                      name="cancerMaxFill"
                      id="formCancerMaxFill"
                      value={state.config.cancerMaxFill ? state.config.cancerMaxFill : 0}
                      onChange={handleConfigChange}
                      min="1"
                    />
                  </Col>
                </Row>
                <Row style={{ marginLeft: 20, marginBottom: 20 }}>
                  <Col className="my-3">
                    <Label for="formCancerStatuses">Statuses</Label>
                    <Input
                      type="textarea"
                      name="cancerStatuses"
                      id="formCancerStatuses"
                      value={state.config.cancerStatuses}
                      onChange={handleCancerStatusesChange}
                    />
                  </Col>
                  <Col className="my-3">
                    <Label for="formProperties">Properties (optional, press enter to add another item)</Label>
                    <Input
                      type="textarea"
                      name="propertiesInit"
                      id="formProperties"
                      value={state.config.cancerProperties}
                      onChange={handleCancerPropertiesChange}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <h4 style={{ marginTop: 20 }}>Score Category</h4>
                <Row>
                  <Col sm="3">
                    <Card>
                      <CardHeader>{state.config.negativeScoreLabel || "NEGATIVE"}</CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <Label for="negativeScoreStart">Start</Label>
                            <Input
                              type="number"
                              label="NEGATIVE"
                              name="negativeScoreStart"
                              value={state.config.negativeScoreStart}
                              onChange={handleConfigChange}
                              innerRef={(element) => (elements.current["negativeScoreStart"] = element)}
                            />
                          </Col>
                        </Row>

                        <Row className="my-3">
                          <Col>
                            <Label for="negativeScoreEnd">End</Label>
                            <Input
                              type="number"
                              name="negativeScoreEnd"
                              value={state.config.negativeScoreEnd}
                              onChange={handleConfigChange}
                              innerRef={(element) => (elements.current["negativeScoreEnd"] = element)}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <Label for="negativeScoreLabel">Label</Label>
                            <Input
                              type="text"
                              name="negativeScoreLabel"
                              value={state.config.negativeScoreLabel || ""}
                              onChange={handleConfigChange}
                              innerRef={(element) => (elements.current["negativeScoreLabel"] = element)}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <Label for="negativeScoreResult">Result</Label>
                            <Input
                              type="text"
                              name="negativeScoreResult"
                              value={state.config.negativeScoreResult || ""}
                              onChange={handleConfigChange}
                              innerRef={(element) => (elements.current["negativeScoreResult"] = element)}
                            />
                          </Col>
                        </Row>
                        {state.config.hideProgressionCategory && (
                          <Row className="my-3">
                            <Col>
                              <Label for="negativeScoreInterpretation">Interpretation</Label>
                              <Input
                                type="text"
                                name="negativeScoreInterpretation"
                                value={state.config.negativeScoreInterpretation || ""}
                                onChange={handleConfigChange}
                                innerRef={(element) => (elements.current["negativeScoreInterpretation"] = element)}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row style={{ marginLeft: 20, marginTop: 20 }}>
                          <Col>
                            <Input
                              id="progression-checkbox"
                              name="negativeProgression"
                              type="checkbox"
                              value={state.config.negativeProgression}
                              disabled={state.config.negativeScoreStart === null ? true : false}
                              checked={!!state.config.negativeProgression}
                              onChange={handleConfigChange}
                            />
                            <Label for="negativeProgression" style={{ marginLeft: "1rem" }}>
                              Progression
                            </Label>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="3">
                    <Card>
                      <CardHeader>{state.config.positiveScoreLabel || "POSITIVE"}</CardHeader>
                      <CardBody>
                        <Row>
                          <Col>
                            <Label for="positiveScoreStart">Start</Label>
                            <Input
                              type="number"
                              name="positiveScoreStart"
                              value={state.config.positiveScoreStart}
                              onChange={handleConfigChange}
                              innerRef={(element) => (elements.current["positiveScoreStart"] = element)}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <Label for="positiveScoreEnd">End</Label>
                            <Input
                              type="number"
                              name="positiveScoreEnd"
                              value={state.config.positiveScoreEnd}
                              onChange={handleConfigChange}
                              innerRef={(element) => (elements.current["positiveScoreEnd"] = element)}
                            />
                          </Col>
                        </Row>
                        <div className="my-3"></div>
                        <Row>
                          <Col>
                            <Label for="positiveScoreLabel">Label</Label>
                            <Input
                              type="text"
                              name="positiveScoreLabel"
                              value={state.config.positiveScoreLabel || ""}
                              onChange={handleConfigChange}
                              innerRef={(element) => (elements.current["positiveScoreLabel"] = element)}
                            />
                          </Col>
                        </Row>
                        <Row className="my-3">
                          <Col>
                            <Label for="positiveScoreResult">Result</Label>
                            <Input
                              type="text"
                              name="positiveScoreResult"
                              value={state.config.positiveScoreResult || ""}
                              onChange={handleConfigChange}
                              innerRef={(element) => (elements.current["positiveScoreResult"] = element)}
                            />
                          </Col>
                        </Row>
                        {state.config.hideProgressionCategory && (
                          <Row className="my-3">
                            <Col>
                              <Label for="positiveScoreInterpretation">Interpretation</Label>
                              <Input
                                type="text"
                                name="positiveScoreInterpretation"
                                value={state.config.positiveScoreInterpretation || ""}
                                onChange={handleConfigChange}
                                innerRef={(element) => (elements.current["positiveScoreInterpretation"] = element)}
                              />
                            </Col>
                          </Row>
                        )}
                        <Row style={{ marginLeft: 20, marginTop: 20 }}>
                          <Col>
                            <Input
                              id="progression-checkbox"
                              name="positiveProgression"
                              type="checkbox"
                              value={state.config.positiveProgression}
                              disabled={state.config.positiveScoreStart === null ? true : false}
                              checked={!!state.config.positiveProgression}
                              onChange={handleConfigChange}
                            />
                            <Label for="positiveProgression" style={{ marginLeft: "1rem" }}>
                              Progression
                            </Label>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col sm="3">
                    {invalidCategories.length ? <div className="validation-error">Category Errors:</div> : <div></div>}
                    {invalidCategories.length ? (
                      invalidCategories.map((invalid, idx) => {
                        return (
                          <span className="validation-error" key={idx}>
                            * {invalid}
                            <br />
                          </span>
                        )
                      })
                    ) : (
                      <div></div>
                    )}
                  </Col>
                </Row>
              </FormGroup>

              <FormGroup>
                <Col>
                  <Input
                    id="progression-checkbox"
                    name="hideProgressionCategory"
                    type="checkbox"
                    value={state.config.hideProgressionCategory}
                    checked={!!state.config.hideProgressionCategory}
                    onChange={handleConfigChange}
                  />
                  <Label for="hideProgressionCategory" style={{ marginLeft: "1rem" }}>
                    <b>Hide Progression Category</b>
                  </Label>
                </Col>

                {!state.config.hideProgressionCategory && (
                  <div>
                    <h4 style={{ marginTop: 20 }}>Progression Category</h4>
                    <Row>
                      <Col sm="3">
                        <Card>
                          <CardHeader>NORMAL</CardHeader>
                          <CardBody>
                            <Row>
                              <Col>
                                <Label for="normalProgressionStart">Start</Label>
                                <Input
                                  type="number"
                                  name="normalProgressionStart"
                                  value={state.config.normalProgressionStart}
                                  onChange={handleConfigChange}
                                  innerRef={(element) => (elements.current["normalProgressionStart"] = element)}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label for="normalProgressionEnd">End</Label>
                                <Input
                                  type="number"
                                  name="normalProgressionEnd"
                                  value={state.config.normalProgressionEnd}
                                  onChange={handleConfigChange}
                                  innerRef={(element) => (elements.current["normalProgressionEnd"] = element)}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <CardHeader>LOW</CardHeader>
                          <CardBody>
                            <Row>
                              <Col>
                                <Label for="lowProgressionStart">Start</Label>
                                <Input
                                  type="number"
                                  name="lowProgressionStart"
                                  value={state.config.lowProgressionStart}
                                  onChange={handleConfigChange}
                                  innerRef={(element) => (elements.current["lowProgressionStart"] = element)}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label for="lowProgressionEnd">End</Label>
                                <Input
                                  type="number"
                                  name="lowProgressionEnd"
                                  value={state.config.lowProgressionEnd}
                                  onChange={handleConfigChange}
                                  innerRef={(element) => (elements.current["lowProgressionEnd"] = element)}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <CardHeader>INTERMEDIATE</CardHeader>
                          <CardBody>
                            <Row>
                              <Col>
                                <Label for="intProgressionStart">Start</Label>
                                <Input
                                  type="number"
                                  name="intProgressionStart"
                                  value={state.config.intProgressionStart}
                                  onChange={handleConfigChange}
                                  innerRef={(element) => (elements.current["intProgressionStart"] = element)}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label for="intProgressionEnd">End</Label>
                                <Input
                                  type="number"
                                  name="intProgressionEnd"
                                  value={state.config.intProgressionEnd}
                                  onChange={handleConfigChange}
                                  innerRef={(element) => (elements.current["intProgressionEnd"] = element)}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col sm="3">
                        <Card>
                          <CardHeader>HIGH</CardHeader>
                          <CardBody>
                            <Row>
                              <Col>
                                <Label for="highProgressionStart">Start</Label>
                                <Input
                                  type="number"
                                  name="highProgressionStart"
                                  value={state.config.highProgressionStart}
                                  onChange={handleConfigChange}
                                  innerRef={(element) => (elements.current["highProgressionStart"] = element)}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Label for="highProgressionEnd">End</Label>
                                <Input
                                  type="number"
                                  name="highProgressionEnd"
                                  value={state.config.highProgressionEnd}
                                  onChange={handleConfigChange}
                                  innerRef={(element) => (elements.current["highProgressionEnd"] = element)}
                                />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                )}
              </FormGroup>

              <FormGroup>
                <h4 style={{ marginTop: "40px" }}>PCR Upload Rules</h4>
                <Row>
                  <Col>
                    <Table responsive hover>
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Field Name</th>
                          <th>Operator</th>
                          <th>Value</th>
                          <th>Action</th>
                          <th>
                            <Button
                              outline
                              color="success"
                              onClick={() => {
                                newRule()
                              }}
                            >
                              <span className="fa fa-plus"></span> Add New Rule
                            </Button>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.config.rules && state.config.rules.length > 0 ? (
                          state.config.rules.map((config, i) => (
                            <tr key={i}>
                              <td>{i + 1}</td>
                              <td>{config.fieldName}</td>
                              <td>{config.operator}</td>
                              <td>{config.ruleValue}</td>
                              <td>
                                <Button
                                  outline
                                  color="secondary"
                                  onClick={() => {
                                    deleteRule(config.fieldName)
                                  }}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={6}>
                              <center className="mt-3">
                                <h5>No rules found</h5>
                              </center>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </FormGroup>
            </>
          ) : (
            <FormGroup>
              <div className="wide-container">
                <Row style={{ marginTop: "30px" }}>
                  <Col sm="12" className="text-left">
                    <h4>Tray Configuration</h4>
                  </Col>
                </Row>
                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Row>
                        <Col sm="12">
                          <Card>
                            <CardHeader>INITIAL TRAY</CardHeader>
                            <CardBody>
                              <Row>
                                <Col>
                                  <Label for="formLabel">Label</Label>
                                  <Input type="text" name="labelInit" id="formLabel" value={state.config.labelInit} onChange={handleConfigChange} />
                                </Col>
                                <Col>
                                  <Label for="formIdSuffix">Id Suffix</Label>
                                  <Input type="text" name="idSuffix" id="formIdSuffix" value={state.config.idSuffix} onChange={handleConfigChange} />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Label for="formStatuses">Statuses (press enter to add another item)</Label>
                                  <Input
                                    type="textarea"
                                    name="statusesInit"
                                    id="formStatuses"
                                    value={state.config.statusesInit}
                                    onChange={handleStatusesChange}
                                  />
                                </Col>
                                <Col>
                                  <Label for="formProperties">Properties (optional, press enter to add another item)</Label>
                                  <Input
                                    type="textarea"
                                    name="propertiesInit"
                                    id="formProperties"
                                    value={state.config.propertiesInit}
                                    onChange={handlePropertiesChange}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup check>
                                    <Row style={{ marginTop: 30 }}>
                                      <Col sm="3" className="text-left">
                                        <Label for="formCategory">CATEGORY (select one to edit)</Label>
                                      </Col>
                                    </Row>
                                    <Row style={{ marginLeft: 50 }}>
                                      <Col sm="2" className="text-left">
                                        <Input
                                          type="checkbox"
                                          name="checkedSingleInit"
                                          id="formSingle"
                                          value={state.checkedSingleInit}
                                          disabled={state.checkedCompoundInit ? true : false}
                                          onChange={handleInitialCategory}
                                        />{" "}
                                        <Label for="formSingle" check>
                                          Single
                                        </Label>
                                      </Col>
                                      <Col sm="2" className="text-left">
                                        <Input
                                          type="checkbox"
                                          name="checkedCompoundInit"
                                          id="formCompound"
                                          value={state.checkedCompoundInit}
                                          disabled={state.checkedSingleInit ? true : false}
                                          onChange={handleInitialCategory}
                                        />{" "}
                                        <Label for="formCompound" check>
                                          Compound
                                        </Label>
                                      </Col>
                                    </Row>
                                    <Row style={{ marginTop: 30, marginLeft: 30 }}>
                                      <Col sm="6" className="text-left">
                                        <Card>
                                          <CardBody>
                                            <Row>
                                              <Col sm="3">
                                                <FormGroup hidden={state.checkedSingleInit}>
                                                  <Label for="formTrayCount">Tray Count</Label>
                                                  <Input
                                                    style={{ maxWidth: "100px" }}
                                                    type="number"
                                                    name="trayCount"
                                                    id="formTrayCount"
                                                    value={state.config.trayCount}
                                                    disabled={
                                                      state.checkedSingleInit || (!state.checkedSingleInit && !state.checkedCompoundInit) ? true : false
                                                    }
                                                    onChange={handleConfigChange}
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col sm="3">
                                                <Label for="formRowCountInit">Row Count</Label>
                                                <Input
                                                  style={{ maxWidth: "100px" }}
                                                  type="number"
                                                  name="rowCountInit"
                                                  id="formRowCountInit"
                                                  value={state.config.rowCountInit}
                                                  disabled={!state.checkedSingleInit && !state.checkedCompoundInit ? true : false}
                                                  onChange={handleConfigChange}
                                                />
                                              </Col>
                                              <Col sm="3">
                                                <Label for="formColumnCountInit">Column Count</Label>
                                                <Input
                                                  style={{ maxWidth: "100px" }}
                                                  type="number"
                                                  name="columnCountInit"
                                                  id="formColumnCountInit"
                                                  value={state.config.columnCountInit}
                                                  disabled={!state.checkedSingleInit && !state.checkedCompoundInit ? true : false}
                                                  onChange={handleConfigChange}
                                                />
                                              </Col>
                                              <Col sm="3">
                                                <Label for="formMaxFillInit">Max Fill</Label>
                                                <Input
                                                  style={{ maxWidth: "100px" }}
                                                  type="number"
                                                  name="maxFillInit"
                                                  id="formMaxFillInit"
                                                  value={state.config.maxFillInit}
                                                  disabled={!state.checkedSingleInit && !state.checkedCompoundInit ? true : false}
                                                  onChange={handleConfigChange}
                                                />
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col sm="12">
                    <FormGroup>
                      <Row>
                        <Col sm="12">
                          <Card>
                            <CardHeader>PCR TRAY</CardHeader>
                            <CardBody>
                              <Row>
                                <Col>
                                  <Label for="formLabelPcr">Label</Label>
                                  <Input type="text" name="labelPcr" id="formLabelPcr" value={state.config.labelPcr} onChange={handleConfigChange} />
                                </Col>
                                <Col>
                                  <Label for="formIdSuffixPcr">Id Suffix</Label>
                                  <Input type="text" name="idSuffixPcr" id="formIdSuffixPcr" value={state.config.idSuffixPcr} onChange={handleConfigChange} />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Label for="formStatusesPcr">Statuses (press enter to add another item)</Label>
                                  <Input
                                    type="textarea"
                                    name="statusesPcr"
                                    id="formStatusesPcr"
                                    value={state.config.statusesPcr}
                                    onChange={handlePcrStatusesChange}
                                  />
                                </Col>
                                <Col>
                                  <Label for="formPropertiesPcr">Properties (optional, press enter to add another item)</Label>
                                  <Input
                                    type="textarea"
                                    name="propertiesPcr"
                                    id="formPropertiesPcr"
                                    value={state.config.propertiesPcr}
                                    onChange={handlePcrPropertiesChange}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <FormGroup check>
                                    <Row style={{ marginTop: 30 }}>
                                      <Col sm="9" className="text-left">
                                        <Label for="formCategory">CATEGORY</Label>
                                        <Card>
                                          <CardBody>
                                            <Row>
                                              <Col sm="3">
                                                <FormGroup hidden={state.checkedSingleInit}>
                                                  <Label for="formCompoundMapping">Compound Mapping</Label>
                                                  <Label for="formCompoundMapping">(press enter to add)</Label>
                                                  <Input
                                                    style={{ maxWidth: "200px" }}
                                                    type="textarea"
                                                    name="compoundMapping"
                                                    id="formCompoundMapping"
                                                    value={state.config.compoundMapping}
                                                    disabled={
                                                      state.checkedSingleInit || (!state.checkedSingleInit && !state.checkedCompoundInit) ? true : false
                                                    }
                                                    onChange={handleCompMappingChange}
                                                  />
                                                </FormGroup>
                                              </Col>
                                              <Col sm="2">
                                                <Label for="formRowCountPcrInit">Row Count</Label>
                                                <Input
                                                  style={{ maxWidth: "100px" }}
                                                  type="number"
                                                  name="rowCountPcrInit"
                                                  id="formRowCountPcrInit"
                                                  value={state.config.rowCountPcrInit}
                                                  disabled={!state.checkedSingleInit && !state.checkedCompoundInit ? true : false}
                                                  onChange={handleConfigChange}
                                                />
                                              </Col>
                                              <Col sm="2">
                                                <Label for="formColumnCountInit">Column Count</Label>
                                                <Input
                                                  style={{ maxWidth: "100px" }}
                                                  type="number"
                                                  name="columnCountPcrInit"
                                                  id="formColumnCountInit"
                                                  value={state.config.columnCountPcrInit}
                                                  disabled={!state.checkedSingleInit && !state.checkedCompoundInit ? true : false}
                                                  onChange={handleConfigChange}
                                                />
                                              </Col>
                                              <Col sm="2">
                                                <Label for="formMaxFillPcrInit">Max Fill</Label>
                                                <Input
                                                  style={{ maxWidth: "100px" }}
                                                  type="number"
                                                  name="maxFillPcrInit"
                                                  id="formMaxFillPcrInit"
                                                  value={state.config.maxFillPcrInit}
                                                  disabled={!state.checkedSingleInit && !state.checkedCompoundInit ? true : false}
                                                  onChange={handleConfigChange}
                                                />
                                              </Col>
                                            </Row>
                                          </CardBody>
                                        </Card>
                                      </Col>
                                    </Row>
                                  </FormGroup>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </FormGroup>
          )}

          <FormGroup>
            <Row>
              <Col style={{ textAlign: "right" }}>
                <Button type="button" color="success" style={{ marginRight: "20px" }} onClick={(e) => saveForm(e)}>
                  {newTestType === true ? "Save" : "Update"}
                </Button>
                <Button
                  type="button"
                  outline
                  color="secondary"
                  onClick={() => {
                    cancel()
                  }}
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </FormGroup>

          {state.createNewRule || state.editingRule ? (
            <FormGroup>
              <Row>
                <Col sm={"12"}>
                  <Modal isOpen={state.createNewRule || state.editingRule} toggle={handleCloseRule} size={"md"}>
                    <ModalHeader>PCR Upload Rule (all fields are required)</ModalHeader>
                    <ModalBody>
                      <Row>
                        <Col>
                          <Label>Field Name</Label>
                          <Input
                            type="text"
                            name="fieldName"
                            id="fieldName"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          ></Input>
                          <small style={{ color: "red" }}>
                            <em>This is a case sensitive field.</em>
                          </small>
                        </Col>
                      </Row>
                      <br />
                      <Row>
                        <Col>
                          <Label>Operator</Label>
                          <Input
                            style={{ width: "250px" }}
                            type="select"
                            name="operator"
                            id="operator"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          >
                            {getOptions("ruleOperator").map((item) => (
                              <option key={item.key} id={item.key} value={item.key}>
                                {item.value || item.key}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col>
                          <Label>Value</Label>
                          <Input
                            type="number"
                            step=".01 "
                            name="ruleValue"
                            id="ruleValue"
                            onChange={(e) => {
                              handleChange(e)
                            }}
                          ></Input>
                        </Col>
                      </Row>
                    </ModalBody>
                    <ModalFooter>
                      <Button type="button" outline color="success" onClick={handleConfirmRule} disabled={state.isDisabled}>
                        Add Rule
                      </Button>
                      <Button type="button" outline color="primary" onClick={handleCloseRule}>
                        Close
                      </Button>
                    </ModalFooter>
                  </Modal>
                </Col>
              </Row>
            </FormGroup>
          ) : null}
        </Form>
        <br />
      </div>
    )
};

export default TestConfigForm;

