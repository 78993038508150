import React, { useEffect, useState } from "react";
import { usePatientDetailsTestsQuery, useTestDetailsQuery } from "api/getters";
import { Alert, Button, Label, Row, Col, Spinner, FormGroup } from "reactstrap";
import dayjs from "dayjs";
import { Link } from "react-router-dom"
import localizedFormat from "dayjs/plugin/localizedFormat";
import utc from "dayjs/plugin/utc";
import { makeApiDownloadCall } from "api/generic-api"

dayjs.extend(utc);
dayjs.extend(localizedFormat);

const CovidTestDetails = ({ testId }) => {
    const { data: testDetailsData, isLoading: isTestDetailsLoading, isError,
        error, } = useTestDetailsQuery({
            variables: {
                id: testId,

            },
            onSuccess: (successData) => {
                setSuccessMessage(successData.message)
            },

        });

    const [patientId, setPatientId] = useState("");
    const [downloadError, setDownloadError] = useState("")
    const [successMessage, setSuccessMessage] = useState("")


    const downloadReport = async () => {
        try {
            setDownloadError("")
            setDownloading(true)
            const response = await makeApiDownloadCall("get", "/api/document/test/:id.pdf", { id: testId }, "application/pdf", `${testId}.pdf`)
            if (!response?.ok) {
                setDownloadError("PDF not yet available")
            }
        } catch (error) {
            console.error(error)
        } finally {
            setDownloading(false)
        }
    }

    const { data: patientsData, isLoading: isPatientDetailsLoading } = usePatientDetailsTestsQuery({
        variables: { id: patientId },
        enabled: Boolean(patientId),
    });


    useEffect(() => {
        if (testDetailsData?.subjectId) {
            setPatientId(testDetailsData.subjectId);
        }
    }, [testDetailsData?.subjectId]);

    const isLoading = isTestDetailsLoading || isPatientDetailsLoading;
    const [downloading, setDownloading] = useState(false)

    return (
      <form>
        {downloadError && (
          <Alert color="warning" role="alert">
            {downloadError}
          </Alert>
        )}
        {successMessage && <Alert color="success">{successMessage}</Alert>}
        {isError && (
          <Alert color="warning" role="alert">
            {error?.response?.data?.message || error?.response?.data?.reason}
          </Alert>
        )}
        {isLoading ? (
          <Spinner type="grow" />
        ) : (
          <Row>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Test ID</Label>
              <input className="form-control" value={testId || ""} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">N3</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords?.all[0]?.n3 || ""} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Sample Id</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords.all[0]?.id || ""} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">RNASEP</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords.all[0]?.rnasep || ""} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <div className="mb-3">
                <Label htmlFor="formrow-text-Input">Patient ID</Label>
                <Link to={`/subject-info/${patientsData?.tests?.testRecords.all[0]?.subjectId}/personal`} className="text-secondary" target="_blank">
                  <input readOnly type="text" className="form-control cursor-pointer" value={patientsData?.tests?.testRecords.all[0]?.subjectId || ""} />
                </Link>
              </div>
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Attestation</Label>
              <input
                type="text"
                className="form-control"
                value={
                  patientsData?.tests?.testRecords?.all?.[0]?.attestation === true
                    ? "Yes"
                    : patientsData?.tests?.testRecords?.all?.[0]?.attestation === false
                    ? "No"
                    : ""
                }
                readOnly
                disabled
              />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Collected</Label>
              <input
                type="text"
                className="form-control"
                value={
                  patientsData?.tests?.testRecords?.all?.[0]?.sampleCollectionDateTime
                    ? dayjs(patientsData.tests.testRecords.all[0].sampleCollectionDateTime).format("MM/DD/YYYY ")
                    : ""
                }
                readOnly
                disabled
              />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Insured</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords?.all?.[0]?.patientInsured} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Accessioned</Label>
              <input
                type="text"
                className="form-control"
                value={
                  patientsData?.tests?.testRecords?.all?.[0]?.laboratoryReceivedDateTime
                    ? dayjs(patientsData.tests.testRecords.all[0].laboratoryReceivedDateTime).format("MM/DD/YYYY ")
                    : ""
                }
                readOnly
                disabled
              />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Insurance Group Id</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords.all[0]?.insuranceGroupId || ""} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Analysed</Label>
              <input
                type="text"
                className="form-control"
                value={
                  patientsData?.tests?.testRecords?.all?.[0]?.laboratoryCompletedDateTime
                    ? dayjs(patientsData.tests.testRecords.all[0].laboratoryCompletedDateTime).format("MM/DD/YYYY ")
                    : ""
                }
                readOnly
                disabled
              />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Insurance Company</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords.all[0]?.insuranceCompanyNam || ""} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">PCR Updated</Label>
              <input
                type="text"
                className="form-control"
                value={
                  patientsData?.tests?.testRecords?.all?.[0]?.pcrUpdatedDateTime
                    ? dayjs(patientsData.tests.testRecords.all[0].pcrUpdatedDateTime).format("MM/DD/YYYY ")
                    : ""
                }
                readOnly
                disabled
              />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Insurance Subscriber No</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords.all[0]?.insuranceSubscriberNo || ""} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Result</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords?.all?.[0]?.testResult?.toUpperCase()} readOnly disabled />
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">Reported</Label>
              <input
                type="text"
                className="form-control"
                value={
                  patientsData?.tests?.testRecords?.all?.[0]?.reportingDateTime
                    ? dayjs(patientsData.tests.testRecords.all[0].reportingDateTime).format("MM/DD/YYYY ")
                    : ""
                }
                readOnly
                disabled
              />
            </Col>
            <Col>
              <FormGroup check>
                <Label check>
                  <input type="radio" name="radio1" disabled checked={patientsData?.tests?.testRecords?.all?.[0]?.testingPurpose === "purposeSymptomatic"} />{" "}
                  Symptomatic
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <input type="radio" name="radio1" disabled checked={patientsData?.tests?.testRecords?.all?.[0]?.testingPurpose === "purposeExposed"} />{" "}
                  Exposed
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check>
                  <input
                    type="radio"
                    name="radio1"
                    checked={patientsData?.tests?.testRecords?.all?.[0]?.testingPurpose === "purposeTravel"}
                    readOnly
                    disabled
                  />{" "}
                  Travel
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check className="sub-option">
                  <input
                    type="radio"
                    name="radio1"
                    checked={patientsData?.tests?.testRecords?.all?.[0]?.testingPurpose === "purposeStateLongTermCare"}
                    readOnly
                    disabled
                  />{" "}
                  Weekly Testing Due to state mandate (Long-term care) Education / childcare
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check className="sub-option">
                  <input
                    type="radio"
                    name="radio1"
                    checked={patientsData?.tests?.testRecords?.all?.[0]?.testingPurpose === "purposeStateEducationOrChildcare"}
                    readOnly
                    disabled
                  />{" "}
                  Weekly Testing Due to state mandate (Education/Childcare)
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check className="sub-option">
                  <input
                    type="radio"
                    name="radio1"
                    checked={patientsData?.tests?.testRecords?.all?.[0]?.testingPurpose === "purposeStateEmployee"}
                    readOnly
                    disabled
                  />{" "}
                  Weekly Testing Due to state mandate (State Employee)
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check className="sub-option">
                  <input
                    type="radio"
                    name="radio1"
                    checked={patientsData?.tests?.testRecords?.all?.[0]?.testingPurpose === "purposeEmployee"}
                    readOnly
                    disabled
                  />{" "}
                  Testing due to other employer mandate, not associated with state mandate
                </Label>
              </FormGroup>
              <FormGroup check>
                <Label check className="sub-option">
                  <input type="radio" name="radio1" checked={patientsData?.tests?.testRecords?.all?.[0]?.testingPurpose === "purposeOther"} readOnly disabled />{" "}
                  Other (please state)
                </Label>
              </FormGroup>
            </Col>
            <Col className="mb-3" lg="6">
              <Label className="form-label">N1</Label>
              <input type="text" className="form-control" value={patientsData?.tests?.testRecords.all[0]?.n1 || ""} disabled readOnly />
            </Col>
            <Col className="mb-3 mt-2" lg="6">
              <Label className="form-label"></Label>

              <input placeholder="Reason..." type="text" className="form-control" disabled readOnly />
            </Col>
            <Col xs="12" className="d-flex flex-wrap gap-2 align-items-end justify-content-end">
              <Button color="primary" disabled={downloading} onClick={downloadReport}>
                {downloading && <Spinner size="sm" />} Download Report
              </Button>
            </Col>
          </Row>
        )}

        {!isLoading && (!testDetailsData || !patientsData) && <p>No records found.</p>}
      </form>
    )
};

export default CovidTestDetails;
