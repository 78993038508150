import React from "react"
import Breadcrumb from "./custom/Breadcrumb"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { usePreviousTableQuery, useQuery } from "hooks/query"
import ExtrasConfigurationDetails from "./custom/ExtrasConfigurationDetails"
import { useParams } from "react-router"

function ExtrasConfigurationViewInfo(props) {
    const permission = props && props.applicationState.authentication.user.permission
    const { id } = useParams()

    const previousTableQuery = usePreviousTableQuery("/extras-configuration")

    const breadcrumbItems = [
        {
            label: "Extras Configuration",
            link: `/extras-configuration?${previousTableQuery}`,
        },
        {
            label: id,
            link: `/extras-configuration?id=${id}`,
        },
    ]

    return (
        <div className="wide-container">
            <Breadcrumb title={id} breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Extras Configuration Details</CardTitle>
                </CardHeader>
                <CardBody>
                    <ExtrasConfigurationDetails id={id} permission={permission} />
                </CardBody>
            </Card>
        </div>
    )
}

export default ExtrasConfigurationViewInfo
