import React, { useState, useEffect, forwardRef, useMemo } from "react"
import { get, objectsIdentical, curry } from "../utility/client-utility"
import GeneralForm from "./general-form-requisition-new"
import { makeApiCall, makeApiDownloadCall } from "../api/generic-api"
import { useAlert } from "react-alert"
import { useParams } from "react-router"
import { flatten } from "../utility/uri-utility"
import { omit, pick } from "lodash"
import moment from "moment/moment"
import { useRequisitionsQuery } from "api/getters"
import netestRequisitionFullDefinition from "configuration/netest-requisition-full-definition"
import lookups from "configuration/lookups"
import Breadcrumb from "./custom/Breadcrumb"
import { usePreviousTableQuery, useQuery } from "hooks/query"

const RequisitionNetestInfo = forwardRef((props, ref) => {
  const params = useParams()
  const alert = useAlert()

  const loadData = makeApiCall
  const structure = netestRequisitionFullDefinition
  const otherDefinition = {}
  const products = []
  const defaultPageSize = 20
  const pageSize = get(() => structure.pageSize, defaultPageSize)
  const tableStructure = get(() => structure.table, {})
  const formStructure = get(() => structure.form, {})
  const tableKey = get(() => tableStructure.key)
  const tableKeyArray = tableKey ? [tableKey] : []
  const tableColumns = tableStructure.columns ? tableStructure.columns : []
  const tableColumnFields = tableColumns.filter((column) => column.field)
  const tableColumnFieldNames = tableColumnFields.map((column) => column.field)
  const tableFields = tableKeyArray.concat(tableColumnFieldNames)
  const tableFieldsWithExtras = tableStructure.extraFields ? tableFields.concat(tableStructure.extraFields) : tableFields
  const getFormFieldNames = (form) => {
    const fields = form.sections?.reduce((acc, section) => {
      return acc.concat(section.fields)
    }, [])
    return fields?.map((field) => (props.formDataField ? props.formDataField + "." + field.name : field.name))
  }
  const formFields = getFormFieldNames(formStructure)
  const fields = tableFieldsWithExtras.concat(formFields)

  const dataQuery = useMemo(
    () => ({
      ...params,
      id: params?.id?.replace(/\_/g, "."),
    }),
    [params],
  )

  const [sort, setSort] = useState(() => {
    return tableStructure.defaultSort
  })
  const [data, setData] = useState(null)
  const [id, setId] = useState(() => {
    return dataQuery?.id || null
  })
  const [sampleId, setSampleId] = useState(() => {
    return dataQuery?.sampleId || null
  })
  const [selector, setSelector] = useState(() => {
    if (dataQuery?.selector?.data) {
      const selectorWithData = pick(dataQuery?.selector, "data")
      const selectorWithoutData = omit(dataQuery?.selector, "data")
      if (selectorWithData) {
        dataQuery.selector = { ...selectorWithoutData, ...flatten(selectorWithData, 1) }
      }
    }

    return dataQuery?.selector || null
  })

  const [currentRecord, setCurrentRecord] = useState(() => {
    return null
  })

  const [previousQuery, setPreviousQuery] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const validateFields = (record, rules) => {
    const resultFields = record.results.testResults
    let rulesFields = []
    let fileFields = []

    rules.forEach((item, index) => {
      rulesFields.push(item.fieldName)
    })

    for (let i = 0; i < resultFields.length; i++) {
      fileFields = Object.keys(resultFields[i].result)
      break
    }

    const allExist = rulesFields.every((val) => fileFields.includes(val))
    return allExist
  }
  const isInDateRange = (bloodCollectionDate) => {
    const dateNow = moment()
    const dateToday = dateNow.locale("en-US").utc()
    const currentDate = moment(dateToday).endOf("day").format("YYYY-MM-DD")

    const forSevenDays = moment(new Date().toLocaleDateString("en-US", { timeZone: "UTC" }))
    const sevenDaysAgo = forSevenDays.subtract(7, "days")
    const previousDay = moment(sevenDaysAgo).format("YYYY-MM-DD")

    return moment(bloodCollectionDate).isSameOrAfter(previousDay, "day") && moment(bloodCollectionDate).isSameOrBefore(currentDate, "day")
  }

  const acceptSample = async (acceptActions) => {
    const verb = acceptActions.verb
    const uri = acceptActions.uri
    const parameters = acceptActions.parameters

    const result = await makeApiCall(verb, uri, parameters)
    if (result.ok) {
      alert.show("Action has been successfully executed", {
        type: "success",
      })
      window.scrollTo(0, document.body.scrollHeight)
    } else {
      let error
      if (result.statusCode !== null) {
        error = result.error
        const detail = `Details: code ${result.statusCode}; ${error.message || error}`
        alert.show(detail, {
          type: "error",
        })
        window.scrollTo(0, document.body.scrollHeight)
      }
    }
  }

  const rejectSample = async (rejectActions) => {
    const verb = rejectActions.verb
    const uri = rejectActions.uri
    const parameters = rejectActions.parameters

    const result = await makeApiCall(verb, uri, parameters)
    if (result.ok) {
      alert.show("Action has been successfully executed", {
        type: "success",
      })
      window.scrollTo(0, document.body.scrollHeight)
    } else {
      let error
      if (result.statusCode !== null) {
        error = result.error
        const detail = `Details: code ${result.statusCode}; ${error.message || error}`
        alert.show(detail, {
          type: "error",
        })
        window.scrollTo(0, document.body.scrollHeight)
      }
    }
    setWarningModal(false)
  }

  const genericAction = (details, record) => {
    if (!record) {
      console.warn("No record selected for action")
      return
    }
    if (!details) {
      console.error("No details selected for action")
      return
    }
    if (!structure?.endpoints) {
      console.error("No endpoints defined for this structure")
      return
    }
    if (!details.endpoint) {
      console.error("No endpoint defined for this action")
      return
    }
    const endpoint = structure?.endpoints?.[details.endpoint]
    if (!endpoint) {
      console.error("No endpoint found for this action endpoint", details.endpoint)
    }
    const verb = endpoint.verb || details.endpoint
    const baseUri = endpoint.uri || endpoint
    if (!verb || !baseUri) {
      console.error("Missing verb/uri for action", details)
      return
    }
    const uri = details.idToken ? baseUri.replace(details.idToken, encodeURIComponent(record[tableKey] || record.id)) : baseUri
    const parameters = details.data || record
    if (details.download) {
      ;(async () => {
        try {
          const data = await makeApiDownloadCall(verb, uri, parameters, endpoint.type, endpoint.filename)
        } catch (err) {
          console.error("Error in download action", err, details)
        }
      })()
    } else {
      ;(async () => {
        try {
          let result
          if (details.data.status === "accepted") {
            const bloodCollectionDate = record?.data?.bloodCollectionDate
            const isAcceptedDate = isInDateRange(bloodCollectionDate)

            // if (!isAcceptedDate) {
            //     setWarningModal(true);
            //     // setAcceptActions({ verb: verb, uri: uri, parameters: parameters });
            //     const acceptValues = { verb: verb, uri: uri, parameters: parameters }
            //     await acceptSample(acceptValues)
            // } else {
            const acceptValues = { verb: verb, uri: uri, parameters: parameters }
            await acceptSample(acceptValues)
            // }
          } else if (details.data.status === "rejected") {
            const data = { verb: verb, uri: uri, parameters: parameters }
            await rejectSample(data)
          }
        } catch (err) {
          console.error("Error in action", err, details)
        }
        refresh()
      })()
    }
  }

  const loadCurrentData = async () => {
    setIsLoading(true)
    // immediately invoked function expression IIFE
    const fullSelector = { ...selector, ...props.fixedSelector }
    const paging = objectsIdentical(fullSelector, previousQuery && previousQuery.selector) && objectsIdentical(sort, previousQuery && previousQuery.sort)
    const page = 1
    const genericQuery = {
      fields: fields,
      selector: fullSelector,
      sort: sort,
      page: page,
      pageSize: pageSize,
    }

    if (sampleId) {
      genericQuery.selector = {
        "data.sampleId": sampleId,
      }
    }

    let finalQuery = genericQuery
    const action = structure?.endpoints?.search.action
    if (action) {
      finalQuery = { action, ...finalQuery }
    }

    setPreviousQuery(finalQuery)
    const data = await loadData(
      get(() => structure?.endpoints?.search.verb),
      get(() => structure?.endpoints?.search.uri),
      finalQuery,
    )

    if (dataQuery?.id && data) {
      const record = data?.body?.filter((x) => x.id == dataQuery.id)[0]
      if (record) {
        setCurrentRecord(record)
      }
    }

    // props.setPageActual(!data.ok ? 0 : page * 1)
    // props.setLastPage((data.ok && data.body instanceof Array && data.body.length < pageSize) || !data.ok ? pageActual : 0)
    setData(data)

    setIsLoading(false)
  }

  const viewInnerForm = (record) => {
    if (record?.data) {
      record.data.id = record?.id
    }
    setCurrentInnerRecord(record)
    setDisplayMode("inner-form")
    if (props.change) {
      props.change()
    }
    setCheckedInput([])
  }

  const closeForm = () => {
    // setDisplayMode("table")
    // setId(null)
    // if (props.change) {
    //   props.change()
    // }
  }

  const formRowActions =
    props && props.customFormRowActions && props.customFormRowActions instanceof Array && props.customFormRowActions.length > 0
      ? props &&
        props.customFormRowActions &&
        props.customFormRowActions.map((customRowAction) => {
          if (customRowAction.name == "view") {
            customRowAction.action = viewInnerForm
          } else if (customRowAction.name !== "showPcrDetail") {
            const curriedAction = curry((details, record) => genericAction(details, record))
            customRowAction.action = curriedAction(customRowAction.details)
          }

          return customRowAction
        })
      : null

  const { data: requisitionsData } = useRequisitionsQuery({
    variables: {
      selector: {
        _id: {
          $eq: dataQuery?.id,
        },
      },
      pageSize: 1,
    },
  })

  const update = async (formState) => {
    const result = await makeApiCall("PUT", `/api/requisition/${encodeURIComponent(formState.id || dataQuery.id)}`, formState)
    if (result.ok) {
      alert.show("Form has been successfully updated", {
        type: "success",
      })

      window.scrollTo(0, document.body.scrollHeight)
    } else {
      const detail = `Details: code ${result.statusCode}; ${result.error}`
      alert.show(detail, {
        type: "error",
      })
      window.scrollTo(0, document.body.scrollHeight)
    }

    await loadCurrentData()
    return result
  }

  const change = () => {
    // setAlertState(null);
  }

  useEffect(() => {
    if (requisitionsData) {
      setCurrentRecord(requisitionsData?.[0])
    }
  }, [requisitionsData])

  const previousQueryString = usePreviousTableQuery("/requisition-netest")

  const breadcrumbItems = [
    {
      label: "Cancer Sample Requisition Backlog",
      link: `/requisition-netest?${previousQueryString}`,
    },
    {
      label: dataQuery?.id,
      link: `/requisition-netest/${dataQuery?.id}`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="" breadcrumbItems={breadcrumbItems} />
      <GeneralForm
        style={{ marginTop: "30px" }}
        formDefinition={formStructure}
        otherDefinition={otherDefinition}
        products={products}
        lookups={lookups}
        formState={currentRecord?.data}
        close={closeForm}
        update={update}
        change={change}
        // findDuplicate={props.findDuplicate}
        // existingSubjects={props.existingSubjects}
        // setExistingSubjects={props.setExistingSubjects}
        loadCurrentData={loadCurrentData}
        setAlertState={props.setAlertState}
        formRowActions={formRowActions}
        currentRecord={currentRecord?.data}
        isLoading={isLoading}
        sampleId={sampleId}
        {...props}
      />
    </div>
  )
})

RequisitionNetestInfo.displayName = "RequisitionNetestInfo"

export default RequisitionNetestInfo
