import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import ExtrasConfigurationForm from "../components/custom/ExtrasConfigurationForm"
import { usePreviousTableQuery } from "hooks/query"

function extrasConfigurationViewForm(props) {
    const permission = props && props.applicationState.authentication.user.permission;
    const previousQuery = usePreviousTableQuery("/extras-configuration")

    const breadcrumbItems = [
        {
            label: "Extras",
            link: `/extras-configuration?${previousQuery}`,
        },
        {
            label: "Extras Configuration Form",
            link: `/extras-configuration-form`,
        },

    ]

    return (
        <div className="wide-container">
            <Breadcrumb title="Extras " breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Extras Configuration Form</CardTitle>
                </CardHeader>
                <CardBody>
                    <ExtrasConfigurationForm permission={permission} />
                </CardBody>
            </Card>
        </div>
    )
}

export default extrasConfigurationViewForm
