import React, { useState } from 'react';

import lookups from "../configuration/lookups";
import DataWow from "./data-wow";
import { makeApiCall } from "../api/generic-api";
import testFullDefinition from "../configuration/test-full-definition";
import { Col, Row } from "reactstrap";

function TestView(props) {
    const [isSubjectInfoPage, setIsSubjectInfoPage] = useState(window.location.pathname.includes('subject-info') || window.location.pathname.includes('order-info'));
    const structure = testFullDefinition;

    // covid tests
    // const fixedSelector = {
    //     'data.testType': 'covid',
    //     "$or": [
    //         {
    //             "status": {
    //                 "$exists": false
    //             }
    //         },
    //         {
    //             status: {
    //                 "$ne": "done"
    //             }
    //         }
    //     ]
    // };

    const fixedSelector = {
        "testType": {
            "$in": ["covid", "covid-thermo-fisher"]
        },
    };

    const userSelector = {
        heading: {
            label: 'Filter',
            style: { display: 'inline-block', fontWeight: 800 }
        },
        lookups: lookups,
        controlBlockStyle: { display: 'inline-block' },
        controls: [
            {
                label: 'Test Id',
                name: '_id',
                type: 'text',
                style: { maxWidth: '170px' },
                groupStyle: { display: 'inline-block', marginLeft: '20px' }
            },
            {
                label: 'Patient Id',
                name: 'subjectId',
                type: 'text',
                style: { maxWidth: '170px' },
                groupStyle: { display: 'inline-block', marginLeft: '20px' }
            },
            /*{
                label: 'Group',
                name: 'prefix',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },
            {
                label: 'First Name',
                name: 'firstName',
                type: 'text',
                style: {maxWidth:'170px'},
                groupStyle: {display: 'inline-block', marginLeft: '20px'}
            },*/

        ]
    };

    return (
        <div>
            <Row style={{ marginTop: '30px' }}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>Covid Tests</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>
            <DataWow
                className={'wide-container'}
                structure={structure}
                userSelector={userSelector}
                loadData={makeApiCall}
                lookups={lookups}
                fixedSelector={fixedSelector}
                isSubjectInfoPage={isSubjectInfoPage}
                {...props} />
        </div>
    );
}

export default TestView;
