import React, {useEffect, useState} from 'react';
import {
    Alert,
    Button, Col, Container, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from 'reactstrap';
import classnames from 'classnames';
import ReactLoading from "react-loading";
import { get } from '../../utility/client-utility';
import {makeApiCall} from "../../api/generic-api";
import {useAlert} from "react-alert";

function DataControlsDilution(props) {
    const editForm = props && props.editForm;
    const currentData = get(() => props.dataState || {}); // current record
    const isDisableForm = props && props.isDisableForm;
    const sampleId = props && props.sampleId;

    const alert = useAlert();

    const currentDataState = get(() => props.currentDataState || {});  // cdna data
    console.log('formStatey', currentDataState);
    const [ dataState, setDataState ] = useState(currentDataState);
    const [ tempDataState, setTempDataState ] = useState(currentDataState);
    
    const [ dilutions, setDilutions ] = useState(get(() => (dataState.dilutions || [])));
    const [ tempDilutions, setTempDilutions ] = useState(get(() => (dataState.dilutions || [])));
    
    
    
    const [ dilutionId, setDilutionId ] = useState(null);
    // let dilutionId;

    const [ locationState, setLocationState ] = useState(null);

    // const isolateId = dataState.id;

    useEffect(() => {
        if (currentData.location) {
            setLocationState(currentData.location)
        }
    }, [currentData]);

    useEffect(() => {
        if (currentData.id) {
            setDilutionId(currentData.id)
        }
    }, [currentData]);

    const generateDilutionId = (isolateId) => {
        let generatedId;
        const dilutionsData = dilutions;
        let inc = 0;
        console.log('dilutions', dilutions);

        inc = Number(dilutionsData.length) + 1;
        console.log('dilutions inc', inc);

        generatedId = isolateId + 'D' + inc;

        // dilutionId = generatedId;
        setDilutionId(generatedId);
        return generatedId;
    };

    const createDilution = async (isolateId) => {
        setLocationState(null);
        // // setIsNewCreation(true);
        if (!editForm) {
            props.setCurrentRecord({});
        }
        const id = await generateDilutionId(isolateId);

        let newData;

        console.log('dilutions generate', id);
        newData = { id: id }

        setDilutions([
            ...dilutions,
            Object.assign({}, newData)
        ])
        props.setViewModal(true);
    };

    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        let newDilutionState = {};

        const value = target.value;
        console.log('data control field.name', name, value);

        if (name === 'location') {
            setLocationState(value);
        }

        let newState;
        if (dilutions && dilutions.length > 0) {
            newState = dilutions.map(obj => {
                if (obj.id === dilutionId) {
                    console.log('data control - object id', obj.id, dilutionId);
                    return {...obj, [name]: value };
                }
                return obj;
            });
            setTempDilutions(newState);
        }
        
        setTempDataState(prev => ({
            ...prev,
            sampleId: props.sampleId,
            dilutions: newState
        }));
    
        props.setTransaction( {
            data : {
                id: dilutionId,
                field: name,
                value: value,
                state: null,
            },
            action: {
                descShort: 'update-dilution', desc: 'Update Dilution'
            }
        });
    };

    const close = () => {
        props.setViewModal(false);
    };


    const handleCloseModal = () => {
        const filteredDilutions = dilutions.filter(x => !!x.location);
        setDilutions(filteredDilutions);
        close();
    };

    const submit = async () => {
        setDataState(tempDataState)
        setDilutions(tempDilutions)
        
        const currentFormState = props.currentFormState;
        let cDna = currentFormState.cDna;
        const index = cDna.findIndex(x => x.id == tempDataState.id);
        
        if (index >= 0) {
            currentFormState.cDna[index] = tempDataState
        } else {
            currentFormState.cDna = cDna.concat(tempDataState)
        }
    
        props.setCurrentDataState(tempDataState)
        props.setCurrentFormState(currentFormState)
        
        
        close();
        
        
        // const result = await makeApiCall('patch', '/api/netest/sample/', { data: { id: sampleId, cDna: [ dataState ] }});
        // if (result.ok) {
        //     alert.show('Successfully created Dilution', {
        //         type: 'success'
        //     });
        //    
        //     console.log('sampleId', sampleId);
        //     console.log('props.currentDataState', props.formState);
        //     const cDna = props.formState.cDna;
        //     const existingSampleId = cDna.find(dna => dna.id == dataState.id);
        //     // if (existingSampleId) {
        //     //     props.loadCurrentData();
        //     // } else {
        //     //     close();
        //     // }
        //    
        //    
        //     // window.scrollTo(0, document.body.scrollHeight);
        // } else {
        //     const detail = `Details: code ${result.statusCode}; ${result.searchStatus}`;
        //     alert.show(detail, {
        //         type: 'error'
        //     });
        //     // window.scrollTo(0, document.body.scrollHeight);
        // }
        //
        // return result;
    };

    const handleConfirmDilution = async () => {
        if (dilutions && dilutions.length > 0) {
            await submit();
            // dilutions.map(async (data) => {
            //     if (data.id === dilutionId && !data.location) {
            //         alert.show('Please enter location', {
            //             type: 'error'
            //         });
            //     } else {
            //         await submit();
            //         // props.close();
            //
            //     }
            // });
        }
    };

    useEffect(() => {
        
    
        // console.log('currentFormState', currentFormState);
        
        // console.log('props.currentFormState', props.currentFormState);
        // console.warn('props.state', props.state);
        // console.warn('dataState', dataState);
        // console.log('currentDataState', currentDataState);
        // console.log('dilution datastates', dataState);
        // const currentDataState = props.currentDataState;
        // const cDna = currentDataState.cDna;
        //
        // console.warn('currentDataState', currentDataState);
        // console.warn('cDna', cDna);
        // const exist = cDna.find(x => x.id == dataState.id);
        // console.log('exist', exist);
        // if (exist) {
        //     currentDataState.cDna  = cDna.find((x, i) => {
        //         if (x.id == dataState.id) {
        //             cDna[i] = dataState
        //             return true; // stop searching
        //         }
        //     });
        // }
        //
        // else {
        //     currentDataState.cDna = cDna.concat(dataState);
        // }
        console.warn('formStatex', currentDataState);
        // props.setState(currentDataState);
    }, [ dataState ]);


    // useEffect(() => {
    //     if (!!currentDataState) {
    //         setDataState(currentDataState)
    //     }
    // }, [currentDataState])

    return (
      <div className={props.className} style={props.style}>
        <div>
          {props.showAddButton && (
            <>
              <Button outline color="primary" onClick={() => createDilution(dataState.id)} disabled={isDisableForm}>
                <span className="fa fa-plus"></span> Create Dilution
              </Button>
              {/*<Button outline color="primary"*/}
              {/*        onClick={props.refresh}*/}
              {/*        style={{marginRight:'20px'}}*/}
              {/*>*/}
              {/*    <span className="fa fa-refresh"></span>*/}
              {/*</Button>*/}
            </>
          )}
        </div>
        {props.viewModal === true ? (
          <>
            <Modal isOpen={props.viewModal} toggle={handleCloseModal} size="md">
              <ModalHeader>Dilution Details</ModalHeader>
              <ModalBody>
                <Label>Id</Label>
                <Input
                  type="text"
                  name="dilutionId"
                  value={dilutionId}
                  disabled={true}
                  onChange={(e) => {
                    e.persist()
                    handleChange(e)
                  }}
                />
                <br />
                <Label>Location</Label>
                <Input
                  type="text"
                  name="location"
                  value={locationState}
                  onChange={(e) => {
                    e.preventDefault()
                    handleChange(e)
                  }}
                  disabled={isDisableForm}
                />
              </ModalBody>

              <ModalFooter>
                <Button type="button" outline color="danger" onClick={handleConfirmDilution} disabled={isDisableForm}>
                  Confirm
                </Button>
                <Button type="button" outline color="primary" onClick={handleCloseModal}>
                  Close
                </Button>
              </ModalFooter>
            </Modal>
          </>
        ) : null}
      </div>
    )
}

export default DataControlsDilution;
