import React from "react"
import { Card, CardBody } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import { AccessionBacklogTable } from "./tables/AccessionBacklogTable"

function AccessionViewNew(props) {
  const breadcrumbItems = [
    {
      label: "Accession Backlog",
      link: `/accession-view`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Accession Backlog" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <AccessionBacklogTable allowPageSize allowPaginate isGlobalFilter {...props} />
        </CardBody>
      </Card>
    </div>
  )
}

export default AccessionViewNew
