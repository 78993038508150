import React, { useEffect, useRef, useState } from 'react';
import { makeApiCall } from "../../api/generic-api";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Collapse,
	FormGroup,
	Input,
	InputGroup,
	Label, Modal, ModalBody, ModalFooter, ModalHeader,
	Row,
} from "reactstrap";
import ReactLoading from "react-loading";
import { stringQueryToData } from "../../utility/uri-utility";
import { useAlert } from "react-alert";
import { camelCase } from "lodash";
import {createHash} from "crypto";

const RequisitionsConfigurationQuestionsForm = props => {
	const urlParams = new URLSearchParams(window.location.search);
	const urlParamsString = decodeURIComponent(urlParams.toString());
	const dataQuery = stringQueryToData(urlParamsString);;
	
	const { isEditForm, isDisableForm } = props;
	const [ sections, setSections ] = useState([]);
	const [ allSections, setAllSections ] = useState([]);
	const [ loadingSections, setLoadingSections ] = useState(false);
	
	const [sectionCollapse, setSectionCollapse] = useState(0);
	const [ fieldCollapse, setFieldCollapse ] = useState(0);
	
	const [ showConfirmModal, setShowConfirmModal ] = useState(false);
	const [ confirmModalType, setConfirmModalType ] = useState(null);
	const [ selectedSectionIndex, setSelectedSectionIndex ] = useState(null);
	const [ selectedFieldIndex, setSelectedFieldIndex ] = useState(null);
	const inputRef = useRef();
	const alert = useAlert();
	
	const [ hasSectionUpdates, setHasSectionUpdates ] = useState(false);
	
	useEffect(() => {
		if (!sections.length) {
			props.setQuestionForms([]);
			return;
		}
		
		const merged = allSections?.length ? [...allSections]: [];
		sections.forEach(update => {
			const index = merged.findIndex(section => section.id === update.id);
			if (index > -1) {
				merged[index] = update;
			} else {
				merged.push(update);
			}
		});
		
		props.setQuestionForms(merged)
		
	}, [ sections ]);
	
	useEffect(() => {
		setSectionCollapse(sections.length - 1)
	}, [ hasSectionUpdates ]);
	
	
	useEffect(() => {
		if (props.formState.testGroup) {
			const query = {
				testGroup:  props.formState?.testGroup,
				// testType: props.formState?.testType
			}
			
			// if (query.testGroup || query.testType) {
			// 	getRequisitionsConfiguration(query)
			// }
			
			if (query.testGroup) {
				getRequisitionsConfiguration(query)
			}
		}
		
	}, [props.formState.testGroup]);
	
	useEffect(() => {
		setLoadingSections(true);
		let activeSections = [];
		if (props.formState.testType) {
			activeSections = allSections?.filter?.((x) => x.productId == props.formState.testType)
		} else {
			activeSections = allSections?.filter?.((x) => x.productId == null)
		}
		
		setSections(activeSections || [])
		setLoadingSections(false);
		setHasSectionUpdates(!hasSectionUpdates)
	}, [ props.formState.testType ]);
	
	
	const getRequisitionsConfiguration = async (query) => {
		setLoadingSections(true);
		
		const result = await makeApiCall('get', `/api/requisitions-configuration`, {
			selector: _.omitBy(query, (v) => !v)
		});
		
		setAllSections(result.body?.length && result?.body[0]?.sections);
		
		let activeSections = [];
		activeSections = result.body?.length && result?.body[0]?.sections;
		if (activeSections && activeSections.length) {
			activeSections = activeSections?.filter(x => x.productId == null)
		}
		
		setSections(activeSections || [])

		
		setLoadingSections(false);
		setHasSectionUpdates(!hasSectionUpdates)
	};
	
	const toggleSection = (e) => {
		const event = e.target.dataset.event;
		setSectionCollapse(Number(event));
	};
	
	const toggleField = (e) => {
		const event = e.target.dataset.event;
		setFieldCollapse(Number(event));
	}
	
	const handleAddField = (sectionIndex) => {
		const fieldStructure = {
			label: null,
			required: "false",
			type: "text",
			maxLength: null,
			options: [],
		}
		
		const updatedSections = [...sections];
		const errors = {
			sections: [],
			fields: []
		};
		
		updatedSections[sectionIndex]['fields'] .map((field, fieldIndex) => {
			if (!field.label || field.label == '') {
				errors.fields.push(`Section ${sectionIndex + 1}(Field  ${fieldIndex + 1})`)
			}
		});
		
		if (errors.sections.length || errors.fields.length) {
			errors.sections.length && alert.show('Invalid values: ' + errors.sections.toString(), {
				type: 'error'
			})
			
			errors.fields.length && alert.show('Invalid values: ' + errors.fields.toString(), {
				type: 'error'
			})
			
			return;
		}
		
		updatedSections[sectionIndex]['fields'] = [...updatedSections[sectionIndex]['fields'], fieldStructure];
		setSections(updatedSections);
		setHasSectionUpdates(!hasSectionUpdates)
	}
	
	const handleAddSection = () => {
		const errors = {
			sections: [],
			fields: []
		};
		
		sections.map((section, sectionIndex) => {
			if (!section.label || section.label == '') {
				errors.sections.push(`Section ${sectionIndex + 1}`)
					
			}
			
			section.fields.map((field, fieldIndex) => {
				if (!field.label || field.label == '') {
					errors.fields.push(`Section ${sectionIndex + 1} (Field  ${fieldIndex + 1})`)
				}
			});
		});
		
		
		if (errors.sections.length || errors.fields.length) {
			errors.sections.length && alert.show('Invalid values: ' + errors.sections.toString(), {
				type: 'error'
			})
			
			errors.fields.length && alert.show('Invalid values: ' + errors.fields.toString(), {
				type: 'error'
			})
			
			return;
		}
		
		
		const sectionStructure = {
			label: null,
			columns: null,
			fields: [{
				label: null,
				required: "false",
				type: "text",
				maxLength: null,
				options: [],
			}],
			productId: props.formState.testType || null,
			id: createHash('sha1').update(Math.random().toString()).digest('hex')
		}
		
		const updatedSections = [...sections, sectionStructure];
		setSections(updatedSections);
		setHasSectionUpdates(!hasSectionUpdates)
	}
	
	const handleFieldChange = (e, sectionIndex, fieldIndex) => {
		const value = e.target.value;
		const name = e.target.name;
		const newSections = [...sections];
		
		if (fieldIndex != null) {
			newSections[sectionIndex]['fields'][fieldIndex][name] = value;
		} else {
			newSections[sectionIndex][name] = value;
		}
		setSections(newSections);
	}
	
	const handleRemoveField = () => {
		const updatedSections = [...sections];
		updatedSections[selectedSectionIndex]['fields'].splice(selectedFieldIndex, 1);
		
		setSections(updatedSections);
		setShowConfirmModal(false);
		setHasSectionUpdates(!hasSectionUpdates)
	}
	
	const handleRemoveSection = () => {
		const updatedSections = [...sections];
		updatedSections.splice(selectedSectionIndex, 1);
		setSections(updatedSections);
		setShowConfirmModal(false);
		setHasSectionUpdates(!hasSectionUpdates)
	}
	
	const handleAddOptionField = (field, sectionIndex, fieldIndex) => {
		const option = inputRef.current?.value;
		if (!option) return;
		const updatedField = { ...field };
		updatedField['options'] = [...updatedField['options'], option];
		
		const source = {
			kind: "direct",
			data: updatedField['options'].map(option => {
				if (option) {
					return {
						key: camelCase(option),
						value: option
					}
				}
			}),
		}
		
		updatedField['source'] = source;
		
		const updatedSections = [...sections];
		updatedSections[sectionIndex]['fields'][fieldIndex] = updatedField;
		setSections(updatedSections);
		inputRef.current.value = null;
	}
	
	const handleRemoveOptionField = (field, optionIndex, sectionIndex, fieldIndex) => {
		const updatedField = { ...field };
		updatedField['options'].splice(optionIndex, 1);
		
		const updatedSections = [...sections];
		updatedSections[sectionIndex]['fields'][fieldIndex] = updatedField;
		setSections(updatedSections);
	}
	
	return (
		<Col lg={12}>
			<Modal isOpen={showConfirmModal} toggle={() => setShowConfirmModal(false)} size="lg">
				<ModalHeader>Remove {confirmModalType == 'delete-field'? 'Field': 'Section'}</ModalHeader>
				<ModalBody>
					Are you sure you want to remove this {confirmModalType == 'delete-field'? 'field': 'section'}?
				</ModalBody>
				<ModalFooter>
					<Button type="button" outline color="danger" onClick={() => confirmModalType == 'delete-field'? handleRemoveField(): handleRemoveSection()}> OK</Button>
					<Button type="button" outline color="primary" onClick={() => setShowConfirmModal(false)}>Close</Button>
				</ModalFooter>
			</Modal>
			
			{ loadingSections &&
				<center className="mt-5">
					<ReactLoading type='spin' color='red' height={667} width={100} />
				</center>
			}
			
			
			{ !loadingSections && sections && sections.map((section, sectionIndex) => {
				return (
          <Row key={sectionIndex}>
            <Col lg={12}>
              <Card style={{ maxWidth: "auto" }} key={sectionIndex}>
                <CardHeader className="section-header" style={{ margin: "0px" }} onClick={(e) => toggleSection(e)} data-event={sectionIndex}>
                  Section {sectionIndex + 1} {section.label ? " - " + section.label : null}
                  {sectionCollapse === sectionIndex ? (
                    <span style={{ float: "right", margin: "5px" }} className="fa fa-minus" />
                  ) : (
                    <span style={{ float: "right", margin: "5px" }} className="fa fa-plus" />
                  )}
                </CardHeader>
                <Collapse isOpen={sectionCollapse === sectionIndex}>
                  <CardBody>
                    <Row>
                      <Col lg={6}>
                        <FormGroup>
                          <Label for="label">Section Label*</Label>
                          <Input
                            disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                            name="label"
                            value={section.label}
                            required={true}
                            onChange={(e) => handleFieldChange(e, sectionIndex, null)}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup>
                          <Label for="columns">Section Columns</Label>
                          <Input
                            disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                            name="columns"
                            value={section.columns}
                            onChange={(e) => handleFieldChange(e, sectionIndex, null)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    {section.fields &&
                      section.fields.map((field, fieldIndex) => {
                        return (
                          <div key={fieldIndex}>
                            <Row>
                              <Col lg={12}>
                                <Card style={{ maxWidth: "auto" }} key={fieldIndex}>
                                  <CardHeader className="section-header" style={{ margin: "0px" }} onClick={(e) => toggleField(e)} data-event={fieldIndex}>
                                    Field {fieldIndex + 1} {field.label ? " - " + field.label : null}
                                    {fieldCollapse === fieldIndex ? (
                                      <span style={{ float: "right", margin: "5px" }} className="fa fa-minus" />
                                    ) : (
                                      <span style={{ float: "right", margin: "5px" }} className="fa fa-plus" />
                                    )}
                                  </CardHeader>
                                  <Collapse isOpen={fieldCollapse === fieldIndex}>
                                    <CardBody>
                                      <Row>
                                        <Col lg={6}>
                                          <FormGroup>
                                            <Label for="label">Field Label*</Label>
                                            <Input
                                              disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                              name="label"
                                              value={field.label}
                                              required={true}
                                              onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                            />
                                          </FormGroup>

                                          <FormGroup>
                                            <Label for="name">Field Name*</Label>
                                            <Input
                                              type="text"
                                              disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                              name="name"
                                              value={field.name}
                                              required={true}
                                              onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                            />
                                          </FormGroup>

                                          <FormGroup>
                                            <Label for="name">Type*</Label>
                                            <div className="ml-3">
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    id={"type-" + fieldIndex}
                                                    disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                    type="checkbox"
                                                    name="type"
                                                    onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                                    value="text"
                                                    checked={field.type === "text" ? true : false}
                                                  />{" "}
                                                  Text
                                                </Label>
                                              </FormGroup>

                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    id={"type-" + fieldIndex}
                                                    disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                    type="checkbox"
                                                    name="type"
                                                    onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                                    value="radio"
                                                    checked={field.type === "radio" ? true : false}
                                                  />{" "}
                                                  Radio
                                                </Label>
                                              </FormGroup>

                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    id={"type-" + fieldIndex}
                                                    disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                    type="checkbox"
                                                    name="type"
                                                    onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                                    value="select"
                                                    checked={field.type === "select" ? true : false}
                                                  />{" "}
                                                  Select
                                                </Label>
                                              </FormGroup>

                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    id={"type-" + fieldIndex}
                                                    disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                    type="checkbox"
                                                    name="type"
                                                    onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                                    value="date"
                                                    checked={field.type === "date" ? true : false}
                                                  />{" "}
                                                  Date
                                                </Label>
                                              </FormGroup>
                                            </div>
                                          </FormGroup>

                                          <FormGroup>
                                            <Label for="label">Required</Label>
                                            <div className="ml-3">
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                    type="checkbox"
                                                    name="required"
                                                    onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                                    value="true"
                                                    checked={field.required == "true"}
                                                  />{" "}
                                                  Yes
                                                </Label>
                                              </FormGroup>
                                              <FormGroup check>
                                                <Label check>
                                                  <Input
                                                    disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                    type="checkbox"
                                                    name="required"
                                                    onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                                    value="false"
                                                    checked={field.required == "false"}
                                                  />{" "}
                                                  No
                                                </Label>
                                              </FormGroup>
                                            </div>
                                          </FormGroup>
                                        </Col>
                                        <Col lg={6}>
                                          {field.type == "text" && (
                                            <FormGroup>
                                              <Label for="maxlength">Max Length</Label>
                                              <Input
                                                disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                name="maxlength"
                                                value={field.maxlength}
                                                onChange={(e) => handleFieldChange(e, sectionIndex, fieldIndex)}
                                              />
                                            </FormGroup>
                                          )}

                                          {
                                            field.type && field.type != "text" && field.type != "date" && (
                                              <div>
                                                <Label for="label">Options</Label>
                                                <InputGroup>
                                                  <Input
                                                    disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                    name="input-option"
                                                    placeholder="Enter list of options"
                                                    innerRef={inputRef}
                                                  />
                                                  <Button
                                                    disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                    style={{ borderRadius: "0 2px 2px 0" }}
                                                    color="success"
                                                    onClick={(e) => {
                                                      handleAddOptionField(field, sectionIndex, fieldIndex)
                                                    }}
                                                  >
                                                    <span className="fa fa-check"></span>
                                                  </Button>
                                                </InputGroup>
                                                <InputGroup className="mt-2">
                                                  {field?.options?.map((option, optionIndex) => {
                                                    return (
                                                      <InputGroup className="mt-2" key={optionIndex}>
                                                        <Input readOnly={true} value={option} />
                                                        <Button
                                                          disabled={(!isEditForm && dataQuery.id) || isDisableForm}
                                                          style={{ borderRadius: "0 2px 2px 0" }}
                                                          color="danger"
                                                          onClick={() => {
                                                            handleRemoveOptionField(field, optionIndex, sectionIndex, fieldIndex)
                                                          }}
                                                        >
                                                          <span className="fa fa-trash"></span>
                                                        </Button>
                                                      </InputGroup>
                                                    )
                                                  })}
                                                </InputGroup>
                                              </div>
                                            )
                                          }

                                          {
                                            isEditForm && !isDisableForm && (
                                              <div
                                                className="mr-3 mb-3 font-weight-bold text-danger"
                                                style={{ position: "absolute", top: "94%", right: "1%", cursor: "pointer" }}
                                                onClick={() => {
                                                  setShowConfirmModal(true)
                                                  setConfirmModalType("delete-field")
                                                  setSelectedFieldIndex(fieldIndex)
                                                  setSelectedSectionIndex(sectionIndex)
                                                }}
                                              >
                                                Remove Field
                                              </div>
                                            )
                                          }
                                        </Col>
                                      </Row>
                                    </CardBody>
                                  </Collapse>
                                </Card>
                              </Col>
                            </Row>
                          </div>
                        )
                      })}

                    {(dataQuery.id ? (isEditForm ? true : false) : true) && !isDisableForm && (
                      <div>
                        <Button
                          color="info"
                          className="mt-3"
                          onClick={() => {
                            handleAddField(sectionIndex)
                            setFieldCollapse(sections[sectionIndex]["fields"].length - 1)
                          }}
                        >
                          Add Field
                        </Button>
                        <span className="mx-1"></span>
                        <Button
                          color="danger"
                          className="mt-3 ml-2"
                          onClick={() => {
                            setShowConfirmModal(true)
                            setConfirmModalType("delete-section")
                            setSelectedSectionIndex(sectionIndex)
                            // setFieldCollapse(sections[sectionIndex]['fields'].length -1)
                          }}
                        >
                          Remove Section
                        </Button>
                      </div>
                    )}
                  </CardBody>
                </Collapse>
              </Card>
            </Col>
          </Row>
        )
			})}
			
			{(dataQuery.id ? (isEditForm ? true : false) : true) && !isDisableForm &&
				<Button color="info" className="mt-3" onClick={handleAddSection}>Add Section</Button>
			}
		</Col>);
};

RequisitionsConfigurationQuestionsForm.propTypes = {

};

export default RequisitionsConfigurationQuestionsForm;
