import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function usePriceList({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["pricesListQueries", variables], () => axiosClient.get(`/prices?${paramsToQuery(variables)}`).then((res) => res.data), { ...rest })
}

export function usePriceFormSubmit({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("submit-price", (update) => axiosClient.post(`price`, update).then((res) => res.data), { ...rest })
}

export function usePriceDetailSubmit({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("submit-Detail-price", (update) => axiosClient.put(`price`, update).then((res) => res.data), { ...rest })
}
