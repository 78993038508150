import React, { useMemo, useState, useEffect, useCallback } from "react"
import axios from "axios"
import { useInsuranceList } from "api/getters"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"
import { Button, Alert, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import ReactSelect from "react-select"
import { makeApiCall } from "api/generic-api"

dayjs.extend(utc)

export function InsuranceCompanyTable({ pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = { sort, page, selector, pageSize: pageSizeProp || pageSize }

  const {
    data: insuranceData,
    isLoading,
    isFetching,
    refetch,
  } = useInsuranceList({
    variables: queryVariables,
  })

  const [alert, setAlert] = useState({ state: null, message: "" })
  const [modalOpen, setModalOpen] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null) // Will hold both id and name
  const [testTypes, setTestTypes] = useState([])
  const [payerTypes, setPayerTypes] = useState([])

  const fetchProductTypesAndPayers = async () => {
    try {
      const [productResponse, payerResponse] = await Promise.all([makeApiCall("get", "/api/products"), makeApiCall("get", "/api/prices")])

      const products = productResponse?.body || []
      const extractedTestTypes = products.map((product) => ({
        _id: product?.id,
        value: product?.id,
        label: product?.productName || "Unknown Product",
      }))

      const prices = payerResponse?.body || []
      const payerTypesSet = new Set(prices.map((price) => price?.payerType).filter((payerType) => payerType))

      setTestTypes(extractedTestTypes)
      setPayerTypes(Array.from(payerTypesSet))
    } catch (error) {
      console.error("Error fetching products or payer types:", error)
      setAlert({ state: "error", message: "Failed to fetch product types and payer types." })
    }
  }

  useEffect(() => {
    fetchProductTypesAndPayers()
  }, [])

  useEffect(() => {
    let timer
    if (alert.state) {
      timer = setTimeout(() => {
        setAlert({ state: null, message: "" })
      }, 2000)
    }
    return () => clearTimeout(timer)
  }, [alert])

  const columns = useMemo(
    () => [
      {
        Header: "Company Code",
        accessor: "id",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Products",
        accessor: "products",
      },
      {
        Header: "Notes",
        accessor: "notes",
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const handleDelete = async () => {
    try {
      await axios.delete(`/api/insurance-company/${itemToDelete?.id}`)
      setAlert({ state: "success", message: "Insurance company has been successfully deleted." })
      refetch()
      setModalOpen(false)
      setItemToDelete(null)
    } catch (error) {
      setAlert({
        state: "error",
        message: "There was a problem deleting the Insurance company. Please try again or contact support.",
      })
    }
  }

  const handleDeleteClick = (item) => {
    setItemToDelete(item)
    setModalOpen(true)
  }

  const handleCloseModal = () => {
    setModalOpen(false)
    setItemToDelete(null)
  }

  const getProcessedTableData = useCallback(
    (_insuranceData) =>
      (_insuranceData &&
        _insuranceData?.map((item) => ({
          id: item?.id,
          name: item?.name,
          products: item?.products,
          notes: item?.notes,
          action: (
            <div className="d-flex gap-2">
              <Link to={`/insurance-company-view/${item.id}`} className="btn btn-outline-primary">
                View
              </Link>
              <Button outline color="danger" onClick={() => handleDeleteClick(item)}>
                Delete
              </Button>
            </div>
          ),
        }))) ||
      [],
    [],
  )

  const data = useMemo(() => getProcessedTableData(insuranceData), [insuranceData, getProcessedTableData])

  const filters = useMemo(
    () => [
      {
        field: "_id",
        label: "Search Company Code",
        placeholder: "Type in Company Code",
        removeSpaces: true,
      },
      {
        field: "name",
        operator: "$regex",
        label: "Search Company Name",
        placeholder: "Type in Company Name",
      },
    ],
    [],
  )

  return (
    <div>
      {alert.state === "success" && (
        <Alert style={{ marginTop: "10px", textAlign: "center" }} color="success">
          {alert.message}
        </Alert>
      )}
      {alert.state === "error" && (
        <Alert style={{ marginTop: "10px", textAlign: "center" }} color="danger">
          {alert.message}
        </Alert>
      )}
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        filters={filters}
        isLoading={isLoading || isFetching}
        selector={selector}
        showAdd="/insurance-company-form"
        sort={sort}
        downloadFileName="Insurance Company"
        csvQuery={useInsuranceList}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
      <Modal isOpen={modalOpen} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>Confirmation</ModalHeader>
        <ModalBody>Are you sure you want to remove {itemToDelete?.name}?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={handleDelete}>
            OK
          </Button>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  )
}
