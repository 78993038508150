import React from 'react';
import './tray-layout.css';
import TrayLayoutSimple from "./tray-layout-simple";

const getTests = (trayConfiguration, tray, subTrayId) => {
    try {
        let start = 0;
        let end = 0;
        for (let i = 0; i < trayConfiguration.compound.length; i++) {
            start = end;
            end += trayConfiguration.compound[i].maxFill;
            if(trayConfiguration.compound[i].id === subTrayId) {
                break;
            }
        }

        if (start < tray.tests.length) {
            if(end >= tray.tests.length) {
                end = tray.tests.length - 1;
            }
            console.log('get tests ' + subTrayId + ' returning ' + start + ' to ' + end);
            return tray.tests.slice(start, end + 1);
        }
    } catch(err) {
        console.error('error in getting tests for tray layout', err);
    }
    console.log('get tests ' + subTrayId + ' returning empty');
    return [];
};

const TrayLayoutCompound = (props) => {
    const tray = props.tray;
    const trayConfiguration = props.trayConfiguration;
    console.log('tray', tray);

    return (
        <div>
            {
                trayConfiguration.compound.map((subTray, key) =>
                    <div key={key}>
                        <h4 style={{marginTop:'10px'}}>{'SUBTRAY ' + subTray.id}</h4>
                        <TrayLayoutSimple
                            trayConfiguration={subTray}
                            tray={props.tray}
                            tests={getTests(trayConfiguration, tray, subTray.id)}
                            />
                    </div>
                )
            }
        </div>
    );
}

export default TrayLayoutCompound;
