import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import { useShallow } from "zustand/react/shallow"

export function useQuery() {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export function usePreviousTableQuery(pageUrl) {
  const { previousQuery, previousPath } = useTableQueryStore(
    useShallow((state) => ({
      previousQuery: state.previousQuery,
      previousPath: state.previousPath,
    })),
  )

  const [tableQuery, setTableQuery] = useState("")

  useEffect(() => {
    if (previousPath === pageUrl) {
      setTableQuery(previousQuery)
    }
  }, [previousPath, previousQuery, pageUrl])

  return tableQuery
}
