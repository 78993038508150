const qs = require('query-string-object')
const nestedObjectsUtil = require('nested-objects-util')
const flattenObject = require( '@stdlib/utils-flatten-object' );

const dataToStringQuery = (data) => {
    const flattened = nestedObjectsUtil.flatten(data);
    const stringified  = qs.stringify(flattened);
    const query = stringified.replaceAll(".", "+")
    return  encodeURI(query);
}

const stringQueryToData = (query) => {
    const data = query.replaceAll("+", ".")
    const parsed = qs.parse(data);
    return nestedObjectsUtil.unflatten(parsed);
}

const flatten = (data, depth = 1) => {
    const flattened = flattenObject( data, {
        'depth': depth,
        'copy': true
    });
    
    console.log('flattened', flattened);
    return flattened;
    
}

const dataToQuery = (data, noQuestion) => {
    if (!data || !(data instanceof Object)) {
        return '';
    }
    let query = '';
    for (var prop in data) {
        if (data.hasOwnProperty(prop)) {
            if(query != '') {
                query += '&';
            }
            const value = data[prop] instanceof Object ? JSON.stringify(data[prop]) : data[prop];
            query += encodeURIComponent(prop) + '=' + encodeURIComponent(value);
        }
    }
    if (query != '' && !noQuestion) {
        query = '?' + query;
    }
    return query;
};


const resolveUriRouteVariables = (uri, data) => {
    if (!data || !(data instanceof Object)) {
        return uri;
    }
    let uriParts = uri.split('/');
    for(let i = 0; i < uriParts.length; i++) {
        if (uriParts[i].startsWith(':')) {
            const variableName = uriParts[i].substring(1);
            const value = data[variableName];
            if (!value || value == 'null' || value == 'undefined') {
                throw('No data found for route variable ' + variableName);
            }
            uriParts[i] = encodeURIComponent(value);
        }
    }
    return uriParts.join('/');
};

const postRequest = (structure, record) => {
    const verb = 'post';
    const baseUri = structure.uri[verb];
    const uri = resolveUriRouteVariables(baseUri, record);
    console.log(verb + ' ' + uri);
    return {
        uri: uri,
        method: verb,
        body: JSON.stringify(record)
    };
};

const patchRequest = (structure, recordOrId, changes) => {
    const verb = 'patch';
    const baseUri = structure.uri[verb];
    const passedObject = recordOrId && recordOrId instanceof Object;
    const recordId = passedObject ? recordOrId[structure.key] : recordOrId;
    const idObject = { };
    idObject[structure.key] = recordId;
    const patch = Object.assign(changes, idObject);
    const uriFromPatch = resolveUriRouteVariables(baseUri, patch);
    const uri = passedObject ? resolveUriRouteVariables(uriFromPatch, recordOrId) : uriFromPatch;
    console.log(verb + ' ' + uri);
    return {
        uri: uri,
        method: verb.toLocaleUpperCase(),
        body: JSON.stringify(patch)
    };
};

const putRequest = (structure, record) => {
    const verb = 'put';
    const baseUri = structure.uri[verb];
    const uri = resolveUriRouteVariables(baseUri, record);
    console.log(verb + ' ' + uri);
    return {
        uri: uri,
        method: verb,
        body: JSON.stringify(record)
    };
};

const getRequest = (structure, recordOrId) => {
    const verb = 'get';
    const baseUri = structure.uri[verb];
    const passedObject = recordOrId && recordOrId instanceof Object;
    const recordId = passedObject ? recordOrId[structure.key] : recordOrId;
    const idObject = { };
    idObject[structure.key] = recordId;
    const uriFromId = resolveUriRouteVariables(baseUri, idObject);
    const uri = passedObject ? resolveUriRouteVariables(uriFromId, recordOrId) : uriFromId;
    console.log(verb + ' ' + uri);
    return {
        uri: uri,
        method: verb
    };
};

const getAllRequest = (structure, page) => {
    const verb = 'get';
    let pageQuery = '';
    if(page && structure.pageSize) {
        const pageData = {
            page: page,
            pagesize: structure.pageSize
        };
        pageQuery = dataToQuery(pageData, false);
    }
    const uri = structure.uri['getAll'] + pageQuery;
    console.log(verb + ' ' + uri);

    return {
        uri: uri,
        method: verb
    };
};

const deleteRequest = (structure, recordOrId) => {
    const verb = 'delete';
    const baseUri = structure.uri[verb];
    const passedObject = recordOrId && recordOrId instanceof Object;
    const recordId = passedObject ? recordOrId[structure.key] : recordOrId;
    const idObject = { };
    idObject[structure.key] = recordId;
    const uriFromId = resolveUriRouteVariables(baseUri, idObject);
    const uri = passedObject ? resolveUriRouteVariables(uriFromId, recordOrId) : uriFromId;
    console.log(verb + ' ' + uri);
    return {
        uri: uri,
        method: verb
    };
};

const hasSearchUri = (structure) => {
    const baseUri = structure.uri['search'];
    return baseUri ? true : false;
};

const searchRequest = (structure, search, page) => {
    const verb = 'get';
    const baseUri = structure.uri['search'];
    let pageQuery = '';
    if(page && structure.pageSize) {
        const pageData = {
            page: page,
            pagesize: structure.pageSize
        };
        pageQuery = '&' + dataToQuery(pageData, true);
    }
    //const query = dataToQuery({search: search});
    search = search || '';
    const uri = baseUri.replace(':search', encodeURIComponent(search.trim())) + pageQuery;
    console.log(verb + ' ' + uri);
    return {
        uri: uri,
        method: verb
    };
};


module.exports  = {
    postRequest: postRequest,
    patchRequest: patchRequest,
    putRequest: putRequest,
    getRequest: getRequest,
    getAllRequest: getAllRequest,
    deleteRequest: deleteRequest,
    searchRequest: searchRequest,
    hasSearchUri: hasSearchUri,
    dataToQuery,
    dataToStringQuery,
    stringQueryToData,
    flatten
};
