import React, {useEffect, useState} from 'react';
import fetch from 'isomorphic-fetch';
import { dataToQuery } from "../utility/uri-utility";
import lookups from "../configuration/lookups";
import {makeApiCall, makeApiDownloadCall} from "../api/generic-api";
import {get} from "../utility/client-utility";

import {Col, Row} from "reactstrap";
import GeneralForm from "./general-form-report";
import csvDownloader from "./csv-downloader";

const reportInputFormDefinitionBase = {
    submit: {
        label: 'Download',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    sections: [
        {
            label: 'Specify Report',
            columns: 3,
            fields: [
                {
                    label: 'Report Type',
                    type: 'select',
                    name: 'id',
                    source: {
                        kind: 'direct',
                        data: [

                        ]
                    },
                    required: true,
                },
                {
                    label: 'Start Date',
                    name: 'startDate',
                    type: 'date',
                    required: true,
                    style: {maxWidth: '170px'},
                    max: "9999-12-31"
                },
                {
                    label: 'End Date',
                    name: 'endDate',
                    type: 'date',
                    required: true,
                    style: {maxWidth: '170px'},
                    max: "9999-12-31"
                }
            ]
        }
    ]
};

function ReportView(props) {
    const [reportDefinitions, setReportDefinitions] = useState([]);
    const [reportInputFormDefinition, setReportInputFormDefinition] = useState(reportInputFormDefinitionBase);
    const [ isDownloading, setIsDownloading ] = useState(false);

    const submit = (formData) => {
        console.log('report params', formData);
        (async () => {
            setIsDownloading(true);
            try {
                const reportDefinition = reportDefinitions.find(r => r.id === formData.id);
                const filename = get(() => reportDefinition.filenameDefinition.format, 'report.txt')
                    .replace('##startDate##', formData.startDate)
                    .replace('##endDate##', formData.endDate)
                const type = get(() => reportDefinition.outputType) === 'text' ? 'text/plain' : get(() => reportDefinition.outputType, 'text/plain');

                setTimeout(async () => {
                    const data = await makeApiDownloadCall('get', '/api/report?', formData, type, filename);
                    setIsDownloading(false);
                }, 800)

            } catch (err) {
                setTimeout(async () => {
                    setIsDownloading(false);
                }, 800)
                console.error('Error in download action', err);
            }
        })();
    };

    useEffect(() => {
        (async () => {
            try {
                const data = await makeApiCall('get', '/api/reports');
                if(data.statusCode === 200) {
                    setReportDefinitions(data.body);
                    const reportFormDefinition = JSON.parse(JSON.stringify(reportInputFormDefinitionBase));
                    reportFormDefinition.sections[0].fields[0].source.data = data.body.map(report => {return {key: report.id, value: report.name};});
                    setReportInputFormDefinition(reportFormDefinition);
                } else {
                    console.error('failed to get report list', data);
                }
            } catch (err) {
                console.error('Error in getting report list', err);
            }
        })();
    }, []);

    return (
        <div>
            <Row style={{marginTop: '30px'}}>
                <Col sm="3">
                </Col>
                <Col sm="6" className="text-center">
                    <h3>Reports</h3>
                </Col>
                <Col sm="3">
                </Col>
            </Row>
            <GeneralForm formDefinition={reportInputFormDefinition} submit={submit} {...props} isDownloading={isDownloading}/>
        </div>
    );
}

export default ReportView;
