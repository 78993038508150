import React from "react"
import { Card, CardBody, Col, Row } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import { SamplesTable } from "./tables/SamplesTable"

function SampleViewNew(props) {
  const permission = props && props.applicationState.authentication.user.permission

  const breadcrumbItems = [
    {
      label: "Samples",
      link: `/sample-view`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Samples" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <SamplesTable allowPageSize allowPaginate isGlobalFilter permission={permission} />
        </CardBody>
      </Card>
    </div>
  )
}

export default SampleViewNew
