import React from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import { usePreviousTableQuery } from "hooks/query"
import Breadcrumb from "./custom/Breadcrumb"
import UnfilledOrderDetails from "./custom/UnfilledOrderDetails"
import { useParams } from "react-router"

function UnfilledOrderInfo(props) {
  const { id: orderId } = useParams()
  const previousTableQuery = usePreviousTableQuery("/unfilled-orders-view")

  const permission = props?.applicationState?.authentication?.user?.permission
  const breadcrumbItems = [
    {
      label: "Unfilled Orders",
      link: `/unfilled-orders-view?${previousTableQuery}`,
    },
    {
      label: orderId,
      link: `/unfilled-orders/${orderId}`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardHeader>
          <CardTitle>Unfilled Order Details</CardTitle>
        </CardHeader>
        <CardBody>
          <UnfilledOrderDetails orderId={orderId} permission={permission} />
        </CardBody>
      </Card>
    </div>
  )
}

export default UnfilledOrderInfo
