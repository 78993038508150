import React, { useEffect, useState } from "react"

import { Container, Row, Col, CardHeader, Collapse, CardBody, Label, Input, Card, Button, CardTitle } from "reactstrap"
import lookups from "../../configuration/lookups"
import { makeApiCall } from "../../api/generic-api"
import { get } from "../../utility/client-utility"
import ProcessDilutionPropertiesForm from "./process-dilution-properties-form"

function ProcessCdnaPropertiesForm(props) {
  const currentData = get(() => props.currentData)
  const index = get(() => props.index)
  const currentFormState = get(() => props.currentFormState)
  const editForm = props && props.editMode
  const isDisableForm = props && props.isDisableForm

  const [location, setLocation] = useState(null)

  // console.warn('processcdnaproperties currentdata', currentData);

  const [viewCdnaProperties, setViewCdnaProperties] = useState(false)
  const [viewDilutionProperties, setViewDilutionProperties] = useState(false)

  const viewCDNAProps = () => {
    setViewCdnaProperties(true)
  }

  const viewDilutionProps = () => {
    setViewDilutionProperties(true)
  }

  const getOptions = (source, noEmpty) => {
    const empty = noEmpty
      ? []
      : [
          {
            key: "",
            value: "",
          },
        ]

    const lookupResult = empty.concat(lookups.filter((s) => s.type === source))
    return lookupResult
  }

  useEffect(() => {
    if (!currentData.location) {
      setLocation(currentFormState.sampleLocation)
    } else {
      setLocation(currentData.location)
    }
  })

  return (
    <>
      <Row>
        <Col sm={6} key={"columnIsolateId-" + index}>
          <Label style={{ display: "block" }} for="sampleId">
            Isolate ID
          </Label>
          <Input
            type="text"
            name="id"
            value={currentData.id}
            onChange={(e) => {
              e.persist()
              props.handleChange(e, index, currentData)
            }}
            disabled={true}
          />
        </Col>
        <Col sm={6} key={"columnIsoLocation-" + index}>
          <Label style={{ display: "block" }} for="location">
            Location
          </Label>
          <Input
            type="text"
            name="location"
            value={location}
            onChange={(e) => {
              e.persist()
              props.handleChange(e, index, currentData)
            }}
            disabled={isDisableForm}
          />
        </Col>
        <Col sm={6} key={"columnIsoStatus-" + index}>
          <Label style={{ display: "block" }} for="isolationStatus" className="mt-3">
            Isolation Status
          </Label>
          <Input
            type="select"
            name="isolationStatus"
            value={currentData.isolationStatus != "isolationStart" ? "isolationStop" : "isolationStart"}
            onChange={(e) => {
              e.persist()
              props.handleChange(e, index, currentData)
            }}
            disabled={isDisableForm}
          >
            {getOptions("isolationStatus")
              .filter((x) => ["isolationStart", "isolationStop"].includes(x.key))
              .map((item) => (
                <option key={item.key} value={item.key}>
                  {item.value || item.key}
                </option>
              ))}
          </Input>

          {currentData.isolationStatus != "isolationStart" && (
            <div className="ml-4 mt-2">
              {getOptions("isolationStatus")
                .filter((x) => ["accepted", "rejected", "storage"].includes(x.key))
                .map((item) => (
                  <span className="mr-5" key={item.key}>
                    <Input
                      type="radio"
                      name="isolationStatus"
                      id={`${item.key}-${index}-isolation`}
                      value={item.key}
                      checked={currentData.isolationStatus == item.key}
                      disabled={isDisableForm}
                      onChange={(e) => {
                        props.handleChange(e, index, currentData)
                      }}
                      style={{ marginRight: "0.5rem" }}
                    />
                    <Label for={`${item.key}-${index}-isolation`} style={{ marginRight: "2rem" }}>
                      {item.value}
                    </Label>
                  </span>
                ))}
            </div>
          )}
        </Col>
        <Col sm={6} key={"columnIsoConcentration-" + index}>
          <Label style={{ display: "block" }} for="isolationConcentration" className="mt-3">
            Isolation Concentration
          </Label>
          <style>
            {`
                          input[type="number"]::-webkit-inner-spin-button,
                          input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                          }
                          input[type="number"] {
                            -moz-appearance: textfield; /* Firefox */
                          }
                        `}
          </style>
          <Input
            type="number"
            name="isolationConcentration"
            value={currentData.isolationConcentration}
            onChange={(e) => {
              e.persist()
              props.handleChange(e, index, currentData)
            }}
            disabled={isDisableForm}
            required={currentData.isolationStatus != "isolationStart" ? true : false}
          />
        </Col>
        <Col sm={6} key={"columnIso260280-" + index}>
          <Label style={{ display: "block" }} for="isolation260280ratio" className="mt-3">
            Isolation 260/280 Ratio
          </Label>
          <style>
            {`
                          input[type="number"]::-webkit-inner-spin-button,
                          input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                          }
                          input[type="number"] {
                            -moz-appearance: textfield; /* Firefox */
                          }
                        `}
          </style>
          <Input
            type="text"
            name="isolation260280ratio"
            value={currentData.isolation260280ratio}
            onChange={(e) => {
              e.persist()
              props.handleChange(e, index, currentData)
            }}
            disabled={isDisableForm}
            required={false}
          />
        </Col>

        <Col sm={6} key={"columnIso260230-" + index}>
          <Label style={{ display: "block" }} for="isolation260230ratio" className="mt-3">
            Isolation 260/230 Ratio
          </Label>
          <style>
            {`
                          input[type="number"]::-webkit-inner-spin-button,
                          input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                          }
                          input[type="number"] {
                            -moz-appearance: textfield; /* Firefox */
                          }
                        `}
          </style>
          <Input
            type="text"
            name="isolation260230ratio"
            value={currentData.isolation260230ratio}
            onChange={(e) => {
              e.persist()
              props.handleChange(e, index, currentData)
            }}
            disabled={isDisableForm}
            required={false}
          />
        </Col>

        {currentData.isolationStatus === "accepted" ? (
          <Col sm={12} key={"cDnaButton-" + index}>
            <Button
              style={{ marginTop: "20px", align: "center" }}
              outline
              color="primary"
              type="button"
              onClick={viewCDNAProps}
              disabled={isDisableForm || viewCdnaProperties}
            >
              cDNA
            </Button>
          </Col>
        ) : null}
      </Row>
      <br />
      {(currentData.isolationStatus || viewCdnaProperties === true) && (
        <Card className="rounded-0">
          <CardHeader key={"columncDna-" + index}>
            <CardTitle>cDNA Properties</CardTitle>
          </CardHeader>
          <CardBody>
            <Row>
              <Col sm={6} key={"columnCdnaStatus-" + index}>
                <Label style={{ display: "block" }} for="cDnaStatus">
                  cDNA Status
                </Label>
                <Input
                  type="select"
                  name="cDnaStatus"
                  value={currentData.cDnaStatus != "cDnaStart" ? "cDnaStop" : "cDnaStart"}
                  onChange={(e) => {
                    e.persist()
                    props.handleChange(e, index, currentData)
                  }}
                  disabled={isDisableForm}
                >
                  {getOptions("cDnaStatus")
                    .filter((x) => ["cDnaStart", "cDnaStop"].includes(x.key))
                    .map((item) => (
                      <option key={item.key} value={item.key}>
                        {item.value || item.key}
                      </option>
                    ))}
                </Input>

                {currentData.cDnaStatus != "cDnaStart" && (
                  <div className="ml-4 mt-2">
                    {getOptions("cDnaStatus")
                      .filter((x) => ["accepted", "rejected", "storage"].includes(x.key))
                      .map((item, idx) => (
                        <span className="mr-5" key={idx}>
                          <Input
                            type="radio"
                            name="cDnaStatus"
                            id={`${item.key}-${index}-cDna`}
                            value={item.key}
                            checked={currentData.cDnaStatus == item.key}
                            disabled={isDisableForm}
                            onChange={(e) => {
                              props.handleChange(e, index, currentData)
                            }}
                            style={{ marginRight: "0.5rem" }}
                          />
                          <Label for={`${item.key}-${index}-cDna`} style={{ marginRight: "2rem" }}>
                            {item.value}
                          </Label>
                        </span>
                      ))}
                  </div>
                )}
              </Col>
              <Col sm={6} key={"columnCdnaConcentration-" + index}>
                <Label style={{ display: "block" }} for="cDnaConcentration">
                  cDNA Concentration
                </Label>
                <style>
                  {`
                          input[type="number"]::-webkit-inner-spin-button,
                          input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                          }
                          input[type="number"] {
                            -moz-appearance: textfield; /* Firefox */
                          }
                        `}
                </style>
                <Input
                  type="number"
                  name="cDnaConcentration"
                  value={currentData.cDnaConcentration}
                  onChange={(e) => {
                    e.persist()
                    props.handleChange(e, index, currentData)
                  }}
                  disabled={isDisableForm}
                  required={currentData.cDnaStatus != "cDnaStart" ? true : false}
                />
              </Col>
              <Col sm={6} key={"columnCdna260280-" + index}>
                <Label style={{ display: "block" }} for="cDna260280ratio" className="mt-3">
                  cDNA 260/280 Ratio
                </Label>
                <style>
                  {`
                          input[type="number"]::-webkit-inner-spin-button,
                          input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                          }
                          input[type="number"] {
                            -moz-appearance: textfield; /* Firefox */
                          }
                        `}
                </style>
                <Input
                  type="text"
                  name="cDna260280ratio"
                  value={currentData.cDna260280ratio}
                  onChange={(e) => {
                    e.persist()
                    props.handleChange(e, index, currentData)
                  }}
                  disabled={isDisableForm}
                  required={false}
                />
              </Col>
              <Col sm={6} key={"columnCdna260230-" + index}>
                <Label style={{ display: "block" }} for="cDna260230ratio" className="mt-3">
                  cDNA 260/230 Ratio
                </Label>
                <style>
                  {`
                          input[type="number"]::-webkit-inner-spin-button,
                          input[type="number"]::-webkit-outer-spin-button {
                            -webkit-appearance: none;
                            margin: 0;
                          }
                          input[type="number"] {
                            -moz-appearance: textfield; /* Firefox */
                          }
                        `}
                </style>
                <Input
                  type="text"
                  name="cDna260230ratio"
                  value={currentData.cDna260230ratio}
                  onChange={(e) => {
                    e.persist()
                    props.handleChange(e, index, currentData)
                  }}
                  disabled={isDisableForm}
                  required={false}
                />
              </Col>
              {currentData.cDnaStatus === "accepted" ? (
                <Col sm={12} key={"dilutiondButton-" + index}>
                  <Button
                    style={{ marginTop: "20px", align: "center" }}
                    outline
                    color="primary"
                    type="button"
                    onClick={viewDilutionProps}
                    disabled={isDisableForm}
                  >
                    Dilutions
                  </Button>
                </Col>
              ) : null}
            </Row>
          </CardBody>
        </Card>
      )}
      <br />
      {(currentData.dilutions || viewDilutionProperties === true) && (
        <>
          <Card className="rounded-0">
            <CardHeader key={"columnDilution-" + index}>
              <CardTitle>Dilution Properties</CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <ProcessDilutionPropertiesForm
                  currentData={currentData}
                  index={index}
                  currentFormState={currentFormState}
                  sampleId={props.sampleId}
                  setCurrentFormState={props.setCurrentFormState}
                  submit={props.submit}
                  editMode={editForm}
                  setEditMode={props.setEditMode}
                  setDisplay={props.setDisplay}
                  isDisableForm={isDisableForm}
                  setIsDisableForm={props.setIsDisableForm}
                  close={props.close}
                  {...props}
                  customRowActions={[]}
                />
              </Row>
            </CardBody>
          </Card>
        </>
      )}
    </>
  )
}

export default ProcessCdnaPropertiesForm
