import React, { useEffect, useState } from "react"
import { Card, CardBody, CardHeader, CardTitle } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import PriceForm from "../components/custom/PriceForm"
import { usePreviousTableQuery } from "hooks/query"

function ProductFormView(props) {
    const permission = props && props.applicationState.authentication.user.permission;
    const previousQuery = usePreviousTableQuery("/prices-view")

    const breadcrumbItems = [
        {
            label: "Prices",
            link: `/prices-view?${previousQuery}`,
        },
        {
            label: "Price Form",
            link: `/prices-view-form`,
        },

    ]

    return (
        <div className="wide-container">
            <Breadcrumb title="Prices" breadcrumbItems={breadcrumbItems} />
            <Card>
                <CardHeader>
                    <CardTitle>Price Form</CardTitle>
                </CardHeader>
                <CardBody>
                    <PriceForm permission={permission} />
                </CardBody>
            </Card>
        </div>
    )
}

export default ProductFormView
