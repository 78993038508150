import { axiosClient, useRequestProcessor } from "api/provider"

export function useExtrasConfigurationFormSubmit({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("extras", (update) => axiosClient.post(`extra`, update).then((res) => res.data), { ...rest })
}

export function useExtrasConfigurationInfoUpdate(ExtraId, { ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("update-extra", (update) => axiosClient.put(`extra/${ExtraId}`, update).then((res) => res.data), { ...rest })
}
