const netestRequisitionFormDefinition = {
    formLabel: 'Cancer Test REQUISITION',
    submit: {
        label: 'Download',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    cancel: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },

    update: {
        label: 'Update',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#f64d52',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center',
        },
        disabled: false
    },
    edit: {
        label: 'Edit',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },

    close: {
        label: 'Close',
        style: {
            cursor: 'pointer',
            padding: '14px 24px',
            borderRadius: '200px',
            backgroundColor: '#29335c',
            boxShadow: 'inset 0 10px 3px 100px transparent',
            webkitTransition: 'box-shadow 200ms ease',
            transition: 'box-shadow 200ms ease',
            color: '#ffffff',
            fontWeight: 600,
            textAlign: 'center'
        }
    },
    sections: [
        {
            label: 'Your Sample',
            columns: 2,
            fields: [
                {
                    label: 'Sample Number',
                    name: 'sampleId',
                    type: 'text',
                    style: {maxWidth:'250px'},
                    className: 'covid-test',
                    pattern: '(^[A-Z]{2}[0-9]{10}[A-Z]$)|(^[A-Z]{4}[0-9]{8}[A-Z]$)',
                    notes: 'Should be 4 letters and 8 numbers along with the suffix of B'
                },
                {
                    label: 'Format is 2 capital letters, 10 digits, 1 capital letter or 4 capital letters, 8 digits, 1 capital letter no commas or spaces.',
                    name: 'manualTestPrompt',
                    type: 'label',
                    visible: {manualTestId: true},
                    labelStyle: {color: 'red'},
                    labelClassName: 'warning-label-class'
                },
            ]
        },
        {
            label: 'Destination',
            columns: 1,
            fields: [
                {
                    label: 'Where should the test kit be sent?',
                    type: 'radio',
                    name: 'destination',
                    source: {
                        kind: 'direct',
                        data: [
                            {
                                key: 'patient',
                                value: 'To the patient'
                            },
                            {
                                key: 'provider',
                                value: 'To the medical care provider'
                            },
                            {
                                key: 'noKit',
                                value: 'No kit needed'
                            }
                        ]
                    },
                    required: true,
                    style: {maxWidth:'200px'},
                    itemStyle: {
                        span: {display: 'block'},
                        radio: {marginTop: '1px'},
                        label: {marginLeft: '30px'}
                    }
                }
            ]
        },
        {
            label: 'Patient\'s Information',
            columns: 2,
            fields: [
                {
                    label: 'First Name',
                    name: 'firstName',
                    type: 'text',
                    //pattern: '[a-z]{10}',
                    //title: 'Must be 10',
                    required: true,
                    style: {maxWidth:'400px'},
                    maxlength: 50
                },
                {
                    label: 'Last Name',
                    name: 'lastName',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'400px'},
                    maxlength: 50
                },
                {
                    label: 'Middle Initial',
                    name: 'middleInitial',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'70px'},
                    maxlength: 50
                },
                {
                    label: 'Date of Birth',
                    name: 'dateOfBirth',
                    type: 'date',
                    required: true,
                    style: {maxWidth:'200px'},
                    max: "9999-12-31"
                },
                {
                    label: 'Gender',
                    name: 'gender',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'netest-gender'
                    },
                    required: true,
                    style: {maxWidth:'200px'}
                },
                {
                    label: 'Country',
                    name: 'country',
                    type: 'select',
                    source: {
                        kind: 'list',
                        data: 'country'
                    },
                    required: true,
                    style: {maxWidth:'200px'}
                },
                {
                    label: 'State *',
                    name: 'state',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'state'
                    },
                    required: false,
                    style: {maxWidth:'300px'},
                    validation: {
                        rule: {
                            $or: [
                                {country: { $in: ['US', 'GB', 'NL']}},
                                {state: {required: true}}
                            ]
                        },
                        message: 'State and Zip Code is required for selected country'
                    }
                },
                {
                    label: 'Zip Code *',
                    name: 'zipCode',
                    type: 'number',
                    required: false,
                    style: {maxWidth:'150px'},
                    maxlength: 5,
                    validation: {
                        rule: {
                            $or: [
                                {country: { $in: ['US', 'GB', 'NL']}},
                                {zipCode: {required: true}}
                            ]
                        },
                        message: 'State and Zip Code is required for selected country'
                    }
                },
                {
                    label: 'Region *',
                    name: 'region',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'300px'},
                    validation: {
                        rule: {
                            $or: [
                                {country: { $nin: ['US', 'GB', 'NL']}},
                                {region: {required: true}}
                            ]
                        },
                        message: 'Region and Postal Code is required for selected country'
                    }
                },
                {
                    label: 'Postal Code *',
                    name: 'postalCode',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'300px'},
                    maxlength: 15,
                    validation: {
                        rule: {
                            $or: [
                                {country: { $nin: ['US', 'GB', 'NL']}},
                                {postalCode: {required: true}}
                            ]
                        },
                        message: 'Region and Postal Code is required for selected country'
                    }
                },
                {
                    label: 'Street Address',
                    name: 'address',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'250px'},
                    maxlength: 30,
                },
                {
                    label: 'City',
                    name: 'city',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'250px'},
                    pattern: '[^0-9]+',
                    title: 'At least one character and not numerical',
                    maxlength: 10
                },
                {
                    label: 'Phone Number',
                    name: 'phoneNumber',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'200px'},
                    maxlength: 15
                },
                {
                    label: 'Email Address',
                    name: 'email',
                    type: 'email',
                    required: true,
                    pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
                    style: {maxWidth:'400px'}
                },
                {
                    label: 'Responsible Party Name',
                    name: 'responsibleParty',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'250px'}
                },
                {
                    label: 'Relationship to Patient',
                    name: 'relationshipToPatient',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'relationship'
                    },
                    required: true,
                    style: {maxWidth:'200px'}
                },
                {
                    label: 'Bill To',
                    name: 'billTo',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'billTo'
                    },
                    required: true,
                    style: {maxWidth:'200px'}
                }
            ]
        },
        // {
        //     label: 'Insurance Information (Complete below)',
        //     columns: 2,
        //     fields: [
        //         {
        //             label: 'Primary Insurance Info:',
        //             name: 'insuranceALabel',
        //             type: 'label',
        //             style: {maxWidth: '400px'}
        //         },
        //         {
        //             label: 'Subscriber Name',
        //             name: 'subscriberAName',
        //             type: 'text',
        //             required: false,
        //             style: {maxWidth: '400px'},
        //             validation: {
        //                 rule: {
        //                     $or: [
        //                         {billTo: 'personal'},
        //                         {billTo: 'client'},
        //                         {subscriberAName: {required: true}}
        //                     ]
        //                 },
        //                 message: 'Subscriber name is required when insurance is selected for billing'
        //             },
        //         },
        //         {
        //             label: 'Provider Name',
        //             name: 'providerAName',
        //             type: 'text',
        //             required: false,
        //             style: {maxWidth: '400px'},
        //             validation: {
        //                 rule: {
        //                     $or: [
        //                         {billTo: 'personal'},
        //                         {billTo: 'client'},
        //                         {providerAName: {required: true}}
        //                     ]
        //                 },
        //                 message: 'Provider name is required when insurance is selected for billing'
        //             },
        //         },
        //         {
        //
        //             label: 'Provider Address',
        //             name: 'providerAAddress',
        //             type: 'text',
        //             required: false,
        //             style: {maxWidth: '400px'},
        //             maxlength: 30,
        //             validation: {
        //                 rule: {
        //                     $or: [
        //                         {billTo: 'personal'},
        //                         {billTo: 'client'},
        //                         {providerAAddress: {required: true}}
        //                     ]
        //                 },
        //                 message: 'Provider address is required when insurance is selected for billing'
        //             },
        //         },
        //         {
        //             label: 'ID#/Group#',
        //             name: 'providerAId',
        //             type: 'text',
        //             required: false,
        //             style: {maxWidth: '400px'},
        //             validation: {
        //                 rule: {
        //                     $or: [
        //                         {billTo: 'personal'},
        //                         {billTo: 'client'},
        //                         {providerAId: {required: true}}
        //                     ]
        //                 },
        //                 message: 'ID#/Group# is required when insurance is selected for billing'
        //             },
        //         },
        //         {
        //             label: 'Secondary Insurance Info:',
        //             name: 'insuranceBLabel',
        //             type: 'label',
        //             style: {maxWidth: '400px'}
        //         },
        //         {
        //             label: 'Subscriber Name',
        //             name: 'subscriberBName',
        //             type: 'text',
        //             required: false,
        //             style: {maxWidth: '400px'}
        //         },
        //         {
        //             label: 'Provider Name',
        //             name: 'providerBName',
        //             type: 'text',
        //             required: false,
        //             style: {maxWidth: '400px'}
        //         },
        //         {
        //
        //             label: 'Provider Address',
        //             name: 'providerBAddress',
        //             type: 'text',
        //             required: false,
        //             style: {maxWidth: '400px'},
        //             maxlength: 30
        //         },
        //         {
        //             label: 'ID#/Group#',
        //             name: 'providerBId',
        //             type: 'text',
        //             required: false,
        //             style: {maxWidth: '400px'}
        //         }
        //     ]
        // },
        {
            label: 'Self Pay (Please fill out credit card information below, or indicate your billing address if different from above)',
            columns: 2,
            fields: [
                {
                    label: 'Name (as it appears on your card) *',
                    name: 'creditCardName',
                    type: 'text',
                    required: false,
                    style: {maxWidth: '400px'},
                    validation: {
                        rule: {
                            $or: [
                                {billTo: 'insurance'},
                                {billTo: 'client'},
                                {creditCardName: {required: true}}
                            ]
                        },
                        message: 'Card name is required when patient is selected for billing'
                    }
                },
                {
                    label: 'Credit Card Type *',
                    name: 'creditCardType',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'creditCardType'
                    },
                    required: false,
                    style: {maxWidth: '400px'},
                    validation: {
                        rule: {
                            $or: [
                                {billTo: 'insurance'},
                                {billTo: 'client'},
                                {creditCardType: {required: true}}
                            ]
                        },
                        message: 'Credit card type is required when patient is selected for billing'
                    }
                },
                {
                    label: 'Card Number *',
                    name: 'creditCardNo',
                    type: 'text',
                    pattern: '[0-9]{16}',
                    required: false,
                    style: {maxWidth: '400px'},
                    validation: {
                        rule: {
                            $or: [
                                {billTo: 'insurance'},
                                {billTo: 'client'},
                                {creditCardNo: {required: true}}
                            ]
                        },
                        message: 'Card number is required when patient is selected for billing'
                    },
                },
                {
                    label: 'Expiry Date *',
                    name: 'creditCardExpiry',
                    type: 'text',
                    required: false,
                    placeholder: 'MM/YY',
                    maxlength: 16,
                    style: {maxWidth:'200px'},
                    validation: {
                        rule: {
                            $or: [
                                {billTo: 'insurance'},
                                {billTo: 'client'},
                                {creditCardExpiry: {required: true}}
                            ]
                        },
                        message: 'Card expiry date is required when patient is selected for billing'
                    },
                },
                {
                    label: "CVV *",
                    name: 'creditCardCVV',
                    type: 'text',
                    pattern: '[0-9]{3}',
                    required: false,
                    maxlength: 3,
                    validation: {
                        rule: {
                            $or: [
                                {billTo: 'insurance'},
                                {billTo: 'client'},
                                {creditCardCVV: {required: true}}
                            ]
                        },
                        message: 'Card CVV is required when patient is selected for billing'
                    },
                    style: {maxWidth:'100px'}
                },
                {
                    label: 'Billing Country',
                    name: 'billingCountry',
                    type: 'select',
                    source: {
                        kind: 'list',
                        data: 'country'
                    },
                    required: false,
                    style: {maxWidth:'200px'},
                    // validation: {
                    //     rule: {
                    //         $or: [
                    //             {billTo: 'insurance'},
                    //             {billTo: 'client'},
                    //             {billingCountry: {required: true}}
                    //         ]
                    //     },
                    //     message: 'Billing Country is required when patient is selected for billing'
                    // },
                },
                {
                    label: 'State',
                    name: 'billingState',
                    type: 'select',
                    source: {
                        kind: 'lookup',
                        data: 'state'
                    },
                    required: false,
                    style: {maxWidth:'300px'},
                    // validation: {
                    //     rule: {
                    //         $or: [
                    //             {billTo: 'insurance'},
                    //             {billTo: 'client'},
                    //             {billingCountry: { $in: ['US', 'GB', 'NL']}},
                    //             {billingState: {required: true}}
                    //         ]
                    //     },
                    //     message: 'Billing State and Zip Code is required for selected country'
                    // }
                },
                {
                    label: 'Zip Code',
                    name: 'billingZipCode',
                    type: 'number',
                    required: false,
                    style: {maxWidth:'150px'},
                    maxlength: 5,
                    // validation: {
                    //     rule: {
                    //         $or: [
                    //             {billTo: 'insurance'},
                    //             {billTo: 'client'},
                    //             {billingCountry: { $in: ['US', 'GB', 'NL']}},
                    //             {billingZipCode: {required: true}}
                    //         ]
                    //     },
                    //     message: 'Billing State and Zip Code is required for selected country'
                    // }
                },
                {
                    label: 'Region',
                    name: 'billingRegion',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'300px'},
                    // validation: {
                    //     rule: {
                    //         $or: [
                    //             {billTo: 'insurance'},
                    //             {billTo: 'client'},
                    //             {billingCountry: { $nin: ['US', 'GB', 'NL']}},
                    //             {billingRegion: {required: true}}
                    //         ]
                    //     },
                    //     message: 'Billing Region and Postal Code is required for selected country'
                    // }
                },
                {
                    label: 'Postal Code',
                    name: 'billingPostalCode',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'300px'},
                    maxlength: 15,
                    // validation: {
                    //     rule: {
                    //         $or: [
                    //             {billTo: 'insurance'},
                    //             {billTo: 'client'},
                    //             {billingCountry: { $nin: ['US', 'GB', 'NL']}},
                    //             {billingPostalCode: {required: true}}
                    //         ]
                    //     },
                    //     message: 'Region and Postal Code is required for selected country'
                    // }
                },
                {
                    label: 'Billing Address',
                    name: 'billingAddress',
                    type: 'text',
                    required: false,
                    style: {maxWidth: '400px'},
                    maxlength: 30,
                    // validation: {
                    //     rule: {
                    //         $or: [
                    //             {billTo: 'insurance'},
                    //             {billTo: 'client'},
                    //             {billingAddress: {required: true}}
                    //         ]
                    //     },
                    //     message: 'Billing address is required when patient is selected for billing'
                    // },
                },
            ]
        },
        {
            label: 'Provider & Test Information (You must include Provider’s Name and Facility)',
            columns: 2,
            fields: [
                {
                    label: 'Ordering Provider\'s Full Name',
                    name: 'providerFullName',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'400px'},
                    maxlength: 100
                },
                {
                    label: 'NPI Registration Number',
                    name: 'providerNPINo',
                    type: 'text',
                    // pattern: '[0-9]{1,12}',
                    required: true,
                    style: {maxWidth:'200px'},
                    maxlength: 12
                },
                {
                    label: 'Provider\'s Facility',
                    name: 'providerFacility',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'400px'},
                    maxlength: 100
                },
                {
                    label: 'Phone Number',
                    name: 'providerPhoneNo',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'200px'},
                    maxlength: 15
                },
                {
                    label: 'Email',
                    name: 'providerEmail',
                    type: 'text',
                    pattern: '^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}$',
                    required: false,
                    style: {maxWidth:'400px'}
                },
                {
                    label: 'Address',
                    name: 'providerAddressLine1',
                    type: 'text',
                    required: false,
                    // validation: {
                    //     rule: {
                    //         $or: [{destination: 'patient'}, {providerAddressLine1: {required: true}}]
                    //     },
                    //     message: 'Please complete the address of the provider as they are the destination'
                    // },
                    style: {maxWidth:'400px'},
                    maxlength: 30
                },
                {
                    label: 'City',
                    name: 'providerCity',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'250px'}
                },
                {
                    label: 'State/Region',
                    name: 'providerState',
                    type: 'input-dropdown',
                    source: {
                        kind: 'lookup',
                        data: 'state'
                    },
                    required: false,
                    style: {maxWidth:'300px'}
                },
                {
                    label: 'Zip/Postal Code',
                    name: 'providerZipCode',
                    type: 'number',
                    required: false,
                    style: {maxWidth:'150px'}
                },
                {
                    label: 'Country',
                    name: 'providerCountry',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'200px'}
                },
                {
                    label: 'Provider Fax Number',
                    name: 'providerFaxNo',
                    type: 'text',
                    pattern: '^[\\d\\[\\]\\(\\)\\-+]+$',
                    required: false,
                    style: {maxWidth:'200px'},
                    maxlength: 15
                },
                {
                    label: 'Diagnosis/Symptoms (Provide ICD10 Code)',
                    name: 'providerDiagnostic',
                    type: 'text',
                    required: true,
                    style: {maxWidth:'400px'},
                    maxlength: 150
                },
                {
                    label: 'How do you want the report delivered?*',
                    type: "label"
                },
                {
                    label: 'Email',
                    name: 'providerEmailDelivery',
                    type: 'checkbox',
                },
                {
                    label: 'Fax',
                    name: 'providerFaxDelivery',
                    type: 'checkbox',
                },
                {
                    label: 'Note: When ordering tests which Medicare reimbursement will be sought, providers should only order tests that are medically necessary for the diagnosis or treatment of the patient. Medicare generally does not cover routine screening tests or those for research purposes only.',
                    name: 'providerLabel',
                    type: 'label',
                    required: false,
                    style: {maxWidth:'400px'}
                }
            ]
        },
        {
            label: 'Please indicate below the type of test(s) to be performed (Tick the Box)',
            columns: 2,
            fields: [
                {
                    label: '',
                    name: 'testTypeName',
                    type: 'product'
                },
                {
                    label: 'Blood Collection Date',
                    name: 'bloodCollectionDate',
                    type: 'date',
                    required: false,
                    style: {maxWidth: '400px'},
                    maxlength: 15
                }
            ]
        },
        {
            label: '',
            columns: 1,
            fields: [
                {
                    label: '',
                    name: 'questionName',
                    type: 'questions'
                }
            ]
        },
        {
            label: 'Please indicate below reason(s) for testing',
            columns: 2,
            fields: [
                {
                    label: 'Pre-Surgery',
                    name: 'isPreSurgery',
                    type: 'checkbox',
                },
                {
                    label: 'Post-Surgery',
                    name: 'isPostSurgery',
                    type: 'checkbox',
                },
                {
                    label: 'Months (if Post-Surgery)',
                    name: 'postSurgeryMonths',
                    type: 'text',
                    required: false,
                    style: {maxWidth:'50px'}
                },
                {
                    label: 'Monitoring (Watchful waiting)',
                    name: 'isMonitoringWaiting',
                    type: 'checkbox',
                },
                {
                    label: 'Monitoring (Somatostatin analog)',
                    name: 'isMonitoringAnalog',
                    type: 'checkbox',
                },
                {
                    label: 'Date of Last SSA Dose',
                    name: 'lastSSADate',
                    type: 'date',
                    required: false,
                    style: {maxWidth:'300px'},
                    max: "9999-12-31",
                },
                {
                    label: 'Symptoms (Disease Not Confirmed)',
                    name: 'isSymptomsNotConfirmed',
                    type: 'checkbox',
                },
                {
                    label: 'Monitoring (Other Therapy)',
                    name: 'isMonitoringOther',
                    type: 'checkbox',
                },
                {
                    label: 'Chemotherapy',
                    name: 'isChemo',
                    type: 'checkbox',
                },
                {
                    label: 'Target Therapy',
                    name: 'isTargetTherapy',
                    type: 'checkbox',
                },
                {
                    label: 'PRRT',
                    name: 'isPRRT',
                    type: 'checkbox',
                },
                {
                    label: 'Other',
                    name: 'isOther',
                    type: 'textbox',
                }
            ]
        }
    ],
    generateId: {
        name: 'sampleId',
        pattern: '(^[A-Z]{2}[0-9]{10}[A-Z]$)|(^[A-Z]{4}[0-9]{8}[A-Z]$)',
        notes: 'Should be 4 letters and 8 numbers along with the suffix of B',
        required: true,

    }
};

export default netestRequisitionFormDefinition;