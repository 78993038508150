import templateFormDefinition from "./template-form-definition";
import requisitionsConfigurationFormDefinition from "./requisitions-configuration-form-definition";

const requisitionsConfigFullDefinition = {
    key: 'id',
    table: {
        key: 'id',
        columns: [
            {
                label: '#',
                type: 'rowNumber',
                filterType: 'none',
            },
            {
                label: 'Test Group',
                field: 'configName',
                type: 'text',
                filterType: 'equals',
                sortType: 'none',
            },
            {
                label: 'Created At',
                field: 'createdTimestamp',
                type: 'timestamp',
                format: 'toLocalizedDateString',
            },
    
            {
                label: 'Updated At',
                field: 'updatedTimestamp',
                type: 'timestamp',
                format: 'toLocalizedDateString',
            },
    
            {
                label: 'Published At',
                field: 'publishedTimestamp',
                type: 'timestamp',
                format: 'toLocalizedDateString',
                sortType: 'none',
            },
          
           
        ],
        defaultSort : [
            { createdTimestamp : 'asc' }
        ]
    },

    form: requisitionsConfigurationFormDefinition,
    // innerForm: pcrInnerFormDefinition,

    endpoints : {
        search: {
            uri: '/api/requisitions-configuration?',
            verb: 'get'
        },
        publish: {
            uri: '/api/requisitions-configuration/:id',
            verb: 'put'
        },
    },
    pageSize: 10
};

export default requisitionsConfigFullDefinition;
