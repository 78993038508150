import React, { Component, useEffect, useState } from 'react';
import fetch from 'isomorphic-fetch';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { MultiSelect } from "react-multi-select-component";
import { makeApiCall } from "../api/generic-api";


const UserForm = props => {
    let newUser = props && props.user && !props.user.username;
    let user = JSON.parse(JSON.stringify(props.user));
    if (newUser) {
        user = {
            username: '',
            firstName: '',
            lastName: '',
            permission: props.administrator ? 'administrator' : 'standard',
            roles: [],
        };
    }
    
    const [ userRoles, setUserRoles ] = useState([]);
    const [ state, setState ] = useState({
        user: user,
        newUser: newUser,
        previousUser: JSON.parse(JSON.stringify(props.user))
    })
    
    
    const [selected, setSelected] = useState(() => {
        const userRoles = user.roles;
        const selectedRoles = [];
        userRoles && userRoles.length && userRoles.map(option => {
            selectedRoles.push({
                label: option,
                value: option,
            });
        });

        return selectedRoles;
    });
    
    
    useEffect(() => {
        getUserRoles()
    }, []);
    
    const getUserRoles = async () => {
        const userRoles = await makeApiCall('get', '/api/user-roles');
        setUserRoles(userRoles.body);
    }
    
    const saveForm = (e) =>  {
        e.persist();
        let user =  JSON.parse(JSON.stringify(state.user));
        
        if(user && user.username) {
            let url = '/api/user/' + encodeURIComponent(user.username);
            user.roles = selected.map(role => role.value)
            
            let body = JSON.stringify(user);
            let method = (state.newUser ? 'post' :'put');
            fetch(
              url,
              {
                  credentials: 'include',
                  method: method,
                  headers: {
                      'Content-Type': 'application/json'
                  },
                  body: body
              })
              .then(function(response) {
                  if (response.ok) {
                      setState(prevState => ({
                          ...prevState,
                          user: user,
                          previousUser: JSON.parse(JSON.stringify(user))
                      }));
                      
                      props.done(user, state.newUser);
                      return response.status;
                  }
                  throw new Error('/api/user call failed, status: ' + response.status);
              })
              .catch((reason) => {
                  props.setError('Could not save the changes');
              });
        }
    }
    
    const cancel = () => {
        let user = JSON.parse(JSON.stringify(state.previousUser));
        setState(prevState => ({
            ...prevState,
            user: user
        }));
        
        props.done(null);
    }
    
    const handleUsernameChange = (e) => {
        e.persist();
        let user = JSON.parse(JSON.stringify(state.user));
        user.username = e.target.value;
        setState(prevState => ({
            ...prevState,
            user: user
        }));
    }
    
    const handleFirstNameChange = (e) => {
        e.persist();
        let user = JSON.parse(JSON.stringify(state.user));
        user.firstName = e.target.value;
        setState(prevState => ({
            ...prevState,
            user: user
        }));
    }
    
    const handleLastNameChange = (e) => {
        e.persist();
        let user = JSON.parse(JSON.stringify(state.user));
        user.lastName = e.target.value;
        setState(prevState => ({
            ...prevState,
            user: user
        }));
    }
    
    
    return (
      <div hidden={props.hidden} >
          <Form onSubmit={saveForm}  >
              <FormGroup>
                  <Label for="formUsername">Email</Label>
                  <Input
                    type="email"
                    name="username"
                    id="formUsername"
                    value={state.user.username}
                    onChange={handleUsernameChange}
                    disabled={!state.newUser}
                  />
              </FormGroup>
              <FormGroup>
                  <Label for="formFirstName">First Name</Label>
                  <Input
                    type="text"
                    name="firstname"
                    id="formFirstName"
                    value={state.user.firstName}
                    onChange={handleFirstNameChange}
                  />
              </FormGroup>
              <FormGroup>
                  <Label for="formLastName">Last Name</Label>
                  <Input
                    type="text"
                    name="lastname"
                    id="formLastName"
                    value={state.user.lastName}
                    onChange={handleLastNameChange}
                  />
              </FormGroup>
              {/*{!props.administrator ?*/}
                  <FormGroup>
                      <Label for="formLastName">Roles</Label>
                      {!props.administrator ?
                          <MultiSelect
                              isLoading={!(userRoles && userRoles.length)}
                              defaultIsOpen={true}
                              options={() => {
                                  const customOptions = [];
                                  userRoles && userRoles.length && userRoles.map(option => {
                                      customOptions.push({
                                          label: option,
                                          value: option,
                                      });
                                  });
                                  return customOptions;
                              }}
                              value={selected}
                              onChange={(e) => {
                                  setSelected(e);
                              }}
                              labelledBy="Select"
                              hasSelectAll={false}
                          />
                          :
                          <MultiSelect
                              isLoading={false}
                              defaultIsOpen={true}
                              options={() => {
                                  const customOptions = [{ label: "Laboratory Director", value: "laboratoryDirector"}];
                                  return customOptions;
                              }}
                              value={selected}
                              onChange={(e) => {
                                  setSelected(e);
                              }}
                              labelledBy="Select"
                              hasSelectAll={false}
                          />
                      }
                  </FormGroup>
                  {/*: null*/}
              {/*}*/}
              
              <div className="float-right">
                  <Button
                    type="button"
                    outline color="primary"
                    style={{marginRight:'20px'}}
                    onClick={(e) => saveForm(e)}
                    disabled={!state.user || !state.user.username || !state.user.firstName
                      || !state.user.lastName}>Save</Button>
                  <Button
                    type="button"
                    outline color="secondary"
                    onClick={() => {cancel();}}>Cancel</Button>
              </div>
          </Form>
      </div>
    );
};

export default UserForm;