import FileSaver from 'file-saver';
import { isFunction } from '../utility/client-utility';

function csvDownloader(records, columnFields, getFieldValue) {

    if(!records || records.length === 0) {
        console.warn('No data to download');
        return;
    }
    if(!columnFields) {
        console.error('Table column fields not provided to csv downloader');
        return;
    }
    if(!getFieldValue && !isFunction(getFieldValue)) {
        console.error('GetFieldValue function not provided for csv downloader');
        return;
    }

    let csv = '';
    columnFields.map((columnField, i) => {
        if (csv !== '') {
            csv += ',';
        }
        csv += '"' + columnField.label + '"';
    });

    csv += '\n';

    records.map((record, i) => {
        let newRow = true;
        columnFields.map((columnField, j) => {
            if (!newRow) {
                csv += ',';
            } else {
                newRow = false;
            }
            csv += '"' + getFieldValue(record, columnField) + '"';
        });
        csv += '\n';
    });
    //console.log(csv);
    let blob = new Blob([csv], {type: 'text/csv;charset=utf-8'});
    FileSaver.saveAs(blob, 'download.csv');
}

export const csvDownload = ({ data, columns, title }) => {
  const filterColumns = columns.filter((item) => !item.skipCsv)

  if (!data || data.length === 0) {
    console.warn("No data to download")
    return
  }

  if (!columns) {
    console.error("Table column fields not provided to csv downloader")
    return
  }

  let csv = ""
  filterColumns.forEach((column) => {
    if (csv !== "") {
      csv += ","
    }
    csv += '"' + column.Header + '"'
  })

  csv += "\n"

  data.map((record) => {
    let newRow = true
    filterColumns.forEach((column) => {
      if (!newRow) {
        csv += ","
      } else {
        newRow = false
      }

      const accessor = column.csvAccessor || column.accessor
      const value = accessor.split(".").reduce((acc, key) => (acc && acc[key] !== undefined && acc[key] !== null ? acc[key] : ""), record)

      csv += '"' + value + '"'
    })

    csv += "\n"
  })

  const blob = new Blob([csv], { type: "text/csv;charset=utf-8" })
  const date = new Date()

  FileSaver.saveAs(blob, `${date.toLocaleDateString()}-${title || "download"}.csv`)
}

export default csvDownloader;
