import React, { useState, useEffect } from "react"
import { Button, Label, Row, Col, Dropdown, DropdownToggle, Spinner, DropdownMenu, DropdownItem, Alert } from "reactstrap"
import { useProductFormSubmit } from "api/getters"
import * as Yup from "yup"
import { useForm, Controller } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { makeApiCall } from "api/generic-api"
import lookups from "../../configuration/lookups"

const schema = Yup.object().shape({
  productCode: Yup.string().required("Product Code is required"),
  productName: Yup.string().required("Product Name is required"),
  testType: Yup.array().required("Test Type is required"),
  status: Yup.string().required("Status is required"),
  productGroup: Yup.string().required("Product Group is required"),
})

const ProductForm = () => {
  const [dropdownOpen, setDropdownOpen] = useState({
    status: false,
    extrasConfiguration: false,
    testType: false,
    productGroup: false,
  })
  const [selected, setSelected] = useState({
    status: "",
    testTypes: [],
    extrasConfiguration: [],
    productGroup: "",
  })
  const [messages, setMessages] = useState({ success: "", error: "" })
  const [configurations, setConfigurations] = useState({
    test: [],
    extras: [],
  })
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitted, setIsSubmitted] = useState(false)

  const {
    control,
    handleSubmit,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onSubmit",
  })

  useEffect(() => {
    const fetchConfigurations = async () => {
      setIsLoading(true)
      try {
        const [testResult, extrasResult] = await Promise.all([makeApiCall("get", "/api/test-configuration"), makeApiCall("get", "/api/extras-products")])
        setConfigurations({
          test: testResult.body,
          extras: extrasResult.body,
        })
      } catch (error) {
        const errorMessage = error.response?.data?.error || "Failed to fetch configurations"
        setMessages((prev) => ({ ...prev, error: errorMessage }))
      } finally {
        setIsLoading(false)
      }
    }

    fetchConfigurations()
  }, [])

  const { mutate, isLoading: mutating } = useProductFormSubmit({
    onSuccess: () => {
      setMessages((prev) => ({ ...prev, success: "Product has been successfully added." }))
      setIsSubmitted(true)
    },
    onError: (error) => {
      const apiErrorMessage = error?.response?.data?.error || "An error occurred"
      setMessages((prev) => ({ ...prev, error: apiErrorMessage }))
    },
  })

  const toggleDropdown = (type) => {
    setDropdownOpen((prev) => ({ ...prev, [type]: !prev[type] }))
  }

  const handleSelectionChange = (type, value) => {
    setSelected((prev) => {
      const newSelection = Array.isArray(prev[type]) ? (prev[type].includes(value) ? prev[type].filter((v) => v !== value) : [...prev[type], value]) : value

      if (type === "status" && errors.status) {
        clearErrors("status")
      }
      if (type === "productGroup" && errors.productGroup) {
        clearErrors("productGroup")
      }

      setValue(type === "testTypes" ? "testType" : type, newSelection)
      return { ...prev, [type]: newSelection }
    })
  }

  const onSubmit = async (data) => {
    const formValues = {
      id: data.productCode,
      productName: data.productName,
      testTypeData: selected.testTypes.map((type) => ({
        label: type,
        value: type.charAt(0).toUpperCase(),
      })),
      extrasConfiguration: selected.extrasConfiguration.map((type) => ({
        label: type,
        value: type.charAt(0).toUpperCase(),
      })),
      status: selected.status.toLowerCase(),
      productGroup: selected.productGroup.toLowerCase() || null,
    }

    await mutate(formValues)
  }

  const getDropdownToggleText = (type, placeholder) => (selected[type]?.length > 0 ? selected[type].join(", ") : placeholder)

  const testTypesArray = Array.from(new Set(configurations.test.map((item) => item.name)))
  const extrasArray = Array.from(new Set(configurations.extras.flatMap((item) => item.extraName || [])))

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <Spinner size="sm" />
      </div>
    )
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {messages.success && <Alert color="success">{messages.success}</Alert>}
      {messages.error && <Alert color="danger">{messages.error}</Alert>}

      <Row>
        <Col lg="6" className="mb-3">
          <Label className="form-label">Product Code *</Label>
          <Controller
            name="productCode"
            control={control}
            render={({ field }) => (
              <input
                placeholder="Enter Product Code"
                {...field}
                className={`form-control ${errors.productCode ? "is-invalid" : ""}`}
                style={{ borderColor: errors.productCode ? "red" : "#ced4da" }}
              />
            )}
          />
          {errors.productCode && <div className="invalid-feedback">{errors.productCode.message}</div>}
        </Col>

        <Col lg="6" className="mb-3">
          <Label className="form-label">Extras Configuration</Label>
          <Dropdown isOpen={dropdownOpen.extrasConfiguration} toggle={() => toggleDropdown("extrasConfiguration")}>
            <DropdownToggle
              caret
              className={`form-control text-start px-3 ${errors.extrasConfiguration ? "is-invalid" : ""}`}
              style={{
                backgroundColor: "white",
                color: "black",
                borderColor: errors.extrasConfiguration ? "red" : "#ced4da",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{getDropdownToggleText("extrasConfiguration", "Select Extras Configuration")}</span>
              <span className="dropdown-caret" style={{ marginLeft: "auto" }}>
                <i className="caret" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="mt-2">
              {extrasArray.map((extra) => (
                <DropdownItem key={extra} onClick={() => handleSelectionChange("extrasConfiguration", extra)}>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" checked={selected.extrasConfiguration.includes(extra)} readOnly />
                    <label className="form-check-label" style={{ color: "black" }}>
                      {extra}
                    </label>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {errors.extrasConfiguration && <div className="invalid-feedback">{errors.extrasConfiguration.message}</div>}
        </Col>

        <Col lg="6" className="mb-3">
          <Label className="form-label">Product Name *</Label>
          <Controller
            name="productName"
            control={control}
            render={({ field }) => (
              <input
                placeholder="Enter Product Name"
                {...field}
                className={`form-control ${errors.productName ? "is-invalid" : ""}`}
                style={{ borderColor: errors.productName ? "red" : "#ced4da" }}
              />
            )}
          />
          {errors.productName && <div className="invalid-feedback">{errors.productName.message}</div>}
        </Col>

        <Col className="mb-3" lg="6">
          <Label className="form-label">Status *</Label>
          <Dropdown isOpen={dropdownOpen.status} toggle={() => toggleDropdown("status")}>
            <DropdownToggle
              caret={!errors.status}
              className={`form-control text-start px-3 ${errors.status ? "is-invalid" : ""}`}
              style={{
                backgroundColor: "white",
                borderColor: errors.status ? "red" : "#ced4da",
                color: "black",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{selected.status || "Select Status"}</span>
              <span className="dropdown-caret" style={{ marginLeft: "auto" }}>
                <i className="caret" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="mt-2" style={{ backgroundColor: "white" }}>
              {lookups
                .filter((item) => item.type === "productStatus")
                .map((item) => (
                  <DropdownItem key={item._id} onClick={() => handleSelectionChange("status", item.value)}>
                    {item.value}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
          {errors.status && (
            <div className="mt-1 text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.status.message}
            </div>
          )}
        </Col>

        <Col className="mb-3" lg="6">
          <Label className="form-label">Test Type *</Label>
          <Dropdown isOpen={dropdownOpen.testType} toggle={() => toggleDropdown("testType")}>
            <DropdownToggle
              caret={!errors.testType}
              className={`form-control text-start px-3 ${errors.testType ? "is-invalid" : ""}`}
              style={{
                backgroundColor: "white",
                borderColor: errors.testType ? "red" : "#ced4da",
                color: "black",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{getDropdownToggleText("testTypes", "Select Test Type")}</span>
              <span className="dropdown-caret" style={{ marginLeft: "auto" }}>
                <i className="caret" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="mt-2" style={{ backgroundColor: "white" }}>
              {testTypesArray.map((type) => (
                <DropdownItem key={type} onClick={() => handleSelectionChange("testTypes", type)}>
                  <div className="form-check">
                    <input type="checkbox" className="form-check-input" checked={selected.testTypes.includes(type)} readOnly />
                    <label className="form-check-label" style={{ color: "black" }}>
                      {type}
                    </label>
                  </div>
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
          {errors.testType && (
            <div className="mt-1 text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.testType.message}
            </div>
          )}
        </Col>

        <Col className="mb-3" lg="6">
          <Label className="form-label">Product Group *</Label>
          <Dropdown isOpen={dropdownOpen.productGroup} toggle={() => toggleDropdown("productGroup")}>
            <DropdownToggle
              caret={!errors.productGroup}
              className={`form-control text-start px-3 ${errors.productGroup ? "is-invalid" : ""}`}
              style={{
                backgroundColor: "white",
                borderColor: errors.productGroup ? "red" : "#ced4da",
                color: "black",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span>{selected.productGroup || "Select Product Group"}</span>
              <span className="dropdown-caret" style={{ marginLeft: "auto" }}>
                <i className="caret" />
              </span>
            </DropdownToggle>
            <DropdownMenu className="mt-2" style={{ backgroundColor: "white" }}>
              {lookups
                .filter((item) => item.type === "productGroup")
                .map((item) => (
                  <DropdownItem key={item._id} onClick={() => handleSelectionChange("productGroup", item.value)}>
                    {item.value}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </Dropdown>
          {errors.productGroup && (
            <div className="mt-1 text-danger" style={{ fontSize: "0.875rem" }}>
              {errors.productGroup.message}
            </div>
          )}
        </Col>

        <Col className="text-end">
          <Button color="primary" type="submit" disabled={isSubmitted}>
            {mutating ? <Spinner size="sm" /> : "Submit"}
          </Button>
        </Col>
      </Row>
    </form>
  )
}

export default ProductForm
