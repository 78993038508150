import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useInvoiceQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  console.log("Variables in useInvoiceQuery:", variables)

  const fields = [
    "_id",
    "labId",
    "lastName",
    "firstName",
    "dateOfBirth",
    "subjectId",
    "testId",
    "loincId",
    "sampleCollectionDateTime",
    "laboratoryCompletedDateTime",
  ]
  const fieldsParam = encodeURIComponent(JSON.stringify(fields))

  const fixedSelector = {
    insured: false,
    testId: {
      $or: [{ $regex: "JLBG" }, { $regex: "CT" }],
    },
  }

  const newVariables = {
    ...variables,
    selector: { ...fixedSelector, ...variables.selector },
  }

  return query(["invoices", newVariables], () => axiosClient.get(`/invoices?fields=${fieldsParam}&${paramsToQuery(newVariables)}`).then((res) => res.data), {
    ...rest,
  })
}

export function useConfigurationQuery({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["configuration", variables], () => axiosClient.get(`/configuration?${paramsToQuery(variables)}`).then((res) => res.data), { ...rest })
}
