import { axiosClient, useRequestProcessor } from "api/provider"
import { paramsToQuery } from "utils/query"

export function useInsuranceList({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  const newVariables = {
    ...variables,
    selector: {
      ...(variables?.selector || {}),
    },
  }

  if (typeof newVariables?.selector?.name === "string") {
    newVariables.selector.name = {
      $regex: "(?i)" + newVariables.selector.name,
    }
  }

  if (newVariables?.selector?._id) {
    newVariables.selector._id = {
      $regex: "(?i)" + newVariables.selector._id,
    }
  }

  return query(["insuranceListQueries", variables], () => axiosClient.get(`/insurance-companies?${paramsToQuery(newVariables)}`).then((res) => res.data), {
    ...rest,
  })
}
export function useInsuranceCompanyFormSubmit({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("submit-InsuranceCompany", (update) => axiosClient.post(`insurance-company`, update).then((res) => res.data), { ...rest })
}
export function useInsuranceInfo({ variables, ...rest }) {
  const { query } = useRequestProcessor()

  return query(["InsuranceInfoQueries", variables], () => axiosClient.get(`/insurance-companies?${paramsToQuery(variables)}`).then((res) => res.data), {
    ...rest,
  })
}

export function useInsuranceCompanyDetailSubmit({ ...rest } = {}) {
  const { mutate } = useRequestProcessor()

  return mutate("submit-Detail-insurance-company", (update) => axiosClient.put(`insurance-company`, update).then((res) => res.data), { ...rest })
}
