import React, { useEffect, useState } from "react"

import lookups from "../configuration/lookups"
import { makeApiCall } from "../api/generic-api"
import trayFullDefinition from "../configuration/tray-full-definition"
import { Card, CardBody, Col, Row } from "reactstrap"
import Breadcrumb from "./custom/Breadcrumb"
import TrayDetails from "./custom/TrayDetails"
import { useParams } from "react-router"
import { usePreviousTableQuery } from "hooks/query"

function ProcessTrayInfoView(props) {
  const { id } = useParams()
  const structure = trayFullDefinition
  const previousQuery = usePreviousTableQuery("/process-tray")

  const breadcrumbItems = [
    {
      label: "Trays",
      link: `/process-tray?${previousQuery}`,
    },
    {
      label: id,
      link: `/process-tray/${id}`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title={id} breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <TrayDetails id={id} structure={structure} lookups={lookups} loadData={makeApiCall} {...props} />
        </CardBody>
      </Card>
    </div>
  )
}

export default ProcessTrayInfoView
