import fetch from 'isomorphic-fetch';
import {dataToQuery} from "../utility/uri-utility";

const searchTest = async (testId) => {
    console.log('search test ' + testId);
    return fetch(
        '/api/accession/test/' + encodeURIComponent(testId.toUpperCase()),
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if(response.ok) {
                return response.json();
            } else if (response.status === 404) {
                return { searchStatus: 'notFound', searchResult: {} };
            } else {
                throw new Error('Error in streamline submission ' + response.status);
            }
        })
        .catch((reason) => {
            console.log('caught an error in streamline form call');
            console.error(reason);
            return { searchStatus: 'error' };
        });
};


const searchExistingSubjects = async ( query) => {
  console.log('query', query);
  return fetch(
    '/api/subjects/existing' + dataToQuery(query),
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then((response) => {
      if(response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong fetching existing subjects ' + response.status);
      }
    })
    .catch((err) => {
      return { searchStatus: 'error' };
    });
};


const processAccessionStreamline = async (streamlineId, status, subjectId) => {
    const url = '/api/accession/streamline/' + encodeURIComponent(streamlineId) + '/status/' + encodeURIComponent(status);
    return fetch(url,
        {
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'put',
            body: JSON.stringify({subjectId: subjectId})
        }
    )
        .then((response) => {
            console.log('got accession response');
            console.log(response);
            if (response.ok) {
                console.log('accession ok');
                return { searchStatus: 'success' };
            } else {
                console.log('accession response not ok');
                return response.json();
            }
        })
        .catch((error) => {
            console.log('accession error');
            console.log(error);
            return { searchStatus: 'error' };
        });
};
//HHCC00654325S
const processRegisteredTest = async (testId, status) => {
    const url = '/api/test/receive';
    return fetch(url,
        {
            credentials: 'include',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            method: 'post',
            body: JSON.stringify({testId: testId.toUpperCase(), status: status, primaryLaboratory: true })
        }
    )
        .then((response) => {
            console.log('got test accessionresponse');
            console.log(response);
            if (response.ok) {
                console.log('test accession ok');
                return { searchStatus: 'success' };
            } else {
                console.log('test accession response not ok');
                throw 'Accession error';
            }
        })
        .catch((error) => {
            console.log('test accession error');
            console.log(error);
            return { searchStatus: 'error' };
        });
};

const searchStreamline = async (query) => {
    // TODO maybe convert to new streamline search endpoint
    const url = '/api/accession/streamline' + dataToQuery(query);
    console.log('search streamline');
    return fetch(
        url,
        {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((response) => {
            if(response.ok) {
                return response.json();
            } else if (response.status === 404) {
                return { searchStatus: 'notFound', searchResult: [] };
            } else {
                throw new Error('Error in streamline submission ' + response.status);
            }
        })
        .catch((reason) => {
            console.log('caught an error in streamline form call');
            console.error(reason);
            return { searchStatus: 'error' };
        });
};

export {
    searchTest,
    processAccessionStreamline,
    processRegisteredTest,
    searchStreamline,
  searchExistingSubjects
};
//serviceInput.configuration.primaryLaboratoryId
