import React, { Component, useEffect, useState } from 'react';
import fetch from 'isomorphic-fetch';
import {
    Table, Container, Row, Col,
    FormGroup, Label, Input,
    ButtonGroup, Button, TabContent, TabPane,
    Nav, NavItem, NavLink,
    Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';
import classnames from 'classnames';
import TestConfigForm from "./test-configuration-form";


const TestConfigurationView = props => {
    const [ state, setState ] = useState({
        testConfigurations: [],
        deletingStandardUsers: [],
        administrators: [],
        deletingAdministrators: [],
        selectedConfig: null,
        selectedAdministrator: null,
        activeTab: 'standardUserTab',
        confirmingToggleSuspend: false,
        confirmingToggleDelete: false,
        confirmingToggleEmailPassword: false,
        editingTestType: false,
        existingSuffixes: [],
    });

    const [isOpenForm, setIsOpenForm] = useState(false);

    const newTestType = () => {
        setState(prevState => ({
            ...prevState, selectedConfig: null, editingTestType: false
        }));
        setIsOpenForm(true);
    };

    const editTestType = async (config) => {
        setState(prevState => ({
            ...prevState, selectedConfig: config, editingTestType: true
        }));
        setIsOpenForm(true);
    };

    const testConfigFormDone = ( user, newUser ) => {
        if (user && (user._id || user.id)) {
            let found = false;
            let testConfigurations = state.testConfigurations;
            for (let i = 0; i < state.testConfigurations.length; i++) {
                if (state.testConfigurations[i].id === (user._id || user.id)) {
                    testConfigurations[i] = user;
                    setState(prevState => ({
                        ...prevState, testConfigurations: testConfigurations, editingTestType: false
                    }));
                    found = true;
                }
            }
            if (!found) {
                testConfigurations.push(user);
                setState(prevState => ({
                    ...prevState, testConfigurations: testConfigurations, editingTestType: false
                }));
            }
        } else {
            setState(prevState => ({
                ...prevState, editingTestType: false
            }));
        }

        refresh();
    }

    const getIds = () => {
        fetch('/api/test-configuration/ids', { credentials: 'include'})
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('/api/test-configuration/ids call failed, status: ' + response.status);
            })
            .then(result => {
                console.log('result', result);
                setState(prevState => ({
                    ...prevState,
                    existingSuffixes: result
                }));
            })
    };

    const refresh = () => {
        fetch('/api/test-configuration', { method: 'get', credentials: 'include' })
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('/api/test-configuration call failed, status: ' + response.status);
            })
            .then(users => {
                const testConfigList = users.filter(user => user.permission !== 'administrator');
                console.log('testConfig', testConfigList);
                getIds();
                setState(prevState => ({
                    ...prevState,
                    testConfigurations: testConfigList,
                    selectedConfig: null,
                }));
            })
            .catch(( reason ) => {
                props.setError('Failed to retrieve users');
            });
    };

    useEffect(() => {
        refresh();
    }, []);


    return (
        <div className="TestConfigurationView">
            <div className="wide-container">
                <Row style={{marginTop: '30px'}}>
                    <Col sm="3">
                    </Col>
                    <Col sm="6" className="text-center">
                        <h3>Test Configuration</h3>
                    </Col>
                    <Col sm="3">
                    </Col>
                </Row>
                <Row>
                    <Col sm="12">
                        { isOpenForm === true ?
                            <TestConfigForm
                                refresh={refresh}
                                done={testConfigFormDone}
                                config={Object.assign({}, state.selectedConfig)}
                                setError={props.setError} {...props}
                                existingIds={state.existingSuffixes}
                                editingTestType={state.editingTestType}
                                setIsOpenForm={setIsOpenForm}
                            />
                            : <Table responsive hover hidden={state.editingTestType}>
                                <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Test Type</th>
                                    <th>Test Configuration</th>
                                    <th>Action</th>
                                    <th>
                                        <Button
                                            outline color="success"
                                            onClick={() => {newTestType(); }}>
                                            <span className="fa fa-plus"></span> New Test Type</Button></th>
                                </tr>
                                </thead>
                                <tbody>
                                {state.testConfigurations.map((config, i) =>
                                    <tr key={(config._id || config.id)}>
                                        <td>{i + 1}</td>
                                        <td>{config._id || config.id}</td>
                                        <td>{config.name}</td>
                                        <td>
                                            <Button
                                                outline color="primary"
                                                onClick={() => {editTestType(config); }}>
                                                <span className="fa fa-pencil"></span> Edit
                                            </Button>
                                        </td>
                                        <td></td>
                                    </tr>
                                )}
                                </tbody>
                            </Table>
                        }
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default TestConfigurationView;
