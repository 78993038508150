import React from "react"
import Breadcrumb from "./custom/Breadcrumb"
import { Card, CardBody } from "reactstrap"
import { UnfilledOrdersTable } from "./tables/UnfilledOrdersTable"

function unfilledOrderNew() {
  const breadcrumbItems = [
    {
      label: "UnfilledOrders",
      link: `/unfilled-orders`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Unfilled Orders" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>
          <UnfilledOrdersTable allowPageSize allowPaginate isGlobalFilter />
        </CardBody>
      </Card>
    </div>
  )
}

export default unfilledOrderNew
