import React, { useCallback, useMemo } from "react"
import { useTestsQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import TableContainer from "./TableContainer"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
dayjs.extend(utc)

export function TestsTable({ pageSize: pageSizeProp, customSelect = {}, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector: {
      ...selector,
      ...customSelect,
    },
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: testsData,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useTestsQuery({
    variables: queryVariables,
  })

  const columns = useMemo(
    () => [
      {
        Header: "Test ID",
        accessor: "_id",
        hasSort: true,
        csvAccessor: "csvId",
      },
      {
        Header: "Test Type",
        accessor: "testTypeName",
        hasSort: true,
      },
      {
        Header: "Patient ID",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Collected",
        accessor: "sampleCollectionDateTime",
        hasSort: true,
      },
      {
        Header: "Accessioned",
        accessor: "laboratoryReceivedDateTime",
        hasSort: true,
      },
      // {
      //   Header: "Analysed",
      //   accessor: "laboratoryCompletedDateTime",
      //   hasSort: true,
      // },
      {
        Header: "Reported",
        accessor: "reportingDateTime",
        hasSort: true,
      },
      {
        Header: "Insured",
        accessor: "insured",
      },
      {
        Header: "Result",
        accessor: "testResult",
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const getProcessedTableData = useCallback(
    (_testsData) =>
      (_testsData &&
        _testsData?.map((item) => ({
          // _id: (
          //   <Link to={`/cancer-tests-view/${item.id || ""}`} className="text-secondary">
          //     {item.id}
          //   </Link>
          // ),
          _id: item.id,
          testTypeName: item?.testTypeName || item?.testType.toUpperCase(),
          subjectId: (
            <a href={`/subject-info/${item.subjectId}/personal`} className="text-secondary" target="_blank" rel="noopener noreferrer">
              {item.subjectId}
            </a>
          ),
          csvId: item.id,
          csvSubjectId: item.subjectId,
          testType: item?.testTypeName || item?.testType.toUpperCase(),
          tubeType: item?.tubeType?.toUpperCase(),
          sampleCollectionDateTime: item?.sampleCollectionDateTime && dayjs(item?.sampleCollectionDateTime).format("MM/DD/YYYY"),
          laboratoryReceivedDateTime: item?.laboratoryReceivedDateTime && dayjs(item?.laboratoryReceivedDateTime).format("MM/DD/YYYY"),
          laboratoryCompletedDateTime: item?.laboratoryCompletedDateTime && dayjs(item?.laboratoryCompletedDateTime).local().format("MM/DD/YYYY h:mm A"),
          reportingDateTime: item?.laboratoryCompletedDateTime && dayjs(item?.laboratoryCompletedDateTime).local().format("MM/DD/YYYY h:mm A"),
          insured: item?.patientInsured === true || item?.insured === true ? "Yes" : item?.patientInsured === false || item?.insured === false ? "No" : "No",
          testResult: (typeof item?.testResult === "object" ? item?.testResult?.scoreCategory : item?.testResult || "-")?.toUpperCase(),
          action: (
            <Link to={`/cancer-tests-view/${item?.id || ""}`} className="btn btn-outline-primary">
              View
            </Link>
          ),
        }))) ||
      [],
    [],
  )

  const data = useMemo(() => getProcessedTableData(testsData), [testsData, getProcessedTableData])

  //meta title
  // document.title = "Data Tables | Skote - React Admin & Dashboard Template";

  const filters = useMemo(() => {
    return [
      {
        field: "_id",
        operator: "$eq",
        label: "Search ID",
        placeholder: "Type in ID",
        removeSpaces: true,
      },
      {
        field: "subjectId",
        operator: "$eq",
        label: "Patient ID",
        placeholder: "Type in Patient ID",
        removeSpaces: true,
      },
    ]
  }, [])

  return (
    <div>
      <TableContainer
        downloadFileName="Cancer Tests"
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="sampleCollectionDateTime"
        defaultSortDir="desc"
        csvQuery={useTestsQuery}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
    </div>
  )
}
