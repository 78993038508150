import React, { useMemo, useState, useEffect, useCallback } from "react"
import { useInvoiceQuery } from "api/getters"
import { useConfigurationQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"

dayjs.extend(utc)

export function InvoiceTable({ pageSize: pageSizeProp, ...rest }) {
  const [loincId, setLoincId] = useState("")
  const [labId, setLabId] = useState("")

  const { data: configuration } = useConfigurationQuery({
    variables: {},
  })

  useEffect(() => {
    if (configuration) {
      setLoincId(configuration.communityConfiguration?.loincId || "")
      setLabId(configuration.communityConfiguration?.laboratoryId || "")
    }
  }, [configuration])

  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector,
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: invoicesData,
    isLoading,
    isFetching,
    refetch,
    error,
  } = useInvoiceQuery({
    variables: queryVariables,
  })

  const columns = useMemo(
    () => [
      {
        Header: "Laboratory Identified",
        accessor: "labId",
      },
      {
        Header: "Patient Last Name",
        accessor: "lastName",
        hasSort: true,
      },
      {
        Header: "Patient First Name",
        accessor: "firstName",
        hasSort: true,
      },
      {
        Header: "Date of Birth",
        accessor: "dateOfBirth",
        hasSort: true,
      },
      {
        Header: "Client Number",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Specimen ID",
        accessor: "id",
        hasSort: true,
      },
      {
        Header: "LOINC",
        accessor: "loincId",
      },
      {
        Header: "Test Location",
        accessor: "testId",
        hasSort: true,
      },
      {
        Header: "Specimen Collection Date",
        accessor: "sampleCollectionDateTime",
        hasSort: true,
      },
      {
        Header: "Tested Date",
        accessor: "laboratoryCompletedDateTime",
        hasSort: true,
      },
    ],
    [],
  )

  const getProcessedTableData = useCallback(
    (_invoicesData) =>
      (_invoicesData &&
        _invoicesData.map((item) => ({
          labId: labId,
          lastName: item?.lastName,
          firstName: item?.firstName,
          dateOfBirth: item?.dateOfBirth,
          subjectId: (
            <Link to={`/subject-info/${item.subjectId || ""}/personal`} className="text-secondary" target="_blank">
              {item.subjectId}
            </Link>
          ),
          csvSubjectId: item.subjectId,
          id: item?.testId || "",
          loincId: loincId,
          testId: item?.testId,
          sampleCollectionDateTime: item?.sampleCollectionDateTime && dayjs(item?.sampleCollectionDateTime).local().format("MM/DD/YYYY"),
          laboratoryCompletedDateTime: item?.laboratoryCompletedDateTime && dayjs(item?.laboratoryCompletedDateTime).local().format("MM/DD/YYYY"),
        }))) ||
      [],
    [loincId, labId],
  )

  const data = useMemo(() => getProcessedTableData(invoicesData), [invoicesData, getProcessedTableData])

  const filters = useMemo(
    () => [
      {
        sectionTitle: "Specimen Collection Date",
        field: "sampleCollectionDateTime",
        type: "date",
        operator: "$gte",
        label: "Start Date",
        placeholder: "Select Date Range",
      },
      {
        sectionTitle: " ",
        field: "sampleCollectionDateTime",
        type: "date",
        operator: "$lte",
        label: "End Date",
        placeholder: "Select Date Range",
      },
    ],
    [],
  )

  return (
    <div>
      <TableContainer
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="sampleCollectionDateTime"
        defaultSortDir="desc"
        downloadFileName="Invoice"
        csvQuery={useInvoiceQuery}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
    </div>
  )
}
