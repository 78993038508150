import React, { useEffect, useState } from "react"
import { Form, Row, Col, Label, Spinner } from "reactstrap"
import dayjs from "dayjs"
import utc from "dayjs/plugin/utc"
import { Link } from "react-router-dom"
import { useSampleDetailsQuery } from "api/getters"
import * as Yup from "yup"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import localizedFormat from "dayjs/plugin/localizedFormat"
dayjs.extend(utc)
dayjs.extend(localizedFormat)

const schema = Yup.object({
  totalPrice: Yup.string().required("Please enter price"),
})

const SampleDetails = ({ id }) => {
  const { data: currentSample, isLoading, remove } = useSampleDetailsQuery({ variables: { id } })

  const [editing, setEditing] = useState(false)

  const {
    reset,
    register,
    handleSubmit,
    getValues,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      totalPrice: "",
      feeCode: "",
      billed: false,
      paid: false,
    },
  })

  useEffect(() => {
    return () => {
      remove()
    }
  }, [])

  const onSubmit = handleSubmit(async (data) => {})

  if (isLoading) {
    return <Spinner type="grow" />
  }

  return (
    <Form onSubmit={onSubmit}>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Sample ID</Label>
            <input readOnly type="text" className="form-control" value={currentSample?.id || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Patient ID</Label>
            <Link to={`/subject-info/${currentSample.subjectId}/personal`} className="text-secondary" target="_blank">
              <input readOnly type="text" className="form-control cursor-pointer" value={currentSample?.subjectId || ""} />
            </Link>
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Tube Type</Label>
            <input readOnly type="text" className="form-control" value={currentSample?.tubeType || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Collected</Label>
            <input
              readOnly
              type="date"
              className="form-control"
              value={Boolean(currentSample?.sampleCollectionDateTime) && dayjs(currentSample?.sampleCollectionDateTime).format("YYYY-MM-DD")}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Collection Method</Label>
            <input readOnly type="text" className="form-control" value={currentSample?.collectionMethod || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Location</Label>
            <input readOnly type="text" className="form-control" value={currentSample?.sampleLocation || ""} />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Label htmlFor="formrow-text-Input">Accessioned</Label>
            <input
              readOnly
              type="date"
              className="form-control"
              value={Boolean(currentSample?.laboratoryReceivedDateTime) && dayjs(currentSample?.laboratoryReceivedDateTime).format("YYYY-MM-DD")}
            />
          </div>
        </Col>
      </Row>
    </Form>
  )
}

export default SampleDetails
