import React, { useEffect, useState } from 'react';
import './covid-streamline-display-form.css';
import DisplayOnlyForm from "./display-only-form";
import lookups from "../configuration/lookups";
import covidStreamlineFormDefinition from "../configuration/covid-streamline-form-definition";
import { Container, Row, Col, Alert, Table, ModalHeader, ModalBody, ModalFooter, Button, Modal } from "reactstrap";
import { get } from "../utility/client-utility";
import { makeApiCall } from "../api/generic-api";
import ReactLoading from "react-loading";
import moment from "moment";

function CovidStreamlineDisplayForm(props) {
    // console.log('isDisableForm', props.isDisableForm);
    const [alertState, setAlertState] = useState();
    // const [ isDisableForm, setIsDisableForm ] = useState(true);
    const { isDisableForm, setIsDisableForm } = props;
    const [alertBadMessage, setAlertBadMessage] = useState();
    
    const [ show, setShow ] = useState(false);
    const [ viewPatient, setViewPatient ] = useState(null);
    const [ selectedSubject, setSelectedSubject ] = useState(null);
    const [selected, setSelected] = useState(0);
    
    const [ selectedSearchResult, setSelectedSearchResult ] = useState(null);
    
    const structure = props && props.structure;
    const change = () => {
        setAlertState(null);
    };
    
    const submit = async (formState) => {
        const result = await makeApiCall('put', '/api/submission/covid', formState);
        if (result.ok) {
            setAlertState('good');
            setAlertBadMessage('');
            setIsDisableForm(true);
            window.scrollTo(0, document.body.scrollHeight);
            if (props.loadTest) {
                props.loadTest();
            }
        } else {
            setAlertState('bad');
            const detail = `Details: code ${result.statusCode}; ${result.error}`;
            setAlertBadMessage(detail);
            window.scrollTo(0, document.body.scrollHeight);
        }
    };
    
    const selectSubject = (data) => {
        if (!data || data.id == selectedSubject?.id) {
            setSelectedSubject(null);
            props.setSelectedSubject(null)
        } else {
            setSelectedSubject(data);
            props.setSelectedSubject(data)
        }
    }
    
    const openPatientModal = (record) => {
        setViewPatient(record)
        handleShowModal();
    };
    const handleCloseModal = () => setShow(false);
    const handleShowModal = () => setShow(true);
    const select = (index) => {
        if (selected == index) {
            setSelected(0)
        } else {
            setSelected(index);
        }
    
        setIsDisableForm(true);
        setSelectedSubject(null);
        props.setSelectedSubject(null)
        props.setSelectedResult(props.searchResult[index]);
        setSelectedSearchResult(props.searchResult[index]);
    };
    
    return (
      <div className="CovidStreamlineForm" style={{ marginTop: "30px" }}>
        <Modal isOpen={show} toggle={handleCloseModal} size="lg">
          <ModalHeader>Subject Details</ModalHeader>
          <ModalBody>
            <DisplayOnlyForm
              formDefinition={{
                sections: covidStreamlineFormDefinition.sections.filter((data) => data.label == "About you"),
              }}
              isDisableForm={true}
              data={viewPatient}
              lookups={lookups}
              {...props}
            />
          </ModalBody>
          <ModalFooter>
            <Button type="button" outline color="primary" onClick={handleCloseModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
        {props.searchResult?.length > 1 ? (
          <Container>
            <h4 className="mt-4">Search Results</h4>
            <Table responsive hover>
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Date of Birth</th>
                  <th>Test Sample ID</th>
                  <th>Submitted</th>
                </tr>
              </thead>
              <tbody>
                {props.searchResult.map((record, index) => (
                  <tr key={index} onClick={() => select(index)} style={{ backgroundColor: selected == index && "lightyellow" }}>
                    <td>{record.data.firstName}</td>
                    <td>{record.data.lastName}</td>
                    <td>{record.data.dateOfBirth}</td>
                    <td>{record.data.testId || record.data.sampleId}</td>
                    <td>{record.data.submittedTimestamp ? new Date(record.data.submittedTimestamp).toLocaleString() : ""}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Container>
        ) : null}

        {props.searchResult[selected].data.isNETest && (
          <Alert style={{ marginTop: "10px", textAlign: "center" }} color="warning">
            {`This sample (${props.searchResult[selected].data?.sampleId || props.searchResult[selected].data?.testId}) is registered in the LIS ${
              props.searchResult[selected].data.taps ? "via TAPS" : "directly"
            } and can be accessioned.  Please check details: ${props.searchResult[selected].data.firstName} ${
              props.searchResult[selected].data.lastName
            } ${moment(props.searchResult[selected].data.dateOfBirth).format("MM/DD/YYYY")}. ${
              props.searchResult[selected].data.isPPQ === true ? "Patient has requested PPQ." : ""
            }`}
          </Alert>
        )}

        {!props.searchResult[selected].data.isNETest && (
          <div>
            {
              <div>
                {!props.searchingExistingSubject && props.existingSubjects.length > 0 ? (
                  <Container className="mt-5">
                    <h4 className="mb-4">Possible Patient Matches</h4>
                    <Table responsive>
                      <thead>
                        <tr>
                          <th>Select</th>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Date of Birth</th>
                          <th>Email</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {props.existingSubjects.map((record, index) => (
                          <tr
                            style={{ backgroundColor: selectedSubject?.id == record.id && "lightyellow", cursor: isDisableForm ? "pointer" : "not-allowed" }}
                            key={index}
                          >
                            <td>
                              <input
                                type="checkbox"
                                value="medium"
                                disabled={!isDisableForm}
                                checked={selectedSubject?.id == record.id}
                                onClick={() => selectSubject(record)}
                              />
                            </td>
                            <td>{record.firstName}</td>
                            <td>{record.lastName}</td>
                            <td>{record.dateOfBirth}</td>
                            <td>{record.email}</td>
                            <td>
                              <button className="btn btn-outline btn-outline-primary" onClick={() => openPatientModal(record)}>
                                View
                              </button>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Container>
                ) : null}

                {props.searchingExistingSubject ? (
                  <Container className="my-5">
                    <center>
                      <ReactLoading type="spin" color="red" height={60} width={50} />
                      <p>Searching for possible patient matches</p>
                    </center>
                  </Container>
                ) : null}
              </div>
            }

            <Container>
              <Row>
                <Col>
                  <h2>COVID-19 TEST SUBMISSION</h2>
                </Col>
              </Row>
            </Container>
            <DisplayOnlyForm
              formDefinition={covidStreamlineFormDefinition}
              lookups={lookups}
              change={change}
              submit={submit}
              isDisableForm={isDisableForm}
              setIsDisableForm={setIsDisableForm}
              selectSubject={selectSubject}
              {...props.searchResult[selected]}
              {...props}
            />
          </div>
        )}

        {alertState === "good" ? (
          <Alert style={{ marginTop: "10px", textAlign: "center" }} color="success">
            {"Record has been updated."}
          </Alert>
        ) : null}
        {alertState === "bad" ? (
          <Alert style={{ marginTop: "10px", textAlign: "center" }} color="danger">
            {"There was a problem submitting your data."}
            <br />
            {alertBadMessage || "Please try again or contact us."}
          </Alert>
        ) : null}
      </div>
    )
}

export default CovidStreamlineDisplayForm;
