'use strict';

const Immutable = require('seamless-immutable').static;

const userType = Immutable(
    {
        laboratory: 'laboratory',
        laboratoryDirector: 'laboratoryDirector',
        systemAdministrator: 'system-administrator'
    }
);

const userTypes = Immutable(
    [userType.laboratory, userType.laboratoryDirector, userType.systemAdministrator]
);

const userPermission = Immutable(
    {
        standard: 'standard',
        administrator: 'administrator'
    }
);

const userPermissions = Immutable(
    [userPermission.standard, userPermission.administrator]
);

const userStatus = Immutable(
    {
        live: 'live',
        suspended: 'suspended'
    }
);

const userStatuses = Immutable(
    [userStatus.live, userStatus.suspended]
);

const testStatus = Immutable({
    registered : 'registered',
    unidentifiable : 'unidentifiable',
    duplicate: 'duplicate',
    usable : 'usable',
    unusable : 'unusable',
    inconclusive : 'inconclusive',
    positive : 'positive',
    negative : 'negative',
    overdue : 'overdue',
    notFound : 'no test found'
});

const testStatuses = Immutable(
    [testStatus.registered, testStatus.unidentifiable, testStatus.usable, testStatus.unusable, testStatus.inconclusive,
        testStatus.positive, testStatus.negative, testStatus.overdue, testStatus.notFound]
);

module.exports = {
    userType: userType,
    userTypes: userTypes,
    userPermission: userPermission,
    userPermissions: userPermissions,
    userStatus: userStatus,
    userStatuses: userStatuses,
    testStatus: testStatus,
    testStatuses: testStatuses
};
