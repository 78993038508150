import React, { useRef, useState } from "react"
import { Card, CardBody } from "reactstrap"
import FileUploader from "./file-uploader"
import { ResultsUploadTable } from "./tables/ResultsUploadTable"
import Breadcrumb from "./custom/Breadcrumb"

function ProcessPcrViewNew(props) {
  const [tableKey, setTableKey] = useState(0)

  const fixedSelector = {
    $or: [
      {
        cancerPcr: false,
      },
      {
        cancerPcr: {
          $exists: false,
        },
      },
    ],
  }

  const callRefresh = () => {
    setTableKey((prev) => prev + 1)
  }

  const permission = props && props.applicationState.authentication.user.permission

  return (
    <div>
      <div className="wide-container">
        <Breadcrumb title="" breadcrumbItems={[]} />
        <Card className="mb-3">
          <CardBody>
            <FileUploader title="Covid PCR Results Upload" uri="/api/pcr-file" refresh={callRefresh} {...props}></FileUploader>{" "}
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <ResultsUploadTable
              type="covid"
              key={tableKey}
              allowPageSize
              allowPaginate
              downloadFileName="Covid PCR Results Upload"
              permission={permission}
              customSelect={fixedSelector}
              defaultSort="submittedTimestamp"
              defaultSortDir="desc"
              {...props}
            />
          </CardBody>
        </Card>
      </div>
    </div>
  )
}

export default ProcessPcrViewNew
