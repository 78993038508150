import React, { Component, useEffect, useState } from 'react';
import logo from '../resources/logo.png';
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
  /*GlyphIcon
  Container,
  Row,
  Col,
  Jumbotron,
  Button*/
} from 'reactstrap';

import { Navigate, /*Link,*/Routes, Route, Switch, NavLink as RRNavLink, Redirect } from 'react-router-dom';

import Login from './login';
import Logout from './logout';
import AdminAccount from './admin-account';
import Password from './password';
import SendPassword from './send-password';
import PropTypes from 'prop-types';
import Accession from './accession';
import RequisitionNetest from './requisition-netest';
import RequisitionCovid from './requisition-covid';
import { userType, userTypes, userPermission, userPermissions, userStatus, userStatuses, testStatus, testStatuses } from '../utility/constants';
import AccessionView from "./accession-view";
import PrescriptionView from "./prescription-view";
import ProcessTrayView from "./process-tray-view";
import ProcessPcrView from "./process-pcr-view";
import CovidStreamlineForm from "./covid-streamline-form";
import SubjectView from './subject-view';
import TestView from './test-view';
import TestViewNew from './test-view-new';
import TestViewInfo from "./test-view-info"
import InvoiceView from './invoice-view';
import ReportView from './report-view';
import Upload from './upload';
import InsuredView from "./insured-view";
import BadEmailAddresses from "./bad-email-addresses";
import InsuranceCompanyView from "./insurance-company-view";
import PricesView from "./prices-view";
import ProductsView from "./products-view";
import TestConfigurationView from "./test-configuration-view";
import { useLocation } from "react-router";
import PageNotFound from "./page-not-found";
import OrdersView from "./orders-view";
import SampleView from "./sample-view";
import ProcessSampleView from "./process-sample-view";
import ProcessCancerPcrView from "./process-cancer-pcr-view";
import PrintView from "./print-view";
import TemplatesView from "./templates-view";
import ExtrasConfigurationView from "./extras-configuration-view";
import RequisitionsConfigurationView from "./requisitions-configuration/requisitions-configuration-view";
import RequisitionsConfigurationViewNew from "./requisitions-configuration/requisitions-configuration-view-new"
import CancerTestsView from "./cancer-tests-view"
import SubjectInfoView from "./subject-info/subject-info-view"
import OrderInfoView from "./order-info/order-info-view"
import UnfilledOrdersView from "./unfilled-orders-view"
import NotificationsView from "./notifications/notifications-view"
import TwoFactorAuthView from "./two-factor-auth/two-factor-auth-view"
import SystemSettingsView from "./system-settings/system-settings-view"
import CancerTestsViewInfo from "./cancer-test-info-view"
import ProcessSampleInfoView from "./process-sample-info-view"
import RequisitionNetestInfo from "./requisition-netest-info"
import ProcessTrayInfoView from "./process-tray-info-view"
import ProcessTrayViewNew from "./process-tray-view-new"
import SampleViewNew from "./sample-view-new"
import SampleViewInfo from "./sample-view-info"
import ProcessPcrViewNew from "./process-pcr-view-new"
import AccessionViewNew from "./accession-view-new"
import AccessionViewInfo from "./accession-view-info"
import UnfilledOrdersViewNew from "./unfilled-orders-view-new"
import UnfilledOrdersInfoView from "./unfilled-order-view-info"
import InvoiceViewNew from "./invoice-view-new"
import InsuredViewNew from "./insured-view-new"
import InsuredViewInfo from "./insured-view-info"
import ProductViewNew from "./products-view-new"
import ProductFormView from "./product-form-view"
import ProductInfoView from "./product-view-info"
import PricesViewNew from "./price-view-new"
import PriceFormView from "./price-view-form"
import PriceInfoView from "./price-view-info"
import TemplatesViewNew from "./templates-view-new"
import InsuranceCompanyViewNew from "./insurance-company-view-new"
import InsuranceCompanyViewForm from "./insurance-company-view-form"
import InsuranceCompanyViewInfo from "./insurance-company-view-info"
import NotificationsViewNew from "./notifications/notifications-view-new"
import NotificationsViewForm from "./notifications/notification-view-form"
import NotificationsViewInfo from "./notifications/notification-view-info"
import ExtrasConfigurationViewNew from "./extras-configuration-view-new"
import ExtrasConfigurationViewForm from "./extras-configuration-view-form"
import ExtrasConfigurationViewInfo from "./extras-configuration-view-info"

/* PrivateRoute component definition */
export const PrivateRoute = ({ component: Component, applicationState, ...rest }) => {
  const location = useLocation()
  const { isAuthenticated, user } = applicationState.authentication
  const { twoFactorAuthentication } = applicationState

  if (isAuthenticated) {
    if (twoFactorAuthentication && user && (user.twoFactorSetupRequired || user.secondFactorRequired)) {
      // if ((user && (user.twoFactorSetupRequired || user.secondFactorRequired))) {
      return <Navigate to="/two-factor-auth" replace />
    }
    return Component ? <Component {...rest} applicationState={applicationState} /> : <Navigate to="/accession-sample" replace />
  } else {
    return <Navigate to={{ pathname: "/login", state: { from: location } }} replace />
  }
}

PrivateRoute.propTypes = {
  location: PropTypes.any,
  component: PropTypes.func,
  authed: PropTypes.any,
}

const NavRouting = (props) => {
  const [state, setState] = useState({
    isOpen: false,
    modeChecked: false,
    accessionDropdownOpen: false,
    requisitionDropdownOpen: false,
    processDropdownOpen: false,
  })

  const toggle = () => {
    setState((prevState) => ({
      ...prevState,
      isOpen: !state.isOpen,
    }))
  }
  const toggleRequisitionDropdown = () => {
    setState((prevState) => ({
      ...prevState,
      requisitionDropdownOpen: !state.requisitionDropdownOpen,
    }))
  }
  const toggleAccessionDropdown = () => {
    setState((prevState) => ({
      ...prevState,
      accessionDropdownOpen: !state.accessionDropdownOpen,
    }))
  }
  const toggleProcessDropdown = () => {
    setState((prevState) => ({
      ...prevState,
      processDropdownOpen: !state.processDropdownOpen,
    }))
  }
  const toggleViewDropdown = () => {
    setState((prevState) => ({
      ...props,
      viewDropdownOpen: !state.viewDropdownOpen,
    }))
  }
  const toggleSettingsDropdown = () => {
    setState((prevState) => ({
      ...props,
      settingsDropdownOpen: !state.settingsDropdownOpen,
    }))
  }
  const isAuthenticated = () => {
    return props.applicationState.authentication.isAuthenticated
  }
  const isAdministrator = () => {
    return (
      props.applicationState.authentication &&
      props.applicationState.authentication.user &&
      props.applicationState.authentication.user.permission === "administrator"
    )
  }
  const mode = () => {
    if (
      !props.applicationState.authentication.isAuthenticated ||
      !props.applicationState.authentication.user ||
      !(props.applicationState.authentication.user._id || props.applicationState.authentication.user.id)
    ) {
      return "login"
    }
    const activeUserType = props.applicationState.authentication.user.type
    //const serviceUrn = props.applicationState.serviceUrn;

    //const serviceGroupId = props.applicationState.groupId;
    switch (activeUserType) {
      case userType.systemAdministrator:
        console.log("sys admin")
        break
      case userType.laboratory:
        break
      default:
        return "Invalid login - the user type of " + activeUserType + " is not recognised by this service."
    }
    return activeUserType
  }
  const checkMode = () => {
    const modeChecked = mode()
    if (state.modeChecked !== mode) {
      if (modeChecked.startsWith("Invalid")) {
        props.setError(modeChecked)
      }
      setState((prevState) => ({
        ...prevState,
        modeChecked: modeChecked,
      }))
    }
  }
  const hasFeature = (feature) => {
    return props.applicationState.features.includes(feature)
  }
  const hasRole = (role) => {
    const roles = props.applicationState.authentication.user?.roles
    return roles && roles.length && roles.includes(role)
  }
  const { applicationState } = props
  const user = applicationState?.authentication?.user

  const userName = user?.userid || ""
  const userRoles = user?.roles && user.roles.length > 0 ? user.roles : []

  const [dropdownOpen, setDropdownOpen] = useState(false)
  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState)
  }

  useEffect(() => {
    checkMode()
  }, [])

  return (
    <div>
      <Navbar color="company" dark expand="md">
        <NavbarBrand href="/">
          <img alt="" src={logo} width="141" height="36" className="d-inline-block align-top" />{" "}
          <span style={{ paddingLeft: "10px", paddingTop: "10px", display: "inline-block" }}></span>
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse style={{ flexGrow: 0 }} isOpen={state.isOpen} navbar>
          <Nav className="ml-auto" navbar>
            {!isAuthenticated() ? (
              <NavItem>
                <NavLink exact to="/login" tag={RRNavLink}>
                  Login
                </NavLink>
              </NavItem>
            ) : null}
            {/*[userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature('laboratory') ?
                                <NavItem>
                                    <NavLink exact to="/prescription-view" tag={RRNavLink} disabled={!isAuthenticated()} hidden={!isAuthenticated()} >Prescriptions</NavLink>
                                </NavItem>
                                : null
                            */}
            {/*[userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature('laboratory') ?
                                <NavItem>
                                    <NavLink exact to="/subject-info" tag={RRNavLink} disabled={!isAuthenticated()} hidden={!isAuthenticated()} >Patients</NavLink>
                                </NavItem>
                                : null
                            */}
            {isAdministrator() ||
              ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
                hasFeature("laboratory") &&
                (mode() == userType.laboratory ? hasRole("accessioning") : true)) ? (
              <Dropdown nav isOpen={state.accessionDropdownOpen} toggle={toggleAccessionDropdown} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>
                <DropdownToggle nav>Accession</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem exact to="/accession-sample" tag={RRNavLink}>
                    Sample
                  </DropdownItem>
                  <DropdownItem exact to="/accession-view" tag={RRNavLink}>
                    Backlog
                  </DropdownItem>
                  <DropdownItem exact to="/covid-submission-view" tag={RRNavLink}>
                    Covid Submission
                  </DropdownItem>
                  <DropdownItem exact to="/upload" tag={RRNavLink}>
                    SOLV Upload
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            ) : null}
            {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
              <Dropdown nav isOpen={state.requisitionDropdownOpen} toggle={toggleRequisitionDropdown} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>
                <DropdownToggle nav>Requisition</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem exact to="/requisition-netest" tag={RRNavLink}>
                    Cancer Sample
                  </DropdownItem>
                  {/*<DropdownItem exact to="/requisition-covid" tag={RRNavLink} >
                                            Covid
                                        </DropdownItem>*/}
                </DropdownMenu>
              </Dropdown>
            ) : null}
            {isAdministrator() ||
              ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
                hasFeature("laboratory") &&
                (mode() == userType.laboratory ? hasRole("medical") || hasRole("laboratory") : true)) ? (
              <Dropdown nav isOpen={state.processDropdownOpen} toggle={toggleProcessDropdown} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>
                <DropdownToggle nav>Process</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem exact to="/process-sample" tag={RRNavLink}>
                    Sample
                  </DropdownItem>
                  <DropdownItem exact to="/process-tray" tag={RRNavLink}>
                    Tray
                  </DropdownItem>
                  <DropdownItem exact to="/process-covid-pcr" tag={RRNavLink}>
                    Covid PCR
                  </DropdownItem>
                  {hasRole("laboratoryDirector") ? (
                    <DropdownItem exact to="/process-cancer-pcr" tag={RRNavLink}>
                      Cancer PCR
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </Dropdown>
            ) : null}
            {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
              <Dropdown nav isOpen={state.viewDropdownOpen} toggle={toggleViewDropdown} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>
                <DropdownToggle nav>View</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem exact to="/subject-view" tag={RRNavLink}>
                    Patients
                  </DropdownItem>
                  <DropdownItem exact to="/test-view" tag={RRNavLink}>
                    Covid Tests
                  </DropdownItem>
                  <DropdownItem exact to="/cancer-tests-view" tag={RRNavLink}>
                    Cancer Tests
                  </DropdownItem>
                  <DropdownItem exact to="/sample-view" tag={RRNavLink}>
                    Samples
                  </DropdownItem>
                  {isAdministrator() || hasRole("billing") ? (
                    <DropdownItem exact to="/invoice-view" tag={RRNavLink}>
                      Invoice
                    </DropdownItem>
                  ) : null}

                  {isAdministrator() || hasRole("billing") ? (
                    <DropdownItem exact to="/insured-view" tag={RRNavLink}>
                      Insured
                    </DropdownItem>
                  ) : null}
                  {isAdministrator() || hasRole("billing") ? (
                    <DropdownItem exact to="/insurance-company-view" tag={RRNavLink}>
                      Insurance Company
                    </DropdownItem>
                  ) : null}

                  {isAdministrator() || hasRole("pricing") ? (
                    <DropdownItem exact to="/prices-view" tag={RRNavLink}>
                      Prices
                    </DropdownItem>
                  ) : null}

                  {isAdministrator() || hasRole("products") ? (
                    <DropdownItem exact to="/products-view" tag={RRNavLink}>
                      Products
                    </DropdownItem>
                  ) : null}
                  {isAdministrator() || hasRole("billing") ? (
                    <DropdownItem exact to="/orders-view" tag={RRNavLink}>
                      Orders
                    </DropdownItem>
                  ) : null}
                  {isAdministrator() || hasRole("billing") ? (
                    <DropdownItem exact to="/unfilled-orders-view" tag={RRNavLink}>
                      Unfilled Orders
                    </DropdownItem>
                  ) : null}
                  {isAdministrator() || hasRole("laboratoryDirector") ? (
                    <DropdownItem exact to="/print-view" tag={RRNavLink}>
                      Print
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </Dropdown>
            ) : null}
            {isAdministrator() ||
              ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
                hasFeature("laboratory") &&
                (mode() == userType.laboratory ? hasRole("reports") : true)) ? (
              <NavItem>
                <NavLink exact to="/reports" tag={RRNavLink} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>
                  Reports
                </NavLink>
              </NavItem>
            ) : null}
            {[userType.systemAdministrator, userType.laboratory].includes(mode()) && isAdministrator() && hasFeature("user-management") ? (
              <NavItem>
                <NavLink exact to="/users" tag={RRNavLink} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>
                  Users
                </NavLink>
              </NavItem>
            ) : null}
            {/*mode() === userType.systemAdministrator && hasFeature('test-reporting') ?
                                <NavItem>
                                    <NavLink exact to="/reporting" tag={RRNavLink} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>Reporting</NavLink>
                                </NavItem>
                                : null
                            */}

            {/*<NavItem>*/}
            {/*    <NavLink exact to="/password" tag={RRNavLink} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>Password</NavLink>*/}
            {/*</NavItem>*/}

            {isAdministrator() && (
              <Dropdown isOpen={state.settingsDropdownOpen} toggle={toggleSettingsDropdown} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>
                <DropdownToggle nav>Settings</DropdownToggle>
                <DropdownMenu style={{ position: "absolute" }}>
                  <DropdownItem exact to="/password" tag={RRNavLink}>
                    Password
                  </DropdownItem>
                  {[userType.systemAdministrator, userType.laboratory].includes(mode()) && isAdministrator() && hasFeature("laboratory") ? (
                    <DropdownItem exact to="/bad-email-addresses" tag={RRNavLink}>
                      Bad Email Addresses
                    </DropdownItem>
                  ) : null}
                  {isAdministrator() ? (
                    <DropdownItem exact to="/test-configuration" tag={RRNavLink}>
                      Test Configuration
                    </DropdownItem>
                  ) : null}
                  {[userType.systemAdministrator, userType.laboratory].includes(mode()) && isAdministrator() && hasFeature("laboratory") ? (
                    <DropdownItem exact to="/templates" tag={RRNavLink}>
                      Template Configuration
                    </DropdownItem>
                  ) : null}

                  {[userType.systemAdministrator, userType.laboratory].includes(mode()) && isAdministrator() && hasFeature("laboratory") ? (
                    <DropdownItem exact to="/extras-configuration" tag={RRNavLink}>
                      Extras Configuration
                    </DropdownItem>
                  ) : null}

                  {[userType.systemAdministrator, userType.laboratory].includes(mode()) && isAdministrator() && hasFeature("laboratory") ? (
                    <DropdownItem exact to="/requisitions-configuration" tag={RRNavLink}>
                      Requisitions Configuration
                    </DropdownItem>
                  ) : null}

                  {!props.applicationState.hideAnnouncementPage &&
                    [userType.systemAdministrator, userType.laboratory].includes(mode()) &&
                    isAdministrator() &&
                    hasFeature("laboratory") ? (
                    <DropdownItem exact to="/notifications" tag={RRNavLink}>
                      Announcements
                    </DropdownItem>
                  ) : null}

                  {[userType.systemAdministrator, userType.laboratory].includes(mode()) && isAdministrator() && hasFeature("laboratory") ? (
                    <DropdownItem exact to="/system-settings" tag={RRNavLink}>
                      System Settings
                    </DropdownItem>
                  ) : null}
                </DropdownMenu>
              </Dropdown>
            )}
            <NavItem>
              <NavLink exact to="/logout" tag={RRNavLink} disabled={!isAuthenticated()} hidden={!isAuthenticated()}>
                Logout
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <div style={{ display: "flex", justifyContent: "flex-end", alignItems: "center", marginTop: "5px", marginRight: "4rem", flexWrap: "wrap" }}>
        <span style={{ fontWeight: "bold", fontSize: "0.9rem", marginRight: "10px" }}>{userName}</span>
        {userRoles.length > 0 && (
          <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
            <DropdownToggle
              style={{
                padding: "5px 10px",
                borderRadius: "5px",
                backgroundColor: "#fff",
                color: "#E74C3C",
                cursor: "pointer",
                border: "1px solid red",
                position: "relative",
                display: "flex",
                alignItems: "center",
                fontSize: "0.9rem",
                fontWeight: "600",
              }}
            >
              User roles
              <span
                style={{
                  marginLeft: "8px",
                  fontSize: "0.8rem",
                  lineHeight: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {dropdownOpen ? "▲" : "▼"}
              </span>
            </DropdownToggle>
            <DropdownMenu style={{ marginTop: "7px" }}>
              {userRoles.map((role, index) => (
                <DropdownItem
                  key={index}
                  style={{
                    cursor: "default",
                    color: "#E74C3C",
                    textTransform: "uppercase",
                    fontSize: "11px",
                  }}
                >
                  {role}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </Dropdown>
        )}
      </div>

      {/* Route components are rendered if the path prop matches the current URL,
                switch makes it use first match

                Passing props through to give access to root application state for authentication, user-service
                and current domain status.
                 */}

      <Routes>
        {!isAuthenticated() ? <Route path="/sendpassword" element={<SendPassword myProp="value" {...props} />} /> : null}
        {/*{!isAuthenticated() ?*/}
        {/*  <Route path="/*" element={<Login myProp="value" {...props}/>}/> : null*/}
        {/*}*/}

        {!isAuthenticated() ? <Route path="/*" element={<Login myProp="value" {...props} />} /> : null}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("accessioning") : true)) ? (
          <Route exact={true} path="/accession-sample" element={<PrivateRoute component={Accession} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("accessioning") : true)) ? (
          <Route exact={true} path="/login" element={<PrivateRoute component={Accession} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/prescription-view" element={<PrivateRoute component={PrescriptionView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/subject-view" element={<PrivateRoute component={SubjectView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/subject-info/:subjectId/*" element={<PrivateRoute component={SubjectInfoView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/order-info/:orderId/*" element={<PrivateRoute component={OrderInfoView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/test-view" element={<PrivateRoute component={TestViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/test-view/:id" element={<PrivateRoute component={TestViewInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/cancer-tests-view" element={<PrivateRoute component={CancerTestsView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/cancer-tests-view/:id" element={<PrivateRoute component={CancerTestsViewInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/sample-view/" element={<PrivateRoute component={SampleViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/sample-view/:id" element={<PrivateRoute component={SampleViewInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("billing") : true)) ? (
          <Route exact={true} path="/insured-view" element={<PrivateRoute component={InsuredViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/insured-view/:id" element={<PrivateRoute component={InsuredViewInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("billing") : true)) ? (
          <Route exact={true} path="/insurance-company-view" element={<PrivateRoute component={InsuranceCompanyViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("billing") : true)) ? (
          <Route exact={true} path="/insurance-company-form" element={<PrivateRoute component={InsuranceCompanyViewForm} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("billing") : true)) ? (
          <Route exact={true} path="/insurance-company-view/:id" element={<PrivateRoute component={InsuranceCompanyViewInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("pricing") : true)) ? (
          <Route exact={true} path="/prices-view" element={<PrivateRoute component={PricesViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("pricing") : true)) ? (
          <Route exact={true} path="/prices-view-form" element={<PrivateRoute component={PriceFormView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("pricing") : true)) ? (
          <Route exact={true} path="/prices-view/:id" element={<PrivateRoute component={PriceInfoView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("products") : true)) ? (
          <Route exact={true} path="/products-view" element={<PrivateRoute component={ProductViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("products") : true)) ? (
          <Route exact={true} path="/products-view-form" element={<PrivateRoute component={ProductFormView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("products") : true)) ? (
          <Route exact={true} path="/products-view/:id" element={<PrivateRoute component={ProductInfoView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("billing") : true)) ? (
          <Route exact={true} path="/invoice-view" element={<PrivateRoute component={InvoiceViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("accessioning") : true)) ? (
          <Route exact={true} path="/accession-view" element={<PrivateRoute component={AccessionViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("accessioning") : true)) ? (
          <Route exact={true} path="/accession-view/:id" element={<PrivateRoute component={AccessionViewInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("accessioning") : true)) ? (
          <Route exact={true} path="/covid-submission-view" element={<PrivateRoute component={CovidStreamlineForm} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/requisition-netest" element={<PrivateRoute component={RequisitionNetest} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && hasFeature("laboratory")) ? (
          <Route exact={true} path="/requisition-netest/:id" element={<PrivateRoute component={RequisitionNetestInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("accessioning") : true)) ? (
          <Route exact={true} path="/upload" element={<PrivateRoute component={Upload} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("medical") || hasRole("laboratory") : true)) ? (
          <Route exact={true} path="/process-sample" element={<PrivateRoute component={ProcessSampleView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("medical") || hasRole("laboratory") : true)) ? (
          <Route exact={true} path="/process-sample/:id" element={<PrivateRoute component={ProcessSampleInfoView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("medical") || hasRole("laboratory") : true)) ? (
          <Route exact={true} path="/process-tray" element={<PrivateRoute component={ProcessTrayViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("medical") || hasRole("laboratory") : true)) ? (
          <Route exact={true} path="/process-tray/:id" element={<PrivateRoute component={ProcessTrayInfoView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("medical") || hasRole("laboratory") : true)) ? (
          <Route exact={true} path="/process-covid-pcr" element={<PrivateRoute component={ProcessPcrViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("medical") || hasRole("laboratoryDirector") : true)) ? (
          <Route exact={false} path="/process-cancer-pcr" element={<PrivateRoute component={ProcessCancerPcrView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("reports") : true)) ? (
          <Route exact={true} path="/reports" element={<PrivateRoute component={ReportView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && isAdministrator() && hasFeature("user-management")) ? (
          <Route exact={true} path="/users" element={<PrivateRoute component={AdminAccount} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() || ([userType.systemAdministrator, userType.laboratory].includes(mode()) && isAdministrator() && hasFeature("laboratory")) ? (
          <Route exact={true} path="/bad-email-addresses" element={<PrivateRoute component={BadEmailAddresses} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ? (
          <Route exact={true} path="/test-configuration" element={<PrivateRoute component={TestConfigurationView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ? (
          <Route exact={true} path="/templates" element={<PrivateRoute component={TemplatesViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {!props.applicationState.hideAnnouncementPage && isAdministrator() ? (
          <Route exact={true} path="/notifications" element={<PrivateRoute component={NotificationsViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {!props.applicationState.hideAnnouncementPage && isAdministrator() ? (
          <Route exact={true} path="/notifications-form" element={<PrivateRoute component={NotificationsViewForm} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {!props.applicationState.hideAnnouncementPage && isAdministrator() ? (
          <Route exact={true} path="/notifications/:id" element={<PrivateRoute component={NotificationsViewInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() ? (
          <Route exact={true} path="/requisitions-configuration" element={<PrivateRoute component={RequisitionsConfigurationViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ? (
          <Route exact={true} path="/system-settings" element={<PrivateRoute component={SystemSettingsView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ? (
          <Route exact={true} path="/test-configuration/ids" {...props} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("billing") : true)) ? (
          <Route exact={true} path="/orders-view" element={<PrivateRoute component={OrdersView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("billing") : true)) ? (
          <Route exact={true} path="/unfilled-orders-view" element={<PrivateRoute component={UnfilledOrdersViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("billing") : true)) ? (
          <Route exact={true} path="/unfilled-orders-view/:id" element={<PrivateRoute component={UnfilledOrdersInfoView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ||
          ([userType.systemAdministrator, userType.laboratory].includes(mode()) &&
            hasFeature("laboratory") &&
            (mode() == userType.laboratory ? hasRole("medical") || hasRole("laboratoryDirector") : true)) ? (
          <Route exact={true} path="/print-view" element={<PrivateRoute component={PrintView} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAdministrator() ? (
          <Route exact={true} path="/extras-configuration" element={<PrivateRoute component={ExtrasConfigurationViewNew} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() ? (
          <Route exact={true} path="/extras-configuration-form" element={<PrivateRoute component={ExtrasConfigurationViewForm} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}
        {isAdministrator() ? (
          <Route exact={true} path="/extras-configuration/:id" element={<PrivateRoute component={ExtrasConfigurationViewInfo} {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {!isAuthenticated() ? (
          // <Route exact={true} path="/two-factor-auth" element={<PrivateRoute component={TwoFactorAuthView} {...props} />}/> :
          <Route exact={true} path="/two-factor-auth" element={<TwoFactorAuthView {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {isAuthenticated() ? (
          <Route exact={true} path="/password" element={<Password {...props} />} />
        ) : (
          <Route exact={true} path="/*" element={<PrivateRoute {...props} />} />
        )}

        {/*<Route path="/page-not-found" element={<PageNotFound {...props}/>}/>*/}
        <Route path="/*" element={<PrivateRoute component={PageNotFound} {...props} />} />
      </Routes>
    </div>
  )
}

export default NavRouting;