import React, { useMemo, useCallback } from "react"
import { useUnfilledOrdersQuery } from "api/getters"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import utc from "dayjs/plugin/utc"
import { useTableQueryStore } from "stores/zustand/tableQueryStore"
import TableContainer from "./TableContainer"
dayjs.extend(utc)

export function UnfilledOrdersTable({ pageSize: pageSizeProp, ...rest }) {
  const { page, pageSize, selector, sort } = useTableQueryStore()

  const queryVariables = {
    sort,
    page,
    selector,
    pageSize: pageSizeProp || pageSize,
  }

  const {
    data: ordersData,
    isLoading,
    isFetching,
    refetch,
    error,
  } = useUnfilledOrdersQuery({
    variables: queryVariables,
  })

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "orderId",
        hasSort: true,
      },
      {
        Header: "Test ID",
        accessor: "id",
        hasSort: true,
      },
      {
        Header: "Patient ID",
        accessor: "subjectId",
        hasSort: true,
        csvAccessor: "csvSubjectId",
      },
      {
        Header: "Submitted Date",
        accessor: "submittedTimestamp",
        hasSort: true,
      },
      {
        Header: "Action",
        accessor: "action",
        skipCsv: true,
      },
    ],
    [],
  )

  const getProcessedTableData = useCallback(
    (_ordersData) =>
      (_ordersData &&
        _ordersData?.map((item) => ({
          orderId: item.orderId,
          id: item.id,
          csvSubjectId: item.subjectId,
          subjectId: (
            <Link to={`/subject-info/${item.subjectId || ""}/personal`} className="text-secondary" target="_blank">
              {item.subjectId}
            </Link>
          ),
          submittedTimestamp: item?.submittedTimestamp && dayjs(item?.submittedTimestamp).local().format("MM/DD/YYYY "),
          action: (
            <Link to={`/unfilled-orders-view/${item?.id || ""}`} className="btn btn-outline-primary">
              View
            </Link>
          ),
        }))) ||
      [],
    [],
  )

  const data = useMemo(() => getProcessedTableData(ordersData), [ordersData, getProcessedTableData])

  const filters = useMemo(() => {
    return [
      {
        field: "orderId",
        operator: "$eq",
        label: "Search ID",
        placeholder: "Type in Order ID",
        removeSpaces: true,
      },
      {
        field: "_id",
        operator: "$eq",
        label: "Search Test ID",
        placeholder: "Type in Test ID",
        removeSpaces: true,
      },
      {
        field: "subjectId",
        operator: "$eq",
        label: "Search Patient ID",
        placeholder: "Type in Patient ID",
        removeSpaces: true,
      },
    ]
  }, [])

  //meta title
  // document.title = "Data Tables | Skote - React Admin & Dashboard Template";

  return (
    <div>
      <TableContainer
        downloadFileName="Unfilled Orders"
        refetch={refetch}
        columns={columns}
        data={data}
        isLoading={isLoading || isFetching}
        filters={filters}
        selector={selector}
        sort={sort}
        defaultSort="submittedTimestamp"
        defaultSortDir="desc"
        csvQuery={useUnfilledOrdersQuery}
        queryVariables={queryVariables}
        getProcessedTableData={getProcessedTableData}
        {...rest}
      />
    </div>
  )
}
