import React, { useEffect, useState } from "react"

import lookups from "../configuration/lookups"
import DataWowTray from "./data-wow-tray"
import { makeApiCall } from "../api/generic-api"
import trayFullDefinition from "../configuration/tray-full-definition"
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import { useAlert } from "react-alert"
import { TraysTable } from "./tables/TraysTable"
import Breadcrumb from "./custom/Breadcrumb"

function ProcessTrayViewNew(props) {
  const permission = props && props.applicationState.authentication.user.permission
  const trayConfiguration = props.applicationState.trayConfiguration
  const structure = trayFullDefinition

  const { applicationState } = props;
  const user = applicationState?.authentication?.user;


  const fixedSelector = {
    // 'data.testType': 'covid',
    // "$or": [
    //     {
    //         "status": {
    //             "$exists": false
    //         }
    //     },
    //     {
    //         status: {
    //             "$ne": "done"
    //         }
    //     }
    // ]
  }

  const userSelector = {
    heading: {
      label: "Filter",
      style: { display: "inline-block", fontWeight: 800 },
    },
    lookups: lookups,
    controlBlockStyle: { display: "inline-block" },
    controls: [
      {
        label: "Tray Id",
        name: "_id",
        type: "text",
        style: { maxWidth: "170px" },
        groupStyle: { display: "inline-block", marginLeft: "20px" },
      },
      {
        label: "Tray Type",
        name: "trayConfigurationLabel",
        type: "select",
        source: {
          kind: "lookup",
          data: "trayType",
        },
        style: { maxWidth: "170px" },
        groupStyle: { display: "inline-block", marginLeft: "20px" },
      },
      {
        label: "Email",
        name: "ownerId",
        type: "select",
        source: {
          kind: "lookup",
          data: "trayEmail",
        },
        style: { maxWidth: "170px" },
        groupStyle: { display: "inline-block", marginLeft: "20px" },
      },
      {
        label: "Status",
        name: "status",
        type: "select",
        source: {
          kind: "lookup",
          data: "trayStatus",
        },
        style: { maxWidth: "170px" },
        groupStyle: { display: "inline-block", marginLeft: "20px" },
      },
    ],
  }

  const breadcrumbItems = [
    {
      label: "Trays",
      link: `/process-tray`,
    },
  ]

  return (
    <div className="wide-container">
      <Breadcrumb title="Trays" breadcrumbItems={breadcrumbItems} />
      <Card>
        <CardBody>

          <TraysTable allowPageSize allowPaginate isGlobalFilter authentication={user} />
        </CardBody>
      </Card>
    </div>
  )
}


export default ProcessTrayViewNew
