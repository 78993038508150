import React, { useEffect, useRef, useState } from 'react';
import {
	Alert, Button, Col, Form, FormGroup, Input, InputGroup, Modal, ModalBody, ModalFooter, ModalHeader, Row
} from "reactstrap";
import { makeApiCall } from "../api/generic-api";

const BadEmailAddresses = props => {
	const [ badEmails, setBadEmails ] = useState([]);
	const [ showDeleteModal, setShowDeleteModal ] = useState(false);
	const [ selectedBadEmailIndex, setSelectedBadEmailIndex ] = useState(null);
	const [ showAlert, setShowAlert ] = useState(false);
	const inputRef = useRef();

	useEffect(() => {
		setTimeout(() => {
			if (showAlert) {
				setShowAlert(false);
			}
		}, 5000)
	}, [ showAlert ]);

	useEffect(() => {
		const handleFetchBadEmails = async () => {
			const result = await makeApiCall('get', '/api/bad-email-addresses');
			if (result.ok) {
				setBadEmails(result.body)
			}
		}

		handleFetchBadEmails();
	}, []);

	const handleCloseModal = () => setShowDeleteModal(false);

	const handleOnAddBadEmail = async (e) => {
		e.preventDefault();
		let email = inputRef.current?.value;

		if (email && email.trim().length) {
			const found = badEmails.filter(badEmail => badEmail == email);
			console.log('found', found);
			if (!found.length) {
				const emails = [
					...badEmails,
					email,
				];

				emails.reverse();
				setBadEmails(emails);
				await handleUpdateBadEmailAddress(emails);
			}
		}

		inputRef.current.value = null;
	}

	const handleOnDeleteEmail = async () => {
		const index = selectedBadEmailIndex;
		const emails = badEmails.filter(item => item != badEmails[index]);
		emails.reverse();
		setBadEmails(emails);
		await handleUpdateBadEmailAddress(emails);
		handleCloseModal();
		setShowAlert(true)
	}

	const handleUpdateBadEmailAddress = async (badEmails) => {
		await makeApiCall('put', '/api/bad-email-addresses', badEmails);
		props.onLoadConfiguration();
	}

	return (
		<div className="">
			<Modal isOpen={showDeleteModal} toggle={handleCloseModal} size="lg">
				<ModalHeader>Remove Bad Email Address</ModalHeader>
				<ModalBody>
					Are you sure you want to delete <code>{badEmails[selectedBadEmailIndex]}</code>?
				</ModalBody>
				<ModalFooter>
					<Button
						type="button"
						outline color="danger"
						onClick={handleOnDeleteEmail}>
						YES
					</Button>
					<Button
						type="button"
						outline color="primary"
						onClick={handleCloseModal}
					>Close</Button>
				</ModalFooter>
			</Modal>

			<div className="wide-container">
				<h3 className="text-center mt-4">Bad Email Addresses</h3>
				<Row>
					<Col sm="6" className="text-center offset-3">
						<div className="px-4 pb-5 mt-4" style={{ border: "1px solid lightgray" , minHeight: "800px", borderRadius: "10px"}}>
							<Row className="mb-3" style={{marginTop: '30px'}} >
								<Col sm="12" className="text-center">
									<Form onSubmit={handleOnAddBadEmail}>
										<InputGroup className="mt-2">
											<Input name="input" placeholder="Enter bad email address" innerRef={inputRef}/>
											<Button style={{ borderRadius: "0 2px 2px 0" }} color="success" onClick={handleOnAddBadEmail}>
												<span className="fa fa-check"></span>
											</Button>
										</InputGroup>
									</Form>
								</Col>
							</Row>
							<Row>
								<Col sm="12" className="text-left">
									{
										badEmails.map((email, index) => {
											return (
												<InputGroup className="mt-2" key={index}>
													<Input readOnly={true} value={email}/>
													<Button style={{ borderRadius: "0 2px 2px 0" }} color="danger" onClick={() => {
														setSelectedBadEmailIndex(index)
														setShowDeleteModal(true);
													}}>
														<span className="fa fa-trash"></span>
													</Button>

												</InputGroup>
											)
										})
									}
									{showAlert &&
										<Alert style={{marginTop: '10px', textAlign: 'center'}}  color="success">
											Entry has been successfully deleted
										</Alert>
									}
								</Col>
							</Row>
						</div>
					</Col>
				</Row>

			</div>
		</div>
	);
};

BadEmailAddresses.propTypes = {

};

export default BadEmailAddresses;
