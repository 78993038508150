import React, { useState, useEffect } from "react";
import {
    Button,
    Label,
    Row,
    Col,
    Dropdown,
    DropdownToggle,
    Spinner,
    DropdownMenu,
    DropdownItem,
    Alert,
} from "reactstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { makeApiCall } from "api/generic-api";
import { FaTimes } from "react-icons/fa";
import { useInsuranceInfo, useInsuranceCompanyDetailSubmit } from "api/getters";


const schema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    productType: Yup.array().min(1, "At least one Supported Product must be selected"),
});

const InsuranceCompanyDetails = ({ id }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [editing, setEditing] = useState(false);
    const [productConfigurationDropdownOpen, setProductConfigurationDropdownOpen] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [productConfiguration, setProductConfiguration] = useState([]);
    const [selectedProductTypes, setSelectedProductTypes] = useState([]);

    const [recentData, setRecentData] = useState({
        companyCode: "",
        companyName: "",
        notes: "",
        productType: []
    });

    const { control, handleSubmit, setValue, formState: { errors, isDirty } } = useForm({
        resolver: yupResolver(schema),
    });
    useEffect(() => {
        const fetchConfigurations = async () => {
            setIsLoading(true);
            try {
                const [productResult] = await Promise.all([
                    makeApiCall("get", "/api/products/"),
                ]);
                setProductConfiguration(productResult.body);

            } catch (error) {
                if (error.response?.data?.error) {
                    setErrorMessage(error.response.data.error);
                } else {
                    setErrorMessage("Failed to fetch configurations");
                }
            } finally {
                setIsLoading(false);
            }
        };

        fetchConfigurations();
    }, []);



    const { data } = useInsuranceInfo({
        variables: {
            page: 1,
            selector: {
                _id: id,
            },
        },
    });

    const { mutate, isLoading: mutating, isError, error } = useInsuranceCompanyDetailSubmit({
        onSuccess: () => {
            setSuccessMessage("Insurance company has been successfully updated.");
            setErrorMessage("");
            setIsSubmitted(true);
        },
        onError: (error) => {
            const apiErrorMessage = error?.response?.data?.error;
            console.error("API Error:", apiErrorMessage);
            setErrorMessage(apiErrorMessage || "An error occurred");
        },
    });

    const insuranceData = data?.[0] || {};
    useEffect(() => {
        if (data?.[0]) {
            const product = data[0];
            const productsData = product.productsData || [];
            setSelectedProductTypes(
                product.productsData.map(type => type.label) || []
            );
            setRecentData({
                companyCode: insuranceData.id || "",
                companyName: insuranceData.name || "",
                notes: insuranceData.notes || "",
                productType: insuranceData.productsData.map((type) => type.label) || []
            });
        }
    }, [data]);
    const onSubmit = async (formData) => {
        const formValues = {
            id: formData.companyCode,
            name: formData.companyName,
            notes: formData.notes || null,
            productsData: selectedProductTypes.map(type => ({
                label: type,
                value: type.toLowerCase(),
            })),
        };
        mutate(formValues);

    };

    const productConfigArray = Array.from(new Set(productConfiguration.map(item => item.productName)));


    const toggleDropdown = () => {
        setProductConfigurationDropdownOpen(prevState => !prevState);
    };
    const getSelectedProductText = () => {
        return selectedProductTypes.length > 0 ? selectedProductTypes.join(", ") : "Select Product";
    };

    const clearSelectedTestTypes = () => {
        setSelectedProductTypes([]);
    };
    const handleTestTypeChange = (value) => {
        const updatedProductTypes = selectedProductTypes.includes(value)
            ? selectedProductTypes.filter(type => type !== value)
            : [...selectedProductTypes, value];
        setSelectedProductTypes(updatedProductTypes);
        setValue("productType", updatedProductTypes);
    };


    const handleCancel = () => {
        if (recentData) {
            setSelectedProductTypes(recentData.productType || []);
            setValue("companyName", recentData.companyName || "");
            setValue("notes", recentData.notes || "");
        } else {
            setSelectedProductTypes([]);
            setValue("companyName", "");
            setValue("notes", "");
        }
        setEditing(false);
    };

    if (isLoading) {
        return <Spinner type="grow" />
    }



    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            {successMessage && <Alert color="success">{successMessage}</Alert>}
            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
            <Row>

                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="companyCode">Company Code *</Label>
                        <Controller
                            name="companyCode"
                            control={control}
                            defaultValue={insuranceData?.id}
                            render={({ field }) => (
                                <input
                                    type="text"
                                    id="companyCode"
                                    className="form-control"
                                    {...field}
                                    value={insuranceData?.id}
                                    disabled
                                    readOnly
                                />
                            )}
                        />
                    </div>
                </Col>


                <Col md={6}>
                    <div className="mb-3">
                        <Label htmlFor="formrow-text-Input">Company Name *</Label>

                        {editing ? (

                            <Controller
                                name="companyName"
                                control={control}
                                defaultValue={insuranceData?.name || ""}
                                render={({ field }) => (
                                    <input placeholder="Enter Company Name" {...field} className={`form-control ${errors.companyName ? "is-invalid" : ""}`} disabled={isSubmitted} />
                                )}
                            />
                        ) : (

                            <input
                                type="text"
                                className="form-control"
                                value={insuranceData?.name || ""}
                                readOnly
                                disabled={!editing || isSubmitted}
                            />
                        )}
                    </div>
                    {errors.companyName && <div className="invalid-feedback">{errors.companyName.message}</div>}
                </Col>


                <Col className="mt-3" lg="15">
                    <Label className="form-label">Notes</Label>
                    <Controller
                        name="notes"
                        control={control}
                        defaultValue={insuranceData.notes || ""}
                        render={({ field }) => (
                            <textarea
                                className="form-control"
                                placeholder="Enter Notes"
                                {...field}
                                readOnly={!editing || isSubmitted}
                                disabled={!editing || isSubmitted}
                                rows="4"
                            />
                        )}
                    />
                </Col>

                <Col className="mt-3" lg="6">
                    <Label className="form-label">Supported Products *</Label>
                    {editing ? (
                        <Dropdown isOpen={productConfigurationDropdownOpen} toggle={() => toggleDropdown("productType")}>
                            <DropdownToggle
                                className="form-control text-start px-3"
                                disabled={isSubmitted}
                                style={{
                                    backgroundColor: "transparent",
                                    border: "1px solid #ccc",
                                    color: "#000",
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                                caret
                            >
                                <span style={{ flex: "1 1 auto", overflow: "auto", whiteSpace: "nowrap" }}>
                                    {getSelectedProductText()}
                                </span>
                                {selectedProductTypes.length > 0 && (
                                    <FaTimes
                                        onClick={clearSelectedTestTypes}
                                        style={{
                                            cursor: "pointer",
                                            fontSize: "1rem",
                                            marginLeft: "1rem",
                                            color: "#888",
                                        }}
                                    />
                                )}
                            </DropdownToggle>
                            <DropdownMenu className="mt-2">
                                {productConfigArray.map((ProductConfiguration) => (
                                    <DropdownItem key={ProductConfiguration} toggle={false} onClick={(e) => e.stopPropagation()}>
                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id={ProductConfiguration}
                                                value={ProductConfiguration}
                                                checked={selectedProductTypes.includes(ProductConfiguration)}
                                                onChange={() => handleTestTypeChange(ProductConfiguration)}
                                            />
                                            <label className="form-check-label cursor-pointer" htmlFor={ProductConfiguration}>
                                                {ProductConfiguration}
                                            </label>
                                        </div>
                                    </DropdownItem>
                                ))}
                            </DropdownMenu>
                        </Dropdown>
                    ) : (
                        <input
                            type="text"
                            className="form-control"
                            value={getSelectedProductText() || ""}
                            readOnly
                            disabled
                        />

                    )}
                    {errors.productType && (
                        <div className="invalid-feedback">
                            {errors.productType.message}
                        </div>
                    )}
                </Col>


                <div className="d-flex justify-content-end gap-3 mt-4">
                    {editing ? (
                        <>
                            <Button
                                key="update"
                                color="danger"
                                type="submit"
                                disabled={isSubmitted || mutating}
                            >
                                Update
                                {mutating && <Spinner size="sm" className="ms-2" />}
                            </Button>
                            {!isSubmitted && (
                                <Button
                                    key="cancel"
                                    color="secondary"
                                    type="button"
                                    onClick={() => {
                                        setSuccessMessage("");
                                        setErrorMessage("");
                                        handleCancel();
                                    }}
                                    disabled={mutating}
                                >
                                    Cancel
                                </Button>

                            )}
                        </>
                    ) : (
                        <Button color="primary" onClick={() => setEditing(true)}>
                            Edit
                        </Button>
                    )}
                </div>
            </Row>
        </form>
    );
};

export default InsuranceCompanyDetails;
